import {DirtyComponent} from 'src/app/common/dirty-component';
import {ActiveDirectoryUserListComponent} from 'src/app/components/autocompletes/active-directory-user-list/active-directory-user-list.component';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ContactRelatedEntityType} from 'src/app/enums/ContactRelatedEntityTypeEnum';
import {ProxyAdvisor} from 'src/app/dto/ProxyAdvisor';
import {Lookup} from 'src/app/dto/Lookup';
import {CityGroup} from 'src/app/dto/CityGroup';
import {CountryGroup} from 'src/app/dto/CountryGroup';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {FormControl} from '@angular/forms';
import {LookupFilter, Utils} from 'src/app/helper/utils';
import {MatDialog} from '@angular/material';
import {StartupdataproviderService} from 'src/app/service/startupdataprovider.service';
import {ActivatedRoute} from '@angular/router';
import {RoleGuardService} from 'src/app/auth/role-guard.service';
import {AlertService} from 'src/app/services/alert.service';
import {DataService} from 'src/app/service/data.service';
import {AdminProxyAdvisorService} from 'src/app/services/admin/admin-proxy-advisor.service';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {NameValueModel} from 'src/app/models/basic-structures';
import {ContactType} from 'src/app/enums/ContactTypeEnum';

@Component({
  selector: 'app-proxy-general',
  templateUrl: './proxy-general.component.html',
  styleUrls: ['./proxy-general.component.scss']
})
export class ProxyGeneralComponent implements OnInit, DirtyComponent {
  static NOT_RESEARCHED_VALUE: string = 'Not researched';
  isEdit: boolean = false;
  @Input() isCreateView : boolean = false;
  @Output() onClose: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild(ActiveDirectoryUserListComponent) responsibility: ActiveDirectoryUserListComponent;
  @Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('proxyForm') proxyForm: any;
  disableForm: boolean = true;
  model: ProxyAdvisor = new ProxyAdvisor();
  message: string;
  lookuparr: Lookup[] = [];
  cityGroups: CityGroup[] = [];
  countryGroups: CountryGroup[] = [];
  cityGroupOptions: Observable<CityGroup[]>;
  cityControl = new FormControl();
  countryGroupOptions: Observable<CityGroup[]>;
  countryControl = new FormControl();
  contactType: ContactType;
  contactRelatedEntityType: ContactRelatedEntityType
  hasPermission: boolean;
  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();
  util = new Utils(this.startupdataproviderService);
  proxyAdvisorLoading = true;
  engagementData: string[];
  votingPlatforms;
  websiteControl = new FormControl();

  constructor(private route: ActivatedRoute,
    private data: DataService,
    public dialog: MatDialog,
    private startupdataproviderService: StartupdataproviderService,
    private alertService: AlertService,
    private roleGuardService: RoleGuardService,
    private proxyAdvisorService: AdminProxyAdvisorService) {
  }

  ngOnInit() {
    if(this.isCreateView){
      this.model.votingPlatform = ProxyGeneralComponent.NOT_RESEARCHED_VALUE;
      this.proxyAdvisorLoading = false;
      this.disableForm = false;
    }
    else {
      this.route.parent.params.subscribe((params) => {
        let proxyId = +params["id"];
        if (proxyId > 0) {
          this.proxyAdvisorLoading = true;
          this.disableForm = true;
          this.getProxyAdvisor(proxyId);
        }
      });
    }
    this.cityCountryGroupOptions();
      this.hasPermission = this.roleGuardService.hasAccess('ADMIN', 'EDIT');
      this.isDirty$.subscribe(data => {
        return of(data);
      });
    this.getUtilsData();
  }

  ngAfterViewInit() {
    this.proxyForm.statusChanges.subscribe(value => {
      const valid = value === 'VALID';
      this.isFormValid.emit(valid);
    });
  }

  getProxyAdvisor(id: number) {
    this.proxyAdvisorService.get(id).subscribe(
      data => {
        this.proxyAdvisorLoading = false;
        this.model = data;
        this.model.votingPlatform = this.model.votingPlatform ? this.model.votingPlatform : ProxyGeneralComponent.NOT_RESEARCHED_VALUE;
      },
      err => {
        this.proxyAdvisorLoading = false;
        console.error(err);
      }
    );
  }

  getUtilsData() {
    this.util.getLookupByType(LookupFilter.INVENG)
      .subscribe((data: Lookup[]) => {
        this.engagementData = data.map((item: Lookup) => item.fieldLabel);
      });

    this.util.getLookupByType(LookupFilter.PRXADVVP)
      .subscribe(data => {
        this.votingPlatforms = data;
      });
  }

  onSaveBtnClick() {
    if (this.model.proxyAdvisorId > 0) {
      this.updateProxyAdvisor(this.model);
    }
    this.isEdit = false;
    this.disableForm = true;
    this.isDirty.next(this.isEdit);
  }

  updateProxyAdvisor(proxyAdvisor: ProxyAdvisor) {
    this.proxyAdvisorService.updateProxyAdvisor(proxyAdvisor).subscribe(
      data => {
        this.getProxyAdvisor(+data);
        this.cityCountryGroupOptions();
        this.alertService.sendSuccess('Proxy Advisor Updated !');
      },
      err => {
        this.alertService.sendError(err);
      }
    );
  }

  addProxyAdvisor() {
    this.proxyAdvisorService.addProxyAdvisor(this.model).subscribe(
      data => {
        this.model = data;
        this.alertService.sendSuccess('Proxy Advisor Added !');
        this.isDirty.next(this.isEdit);
        this.onClose.emit(+data);
      },
      err => { this.alertService.sendError(err); }
    );
  }

  onCancelClick() {
    this.isEdit = false;
    this.disableForm = true;
    this.isDirty.next(this.isEdit);
  }

  onEditBtnClick() {
    this.isEdit = true;
    this.disableForm = false;
    this.isDirty.next(this.isEdit);
  }

  cityCountryGroupOptions() {
    this.countryGroups = this.util.getCountryGroup();
    this.cityGroups = this.util.getCityGroup();

    this.cityGroupOptions = this.cityControl!.valueChanges
      .pipe(
        debounceTime(1),
        distinctUntilChanged(),
        startWith(''),
        map(value => Utils.CityFilterGroup(value, this.cityGroups)
        )
      );

    this.countryGroupOptions = this.countryControl!.valueChanges
      .pipe(
        debounceTime(1),
        distinctUntilChanged(),
        startWith(''),
        map(value => Utils.CountryFilterGroup(value, this.countryGroups)
        )
      );
  }
  onCancelDialogBtnClick() {
    if (this.isCreateView) {
        this.onClose.emit(undefined);
    }
  }
  onResetBtnClick() {
    this.model = new ProxyAdvisor(),
    this.responsibility.resetValue();
    this.disableForm= false;
    this.proxyAdvisorLoading = false;
  }

  citySelectionChanged(city: NameValueModel) {
    this.model.city = city.name;
  }
}
