import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClassificationsService} from '@app/services/classifications.service';
import {ResolutionClassificationItemDTO} from '@app/dto/ResolutionClassificationItemDTO';
import {AlertService} from '@app/services/alert.service';
import {RoleGuardService} from '@app/auth/role-guard.service';

@Component({
  selector: 'app-add-edit-classification',
  templateUrl: './resolution-add-edit-classification.component.html',
  styleUrls: ['./resolution-add-edit-classification.component.scss']
})
export class ResolutionAddEditClassificationComponent implements OnInit, AfterViewInit {
  _memoryModel: ResolutionClassificationItemDTO;
  _model: ResolutionClassificationItemDTO = new ResolutionClassificationItemDTO();
  @Input() set model(value) {
    if (value) {
      this._model = value;
      this._memoryModel = JSON.parse(JSON.stringify(value));
    }
  }

  categories: string[] = [];
  @Input() isEdit: boolean = false;
  @Input() dialog: boolean = false;
  @ViewChild('classificationForm') classificationForm: any;
  @Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isFormSaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  hasAccess: boolean = false;

  constructor(private classificationsService: ClassificationsService,
              private alertService: AlertService,
              private roleGuardService: RoleGuardService,) {
    this._model = new ResolutionClassificationItemDTO();
  }


  ngOnInit() {
    this.hasAccess = this.roleGuardService.hasAccess('ADMIN', 'EDIT');
    this.getClassifications();
  }

  ngAfterViewInit() {
    this.classificationForm.statusChanges.subscribe(value => {
      const valid = value === 'VALID';
      this.isFormValid.emit(valid);
    });
  }

  getClassifications() {
    this.classificationsService.getAll().subscribe(data => {
        this.categories = data;
      },
      err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      }
    );
  }


  edit(flag) {
    this.isEdit = flag;
    if (!flag) {
      this._model = JSON.parse(JSON.stringify(this._memoryModel));
    }
  }

  save() {
    this.classificationsService.addClassification(this._model).subscribe(
      data => {
        this.alertService.sendSuccess(`Classification has been ${ this._model.id ? 'updated' : 'added'} successfully!`);

        this.isEdit = false;
        this.isFormSaved.emit(true);
      },
      err => {
        this.alertService.sendError(err.error.Message);
      }
    );
  }
}
