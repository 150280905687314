export class CallLog {
  callLogId: number;
  projectId: number;
  investorId: number;
  contactId: number;
  contactName: string;
  coverage: string;
  commentType: string;
  followup: Date;
  followupTime: Date;
  followupTimePicked: boolean;
  followupCompleted: boolean;
  entryDate: Date;
  username: string;
  comment: string;
  userId = 1;
  updateTime: Date;
}
