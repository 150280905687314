import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DropdownItem } from '@app/components/dropdown-select/dropdown-select.component';
import { ClientReportDto } from '@app/dto/ClientReportDto';
import { ProjectMailingDeliveryLabelDTO, ProjectMailingIndividualDeliveryTicket } from '@app/dto/ProjectMailingDeliveryLabelDTO';
import { LookupFilter, Utils } from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { AlertService } from '@app/services/alert.service';
import { DashboardMailingService } from '@app/services/dashboard';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { take } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { sanitizeFileName } from '@app/utility/Strings';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-mailing-recipient-delivery-label',
  templateUrl: './recipient-delivery-label.component.html',
  styleUrls: ['recipient-delivery-label.component.scss']
})
export class DashboarMailingRecipientDeliveryLabelComponent implements OnInit {
  model: ProjectMailingDeliveryLabelDTO;
  disableForm = true;
  util = new Utils(this.startupdataproviderService);
  recipientsDataPicker: any;
  cusips: DropdownItem[];
  setOfMaterialsSelected: any[] = [];
  cusipsSelected: any[] = [];
  isPrinting: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DashboarMailingRecipientDeliveryLabelComponent>,
    private dashboardService: DashboardService,
    private alertService: AlertService,
    private startupdataproviderService: StartupdataproviderService,
    private dashboardMailingService: DashboardMailingService,
    private spinnerService: NgxSpinnerService
  ) {
    this.model = new ProjectMailingDeliveryLabelDTO();
    this.model = data;
  }

  ngOnInit(): void {

    this.dashboardService.getDeliveryLabelData(this.model.projectId).subscribe(data => {
      data.meetingDate = new Date(data.meetingDate);
      data.recordDate = new Date(data.recordDate);
      this.model = Object.assign(this.model, data);
      this.recipientsDataPicker = {
        noRecordsText: 'There are still no recipients selected',
        noRecordsLink: 'Edit the recipients...',
        allRecords: data.recipients,
        selectedRecords: [],
        titleNotSelectedViewInDisableMode: true,
        titleNotSelected: 'Recipients',
      };

      this.cusips = data.cusips.map(x => ({ id: x, name: x }));

    }, e => this.alertService.alert(e));

    this.util.getLookupByType(LookupFilter.PROJECT_MAILING_DOC).subscribe(data => {
      this.model.setOfMaterials = data.map(x => {
        let dropdownItem = new DropdownItem();
        dropdownItem.id = x.lookUpId.toString();
        dropdownItem.name = x.fieldValue;

        return dropdownItem;
      });
    });
  }

  onSetOfMaterialsChange(event) {
    this.setOfMaterialsSelected = event;
  }

  onCusipChange(event) {
    this.cusipsSelected = event;
    this.updateRecipients((this.recipientsDataPicker || {}).selectedRecords);
  }

  onPrint() {
    let model = new ClientReportDto();
    model.projectId = this.model.projectId;
    model.recipients = this.recipientsDataPicker.selectedRecords.map(x => ({
      custodianId: x.id,
      isGlobal: x.isGlobal,
      numberOfSetsPerCusip: x.numberOfSetsPerCusip
    }));
    model.setOfMaterialIds = this.setOfMaterialsSelected.map(x => x.id);
    model.cusips = this.cusipsSelected.map(x => x.id);
    model.numberOfSets = this.model.numberOfSets;
    model.jobDescription = this.model.jobDescription;
    model.recordDate = this.model.recordDate;
    model.meetingDate = this.model.meetingDate;
    model.specialInstructions = this.model.specialInstructions;

    this.isPrinting = true;
    this.spinnerService.show();
    this.dashboardMailingService.deliveryBulkTicket(model)   
      .pipe(take(1))
      .subscribe(
        async data => {
          await this.downloadPdfReport(data);
          this.spinnerService.hide();
          this.isPrinting = false;
          this.alertService.sendSuccess("Generating bulk delivery label is successful!");
        },
        e => {
          this.alertService.alert("There is a system");
          this.spinnerService.hide();
        }
      );
  }

  async downloadPdfReport(bulkData: ProjectMailingIndividualDeliveryTicket[]): Promise<void> {    
    this.dashboardService.printClientReport(bulkData).subscribe(response => {
      const contentDisposition = response.headers.get('content-disposition');
      const fileName = contentDisposition.split(';')[1].trim().split('=')[1].replace(/["']/g, "");
      FileSaver.saveAs(response.body, sanitizeFileName(fileName));
      this.dialogRef.close(true);
    }, e => this.alertService.alert('Error while downloading file!'));  
  }

  onRecipientsChange(event) {
    this.updateRecipients(event.selectedRecords);
  }

  private updateRecipients(selectedReceipients) {
    if (!selectedReceipients) return;
   const selectedCusips = this.cusipsSelected.map(cs => cs.id);

    this.model.numberOfSets = selectedReceipients
      .reduce((c, n) => c + n.numberOfSetsPerCusip.filter(spc => selectedCusips.indexOf(spc.cusip) > -1).reduce((cc, nn) => cc + (nn.setsRequired - nn.setsSent), 0), 0);
  }

  onCloseBtnClick(): void {
    this.dialogRef.close();
  }
}
