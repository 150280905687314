import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { Lookup } from '@app/dto/LookUp';
import { ProjectMailingDocumentDTO } from '@app/dto/ProjectMailingDocumentDTO';
import { ProjectMailingDocumentOrderReceiveDTO } from '@app/dto/ProjectMailingDocumentOrderReceiveDTO';
import { LookupFilter, Utils } from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { AlertService } from '@app/services/alert.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-dashboard-mailing-order-receive',
  templateUrl: './dashboard-mailing-dialog-order-receive.component.html',
  styleUrls: ['dashboard-mailing-dialog-order-receive.component.scss']
})
export class DashboardOrderReceiveDocumentComponent implements OnInit{
  documentNames: Lookup[] = [];
  util = new Utils(this.startupdataproviderService);
  model: ProjectMailingDocumentOrderReceiveDTO;
  actionType: string;
  disableForm: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DashboardOrderReceiveDocumentComponent>,
    private startupdataproviderService: StartupdataproviderService,
    private dashboardService: DashboardService,
    private alertService: AlertService
  ) {
    this.model = new ProjectMailingDocumentOrderReceiveDTO();
    this.model = data.document;
    this.actionType = data.actionType;
  }


  ngOnInit(): void {
    this.model.date = new Date();
    this.model.actionType = this.actionType;
  }

  onClickSave() {
    this.dashboardService.addCopiesToMailingDocument(this.model).subscribe(data => {
    this.alertService.sendSuccess('Copies added to the mailing document!');
    this.dialogRef.close();
    },
    e => {
        if ((e.error || {}).InnerMessages) {
        for (let msg of e.error.InnerMessages) {
            this.alertService.sendError(msg);
        }
        } else {
        const errorMessage = this.alertService.transformError(e.error.errors || e.error);
        this.alertService.sendError(errorMessage);
        }
    });
  }

  onCloseBtnClick(): void {
    this.dialogRef.close();
  }
}
