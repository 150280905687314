import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { Shareholdings } from '@app/dto/ProjectCustodialListDto';
import { ProjectMailingDocRecipientSentDTO } from '@app/dto/ProjectMailingDocRecipientSentDTO';
import { AlertService } from '@app/services/alert.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { ProjectsService } from '@app/services/projects.service';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-dashboard-mailing-doc-recipient-sent',
  templateUrl: './dashboard-mailing-doc-recipient-sent.component.html',
  styleUrls: ['dashboard-mailing-doc-recipient-sent.component.scss']
})
export class DashboardMailingDocRecipientSentComponent implements OnInit {
  model: ProjectMailingDocRecipientSentDTO;
  disableForm = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DashboardMailingDocRecipientSentComponent>,
    private dashboardService: DashboardService,
    private alertService: AlertService,
    private adalService: MsAdalAngular6Service,
    private projectsService: ProjectsService,
  ) {
    this.model = new ProjectMailingDocRecipientSentDTO();
    this.model = {...data, loggedUser: (this.getUserProfile() || {name: ''}).name};
  }


  ngOnInit(): void {
    this.model.date = new Date();
    this.getClassifications();
  }

  getClassifications() {
    this.dashboardService.getCusips(this.model.mailingDocumentRecipientId, this.model.projectId, false).subscribe(data => {
      this.model.shareCapitals = data.map( item => new Shareholdings({
        id: item.id,
        name: item.name,
        shareCapital: item.shareCapital,
        cusip: item.cusip
      }));
    }, err => {
      this.alertService.sendError(' error ' + JSON.stringify(err));
    });
  }

  onClickSave() {
    this.dashboardService.addSentToMailingDocumentRecipient(this.model).subscribe(data => {
    this.alertService.sendSuccess('Sent records added to the recipient!');
    this.dialogRef.close();
    }, e => this.alertService.alert(e));
  }

  onCloseBtnClick(): void {
    this.dialogRef.close();
  }


  public getUserProfile(): any {
    return this.adalService.userInfo.profile;
  }
}
