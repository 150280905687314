import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Client } from '@app/dto/Client';
import { Contact } from '@app/dto/Contact';
import { KeyValue } from '@angular/common';
import { RelatedEntity } from '@app/contact/contactdetail/contact-general/relatedEntity';

@Injectable({
  providedIn: 'root'
})
export class ContactGeneralService {
  private cache$: Observable<Client[]>[] = [];

  constructor(private http: HttpClient) {
  }

  public getContact(contactId: number): Observable<Contact> {
    const apiURL: string = environment.serverUrl + 'contacts/' + contactId;
    return this.http.get<Contact>(apiURL);
  }

  public saveContact(contat: Contact, isAdd: boolean): Observable<any> {
    const apiURL: string = environment.serverUrl + 'contacts';
    return isAdd ?
      this.http.post<Contact>(apiURL, contat) : this.http.put<Contact>(apiURL, contat);
  }

  public deleteContact(contactId: number): Observable<any> {
    const apiURL: string = environment.serverUrl + 'contacts/' + contactId;
    return this.http.delete(apiURL);
  }

  public getContactTypes(): Observable<KeyValue<number, string>[]> {
    const apiURL: string = environment.serverUrl + 'contacts/contact-types';
    return this.http.get<KeyValue<number, string>[]>(apiURL);
  }

  public getRelatedEntities(term: string): Observable<RelatedEntity[]> {
    const apiURL: string = environment.serverUrl + 'contacts/contact-related-entities/' + term;
    return this.http.get<RelatedEntity[]>(apiURL);
  }
}
