import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {MatSort, MatTableDataSource} from '@angular/material';
import {ContactDisplayType} from 'src/app/dto/ContactDisplayType';
import {RoleGuardService} from '@app/auth/role-guard.service';

@Component({
  selector: 'app-investor-call-management-contact-list',
  templateUrl: './investor-call-management-contact-list.component.html',
  styleUrls: ['./investor-call-management-contact-list.component.scss']
})

export class InvestorCallManagementContactListComponent implements OnInit {
  _data: ContactDisplayType[] = [];
  @Output() contactSelected = new EventEmitter();

  @Input()
  set data(data: ContactDisplayType[]) {
    this._data = data;
  }

  get dsContact(): MatTableDataSource<ContactDisplayType> {
    let dsContact = new MatTableDataSource<ContactDisplayType>();
    dsContact.data = this._data;
    return dsContact;
  }

  @ViewChild(MatSort) sort: MatSort;
  displayContactColumns: string[] = [
    'fullName',
    'email',
    'action'
  ];
  canEditInvestor = false;

  constructor(private roleGuardService: RoleGuardService){

  }

  ngOnInit() {
    this.canEditInvestor = this.roleGuardService.hasAccess('DASHBOARD', 'EDIT');
  }

  selectContact(contact) {
    this.contactSelected.emit(contact);
  }

}
