import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Initiative } from '@app/dto/Initiative';
import { InvestorInitiative } from '@app/dto/InvestorInitiative';

@Injectable({
  providedIn: 'root'
})
export class InitiativesService {
  private cache$: Observable<Initiative[]>[] = [];

  constructor(private http: HttpClient) {
  }

  public getInitiative(id: string, useCache: boolean = false): Observable<Initiative> {
    if (!this.cache$[id] || !useCache) {
      this.cache$[id] = this.http
        .get<Initiative>(`${environment.serverUrl}esginitiatives/${id}`).pipe(
          map(data => data),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        );
    }

    return this.cache$[id];
  }

  public createInitiative(initiative: Initiative): Observable<Initiative> {
    return this.http.post<Initiative>(`${environment.serverUrl}esginitiatives/`, initiative);
  }

  public updateInitiative(initiative: Initiative): Observable<Initiative> {
    return this.http.put<Initiative>(`${environment.serverUrl}esginitiatives/`, initiative);
  }

  public getThematics(): Observable<any> {
    return this.http.get<Initiative>(`${environment.serverUrl}esginitiatives/thematics`).pipe();
  }

  public getInvestorAvailableInitiatives(investorId): Observable<Initiative[]> {
    const url = `${environment.serverUrl}esginitiatives/investor-available-initiatives/${investorId}`;
    return this.http.get<Initiative[]>(url);
  }

  public createInvestorInitiative(initiative: InvestorInitiative): Observable<InvestorInitiative> {
    return this.http.post<InvestorInitiative>(`${environment.serverUrl}esginitiatives/add-investor-initiative`, initiative);
  }

  public hardDeleteInvestorInitiative(initiativeId:number) : Observable<any>{
    const url = `${environment.serverUrl}esginitiatives/hard-delete-investor-initiative/${initiativeId}`;
    return this.http.delete(url);
  }

  public softDeleteInvestorInitiative(initiativeId:number) : Observable<any>{
    const url = `${environment.serverUrl}esginitiatives/soft-delete-investor-initiative/${initiativeId}`;
    return this.http.delete(url);
  }
  
  public updateInvestorInitiative(initiative: InvestorInitiative): Observable<InvestorInitiative> {
    return this.http.post<InvestorInitiative>(`${environment.serverUrl}esginitiatives/update-investor-initiative`, initiative);
  }
}
