export class EndpointConstants {
  public static ALL_ENGAGEMENT_EXAMPLES: string = 'investorintelligence/all-engagement-examples';
  public static ENGAGEMENT_EXAMPLE: string = 'investorintelligence/engagement-example';

  public static ALL_EXCLUSION_RULES: string = 'investorintelligence/all-exclusion-rules';
  public static EXCLUSION_RULE: string = 'investorintelligence/exclusion-rule';

  public static ALL_ARTICLES_OPINIONS: string = 'investorintelligence/all-articles-opinions';
  public static ARTICLES_OPINIONS: string = 'investorintelligence/article-opinion';

  public static FOCUS_DISCLOSURE = 'investorintelligence/esg-focus-disclosure';
}