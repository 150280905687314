import {AdminProxyAdvisorService} from 'src/app/services/admin/admin-proxy-advisor.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContactRelatedEntityType} from 'src/app/enums/ContactRelatedEntityTypeEnum';
import {ProxyAdvisor} from 'src/app/dto/ProxyAdvisor';
import {ContactType} from 'src/app/enums/ContactTypeEnum';

@Component({
  selector: 'app-proxy-advisor-contact',
  templateUrl: './proxy-advisor-contact.component.html',
  styleUrls: ['./proxy-advisor-contact.component.scss']
})
export class ProxyAdvisorContactComponent implements OnInit {

  isChild = true; // it is used in the landing component to determine childs
  proxyAdvisorId: number;
  model : ProxyAdvisor = new ProxyAdvisor;
  contactType: ContactType;
  contactRelatedEntityType: ContactRelatedEntityType

  constructor(private route: ActivatedRoute, private proxyService: AdminProxyAdvisorService) {

  }

  ngOnInit() {
    this.contactType = ContactType.ProxyAdvisor;
    this.contactRelatedEntityType = ContactRelatedEntityType.ProxyAdvisor;
    this.route.parent.params.subscribe((params) => {
      this.proxyAdvisorId =  +params['id'];
    });
    this.proxyService.get(this.proxyAdvisorId).subscribe(
      data => {
        this.model = data;
      },
      err => console.error(err)
    );
  }

}
