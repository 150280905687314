import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Investor } from '../../dto/Investor';
import { Router, ActivatedRoute } from '@angular/router';
import { StartupdataproviderService } from '../../service/startupdataprovider.service';
import { Utils, LookupFilter } from '../../helper/utils';
import { Lookup } from 'src/app/dto/Lookup';
import { AlertService } from '../../services/alert.service';
import { Currency } from 'src/app/dto/Currency';
import { InvestorsService } from '@app/services/investors';
import { DirtyComponent } from '@app/common/dirty-component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AlternativeNameTypeEnum } from '@app/enums/AlternativeNameTypeEnum';
import { NameValueModel } from '@app/models/basic-structures';

@Component({
  selector: 'app-investor-general',
  templateUrl: './investor-general.component.html',
  styleUrls: ['investor-general.component.scss']
})

export class InvestorGeneralComponent implements OnInit, AfterContentChecked, DirtyComponent {
  model: Investor;
  disableForm: boolean;
  isCreate: boolean = false;
  coverageList: string[] = [];
  investorCategories: Lookup[] = [];
  currencies: Currency[] = [];

  util = new Utils(this.startupdataproviderService);

  routeSub: any;
  isEdit: boolean;
  loading: boolean = true;
  shareHolderType: AlternativeNameTypeEnum;
  shareHolderId: number = 0;
  placeholder = "State";
  public requiredState = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private startupdataproviderService: StartupdataproviderService,
    private cdref: ChangeDetectorRef,
    private alertService: AlertService,
    private investorService: InvestorsService) {
    this.isEdit = true;
    this.disableForm = false;
    this.model = new Investor();
  }

  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();

  public marketCapData = {
    name: 'marketCap',
    placeholder: 'Market Capitalization',
    amount: null,
    currency: null,
    currencyName: 'marketCapCurrency'
  };

  public aumData = {
    name: 'aum',
    placeholder: 'AUM',
    amount: null,
    currency: null,
    currencyName: 'aumCurrency'
  };

  ngOnInit() {
    this.shareHolderType = AlternativeNameTypeEnum.Investor;
    this.initializeCoverageList();
    const that = this;
    this.routeSub = this.route.params.subscribe((params) => {
      that.model.investorId = +params['id'];

      if (that.model.investorId > 0) {
        that.getInvestor();
        that.disableForm = true;
        that.isEdit = false;

      } else {
        this.model = new Investor();
        that.isCreate = true;
        that.isEdit = true;
        that.disableForm = false;
        that.loading = false;
        this.isDirty.next(true);
      }

      this.isDirty$.subscribe(data => {
        return of(data);
      });

      that.util.getLookupByType(LookupFilter.INVCAT).subscribe(r => that.investorCategories = r);
      that.startupdataproviderService.getCurrencies().subscribe(r => this.currencies = r);
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  //This is an important field as it filters what investors the user sees.
  initializeCoverageList() {
    this.coverageList = this.util.getCoverageList();
  }

  getInvestor() {
    this.investorService.getInvestor(this.model.investorId.toString())
      .subscribe(
        data => {
          this.model = data;
          this.shareHolderId = this.model.investorId;
          this.marketCapData.amount = data.marketCapitalization;
          this.marketCapData.currency = data.marketCapitalizationCurrency;

          this.aumData.amount = data.aum;
          this.aumData.currency = data.aumCurrency;

          this.loading = false;
        },
        err => {
          this.loading = false;
        }
      );
  }

  onEditBtnClick() {
    this.isEdit = true;
    this.disableForm = false;
    this.isDirty.next(this.isEdit);
  }

  onSaveBtnClick() {
    this.upsertInvestor(this.model);
    this.isEdit = false;
    this.disableForm = true;
    this.isDirty.next(this.isEdit);
  }

  upsertInvestor(investor: Investor) {
    investor.marketCapitalization = (this.marketCapData.amount) ? Number(this.marketCapData.amount) : null;
    investor.marketCapitalizationCurrency = this.marketCapData.currency ? this.marketCapData.currency : null;

    investor.aum = (this.aumData.amount) ? Number(this.aumData.amount) : null;
    investor.aumCurrency = this.aumData.amount ? this.aumData.currency : null;

    let serviceCall;
    if (investor.investorId > 0) {
      serviceCall = this.investorService.updateInvestor(investor);
    } else {
      serviceCall = this.investorService.createInvestor(investor);
    }
    serviceCall.subscribe(
      data => {
        this.model = data;
        this.alertService.sendSuccess("Investor: " + (this.model.company) + ` updated successfully. InvestorId: ${data.investorId}`);
        if (this.isCreate) {
          this.isCreate = false;
          this.router.navigate(["/investors/" + data.investorId])
        }
      },
      err => {
        let errorMsg: string = 'error ' + JSON.stringify(err);
        this.alertService.sendError(errorMsg);
      }
    );
  }

  stateSelectionChanged(newState: NameValueModel) {
    this.model.stateName = newState.name;
    this.model.stateId = +newState.value;
  }

  onCancelBtnClick() {
    this.getInvestor();
    this.isEdit = false;
    this.disableForm = true;
    this.isDirty.next(this.isEdit);
  }

  countrySelectionChanged(newCountry: NameValueModel) {
    this.model.country = newCountry.name;
  }

  citySelectionChanged(city: NameValueModel) {
    this.model.city = city.name;
  }
}
