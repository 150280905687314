import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NoItemsGridComponent } from "@app/components/no-items-grid/no-items-grid.component";

@NgModule({
    imports:[
        CommonModule
    ],
    declarations:[
        NoItemsGridComponent,
    ],
    entryComponents:[
        NoItemsGridComponent
    ],
    exports:[
        NoItemsGridComponent,
    ]
})

export class NoItemsGridModule {}