import { Directive, ElementRef, HostListener, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';

@Directive({
  selector: '[appNegativeNumbers]'
})
export class NegativeNumbersDirective implements OnInit {
  @Input() allowDecimals: boolean = false;
  @Input() decimalSeparator: string = ".";

  previousValue : string = "";
  integerPattern: string = '^([-+]?[0-9]+[-,])*[+-]?[0-9]+$';
  decimalPattern: string = '^[+-]?[0-9](?:\.[0-9]{1,2})?$';
  regex : string = "";

  constructor(private hostElement: ElementRef) {
   }

   ngOnInit(){
    if(this.allowDecimals===true){
      this.regex = this.decimalPattern;
    } else {
      this.regex = this.integerPattern;
    }
   }

  @HostListener('change', ['$event']) onChange(e) {
    this.validateValue(this.hostElement.nativeElement.value);
  }

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    let cursorPosition: number = e.target['selectionStart'];
    let originalValue: string = e.target['value'];
    let key: string = this.getName(e);
    let controlOrCommand = (e.ctrlKey === true || e.metaKey === true);
    let signExists = originalValue.includes('-');
    let separatorExists = originalValue.includes(this.decimalSeparator);

    let allowedKeys = [
        'Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Tab'
    ];

    let separatorIsCloseToSign = (signExists && cursorPosition <= 1);
    if(this.allowDecimals){
      if(!separatorIsCloseToSign && !separatorExists){
          if (this.decimalSeparator == '.')
              allowedKeys.push('.');
          else
              allowedKeys.push(',');
      }
    }

    let firstCharacterIsSeparator = (originalValue.charAt(0) != this.decimalSeparator);
    if (!signExists &&
        firstCharacterIsSeparator && cursorPosition == 0) {
        allowedKeys.push('-');
    }

    if (allowedKeys.indexOf(key) != -1 ||
        // Allow: Ctrl+A and Command+A
        (key == 'a' && controlOrCommand) ||
        // Allow: Ctrl+C and Command+C
        (key == 'c' && controlOrCommand) ||
        // Allow: Ctrl+V and Command+V
        (key == 'v' && controlOrCommand) ||
        // Allow: Ctrl+X and Command+X
        (key == 'x' && controlOrCommand)) {
        return;
    }

    this.previousValue = originalValue;

    let isNumber = this.validateRegexExpression(key);
    if (isNumber) return; else e.preventDefault();
}

@HostListener('paste', ['$event']) onPaste(e) {
  let value = e.clipboardData.getData('text/plain');
  this.validateValue(value);
  e.preventDefault();
}

  validateValue(value: string): void {
    let firstCharacter = value.charAt(0);
    if (firstCharacter == this.decimalSeparator)
        value = 0 + value;

    let lastCharacter = value.charAt(value.length-1);
    if (lastCharacter == this.decimalSeparator)
        value = value + 0;

    let valid: boolean = this.validateRegexExpression(value);
    this.hostElement.nativeElement['value'] = valid ? value : '';
}

getName(e): string {
  if (e.key) {
      return e.key;
  } else {
      if (e.keyCode && String.fromCharCode) {
          switch (e.keyCode) {
              case   8: return 'Backspace';
              case   9: return 'Tab';
              case  27: return 'Escape';
              case  37: return 'ArrowLeft';
              case  39: return 'ArrowRight';
              case 188: return ',';
              case 190: return '.';
              case 109: return '-'; // minus in numbpad
              case 173: return '-'; // minus in alphabet keyboard in firefox
              case 189: return '-'; // minus in alphabet keyboard in chrome
              default: return String.fromCharCode(e.keyCode);
          }
      }
    }
  }

  validateRegexExpression(value : string){
    return new RegExp(this.regex).test(value)
  }
}