import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import {
  AccountNumber
} from '@app/investor/investordetail/investor-value-country-grid/investor-value-country-grid-source-dto';

export class Custodian {
  public id: number;
  public name: string;
  public country: string;
  public accountNumbers: AccountNumber[] = [];

  constructor(init = {}) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'app-custodian-table',
  templateUrl: './custodian-table.component.html',
  styleUrls: ['./custodian-table.component.scss']
})
export class CustodianTableComponent implements OnChanges {
  @Input() data: Custodian[] = [];
  @Input() custodianType = 'Global';
  @Output() editCustodian: EventEmitter<Custodian> = new EventEmitter();
  @Output() deleteCustodian: EventEmitter<Custodian> = new EventEmitter();

  public displayedColumns: string[] = ['name', 'country', 'actions'];
  public showGrid = false;
  public noItemsText = '';

  constructor() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      if (changes.data.currentValue && changes.data.currentValue.length) {
        this.showGrid = true;
      } else {
        this.showGrid = false;
      }
    }

    if (changes.custodianType) {
      this.noItemsText = `${changes.custodianType.currentValue} Custodians`;
    }
  }

  public onEditCustodian(custodian: Custodian) {
    this.editCustodian.emit(custodian);
  }

  public onDeleteCustodian(custodian: Custodian) {
    this.deleteCustodian.emit(custodian);
  }
}
