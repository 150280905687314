import { ShareHoldersAlternativeNames } from './../../dto/ShareHoldersAlternativeNames';
import { AlternativeNameTypeEnum } from './../../enums/AlternativeNameTypeEnum';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AlertService } from '@app/services/alert.service';
import { ShareholderalternativenamesService } from '@app/services/shareholderalternativenames.service';
import { DialogsService} from '@app/services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';

@Component({
  selector: 'app-alternative-names',
  templateUrl: './alternative-names.component.html',
  styleUrls: ['./alternative-names.component.scss']
})
export class AlternativeNamesComponent implements OnInit {
  @Input() shareHolderId : number;
  @Input() shareHolderType : AlternativeNameTypeEnum;
  @Input() disabled : boolean =false;
  alternativeNames : ShareHoldersAlternativeNames [] = []
  constructor(public dialog: MatDialog,
              private dialogsService: DialogsService,
              private alertService: AlertService,
              private shareHolderAlternativeNamesService: ShareholderalternativenamesService) { }
  
  ngOnInit() {
    this.getShareHolderAlternativeNames(this.shareHolderId,this.shareHolderType);
  }

  onDeleteAlternativeName(nameSelected: ShareHoldersAlternativeNames){
    let title: string;
    switch (this.shareHolderType){
      case AlternativeNameTypeEnum.AssetManger : {
          title = "Asset Manager";
          break;
      }
      case AlternativeNameTypeEnum.Fund : {
        title= "Fund";
        break;
      }
      case AlternativeNameTypeEnum.Investor: {
        title="Investor";
        break;
      }
    }
    this.dialogsService.confirm(
      null,
      null,
      { template: DeleteProjectTemplate({ title: `${title} Alternative Names`, message: nameSelected.name }) });

    this.dialogsService.close$.subscribe(res => {
      if (res) {
          this.shareHolderAlternativeNamesService.deleteAlternativeName(nameSelected.id)
            .subscribe(r => {
              this.alertService.sendSuccess(`${title} Alternative Name deleted.`);
              this.getShareHolderAlternativeNames(
                this.shareHolderType == AlternativeNameTypeEnum.AssetManger ? nameSelected.assetManagerId : this.shareHolderType == AlternativeNameTypeEnum.Fund? nameSelected.fundId : nameSelected.investorId, this.shareHolderType);
            },
            r => {
              this.alertService.sendError(r.error);
            });
        }
    });
  }  

  getShareHolderAlternativeNames(shareHolderId: number, type:AlternativeNameTypeEnum ){
    this.shareHolderAlternativeNamesService.getShareHolderAlternativeNames(shareHolderId, type).subscribe(
      names=> {
        this.alternativeNames = [...names];
        this.alternativeNames.forEach(name => name.truncatedName = name.name.slice(0,25))
      }, err => {
        this.alertService.sendError(err.error);
      });
  }

}
