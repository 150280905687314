import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { DocumentTableModel } from '@app/dto/documentTableModel';
import { DocumentRelatedEntityType } from '@app/enums/DocumentRelatedEntityType';
import { DocumentDto } from '@app/components/documents/DocumentDto';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  private documentSource = new Subject<any>();
  documentEmitted$ = this.documentSource.asObservable();
  
  private inMemoryDocuments = new Subject<DocumentDto[]>();
  inMemoryDocuments$: Observable<DocumentDto[]> = this.inMemoryDocuments.asObservable();
  private allInMemoryDocuments: DocumentDto[] = [];

  constructor(private httpClient: HttpClient) {
    this.inMemoryDocuments$.subscribe((data) => {
      this.allInMemoryDocuments = data;
    })
  }

  public getDocuments(projectid: number, relatedEntityType: DocumentRelatedEntityType): Observable<DocumentTableModel[]> {
    return this.httpClient.get<DocumentTableModel[]>(environment.serverUrl + 'document/documents/' + projectid + '/' + relatedEntityType)
  }

  public deleteDocument(documentId: number, relatedEntityType: DocumentRelatedEntityType): Observable<boolean> {
    return this.httpClient.delete<boolean>(environment.serverUrl + 'document/' + documentId + '/' + relatedEntityType);
  }

  public uploadDocument(fileToUpload: FormData): Observable<any> {
    return this.httpClient.post(environment.serverUrl + 'document', fileToUpload);
  }

  public uploadDocumentInMemory(fileToUpload: DocumentDto) {
    this.allInMemoryDocuments.push(fileToUpload);
    this.inMemoryDocuments.next(this.allInMemoryDocuments);
    this.emitDocument({});
  }

  public cleanDocumentsInMemory() {
    this.inMemoryDocuments.next([]);
  }

  public deleteDocumentInMemory(index: number) {
    this.allInMemoryDocuments.splice(index, 1);
    this.inMemoryDocuments.next(this.allInMemoryDocuments);
  }

  public downloadDocument(id: number, relatedEntityType: DocumentRelatedEntityType): Observable<any> {
    let url = environment.serverUrl + 'document/download/' + id + '/' + relatedEntityType;
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  public setDocumentProps(file: File, 
    fileDescription:string,
    relatedEntityId:number,
    relatedEntityType:number,
    userProfile: any,
    customField: string,
    link: string): FormData {
    let formData: FormData = new FormData();
    formData.append("Description", fileDescription);
    formData.append("RelatedEntityId", relatedEntityId.toString());
    formData.append("RelatedEntityType", relatedEntityType.toString());
    formData.append("Mail", userProfile.upn);
    formData.append("Id", userProfile.oid);
    formData.append("DisplayName", userProfile.name);
    formData.append("UserPrincipalName", userProfile.upn);
    formData.append("CustomField", customField);
    formData.append("Link", link)
    if(file){
      formData.append("file", file, file.name);
    }
    
    return formData;
  } 

  public emitDocument(data: {}){
    this.documentSource.next(data);
  }
}
