import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-broker-type-dialog',
  templateUrl: './broker-type-dialog.component.html',
  styleUrls: ['./broker-type-dialog.component.scss']
})
export class BrokerTypeDialogComponent implements OnInit {

  projectId: number;
  constructor(@Inject(MAT_DIALOG_DATA)
  public data: any,
  private dialogRef: MatDialogRef<BrokerTypeDialogComponent>) { }

  ngOnInit() {
    this.projectId = this.data.projectId;
  }

  onCloseBtnClick(){
    this.dialogRef.close();
  }
}
