import { ResponsibilityUserObject } from './base/ResponsibilityUserObject';
import { BroadridgeCode } from './BroadridgeCode';
import { DtcCode } from './DtcCode';

export class GlobalCustodian extends ResponsibilityUserObject {
  constructor() {
    super();
    this.dtcCodes = [];
    this.freeBroadridgeCodes = [];
    this.isManualBrokerSearch = false;
  }

  globalCustodianId: number;
  name: string;
  address: string;
  city: string;
  country: string;
  state: string;
  zipCode: string;
  phone: string;
  website: string;
  comments: string;
  operationalProcess: string;
  locationType: string;
  brokerVoteType: string;
  dtcCodes: DtcCode[];
  freeBroadridgeCodes: BroadridgeCode[];
  isManualBrokerSearch: boolean;
  legacyId: string;
  reorgLocationType: string;
  reorgAddress: string;
  reorgCity: string;
  reorgState: string;
  reorgZipCode: string;
}
