import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RelatedEntityData} from './contact-general/relatedEntityData';

@Component({
    selector: 'app-contact-dlg',
    templateUrl: 'contact-dlg.component.html',
    styleUrls: ['contact-dlg.component.scss'],
})

export class ContactDlgComponent {
    public isFormValid = false;
    public isAdd: boolean;
    public isReadOnlyMode: boolean;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: RelatedEntityData,
        private dialogRef: MatDialogRef<ContactDlgComponent>) {
            this.isAdd = this.data.isAdd;
            this.isReadOnlyMode = this.data.isReadOnlyMode;
    }


    onCloseBtnClick() {
        this.dialogRef.close();
    }

    setFormValidation(valid) {
        this.isFormValid = valid;
      }
}
