import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DashboardProjectInvestorAU, TeamActivityDto } from '@app/dto/DashboardProjectInvestorAU';

import { MatSort, MatTableDataSource } from '@angular/material';
import { UserService } from 'src/app/services/user.service';
import { ProjectTemplateTypes } from '@app/enums/ProjectTemplateTypesEnum';
import { VotingItemDto } from '@app/dto/VotingItemDto';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { Lookup } from '@app/dto/Lookup';

@Component({
  selector: 'app-dashboard-campaign-table',
  templateUrl: './dashboard-campaign-table.html',
  styleUrls: ['dashboard-campaign-table.scss']
})

export class DashboardCampaignTableComponent implements OnInit {
  projectinvestordataforaus: DashboardProjectInvestorAU[] = [];

  displayedColumns: string[] = [
    'responsibility',
    'investorName',
    'shareholdingAsOf',
    'shareholding',
    'lastCallLog',
    'proxyAdvisor',
    'esg',
    'votingPlatform',
    'decisionMakers'
  ];

  teamActivityheaders: TeamActivityDto[] = [];

  displayDynamicColumns: DynamicColumnDefinition[] = [];

  @Input() projectId: number;
  @Input() shareClassification: string;
  @ViewChild(MatSort) sort: MatSort;
  dataSource;
  shareholdingasof: string;
  routeSub;
  rolename: string;
  coverage: string;
  projectType: ProjectTemplateTypes;
  resolutiongroupheaders: FrontBackOfficeResolutionGroup[] = [];
  groupheaders: string[] = [];
  typeColors = this.projectTemplateService.votedtypecolors;

  @ViewChild('topScrollBar') topScrollBar: ElementRef;
  @ViewChild('bottomScrollBar') bottomScrollBar: ElementRef;
  topScrollbarWidth: string="";
  showTopScrollBar: boolean= true;
  recommendations: Lookup[] = [];

  constructor(
    private projectTemplateService: ProjectTemplateService,
    private userService: UserService,
    private dashboardService: DashboardService) {
  }

  ngOnInit() {
    this.rolename = this.userService.getUserRoleName();
    this.coverage = this.userService.getUserCoverage();
    this.getDashboardProjectDetails(this.coverage, this.projectId, this.shareClassification);
    this.scroll();
  }

  ngDoCheck(){
    if (this.topScrollbarWidth != this.bottomScrollBar.nativeElement.scrollWidth + 'px'){
      this.topScrollbarWidth = this.bottomScrollBar.nativeElement.scrollWidth + 'px'
    }
  }

  getDashboardProjectDetails(coverage: string, id: number, shareClassification: string) {
    this.dashboardService.getCampaignDashboard(coverage, id, shareClassification).subscribe(
      data => {
        this.projectType = data.projectTemplateType;

        const additional = this.projectTemplateService.hasFrequency(data.votingItems) ? this.projectTemplateService.getFrequencyVoteTypes(false) : [];
        this.recommendations = this.projectTemplateService.getIntentionTypeWithoutSplitVote(data.projectTemplate, additional, true);

        this.projectinvestordataforaus = data.projectInvestors;
        this.dataSource = new MatTableDataSource<DashboardProjectInvestorAU[]>();
        this.dataSource.sort = this.sort;
        this.dataSource.data = this.getResolutionIntentionVotes(this.projectinvestordataforaus, data.teamActivities);
        this.buildTeamActivitiesDynamicColumns(data.teamActivities);
        this.buildDynamicColumns(data.votingItems);
      },
      err => console.error(err)
    );
  }

  buildTeamActivitiesDynamicColumns(teamActivities: TeamActivityDto[]) {
    let tasks = {}
    for(let i = 0; i < teamActivities.length; i++){
      if(!tasks[teamActivities[i].taskId]){
        tasks[teamActivities[i].taskId] = {};
      }

      if(tasks[teamActivities[i].taskId.toString()][teamActivities[i].projectTeamActivityId]){
        continue;
      }

      tasks[teamActivities[i].taskId.toString()][teamActivities[i].projectTeamActivityId] = teamActivities[i];
    }

    const taskKeys = Object.keys(tasks);
    for(let taskKey of taskKeys){
      let activityKeys = Object.keys(tasks[taskKey]);
      for(let i = 0; i < activityKeys.length; i++){
        if(activityKeys.length > 1){
          tasks[taskKey][activityKeys[i]].activity += ` (${i + 1}) status`;
        }

        this.teamActivityheaders.push(tasks[taskKey][activityKeys[i]]);
        this.displayedColumns.push(tasks[taskKey][activityKeys[i]].projectTeamActivityId)
      }
    }
  }

  getActivityStatus(investorActivities: TeamActivityDto[], projectTeamActivityId: string): string {
    const investorActivity = investorActivities.find(x => x.projectTeamActivityId == projectTeamActivityId);
    return investorActivity ? investorActivity.status : '';
  }

  buildDynamicColumns(votingItems: VotingItemDto[]){
    // Push header-row-first-group twice for the Investor Name column
    // to apply the same design as the Responsibility column
    this.groupheaders.push('header-row-first-group');
    this.groupheaders.push('header-row-first-group');

    this.groupheaders.push('header-row-second-group');
    votingItems.forEach(votingItem => {
      let grpName: string = 'header-row-' + votingItem.itemId + '-group';
      let rec = new FrontBackOfficeResolutionGroup();
      rec.itemId = votingItem.itemId.toString();
      rec.displayField1 = votingItem.templateProperty1;
      rec.displayField2 = votingItem.templateProperty2;
      rec.fullDescription = this.projectType == ProjectTemplateTypes.ShareHolderMeeting ? `${rec.displayField1} ${rec.displayField2}` : rec.displayField1;
      rec.headerGroupName = grpName;
      this.groupheaders.push(grpName);
      this.resolutiongroupheaders.push(rec);
      this.buildFrontOfficeDynamicCol(rec.itemId);
      this.buildBackOfficeDynamicCol(rec.itemId);
    });
  }

  buildFrontOfficeDynamicCol(itemId: string){
    this.displayedColumns.push('front_resol_' + itemId);
    let coldef = new DynamicColumnDefinition();
    coldef.colName = 'front_resol_' + itemId;
    coldef.colColorHeaderName = 'front_color_' + itemId;
    coldef.colHeaderNameFirstLine = 'Front Office';
    coldef.colHeaderNameSecondLine = 'Intention';
    this.displayDynamicColumns.push(coldef);
  }

  buildBackOfficeDynamicCol(itemId: string) {
    this.displayedColumns.push('back_resol_' + itemId);
    let coldefBack = new DynamicColumnDefinition();
    coldefBack.colName = 'back_resol_' + itemId;
    coldefBack.colColorHeaderName = 'back_color_' + itemId;

    if (this.projectType === ProjectTemplateTypes.CorporateAction ) {

      coldefBack.colHeaderNameFirstLine = 'Back Office';
      coldefBack.colHeaderNameSecondLine = 'Outcome';
    }
    else {
      coldefBack.colHeaderNameFirstLine = 'Back Office';
      coldefBack.colHeaderNameSecondLine = 'Voted';
    }
    this.displayDynamicColumns.push(coldefBack);
  }

  getResolutionIntentionVotes(arr: DashboardProjectInvestorAU[], teamActivities: TeamActivityDto[]): DashboardProjectInvestorAU[] {
    let dashboardprjinvaus: DashboardProjectInvestorAU[] = [];
    let dashboardProjectInvestorAU: DashboardProjectInvestorAU;
    arr.forEach((element, i) => {
      let childRows: DashboardProjectInvestorAU[] = [];
      dashboardProjectInvestorAU = new DashboardProjectInvestorAU();
      //we are assigning alternate rows here to be able to keep the same color across split vote rows
      dashboardProjectInvestorAU.isAlternateRow = i % 2 == 1;
      dashboardProjectInvestorAU.projectId = element.projectId;
      dashboardProjectInvestorAU.investorId = element.investorId;
      dashboardProjectInvestorAU.responsibility = element.responsibility;
      const locationAddon = element.location ? ' (' + element.location + ')': '';
      dashboardProjectInvestorAU.investorName = `${element.investorName}${locationAddon}`;
      dashboardProjectInvestorAU.shareholdingAsOf = element.shareholdingAsOf;
      dashboardProjectInvestorAU.shareholding = element.shareholding;
      dashboardProjectInvestorAU.usageType = element.usageType;
      dashboardProjectInvestorAU.percentofISC = element.percentofISC;
      dashboardProjectInvestorAU.lastCallLog = element.lastCallLog;
      dashboardProjectInvestorAU.proxyAdvisors = element.proxyAdvisors;
      dashboardProjectInvestorAU.esg = element.esg;
      dashboardProjectInvestorAU.votingPlatform = element.votingPlatform === 'None' ? '' : element.votingPlatform;
      dashboardProjectInvestorAU.decisionMakers = element.decisionMakers;
      dashboardProjectInvestorAU.teamActivities = teamActivities
      .filter(u => u.projectInvestorDataId == element.projectInvestorDataId);
      element.itemVotes.forEach(element2 => {
        let resolNo = element2.itemId;

        let foColor = this.typeColors.find(x => element2.frontOfficeIntentionType && x.type.toUpperCase() === element2.frontOfficeIntentionType.toUpperCase() || x.type === "DEFAULT").color;
        let boColor = this.typeColors.find(x => element2.backOfficeVotedType && x.type.toUpperCase() === element2.backOfficeVotedType.toUpperCase() || x.type === "DEFAULT").color;

        if (dashboardProjectInvestorAU['front_resol_' + resolNo] != undefined) {
          var unfilledChildRows = childRows.filter(x => x['front_resol_' + resolNo] == undefined);
          if (unfilledChildRows.length > 0) {
            unfilledChildRows[0]['front_color_' + resolNo] = foColor;
            unfilledChildRows[0]['front_resol_' + resolNo] = element2.frontOfficeIntention;
            unfilledChildRows[0]['back_color_' + resolNo] = boColor;
            unfilledChildRows[0]['back_resol_' + resolNo] = element2.backOfficeVoted;
          } else {
            let newRow = new DashboardProjectInvestorAU();
            newRow.isAlternateRow = dashboardProjectInvestorAU.isAlternateRow;
            newRow.isChildRow = true;
            newRow.projectId = element.projectId;
            newRow.investorId = element.investorId;
            newRow['front_color_' + resolNo] = foColor;
            newRow['front_resol_' + resolNo] = element2.frontOfficeIntention;
            newRow['back_color_' + resolNo] = boColor;
            newRow['back_resol_' + resolNo] = element2.backOfficeVoted;
            childRows.push(newRow)
          }
        } else {
          dashboardProjectInvestorAU['front_color_' + resolNo] = foColor;
          dashboardProjectInvestorAU['front_resol_' + resolNo] = element2.frontOfficeIntention;
          dashboardProjectInvestorAU['back_color_' + resolNo] = boColor;
          dashboardProjectInvestorAU['back_resol_' + resolNo] = element2.backOfficeVoted;
        }
      });
      dashboardprjinvaus.push(dashboardProjectInvestorAU);
      dashboardprjinvaus.push(...childRows);
    });

    return dashboardprjinvaus;
  }

  buildProxyAdvisors(element: DashboardProjectInvestorAU) {
    if (!element.proxyAdvisors || element.proxyAdvisors.length === 0) {
      return '';
    }

    return element.proxyAdvisors.reduce((results, _proxyAdvisor) => {
      if (_proxyAdvisor.name === 'None') {
        return results;
      }
      const dependencyDisplay = _proxyAdvisor.dependency.toUpperCase() === 'N/A' ? '' : `(${_proxyAdvisor.dependency})`;
      results.push(`${_proxyAdvisor.name} ${dependencyDisplay}`.trim());
      return results;
    }, []).join(', ');
  }
  scroll() {
    this.topScrollBar.nativeElement.addEventListener('scroll', () => {
      if (this.showScrollBar()) {
          this.topScrollbarWidth = this.bottomScrollBar.nativeElement.scrollWidth + 'px';
          this.bottomScrollBar.nativeElement.scrollLeft = this.topScrollBar.nativeElement.scrollLeft;
      }
    });
    this.bottomScrollBar.nativeElement.addEventListener('scroll', () => {
      if (this.showScrollBar()) {
            this.topScrollbarWidth = this.bottomScrollBar.nativeElement.scrollWidth + 'px';
            this.topScrollBar.nativeElement.scrollLeft = this.bottomScrollBar.nativeElement.scrollLeft;
        }
      });

  }
  showScrollBar(): boolean {
    return this.showTopScrollBar=this.bottomScrollBar.nativeElement.scrollWidth > this.bottomScrollBar.nativeElement.offsetWidth;

  }
  ngOnDestroy() {
    this.bottomScrollBar.nativeElement.removeAllListeners();
    this.topScrollBar.nativeElement.removeAllListeners();
  }
}

export class DynamicColumnDefinition {
  colName: string;
  colHeaderNameFirstLine: string;
  colHeaderNameSecondLine: string;
  colColorHeaderName: string;
}

export class FrontBackOfficeResolutionGroup {
  itemId: string;
  displayField1: string;
  displayField2: string;
  fullDescription: string;
  headerGroupName: string;
}
