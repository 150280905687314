import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SearchGridService} from 'src/app/service/search-grid/search-grid.service';
import {MatDialog, MatPaginator, MatTableDataSource} from '@angular/material';
import {HttpClient} from '@angular/common/http';
import {FilterDto} from 'src/app/service/search-grid/SearchDto';
import { AddGlobalCustodianLinkDialogComponent } from './add-global-custodian-link-dialog/add-global-custodian-link-dialog.component';
import { LocalCustodiansService } from '@app/services/admin/local-custodians.service';
import { AlertService } from '@app/services/alert.service';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';

@Component({
  selector: 'app-local-custodians-links',
  templateUrl: './local-custodians-links.component.html',
  styleUrls: ['./local-custodians-links.component.scss']
})
export class LocalCustodiansLinksComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'dtcCode', 'country', 'subAccount', 'notes', 'actions'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  localCustodianId: number;
  dataSource = new MatTableDataSource();
  searchService: SearchGridService;


  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private localCustodiansService: LocalCustodiansService,
    private alertService: AlertService,
    private dialogsService: DialogsService,) {

  }

  ngOnInit() {
    this.route.parent.params.subscribe((params) => {
      this.localCustodianId = +params['id'];

      this.searchService = new SearchGridService(
        this.paginator,
        this.dataSource,
        this.httpClient,
        'linkedlocalcustodians/linked-global-to-local-custodians',
        null,
        new FilterDto({ filterPorp: 'localCustodianId', value: [this.localCustodianId.toString()] }),
      );
    });
  }

  addGlobalCustodians() {
    const dialogRef = this.dialog.open(AddGlobalCustodianLinkDialogComponent, {
      data: {
        localCustodianId: this.localCustodianId
      },
      width: '600px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.searchService.reloadItems();
    });
  }

  onEdit(element){
    const dialogRef = this.dialog.open(AddGlobalCustodianLinkDialogComponent, {
      data: {
        model: element
      },
      width: '600px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.searchService.reloadItems();
    });
  }

  onDeleteBtnClick(element){
    this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: 'Global Custodian', message: 'Global Custodian' }) });
    this.dialogsService.close$.subscribe(res => {
      if (res) {
        this.localCustodiansService.deleteLinkedGlobalCustodian(element.linkedLocalCustodianId).subscribe(data => {
          this.alertService.sendSuccess(`Global custodian has been unlinked successfully!`);
          this.searchService.reloadItems();
        },
        err => {
          const message = this.alertService.transformError(err.error || { Message: 'Unable to delete link.' });
          this.alertService.sendError(message);
        })
      }
    });
    
  }

  ngOnDestroy(): void {
    this.searchService.unSubscribe();
  }
}
