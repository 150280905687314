import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FilterContainerOptionsDTO, SelectOptions } from '@app/components/table-filter-wrapper/interfaces';
import { isObservable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterFormComponent } from '@app/components/table-filter-wrapper/components/filter-form-component/filter-form-component';
import { FilterOperators } from '@app/components/table-filter-wrapper/enums';

@Component({
  selector: 'app-multiselect-filter-form',
  templateUrl: './multiselect-filter-form.component.html',
  styleUrls: ['./multiselect-filter-form.component.scss']
})
export class MultiselectFilterFormComponent extends FilterFormComponent {
  checkboxes: SelectOptions[] = [];
  optionsObservable$;
  constructor(fb: FormBuilder) {
    super(fb);
  }

  public setDefault(options?: FilterContainerOptionsDTO): FormGroup {
    if (options && options.options) {
      if ( isObservable(options.options) ) {
        this.optionsObservable$ = options.options;
        this.optionsObservable$.pipe(takeUntil(this.unsubscribe$)).subscribe(checkboxes => {
          this.checkboxes = checkboxes;

          this.formGroup = this.setFormGroup(options);
        });
      } else {
        this.checkboxes = options.options;
      }
    }


    return this.setFormGroup(options);
  }

  private setFormGroup(options?: FilterContainerOptionsDTO) {
    const defaultMultiselect = ((options.defaultSearch || []).find(filter => filter.operator === FilterOperators.IN) || {value: []}).value;
    return this.fb.group({
      checkboxes: this.fb.array(this.checkboxes.map((checkbox) => defaultMultiselect.findIndex(option => option === checkbox.value) > -1))
    });
  }


  public get values(): any {
    const checkboxControl = (this.formGroup.controls.checkboxes as FormArray);
    return checkboxControl.value
      .map((value, i) => value ? this.checkboxes[i].value : null)
      .filter(value => value !== null);
  }
}
