import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTable, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
// tslint:disable-next-line:max-line-length
import { CustodianVotesImportDialogService } from '@app/dashboard/dashboardau/custodian-votes-import-dialog/custodian-votes-import-dialog.service';
import {
  ICustodianVotesImportFile,
  IFile
} from '@app/dashboard/dashboardau/custodian-votes-import-dialog/custodian-votes-import-file.interface';
import { DashboardService } from '@app/services/dashboard';
import { first } from 'rxjs/operators';
import { ListAdapter } from '@app/dto/base/list.interface';
import { Observable } from 'rxjs';
import { CustodianVotesImportResultDlgComponent } from './custodian-votes-import-result-dlg/custodian-votes-import-result-dlg.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-custodian-votes-import-dialog',
  templateUrl: './custodian-votes-import-dialog.component.html',
  styleUrls: ['./custodian-votes-import-dialog.component.scss']
})
export class CustodianVotesImportDialogComponent implements OnInit {
  dataSource = new MatTableDataSource();
  files = new Set();
  displayedColumns: string[] = ['fileName', 'fileSource'];
  formGroup: FormGroup = new FormGroup({});
  public itemList: FormArray;
  @ViewChild(MatTable) table: MatTable<any>;
  formValid = false;
  sourceList: ListAdapter[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly dashboardService: DashboardService,
    private readonly custodianVotesImportDialogService: CustodianVotesImportDialogService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.dashboardService.getSourceList().pipe(first()).subscribe(data => this.sourceList = data);
    this.custodianVotesImportDialogService.getAll();
    this.custodianVotesImportDialogService.files$.subscribe(result => {
      this.dataSource.data = result;
      this.formValid = !!(!result.find(item => !item.fileSource) && result.length);
    });

  }

  public readUrl(event) {
    const input = event.target;
    const files: IFile[] = Array.from(input.files);
    files.map(file => this.files.add(file));
    files.forEach(file => {
      this.custodianVotesImportDialogService.add({
        index: this.getRandomIndex(),
        fileName: file.name,
        fileSource: this.findFileSource(file.name),
        file: file
      } as ICustodianVotesImportFile);
    });
  }

  findFileSource(filename) {
    filename = filename.substring(0, filename.lastIndexOf('.')) || filename;

    return this.sourceList.find(item => !!filename.match(new RegExp(`${item.value.toLowerCase()}$`, 'gmi')));
  }

  public dragOver(event): void {
    if (event.dataTransfer.items.length > 0) {
      document.querySelectorAll('.drag-n-drop').item(0).classList.add('over');
    }
  }

  public dragOut(event): void {
    if (event.dataTransfer.items.length > 0) {
      document.querySelectorAll('.drag-n-drop').item(0).classList.remove('over');
    }
  }

  deleteItem(index: number) {
    this.custodianVotesImportDialogService.remove(index);
  }

  getRandomIndex = () => {
    return new Date().valueOf() + Math.floor((Math.random()) * (9999 - 99)) + 99;
  }

  doCheck() {
    this.custodianVotesImportDialogService.getAll();
  }

  upload() {
    const { data } = this.dataSource;
    const { projectId } = this.data;

    let uploadTasks$ = data.map((fileData: any) => {
      const { file, fileName, fileSource: { id: auFileSourceId } } = fileData;
      return this.dashboardService.investorVotesFileUpload(projectId, fileName, auFileSourceId, file);
    });

    this.spinner.show();
    Observable.forkJoin(uploadTasks$).subscribe(results => {
      this.spinner.hide();
      this.dialog.open(CustodianVotesImportResultDlgComponent, {
        data: results,
        maxHeight: 'calc(100vh - 90px)',
        height: 'auto',
        width: '90%',
        disableClose: true,
      });

      this.data.onComplete && this.data.onComplete();
      this.custodianVotesImportDialogService.clear();
    }, err => {
      this.spinner.hide();
    });
  }
}
