import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DialogsService} from '@app/services/dialogs/dialogs.service';
import {DialogsGeneralInput} from '@app/services/dialogs/dialogs.dto';
import {DialogsEnums} from '@app/services/dialogs/dialogs.enums';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-general-dialogs',
  templateUrl: './dialogs.component.template.html',
  styleUrls: ['./dialogs.component.scss']
})
export class DialogsComponent implements OnInit {
  types = DialogsEnums;
  config: DialogsGeneralInput;

  constructor(private dialogRef: MatDialogRef<DialogsService>,
              @Inject(MAT_DIALOG_DATA) public data: DialogsGeneralInput,
              private sanitizer: DomSanitizer) {

    if (data.config && data.config.template) {
      data = {...data, ...data.config.template};
    }
    data.content = this.sanitizer.bypassSecurityTrustHtml(data.message)
    this.config = data;
  }

  ngOnInit() {
  }

  onBtnClick(result) {
    this.dialogRef.close(result);
  }
}
