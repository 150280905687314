import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';

import {MatSort, MatTableDataSource} from '@angular/material';

@Component({
    selector: 'app-contact-conversationlog',
    templateUrl: './contact-conversationlog.component.html'
})
export class ContactConversationLogComponent implements OnInit, OnDestroy {
    displayedColumns: string[] = ['Client', 'Type', 'Country', 'Year', 'Participation', 'Intention', 'Feedback'];

    @ViewChild(MatSort) sort: MatSort;
    dataSource = new MatTableDataSource();

    constructor(private activeroute: ActivatedRoute, private httpClient: HttpClient) {
    }

    ngOnInit() {

    }

    ngOnDestroy() {
    }
}
