import {Shareholdings} from '@app/dto/ProjectCustodialListDto';
import { ValuesLookup } from './OptionsPicker';

export class ProjectCustodianDto {
  projectCustodianId?:number;
  custodianId?: number;
  name:string;
  projectId: number;
  isGlobalCustodian: boolean;
  parentCustodianId: number;
  parentCustodian:string;
  parentCustodianCode?: string | null;
  shareCapitals: Shareholdings[];
  codes: ValuesLookup[];
  custodianIdentifierId?: null | number = null;
  custodianCountry?: null | string = null;

  public constructor(init?: Partial<ProjectCustodianDto>) {
    Object.assign(this, init);
  }
}
