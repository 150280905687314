import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { ContactGridDTO } from '@app/dto/ContactGridDTO';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ContactRelatedEntityType } from '@app/enums/ContactRelatedEntityTypeEnum';
import { RelatedEntityData } from '@app/contact/contactdetail/contact-general/relatedEntityData';
import { ContactDlgComponent } from '@app/contact/contactdetail/contact-dlg.component';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { ContactType } from '@app/enums/ContactTypeEnum';

@Component({
  selector: 'app-contacts-grid-view',
  templateUrl: './contacts-grid-view.component.html',
  styleUrls: ['./contacts-grid-view.component.scss']
})
export class ContactsGridViewComponent implements OnInit {
  displayedContactColumns: string[] = ['name', 'legalEntity', 'title', 'contactType', 'areaOfResponsibility', 'country', 'DirectPhoneNo', 'emailPersonal'];
  dataSourceContact = new MatTableDataSource();
  contacts: ContactGridDTO[] = [];
  hasPermission: boolean;
  @Input() mappingId: number;
  @Input() contactType: ContactType;
  @Input() contactRelatedEntityType: ContactRelatedEntityType;
  @Input() entity: string;
  noItemsText:string = 'Contacts';
  showGrid: boolean;
  isReadOnlyMode: boolean =false;

  constructor(
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private roleGuardService: RoleGuardService
  ) { }

  ngOnInit() {
    this.getContacts();
    if (this.contactRelatedEntityType == ContactRelatedEntityType.Investor || this.contactRelatedEntityType == ContactRelatedEntityType.Client) {
      this.hasPermission = this.roleGuardService.hasAccess('CONTACTS', 'CREATE');
    } else {
      this.hasPermission = this.roleGuardService.hasAccess('ADMIN', 'CONTACTS');
    }
  }

  getContacts() {
    let apiURL: string = environment.serverUrl + 'contacts/contacts-by-type/' + this.contactRelatedEntityType + '/' + this.mappingId;
    this.httpClient.get<ContactGridDTO[]>(apiURL).subscribe(

      data => {
        this.contacts = data;
        this.dataSourceContact.data = this.contacts;
        this.showGrid = data.length > 0;
      },
      err => console.error(err)
    );
  }
  onAddContactBtnClick() {    
    const dialogData = new RelatedEntityData();
    dialogData.legalEntity = this.entity;
    dialogData.mappingId = this.mappingId;
    dialogData.relatedEntityTypeId = this.contactRelatedEntityType;
    dialogData.contactTypeId = this.contactType;
    dialogData.isAdd = true;
    const dialogRef = this.dialog.open(ContactDlgComponent
      , {
        data: dialogData,
        width: '1300px',
        maxHeight: 'fit-content'
      });

    dialogRef.afterClosed().subscribe(result => this.getContacts());
  }

  onEditContactBtnClick(data: ContactGridDTO) {
    const dialogData = new RelatedEntityData();
    dialogData.contactId = data.contactId;
    dialogData.mappingId = this.mappingId;
    dialogData.relatedEntityTypeId = this.contactRelatedEntityType;
    dialogData.contactTypeId = this.contactType;
    dialogData.isAdd = false;
    dialogData.isReadOnlyMode = !this.hasPermission;

    const dialogRef = this.dialog.open(ContactDlgComponent
      , {
        data: dialogData,
        width: '1300px',
        maxHeight: 'fit-content'
      });
    dialogRef.afterClosed().subscribe(result => this.getContacts());
  }

  onRefreshContactBtnClick() {
    this.refreshContacts(this.mappingId);
  }

  refreshContacts(id: number) {
    this.getContacts();
  }

}
