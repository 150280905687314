import { ActiveDirectoryUser } from "./ActiveDirectoryUser";
import { InvestorShareCapitalItem } from "./ProjectInvestorAUData";

export class ProjectInvestorDataAddDto{
    projectId:number;
    investorId:number;
    investor:string;
    city:string;
    responsibilityUser: ActiveDirectoryUser;
    investorShareCapitalItems: InvestorShareCapitalItem[];
    status?: string;

    public constructor(init?:Partial<ProjectInvestorDataAddDto>) {
        Object.assign(this, init);
    }
}
