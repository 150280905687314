import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationTriggerService {
  private _showSubMenu$ = new BehaviorSubject<any>(false);
  public shouldShowSubMenu = (): Observable<any> => this._showSubMenu$.asObservable();
  public setSubMenu = (data: any) => this.setIt(data);
  private setIt = (data: any) => this._showSubMenu$.next(data);
}
