import { FilterContainerType } from '@app/components/table-filter-wrapper/enums';
import { FilterContainerOptionsDTO } from '@app/components/table-filter-wrapper/interfaces';

export const noboFilters = {
  investorName: new FilterContainerOptionsDTO({
    type: FilterContainerType.TEXT,
    fieldName: 'investorName',
    title: 'Filter by Investor Name'
  }),
  address: new FilterContainerOptionsDTO({
    type: FilterContainerType.TEXT,
    fieldName: 'address',
    title: 'Filter by Address'
  }),
  contact: new FilterContainerOptionsDTO({
    type: FilterContainerType.TEXT,
    fieldName: 'contactSearch',
    title: 'Filter by Contact'
  }),
  controlNumber: new FilterContainerOptionsDTO({
    type: FilterContainerType.TEXT,
    fieldName: 'controlNumber',
    title: 'Filter by Control Number'
  }),
  cusip: new FilterContainerOptionsDTO({
    type: FilterContainerType.MULTISELECT,
    fieldName: 'cusip',
    title: 'Filter by Cusip',
    options: []
  }),
  voteStatus: new FilterContainerOptionsDTO({
    type: FilterContainerType.MULTISELECT,
    fieldName: 'isVoted',
    title: 'Filter by Vote Status',
    options: []
  }),
  investorType: new FilterContainerOptionsDTO({
    type: FilterContainerType.MULTISELECT,
    fieldName: 'investorTypeId',
    title: 'Filter by Investor Type',
    options: []
  }),
  source: new FilterContainerOptionsDTO({
    type: FilterContainerType.MULTISELECT,
    fieldName: 'sourceId',
    title: 'Filter by Source',
    options: []
  }),
  howVoted: new FilterContainerOptionsDTO({
    type: FilterContainerType.MULTISELECT,
    fieldName: 'voteTypeId',
    title: 'Filter by How Voted',
    options: []
  }),
  emailMaterials: new FilterContainerOptionsDTO({
    type: FilterContainerType.MULTISELECT,
    fieldName: 'emailMaterialStatusString',
    title: 'Filter by Email Materials',
    options: []
  }),
  shares: new FilterContainerOptionsDTO({
    type: FilterContainerType.NUMBER_RANGE,
    fieldName: 'shares',
    title: 'Filter by Shares'
  }),
  liveVote: new FilterContainerOptionsDTO({
    type: FilterContainerType.DATE_RANGE_MULTISELECT,
    fieldName: 'votedDate',
    title: 'Filter by Live Vote',
    options: [
      {
        value: '0001-01-01T00:00:00Z',
        name: 'No'
      }
    ]
  })
};
