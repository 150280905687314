import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatIconRegistry, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '@app/services/alert.service';
import { CustodianReportService } from '@app/services/dashboard/custodian-report.service';
import { CustodianReportDTO } from './CustodianReportDTO';
import * as FileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardNoborhService } from '@app/services/dashboard';

@Component({
  selector: 'app-custodian-report-dialog',
  templateUrl: './custodian-report-dialog.component.html',
  styleUrls: ['./custodian-report-dialog.component.scss']
})

export class CustodianReportDialogComponent implements OnInit {

  dailySummary = 'Daily Summary Report';
  custodianSummary = 'Custodian Summary Report';
  retailReport = 'Retail Report';
  reportTypes = [this.dailySummary, this.custodianSummary, this.retailReport];

  model: CustodianReportDTO = new CustodianReportDTO();

  constructor(private dialogRef: MatDialogRef<CustodianReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private custodianReportService: CustodianReportService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService,
    private dashboardNoborhService: DashboardNoborhService) {
    this.matIconRegistry.addSvgIcon(
      'icon_calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/navigation-icons/calendar.svg')
    );
  }

  ngOnInit() {
    this.model.projectId = this.data.projectId;
    this.model.reportType = this.dailySummary;
  }

  onCancel() {
    this.dialogRef.close();
  }

  onGenerate() {
    if (!this.checkDateRange(this.model)) {
      return;
    }

    this.formatReportDates();

    let reportGenerator;
    switch (this.model.reportType) {
      case this.dailySummary:
        reportGenerator = this.custodianReportService.generateDailySummaryReport(this.model);
        break;
      case this.custodianSummary:
        reportGenerator = this.custodianReportService.generateSummaryReport(this.model);
        break;

      case this.retailReport:
        reportGenerator = this.dashboardNoborhService.generateNoborhSummaryReport(this.model);
    }

    this.spinnerService.show();
    reportGenerator.subscribe(data => {
      this.spinnerService.hide();
      this.dialogRef.close();

      const contentDisposition = data.headers.get('content-disposition');
      const fileName = contentDisposition.split(';')[1].trim().split('=')[1].replace(/["']/g, "");

      FileSaver.saveAs(data.body, `${fileName}.xlsx`);
    }, error => {
      this.alertService.sendError(`Error: ${error.message}`);
    })
  }

  checkDateRange(item: CustodianReportDTO) {
    if (item.startDate != null && item.endDate != null &&
      new Date(item.startDate) > new Date(item.endDate)) {
      this.alertService.sendError('Start date can not be greater than end date!');
      return false;
    }

    return true;
  }

  formatReportDates() {
    const dates = this.serializeDates([this.model.startDate, this.model.endDate]);
    this.model.startDate = dates[0];
    this.model.endDate = dates[1];
  }

  private serializeDates(dates: any[]): Date[] {
    const from = dates[0] ? new Date(dates[0]) : null;
    const to = dates[1] ? new Date(dates[1]) : null;

    if (from) {
      from.setHours(0, 0, 0, 0);
    }

    if (to) {
      to.setHours(23, 59, 59);
    }

    return [this.convertDate(from), this.convertDate(to)];
  }

  private convertDate(date: Date): Date {
    if (date === null) {
      return null;
    } else {
      return new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds()
        )
      );
    }
  }
}
