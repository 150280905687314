import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { AuditEntityParentType } from '@app/enums/AuditEntityParentTypeEnum';

@Component({
  selector: 'app-dashboard-audit-logs',
  templateUrl: './dashboard-audit-logs.component.html',
  styleUrls: ['dashboard-audit-logs.component.scss']
})

export class DashboardAuditLogsComponent implements OnInit {

  @Input() data: any;
  params = {
    projectId: 0,
    investorId: 0,
    auditEntityParentType: AuditEntityParentType.PROJECT_INVESTOR_DATA
  };

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.params = {
        projectId: +params['id'],
        investorId: +params['invid'],
        auditEntityParentType: AuditEntityParentType.PROJECT_INVESTOR_DATA
      };
    });
  }

  ngOnInit() {

  }
}
