import { Component, OnInit } from '@angular/core';
import { FilterFormComponent } from '@app/components/table-filter-wrapper/components/filter-form-component/filter-form-component';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterContainerOptionsDTO, SelectOptions } from '@app/components/table-filter-wrapper/interfaces';
import { isObservable } from 'rxjs';
import { FilterContainerType, FilterOperators } from '@app/components/table-filter-wrapper/enums';


@Component({
  selector: 'app-number-range-filter-form',
  templateUrl: './number-range-filter-form.component.html',
  styleUrls: ['./number-range-filter-form.component.scss']
})
export class NumberRangeFilterFormComponent extends FilterFormComponent {
  checkboxes: SelectOptions[] = [];
  type: FilterContainerType;
  constructor(fb: FormBuilder) {
    super(fb);
  }

  public setDefault( options?:  FilterContainerOptionsDTO): FormGroup {
    this.type = options.type;
    if (options && options.options) {
      if ( !isObservable(options.options) ) {
        this.checkboxes = options.options;
      }
    }

    const defaultDateRange = ((options.defaultSearch || []).find(filter => filter.operator === FilterOperators.RANGE) || {value: []}).value;
    const defaultMultiselect = ((options.defaultSearch || []).find(filter => filter.operator === FilterOperators.IN) || {value: []}).value;

    const formControl = this.fb.group({
      range: this.fb.group({
        from: this.fb.control(defaultDateRange[0]),
        to: this.fb.control(defaultDateRange[1]),
      }, { validator: [this.validateGroup()] }),
      checkboxes: this.fb.array(
        this.checkboxes.map((checkbox) => defaultMultiselect.findIndex(option => option === checkbox.value) > -1)
      )
    });

    formControl.get('range').valueChanges
      .subscribe(value => {
          if (value.from) {
            formControl.get('range').get('to').setValidators(Validators.min(value.from));
          } else {
            formControl.get('range').get('to').clearValidators();
          }
        }
      );

    return formControl;
  }

  public validateGroup(): any {
    return (formGroup: FormGroup) => {
      const { from, to } = formGroup.controls;

      if (!from.value && !to.value && !this.checkboxes.length) {
        from.setErrors({ groupValueRequired: true });
        to.setErrors({ groupValueRequired: true });
      } else {
        from.setErrors(null);
        to.setErrors(null);
      }
    };
  }

  public get values(): any {
    const checkboxControl = (this.formGroup.controls.checkboxes as FormArray);
    const { from, to } =  this.formGroup.get('range').value;

    let result = [from, to];
    const isDateRangeMultiSelect = this.type === FilterContainerType.DATE_RANGE_MULTISELECT;
    if (isDateRangeMultiSelect) {
      result = [result];
    }
    const selects = checkboxControl.value.reduce((prev, next, i) => [...prev, ...(next ? [this.checkboxes[i].value] : [])], []);
    if (selects.length && isDateRangeMultiSelect) {
      result.push(selects);
    }
    return result;
  }
}
