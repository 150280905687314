import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentTableModel } from '@app/dto/documentTableModel';
import { MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import * as FileSaver from 'file-saver';
import { AlertService } from '@app/services/alert.service';
import { DocumentRelatedEntityType } from '../../enums/DocumentRelatedEntityType';
import { DocumentsService } from '@app/services/documents.service';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { LoadFileDialogComponent } from './load-file-dialog/load-file-dialog.component';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import { DocumentConfiguration } from './document-configuration';
import { UserService } from '@app/services/user.service';
import { DocumentDto } from './DocumentDto';
import { Observable, concat } from 'rxjs';
import { DocumentUploadType } from './DocumentUploadType';
import {LookupFilter, Utils} from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['documents.component.css']
})

export class DocumentsComponent implements OnInit {
    permissionSection: string;
    @Input() documentEntityType: DocumentRelatedEntityType;
    @Input() relatedEntityId: number;
    @Input() canEdit: boolean;
    @Input() canView: boolean;
    @Input() configuration: DocumentConfiguration = new DocumentConfiguration();
    @Input() shouldUseRouteParams: boolean = true;
    // used when the related entity is non created and the relatedEntityId is not present
    @Input() areDocumentsInMemory: boolean = false;
    @Input() set createdEntityId(id) {
        if (id) {
            this.flushInMemoryDocuments(id);
        }
    }
    @Output() onAllDocumentsChange: EventEmitter<any> = new EventEmitter<any>();
    inMemoryDocuments: DocumentDto[] = [];
    displayDocumentColumns: string[] = [
        'fileName',
        'description',
        'uploadDate',
        'uploadBy',
        'action'
    ];

    relatedEntityType: DocumentRelatedEntityType;
    dsDocuments: MatTableDataSource<DocumentTableModel> = new MatTableDataSource<DocumentTableModel>();
    @ViewChild(MatSort) matSort: MatSort;
    showGrid: boolean = true;
    isDocumentUploadType: boolean = true;
    util = new Utils(this.startupdataproviderService);

    constructor(private route: ActivatedRoute,
        private documentsService: DocumentsService,
        private alertService: AlertService,
        private roleGuardService: RoleGuardService,
        public dialog: MatDialog,
        private dialogsService: DialogsService,
        private userService: UserService,
        private startupdataproviderService: StartupdataproviderService  ) {
    }

    ngOnInit() {
        if (this.configuration.dialogConfiguration && this.configuration.dialogConfiguration.customField
          && this.configuration.dialogConfiguration.customField.showField) {
          let _position = 3;
          if (this.configuration.dialogConfiguration.customField.listType === LookupFilter.CLDOCTYPE) {
            _position = 1;
          }
          this.displayDocumentColumns.splice(_position, 0, 'impact');
        }
        if (this.configuration.dialogConfiguration.showLink) {
          this.displayDocumentColumns.splice(2, 0, 'link');
        }
        this.relatedEntityType = this.documentEntityType;
        if (this.shouldUseRouteParams && this.relatedEntityType == undefined) {
            this.route.params.subscribe((params) => {
                this.relatedEntityId = +params['id'];
                this.relatedEntityType = +params['relatedEntityType'];
                this.permissionSection = params.permissionSection ? params.permissionSection : 'PROJECTS';

                if (this.permissionSection === "PROJECTS") {
                    this.canEdit = this.roleGuardService.hasAccess(this.permissionSection, 'edit');
                    this.canView = this.roleGuardService.hasAccess(this.permissionSection, 'general');
                }

                if (this.permissionSection === "INVESTORS") {
                    this.canEdit = this.roleGuardService.hasAccess(this.permissionSection, '');
                    this.canView = this.roleGuardService.hasAccess(this.permissionSection, '');
                }
            });
        }

        if (this.areDocumentsInMemory) {
            this.documentsService.cleanDocumentsInMemory();
            this.showGrid = false;

            this.documentsService.inMemoryDocuments$.subscribe((inMemoryDocuments: DocumentDto[]) => {
                const tableDocuments: DocumentTableModel[] = [];

                inMemoryDocuments.forEach(inMemoryDocument => {
                    const newTableDocument = new DocumentTableModel();
                    newTableDocument.fileName = inMemoryDocument.fileName;
                    newTableDocument.description = inMemoryDocument.fileDescription;
                    newTableDocument.customField = inMemoryDocument.customField;
                    newTableDocument.link = inMemoryDocument.link;
                    newTableDocument.uploadBy = this.userService.getUserProfile().name;
                    newTableDocument.uploadDate = new Date();
                    tableDocuments.push(newTableDocument);
                });
                this.dsDocuments.data = tableDocuments;
                this.dsDocuments.sort = this.matSort;

                this.inMemoryDocuments = inMemoryDocuments;

                if (inMemoryDocuments.length > 0) {
                    this.showGrid = true;
                } else {
                    this.showGrid = false;
                }
            });
        } else {
            this.getDocuments();
        }

        if (this.configuration.uploadType === DocumentUploadType.DOCUMENT) {
            this.isDocumentUploadType = true;
        } else {
            this.isDocumentUploadType = false;
        }
    }

    flushInMemoryDocuments(id) {
        const uploadDocumentsObservables = [];
        this.inMemoryDocuments.forEach((d) => {
            const formData = this.documentsService.setDocumentProps(
                d.file,
                d.fileDescription,
                id,
                this.relatedEntityType,
                this.userService.getUserProfile(),
                d.customField,
                d.link
            );

            uploadDocumentsObservables.push(this.documentsService.uploadDocument(formData));
        })

        const documentsForUploadCount = uploadDocumentsObservables.length;
        let uploadedCount = 0;
        concat(...uploadDocumentsObservables).subscribe(result => {
            if (result) {
                ++uploadedCount;
            }
            if (documentsForUploadCount === uploadedCount) {
                this.alertService.sendSuccess('Documents Uploaded!');
            }
        }, error => {
            this.alertService.sendError('Document Upload failed!');
        });
    }

    deleteDocument(element: DocumentTableModel, index: number) {
        this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: 'Document', message: element.fileName || element.description }) });
        this.dialogsService.close$.subscribe(res => {
            if (res) {
                if (this.areDocumentsInMemory) {
                    this.documentsService.deleteDocumentInMemory(index);
                } else {
                    this.documentsService.deleteDocument(element.id, this.relatedEntityType)
                        .subscribe(data => {
                            if (data) {
                                this.getDocuments();
                                this.alertService.sendSuccess('Document deleted!');
                            } else {
                                this.alertService.sendError('Document can not be deleted!');
                            }
                        }, err => this.alertService.sendError(err));
                }
            }
        });
    }

    downloadDocument(element: DocumentTableModel, index: number): void {
        if (this.areDocumentsInMemory) {
            const document: DocumentDto = this.inMemoryDocuments[index];
            FileSaver.saveAs(document.file, element.fileName);
        } else {
            this.documentsService.downloadDocument(element.id, this.relatedEntityType).subscribe(
                response => {
                    FileSaver.saveAs(response, element.fileName);
                },
                error => {
                    this.alertService.sendError(`Error: ${error.message}`);
                }
            )
        }
    }

    public getDocuments() {
        this.documentsService.getDocuments(this.relatedEntityId, this.relatedEntityType)
            .subscribe(data => {
                this.onAllDocumentsChange.emit(data);
                this.dsDocuments.data = data;
                this.dsDocuments.sort = this.matSort;
                this.showGrid = data !== undefined && data.length > 0;
            }, err => console.log(err));
    }

    openUploadFileDialog() {
        const dialogRef = this.dialog.open(LoadFileDialogComponent, {
            data: {
                entityType: this.relatedEntityType,
                entityId: this.relatedEntityId,
                dialogConfiguration: this.configuration.dialogConfiguration,
                inMemory: this.areDocumentsInMemory
            }
        });

        dialogRef.afterClosed().subscribe(x => {
            if (!this.areDocumentsInMemory) {
                this.getDocuments();
            }
        });
    }

    goToWebSite(website : string){
        this.util.openWebSite(website);
    }
}
