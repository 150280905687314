import { Injectable } from '@angular/core';
import { FilterAndPaginateOutputDTO, IFilterAndPaginate, IRequestResults } from '@app/components/table-filter-wrapper/interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterAndPaginateService {
  public length = 0;
  public pageIndex = 0;
  public pageSize = 50;
  public filters: IFilterAndPaginate;
  public data: IRequestResults;

  constructor(
    private readonly httpClient: HttpClient
    ) { }

  list(action: string, filters: FilterAndPaginateOutputDTO): Observable<IRequestResults> {
    const url = environment.serverUrl + action;

    return this.httpClient.post<IRequestResults>(url, filters);
  }

}
