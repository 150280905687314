import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { ShareTypesAdapter } from '@app/dashboard/dashboardau/dashboard-custodian-table/custodian-details/interfaces/share-types.interface';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { ProjectsService } from '@app/services/projects.service';
import { VotingItemsService } from '@app/services/voting-items.service';
import { LookupFilter, Utils } from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { DashboardVotingSummaryService } from '@app/services/dashboard/dashboard-voting-summary.service';
import { ProjectTemplate } from '@app/dto/ProjectTemplate';
import { VotingItemDropdownDto } from '@app/dto/VotingItemDto';
import { VotingSummaryAdapter } from '@app/dashboard/dashboardau/dashboard-voting-summary/interfaces/voting-summary';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-voting-summary',
  templateUrl: './dashboard-voting-summary.component.html',
  styleUrls: ['./dashboard-voting-summary.component.scss']
})
export class DashboardVotingSummaryComponent implements OnInit {
  private $unsubscribe = new Subject<void>();
  investorTypes = [];
  dataSource: VotingSummaryAdapter[] = [];
  defaultShareType: ShareTypesAdapter = new ShareTypesAdapter({ cusip: null, shareClassification: 'Total', prjInvDataShareClassId: 0 });
  defaultInvestorType: LookupFilter;
  shareTypes: ShareTypesAdapter[] = [];
  orgShareTypes: ShareTypesAdapter[] = [];
  votingItems: VotingItemDropdownDto[] = [];
  filterForm: FormGroup = new FormGroup({
    shareType: this.fb.control(null),
    investorType: this.fb.control(null)
  });
  projectType: ProjectTemplate;
  @Input() projectId: number;
  public util = new Utils(this.startupdataproviderService);

  constructor(protected readonly fb: FormBuilder,
              private projectTemplateService: ProjectTemplateService,
              private projectsService: ProjectsService,
              private votingItemsService: VotingItemsService,
              private projectService: ProjectsService,
              private startupdataproviderService: StartupdataproviderService,
              private readonly dashboardVotingSummaryService: DashboardVotingSummaryService) { }

  ngOnInit() {

    combineLatest(
      this.projectTemplateService.getProjectTemplate(this.projectId),
      this.projectService.getProjectFinancial(this.projectId),
      this.projectsService.getProject(this.projectId, true),
      this.util.getLookupByType(LookupFilter.VOTING_SUMMARY_INVESTORS),
      this.votingItemsService.getVotingItemsDropdown(this.projectId),
      (projectType, projectFinancials, projectData, investorTypes, votingItems) => ({projectType, projectFinancials, projectData, investorTypes, votingItems})
    )
      .subscribe(pair => {
        this.votingItems = pair.votingItems;
        this.projectType = pair.projectType;
        this.orgShareTypes = pair.projectFinancials.projectFinancialDetails
          .map(item => (new ShareTypesAdapter({
            cusip: item.cusip,
            shareClassification: item.shareClassification,
            prjInvDataShareClassId: item.prjInvDataShareClassId
          })));

        // With this we make the array with only unique values
        this.shareTypes = Array.from(new Set([...this.orgShareTypes, this.defaultShareType]));
        if (pair.projectData.marketCoverage === 'US') {
          this.defaultShareType = this.shareTypes[0];
        }
        this.investorTypes = pair.investorTypes;
        this.defaultInvestorType = this.investorTypes[0];

        this.setDefault(this.defaultShareType, this.defaultInvestorType);
      });
    this.filterForm.valueChanges.subscribe(data => {
      this.doSearch(data);
    });
  }

  public setDefault(defaultShareType, defaultInvestorType): void {
    this.filterForm.setValue({
      shareType: defaultShareType,
      investorType: defaultInvestorType
    });
  }

  compareShareTypeFunction(o1: any, o2: any) {
    return (o1.shareClassification === o2.shareClassification);
  }

  compareInvestorTypeFunction(o1: any, o2: any) {
    return (o1.lookUpId === o2.lookUpId);
  }

  private doSearch(data) {
    this.dashboardVotingSummaryService.list(this.projectId, data.shareType.prjInvDataShareClassId, data.investorType.fieldValue)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe(items => {
        this.dataSource = items;
      });
  }
}
