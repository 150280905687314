export class ExternalAdvisorDto {
  externalAdvisorId: number;
  name: string;
  address: string;
  city: string;
  state: string;
  country: string;
  postalZipCode: string;
  phone: string;
  comment: string;
  advisorTypeId: number;
  advisorTypeName: string;

}
