import {Component, Input, OnInit} from '@angular/core';
import {convertPascalCaseToString, convertToStringIfBoolean} from '@app/utility/Strings';
import {isDate} from '@app/utility/Dates';
import {AuditLogConstants} from '../auditLogConstants';
import {reformatValues} from '@app/utility/auditLog';

@Component({
  selector: 'app-generic-logs-insert-segment',
  templateUrl: './generic-logs-insert-segment.component.html',
  styleUrls: ['generic-logs-insert-segment.component.scss']
})

export class GenericAuditLogsInsertSegmentComponent implements OnInit {
  private static readonly EMPTY = '( Empty )';
  _data;
  isOpen = false;
  @Input() public data: any;

  constructor() {
  }

  ngOnInit() {

    const values = this.data.ColumnValues;
    this._data = Object.keys(values).map(key => {

      values[key] = reformatValues(values[key], key);

      return {
        key: convertPascalCaseToString(key),
        value: isDate(values[key]) ? new Date(values[key]).toLocaleString() : convertToStringIfBoolean(values[key]) || GenericAuditLogsInsertSegmentComponent.EMPTY,
        isDate: (AuditLogConstants.dateColumnsFormatExceptions.indexOf(key) < 0) && isDate(values[key]),
      };
    });
  }
}
