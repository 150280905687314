import { BrokerTypeDialogComponent } from '@app/project/projectdetail/custodial-list/broker-type-dialog/broker-type-dialog.component';
import { AlertService } from './../../../services/alert.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {ProjectCustodialListDto} from '@app/dto/ProjectCustodialListDto';
import {ActivatedRoute} from '@angular/router';
import {CustodiansService} from '@app/services/custodians.service';
import {RoleGuardService} from '@app/auth/role-guard.service';
import {CustodialDialogComponent} from '@app/project/projectdetail/custodial-list/custodial-dialog/custodial-dialog.component';
import {ProjectCustodianDto} from '@app/dto/ProjectCustodianDto';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { ProjectsService } from '@app/services/projects.service';

@Component({
  selector: 'app-custodial-list',
  templateUrl: './custodial-list.component.html',
  styleUrls: ['./custodial-list.component.scss']
})
export class CustodialListComponent implements OnInit {
  showGrid = false;
  dataSource = new MatTableDataSource();
  projectId: number;
  orgDisplayedColumns: string[] = [
    'name',
    'codes',
    'type',
    'country',
  ];
  displayedColumns: string[] = [];
  displayDynamicColumns: string[] = [];
  classifications = [];
  @ViewChild(MatSort) sort: MatSort;
  canEdit = false;

  constructor(private route: ActivatedRoute,
              private roleGuardService: RoleGuardService,
              public dialog: MatDialog,
              private custodiansService: CustodiansService,
              private dialogsService: DialogsService,
              private alertService : AlertService,
              private projectService: ProjectsService) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<ProjectCustodialListDto[]>();
    this.dataSource.sort = this.sort;
    this.route.parent.params.subscribe((params) => {
      this.projectId = +params['id'];

      this.projectService.getProjectType(this.projectId).subscribe(data =>{
        this.canEdit = this.roleGuardService.hasAccessByProjectType('PROJECTS', 'EDIT', data.name);
      })
     
      if (this.projectId > 0) {
            this.getData(this.projectId);
      }
    });
  }

  getData(projectId) {
    this.custodiansService.getCustodialListByProject(projectId).subscribe(data => {
      this.showGrid = data.length > 0;
      if (this.showGrid) {
        this.classifications = this.extractUniqueShareClassifications(data);
        this.setColumns();
        this.setTableRows(data);
      } else {
        this.dataSource = new MatTableDataSource();
        this.dataSource.sort = this.sort;
        this.dataSource.data = [];
      }
    });
  }

  setColumns() {
    this.displayedColumns = Object.assign([], this.orgDisplayedColumns);
    this.displayDynamicColumns = [];

    this.classifications.forEach(classification => {
      this.displayedColumns.push(`${classification.name}`);
      this.displayDynamicColumns.push(`${classification.name}`);
    });

    this.displayedColumns.push(`action`);
  }

  setTableRows(data) {
    const tableRows = data.reduce((accumulator, item, currentIndex) => {
      const record = {
        projectCustodianId: item.projectCustodianId,
        name: item.name,
        codes: item.codes,
        type: item.isGlobalCustodian ? 'GLOBAL' : 'LOCAL',
        country: item.country,
        parentName: item.parentName,
        parentCode: item.parentCode,
        parentIdentifierId: item.parentIdentifierId,
        globalCustodian: item.globalCustodianId,
        localCustodianId: item.localCustodianId
      };

      this.classifications.forEach(classification => {
        const result = item.shareholdings.find(vote => vote.name === classification.name);
        record[`${classification.name}`] = result ? { shareCapital: result.shareCapital, childrenHoldings: result.childrenHoldings } :
         { shareCapital: 0, childrenHoldings: 0 };
      });
      accumulator.push(record);

      return accumulator;
    }, []);

    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.dataSource.data = tableRows;
  }

  onAdd() {
    const dialogRef = this.dialog.open(CustodialDialogComponent, {
      data: new ProjectCustodianDto({projectId: this.projectId, shareCapitals: []}),
      width: '600px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData(this.projectId);
      }
    });
  }

  onEdit(element) {
    const dialogRef = this.dialog.open(CustodialDialogComponent, {
      data: new ProjectCustodianDto({projectId: this.projectId,projectCustodianId: element.projectCustodianId, shareCapitals: []}),
      width: '600px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData(this.projectId);
      }
    });
  }

  onDelete(element) {
    const isGlobal = element.type =='GLOBAL' ?true : false;
    this.dialogsService.confirm(
      null,
      null,
      { template: DeleteProjectTemplate({ title: 'Custodian From List', message: element.name }) }
    );
    this.dialogsService.close$.subscribe(res => {
      if (res) {
        this.custodiansService.deleteProjectCustodian(element.projectCustodianId).subscribe(
                               data => { 
                                 if (data) {
                                  this.getData(this.projectId);
                                 }                               
                              });                   
      }});
  }

  extractUniqueShareClassifications = array => array.reduce((h, obj) => [...h, ...obj.shareholdings.map(o => ({cusip: o.cusip, name: o.name}))], [])
    .filter(function(value, index, self) {
      return self.findIndex(v => v.name === value.name) === index;
    })

    getClassificationCusip(classification: string): string{
      let cusip = this.classifications.find(c => c.name === classification).cusip;
      if(cusip){
        return `(${cusip})`
      }

      return;
    }

    onViewReport(){
      const dialogRef = this.dialog.open(BrokerTypeDialogComponent, {
        data: {projectId: this.projectId},
        width: '800px',
        maxHeight: 'fit-content'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getData(this.projectId);
        }
      });
    }
}


export class DynamicColumnDefinition {
  colName: string;
  colHeaderName: string;
}
