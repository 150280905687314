import { VotingRecommendations } from '@app/enums/voting-recommendations.enum';

export interface InvestorVotingSummaryInterface {
  projects: InvestorVotingSummaryProjects;
  proxyAdvisorsNames: string[];
  shareClassifications: string[];
}

export interface InvestorVotingSummaryProject {
  clientName: string;
  marketCoverage: string;
  projectName: string;
  sector: string;
  endDate: Date;
  votingItems: InvestorVotingSummaryProjectVotingItems[];
}

interface InvestorVotingSummaryProjects {
  items: InvestorVotingSummaryProject[];
  page: number;
  numberOfPages: number;
  totalItems: number;
}

export interface InvestorVotingSummaryProjectVotingItems {
  templateProperty1: string;
  templateProperty2: string;
  recommendations: InvestorVotingSummaryRecommendations[];
  isChild: boolean;
  votingItemId: number;
  votes: any;
}

interface InvestorVotingSummaryRecommendations {
  [key: string]: VotingRecommendations;
}

export class InvestorVotingSummaryAdapter {
  projects: InvestorVotingSummaryProject[];
  proxyAdvisorsNames: string[];
  shareClassifications: string[];
  page: number;
  numberOfPages: number;
  totalItems: number;

  public constructor(init = {
    projects: { items: [], page: 0, numberOfPages: 0, totalItems: 0 }, proxyAdvisorsNames: [], shareClassifications: []
  }) {
    this.projects = init.projects.items;
    this.proxyAdvisorsNames = init.proxyAdvisorsNames;
    this.shareClassifications = init.shareClassifications;
    this.page = init.projects.page;
    this.numberOfPages = init.projects.numberOfPages;
    this.totalItems = init.projects.totalItems;
  }
}
