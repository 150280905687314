import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../dto/User';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { UserService } from '@app/services/user.service';
import { environment } from '../../environments/environment';
import { AppConstants } from '@app/app.constants';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  appname: string = 'MSPLATFORMCRM';
  showUser: boolean;
  showContent = true;
  loggedInUser: User;
  router;
  isStaging: boolean = false;



  constructor(private route: ActivatedRoute,
              router: Router,
              iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer,
              private msadService: MsAdalAngular6Service,
              private _userService: UserService,
              ) {

    iconRegistry.addSvgIcon(
      'expand-more',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/navigation-icons/expand_more-24px.svg'));

    this.router = router;

    this.getLoggedInUser();
  }

  ngOnInit() {

    this.showUser = true;
    this.showContent = false;
    this.isStaging = AppConstants.ENVIRONMENT_STAGING_NAME == environment.name;
  }

  getLoggedInUser() {
    this.loggedInUser = new User();

    if (this.msadService.userInfo) {
      this.loggedInUser.role = this.getLoggedInUsersRole();
      this.loggedInUser.coverage = this.getLoggedInUsersCoverage();
      this.loggedInUser.aDUserName = this.msadService.LoggedInUserEmail;
      this.loggedInUser.userId = 0;
      this._userService.getUserPermissions(this.loggedInUser.role)
        .subscribe(x => {
            this.loggedInUser.permissions = x;
            this.showContent = true;
            this._userService.setLoggedInUser(this.loggedInUser);
          },
          err => {
            this.showContent = true;
          });
    }
    else {
      this.msadService.login();
    }
  }


  getLoggedInUsersRole(): string {
    return this._userService.getUserRoleName();
  }

  getLoggedInUsersCoverage(): string {
    return this._userService.getUserCoverage();
  }

  checkPermission(permission: string): boolean {
    return this._userService.checkPermissionAssigned(this.loggedInUser, permission);
  }

  onLogout() {
    this.showUser = true;
    this.showContent = false;
    this.router.navigate(['']);
    this.msadService.logout();
  }

  ngAfterViewInit() {

  }



}
