import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-contact-landing',
    templateUrl: './contact-landing.component.html',
    styleUrls: ['contact-landing.component.css']
})
export class ContactLandingComponent implements OnInit {
    index = 0;
    routeSub: any;
    showGeneral;
    showConversationLog;

    constructor(private route: ActivatedRoute, private router: Router) {
    }

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.index = +params['id'];
            this.showGeneral = true;
            this.showConversationLog = true;
        });
    }

}
