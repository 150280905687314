import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-callback'
  , templateUrl: './auth-callback.component.html'
 /* ,styleUrls: ['./auth-callback.component.css'] */
})
export class AuthCallbackComponent implements OnInit {
 
  constructor(private router: Router, private _zone: NgZone) 
  { 

  }
 
  ngOnInit() {
    alert('Calling AuthCallbackComponent');
      this._zone.run(() => {
        setTimeout(() => {
            this.router.navigate(['/home'])
        }, 200);
    });  
  }
 
}