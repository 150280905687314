import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectInvestorIntentionVoteDTO } from '@app/dto/ProjectInvestorIntentionVoteDTO';
import { ProjectInvestorShareDTO } from '@app/dto/ProjectInvestorShareDTO';
import { DashboardService } from '@app/services/dashboard';
import { Subject } from 'rxjs-compat';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-voting-intentions',
  templateUrl: './dashboard-voting-intentions.component.html',
  styleUrls: ['./dashboard-voting-intentions.component.scss']
})
export class DashboardVotingIntentionsComponent implements OnInit {
  private $unsubscribe = new Subject<void>();
  dataSource: ProjectInvestorIntentionVoteDTO;
  shares: ProjectInvestorShareDTO[];
  projectId: number;
  investorId: number;
  projectType: string;
  votingItemTypes: string[];
  isEdit: boolean = false;
  @Output() getVoteRecordsFormState = new EventEmitter<boolean>();
  validCheckArray = [];

  votesToDelete: any[];

  constructor(private dashboardService: DashboardService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.params.id;
    this.investorId = this.route.snapshot.params.invid;

    this.getIntention(this.projectId, this.investorId);
  }

  getIntention(projectId, investorId) {
    this.dashboardService.getInvestorVotingIntentions(projectId, investorId)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe(items => {
        this.dataSource = items;
        this.projectType = this.dataSource.projectType;
        this.votingItemTypes = this.dataSource.votingItemTypes;
        this.shares = this.dataSource.shares;
        this.validCheckArray = this.shares.map(_ => true);
      });
  }

  onEditBtnClick() {
    this.isEdit = true;
    this.getVoteRecordsFormState.emit(this.isEdit);
  }

  onCancelClick() {
    this.getIntention(this.projectId, this.investorId);
    this.isEdit = false;
    this.getVoteRecordsFormState.emit(this.isEdit);
  }

  onSaveBtnClick(shares) {
    this.updateVotingIntentions(shares);
    this.isEdit = false;
  }

  updateVotingIntentions(shares) {
    shares.forEach(share => {
      share.votingItems.forEach(votingItem => {
        votingItem.undeclaredIntention = votingItem.undeclaredIntention.votes;
      });
    });

    this.dataSource.shares = shares;

    this.dashboardService.editInvestorVotingIntentions(this.dataSource)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe(items => {
        this.dataSource = items;
        this.projectType = this.dataSource.projectType;
        this.votingItemTypes = this.dataSource.votingItemTypes;
        this.shares = this.dataSource.shares;
      });
  }

  isValid() {
    return this.validCheckArray.includes(false);
  }
}
