import { DocumentDialogConfiguration } from "./document-dialog-configuration";
import { DocumentUploadType } from "./DocumentUploadType";

export class DocumentConfiguration{
    gridName: string;
    columnsNames:string[];
    isPopup:boolean = true;
    documentComponentOuterDivMargins:string = 'mt-5 ml-5 mr-5';
    uploadBtnText = 'UPLOAD FILE';
    uploadBtnIconClass = 'ms-upload-white-icon';
    uploadType: DocumentUploadType = DocumentUploadType.DOCUMENT;
    dialogConfiguration: DocumentDialogConfiguration = new DocumentDialogConfiguration();
}