import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectEvent } from '@app/dto/ProjectEvent';
import { MatTableDataSource } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { Utils, LookupFilter } from '@app/helper/utils';

@Component({
  selector: 'app-key-events',
  templateUrl: './key-events.component.html',
  styleUrls: ['./key-events.component.scss']
})
export class KeyEventsComponent implements OnInit, AfterContentChecked {
  @Input() projectId = 0;
  @Input() disabled = true;
  @Input() isProjectCreation = false;
  @Input('selectedProjectStatusActive') set selectedProjectStatusActive(status: boolean) {
    this._selectedProjectStatusActive = status;
    this.setDataSource(this._events);
  }
  @Input('events') set events(items) {
    if (items && JSON.stringify(items) !== JSON.stringify(this._events)) {
      const extendedItems = this.assignInternalIndex(items);
      this._events = extendedItems.sort(function (a, b) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      this.setDataSource(this._events);
    }
  }
  @Input('selectedProjectMarketCoverage') set selectedProjectMarketCoverage(coverage: string) {
    this._selectedProjectMarketCoverage = coverage;
    this.manageDefaultKeyEvents();
  }
  @Output() eventsChange: EventEmitter<ProjectEvent[]> = new EventEmitter<ProjectEvent[]>();
  @Output() isFormValid: EventEmitter<any> = new EventEmitter<any>();

  displayedColumns: string[] = ['date', 'description', 'action'];
  dataSource = new MatTableDataSource();
  userId = 1;
  keyEventsGroup: FormGroup = new FormGroup({});
  utils: Utils;
  _events: ProjectEvent[] = [];
  _selectedProjectMarketCoverage: string;
  _selectedProjectStatusActive: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    startupdataproviderService: StartupdataproviderService) {
    this.utils = new Utils(startupdataproviderService);
  }

  ngOnInit() {
    this.keyEventsGroup.statusChanges.subscribe(valid => {
      this.isFormValid.emit(valid === 'VALID');
    });

    (<EventEmitter<any>>this.keyEventsGroup.statusChanges).emit(this.keyEventsGroup.status);
  }

  setDataSource(data) {
    this.dataSource = new MatTableDataSource<ProjectEvent[]>(data);
    this.dataSource.data = data;
    if (data) {
      data.forEach((item) => {
        if (!item.isRequired) {
          item.isRequired = !this.isProjectCreation &&
                            item.isDefault &&
                            this._selectedProjectStatusActive;
        }

        this.addFieldControl(item);
      });
    }
  }

  removeEvent(element: ProjectEvent) {
    this._events = this._events.filter(item => item.internalIndex !== element.internalIndex);
    this.dataSource.data = this._events;
    this.eventsChange.emit(this._events);

    this.removeFieldControl(element);
  }

  addNewEvent(isDefault?: boolean) {
    const newItem = new ProjectEvent({
      projectId: this.projectId,
      description: '',
      date: new Date(),
      isDefault: isDefault,
      internalIndex: this.getRandomIndex(),
      isRequired: false
    });

    this._events = [...this._events, newItem];

    this.addFieldControl(newItem);
    this.dataSource.data = this._events;
    this.eventsChange.emit(this._events);
    return newItem;
  }

  addFieldControl = (item) => {
    const _index = item.internalIndex;
    this.keyEventsGroup.addControl(`date_${_index}`, new FormControl(item.date, item.isRequired ?  Validators.required : null));
    if (!item.isDefault) {
      this.keyEventsGroup.addControl(`description_${_index}`, new FormControl(item.description, Validators.required));
    }

    this.addFieldValueChanges(item);
  }

  removeFieldControl = (item) => {
    const _index = item.internalIndex;
    this.keyEventsGroup.removeControl(`date_${_index}`);
    this.keyEventsGroup.removeControl(`description_${_index}`);
  }

  addFieldValueChanges = (item) => {
    const _index = item.internalIndex;
    this.keyEventsGroup.controls[`date_${_index}`].valueChanges.subscribe(val => {
      item.date = val;
      this.eventsChange.emit(this._events);
    });
    if (!item.isDefault) {
      this.keyEventsGroup.controls[`description_${_index}`].valueChanges.subscribe(val => {
        item.description = val;
        this.eventsChange.emit(this._events);
      });
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  assignInternalIndex = (items) => {
    return items.map(item => {
      return { ...item, ...{ internalIndex: this.getRandomIndex() } };
    });
  }

  getRandomIndex = () => {
    return new Date().valueOf() + Math.floor((Math.random()) * (9999 - 99)) + 99;
  }

  private manageDefaultKeyEvents() {
    if (!this.isProjectCreation) {
      return;
    }

    // intial state default events
    if (!this._selectedProjectMarketCoverage) {
      this.utils.getLookupByType(LookupFilter.PROJECT_DEFAULT_EVENT).subscribe(defaultEvents => {
        defaultEvents.forEach(defaultEvent => {
          const newEvent = this.addNewEvent(true);
          newEvent.description = defaultEvent.fieldLabel;
          newEvent.isDefault = true;
          newEvent.date = undefined;
          newEvent.isRequired = (newEvent.description.toLowerCase().includes('meeting date') ||
                                newEvent.description.toLowerCase().includes('record date') ||
                                newEvent.description.toLowerCase().includes('mailing date')
                                ) && this.isProjectCreation ? false : true;
        });
      });
    } else {
      const defaultEventsToRemove = this._events
        .filter(event =>
          event.isDefault
          && event.defaultEventCoverage
          && event.defaultEventCoverage !== this._selectedProjectMarketCoverage
        );
      if (defaultEventsToRemove) {
        defaultEventsToRemove.forEach(item => this.removeEvent(item));
      }
    }

    if (this._selectedProjectMarketCoverage === 'US') {
      this.utils.getLookupByType(LookupFilter.PROJECT_US_EVENT).subscribe(defaultEvents => {
        defaultEvents.forEach(defaultEvent => {
          if (!this._events.find(e => e.isDefault && e.description === defaultEvent.fieldLabel)) {
            const newEvent = this.addNewEvent(true);
            newEvent.description = defaultEvent.fieldLabel;
            newEvent.isDefault = true;
            newEvent.defaultEventCoverage = 'US';
            newEvent.date = undefined;
            newEvent.isRequired = false;
          }
        });
      });
    }
  }
}
