import { AppConstants } from "@app/app.constants";
import { isObject } from "util";

export class VoteWithPercentageEdit {
    voteId: number;
    voteType: string;
    votes: number;
    percentage: string;
  
    constructor(votes, all) {
      this.voteId = votes && isObject(votes) ? votes.voteId : 0;
      this.voteType = votes && isObject(votes) ? votes.voteType : '';
      this.votes = (votes != undefined && isObject(votes)) ?  votes.vote : "";
      this.percentage = (votes != undefined && isObject(votes)) ? this.calcPercent(this.votes, all) : "";
    }
  
    calcPercent = (item, all) => {
      return `${(item === 0 || all === 0) ? 0 : ((item / all) * 100).toFixed(AppConstants.DECIMALS_FIX)}%`;
    }
  }