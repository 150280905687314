import { ResponsibilityUserObject } from "./base/ResponsibilityUserObject";
import { BroadridgeCode } from "./BroadridgeCode";

export class LocalCustodian extends ResponsibilityUserObject {
  constructor() {
    super();
    this.isManualBrokerSearch = false;
  }

  checked: boolean;
  localCustodianId: number;
  name: string;
  address: string;
  city: string;
  country: string;
  state: string;
  zipCode: string;
  phone: string;
  website: string;
  comments: string;
  operationalProcess: string;
  managedBy: number;
  locationType: string;
  brokerVoteType: string;
  broadridgeCodes: BroadridgeCode[];
  isManualBrokerSearch: boolean;
  legacyId: string;
  reorgLocationType: string;
  reorgAddress: string;
  reorgCity: string;
  reorgState: string;
  reorgZipCode: string;
}
