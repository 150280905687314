import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { SearchGridService } from '@app/service/search-grid/search-grid.service';
import { FilterDto } from '@app/service/search-grid/SearchDto';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import { GenericEndpointSertvice } from '@app/services/generic-endpoints.service';
import { AlertService } from '@app/services/alert.service';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { Utils } from '@app/helper/utils';
import { RegionCountryMultiselectHelper } from '@app/helper/RegionCountryMultiselectHelper';

@Component({
  selector: 'app-investor-intel-common-grid',
  templateUrl: './investor-intel-common-grid.component.html',
  styleUrls: ['./investor-intel-common-grid.component.scss']
})
export class InvestorInitiativeGridComponent implements OnInit {
  @Input() additionalFilter: FilterDto | FilterDto[]
  @Input()
  displayedColumns: string[] = [];
  @Input() requestUrl: string;
  @Input() disabled: boolean;
  @Input() customTitleMapping: {} = {};
  @Input() noItemsText: string = 'There are no items';
  @Input() idKeyName: string;
  @Input() deleteUrl: string;
  @Input() deleteConfirmationTitle: string = 'Delete';
  @Input() deleteMessageMappingKey: string;
  @Output() editClicked: EventEmitter<any> = new EventEmitter<any>();
  showGrid: boolean = true;
  titleMapping = {
    name: 'NAME',
    initiativeName: 'INITIATIVE NAME',
    impact: 'IMPACT',
    exclusionImpact: 'IMPACT',
    sectorFocus: 'SECTOR FOCUS',
    thematicFocus: 'THEMATIC FOCUS',
    regionalFocus: 'REGIONAL FOCUS',
    activated: 'ACTIVE',
    parentActivated: 'ACTIVE',
    actions: 'ACTIONS'
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource();
  searchService: SearchGridService;
  pageSize: number = 10;
  resourcesFocus: string[]=[];
  groupedCountries: {};
  util = new Utils(this.startupdataproviderService);
  
  constructor(
    private httpClient: HttpClient,
    private dialogsService: DialogsService,
    private genericEndpointService: GenericEndpointSertvice,
    private alertService: AlertService,
    private startupdataproviderService: StartupdataproviderService,
  ) {

  }
  ngOnInit() {
    this.groupedCountries = this.util.getCountriesByRegionGroup();
    Object.assign(this.titleMapping, this.customTitleMapping);
    this.fetchGridData();
  }

  public fetchGridData() {
    this.searchGridData();
  }

  private searchGridData() {
    this.searchService = new SearchGridService(
      this.paginator,
      this.dataSource,
      this.httpClient,
      this.requestUrl,
      null,
      this.additionalFilter,
      this.pageSize);

    this.searchService.anyResults.subscribe(hasResults => {
      this.showGrid = hasResults;
      if (hasResults) {
        this.dataSource.data.forEach((i: any) => {
          i.regionFocuses = RegionCountryMultiselectHelper.getDisplayName(i.regionFocuses, this.groupedCountries); 
        })
      }
    })
  }

  onEdit(el) {
    this.editClicked.emit(el);
  }

  onDelete(el) {
    const id = el[this.idKeyName];
    const message = el[this.deleteMessageMappingKey];

    this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: this.deleteConfirmationTitle, message: message }) });
    this.dialogsService.close$.subscribe(res => {
      if (res) {
        this.genericEndpointService.delete(this.deleteUrl, id)
          .subscribe(data => {
            this.alertService.sendSuccess(`${message} Deleted!`);
            this.fetchGridData();
          });
      }
    });
  }
}
