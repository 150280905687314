import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ProjectInvestorDataService} from '@app/services/project-investor-data.service';
import {ProjectInvestorDataUpdateDto} from '@app/dto/ProjectInvestorDataUpdateDto';
import {AlertService} from '@app/services/alert.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-edit-investor-dialog',
  templateUrl: 'edit-investor-dialog.component.html',
  styleUrls: ['edit-investor-dialog.component.scss'],
})

export class EditInvestorDialogComponent implements OnInit {
  model: ProjectInvestorDataUpdateDto;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditInvestorDialogComponent>,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService,
    private projectInvestorDataService: ProjectInvestorDataService) {
    this.model = data;
  }

  ngOnInit() {

  }

  onCancelBtnClick() {
    this.dialogRef.close();
  }

  onSaveBtnClick() {
    this.spinnerService.show();
    this.projectInvestorDataService.updateProjectInvestorData(this.model)
      .pipe(finalize(() => {
        this.spinnerService.hide();
        this.dialogRef.close();
      }))
      .subscribe(data => {
        this.alertService.sendSuccess('Investor Data updated!');
      },
        err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        });
  }

}
