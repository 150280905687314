import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { Shareholdings } from '@app/dto/ProjectCustodialListDto';
import { ProjectMailingDocRecipientDetailedDTO } from '@app/dto/ProjectMailingDocRecipientDetailedDTO';
import { AlertService } from '@app/services/alert.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { ProjectsService } from '@app/services/projects.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-mailing-add-edit-recipient',
  templateUrl: './dashboard-mailing-doc-recipient-add-edit.component.html',
  styleUrls: ['dashboard-mailing-doc-recipient-add-edit.component.scss']
})
export class DashboardAddEditMailingDocRecipientComponent implements OnInit {
  model: ProjectMailingDocRecipientDetailedDTO;
  disableForm = true;
  isEdit  = false;
  projectId: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DashboardAddEditMailingDocRecipientComponent>,
    private dashboardService: DashboardService,
    private alertService: AlertService,
    private projectsService: ProjectsService,
  ) {
    this.model = new ProjectMailingDocRecipientDetailedDTO();
    this.model.projectId = data.projectId;
    if (data.mailingDocumentRecipientId) {
      this.model = data;
      this.isEdit = true;
    } else {
      this.model.projectId = data.projectId;
    }
  }

  ngOnInit(): void {
    this.getClassifications();
    this.getProjectData();
  }

  getProjectData(){
    this.projectsService.getProject(this.model.projectId, true).pipe(first()).subscribe(
      data => this.model.projectName = data.name,
      e => this.alertService.alert(e));
  }

  getCustodianName = (item) => {
    return item.custodianCode && item.custodianCode.length ? `${item.name} (${item.isGlobalCustodian? 'DTC':'BR'}: < ${(item.custodianCode)}>)`: `${item.name}`;
  }

  onCustodianSelected(item) {
    if (item.custodianId) {
      this.model.custodianId = item.custodianId;
      this.model.custodianCountry = item.country;
      this.model.custodianIdentifierId = item.custodianIdentifierId;
      this.model.isGlobalCustodian = item.isGlobalCustodian;
      this.model.custodianCodeId = item.codeId;
      this.model.globalCustodianDtcCode = item.codeName
    } else {
      this.model.custodianId = null;
      this.model.custodianCountry = null;
      this.model.custodianIdentifierId = null;
      this.model.custodianCodeId = null;
      this.model.globalCustodianDtcCode = null;
    }
  }

  getClassifications() {
    if(!this.isEdit){
      this.projectsService.getProjectShareClassificationsPair(this.model.projectId).subscribe(data => {
        this.model.shareCapitals = data.map( item => new Shareholdings({
          id: item.id,
          name: item.name,
          shareCapital: 0,
          cusip: item.cusip
      }));
      }, err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      });
    }
    else{
      this.dashboardService.getCusips(this.model.mailingDocumentRecipientId, this.model.projectId, this.isEdit).subscribe(data => {
        this.model.shareCapitals = data.map( item => new Shareholdings({
          id: item.id,
          name: item.name,
          shareCapital: item.shareCapital,
          cusip: item.cusip
        }));
      }, err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      });
    }
  }

  onClickSave() {
    const action = this.isEdit ? 'editMailingDocumentRecipient' : 'addMailingDocumentRecipient';
    this.dashboardService[action](this.model).subscribe(data => {
      this.alertService.sendSuccess(`Mailing document recipient ${this.isEdit ? 'updated' : 'added'}!`);
      this.dialogRef.close();
    },
      e => this.alertService.alert(e)
    );
  }

  onCloseBtnClick(): void {
    this.dialogRef.close();
  }
}
