export enum Roles {
  AU_PM = 'AU_ADMIN',
  EU_PM = 'EU_ADMIN',
  CLIENT = 'External_Client',
  SYS_ADMIN = 'MSP_SysAdmin',
  USER_EU = 'EU_User',
  SUPER_PM_EU = 'Super_PM',
  SUPER_PM_AU = 'Super_PM_AU',
  SUPER_PM_US = "US_Super_PM",
  US_USER = "US_Standard",
  US_PSC = "US_PSC"
}