import { ViewChild, Component, DoCheck, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import {
  NgModel,
  FormControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { NameValueModel } from '../../../models/basic-structures';
import { debounceTime } from 'rxjs/operators';
import { MasterDataService } from '../../../services/master-data.service';
import { BehaviorSubject } from 'rxjs';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { Utils } from '@app/helper/utils';

const noop = () => {/* */
};

@Component({
  selector: 'app-country-autocomplete',
  templateUrl: './country-autocomplete.component.html',
  styleUrls: ['./country-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryAutocompleteComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CountryAutocompleteComponent),
      multi: true
    }
  ]
})
export class CountryAutocompleteComponent implements OnInit, DoCheck, ControlValueAccessor, Validator {

  @ViewChild('currentCountryValue') public currentCountryValue: NgModel;
  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public placeholder = "Country";
  @Input() useIsoCodeAsValue = false;
  @Input() clearOnBlur = false;
  @Output() onSelectionChanged = new EventEmitter<NameValueModel>();
  filteredResults: NameValueModel[];
  preselectedValue: NameValueModel;
  selectedValue: NameValueModel;
  private innerValue: any = undefined;
  util = new Utils(this.startupdataproviderService);
  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  public countryValueChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private startupdataproviderService: StartupdataproviderService, private masterDataService: MasterDataService) {
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.onChangeCallback(v);
      this.innerValue = v;
    }
  }

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  validate(control: FormControl): ValidationErrors {
    const valid = this.currentCountryValue && this.currentCountryValue.valid;
    return valid ? null : { valid };
  }

  ngDoCheck() {
    this.onChangeCallback(this.value);
  }

  doSearch(value) {
    this.countryValueChanges.next(value);
  }

  public onTouched() {
    this.onTouchedCallback();
  }

  ngOnInit() {
    if (!this.innerValue) {
      this.innerValue = undefined;
    }
    this.initializeFilters();
  }

  initializeFilters() {
    this.countryValueChanges.pipe(
      debounceTime(500)
    ).subscribe((input) => {
      this.filteredResults = this.util.CountryAutocomplete(input, this.useIsoCodeAsValue);
    });
  }

  onSelected(selectedCountry: NameValueModel) {
    if(selectedCountry){
      this.innerValue = selectedCountry;
    }
    else{
      selectedCountry = this.innerValue;
    }
    this.onSelectionChanged.emit(selectedCountry);
  }

  onBlur(value) {
    if (this.clearOnBlur) {
      if (this.util.CountryAutocomplete(value, false).length == 0) {
        this.innerValue = undefined;
        this.onSelectionChanged.emit({ name: "", value: "" });
      }
    }
  }

  displayFn(autoCompleteResult: NameValueModel) {
    if (autoCompleteResult) {
        this.innerValue= autoCompleteResult;
        if(typeof(autoCompleteResult)==='object') {
            return autoCompleteResult.name;
        } 
        else {
          return autoCompleteResult;
        }
    }
  }
}
