import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AddEditCustomFieldComponentData} from './AddEditCustomFieldComponent';
import {AlertService} from '@app/services/alert.service';
import {CustomFieldType} from '@app/enums/CustomFieldType';
import {ResolutionClassificationCastumFieldsService} from '@app/services/resolution-classification-custom-fields.service';
import {CustomFieldOptionDto} from '@app/dto/ResolutionClassificationCustomFieldDto';
import {ClassificationOptionSourceTypeEnum} from '@app/enums/ClassificationOptionSourceTypeEnum';
import {RoleGuardService} from '@app/auth/role-guard.service';

@Component({
  selector: 'app-add-edit-custom-field',
  templateUrl: './add-edit-custom-field.component.html',
  styleUrls: ['./add-edit-custom-field.component.scss']
})
export class AddEditCustomFieldComponent implements OnInit {
  componentHeader: string;
  fieldTypes: any[] = [];
  eCustomFieldType = CustomFieldType;
  optionSources: Array<any> = [];

  optionsDisplayList: CustomFieldOptionDto[] = [];
  hasAccess: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddEditCustomFieldComponentData,
    private dialogRef: MatDialogRef<AddEditCustomFieldComponent>,
    private alertService: AlertService,
    private roleGuardService: RoleGuardService,
    private service: ResolutionClassificationCastumFieldsService,
  ) {
    this.optionsDisplayList = data.item.options;
  }
  
  ngOnInit() {
    this.hasAccess = this.roleGuardService.hasAccess('ADMIN', 'EDIT');
    this.service
      .getCutomFieldTypes()
      .subscribe(
        data => {
          this.fieldTypes = data;
        },
        err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });

    this.service.getOptionsSourceTypes().subscribe((optionSources) => {
      this.optionSources = optionSources;
    },
      err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });

    this.prepareLabels();
  }

  onSave() {
    const request = this.data.action === 'Add' ? this.service.addCustomField(this.data.item) :
      this.service.updateCustomField(this.data.item);

    request.subscribe(data => {
      if (data) {
        this.alertService.sendSuccess(`Custom filed is ${this.data.action === 'Add' ? 'Created' : 'Updated'}.`);
        this.dialogRef.close();
      }
    },
      err => { this.alertService.sendError(err.error.Message); });
  }

  onOptionSourceChange(optionSourceId) {
    this.data.item.options = [];
    this.optionsDisplayList = this.data.item.options;

    if (optionSourceId === ClassificationOptionSourceTypeEnum.Countries) {
      this.service.getOptionsByOptionSource(optionSourceId)
        .subscribe((fieldOptions: CustomFieldOptionDto[]) => {
          this.data.item.options = fieldOptions;
          this.optionsDisplayList = this.data.item.options;
        });
    }
  }

  addNewOption(): void {
    this.data.item.options.push(new CustomFieldOptionDto({ active: true }));
    this.optionsDisplayList = this.data.item.options.filter(item => item.active);
  }

  onDeleteOption(option: CustomFieldOptionDto): void {
    if (option.id) {
      option.active = false;
    } else {
      this.data.item.options = this.data.item.options.filter(item => item !== option);
    }

    this.optionsDisplayList = this.data.item.options.filter(item => item.active);
  }

  private prepareLabels() {
    this.componentHeader = this.data.action === 'Add' ? 'Add New ' : 'Edit ';
  }
}
