import {Component, OnInit} from '@angular/core';
import {NavigationTriggerService} from '@app/services/admin/navigation-trigger.service';

@Component({
  selector: 'app-esg-pa-landing',
  templateUrl: './esg-pa-landing.component.html',
  styleUrls: ['./esg-pa-landing.component.scss']
})
export class EsgPaLandingComponent implements OnInit {
  isChild = true;
  constructor(private navigationTriggerService: NavigationTriggerService) {
  }

  ngOnInit() {
    this.navigationTriggerService.setSubMenu(false);
  }
}
