import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

class MSClass {
  getName() {
    return (<any>this).constructor.name;
  }
}

interface IMultiselectChipsGroupOptionModel {
  key: string;
  value: MultiselectChipsOptionModel[];
}


export class MultiselectChipsGroupOptionModel extends MSClass implements IMultiselectChipsGroupOptionModel {
  key: string;
  value: MultiselectChipsOptionModel[];
  getName: () => {};

  constructor(value: MultiselectChipsGroupOptionModel | any) {
    super();
    Object.assign(this, value);
  }
}

export class MultiselectChipsOptionModel extends MSClass {
  constructor(value: MultiselectChipsOptionModel | any) {
    super();
    Object.assign(this, value);
  }

  name: string;
  value: string;
  checked: boolean;
  getName: () => {};
}

export const _filter = (opt, value: string) => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => {
    return item.value.toLowerCase().indexOf(filterValue) >= 0;
  });
};

@Component({
  selector: 'app-multiselect-chips',
  templateUrl: './multiselect-chips.component.html',
  styleUrls: ['./multiselect-chips.component.scss'],
})
export class MultiselectChipsComponent implements OnInit, OnChanges {
  @Input() defaultOptions: MultiselectChipsOptionModel[] = [];
  @Input() allOptions: any[] = [];
  @Input() placeholder: string;
  @Input() fieldProperty: string;
  @Output() selectionChange: EventEmitter<MultiselectChipsOptionModel[]> = new EventEmitter<MultiselectChipsOptionModel[]>();

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public selectedOptions: MultiselectChipsOptionModel[] = [];
  public filteredOptions: any[] = [];
  public allSelected = false;
  public someOfAllChecked = false;
  public allSelectedOption = new MultiselectChipsOptionModel({ name: 'All', value: 'All' });
  public isGrouped = false;
  public valueChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public groupsAllSelected = [];
  public groupsSomeChecked = [];

  constructor() {}

  ngOnInit() {
    this.initializeFilters();
  }

  initializeFilters() {
    this.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((input) => {
      this.filter(input);
    });
  }

  inputEnd($event) {
    $event.input.value = '';
    this.valueChanges.next('');
  }

  doSearch(value) {
    this.valueChanges.next(value);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.allOptions) {
      this.filteredOptions = [...changes.allOptions.currentValue];
      this.isGrouped = !!this.filteredOptions.find(option => option.getName() === 'MultiselectChipsGroupOptionModel');
    }

    if (changes.defaultOptions && changes.defaultOptions.currentValue) {
      const defaultOptions = changes.defaultOptions.currentValue;
      const defaultOptionsHash = [];

      defaultOptions.reduce((acc, option) =>  {
        if (option.getName() === 'MultiselectChipsGroupOptionModel') {
          return [...acc, ...option.value.map(o => o.value.toString())];
        } else {
          return [...acc, option.value.toString()];
        }
      }, []).forEach(v => defaultOptionsHash[v] = v);

      this.allOptions.forEach((option) => {
        if (option.getName() === 'MultiselectChipsGroupOptionModel') {
          option.value.map(opt => {
            return {...opt, checked: !!defaultOptionsHash[opt.value]};
          });
        } else {
          option.checked = !!defaultOptionsHash[option.value];
        }
      });

      this.filterSelectedCheckboxes();
    }
  }

  public remove(option: MultiselectChipsOptionModel): void {
    option.checked = false;

    this.filterSelectedCheckboxes();
    this.selectionChange.emit(this.selectedOptions);
  }

  public removeAll(): void {
    this.allOptions.forEach((option) => {
      if (option.getName() === 'MultiselectChipsGroupOptionModel') {
        option.value.map(opt => {
          return {...opt, checked: false};
        });
      } else {
        option.checked = false;
      }
    });

    this.filterSelectedCheckboxes();
    this.selectionChange.emit(this.selectedOptions);
  }

  public checkboxClicked(event): void {
    const { target } = event;

    if (target.nodeName === 'MAT-OPTION' || target.closest('mat-option')) {
      event.stopPropagation();
    }
  }

  private _filterGroup(value: string) {
    if (value) {
      return this.allOptions
        .map(group => ({key: group.key, value: _filter(group.value, value)}))
        .filter(group => group.value.length > 0);
    }

    return this.allOptions;
  }

  public filter(searchText) {
    if (searchText === null) {
      this.filteredOptions = this.allOptions;
    } else {
      if (this.isGrouped) {
        this.filteredOptions = this._filterGroup(searchText);
      } else {
        this.filteredOptions = _filter(this.allOptions, searchText);
      }
    }
  }

  public checkboxChange(event: MatCheckboxChange, option: MultiselectChipsOptionModel): void {
    option.checked = event.checked;

    this.filterSelectedCheckboxes();
    this.selectionChange.emit(this.selectedOptions);
  }

  public selectAll(event: MatCheckboxChange): void {
    this.toggleAllCheckboxes(event.checked);
    this.filterSelectedCheckboxes();
    this.selectionChange.emit(this.selectedOptions);
  }

  public selectAllInGroup(key, event: MatCheckboxChange) {
    this.toggleAllCheckboxesInGroup(event.checked, key);
    this.filterSelectedCheckboxes();
    this.selectionChange.emit(this.selectedOptions);
  }

  toggleAllCheckboxesInGroup(checked: boolean, key) {
    (this.filteredOptions.find(group => group.key === key) || {value: []}).value.forEach((option) => {
      option.checked = checked;
    });
  }

  private toggleAllCheckboxes(checked: boolean): void {
    this.filteredOptions.forEach((option) => {
      if (option.getName() === 'MultiselectChipsGroupOptionModel') {
        option.value.map(opt => {
          opt.checked = checked;
          return opt;
        });
      } else {
        option.checked = checked;
      }
    });
  }

  private filterSelectedCheckboxes(): void {
    let allOptionsLenght = 0;
    if (this.isGrouped) {
      this.selectedOptions = this.allOptions.reduce((acc, group) => [...acc, ...group.value.filter(opt => opt.checked)], []);
      allOptionsLenght = this.allOptions.reduce((sum, group) => sum + group.value.length, 0);
      this.groupSelectionChangeDetection();
    } else {
      this.selectedOptions = this.allOptions.filter(opt => opt.checked);
      allOptionsLenght = this.allOptions.length;
    }

    if (allOptionsLenght === this.selectedOptions.length) {
      this.allSelected = true;
      this.someOfAllChecked = false;
    } else {
      if (this.selectedOptions.length) {
        this.someOfAllChecked = true;
      }
      this.allSelected = false;
    }
  }

  // The method is used to determinate the status of selected options per group and set them as parameter
  groupSelectionChangeDetection() {
    this.allOptions.forEach((group) => {
      this.groupsAllSelected[group.key] = this.allChecked(group.key);
      this.groupsSomeChecked[group.key] = this.someChecked(group.key);
    });
  }

  someChecked(key) {
    const { matches, groupOptions } = this.getMatches(key);
    return matches.length && groupOptions.length !== matches.length;
  }

  allChecked(key) {
    const { groupOptions, matches } = this.getMatches(key);
    return groupOptions.length === matches.length;
  }

  // get the determined statuses of the groups
  isGroupSelected = (key) => this.groupsAllSelected[key];
  isGroupIndeterminate = (key) => this.groupsSomeChecked[key];

  getMatches(key) {
    const groupOptions = (this.allOptions.find(_group => _group.key === key) || {value: []}).value;
    const matches = groupOptions.filter((o1) => {
      return this.selectedOptions.some( (o2) => {
        return o1.value === o2.value;
      });
    });

    return {groupOptions, matches};
  }
}
