import { AlternativeNameTypeEnum } from '@app/enums/AlternativeNameTypeEnum';
import { NameValueModel } from './../../../models/basic-structures';
import { BeneficiaryFilter } from './../../../dto/BeneficiaryFilter';
import { BeneficiaryType } from './../../../enums/BeneficiaryType';
import { Beneficiary } from './../../../dto/Beneficiary';
import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { BeneficiaryService } from '@app/services/admin/beneficiary.service';
import { AlertService } from '@app/services/alert.service';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-beneficiarydetail',
  templateUrl: './beneficiarydetail.component.html',
  styleUrls: ['./beneficiarydetail.component.scss']
})
export class BeneficiaryDetailComponent implements OnInit, AfterViewInit {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  model: Beneficiary = new Beneficiary();
  id: number;
  isCreateView: boolean = false;
  type: BeneficiaryType;
  beneficiaryType : BeneficiaryType [] = [BeneficiaryType.AssetManager, BeneficiaryType.Fund];
  beneficiaries : Observable<BeneficiaryFilter[]>;
  typeSelected : boolean = false;
  loading: boolean =false;
  shareHolderType : AlternativeNameTypeEnum;
  shareHolderId: number;
  disabled: boolean;
  @Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('beneficiaryForm') beneficiaryForm: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private beneficiaryService: BeneficiaryService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.id = this.data.id;
    this.type = this.data.beneficiaryType;
    this.shareHolderType = this.type == BeneficiaryType.AssetManager ? AlternativeNameTypeEnum.AssetManger : AlternativeNameTypeEnum.Fund;
    this.isCreateView = this.data.isCreateView;
    this.disabled = !this.data.disabled;
    if (this.isCreateView) {
      this.model = new Beneficiary();
      this.loading=false;
    } else {
      this.loading=true;
        this.getBeneficiary(this.id,this.type);
    }
  }

  ngAfterViewInit() {
    this.beneficiaryForm.statusChanges.subscribe(value => {
      const valid = value === 'VALID';
      this.isFormValid.emit(valid);
    });
  }

  onCancelBtnClick() {
    this.model = new Beneficiary();
  }

  onCancelDialogBtnClick() {
        this.onClose.emit(true);
  }

  getBeneficiary(id: number, beneficiaryType : BeneficiaryType) {
    this.beneficiaryService.getBeneficiary(id, beneficiaryType)
        .subscribe(
            data => {
                this.loading=false;
                this.model = data;
                this.model.beneficiaryType= this.model.beneficiaryType == 0? BeneficiaryType.AssetManager : BeneficiaryType.Fund;
                this.shareHolderId = this.model.beneficiaryId;
            },
            err => {
              this.loading=false;
              this.alertService.sendError(err.error);
            });
}

  onSaveBtnClick() {
    this.beneficiaryService.updateBeneficiary(this.model).subscribe(
        data => {
            this.model = data;
            this.alertService.sendSuccess('Beneficiary Updated!');
            this.onClose.emit(true);
        },
        err => {
            this.alertService.sendError(err);
        }
    );
  }

  beneficiarySelectionChanged(newBeneficiary : NameValueModel) {
    this.model.assignToName = newBeneficiary.name;
    this.model.assignToId = +newBeneficiary.value;
  }

  onCreateBtnClick() {
    this.beneficiaryService.createBeneficiary(this.model).subscribe(
        data => {
            this.model = data;
            this.alertService.sendSuccess('Beneficiary Created!');
            this.onClose.emit(true);
        },
        err => {
            this.alertService.sendError(err);
        }
    );
  }
}

