import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { PpBreadcrumbsResolver, Breadcrumb } from 'pp-breadcrumbs';
import { InitiativesService } from '@app/services/initiatives.service';
import { mergeMap, catchError } from 'rxjs/operators';

@Injectable()
export class InitiativeResolver extends PpBreadcrumbsResolver {
    constructor(
        private initiativesService: InitiativesService
    ) {
        super();
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Promise<Breadcrumb[]> | Breadcrumb[] {
        const initiativeId = route.params['id'];

        return this.initiativesService.getInitiative(initiativeId, true).pipe(catchError(error => {
            return EMPTY;
        }), mergeMap(data => {
            if (data) {
                return of([{
                    text: data.name,
                    path: this.getFullPath(route)
                }]);
            } else {
                return EMPTY;
            }
        })
        );
    }

}
