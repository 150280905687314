export class ProjectInvestorUploadDto {
    projectId: number;
    excelTemplate: string;
    usageType: string;
    investors: ProjectInvestorUploadItemDto[];
}

export class ProjectInvestorUploadItemDto {
    investorId: string;
    city: string;
    institution: string;
    responsibility: string;    
    name: string;
    investorShareCapitalItems: InvestorShareCapitalItem[];

    public constructor(init?: Partial<ProjectInvestorUploadItemDto>) {
        Object.assign(this, init);
    }
}

export class InvestorShareCapitalItem {
    source: string;
    shareType: string;
    shareCapital: number;
    isPrimarySource: boolean = false;

    public constructor(init?: Partial<InvestorShareCapitalItem>) {
        Object.assign(this, init);
    }
}
