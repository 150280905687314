import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    InvestorIntelGridAddDto,
    InvestorValueCountryGridSourceDto
} from '@app/investor/investordetail/investor-value-country-grid/investor-value-country-grid-source-dto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InvestorValueCountryGridService {
    constructor(private http: HttpClient) {
    }

    public getGridData(endpointPath: string, searchItemId: number): Observable<InvestorValueCountryGridSourceDto> {
        return this.http.get<InvestorValueCountryGridSourceDto>(`${environment.serverUrl}${endpointPath}/${searchItemId}`);
    }

    public addRow(endpointPath: string, data: InvestorIntelGridAddDto) {
        return this.http.post<InvestorIntelGridAddDto>(`${environment.serverUrl}${endpointPath}/`, data);
    }

    public updateRow(endpointPath: string, data: InvestorIntelGridAddDto) {
        return this.http.put<InvestorIntelGridAddDto>(`${environment.serverUrl}${endpointPath}/`, data);
    }

    public deleteRow(endpointPath: string, entityId: number) {
        return this.http.delete(`${environment.serverUrl}${endpointPath}/${entityId}`);
    }

    public getGlobalCustodianOptions(): Observable<any> {
        return this.http.get(`${environment.serverUrl}InvestorIntelligence/options/global-custodians`);
    }

    public getLocalCustodianOptions(): Observable<any> {
        return this.http.get(`${environment.serverUrl}InvestorIntelligence/options/local-custodians`);
    }
}
