import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@app/../environments/environment';
import {Observable} from 'rxjs';
import {LocalCustodian} from '@app/dto/LocalCustodian';
import {Project} from '@app/dto/Project';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {GlobalCustodian} from '@app/dto/GlobalCustodian';
import {Local} from 'protractor/built/driverProviders';
import { CustodiansLink } from '@app/dto/CustodiansLink';

@Injectable({
  providedIn: 'root'
})
export class LocalCustodiansService {
  private cache$: Observable<LocalCustodian>[] = [];

  constructor(private http: HttpClient) {
  }

  public getLocalCustodiansList(): Observable<LocalCustodian[]> {
    const url = `${environment.serverUrl}localcustodians`;
    return this.http.get<LocalCustodian[]>(url);
  }

  public get(id: number, useCache: boolean = false): Observable<LocalCustodian> {
    if (!this.cache$[id] || !useCache) {
      this.cache$[id] = this.http
        .get<LocalCustodian>(`${environment.serverUrl}localcustodians/${id}`).pipe(
          map(data => data),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        );
    }

    return this.cache$[id];
  }

  public create(model: LocalCustodian): Observable<LocalCustodian> {
    return this.http.post<LocalCustodian>(`${environment.serverUrl}localcustodians`, model);
  }

  public update(model: LocalCustodian): Observable<LocalCustodian> {
    return this.http.put<LocalCustodian>(`${environment.serverUrl}localcustodians`, model);
  }

  public linkToGlobalCustodian(model: CustodiansLink) {
    return this.http.post<LocalCustodian>(`${environment.serverUrl}LinkedLocalCustodians/local-custodians/link`, model);
  }

  public deleteLinkedGlobalCustodian(linkedLocalCustodianId: number): Observable<any> {
    return this.http.delete(`${environment.serverUrl}LinkedLocalCustodians/local-custodians/link/${linkedLocalCustodianId}`)
  }


  clearCache(id: number | null = null) {
    if (this.cache$ && id) {
      this.cache$[id] = null;
    } else {
      this.cache$ = null;
    }
  }


}
