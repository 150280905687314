export class Initiative {
    esgInitiativeId: number;
    name: string;
    types: string[];
    impacts: string[];
    activated: boolean;
    initiationDate: Date;
    summary: string;
    documentsCount: number;
    website: string;
    deactivatedDate?: Date;
    hasSectorFocus: boolean = false;
    sectorFocuses: string[];
    hasRegionFocus: boolean = false;
    regionFocuses: string[];
    hasThematicFocus: boolean = false;
    thematicFocuses: string[];
    parentEsgInitiativeId?: number;
    investorId?: number;
}
