import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminLandingComponent} from '@app/admin/admin-landing.component';
import {RoleGuardService} from '@app/auth/role-guard.service';
import {TeamManagementModule} from '@app/admin/team-management/team-management.module';

const routes: Routes = [
  {
    path: '',
    component: AdminLandingComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'gc',
      },
      {
        path: 'gc',
        data: {breadcrumbs: 'Global Custodians', title: 'Global Custodians List'},
        loadChildren: '@app/admin/global-custodians/global-custodians.module#GlobalCustodiansModule'
      },
      {
        path: 'lc',
        data: {breadcrumbs: 'Local Custodians', title: 'Local Custodians List'},
        loadChildren: '@app/admin/local-custodians/local-custodians.module#LocalCustodiansModule',
      },
      {
        path: 'ch',
        data: {
          breadcrumbs: 'Clearing Houses',
          title: 'Clearing Houses List',
        },
        loadChildren: '@app/admin/clearing-houses/clearing-houses.module#ClearingHousesModule',
      },
      {
        path: 'esg',
        data: {
          breadcrumbs: 'ESG providers and associations',
          title: 'ESG providers and associations List',
        },
        canActivateChild: [RoleGuardService],
        loadChildren: '@app/admin/esg-providers-and-associations/esg-providers-and-associations.module#EsgProvidersAndAssociationsModule',
      },
      {
        path: 'initiatives',
        canActivate: [RoleGuardService],
        canActivateChild: [RoleGuardService],
        data: {
          breadcrumbs: 'ESG Initiatives', title: 'ESG Initiatives List'
        },
        loadChildren: '@app/admin/initiative/initiative.module#InitiativeModule',
      },
      {
        path: 'beneficiaries',
        canActivate: [RoleGuardService],
        data: {
          breadcrumbs: 'Beneficial Owners and Funds'
        },
        loadChildren: '@app/admin/beneficiary/beneficiary.module#BeneficiaryModule',
      },
      {
        path: 'classifications',
        data: {
          breadcrumbs: 'Resolution Classifications',
          title: 'Resolution Classification List'
        },
        loadChildren: '@app/admin/resolution-classification/resolution-classification.module#ResolutionClassificationModule',
      },
      {
        path: 'proxy',
        data: {breadcrumbs: 'Proxy Advisor', title: 'Proxy Advisors List'},
        loadChildren: '@app/admin/proxy-advisor/proxy-advisor.module#ProxyAdvisorModule',

      },
      {
        path: 'external-advisors',
        canActivate: [RoleGuardService],
        data: {breadcrumbs: 'External Advisors', title: 'External Advisors List'},
        loadChildren: '@app/admin/external-advisors/external-advisors.module#ExternalAdvisorsModule'
      },
      {
        path: 'team-management',
        canActivate: [RoleGuardService],
        data: {breadcrumbs: 'Team Management', title: 'Team Management List'},
        loadChildren: '@app/admin/team-management/team-management.module#TeamManagementModule'
      },
      {
        path: 'us-data-imports',
        canActivate: [RoleGuardService],
        data: {breadcrumbs: 'Us Data Imports', title: 'Us Data Imports'},
        loadChildren: '@app/admin/us-data-imports/us-data-imports.module#UsDataImportsModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)], // add  `enableTracing: true` if you want to debug the links
  exports: [RouterModule]
})

export class AdminRoutingModule {}

