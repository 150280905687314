import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {InvestorIntelLogisticalIntelligenceDto} from '@app/dto/InvestorIntelDto';
import {InvestorsIntelligenceService} from '@app/services/investors';
import {ActivatedRoute} from '@angular/router';
import {AlertService} from '@app/services/alert.service';
import { DirtyComponent } from '@app/common/dirty-component';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'app-investor-logistical-intelligence',
  templateUrl: 'investor-logistical-intelligence.component.html',
  styleUrls: ['investor-logistical-intelligence.component.scss']
})
export class InvestorLogisticalIntelligenceComponent implements OnInit, DirtyComponent {
  isChild = true; // it is used in the landing component to determine childs
  investorId: number;
  isEdit = false;
  model: InvestorIntelLogisticalIntelligenceDto;

  constructor(private investorsIntelligenceService: InvestorsIntelligenceService,
              private alertService: AlertService,
              private route: ActivatedRoute) {
    this.model = new InvestorIntelLogisticalIntelligenceDto();
  }

  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.investorId = +params['id'];
      this.getData();
    });

    this.isDirty$.subscribe(data => {
      return of(data);
    });
  }

  getData() {
    this.investorsIntelligenceService.getLogisticalIntelligence(this.investorId).subscribe(
      data => {
        this.model = data;
      },
      err => {
        this.alertService.sendError(JSON.stringify(err));
      });
  }

  onCancelClick() {
    this.getData();
    this.isEdit = false;
    this.isDirty.next(this.isEdit);
  }

  onSaveBtnClick() {
    this.investorsIntelligenceService.updateLogisticalIntelligence(this.model).subscribe(
      data => {
        this.isEdit = false;
        this.isDirty.next(this.isEdit);
        this.alertService.sendSuccess('Logistical Intelligence was updated successfully!');
      },
      err => {
        this.alertService.sendError(JSON.stringify(err));
      });
  }

  onEditBtnClick(){
    this.isEdit = true;
    this.isDirty.next(this.isEdit);
  }
}
