
export interface IInvestorVotingEdit {
  shareClassification: string;
  investorShareCapitalId: number;
  votingItems: IVotingItems[];
}

export interface IVotingItems {
  votingItemId: number;
  templateProperty1: string;
  templateProperty2: string;
  votingItemType: string;
  isParentVotingItem: boolean;
  votes: IVote[];
}

interface IVote {
  voteId: number;
  voteValue: number;
  voteType: string;
}

export class InvestorVotingEditAdapter {
  shareClassification: string;
  investorShareCapitalId: number;
  votingItems: IVotingItems[];

  constructor(init?: Partial<IInvestorVotingEdit>) {
    Object.assign(this, init);
  }
}

export class InvestorVotingSaveAdapter {
  projectId: number;
  investorId: number;
  globalCustodianId: number;
  shareCapitals: IInvestorVotingEdit[];

  constructor(init?) {
    Object.assign(this, init);
  }
}
