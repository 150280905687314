import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ExternalAdvisorsListComponent} from '@app/admin/external-advisors/external-advisors-list/external-advisors-list.component';
import {ExternalAdvisorsComponent} from '@app/admin/external-advisors/external-advisors.component';
import {AdminExternalAdvisorResolver} from '@app/resolvers/admin/admin-external-advisor.resolver';

const routes: Routes = [
  {
    path: '',
    component: ExternalAdvisorsComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        component: ExternalAdvisorsListComponent,
      },
      {
        path: ':id',
        data: {breadcrumbs: AdminExternalAdvisorResolver, subnav: 1},
        loadChildren: './external-advisors-sub-module/external-advisors-sub-module.module#ExternalAdvisorsSubModuleModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)], // add  `enableTracing: true` if you want to debug the links
  exports: [RouterModule]
})

export class ExternalAdvisorsRoutingModule {}

