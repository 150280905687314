export class ProxyAdvisorRecommendationComment {
    public constructor(){
        this.id=0;
        this.comment='';
        this.projectPxyAdvResolutionRecommendationId=0;
    }
    
    id: number;
    comment: string;
    projectPxyAdvResolutionRecommendationId: number;


}
