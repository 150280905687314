import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Filters } from '@app/investor/investordetail/investor-history-summary/investor-voting-history.component';

const dateFormat = 'dd MMM yyyy';

@Component({
  selector: 'app-investor-filters-summary',
  templateUrl: './investor-filters-summary.component.html',
  styleUrls: ['./investor-filters-summary.component.scss']
})
export class InvestorFiltersSummaryComponent {
  _filters: Filters;
  marketFilterText: string;
  prxyAdvisorsText: string;
  resolutionClassificationFilterText: string;
  sectorFilterText: string;
  dateFilterText: string;

  constructor(private datePipe: DatePipe) { }

  @Input() set filters(value: Filters) {
    this._filters = value;
    if (!value) {
      return;
    }
    this.marketFilterText = this._filters.marketOptions.map(m => m.name).join(', ');
    this.prxyAdvisorsText = this._filters.proxyAdvisorsOptions.map(m => m.name).join(', ');
    this.resolutionClassificationFilterText = this._filters.resolutionClassifications.map(m => m.name).join(', ');
    this.sectorFilterText = this._filters.sectorOptions.map(m => m.name).join(', ');

    const { fromDate, toDate } = this.filters;
    const dates = [
      ... fromDate ? [this.datePipe.transform(fromDate, dateFormat)] : [],
      ... toDate ? [this.datePipe.transform(toDate, dateFormat)] : []
    ];

    this.dateFilterText = dates.join(' - ');
  }

  get filters() { return this._filters; }

  @Input() options: any;
}
