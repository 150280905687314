import * as XLSX from 'xlsx';
import {InvestorShareCapitalItem, ProjectInvestorUploadItemDto} from './project-investor-upload-dto';

export class ProcessExcelFileService {
    public readEUExcelFile(workSheet: XLSX.WorkSheet) : ProjectInvestorUploadItemDto[] {
        const headers = this.getHeaders(workSheet);

        let investors: ProjectInvestorUploadItemDto[] = [];
        // start data coordinats
        let cellColumn = 'A';
        let cellRow = 6;
        while (workSheet[cellColumn + cellRow]) {
            var investor = new ProjectInvestorUploadItemDto({ name: workSheet[cellColumn + cellRow].v, investorShareCapitalItems: [] });
            for (let header of headers) {
                let investorShareCapitalItem = new InvestorShareCapitalItem({
                    source: header.header1,
                    shareType: header.header2,
                    isPrimarySource: header.isPrimarySource
                });

                if (workSheet[header.column + cellRow]) {
                    investorShareCapitalItem.shareCapital = workSheet[header.column + cellRow].v;

                    investor.investorShareCapitalItems.push(investorShareCapitalItem);
                }
            }

            cellRow++;
            investors.push(investor);
        }

        return investors;
    }

    public readEAUExcelFile(workSheet: XLSX.WorkSheet): ProjectInvestorUploadItemDto[] {
        let excelArr: object[] = [];
        excelArr = XLSX.utils.sheet_to_json(workSheet, { raw: true });
        let prjInvestors: ProjectInvestorUploadItemDto[] = [];

        excelArr.forEach((element) => {
          let item: ProjectInvestorUploadItemDto = new ProjectInvestorUploadItemDto();
          item.investorShareCapitalItems = [];
          item.investorId = element['InvestorId'];
          item.investorShareCapitalItems.push(new InvestorShareCapitalItem({
            source: 'Company Register',
            shareType: 'Ordinary Shares',
            shareCapital: element['Shareholding'],
            isPrimarySource: true
        }));

          item.name = element['Institution'];
          item.city = element['City'];
          item.responsibility = element['Responsibility'];
          prjInvestors.push(item);

        });

        return prjInvestors;
      }

    private getHeaders(workSheet: XLSX.WorkSheet) {
        let headers: ComumnHeader[] = [];

        // start headers coordinats
        let cellColumn = 'B';
        let cellRow = 4;

        // primary sorce is the first source in table
        let primarySource: string = workSheet[cellColumn + cellRow].v;
        while (workSheet[cellColumn + cellRow]) {
            headers.push(new ComumnHeader({
                header1: workSheet[cellColumn + cellRow].v,
                header2: workSheet[cellColumn + (cellRow + 1)].v,
                column: cellColumn,
                isPrimarySource: workSheet[cellColumn + cellRow].v === primarySource,
            }));

            cellColumn = this.getNextCharacter(cellColumn);
        }

        return headers;
    }

    private getNextCharacter(character: string): string {
        return String.fromCharCode(character.charCodeAt(0) + 1);
    }
}

class ComumnHeader {
    header1: string;
    header2: string;
    column: string;
    isPrimarySource: boolean;

    public constructor(init?: Partial<ComumnHeader>) {
        Object.assign(this, init);
    }
}
