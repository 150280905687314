import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ComponentsModule} from '@app/components/components.module';
import {CdkTableModule} from '@angular/cdk/table';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {GlobalCustodiansLendingComponent} from '@app/admin/global-custodians/global-custodians-lending.component';
import {GlobalCustodiansRoutingModule} from '@app/admin/global-custodians/global-custodians-routing.module';
import {GlobalCustodiansListComponent} from '@app/admin/global-custodians/global-custodians-list/global-custodians-list.component';
import {GlobalCustodiansResolver} from '@app/resolvers/admin/global-custodians.resolver';
import {GlobalCustodiansDialogAddComponent} from '@app/admin/global-custodians/global-custodians-dialog-add/global-custodians-dialog-add.component';

@NgModule({
  imports: [
    ComponentsModule,

    FormsModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,

    MatSortModule,
    MatTabsModule,
    MatInputModule,
    MatCheckboxModule,
    CdkTableModule,
    MatExpansionModule,
    MatDialogModule,
    MatDatepickerModule,
    MatListModule,
    MatNativeDateModule,
    MatRadioModule,
    MatAutocompleteModule,
    NgbModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTableModule,

    CommonModule,
    GlobalCustodiansRoutingModule,
  ],
  exports: [
    GlobalCustodiansListComponent,
  ],
  declarations: [
    GlobalCustodiansDialogAddComponent,
    GlobalCustodiansListComponent,
  ],
  bootstrap: [GlobalCustodiansLendingComponent],
  providers: [GlobalCustodiansResolver],
  entryComponents: [
    GlobalCustodiansDialogAddComponent
  ],
})
export class GlobalCustodiansModule { }
