import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UsActivityItemInfo } from '@app/dto/UsActivityDashboardInfo';
import { ActionsService } from '@app/services/actions.service';
import { AlertService } from '@app/services/alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-us-activity-dashboard',
  templateUrl: './us-activity-dashboard.component.html',
  styleUrls: ['./us-activity-dashboard.component.scss']
})
export class UsActivityDashboardComponent implements OnInit, OnDestroy {

  @Input() projectId: number;

  showActivities: boolean = false;
  activities: UsActivityItemInfo[];
  private activityUpdatedSubscription: Subscription;

  constructor(private actionsService: ActionsService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.getActivities();

    this.activityUpdatedSubscription = this.actionsService.activityUpdatedSubject.subscribe(activityUpdated => {
      if (activityUpdated) {
        this.getActivities();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.activityUpdatedSubscription) {
      this.activityUpdatedSubscription.unsubscribe();
    }
  }

  getActivities() {
    this.actionsService.getUsActivityDashboardInfo(this.projectId)
      .subscribe((data) => {
        this.showActivities = data.isAvailable;
        this.activities = data.activities;
      }, (error) => {
        this.alertService.alert(error);
      });
  }
}
