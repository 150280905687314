import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatDialog } from '@angular/material';
import { InvestorsService } from '@app/services/investors';
import { first, takeUntil } from 'rxjs/operators';
import { Investor } from '@app/dto/Investor';
import AutocompleteOption from '@app/dto/common/autocomplete-option';
import { Subject } from 'rxjs';
import { DashboardInvestorVotingService } from '@app/services/dashboard/dashboard-investor-voting.service';
import { IList, ListAdapter } from '@app/dto/base/list.interface';
import { InvestorVotingEditAdapter, InvestorVotingSaveAdapter } from '@app/dto/investor/investor-voting-edit.interface';
import { DynamicColumnDefinition } from '@app/dashboard/dashboardau/dashboard-custodian-table/dashboard-custodian-table.component';
import { ProjectsService } from '@app/services/projects.service';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { AlertService } from '@app/services/alert.service';
import { Lookup } from '@app/dto/Lookup';
import { CommonUtilityService } from '@app/utility/common-utility.service';
import { NgxSpinnerService } from 'ngx-spinner';
// tslint:disable-next-line: max-line-length
import { OvervoteConfirmDialogComponent } from '@app/dashboard/dashboardau/detail/dashboard-investor-voting/overvote-confirm-dialog/overvote-confirm-dialog.component';

@Component({
  selector: 'app-add-custodian-votes-dialog',
  templateUrl: './add-custodian-votes-dialog.component.html',
  styleUrls: ['./add-custodian-votes-dialog.component.scss']
})
export class AddCustodianVotesDialogComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private investorId;
  private projectId;
  private custodianId;
  public custodianName;
  public isEditMode;
  public investor: Investor;
  public clearAutocompleteSubject = new Subject<void>();
  public custodianOptions: IList[] = [];
  public custodian: ListAdapter;
  private project;
  voteTitle: string;
  private model: InvestorVotingEditAdapter[];
  public showGrid = false;

  orgDisplayedColumns: string[] = [
    'shareClassification',
    'name'
  ];
  displayedColumns: string[] = [];
  displayDynamicColumns: DynamicColumnDefinition[] = [];
  dataSource = new MatTableDataSource([]);
  disableSource = false;
  custodianVotingTypes: Lookup[] = [];
  coverage: string;
  unspecifiedIsChecked: boolean;

  preconditionFailedStatusCode = 412;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddCustodianVotesDialogComponent>,
    private readonly investorsService: InvestorsService,
    private readonly investorVotingService: DashboardInvestorVotingService,
    private projectsService: ProjectsService,
    private projectTemplateService: ProjectTemplateService,
    private alertService: AlertService,
    private commonUtility: CommonUtilityService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog) {
    this.investorId = data.investorId;
    this.projectId = data.projectId;
    this.custodianName = data.custodianName ? data.custodianName : '';
    this.custodianId = data.custodianId;
    this.isEditMode = data.isEditMode;
  }

  ngOnInit() {
    this.projectsService.getProject(this.projectId, true).subscribe(
      data => {
        this.project = data;
        this.coverage = this.project.marketCoverage;
        this.voteTitle = data.template === this.projectTemplateService.SHAREHOLDER_MEETING_TEMPLATE_NAME ? 'Resolutions' : 'Bid companies';
      }, error => {
        this.alertService.sendError(error.message);
      });

    this.getInvestorData();
    if (this.isEditMode) {
        this.onCheckBoxChange(this.unspecifiedIsChecked = this.custodianName === 'UNSPECIFIED');
        if (!this.unspecifiedIsChecked) {
          this.custodian = new ListAdapter();
          this.custodian.name = this.custodianName;
          this.custodian.value = this.custodianId;
          this.getData();
        } else {
          this.custodianName = '';
        }
    }
  }

  getInvestorData() {
    this.investorsService.getInvestor(this.investorId, true).pipe(first()).subscribe(data => {
      this.investor = data;
    });
  }

  onValueChange($event: string) {
    if ($event === '') {
      this.custodianOptions = [];
      this.onOptionSelect();
    } else {
      this.investorVotingService.custodianSearch($event, this.projectId).pipe(takeUntil(this.unsubscribe$)).subscribe((data: IList[]) => {
        this.custodianOptions = data;
      });
    }
  }

  onOptionSelect($event?: AutocompleteOption) {
    if ($event) {
      this.custodian = new ListAdapter($event);
      this.getData();
    } else {
      this.clearGrid();
    }
  }

  getData() {
    this.investorVotingService.getInvestorVotes(this.projectId,
                                this.investorId,
                                this.custodian.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: InvestorVotingEditAdapter[]) => {
        this.showGrid = true;
        this.model = data;
        const voteTypes = this.extractUniqueVoteTypes(data);
        const additional = this.projectTemplateService.hasFrequencySimple(voteTypes) ?
          this.projectTemplateService.getFrequencyVoteTypes(
            false,
            'CUSTODIAL_RECONCILIATION',
            [],
            this.projectTemplateService.isFrequencyWithReverseOrderSimple(voteTypes.join(), true)
          ) : [];
        const template = this.project.template.replace(/\s/g, '');

        this.custodianVotingTypes = this.projectTemplateService.getInvestorVotingVotedTypes(
          this.project.template,
          additional
        );

        this.setColumns(data);
        this.setTableRows(data);
      });
  }

  setColumns(data) {
    this.displayedColumns = Object.assign([], this.orgDisplayedColumns);

    this.displayDynamicColumns = [];

    this.custodianVotingTypes.forEach(shareType => {
      const coldef = new DynamicColumnDefinition();
      coldef.colName = `${shareType.fieldValue}`;
      coldef.colHeaderName = `${shareType.fieldLabel}`;
      coldef.colHeaderColor = `${shareType.color}`;
      this.displayedColumns.push(`${shareType.fieldValue}`);
      this.displayDynamicColumns.push(coldef);
    });
  }

  setTableRows(data) {
    let isDark = false;

    const tableRows = data.reduce((accumulator, item, currentIndex) => {

      item.votingItems.forEach((votingItem, index) => {
        const row = {
          ...votingItem,
          shareClassification: item.shareClassification,
          investorShareCapitalId: item.investorShareCapitalId,
          // tslint:disable-next-line:max-line-length
          name: votingItem.isParentVotingItem ? `${votingItem.templateProperty1}. ${votingItem.templateProperty2}` : `${this.indexProperties(+votingItem.templateProperty1)}. ${votingItem.templateProperty2}`,
          rowspan: item.votingItems.length,
          index
        };

        this.custodianVotingTypes.forEach(votingType => {
          const itemVote = votingItem.votes.find(vote => vote.voteType === votingType.fieldValue);
          let element;
          if (itemVote) {
            element = { ...itemVote, voteValue: itemVote.voteValue || 0, hide: false };
          } else {
            element = { ...itemVote, hide: true };
          }

          row[votingType.fieldValue] = element;
        });
        accumulator.push(row);
      });

      isDark = !isDark;

      return accumulator;
    }, []);

    this.dataSource.data = tableRows;
  }

  setVotes(elementDynColm, element) {
    // tslint:disable-next-line:max-line-length
    const index = this.model.findIndex(shareClassification => shareClassification.investorShareCapitalId === element.investorShareCapitalId);
    const votingItem = this.model[index].votingItems.find(_votingItem => _votingItem.votingItemId === element.votingItemId);
    const vote = (votingItem.votes || []).find(item => item.voteType === elementDynColm.voteType);
    vote.voteValue = elementDynColm.voteValue;
  }

  extractUniqueVoteTypes = array => array.reduce((h, obj) => [...h, ...obj.votingItems.map(o => o.votingItemType)], [])
    .filter((value, index, self) => self.indexOf(value) === index)

  indexProperties = (index) => this.commonUtility.indexToLetter(index, false);

  saveData() {
    this.spinner.show('custodian-votes-dialog');
    const data = new InvestorVotingSaveAdapter({
      projectId: this.projectId,
      investorId: this.investorId,
      globalCustodianId: this.custodian.value,
      shareCapitals: this.model
    });

    this.investorVotingService.setInvestorVotes(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_data) => {
        this.spinner.hide('custodian-votes-dialog');
        this.dialogRef.close(true);
      }, error => {
        this.spinner.hide('custodian-votes-dialog');
        if (error.hasOwnProperty('status') && error.status === this.preconditionFailedStatusCode) {
          this.handlePreconditionFailedError(data, error.error);
        } else {
          this.alertService.sendError(error.message);
        }
      });
  }

  onCheckBoxChange(isChecked) {
    this.clearAutocompleteSubject.next();
    this.clearGrid();
    if (isChecked) {
      const adapter = new ListAdapter();
      adapter.name = '';
      adapter.value = 0;
      this.custodian = adapter;
      this.getData();
    }

    this.disableSource = isChecked;
  }

  clearGrid() {
    this.custodian = null;
    this.model = null;
    this.displayedColumns = [];
    this.showGrid = false;
    this.dataSource = new MatTableDataSource([]);
  }

  handlePreconditionFailedError(data: InvestorVotingSaveAdapter, message: string) {
    this.dialog.open(OvervoteConfirmDialogComponent,
      {
        width: '600px',
        data: {
          message: message
        }
      }).afterClosed().subscribe(isConfirm => {
        if (isConfirm) {
          this.investorVotingService.setInvestorOvervotes(data)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((_data) => {
              this.spinner.hide('custodian-votes-dialog');
              this.dialogRef.close(true);
            }, error => {
              this.spinner.hide('custodian-votes-dialog');
              this.alertService.sendError(error.message);
            });
        }
      });
  }
}
