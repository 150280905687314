import {AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LocalCustodiansService} from 'src/app/services/admin/local-custodians.service';
import {LocalCustodian} from 'src/app/dto/LocalCustodian';
import {AlertService} from 'src/app/services/alert.service';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'app-local-custodians-general',
  templateUrl: './local-custodians-general.component.html',
  styleUrls: ['local-custodians-general.component.scss'],
})
export class LocalCustodiansGeneralComponent implements OnInit, AfterViewChecked {
  localCustodianId: number;
  public _formValid = false;
  isEdit: boolean = false;
  model: LocalCustodian;
  @ViewChild('localcustodians') localcustodians: any;
  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();

  constructor(private route: ActivatedRoute,
              private alertService: AlertService,
              private cdRef : ChangeDetectorRef,
              private localCustodiansService: LocalCustodiansService) {
  }

  ngOnInit() {
    this.route.parent.params.subscribe((params) => {
      this.localCustodianId = +params['id'];
      this.isDirty$.subscribe(data => {
        return of(data);
      });
      this.getLocalCustodianRecord();
    });
  }

  getLocalCustodianRecord() {
    this.localCustodiansService.get(this.localCustodianId).subscribe(data => {
        this.model = data;
      },
      err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      });
  }

  edit(flag) {
    this.isEdit = flag;
    this.localcustodians.edit(flag);
    this.isDirty.next(this.isEdit);
  }

  setFormValidation(valid) {
    this._formValid = valid;
  }

  formSaved(flag) {
    this.isDirty.next(false);
    this.edit(false);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
