import { Component, forwardRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { Lookup } from '@app/dto/LookUp';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { AlertService } from '@app/services/alert.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const noop = () => {/* */
};

@Component({
  selector: 'app-assign-director-to-votin-items',
  templateUrl: './assign-director-to-voting-items.component.html',
  styleUrls: ['./assign-director-to-voting-items.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssignDirectorToVotingItemsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS, // Is an InjectionToken required by this class to be able to be used as an Validator
      useExisting: forwardRef(() => AssignDirectorToVotingItemsComponent),
      multi: true,
    }
  ]
})
export class AssignDirectorToVotingItemsComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  private unsubscribe$ = new Subject<void>();
  label = 'Director candidates:';
  _items: string[] = [];
  boardOfDirectorsRecommendations: Lookup[] = [];

  public itemList: FormArray;
  public formGroup: FormGroup = new FormGroup({});

  set value(data: string[]) {
    this._items = data;
    const elements: FormGroup[] =
      data.length > 0 ? data.map(item => this.createItem(item)) : [this.createItem()];
    this.formArrayClear();
    elements.forEach(el => this.itemList.push(el));

  }

  get value() {
    return this._items;
  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  discloseValidatorChange = () => {};
  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private alertService: AlertService,
              private projectTemplateService: ProjectTemplateService,
              private readonly fb: FormBuilder) {
  }

  ngOnInit() {
    this.formGroup.addControl('itemList', this.fb.array([]));
    this.itemList = this.formGroup.get('itemList') as FormArray;
    if (this.itemList.length === 0) {
      this.itemList.push(this.createItem());
    }
    this.boardOfDirectorsRecommendations = this.projectTemplateService.getDirectorExceptionVoteTypes();
  }

  addItem() {
    this.itemList.push(this.createItem());
    this._items = this.formGroup.getRawValue().itemList.map(item => item);
    this.onChangeCallback(this._items);
  }

  deleteItem(rowId: number) {
    this.removeFormRow(rowId);
  }

  public removeFormRow(rowId: number) {
    this.itemList.removeAt(rowId);
    if (this.itemList.length === 0) {
      this.itemList.push(this.createItem());
    }
    this._items = this.formGroup.getRawValue().itemList.map(item => item);
    this.onChangeCallback(this._items);
  }

  public resetForm() {
    this.formArrayClear();
    this.itemList.reset();
  }

  createItem(data?) {
    const theGroup = this.fb.group({
      ...data,
      name: this.fb.control(data ? data.name : '', Validators.required),
      boardOfDirectorsRecommendation: this.fb.control(data ? data.boardOfDirectorsRecommendation : '')
    });

    theGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this._items = this.formGroup.getRawValue().itemList.map(item => item);
      this.onChangeCallback(this._items);
    });


    return theGroup;
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  formArrayClear() {
    while (this.itemList.length) {
      this.itemList.removeAt(this.itemList.length - 1);
    }
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value !== this._items) {
      const elements: FormGroup[] = value && value.length > 0 ? value.map(item => this.createItem(item)) : [this.createItem()];
      this.formArrayClear();
      elements.forEach(el => this.itemList.push(el));

      this._items = this.formGroup.getRawValue().itemList.map(item => item);
      this.onChangeCallback(this._items);
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.formGroup.valid ? null : {required: {valid: false}};
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  getColorByVoteType(vote: string): string {
    if (vote === '' || vote === null) return '';
    return this.projectTemplateService.votedtypecolors.find(c => c.type === vote).color;
  }
}
