import {
  AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output,
  ViewChild
} from '@angular/core';
import { LocalCustodian } from '@app/dto/LocalCustodian';
import { AlertService } from '@app/services/alert.service';
import { LocalCustodiansService } from '@app/services/admin/local-custodians.service';
import { NameValueModel } from '@app/models/basic-structures';
import {
  ActiveDirectoryUserListComponent
} from '@app/components/autocompletes/active-directory-user-list/active-directory-user-list.component';
import { FormControl } from '@angular/forms';
import { LookupFilter, Utils } from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { Lookup } from '@app/dto/Lookup';
import { BroadridgeCode } from '../../../dto/BroadridgeCode';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';
import { DialogsService } from '../../../services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '../../../services/dialogs/dialog-delete-template.config';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-local-custodians-add-edit',
  templateUrl: './local-custodians-add-edit.component.html',
  styleUrls: ['local-custodians-add-edit.component.scss'],
})
export class LocalCustodiansAddEditComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @ViewChild(ActiveDirectoryUserListComponent) responsibility: ActiveDirectoryUserListComponent;
  @ViewChild('genericForm') genericForm: any;
  @Input() set model(value) {
    if (value) {
      this.loading = true;
      this._model = value;
      this._memoryModel = JSON.parse(JSON.stringify(value));
    }
  }
  @Input() isEdit = false;
  @Input() dialog = false;
  @Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isFormSaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public loading = false;
  public websiteControl = new FormControl();
  public localCustodianLoading = false;
  public util = new Utils(this.startupdataproviderService);
  public locationTypes: string[];
  public brokerVoteTypes: string[];
  public _model: LocalCustodian = new LocalCustodian();
  private _newIdCounter = 0;
  private _memoryModel: LocalCustodian;

  constructor(
    private alertService: AlertService,
    private localCustodiansService: LocalCustodiansService,
    private startupdataproviderService: StartupdataproviderService,
    private cdRef: ChangeDetectorRef,
    private dialogsService: DialogsService) {
  }

  ngOnInit() {
    this.initialiseUtils();
  }

  ngAfterViewInit() {
    this.genericForm.statusChanges.pipe(
      distinctUntilChanged()
    ).subscribe(value => {
      const valid = value === 'VALID';
      this.isFormValid.emit(valid);
    });
  }

  edit(flag): void {
    this.isEdit = flag;

    if (!flag) {
      this.reset();
    }
  }

  reset(): void {
    if (this._memoryModel) {
      this._model = JSON.parse(JSON.stringify(this._memoryModel));
    } else {
      this.responsibility.resetValue();
      this.genericForm.reset();
    }
  }

  save(): void {
    const modelAction = {
      action: 'create',
      successMsg: 'Local Custodian has been added successfully!'
    };
    if (this._model.localCustodianId > 0) {
      modelAction.action = 'update';
      modelAction.successMsg = 'Local Custodian has been updated successfully!';
    }
    this.saveLocalCustodian(modelAction);
  }

  saveLocalCustodian({ action, successMsg }): void {
    this.localCustodiansService[action](this._model).subscribe(
      data => {
        this._memoryModel = data;
        this.alertService.sendSuccess(successMsg);
        this.isFormSaved.emit(data);
      },
      err => {
        const innerMessages = (err.error || {}).InnerMessages;
        if (innerMessages) {
          for (const innerMessage of innerMessages) {
            this.alertService.sendError(innerMessage);
          }
        } else {
          const errorMessage = this.alertService.transformError(err.error.errors);
          this.alertService.sendError(errorMessage);
        }
      }
    );
  }

  countrySelectionChanged(newCountry: NameValueModel): void {
    this._model.country = newCountry.name;
  }

  citySelectionChanged(city: NameValueModel): void {
    this._model.city = city.name;
  }

  reorgCitySelectionChanged(city: NameValueModel): void {
    this._model.reorgCity = city.name;
  }

  modelChange(value): void {
    this._model.responsibilityUser = value;
  }

  initialiseUtils(): void {
    this.util.getLookupByType(LookupFilter.CUSTLOCTYPE)
      .subscribe((data: Lookup[]) => {
        this.locationTypes = data.map((item: Lookup) => item.fieldLabel);
      });

    this.util.getLookupByType(LookupFilter.BROKERVOTETYPE)
      .subscribe((data: Lookup[]) => {
        this.brokerVoteTypes = data.map((item: Lookup) => item.fieldLabel);
      });
  }

  // this is necessary to fix `ExpressionChangedAfterItHasBeenCheckedError`
  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  deleteBroadridgeCode(bdCode: BroadridgeCode): void {
    this.dialogsService.confirm(null, null, {
      template: DeleteProjectTemplate({
        title: 'Broadridge Code',
        message: `${bdCode.code}`
      })
    });
    this.dialogsService.close$.subscribe(res => {
      if (!res) {
        return null;
      }

      if (bdCode.action === 'create') {
        this._model.broadridgeCodes.splice(this._model.broadridgeCodes.indexOf(bdCode), 1);
      } else {
        bdCode.action = 'delete';
      }
    });
  }

  addBroadridgeCode(event: MatChipInputEvent): void {
    const { value, input } = event;

    const bdCodes = [...(this._model.broadridgeCodes || [])];
    if (!value || this.getActiveBdCode(value)) {
      return null;
    }

    const deletedBdCode = this.getDeletedBdCode(value);

    if (deletedBdCode) {
      deletedBdCode.action = null;
      input.value = '';

      return null;
    }

    const bdCodeToAdd = new BroadridgeCode();
    bdCodeToAdd.id = this.newId;
    bdCodeToAdd.action = 'create';
    bdCodeToAdd.code = value;
    bdCodeToAdd.localCustodianId = this.newId;

    bdCodes.push(bdCodeToAdd);
    this._model.broadridgeCodes = bdCodes;

    input.value = '';
  }

  getActiveBdCode(code: string): void {
    const bdCodes = [...(this._model.broadridgeCodes || [])];
    bdCodes.find(x => x.code.toLowerCase() === code.toLowerCase() && x.action !== 'delete');
  }

  getDeletedBdCode(code: string): BroadridgeCode {
    const bdCodes = [...(this._model.broadridgeCodes || [])];
    return bdCodes.find(x => x.code.toLowerCase() === code.toLowerCase() && x.action === 'delete');
  }

  get newId(): number {
    return --this._newIdCounter;
  }
}
