import {DirtyComponent} from '@app/common/dirty-component';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StartupdataproviderService} from '../../../service/startupdataprovider.service';
import {LookupFilter, Utils} from '../../../helper/utils';
import {Contact} from '../../../dto/Contact';
import {Lookup} from '../../../dto/Lookup';
import {AlertService} from '@app/services/alert.service';
import {RelatedEntityData} from './relatedEntityData';
import {FormControl} from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {RelatedEntity} from './relatedEntity';
import {debounceTime} from 'rxjs/operators';
import {KeyValue} from '@angular/common';
import {ContactGeneralService} from '@app/services/contact-general.service';
import {DeleteProjectTemplate} from '@app/services/dialogs/dialog-delete-template.config';
import {DialogsService} from '@app/services/dialogs/dialogs.service';
import {NameValueModel} from '@app/models/basic-structures';
import {RoleGuardService} from '@app/auth/role-guard.service';
import {ContactRelatedEntityType} from '@app/enums/ContactRelatedEntityTypeEnum';
import {ContactType as ContactTypeEnums} from '@app/enums/ContactTypeEnum';
import {ReportReceivedEnum} from '@app/enums/ReportReceivedEnum';

@Component({
    selector: 'app-contact-general',
    templateUrl: './contact-general.component.html',
    styleUrls: ['contact-general.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactGeneralComponent implements OnInit, DirtyComponent {
    @Input() relatedEntityData: RelatedEntityData;
    @Input() isModal: boolean;
    @Input() isReadOnlyMode: boolean;
    @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('contactForm') contactForm: any;
    public isDirty = new BehaviorSubject<boolean>(false);
    public isDirty$ = this.isDirty.asObservable();
    showAdd;
    showEdit;
    showDelete;
    showSave;
    showReset;
    showClose;
    disableForm;
    areaOfResponsibilities: Lookup[] = [];
    contactRatings: Lookup[] = [];
    contactPreferences: Lookup[] = [];
    contactSources: Lookup[] = [];
    contactTypes: Observable<KeyValue<number, string>[]>;
    titles: Lookup[] = [];
    georegions: Lookup[] = [];
    selectedTabIndex: number;
    model: Contact;
    contactId: number;
    mappingId: number;
    contactSource: string;
    isRalatedEntitySelectChange: boolean = false;

    isAdd: boolean=false;
    loading: boolean = true;
    util = new Utils(this.startupdataproviderService);
    routeSub: any;

    relatedEntityControl = new FormControl();
    relatedEntityControlFilteredOptions: Observable<RelatedEntity[]> = new Observable<RelatedEntity[]>();
    isEdit = false;
    hasAccess: boolean;
    ContactTypeEnums = ContactTypeEnums;
    ReportReceivedEnum = ReportReceivedEnum;
    reportReceivedOptions: Lookup[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private startupdataproviderService: StartupdataproviderService,
        private alertService: AlertService,
        private contactService: ContactGeneralService,
        private dialogsService: DialogsService,
        private roleGuardService: RoleGuardService
    ) {
        this.model = new Contact();
    }

    ngOnInit() {
        if (this.isModal) {
            this.isAdd = false
            this.isEdit = this.isReadOnlyMode? false: true;
            this.loadModalData();
        }

        this.relatedEntityControl.disable();

        this.relatedEntityControl.valueChanges
            .pipe(debounceTime(500)).subscribe((input) => { this.filterRelatedEntities(input); });

        this.util.getLookupByType(LookupFilter.CONAOR).subscribe(r => this.areaOfResponsibilities = r);
        this.util.getLookupByType(LookupFilter.CONRATING).subscribe(r => this.contactRatings = r)
        this.util.getLookupByType(LookupFilter.CONTACTITLE).subscribe(r => this.titles = r)
        this.util.getLookupByType(LookupFilter.CONTACTSOURCE).subscribe(r => this.contactSources = r)
        this.util.getLookupByType(LookupFilter.WORLDS).subscribe(r => this.georegions = r)
        this.util.getLookupByType(LookupFilter.CONWTC).subscribe(r => this.contactPreferences = r)
        this.util.getLookupByType(LookupFilter.REPORTTYPE).subscribe(r => this.reportReceivedOptions = r)

        this.getContactTypes();
        if(this.relatedEntityData && this.relatedEntityData.relatedEntityTypeId != ContactRelatedEntityType.Investor){
            this.hasAccess = this.roleGuardService.hasAccess('ADMIN', 'EDIT');
        } else {
            this.hasAccess = this.roleGuardService.hasAccess('CONTACTS','CREATE');
        }

        if (!this.isModal) {
            this.routeSub = this.route.parent.params.subscribe((params) => {
                this.model.contactId = +params['id'];
                if (this.model.contactId > 0) {
                    this.isAdd = false;
                    this.isEdit=false;
                    this.getContact();
                } else {
                    this.isAdd = true;
                    this.isEdit=true;
                    this.relatedEntityControl.enable();
                }

                this.manageOprationsProps();
            });
        } else {
            this.manageOprationsProps();
            if (!this.isAdd) {
                this.getContact();
            }
        }

        this.isDirty$.subscribe(data => {
            return of(data);
        });

        this.contactForm.form.controls.relatedEntityControl = this.relatedEntityControl;
    }

    ngAfterViewInit() {
        this.contactForm.statusChanges.subscribe(value => {
          const valid = value === 'VALID';
          this.isFormValid.emit(valid);
        });
      }

    private loadModalData(): void {
        this.model.contactId = this.relatedEntityData.contactId;
        this.model.mappingId = this.relatedEntityData.mappingId;
        this.isAdd = this.relatedEntityData.isAdd;
        this.model.contactTypeId = this.relatedEntityData.contactTypeId;

        if (this.isAdd) {
            this.model.relatedEntityTypeId = this.relatedEntityData.relatedEntityTypeId;
            this.model.legalEntity = this.relatedEntityData.legalEntity;
            this.loading = false;
        }

        this.showClose = true;
    }

    onSelected(item: RelatedEntity) {
        this.isRalatedEntitySelectChange = true;
        this.model.legalEntity = item.name;
        this.model.relatedEntityTypeId = item.relatedEntityType;
        this.model.mappingId = item.id;
    }

    manageOprationsProps() {
        if (this.isAdd) {
            this.selectedTabIndex = 0;
            this.showSave = true;
            this.showReset = true;
            this.disableForm = false;
            setTimeout(() => {
                this.isDirty.next(true);
            }, 1000);
        } else {
            this.selectedTabIndex = 0;
            this.showAdd = false;
            this.showDelete = false;
            this.showEdit = !this.isModal;
            this.showReset = this.isModal;
            this.showSave = this.isModal;
            this.disableForm = true;
        }
    }

    getContact() {
        this.contactService.getContact(this.model.contactId).subscribe(
            data => {
                this.model = data;
                this.loading = false;
            },
            err => this.alertService.sendError(JSON.stringify(err))
        );
    }

    onEditLinkClick(data) {
        this.model = data;
        this.contactId = +this.model.contactId;
        this.showSave = false;
        this.showReset = false;
        this.showAdd = false;
        this.showEdit = true;
        this.showDelete = false;
        this.disableForm = true;
        this.selectedTabIndex = 1;
    }

    onResetBtnClick() {
        if(this.isModal){
            this.onCloseBtnClick();
        }
        this.isEdit = false;

        if (this.model.contactId) this.getContact();
        this.isDirty.next(false);
    }

    onSaveBtnClick() {
        this.model.userId = 1; // TODO: this should be replaced with the user id

        this.contactService.saveContact(this.model, this.isAdd).subscribe(
            data => {
                this.alertService.sendSuccess(this.isAdd ? 'Contact created.' : 'Contact updated.');
                this.manageOprationsProps();
                this.onCloseBtnClick();
                this.isEdit = false;
                this.isDirty.next(false);
                if (!this.isModal && data && this.isAdd) {
                    this.router.navigate(["/contacts/" + data]);
                }
            },
            err => { this.alertService.sendError(JSON.stringify(err)); }
        );
    }

    onDeleteBtnClick() {
        this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: 'Contact', message: this.model.name }) });
        this.dialogsService.close$.subscribe(res => {
            if (res) {
                this.contactService.deleteContact(this.model.contactId).subscribe(
                    data => {
                        if (data) {
                            this.alertService.sendSuccess('Contact deleted!');
                            this.isDirty.next(false);
                            this.onCloseBtnClick();
                        }
                    },
                    err => {
                        this.alertService.sendError(JSON.stringify(err));
                    }
                );
            }
        });
    }

    onEditBtnClick() {
        this.isEdit = true;
        this.showAdd = false;
        this.showEdit = false;
        this.showDelete = true;
        this.showSave = true;
        this.showReset = true;
        this.disableForm = false;
        this.isDirty.next(true);
    }

    onCloseBtnClick() {
        if (this.isModal) {
            this.onClose.emit(true);
        }
    }

    onBlur() {
        if (!this.model.mappingId || !this.model.relatedEntityTypeId) {
            this.model.legalEntity = '';
            this.model.relatedEntityTypeId = undefined;
            this.model.mappingId = undefined;
        }
    }

    countrySelectionChanged(newCountry: NameValueModel) {
        this.model.country = newCountry.name;
    }

    citySelectionChanged(city: NameValueModel) {
        this.model.city = city.name;
    }

    private getContactTypes(): void {
        this.contactTypes = this.contactService.getContactTypes();
    }

    private filterRelatedEntities(value: string): void {
        if (this.isModal) {
            return;
        }

        // this is added because when a related entity is selected,
        // one more change event is raised
        if (this.isRalatedEntitySelectChange) { // this is added because when a related entity is sele
            this.isRalatedEntitySelectChange = false;
            return;
        }

        if (this.isAdd) {
            this.model.mappingId = undefined;
        }
        if (!value || value.length < 2) {
            this.relatedEntityControlFilteredOptions = new Observable<RelatedEntity[]>();
            return;
        }

        this.relatedEntityControlFilteredOptions = this.contactService.getRelatedEntities(value);
    }
}
