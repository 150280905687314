import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AppConstants } from '@app/app.constants';
import { EsgFocusDisclosureComponent } from '../esg-focus-disclosure/esg-focus-disclosure.component';
import { EsgReportsComponent } from '../esg-reports/esg-reports.component';

@Component({
  selector: 'app-esg-reports-section',
  templateUrl: './esg-reports-section.component.html',
  styleUrls: ['./esg-reports-section.component.scss']
})
export class EsgReportsSectionComponent implements OnInit {
  @Input()
  investorId: number;

  @ViewChild('thematicDisclosure') private thematicDisclosure: EsgFocusDisclosureComponent;
  @ViewChild('sectorDisclosure') private sectorDisclosure: EsgFocusDisclosureComponent;
  @ViewChild('quarterlyReport') private quarterlyReport: EsgReportsComponent;
  @ViewChild('annualReport') private annualReport: EsgReportsComponent;
  @ViewChild('esgIncorporationLei1') private esgIncorporationLei1: EsgReportsComponent;
  @ViewChild('esgIncorporationLei2') private esgIncorporationLei2: EsgReportsComponent;
  @ViewChild('engagementOutputsOutcomes') private engagementOutputsOutcomes: EsgReportsComponent;

  quarterlyReportType = AppConstants.INVESTOR_REPORTS_TYPES.INVESTOR_ESG_QUARTERLY_REPORTS;
  annuallyReportType = AppConstants.INVESTOR_REPORTS_TYPES.INVESTOR_ESG_ANNUALLY_REPORTS;
  incorporationLei1ReportType = AppConstants.INVESTOR_REPORTS_TYPES.INVESTOR_ESG_PRI_ESG_INCORPORATION_LEI_1_REPORTS;
  incorporationLei12ReportType = AppConstants.INVESTOR_REPORTS_TYPES.INVESTOR_ESG_PRI_ESG_INCORPORATION_LEI_12_13_REPORTS;
  engagementReportType = AppConstants.INVESTOR_REPORTS_TYPES.INVESTOR_ESG_PRI_ENGAGEMENT_REPORTS;
  sectorFocusDisclosure = AppConstants.INVESTOR_REPORTS_TYPES.INVESTOR_ESG_SECTOR_FOCUS_DISCLOSURE;
  thematicFocusDisclosure = AppConstants.INVESTOR_REPORTS_TYPES.INVESTOR_ESG_THEMATIC_FOCUS_DISCLOSURE;

  isEdit: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  onCancelClick() {
    this.reloadSectionData();
    this.isEdit = false;
  }

  onEditBtnClick() {
    this.isEdit = true;
  }

  onSaveBtnClick() {
    this.thematicDisclosure.onSaveFocusDisclosure();
    this.sectorDisclosure.onSaveFocusDisclosure();

    this.isEdit = false;
  }

  reloadSectionData() {
    this.quarterlyReport.getReportsExistence();
    this.annualReport.getReportsExistence();
    this.esgIncorporationLei1.getReportsExistence();
    this.esgIncorporationLei2.getReportsExistence();
    this.engagementOutputsOutcomes.getReportsExistence();

    this.thematicDisclosure.getEsgDisclosureByIdSubscriber();
    this.sectorDisclosure.getEsgDisclosureByIdSubscriber();
  }
}
