import {Component, Input, OnInit} from '@angular/core';
import {convertPascalCaseToString, convertToStringIfBoolean} from '@app/utility/Strings';
import {isDate} from '@app/utility/Dates';

@Component({
  selector: 'app-voting-logs-insert-segment',
  templateUrl: './voting-logs-insert-segment.component.html',
  styleUrls: ['voting-logs-insert-segment.component.scss']
})

export class VotingLogsInsertSegmentComponent implements OnInit {
  _data;
  isOpen = false;
  @Input() public data: any;

  constructor() {
  }

  ngOnInit() {

    const values = this.data.ColumnValues;
    this._data = Object.keys(values).map(key => {
      return {
        key: convertPascalCaseToString(key),
        value: isDate(values[key]) ? new Date(values[key]).toLocaleString() : convertToStringIfBoolean(values[key]) || 'Empty',
      };
    });
  }
}
