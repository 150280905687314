import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnDestroy, ElementRef, OnInit } from '@angular/core';
import AutocompleteOption from '@app/dto/common/autocomplete-option';
import { debounceTime, takeUntil, filter, map } from 'rxjs/operators';
import { Subject, fromEvent } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public required = false;
  @Input() public allowEmpty = false;
  @Input() public placeholder: string;
  @Input() public label: string;
  @Input() public floatLabel = 'always';
  @Input() public id: string;
  @Input() public disabled = false;
  @Input() public hide = false;
  @Input() public options: AutocompleteOption[] = [];
  @Input() public panelWidth = 300;
  @Input() public debounceTime = 500;
  @Input() public clearAutocompleteSubject = new Subject<void>();
  @Input() public minSearchLength: number;
  @Output() public blur = new EventEmitter<void>();
  @Output() public valueChange = new EventEmitter<string>();
  @Output() public optionSelected = new EventEmitter<AutocompleteOption>();
  @Input() public value: string;
  @ViewChild('input') public input: ElementRef;
  private unsubscribe = new Subject<void>();

  constructor() {}

  public ngOnInit(): void {
    this.clearAutocompleteSubject.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(() => {
      this.input.nativeElement.value = '';

      if (!this.minSearchLength) {
        this.valueChange.emit('');
      }
    });
  }

  public ngAfterViewInit(): void {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(this.debounceTime),
        map((event: KeyboardEvent) => (event.target as HTMLInputElement).value),
        filter((value: string) => {
          if (this.minSearchLength && value.length <= this.minSearchLength) {
            this.optionSelected.emit(null);

            return false;
          }

          return true;
        })
      ).subscribe((value: string) => {
        this.valueChange.emit(value);
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onBlur(): void {
    this.blur.emit();
  }

  public onOptionSelect(event: MatAutocompleteSelectedEvent): void {
    this.optionSelected.emit(event.option.value);
  }

  public displayProperty(value) {
    if (value) {
      return value.name;
    }
  }

  clear() {
    this.clearAutocompleteSubject.next();
  }
}
