import {RoleGuardService} from '@app/auth/role-guard.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable, of, EMPTY, empty} from 'rxjs';
import {PpBreadcrumbsResolver, Breadcrumb} from 'pp-breadcrumbs';
import {ClientsService} from '@app/services/clients.service';
import {mergeMap, catchError} from 'rxjs/operators';

@Injectable()
export class ClientsResolver extends PpBreadcrumbsResolver {
  constructor(private clientsService: ClientsService, private roleGuardService: RoleGuardService, private router: Router) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Promise<Breadcrumb[]> | Breadcrumb[] {
    const id = +route.params['id'];
    if (id === 0) {
      if (!this.roleGuardService.hasAccess('clients', 'CREATE')) {
        this.router.navigate(['clients']);
        return EMPTY;
      }
      return of([{
        text: 'Add new client',
        path: this.getFullPath(route)
      }]);
    } else {
      return this.roleGuardService.hasAccess('clients', 'EDIT') ?
        (this.clientsService.getClient(id, true)
          .pipe(catchError(error => {
            return EMPTY;
          }), mergeMap(data => {
            if (data) {
              return of([{
                text: data.company || 'Add new client',
                path: this.getFullPath(route)
              }]);
            } else {
              return EMPTY;
            }
          }))) : EMPTY;
    }
  }
}
