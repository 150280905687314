import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-resolution-classification-dialog',
  templateUrl: './resolution-classification-dialog.component.html',
  styleUrls: ['./resolution-classification-dialog.component.scss']
})
export class ResolutionClassificationDialogComponent implements OnInit {
  public _formValid = false;

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              private dialogRef: MatDialogRef<ResolutionClassificationDialogComponent>) {
  }

  ngOnInit() {
  }

  setFormValidation(valid) {
    this._formValid = valid;
  }

  formSaved(flag) {
    this.dialogRef.close(flag);
  }
}
