import { Directive, OnInit, Input, ElementRef  } from '@angular/core';
import { AppConstants } from '@app/app.constants';
import { ProjectTemplateService } from '@app/service/projectTemplateService';

@Directive({
  selector: '[appAlignCellBasedOnProjectTemplate]'
})
export class AlignCellBasedOnProjectTemplateDirective implements OnInit {
  @Input() projectTemplate: string;
  @Input() isChild: boolean;

  constructor(
    private elementRef: ElementRef,
    private projectTemplateService: ProjectTemplateService
  ) {
  }

  ngOnInit() {
    if (this.projectTemplate === this.projectTemplateService.SHAREHOLDER_MEETING_TEMPLATE_NAME) {
      if (this.isChild) {
        this.addClass(AppConstants.CHILD_RECOMMENDATION_CLASS);
      }
    } else {
      this.addClass(AppConstants.PAD_TEXT_RIGHT_CLASS);
    }
  }

  private addClass(className: string): void {
    this.elementRef.nativeElement.classList.add(className);
  }
}
