import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ClearingHouseAddEditComponent} from '@app/admin/clearing-houses/clearing-house-add-edit/clearing-house-add-edit.component';

@Component({
  selector: 'app-clearing-house-dialog',
  templateUrl: './clearing-house-dialog.component.html',
  styleUrls: ['./clearing-house-dialog.component.scss']
})
export class ClearingHouseDialogComponent implements OnInit {
  public _formValid = false;
  @ViewChild('clearinghouse') clearinghouse: ClearingHouseAddEditComponent;
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              private dialogRef: MatDialogRef<ClearingHouseDialogComponent>) {
  }

  ngOnInit() {
  }

  setFormValidation(valid) {
    this._formValid = valid;
  }

  formSaved(flag) {
    this.dialogRef.close(flag);
  }

  onClickReset() {
    this.clearinghouse.reset();
  }
}
