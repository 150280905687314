import {DialogGeneralTemplateConfig} from '@app/services/dialogs/dialog-general-template.config';

export function DeleteCodeTemplate(_params) {
  return new DialogGeneralTemplateConfig({
    title: `Delete ${_params.title}`,
    message: _params.message,
    config: {
      okButton: {
        text:'OK'
      }
    }
  }, _params);
}
