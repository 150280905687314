import { Component, OnInit } from '@angular/core';
import { InvestorValueCountryConfiguration } from '../investor-value-country-grid/investor-value-country-configuration';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DirtyComponent } from '@app/common/dirty-component';

@Component({
  selector: 'app-investor-intel-esg',
  templateUrl: './investor-intel-esg.component.html',
  styleUrls: ['./investor-intel-esg.component.scss']
})
export class InvestorIntelEsgComponent implements OnInit, DirtyComponent {

  isChild = true; // it is used in the landing component to determine childs
  esgModel : InvestorValueCountryConfiguration;
  investorId: number;
  isEdit: boolean = false;

  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();

  constructor(private route: ActivatedRoute) {
    this.esgModel = new InvestorValueCountryConfiguration();
    this.esgModel.header = "Esg providers and associations";
  }
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.investorId = +params['id'];

      this.esgModel = new InvestorValueCountryConfiguration();
      this.esgModel.investorId = this.investorId;
      this.esgModel.header = "Esg Providers and Association";
      this.esgModel.inputPlaceholder = "Esg";
      this.esgModel.gridHeader = "Esg";
      this.esgModel.addButtonCaption = "Add Esg";
      this.esgModel.apiEndpointPath = "investorintelligence/esg";
      this.esgModel.countryEditable = true;
    });

    this.isDirty$.subscribe(data => {
      return of(data);
    });
  }

  onEditBtnClick() {
    this.isEdit = true;
    this.isDirty.next(this.isEdit);
  }

  onCancelBtnClick() {
    this.isEdit = false;
    this.isDirty.next(this.isEdit);
  }

  public onSaveBtnClick() {
    this.isEdit = false;
    this.isDirty.next(this.isEdit);
  }

  DirtyCheck(value: any){
    this.isDirty.next(value);
  }
}
