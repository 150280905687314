export class InvestorVote {
    checked: boolean;
    financialId: number;
    shareCapitalId: number;
    relatedProjectItemId: number;
    templateProp1: string;
    templateProp2: string;
    intention: number;
    intentionType: string;
    voted: number;
    votedType: string;
    insertTime: string;
    insertBy: number;
    updateTime: string;
    updateBy: number;
    active: number;
    versionId: number;
    voteId?: number;
    hasSplitVote: boolean;
    primaryVoteId: number;
    projectTypeId: number;
    userId: number;
    isInlineVote:boolean;
    votingItemType: string;
}

export class SaveVotesDTO {
    votesToDelete: InvestorVote[];
    votesToUpdate: InvestorVote[];
  }
