import {DialogGeneralTemplateConfig} from '@app/services/dialogs/dialog-general-template.config';

export function CopyCustomFieldsTemplate(_params = {}) {

  return new DialogGeneralTemplateConfig({
    title: 'Copy Parameter Fields',
    message: 'Are you sure you want to copy the parameter fields from <br /><strong>"${message}"</strong>?',
    config: {
      okButton: {
        text: 'COPY',
      },
      cancelButton: {
        text: 'CANCEL'
      }
    }
  }, _params);
}
