import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AlertService} from '@app/services/alert.service';
import {NameValueModel} from '@app/models/basic-structures';
import {Esg} from '@app/dto/Esg';
import {AdminEsgService} from '@app/services/admin/admin-esg.service';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-esg-pa-add-edit',
  templateUrl: './esg-pa-add-edit.component.html',
  styleUrls: ['esg-pa-add-edit.component.scss'],
})
export class EsgPaAddEditComponent implements OnInit, AfterViewInit {
  loading: boolean = false;
  _memoryModel: Esg;
  _model: Esg = new Esg();
  @Input() set model(value) {
    if (value) {
      this.loading=true;
      this._model = value;
      this._memoryModel = JSON.parse(JSON.stringify(value));
    }
  }
  @ViewChild('genericForm') genericForm: any;
  @Input() isEdit: boolean = false;
  @Input() dialog: boolean = false;
  @Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isFormSaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  websiteControl = new FormControl();
  
  constructor(private alertService: AlertService,
              private adminEsgService: AdminEsgService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  edit(flag) {
    this.isEdit = flag;
    if (!flag) {
      this.reset();
    }
  }

  reset() {
    if (this._memoryModel) {
      this._model = JSON.parse(JSON.stringify(this._memoryModel));
    } else {
      this.genericForm.reset();
    }
  }

  save() {
    const modelAction = {
      action: 'create',
      successMsg: 'ESG record has been added successfully!'
    };
    if (this._model.esgId > 0) {
      modelAction.action = 'update';
      modelAction.successMsg = 'ESG record has been updated successfully!';
    }
    this.saveEsg(modelAction);
  }

  saveEsg({action, successMsg}) {
    this.adminEsgService[action](this._model).subscribe(
      data => {
        this._memoryModel = data;
        this.alertService.sendSuccess(successMsg);
        this.isFormSaved.emit(data);
      },
      err => {
        this.alertService.sendError(err.error.Message);
      }
    );
  }

  countrySelectionChanged(newCountry: NameValueModel) {
    this._model.country = newCountry.name;
  }

  citySelectionChanged(city: NameValueModel) {
    this._model.city = city.name;
  }

  ngAfterViewInit() {
    this.genericForm.statusChanges.subscribe(value => {
      const valid = value === 'VALID';
      this.isFormValid.emit(valid);
    });
  }

}
