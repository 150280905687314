import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntelligenceProxyDataDto } from '@app/dto/IntelligenceProxyDataDto';
import { InvestorsIntelligenceService } from '@app/services/investors';
import { AlertService } from '@app/services/alert.service';
import { InvestorValueCountryConfiguration } from '../investor-value-country-grid/investor-value-country-configuration';
import { DirtyComponent } from '@app/common/dirty-component';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'app-investor-proxy',
  templateUrl: './investor-proxy.component.html',
  styleUrls: ['./investor-proxy.component.scss']
})
export class InvestorProxyComponent implements OnInit, DirtyComponent {
  isChild = true; // it is used in the landing component to determine childs
  modelProxy: IntelligenceProxyDataDto;
  isEdit: boolean = false;
  proxyAdvisorModel: InvestorValueCountryConfiguration;
  votingBehaviorModel: InvestorValueCountryConfiguration;
  investorId;
  routeSub: any;

  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();

  constructor(private route: ActivatedRoute,
    private investorsIntelligenceService: InvestorsIntelligenceService, private alertService: AlertService) {
    this.modelProxy = new IntelligenceProxyDataDto();
  }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe((params) => {
      this.investorId = +params['id'];
      this.getProxy(this.investorId);

      this.buildProxyAdvisorGridModel();
      this.buildVotingBehaviorGridModel();
  });

    this.isDirty$.subscribe(data => {
      return of(data)
    });
  }

  getProxy(investorId: number) {
    this.investorsIntelligenceService.getProxy(investorId).subscribe(
      data => {
        this.modelProxy = data;
      },
      err => {
        this.alertService.sendError(JSON.stringify(err));
      }
    );
  }

  updateProxy(proxy: IntelligenceProxyDataDto) {
    this.investorsIntelligenceService.updateProxy(proxy).subscribe(
      () => {
        this.alertService.sendSuccess("Proxy was updated successfully!");
        this.getProxy(this.investorId);
      },
      err => {
        let errorMsg: string = 'error ' + JSON.stringify(err);
        this.alertService.sendError(errorMsg);
      }
    );
  }

  onSaveBtnClick() {
    this.modelProxy.investorId = this.investorId;
    this.updateProxy(this.modelProxy);
    this.isEdit = false;
    this.isDirty.next(this.isEdit);
  }

  onCancelClick() {
    this.isEdit = false;
    this.isDirty.next(this.isEdit);
    this.getProxy(this.investorId);
  }

  onEditBtnClick(){
    this.isEdit = true;
    this.isDirty.next(this.isEdit);
  }

  private buildProxyAdvisorGridModel() {
    this.proxyAdvisorModel = new InvestorValueCountryConfiguration();
    this.proxyAdvisorModel.investorId = this.investorId;
    this.proxyAdvisorModel.header = "Proxy Advisors";
    this.proxyAdvisorModel.inputPlaceholder = "Proxy Advisor";
    this.proxyAdvisorModel.gridHeader = "Proxy Advisor";
    this.proxyAdvisorModel.addButtonCaption = "Add Proxy Advisor";
    this.proxyAdvisorModel.apiEndpointPath = "investorintelligence/proxy-advisors";
    this.proxyAdvisorModel.countryEditable = true;
    this.proxyAdvisorModel.displayExtensionFields = true;
    this.proxyAdvisorModel.displayParticipationPolicy = false;
  }

  private buildVotingBehaviorGridModel(){
    this.votingBehaviorModel = new InvestorValueCountryConfiguration();
    this.votingBehaviorModel.investorId = this.investorId;
    this.votingBehaviorModel.header = "Voting Behaviors";
    this.votingBehaviorModel.inputPlaceholder = "Voting Behavior";
    this.votingBehaviorModel.gridHeader = "Voting Behavior";
    this.votingBehaviorModel.addButtonCaption = "Add Voting Behavior";
    this.votingBehaviorModel.apiEndpointPath = "investorintelligence/voting-behaviors";
    this.votingBehaviorModel.countryEditable = true;
    this.votingBehaviorModel.displayExtensionFields = true;
    this.votingBehaviorModel.displayParticipationPolicy = true;
    this.votingBehaviorModel.isEditable = true;
  }
}
