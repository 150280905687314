import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { CustodianReportDTO } from "@app/dashboard/dashboardau/custodian-report-dialog/CustodianReportDTO";
import { environment } from "@src/environments/environment";
import { Observable } from "rxjs-compat";

@Injectable({
  providedIn: 'root'
})

export class CustodianReportService {
  constructor(private http: HttpClient) {
  }

  public generateSummaryReport(model: CustodianReportDTO): Observable<any> {
    return this.http.post(`${environment.serverUrl}dashboards/custodian-summary-report`, model, { responseType: 'blob', observe: 'response' });
  }

  public generateDailySummaryReport(model: CustodianReportDTO): Observable<any> {
    return this.http.post(`${environment.serverUrl}dashboards/custodian-daily-summary-report`, model, { responseType: 'blob', observe: 'response' });
  }
}