import { Component, Input, OnInit } from '@angular/core';
import { Lookup } from '@app/dto/Lookup';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { VoteTableDto } from '@app/dto/VoteTableDto';

@Component({
  selector: 'app-vote-table',
  templateUrl: './vote-table.component.html',
  styleUrls: ['./vote-table.component.scss']
})
export class VoteTableComponent implements OnInit {
  @Input() isEdit: boolean;
  @Input() projectTemplate: string;
  @Input() placeholder: string;
  @Input() voteLabel: string;
  @Input() dataSource: VoteTableDto[];
  @Input() projectTemplateServiceMethod = 'getAdvisorVoteTypes';

  public displayedColumns = ['description', 'recommendation'];
  private frequencyVoteTypes: string[] | Lookup[] = [];
  private advisorVoteTypes: Lookup[] = [];
  private allVoteTypes: any[];

  constructor(
    private projectTemplateService: ProjectTemplateService
  ) { }

  ngOnInit() {
    const freqVoteTypes = this.projectTemplateService.hasFrequency(this.dataSource)
      ? this.projectTemplateService.getFrequencyVoteTypes(false)
      : [];
    this.frequencyVoteTypes = this.projectTemplateService.getFrequencyVoteTypes(true);
    this.advisorVoteTypes = this.projectTemplateService[this.projectTemplateServiceMethod](this.projectTemplate);
    this.allVoteTypes = this.projectTemplateService[this.projectTemplateServiceMethod](this.projectTemplate, freqVoteTypes);
  }

  public getClassName(voteType: string): string {
    return 'vote ' + (voteType || '')
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/1/, 'one')
      .replace(/2/, 'two')
      .replace(/3/, 'three');
  }

  public getColor(voteType: string): string {
    if (!voteType) {
      return null;
    }

    const item = this.allVoteTypes.find(x => x.fieldLabel.toUpperCase() === voteType.toUpperCase());

    if (item) {
      return item.color;
    }
  }

  public getOptions(element: VoteTableDto): string[] | Lookup[] {
    return /^frequency/gmi.exec(element.votingItemType) ? this.frequencyVoteTypes : this.advisorVoteTypes;
  }
}
