import { RoleGuardService } from './../auth/role-guard.service';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Observable, of, EMPTY } from 'rxjs';
import {PpBreadcrumbsResolver, Breadcrumb} from 'pp-breadcrumbs';
import { InvestorsService} from '@app/services/investors';
import { mergeMap, catchError} from 'rxjs/operators';

@Injectable()
export class InvestorsResolver extends PpBreadcrumbsResolver {
  constructor(private investorsService: InvestorsService, private roleGuardService: RoleGuardService, private router: Router) {
    super();
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Promise<Breadcrumb[]> | Breadcrumb[] {
    const investorId = route.params['invid'];

    if (investorId == 0) {
      if(!this.roleGuardService.hasAccess("investors","CREATE")){
        this.router.navigate(['investors']);
        return EMPTY}
      return of([{
        text: 'Add new investor',
        path: this.getFullPath(route)
      }]);
    } else {
      return this.investorsService.getInvestor(investorId, true).pipe(catchError(error => {
          return EMPTY;
        }), mergeMap(data => {
          if (data) {
            return of([{
              text: data.company,
              path: this.getFullPath(route)
            }]);
          } else {
            return EMPTY;
          }
        })
      );
    }
  }

}
