import { Component, OnInit, DoCheck, Inject, LOCALE_ID } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { InvestorsIntelligenceService } from '@app/services/investors';
import { InvestorIntelInvestmentFunds } from '@app/dto/InvestorIntelDto';
import { AlertService } from '@app/services/alert.service';

@Component({
  selector: 'app-investor-intel-esg-investment-funds',
  templateUrl: './investor-intel-esg-investment-funds.component.html',
  styleUrls: ['./investor-intel-esg-investment-funds.component.scss']
})
export class InvestorIntelEsgInvestmentFundsComponent implements OnInit, DoCheck {

  public investorId: number;

  public isEdit = false;

  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();

  public morning;
  public bloomberg;
  public cdp;
  public other;
  public details;
  public eurosifTransparencyCode: boolean = false;
  public eurosifFunds;
  public fundEcomarket;
  public fossilFuelsSourceInformation;
  public sum;

  public constructor(@Inject(LOCALE_ID)
    private locale:string,
    private route: ActivatedRoute,
    private router: Router,
    private investorIntelService: InvestorsIntelligenceService,
    private alertService: AlertService
  ) { }

  public currencyData = {
    name: 'amount',
    placeholder: 'VALUE OF TOTAL SUSTAINABLE FUNDS:',
    amount: null,
    currency: null,
    currencyName: 'curr'
  };

  public currencyFossilFuels = {
    name: 'fossilFuels',
    placeholder: 'EXPOSURE TO FOSSIL FUELS:',
    amount: null,
    currency: null,
    currencyName: 'curr'
  };

  ngOnInit() {
    this.route.parent.params.subscribe((params) => {
      this.investorId = params.invid;
      this.investorIntelService.getInvestmentFunds(this.investorId).subscribe(res => {
        this.morning = res.morningStarESGFunds;
        this.bloomberg = res.bloomingESGFunds;
        this.cdp = res.cdpESGFunds;
        this.other = this.transformAmount(res.otherESGFunds);
        this.details = res.otherESGFundsDetails;
        this.currencyData.amount = res.totalSustainableFunds;
        this.currencyData.currency = res.totalSustainableFundsCurrency;
        this.eurosifTransparencyCode = res.eurosifTransparencyCode;
        this.eurosifFunds = res.eurosifFunds;
        this.fundEcomarket = res.fundEcomarket;
        this.currencyFossilFuels.amount = res.exposureToFossilFuels;
        this.currencyFossilFuels.currency = res.exposureToFossilFuelsCurrency;
        this.fossilFuelsSourceInformation = res.exposureToFossilFuelsSourceInformation;
      });
    });
    this.isDirty$.subscribe(data => {
      return of(data);
    });
  }

  public onCancelBtnClick() {
    this.isEdit = false;
    this.isDirty.next(this.isEdit);
  }

  public onSaveBtnClick() {
    this.isEdit = false;
    this.isDirty.next(this.isEdit);
    this.investorIntelService.setInvestmentFunds(this.getDTO()).subscribe(res => {
      this.alertService.sendSuccess('ESG Funds Updated!');
    }, err => {
      this.alertService.sendError(err.statusText);
    });
  }

  public getDTO() {
    const dto = new InvestorIntelInvestmentFunds();
    dto.investorId = Number(this.investorId);
    dto.morningStarESGFunds = (this.morning) ? Number(this.morning) : null;
    dto.bloomingESGFunds = (this.bloomberg) ? Number(this.bloomberg) : null;
    dto.cdpESGFunds = (this.cdp) ? Number(this.cdp) : null;
    dto.otherESGFunds = (this.other) ? Number(this.other.replace(/\,/g,'')): null;
    dto.otherESGFundsDetails = (this.details) ? this.details : null;
    dto.totalSustainableFunds = (this.currencyData.amount) ? Number(this.currencyData.amount) : null;
    dto.totalSustainableFundsCurrency = (this.currencyData.currency) ? this.currencyData.currency : null;
    dto.eurosifTransparencyCode = this.eurosifTransparencyCode;
    dto.eurosifFunds = (this.eurosifFunds) && this.eurosifTransparencyCode? Number(this.eurosifFunds) : null;
    dto.fundEcomarket = (this.fundEcomarket) ? Number(this.fundEcomarket) : null;
    dto.exposureToFossilFuels = (this.currencyFossilFuels.amount) ? Number(this.currencyFossilFuels.amount) : null;
    dto.exposureToFossilFuelsCurrency = (this.currencyFossilFuels.currency) ? this.currencyFossilFuels.currency : null;
    dto.exposureToFossilFuelsSourceInformation = (this.fossilFuelsSourceInformation) ? this.fossilFuelsSourceInformation : null;

    return dto;
  }

  public onEditBtnClick() {
    this.isEdit = true;
    this.isDirty.next(this.isEdit);
  }

  public ngDoCheck() {
    this.sum = 0;
    if (Number(this.morning)) {
      this.sum += Number(this.morning);
    }
    if (Number(this.bloomberg)) {
      this.sum += Number(this.bloomberg);
    }
    if (Number(this.cdp)) {
      this.sum += Number(this.cdp);
    }
    if (Number(this.other)) {
      this.sum += Number(this.other);
    }
  }

  transformAmount(value){
    if(value && value!="" && value !="-"){
      let onlyNumbers = value.toString().includes(",")? value.replaceAll(",","") : value;
      var result =onlyNumbers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return this.other = result;
    }
  }
}
