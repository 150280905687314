import { Component, Input, OnInit } from '@angular/core';
import { UsActivityItemInfo } from '@app/dto/UsActivityDashboardInfo';

const statusMapping = {
  'Not Started': 'activity-not-started',
  'In Progress': 'activity-in-progress',
  Completed: 'activity-completed'
}

@Component({
  selector: 'app-us-activity-item',
  templateUrl: './us-activity-item.component.html',
  styleUrls: ['./us-activity-item.component.scss']
})
export class UsActivityItemComponent implements OnInit {
  @Input() activity: UsActivityItemInfo;
  activityClassName: string;

  constructor() { }

  ngOnInit(): void {
    this.activityClassName = statusMapping[this.activity.status];
  }

  buttonEnter(trigger) {
    trigger.openMenu();
  }

  buttonLeave(trigger) {
    trigger.closeMenu();
  }
}