export class VotingItemDto {
    itemId: number;
    projectId: number;
    templateProperty1: string;
    templateProperty2: string;
    date?: Date;
    issClassificationId?: number;
    IssClassification: string;
    totalNumberOfCustomFields: number;
    numberOfCompleteCustomFields: number;
    votingItemType: string;
    votingItemChildren: string[];
    parentVotingItemChildren: any[];
    boardOfDirectorsRecommendation: string;
}

export class VotingItemDropdownDto {
    itemId: number;
    projectId: number;
    templateProperty1: string;
    templateProperty2: string;
    votingItemType: string;
    childVotingItemOrderId: number;
    parentVotingItemChildren: VotingItemDropdownDto[];
    boardOfDirectorsRecommendation: string;
}

export class VotingItemEditDto extends VotingItemDto {
    votingItemTypeId: number;
    customFields: CustomFieldDto[];
}

export class CustomFieldDto {
    id: number;
    resolutionClassificationCustomFieldId: number;
    fieldTypeId: number;
    fieldName: string;
    numericValue?: number;
    singleSelectValue?: number;
    options: CustomFieldOptionDto[];
    multiSelectSelectedOptions: number[];
}

export class CustomFieldOptionDto {
    id: number;
    display: string;
}
