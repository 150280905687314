import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatSortable } from '@angular/material';
import { CallLog } from '@app/dto/CallLog';

import { MatDialog, } from '@angular/material/dialog';
import { CommentEditDialogComponent } from '@app/dashboard/dashboardau/detail/investor-call-management/comments-table/comment-edit-dialog/comment-edit-dialog.component';
import { AlertService } from '@app/services/alert.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { ValidationHelper } from '@app/helper/validationHelper';
import {DialogsService} from '@app/services/dialogs/dialogs.service';
import {DeleteProjectTemplate} from '@app/services/dialogs/dialog-delete-template.config';

@Component({
  selector: 'app-investor-call-management-comment-table',
  templateUrl: './investor-call-management-comment-table.component.html',
  styleUrls: ['./investor-call-management-comment-table.component.scss']
})
export class InvestorCallManagementCommentTableComponent implements OnInit {
  _data: CallLog[] = [];
  validationHelper = new ValidationHelper();
  displayCalllogColumns: string[] = [
    'contactName',
    'username',
    'comment',
    'entryDate',
    'followup',
    'followupStatus',
    'updateTime',
    'action'
  ];

  get dsCallLog(): MatTableDataSource<CallLog> {
    const dsCallLog = new MatTableDataSource<CallLog>();
    dsCallLog.data = this._data.sort((a, b) => (a.callLogId < b.callLogId) ? 1 : ((b.callLogId < a.callLogId) ? -1 : 0));
    return dsCallLog;
  }

  @Input()
  set data(data: CallLog[]) {
    this._data = data;
    this.showGridWithData();
  }

  @Input()
  set addRecord(data) {
    if (data) {
      if (!this.validationHelper.isValidDate(data.followup)) {
        data.followup = null;
      }
      this._data.push(data);
      this.showGridWithData();
    }
  }

  @ViewChild(MatSort) matSort: MatSort;

  noItemsText:string='Call Logs';
  showGrid:boolean=true;

  constructor(
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private alertService: AlertService,
    private dialogsService: DialogsService
  ) {
  }

  ngOnInit() {

  }

showGridWithData(){
  this.showGrid = this._data.length > 0;
}

  onCompleteCallLog(element: CallLog) {
    this.dashboardService.completeCallLog(element.projectId, element.callLogId)
      .subscribe(r => {
        element.followupCompleted = true;
        this.alertService.sendSuccess('Call Log Completed!')
      },
        e => this.alertService.sendError(e.message));
  }

  onEditBtnClick(element) {
    const dialogRef = this.dialog.open(CommentEditDialogComponent, {
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        if (!this.validationHelper.isValidDate(result.data.followup)) {
          result.data.followup = undefined;
        }
        result.data.updateTime = new Date();
        this._data = this._data.map(row => result.data.callLogId === row.callLogId ? result.data : row);

        this.updateCallFeedback(result.data);
      }
    });
  }

  onDeleteBtnClick(element) {
    this.dialogsService.confirm('Delete Comment', 'Are you sure you want to delete that comment?', {
        okButton: {
          text: 'DELETE',
          class: 'ms-btn-red-delete',
          icon: 'ms-delete-red-icon'
        },
        cancelButton: {
          text: 'CANCEL'
        }
      });
    this.dialogsService.close$.subscribe(async result => {
      if (result) {
        try {
          const res = await this.dashboardService.deleteCallFeedback(element);
          if (res) {
            this._data = this._data.filter(x => x.callLogId !== element.callLogId);
            this.showGridWithData();
            this.alertService.sendSuccess('Feedback is deleted.');
          }
        } catch (err) {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        }
      }
    });
  }

  updateCallFeedback(record) {
    const self = this;
    this.dashboardService.saveCallFeedback(record).subscribe(
      data => {
        this.alertService.sendSuccess('Feedback is updated.');

      },
      err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      }
    );
  }
}
