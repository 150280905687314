import { Injectable } from '@angular/core';
import { Campaign } from '@app/dto/campaign';
import { HttpClient } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(
    private http: HttpClient
  ) { }

  public getProjectCampaigns(projectId: number): Observable<Campaign[]> {
    return new Observable((observer) => {
      const url = `${environment.serverUrl}noborh/campaigns/${projectId}`;

      return this.http.get<Campaign[]>(url).subscribe(
        (response) => {
          const campaigns = response.map(r => new Campaign(r));

          observer.next(campaigns);
        },
        (error) => observer.error(error),
        () => observer.complete()
      );
    });
  }

  public generateExport(campaignId: number): Observable<any> {
    const url = `${environment.serverUrl}noborh/export-live-votes`;

    return this.http.post<any>(url, { campaignId });
  }
}
