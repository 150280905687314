import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-external-advisors',
  templateUrl: './external-advisors.component.html'
})
export class ExternalAdvisorsComponent implements OnInit, OnDestroy {
  isChild = true;

  constructor(private router: Router) {

  }


  ngOnInit() {
  }

  ngOnDestroy() {

  }
}
