import {Component, OnInit} from '@angular/core';
import {NavigationTriggerService} from '@app/services/admin/navigation-trigger.service';

@Component({
  selector: 'app-beneficiary-landing',
  templateUrl: './beneficiary-landing.component.html',
  styleUrls: ['./beneficiary-landing.component.scss']
})
export class BeneficiaryLandingComponent implements OnInit {

  constructor(private navigationTriggerService: NavigationTriggerService) { }

  ngOnInit() {
    this.navigationTriggerService.setSubMenu(false);
  }

}
