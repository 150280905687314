import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@app/../environments/environment';
import {Observable} from 'rxjs';
import {ClearingHouse} from '@app/dto/ClearingHouse';
import {Project} from '@app/dto/Project';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {LocalCustodian} from '@app/dto/LocalCustodian';

@Injectable({
  providedIn: 'root'
})
export class ClearingHouseService {
  private cache$: Observable<ClearingHouse>[] = [];

  constructor(private http: HttpClient) {
  }

  public getClearingHouseList(): Observable<ClearingHouse[]> {
    const url = `${environment.serverUrl}clearinghouses`;
    return this.http.get<ClearingHouse[]>(url);
  }

  public get(id: number, useCache: boolean = false): Observable<ClearingHouse> {
    if (!this.cache$[id] || !useCache) {
      this.cache$[id] = this.http
        .get<ClearingHouse>(`${environment.serverUrl}clearinghouses/${id}`).pipe(
          map(data => data),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        );
    }

    return this.cache$[id];
  }

  public create(model: ClearingHouse): Observable<ClearingHouse> {
    return this.http.post<ClearingHouse>(`${environment.serverUrl}clearinghouses`, model);
  }

  public update(model: ClearingHouse): Observable<ClearingHouse> {
    return this.http.put<ClearingHouse>(`${environment.serverUrl}clearinghouses`, model);
  }

  clearCache(id: number | null = null) {
    if (this.cache$ && id) {
      this.cache$[id] = null;
    } else {
      this.cache$ = null;
    }
  }

}
