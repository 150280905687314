import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-actions',
  templateUrl: './dashboard-actions.component.html',
  styleUrls: ['./dashboard-actions.component.scss']
})
export class DashboardActionsComponent {
_projectId: string;

 @Input() set projectId(projectId) {
   this._projectId = projectId;
 }

 get projectId() {
   return this._projectId;
 }
}
