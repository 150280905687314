import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminLandingComponent} from '@app/admin/admin-landing.component';
import {AdminNavComponent} from '@app/admin/admin-nav.component';
import {ComponentsModule} from '@app/components/components.module';
import {AdminRoutingModule} from '@app/admin/admin-routing.module';
import {ExternalAdvisorsModule} from '@app/admin/external-advisors/external-advisors.module';
import {GlobalCustodiansModule} from '@app/admin/global-custodians/global-custodians.module';
import { MatButtonModule } from '@angular/material';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        AdminRoutingModule,
        ExternalAdvisorsModule,
        GlobalCustodiansModule,
        MatButtonModule,
    ],
  declarations: [
    AdminLandingComponent,
    AdminNavComponent,
  ],
  exports: [
    AdminNavComponent
  ],
  bootstrap: [AdminLandingComponent]
})
export class AdminModule {}
