import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@app/../environments/environment';
import {Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';
import { ProxyAdvisor } from '@app/dto/ProxyAdvisor';

@Injectable({
  providedIn: 'root'
})
export class AdminProxyAdvisorService {
  private cache$: Observable<ProxyAdvisor>[] = [];

  constructor(private http: HttpClient) {
  }

  public get(id: number, useCache: boolean = false): Observable<ProxyAdvisor> {
    if (!this.cache$[id] || !useCache) {
      this.cache$[id] = this.http
        .get<ProxyAdvisor>(`${environment.serverUrl}ProxyAdvisors/${id}`).pipe(
          map(data => data),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        );
    }

    return this.cache$[id];
  }

  public updateProxyAdvisor(proxyAdvisor: ProxyAdvisor) : Observable<ProxyAdvisor> {
    return this.http.put<ProxyAdvisor>(`${environment.serverUrl}ProxyAdvisors/`, proxyAdvisor);
  }

  public addProxyAdvisor(proxyAdvisor: ProxyAdvisor) : Observable<ProxyAdvisor> {
    return this.http.post<ProxyAdvisor>(`${environment.serverUrl}ProxyAdvisors/`, proxyAdvisor);
  }
  clearCache(id: number | null = null) {
    if (this.cache$ && id) {
      this.cache$[id] = null;
    } else {
      this.cache$ = null;
    }
  }
}
