import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { Lookup } from '@app/dto/LookUp';
import { ProjectMailingDocumentDTO } from '@app/dto/ProjectMailingDocumentDTO';
import { LookupFilter, Utils } from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { AlertService } from '@app/services/alert.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-dashboard-mailing-add-doc',
  templateUrl: './dashboard-mailing-add-doc-component.html'
})
export class DashboardAddMailingDocumentComponent implements OnInit {
  documentNames: Lookup[] = [];
  util = new Utils(this.startupdataproviderService);
  model: ProjectMailingDocumentDTO;
  disableForm = true;
  isEdit  = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DashboardAddMailingDocumentComponent>,
    private startupdataproviderService: StartupdataproviderService,
    private dashboardService: DashboardService,
    private alertService: AlertService,
  ) {
    this.model = new ProjectMailingDocumentDTO();
    if ( data.mailingDocumentId ) {
      this.model = data;
      this.isEdit = true;
    } else {
      this.model.projectId = data.projectId;
    }
  }


  ngOnInit(): void {
    this.util.getLookupByType(LookupFilter.PROJECT_MAILING_DOC).subscribe(data => {
      if(data.length >0){
        this.documentNames = data.sort((a,b)=>(a.fieldLabel > b.fieldLabel ? 1: -1))
      }
    });
  }

  onClickSave() {
    const action = this.isEdit ? 'editMailingDocuments' : 'addMailingDocuments';
    this.dashboardService[action](this.model).subscribe(data => {
      this.alertService.sendSuccess(`Mailing document ${this.isEdit ? 'updated' : 'added'}!`);
      this.dialogRef.close();
    },
      e => this.alertService.alert(e)
    );
  }

  onCloseBtnClick(): void {
    this.dialogRef.close();
  }
}
