import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Lookup } from '../dto/Lookup';
import { Region } from '../dto/Region';
import { SubRegion } from '../dto/SubRegion';
import { StartupDataResponse } from '../dto/StartupDataResponse';
import { Observable } from 'rxjs';
import { map, share, shareReplay } from 'rxjs/operators';
import { CountryGroup } from '../dto/CountryGroup';
import { CityGroup } from '../dto/CityGroup';
import { City } from '../dto/City';
import { Currency } from '../dto/Currency';

@Injectable()
export class StartupdataproviderService {
  private regionarray: Region[] = [];
  private subregionarray: SubRegion[] = [];
  private citiesArray: City[];
  groupedCountries: CountryGroup[] = [];
  groupedCities: CityGroup[] = [];
  private startupdatacache$: Observable<StartupDataResponse>;
  CACHE_SIZE: number = 1;
  private lookupsCache$: Observable<Lookup[]>;

  constructor(private httpClient: HttpClient) {
  }

  startupdata() {
    if (!this.startupdatacache$) {
      this.startupdatacache$ = this.requestStartupData();
    }

    this.startupdatacache$
      .subscribe(c => {
        this.regionarray = c.regions as Region[];
      });

    this.startupdatacache$.subscribe(c => {
      this.citiesArray = c.cities;
      this.groupedCities = this.groupCity();
    });
    this.subregionarray = this.getSubRegions(this.regionarray);
    this.groupCountry(this.regionarray);

  }

  requestStartupData(): Observable<StartupDataResponse> {
    let apiURL: string = environment.serverUrl + 'lookups/GetStartupDataResponse';
    return this.httpClient.get<StartupDataResponse>(apiURL).pipe(share());
  }

  Regions(): Region[] {
    return this.regionarray;
  }
  SubRegions(): SubRegion[] {
    return this.subregionarray;
  }

  Countries(): CountryGroup[] {
    return this.groupedCountries;
  }
  Cities(): CityGroup[] {
    return this.groupedCities;
  }

  getSubRegions(regionArray: Region[]): SubRegion[] {
    let arr: SubRegion[] = [];
    regionArray.map(e => e.subRegion)
      .map(function (element) {
        element.map(function (element1) {
          arr.push(element1);
        });
      });
    return arr;
  }

  getCurrencies(): Observable<Currency[]> {
    return this.startupdatacache$.pipe(map(d => d.currencies));
  }

  getLookups(): Observable<Lookup[]> {
    let apiURL: string = environment.serverUrl + 'lookups/GetLookup';
    if (!this.lookupsCache$) {
      this.lookupsCache$ = this.httpClient.get<Lookup[]>(apiURL).pipe(shareReplay(1));
    }

    return this.lookupsCache$;
  }

  load() {
    this.startupdata();
  }

  groupCountry(regions: Region[]): CountryGroup[] {
    if (this.groupedCountries.length) {
      return this.groupedCountries;
    }

    regions.forEach((country: Region) => {
      const groupIndex = this.groupedCountries.findIndex((item: CountryGroup) => {
        return item.letter === country.firstChar;
      });
      groupIndex !== -1 ? this.groupedCountries[groupIndex].names.push(country.country)
        : this.groupedCountries.push({ letter: country.firstChar, names: [country.country] });
    });

    return this.groupedCountries;
  }
  groupCity(): CityGroup[] {

    if ((!this.groupedCities || this.groupedCities.length == 0) && this.citiesArray) {
      this.citiesArray.forEach((x: City) => {
        //Assume list is ordered
        let firstChar = x.cityName.substring(0, 1);
        let tmpGrp: CityGroup = this.groupedCities.find((y: CityGroup) => {
          return y.letter == firstChar;
        });
        if (tmpGrp) {
          tmpGrp.names.push(x.cityName);
        }
        else {
          this.groupedCities.push({ letter: firstChar, names: [x.cityName] });
        }
      });
    }
    return this.groupedCities;
  }
}

export function startupdataProviderFactory(provider: StartupdataproviderService) {
  return () => provider.load();
}
