import { HttpResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { OptionsPickerComponent } from '@app/components/options-picker/options-picker.component';
import {
  InvestorIntelGridAddDto,
  InvestorValueCountryGridLookupDto,
  AccountNumber
} from '@app/investor/investordetail/investor-value-country-grid/investor-value-country-grid-source-dto';
import { InvestorValueCountryGridService } from '@app/services/investors';
import { AlertService } from '@app/services/alert.service';

@Component({
  selector: 'app-add-investor-custodian-dlg',
  templateUrl: './add-investor-custodian-dlg.component.html',
  styleUrls: ['./add-investor-custodian-dlg.component.scss']
})
export class AddInvestorCustodianDlgComponent implements OnInit {
  lookups: InvestorValueCountryGridLookupDto[] = [];
  lookupsFiltered: InvestorValueCountryGridLookupDto[] = [];
  formGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<AddInvestorCustodianDlgComponent>,
    private service: InvestorValueCountryGridService,
  ) {
    const accountNumbers = data.investorCustodian.accountNumbers.length
      ? data.investorCustodian.accountNumbers
      : [new AccountNumber()];

    this.formGroup = new FormGroup({
      accountNumbers: new FormArray(accountNumbers.map(x => {
        return new FormGroup({
          number: new FormControl(x.number),
          id: new FormControl(x.id),
          deleted: new FormControl()
        });
      })),
      relatedEntityId: new FormControl(data.investorCustodian.relatedEntityId, Validators.required),
      relatedEntityName: new FormControl(data.investorCustodian.name, Validators.required),
      investorId: new FormControl(data.investorId, Validators.required),
      country: new FormControl(data.investorCustodian.country),
      iso3: new FormControl(data.investorCustodian.iso3)
    });
  }

  ngOnInit() {
    this.initData();

    this.formGroup.controls.accountNumbers.valueChanges.subscribe((value) => {
      const duplicates = {};
      const values = {};

      value.forEach((element: AccountNumber) => {
        const { number } = element;

        if (values[number] !== undefined && number && element.deleted !== true) {
          duplicates[number] = '';
        }

        values[number] = '';
      });

      this.formGroup.get('accountNumbers')['controls'].forEach((x) => {
        if (duplicates[x.value.number] !== undefined) {
          x.controls.number.setErrors({ nonUnique: true });
        } else {
          x.controls.number.setErrors(null);
        }
      });
    });
  }

  initData(): void {
    const custodianOptions = this.data.isGlobalCustodian
      ? this.service.getGlobalCustodianOptions()
      : this.service.getLocalCustodianOptions();

    custodianOptions.subscribe(data => {
      this.lookups = data;
    });
  }

  public onSelectedCustodianChange(option: MatAutocompleteSelectedEvent): void {
    const { value } = option.option;
    const { id } = value;

    this.updateForm({
      id: value.id,
      name: value.name,
      investorId: this.data.investorId,
      country: value.country,
      iso3: value.iso3
    });
  }

  public addItem(): void {
    const accountNumbers = this.formGroup.get('accountNumbers') as FormArray;

    accountNumbers.push(
      new FormGroup({
        number: new FormControl(),
        id: new FormControl(),
        deleted: new FormControl(false)
      })
    );
  }

  public deleteItem(index: number): void {
    const accountNumbers = this.formGroup.get('accountNumbers') as FormArray;
    const accountNumber = accountNumbers.controls[index];

    if (accountNumber.value.id) {
      accountNumber.value.deleted = true;
    } else {
      accountNumbers.removeAt(index);
    }
  }

  public onUpsertCustodian(): void {
    const formData = {
      ...this.formGroup.value,
      accountNumbers: this.formGroup.value.accountNumbers
        .filter(x => x.number)
    };

    this.ref.close(formData);
  }

  public onCustodianSearchChange(search): void {
    search = search || '';
    this.lookupsFiltered = this.lookups
      .filter(x => x.name && x.name.toLocaleLowerCase().indexOf(search.toLowerCase()) > -1);

    this.updateForm({
      investorId: this.data.investorId
    });
  }

  public custodianSearchDisplayWith($event): string {
    $event = $event || {};

    return $event.name;
  }

  private updateForm(form: any): void {
    this.formGroup.controls.relatedEntityId.setValue(form.id);
    this.formGroup.controls.relatedEntityName.setValue(form.name);
    this.formGroup.controls.investorId.setValue(form.investorId);

    if (form.country && form.iso3) {
      this.formGroup.controls.country.setValue(form.country);
      this.formGroup.controls.iso3.setValue(form.iso3);
    }
  }
}
