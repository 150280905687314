import { Component, OnInit, Input } from '@angular/core';
import { BeneficiaryType } from '@app/enums/BeneficiaryType';
import { BeneficiaryService } from '@app/services/admin/beneficiary.service';
import { AlertService } from '@app/services/alert.service';
import { convertPascalCaseToString } from '@app/utility/Strings';

@Component({
  selector: 'app-beneficiary-logs-delete-segment',
  templateUrl: './beneficiary-logs-delete-segment.component.html',
  styleUrls: ['./beneficiary-logs-delete-segment.component.scss']
})
export class BeneficiaryLogsDeleteSegmentComponent implements OnInit {
  private static readonly EMPTY = '( Empty )';
  _data;
  isOpen = false;
  @Input() public data: any;
  beneficiaryName : any;
  beneficiaryType : BeneficiaryType;
  constructor(private beneficiaryService: BeneficiaryService,
              private alertService: AlertService) { }

  ngOnInit() {
    this.beneficiaryType = this.data.Table =="InvestorAssetManagerLookup" ? BeneficiaryType.AssetManager : 
                           this.data.Table == "InvestorFundLookup" ? BeneficiaryType.Fund :
                           this.data.Table == "InvestorBeneficiary"? BeneficiaryType.DecisionMaker : BeneficiaryType.AssetManager;
    this.beneficiaryService.getBeneficiary(this.data.PrimaryKey.Id, this.beneficiaryType)
                            .subscribe(b=> { this.beneficiaryName = b.name},
                            err => {
                              this.alertService.sendError(err.error);
                            });
    const oldValues = this.data['Changes'][0];
    const values = this.data.ColumnValues;
    let oldValue =oldValues['OriginalValue']  ;
    this._data = Object.keys(values).map(key => {
      return {
        value: values[key] ==null ? oldValue : values[key]
      };
    });
    
    
  }
}
