export default class AutocompleteOption {
  public id: number;
  public name: string;
  public disabled: boolean;
  public subText: string;

  constructor(init = {}) {
    Object.assign(this, init);
  }
}
