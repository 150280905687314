import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@app/../environments/environment';
import {Observable} from 'rxjs';
import {GlobalCustodian} from '@app/dto/GlobalCustodian';
import {map, publishReplay, refCount} from 'rxjs/operators';
import { SearchDto } from '@app/service/search-grid/SearchDto';

@Injectable({
  providedIn: 'root'
})
export class GlobalCustodiansService {
  private cache$: Observable<GlobalCustodian>[] = [];

  constructor(private http: HttpClient) {
  }

  public getGlobalCustodiansList(pageIndex: number, pageSize:number): Observable<any> {
    let searchDto = new SearchDto();
        searchDto.pageIndex = pageIndex;
        searchDto.pageSize = pageSize;
    const url = `${environment.serverUrl}globalcustodians/global-custodians-list`;
    return this.http.post<any>(url, searchDto);
  }

  public get(id: number, useCache: boolean = false): Observable<GlobalCustodian> {
    if (!this.cache$[id] || !useCache) {
      this.cache$[id] = this.http
        .get<GlobalCustodian>(`${environment.serverUrl}globalcustodians/${id}`).pipe(
          map(data => data),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        );
    }

    return this.cache$[id];
  }

  public create(model: GlobalCustodian) : Observable<GlobalCustodian> {
    return this.http.post<GlobalCustodian>(`${environment.serverUrl}globalcustodians`, model);
  }

  public update(model: GlobalCustodian) : Observable<GlobalCustodian> {
    return this.http.put<GlobalCustodian>(`${environment.serverUrl}globalcustodians`, model);
  }

  clearCache(id: number | null = null) {
    if (this.cache$ && id) {
      this.cache$[id] = null;
    } else {
      this.cache$ = null;
    }
  }
}
