import {Component, OnInit} from '@angular/core';
import {DocumentConfiguration} from '@app/components/documents/document-configuration';
import {CustomFields, FieldOptionsType} from '@app/components/documents/CustomFields';
import {LookupFilter} from '@app/helper/utils';
import {DocumentDialogConfiguration} from '@app/components/documents/document-dialog-configuration';
import {CustomFieldType} from '@app/enums/CustomFieldType';
import {ActivatedRoute} from '@angular/router';
import {DocumentRelatedEntityType} from '@app/enums/DocumentRelatedEntityType';
import { RoleGuardService } from '@app/auth/role-guard.service';

@Component({
  selector: 'app-client-documents',
  templateUrl: './client-documents.component.html',
  styleUrls: ['client-documents.component.scss']
})
export class ClientDocumentsComponent implements OnInit {
  documentConfiguration: DocumentConfiguration;
  relatedEntityId;
  canEdit = false;
  canView = false;
  relatedEntityType: DocumentRelatedEntityType = DocumentRelatedEntityType.Client;

  constructor(private route: ActivatedRoute,
    private roleGuardService: RoleGuardService) {

  }

  ngOnInit() {
    this.canView = this.roleGuardService.hasAccess('clients', 'general');
    this.canEdit = this.roleGuardService.hasAccess('clients','edit');

    this.buildConfig();
    this.route.parent.params.subscribe((params) => {
      this.relatedEntityId = params['id'];
    });
  }

  buildConfig() {
    this.documentConfiguration = new DocumentConfiguration();
    this.documentConfiguration.documentComponentOuterDivMargins = '';
    this.documentConfiguration.dialogConfiguration = new DocumentDialogConfiguration();
    this.documentConfiguration.dialogConfiguration.customField = new CustomFields();
    this.documentConfiguration.dialogConfiguration.customField.fieldName = 'Documents type';
    this.documentConfiguration.dialogConfiguration.customField.fieldOptions = FieldOptionsType.DropDown;
    this.documentConfiguration.dialogConfiguration.customField.fieldType = CustomFieldType.SingleSelect;
    this.documentConfiguration.dialogConfiguration.customField.listType = LookupFilter.CLDOCTYPE;
    this.documentConfiguration.dialogConfiguration.customField.showField = true;
    this.documentConfiguration.columnsNames = ['FILE NAME', 'DESCRIPTION', '', 'Documents type'];
  }

}
