import {Injectable} from '@angular/core';
import {Observable, of, pipe} from 'rxjs';
import {CanDeactivate} from '@angular/router';
import {switchMap, map, take} from 'rxjs/operators';
import {DirtyComponent} from './dirty-component';
import {MatDialog} from '@angular/material';
import {ConfirmDialogComponent} from '@app/components/confirm-dialog/confirm-dialog.component';

@Injectable({providedIn: 'root'})
export class DirtyCheckGuard implements CanDeactivate<DirtyComponent> {
  constructor(public dialog: MatDialog) {
  }

  canDeactivate(component: DirtyComponent) {
    return component.isDirty$.pipe(switchMap(dirty => {
      if (dirty === false) {
        return of(true);
      }

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        autoFocus: true
      });
      return dialogRef.afterClosed().pipe(
        switchMap(result => {

          const firstInputElement = document.getElementsByTagName('input')[0];

          // We doing this dirty hack because to remove the focus
          if (!result && firstInputElement) {
            firstInputElement.focus();
            firstInputElement.blur();
          }

          return of(result);
        })
      );
    }), take(1));
  }
}
