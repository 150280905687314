import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatIconRegistry, MatPaginator, MatSort, MatSortable, MatTableDataSource } from '@angular/material';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { AlertService } from '@app/services/alert.service';
import { Lookup } from '@app/dto/Lookup';
import { SearchGridService } from '@app/service/search-grid/search-grid.service';
import { GridFilterItem, GridFilterModel } from '@app/components/grid-filter/grid-filter.models';
import { HttpClient } from '@angular/common/http';
import { FilterDto, SortDto } from '@app/service/search-grid/SearchDto';
import { ProjectsService } from '@app/services/projects.service';
import { DomSanitizer } from '@angular/platform-browser';
import { combineLatest } from 'rxjs';
import { VotingItemsService } from '@app/services/voting-items.service';
import { VotingItemDropdownDto } from '@app/dto/VotingItemDto';
import { Utils } from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { CustodianDetailsComponent } from '@app/dashboard/dashboardau/dashboard-custodian-table/custodian-details/custodian-details.component';
import { CommonUtilityService } from '@app/utility/common-utility.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConstants } from '@app/app.constants';
import { ShareTypesAdapter } from '@app/dashboard/dashboardau/dashboard-custodian-table/custodian-details/interfaces/share-types.interface';
import { BrokerTypeDialogComponent } from '@app/project/projectdetail/custodial-list/broker-type-dialog/broker-type-dialog.component';

@Component({
  selector: 'app-dashboard-custodian-table',
  templateUrl: './dashboard-custodian-table.component.html',
  styleUrls: ['./dashboard-custodian-table.component.scss']
})
export class DashboardCustodianTableComponent implements OnInit, OnDestroy {
  _dataSource = new MatTableDataSource();
  dataSource;
  loaded = true;
  orgDisplayedColumns: string[] = [
    'custodian',
    'codes',
    'type',
    'shares',
    'votes',
    'notVoted'
  ];
  displayedColumns: string[] = [];
  displayDynamicColumns: DynamicColumnDefinition[] = [];
  searchService: SearchGridService;
  filter: GridFilterModel = new GridFilterModel({
    gridFilterItems: [
      new GridFilterItem({ filterPorp: 'ProjectId', value: [''] }),
      new GridFilterItem({ filterPorp: 'ShareType', value: [''] }),
      new GridFilterItem({ filterPorp: 'VotingItemId', value: [''] }),
    ]
  });
  defaultShareType: ShareTypesAdapter = new ShareTypesAdapter({ cusip: null, shareClassification: 'Total' });
  shareTypes: ShareTypesAdapter[] = [];
  orgShareTypes: ShareTypesAdapter[] = [];
  custodianVotingTypes: Lookup[] = [];
  defaultVotingTypes: VotingItemDropdownDto = new VotingItemDropdownDto();
  votingTypes: VotingItemDropdownDto[] = [];
  voteTitle: string;
  showGrid = false;
  private customPageSize = 25;
  private additionalFilter: FilterDto = null;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() projectId: number;
  shareTypeTemplate: string;
  util = new Utils(this.startupdataproviderService);
  totalRow = { shares: 0, votes: 0 };
  globalHoldings = 0;

  constructor(private projectTemplateService: ProjectTemplateService,
              private alertService: AlertService,
              private httpClient: HttpClient,
              private iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer,
              private projectsService: ProjectsService,
              private votingItemsService: VotingItemsService,
              private projectService: ProjectsService,
              private startupdataproviderService: StartupdataproviderService,
              public dialog: MatDialog,
              private commonUtility: CommonUtilityService,
              private spinnerService: NgxSpinnerService) {
    iconRegistry.addSvgIcon(
      'search',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/outline-search-24px.svg'));
  }

  ngOnInit() {

    this.filter.gridFilterItems[0] = new GridFilterItem({ filterPorp: 'ProjectId', value: [`${this.projectId}`] });
    this.getData();

    combineLatest(
      this.projectTemplateService.getProjectTemplate(this.projectId),
      this.votingItemsService.getVotingItemsDropdown(this.projectId),
      this.projectService.getProjectFinancial(this.projectId),
      this.projectsService.getProject(this.projectId, true),
      (shareTypes, votingItems, projectFinancials, projectData) => ({shareTypes, votingItems, projectFinancials, projectData})
    )
    .subscribe(pair => {
      this.shareTypeTemplate = pair.shareTypes.template;
      this.voteTitle = this.projectTemplateService.getProjectItemsTemplateLabels(pair.shareTypes.template).templateProp2Label;

      const additional = this.projectTemplateService.hasFrequency(pair.votingItems)
        ? this.projectTemplateService.getFrequencyVoteTypes(
          false,
          'CUSTODIAL_RECONCILIATION',
          [],
          this.projectTemplateService.isFrequencyWithReverseOrder(pair.votingItems))
        : [];
      this.custodianVotingTypes = this.projectTemplateService.getCustodialReconciliationVotedTypes(pair.shareTypes.template, additional);

      this.orgShareTypes = pair.projectFinancials.projectFinancialDetails
        .map(item => ( new ShareTypesAdapter({cusip: item.cusip, shareClassification: item.shareClassification})));
      // With this we make the array with only unique values
      this.shareTypes = Array.from(new Set([...this.orgShareTypes, this.defaultShareType]));
      if (pair.projectData.marketCoverage === 'US') {
        this.defaultShareType = this.shareTypes[0];
      }
      this.filter.gridFilterItems[1] = new GridFilterItem(
        { filterPorp: 'ShareType', value: [`${this.defaultShareType.shareClassification}`] }
      );

      this.setVotingItems(pair.votingItems);

      this.loaded = true;
      this.searchService.onFilterChanged(this.filter);
    }, err => {
      this.alertService.sendError(' error ' + JSON.stringify(err));
    });
  }

  getData() {
    this.searchService =
      new SearchGridService(
        this.paginator,
        this._dataSource,
        this.httpClient,
        'custodian/project-custodians',
        this.filter,
        null,
        this.customPageSize,
        false,
        false,
        [new SortDto({sortPorp: 'allShares', sortOrder: 'desc'})]
      );

    this.spinnerService.show('custodian-table');

    this.searchService.allData.subscribe(data => {
      if (typeof data.items !== 'undefined' && data.items.length > 0) {
        this.showGrid = true;
        this.setColumns();
        this.setTotalRow(data);
        this.setTableRows(data);
      } else {
        this.showGrid = false;
        this.dataSource = new MatTableDataSource();
        this.dataSource.data = [];
      }

      if (typeof data.items !== 'undefined') {
        this.spinnerService.hide('custodian-table');
      }
    });
  }

  setColumns() {
    this.displayedColumns = Object.assign([], this.orgDisplayedColumns);
    this.displayDynamicColumns = [];

    this.custodianVotingTypes.forEach(shareType => {
      const coldef = new DynamicColumnDefinition();
      coldef.colName = `${shareType.fieldValue}`;
      coldef.colHeaderName = shareType.fieldLabel;
      coldef.colHeaderColor = shareType.color;

      this.displayedColumns.push(`${shareType.fieldValue}`);
      this.displayDynamicColumns.push(coldef);
    });
  }

  setTotalRow(data) {
    const notVoted = data.additionalData.allShares - data.additionalData.voted;

    const row = {
      shares: data.additionalData.allShares,
      votes: data.additionalData.voted,
      notVoted: notVoted,
      percentage: this.calcPercent(data.additionalData.voted, data.additionalData.allShares),
      notVotedPercentage: this.calcPercent(notVoted, data.additionalData.allShares)
    };

    const custodianVotes = data.additionalData.custodianVotes;
    this.custodianVotingTypes.forEach(shareType => {
      const result = custodianVotes.find(vote => vote.voteType === shareType.fieldValue);
      row[`${shareType.fieldValue}`] = result
        ? {
          votes: result.vote,
          percentage: this.calcPercent(result.vote, row.shares)
        }
        : { votes: 0, percentage: '0%' };
    });

    this.totalRow = row;
  }

  setTableRows(data) {
    const tableRows = data.items.reduce((accumulator, item, currentIndex) => {
      const type = item.isGlobalCustodian ? 'GLOBAL' : 'LOCAL';

      const shares = !item.isGlobalCustodian ?
                        item.allShares :
                        !!item.globalCustodianChildrenShares ?
                            item.globalCustodianChildrenShares :
                            item.allShares;
      const notVoted = shares - item.voted;

      const record = {
        custodian: item.custudianName,
        codes: item.codes,
        id: item.projectCustudianId,
        type,
        parentName: item.parentName,
        parentCode: item.parentCode,
        globalCustodianChildrenShares: item.globalCustodianChildrenShares,
        shares: item.allShares,
        votes: item.voted,
        notVoted: notVoted,
        percentage: this.calcPercent(item.voted, shares),
        notVotedPercentage: this.calcPercent(notVoted, shares)
      };

      this.custodianVotingTypes.forEach(shareType => {
        const result = item.custodianVotes.find(vote => vote.voteType === shareType.fieldValue);

        record[`${shareType.fieldValue}`] = result
          ? {
            votes: result.vote,
            percentage: this.calcPercent(result.vote, shares)
          }
          : { votes: 0, percentage: '0%' };
      });

      accumulator.push(record);

      return accumulator;
    }, []);

    this.dataSource = new MatTableDataSource();
    this.dataSource.data = tableRows;
  }

  setVotingItems(votingItems: VotingItemDropdownDto[]) {
    const votingItemDrodpdown = votingItems.reduce((accumulator, item) => {
      const votingItem = {
        itemId: item.itemId,
        projectId: item.projectId,
        templateProperty1: item.templateProperty1,
        templateProperty2: item.templateProperty2,
        votingItemType: item.votingItemType,
        childVotingItemOrderId: item.childVotingItemOrderId,
        isDisabled: item.parentVotingItemChildren.length > 0,
        isChild: false
      };

      accumulator.push(votingItem);

        item.parentVotingItemChildren.forEach(child => {
          const childVotingItem = {
            itemId: child.itemId,
            projectId: child.projectId,
            templateProperty1: item.templateProperty1,
            templateProperty2: `${this.commonUtility.indexToLetter(child.childVotingItemOrderId, false)}.${child.templateProperty2}`,
            votingItemType: child.votingItemType,
            childVotingItemOrderId: child.childVotingItemOrderId,
            isDisabled: false,
            isChild: true
          };

          accumulator.push(childVotingItem);
        });

      return accumulator;
    }, []);

    if (votingItemDrodpdown.length) {
      this.defaultVotingTypes = votingItemDrodpdown[0].isDisabled ? votingItemDrodpdown[1] : votingItemDrodpdown[0];
      this.filter.gridFilterItems[2] = new GridFilterItem({filterPorp: 'VotingItemId', value: [`${this.defaultVotingTypes.itemId}`]});
    }

    this.votingTypes = votingItemDrodpdown;
  }

  filterGrid(value, filterId) {
    if (filterId === 1) {
      this.filter.gridFilterItems[1] = new GridFilterItem({filterPorp: 'ShareType', value: [`${value.shareClassification}`]});
    } else if (filterId === 2) {
      this.filter.gridFilterItems[2] = new GridFilterItem({filterPorp: 'VotingItemId', value: [`${value.itemId}`]});
    }

    this.searchService.onFilterChanged(this.filter);
  }

  openCustodianDetails(item) {
    const dialogRef = this.dialog.open(CustodianDetailsComponent, {
      data: {
        projectId: this.projectId,
        projectCustodianId: item.id,
        defaultShareType: this.defaultShareType
      },
      width: '1100px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.searchService.reloadItems();
      }

    });
  }

  ngOnDestroy(): void {
    this.searchService.unSubscribe();
  }

  calcPercent = (item, all) => {
    return `${(item === 0 || all === 0) ? 0 : ((item / all) * 100).toFixed(AppConstants.DECIMALS_FIX)}%`;
  }

  onViewReport(){
    const dialogRef = this.dialog.open(BrokerTypeDialogComponent, {
      data: {projectId: this.projectId},
      width: '800px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }
}

export class DynamicColumnDefinition {
  colName: string;
  colHeaderName: string;
  colHeaderColor: string;
}
