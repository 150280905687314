import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "./app-config.service";
import { Observable, BehaviorSubject } from "rxjs";
import { map, finalize } from "rxjs/operators";
import { environment } from '../../environments/environment';
import { ProjectActivityGrid } from "@app/dto/ProjectActivityGrid";
import { FollowUpGridWithCounter } from "@app/dashboard/dashboardau/dashboard-actions/FollowUpGridWithCounter";
import { ProjectActivityGridWithCounter } from "@app/dashboard/dashboardau/dashboard-actions/dashboard-project-activity/ProjectProjectActivityGridWithCounter";
import { FollowUpCallsWithCounterDto } from "@app/dto/FollowUpCallsWithCounterDto";
import { SortDto } from "@app/service/search-grid/SearchDto";
import { SelectOptions } from '@app/components/table-filter-wrapper/interfaces';
import { UsActivityDashboardGeneralInfo } from "@app/dto/UsActivityDashboardInfo";

@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  constructor(private http: HttpClient, private appConfigService: AppConfigService) { }

  public activityUpdatedSubject = new BehaviorSubject<boolean>(false);

  public getActionCount(projectId: number): Observable<number> {
    return this.http.get<number>(`${environment.serverUrl}actions/${projectId}/count`).pipe(
      map(data => data)
    );
  }

  public getFollowups(projectId: number, skip: number, take: number): Observable<FollowUpCallsWithCounterDto[]> {
    return this.http.get<FollowUpCallsWithCounterDto[]>(`${environment.serverUrl}actions/followups/${projectId}/${skip}/${take}`);
  }

  public getUpcomingFollowups(projectId: number, skip: number, take: number): Observable<FollowUpGridWithCounter> {
    return this.http.get<FollowUpGridWithCounter>(`${environment.serverUrl}actions/upcoming-followups/${projectId}/${skip}/${take}`);
  }

  public getUpcomingFollowupsPersonal(skip: number, take: number, userName: string): Observable<FollowUpGridWithCounter> {
    return this.http.get<FollowUpGridWithCounter>(`${environment.serverUrl}actions/upcoming-followups-personal/${skip}/${take}/${userName}`);
  }

  public getFollowUps(userName: string, skip: number, take: number): Observable<FollowUpCallsWithCounterDto[]> {
    return this.http.get<FollowUpCallsWithCounterDto[]>(`${environment.serverUrl}actions/outstanding-calls/${userName}/${skip}/${take}`);
  }

  public getActivities(projectId: number, skip: number, take: number): Observable<ProjectActivityGridWithCounter> {
    return this.http.get<ProjectActivityGridWithCounter>(`${environment.serverUrl}actions/activities/${projectId}/${skip}/${take}`);
  }

  public getActivitiesPersonal(skip: number, take: number, userEmail: string): Observable<ProjectActivityGridWithCounter> {
    return this.http.get<ProjectActivityGridWithCounter>(`${environment.serverUrl}actions/activities-personal/${userEmail}/${skip}/${take}`);
  }

  public getProjectActivitiesForGrid(id: number, skip: number, take: number) {
    return this.http.get<ProjectActivityGridWithCounter>(`${environment.serverUrl}actions/project-activities-by-id/${id}/${skip}/${take}`);
  }

  public updateActivityStatus(id: number, status: string) {
    return this.http.put(`${environment.serverUrl}actions/activities/${id}/${status}`, null)
    .pipe(finalize(() => {
      this.activityUpdatedSubject.next(true);
    }));
  }

  public getProjectActivitiesCount(id: number) {
    return this.http.get<number>(`${environment.serverUrl}actions/project-activities-count/${id}`);
  }

  public getActivitiesPerInvestor(projectId: number, investorId: number): Observable<ProjectActivityGrid[]> {
    return this.http.get<ProjectActivityGrid[]>(`${environment.serverUrl}dashboards/project-activities-per-investor/${projectId}/${investorId}`);
  }

  public getStatusOptions(): Observable<SelectOptions[]> {
    return this.http.get<SelectOptions[]>(`${environment.serverUrl}actions/user-personal-activities/status-options`);
  }

  public getUsActivityDashboardInfo(projectId: number): Observable<UsActivityDashboardGeneralInfo> {
    return this.http.get<UsActivityDashboardGeneralInfo>(`${environment.serverUrl}actions/us-activity-dashboard-info/${projectId}`)
  }
}
