export class ProjectEvent {
  internalIndex?: number;
  projectEventId?: number;
  projectId: number;
  date: Date;
  description: string;
  isDefault: boolean;
  defaultEventCoverage:string;
  isRequired: boolean;

  public constructor(init?: Partial<ProjectEvent>) {
    Object.assign(this, init);
  }
}
