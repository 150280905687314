import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ProjectCustodianDto} from '@app/dto/ProjectCustodianDto';
import {ProjectsService} from '@app/services/projects.service';
import {AlertService} from '@app/services/alert.service';
import {Shareholdings} from '@app/dto/ProjectCustodialListDto';
import {MatSelect} from '@angular/material';
import { TypeAheadSearchComponent } from '@app/components/autocompletes/type-ahead-search/type-ahead-search.component';

@Component({
  selector: 'app-add-edit-custodial',
  templateUrl: './add-edit-custodial.component.html',
  styleUrls: ['./add-edit-custodial.component.scss']
})
export class AddEditCustodialComponent implements OnInit, AfterViewInit {
  update = false;
  _model: ProjectCustodianDto = new ProjectCustodianDto();
  _memoryModel: ProjectCustodianDto;
  _resetShareCapitals: Shareholdings[] = [];
  parentCustodians = [{
    globalCustodianId: 0,
    globalCustodianName: 'n / a',
    isGlobalCustodianAddedToProject: false
  }];
  noParent = true;
  projectShares = [];
  showParentCustodianField = false;


  @Input() set model(value) {
    if (!value.projectCustodianId) {
      this._model = JSON.parse(JSON.stringify(value));
      this._memoryModel = JSON.parse(JSON.stringify(value));
      this.getClassifications();
    }
    else {
      this.update = true;
      this.getProjectCustodianById(value.projectCustodianId);
    }
  }
  @Input() isEdit = false;
  @Input() dialog = false;
  @Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isFormSaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('genericForm') genericForm: any;
  @ViewChild('parentCustodian') parentCustodian: MatSelect;
  @ViewChild(TypeAheadSearchComponent) typeAheadSearch: TypeAheadSearchComponent;

  constructor(
    private alertService: AlertService,
    private projectsService: ProjectsService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }

  getCustodianName = (item) => {
    return item.codes && item.codes.length ? `${item.name} (${item.isGlobalCustodian? 'DTC':'BR'}: < ${(item.codes.map(code => code.name) || []).join(', ')}>)`: `${item.name}`;
  }

  setParentCustodian = (value) => {
    this._model.parentCustodianId = null;
    this._model.parentCustodianCode = null;
    if(value){
      this._model.parentCustodianId = value.globalCustodianId;
      this._model.parentCustodianCode = value.dtcCode;
    }

    this.checkIsFormValid()
  }

  getProjectCustodianById(projectCustodianId:number){
      this.projectsService.getProjectCustodianById(projectCustodianId).subscribe(data =>{
        this._model = JSON.parse(JSON.stringify(data));
        this._memoryModel = JSON.parse(JSON.stringify(data));

        this._resetShareCapitals = JSON.parse(JSON.stringify(this._model.shareCapitals));
        this.projectShares = this._model.shareCapitals;
        this.showParentCustodianField = !this._model.isGlobalCustodian;
        const valid = this._model.projectCustodianId > 0;
        this.isFormValid.emit(valid);
      })
  }

  getClassifications() {
    this.projectsService.getProjectShareClassificationsPair(this._model.projectId).subscribe(data => {
      this._model.shareCapitals = data.map( item => new Shareholdings({
        id: item.id,
        name: item.name,
        shareCapital: 0
      }));
      this._resetShareCapitals = JSON.parse(JSON.stringify(this._model.shareCapitals));
      this.projectShares = this._model.shareCapitals;
      this.cdRef.detectChanges();
    }, err => {
      this.alertService.sendError(' error ' + JSON.stringify(err));
    });
  }

  getParentCustodianName = (item) => item.dtcCode? `${item.globalCustodianName} (DTC: <${item.dtcCode}>)` : `${item.globalCustodianName}`;

  // tslint:disable-next-line: max-line-length
  getParentCustodianNameFromModel = (item) => item.parentCustodianCode? `${item.parentCustodian} (DTC: <${item.parentCustodianCode}>)` : `${item.parentCustodian}`;

  reset() {
    if (this._memoryModel.custodianId) {
      this._model = JSON.parse(JSON.stringify(this._memoryModel));
    } else {
      this._model = new ProjectCustodianDto(JSON.parse(JSON.stringify(this._memoryModel)));
      this._model.shareCapitals = this._resetShareCapitals;
      
      this.typeAheadSearch.resetValue();
      this.parentCustodians = [{
        globalCustodianId: 0,
        globalCustodianName: 'n / a',
        isGlobalCustodianAddedToProject: false
      }];
      this.setParentCustodianField(true);
      this.genericForm.reset();
    }
    this.checkIsFormValid();
  }

  save() {
    if (!this._model.parentCustodianId) {
      this._model.parentCustodianId = null;
    }
    this.projectsService.addProjectCustodian(this._model).subscribe(
      data => {
        this._memoryModel = data;
        this.alertService.sendSuccess(`Custodian has been ${this.update ? 'updated' : 'added' } successfully!`);
        this.isFormSaved.emit(data);
      },
      err => {
        this.alertService.sendError(err.error.Message);
      }
    );
  }

  onCustodianSelected(item) {
    this._model.parentCustodianId = null;
    this._model.parentCustodianCode = null;
    if (item.custodianId) {
      this._model.custodianId = item.custodianId;
      this._model.custodianCountry = item.country;
      this._model.custodianIdentifierId = item.custodianIdentifierId;
      this._model.isGlobalCustodian = item.isGlobalCustodian;
      const codes = item.isGlobalCustodian ? [item.codes.find(c => c.id === item.codeId)] : item.codes || [];
      this._model.codes = codes.filter(code => code);
      if (!item.isGlobalCustodian) {
        this.getParentCustodians(item.custodianId);
      }     
      this.setParentCustodianField(item.isGlobalCustodian);
    } else {
      this._model.custodianId = null;
      this._model.custodianCountry = null;
      this._model.custodianIdentifierId = null;
      this._model.codes = [];
      this.parentCustodians = [{
        globalCustodianId: 0,
        globalCustodianName: 'n / a',
        isGlobalCustodianAddedToProject: false
      }];
      this.setParentCustodianField(true);
    }
  }

  getParentCustodians(localCustodianId) {
    this.projectsService.getParentCustodians(this._model.projectId, localCustodianId).subscribe((data) => {
      this.showParentCustodianField = false;
      if ( data.length > 0) {
        this.setParentCustodianField(false);
        this.parentCustodians = data;
      } else {
        this.parentCustodians = [];
        this.setParentCustodianField(true);
        this._model.parentCustodianId = 0;
        this.parentCustodians = [{
          globalCustodianId: 0,
          globalCustodianName: 'n / a',
          isGlobalCustodianAddedToProject: false
        }];
      }
    });
  }

  private setParentCustodianField(disabled) {
    this.parentCustodian.disabled = disabled;
    this.showParentCustodianField = this.parentCustodian.required = !disabled;
    
    this.checkIsFormValid();
  }

  public setShareValue(value, type) {
    const shareCapitalItem = this._model.shareCapitals.findIndex(item => item.id === type.id);
    if (shareCapitalItem > -1) {
      this._model.shareCapitals[shareCapitalItem].shareCapital = +value;
    }
  }

  ngAfterViewInit() {
    this.genericForm.statusChanges.subscribe(value => {
      // tslint:disable-next-line: max-line-length
      const valid = this._model.custodianId > 0 && (!this.parentCustodian.required || (!!this.parentCustodian.required && this._model.parentCustodianId > 0));
      this.isFormValid.emit(valid);
    });
  }

  checkIsFormValid = () => {
    setTimeout(() => {
      // tslint:disable-next-line: max-line-length
      const valid = this._model.custodianId > 0 && (!this.parentCustodian.required || (!!this.parentCustodian.required && this._model.parentCustodianId > 0));
      this.isFormValid.emit(valid);

      return valid;
    });
  }
}
