import { FilterContainerType, FilterOperators } from '@app/components/table-filter-wrapper/enums';
import { FilterContainerOptionsDTO, FilterDTO } from '@app/components/table-filter-wrapper/interfaces';

export const myProjectsFilters = {
  name: new FilterContainerOptionsDTO({
    type: FilterContainerType.TEXT,
    fieldName: 'name',
    title: 'Filter by Project Name'
  }),
  clientName: new FilterContainerOptionsDTO({
    type: FilterContainerType.TEXT,
    fieldName: 'clientName',
    title: 'Filter by Client Name'
  }),
  projectManager: new FilterContainerOptionsDTO({
    type: FilterContainerType.TEXT,
    fieldName: 'manager',
    title: 'Filter by Project Manager'
  }),
  assistantManager: new FilterContainerOptionsDTO({
    type: FilterContainerType.TEXT,
    fieldName: 'assistant',
    title: 'Filter by Assistant Manager'
  }),
  startDate: new FilterContainerOptionsDTO({
    type: FilterContainerType.DATE_RANGE,
    fieldName: 'startDate',
    title: 'Filter by Start Date'
  }),
  endDate: new FilterContainerOptionsDTO({
    type: FilterContainerType.DATE_RANGE,
    fieldName: 'endDate',
    title: 'Filter by End Date'
  }),
  status: new FilterContainerOptionsDTO({
    type: FilterContainerType.MULTISELECT,
    fieldName: 'status',
    title: 'Filter by Project Status',
    defaultOptions: new FilterDTO('status', ['Active'], FilterOperators.IN),
    options: []
  }),
  market: new FilterContainerOptionsDTO({
    type: FilterContainerType.MULTISELECT,
    fieldName: 'regionId',
    title: 'Filter by Project Market',
    options: []
  }),
  type: new FilterContainerOptionsDTO({
    type: FilterContainerType.MULTISELECT,
    fieldName: 'projectTypeId',
    title: 'Filter by Project Type',
    options: []
  }),
  year: new FilterContainerOptionsDTO({
    type: FilterContainerType.MULTISELECT,
    fieldName: 'year',
    title: 'Filter by Project Year',
    options: []
  })
};
