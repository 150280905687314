import { element } from 'protractor';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IVotingPatternsScannedInvestorInterface, VotingPatternsScannedInvestorDto } from '@app/dto/VotingPatternsScannedInvestorDto';
import { MatDialog, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { NgForm } from '@angular/forms';
import { DashboardNoborhService } from '@app/services/dashboard/dashboard-noborh.service';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AlertService } from '@app/services/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { of, Observable, Subject } from 'rxjs';
import {
  FilterAndPaginateDTO,
  FilterAndPaginateOutputDTO,
  PaginationDTO,
  FilterDTO
} from '@app/components/table-filter-wrapper/interfaces';
import { FilterOperators } from '@app/components/table-filter-wrapper/enums';
import { NOBORHSummary } from '@app/dto/NOBORHSummary';
import AutocompleteOption from '@app/dto/common/autocomplete-option';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';

@Component({
  selector: 'app-voting-patterns-scanned-investor',
  templateUrl: './voting-patterns-scanned-investor.component.html',
  styleUrls: [
    './voting-patterns-scanned-investor.component.scss',
    '../styles/investor-voting-dialog.scss'
  ]
})
export class VotingPatternsScannedInvestorComponent implements OnInit, OnDestroy {
  @Input() private projectId;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('controlNumberForm') controlNumberForm: NgForm;

  public dataSource: MatTableDataSource<VotingPatternsScannedInvestorDto> = new MatTableDataSource<VotingPatternsScannedInvestorDto>([]);
  public displayedColumns = ['position', 'controlNumber', 'investorName', 'address', 'shares', 'action'];
  private unsubscribe$ = new Subject<void>();
  private getInvestorByControlNumberTrigger$ = new Subject<number>();
  private addedControlNumbersSet = new Set([]);
  private searchInvestorByNameFilter = new FilterAndPaginateDTO({
    pagination: new PaginationDTO(1, 100),
    filters: [
      new FilterDTO('investorName', [''], FilterOperators.SEARCH)
    ]
  });
  public noborhSummaryOptions: AutocompleteOption[] = [];
  public clearAutocompleteSubject = new Subject<void>();
  public selectedNoborhSummary: NOBORHSummary;
  private noborhSummaries: NOBORHSummary[] = [];

  constructor(
    private readonly dashboardNoborhService: DashboardNoborhService,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    private dialogsService: DialogsService,
  ) {}

  ngOnInit() {
    this.setData();

    this.getInvestorByControlNumberTrigger$
      .pipe(
        filter((value) => this.checkIfInvestorExist(value)),
        tap((value) => this.addInvestorForCheck(value)),
        switchMap((value) => this.searchForInvestor(value)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(
      data => this.addInvestor(data),
    );
  }

  public onValueChange(searchString: string): void {
    this.selectedNoborhSummary = null;
    this.searchInvestorByNameFilter.filters[0].value = [searchString];
    const filters = new FilterAndPaginateOutputDTO(this.searchInvestorByNameFilter);

    this.dashboardNoborhService.searchInvestorsByName(
      this.projectId, filters).subscribe((summaries: NOBORHSummary[]) => {
        this.noborhSummaries = summaries;
        this.noborhSummaryOptions = summaries.map(
          (summary: NOBORHSummary) => new AutocompleteOption({
            id: summary.investorId,
            name: summary.investorName,
            subText: summary.addresses.filter((addr: string) => !!addr).join(', '),
            disabled: this.dataSource.data.some((datum: any) => datum.investorId === summary.investorId)
          })
        );
    });
  }

  public onOptionSelect(option: AutocompleteOption): void {
    this.selectedNoborhSummary = option === null ? null : this.noborhSummaries
      .find((summary: NOBORHSummary) => summary.investorId === option.id);
  }

  public onAddInvestorToList(): void {
    if (this.selectedNoborhSummary) {
      this.getInvestorByControlNumberTrigger$.next(+this.selectedNoborhSummary.controlNumber);
      this.clearAutocomplete();
    }
  }

  private clearAutocomplete(): void {
    this.selectedNoborhSummary = null;
    this.noborhSummaryOptions = [];
    this.clearAutocompleteSubject.next();
  }

  checkIfInvestorExist(value) {
    const notExist = !this.addedControlNumbersSet.has(value);
    if (!notExist) {
      this.alertService.alert({ message: 'This vote already exists' });
    }
    return notExist;
  }

  addInvestorForCheck(value) {
    this.addedControlNumbersSet.add(value);
    this.spinnerService.show('voting-patterns-scanned-investor');
  }

  searchForInvestor(value): Observable<IVotingPatternsScannedInvestorInterface | null> {
    return this.dashboardNoborhService
      .getInvestorByControlNumber(value, this.projectId)
      .pipe(catchError((error) => {
        this.addedControlNumbersSet.delete(value);
        this.alertService.alert(error);
        return of(null);
      }));
  }

  setData() {
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;

    const sortState: Sort = {active: 'position', direction: 'desc'};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  addInvestor(investor: IVotingPatternsScannedInvestorInterface = null) {
    this.spinnerService.hide('voting-patterns-scanned-investor');
    if (!investor) {
      return;
    }
    this.controlNumberForm.resetForm();
    this.dataSource.data.push(new VotingPatternsScannedInvestorDto({...investor, position: this.dataSource.data.length + 1}));
    this.dataSource.data = this.dataSource.data.slice();
  }

  onAddControlNumber(form: NgForm) {
    const { controlNumber } = form.value;

    if ( isNaN(+controlNumber)) {
      this.alertService.alert({message: 'Wrong value!'});
      return;
    }

    this.getInvestorByControlNumberTrigger$.next(+controlNumber);
  }

  get investors() {
    return this.dataSource.data.map(row => row.investorId);
  }

  get isValid() {
    return this.investors.length > 0;
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDelete(element) {
    const message = `${element.investorName}, ${element.controlNumber}`;
    let gridData = this.dataSource.data;
    this.dialogsService.confirm(
      null,
      null,
      { template: DeleteProjectTemplate({ title: 'Investor Voting Entry', message: message} ) }
    );
      this.dialogsService.close$.subscribe(res => {
        if (res) {
          let index = gridData.findIndex(x => x.controlNumber == element.controlNumber);
          gridData.splice(index,1);
          this.addedControlNumbersSet.delete(+element.controlNumber);
          this.dataSource.data = this.reorderArray(gridData);
      }
    });
  }
  
  reorderArray(gridData:VotingPatternsScannedInvestorDto []):VotingPatternsScannedInvestorDto []  {
   return gridData.map((item, index) => {
    item.position = index +1;
    return item;
   });
  }
}
