import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of, EMPTY} from 'rxjs';
import {PpBreadcrumbsResolver, Breadcrumb} from 'pp-breadcrumbs';
import {catchError, mergeMap} from 'rxjs/operators';
import {ClassificationsService} from '@app/services/classifications.service';

@Injectable()
export class ResolutionClassificationResolver extends PpBreadcrumbsResolver {
  constructor(private classificationsService: ClassificationsService) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Promise<Breadcrumb[]> | Breadcrumb[] {
    const id = route.params['id'];
    if (id == 0) {
      return of([{
        text: 'Add New Resolution Classification',
        path: this.getFullPath(route)
      }]);
    } else {
      return this.classificationsService.getClassificationRecord(id, true).pipe(catchError(error => {
          return EMPTY;
        }), mergeMap(data => {
          if (data) {
            return of([{
              text: `(${data.code}) ${data.name}`,
              path: this.getFullPath(route)
            }]);
          } else {
            return EMPTY;
          }
        })
      );
    }
  }
}
