export class ProjectMailingDocumentOrderReceiveDTO {
    mailingDocumentId: number;    
    projectId: number;
    documentName: string;    
    copies: number;    
    comment: string;
    insertedByUser: string;
    date: Date;
    actionType: string;
    loggedUser: string;
}