import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProjectInvestorDataAddDto } from '@app/dto/ProjectInvestorDataAddDto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InvestorDataLookupDto } from '@app/dto/InvestorDataLookupDto';
import { ProjectInvestorDataUpdateDto } from '@app/dto/ProjectInvestorDataUpdateDto';
import { ProjectUnmatchedInvestorDto } from '@app/dto/ProjectUnmatchedInvestorDto';
import { ProjectInvestorUploadDto } from '@app/project/projectdetail/prjinvdata/project-investor-upload-dialog/project-investor-upload-dto';
import { BeneficiariesList } from '@app/project/projectdetail/prjinvdata/project-investor-upload-dialog/BeneficiariesListDto';

@Injectable({
  providedIn: 'root'
})
export class ProjectInvestorDataService {

  constructor(private httpClient: HttpClient) { }

  public addProjectInvestorData(dto: ProjectInvestorDataAddDto): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.serverUrl}projectinvestors`, dto);
  }

  public updateProjectInvestorData(dto: ProjectInvestorDataUpdateDto): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.serverUrl}projectinvestors`, dto);
  }

  public getInvestorData(projectId: number, term: string): Observable<InvestorDataLookupDto[]> {
    const apiURL: string = `${environment.serverUrl}investors/investor-data-lookup/${projectId}/${term}`;
    return this.httpClient.get<InvestorDataLookupDto[]>(apiURL);
  }

  public getBeneficiariesData(projectId: number, term: string): Observable<BeneficiariesList[]> {
    const apiURL: string = `${environment.serverUrl}investors/beneficiaries-data-lookup/${projectId}/${term}`;
    return this.httpClient.get<BeneficiariesList[]>(apiURL);
  }

  public getUnmatchedInvestor(projId: number, projInvId: number): Observable<ProjectUnmatchedInvestorDto> {
    const apiURL = `${environment.serverUrl}projects/project-investor-au-unmatched-record/${projId}/${projInvId}`;
    return this.httpClient.get<ProjectUnmatchedInvestorDto>(apiURL);
  }

  public matchInvestor(dto: ProjectUnmatchedInvestorDto): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.serverUrl}projects/match-investor`, dto);
  }

  public uploadProjectInvestorData(dto: ProjectInvestorUploadDto): Observable<any> {
    const url = `${environment.serverUrl}projectinvestors/upload` + (dto.excelTemplate === 'EU' ? '-eu' : '-au');
    return this.httpClient.post(url, dto);
  }
}
