import { Injectable } from '@angular/core';
import { Roles } from '@app/enums/RolesEnum';
import { RoleGuardService } from './auth/role-guard.service';

@Injectable({
  providedIn: 'root'
})
export class AppConstants {
  public static ENVIRONMENT_STAGING_NAME = 'staging';
  public static ENVIRONMENT_PRODUCTION_NAME = 'production';
  public static DASHBOARD_ROUTE_PATH = 'dashboard';
  public static DECIMALS_FIX = 2;
  public static CUSIP_LENGTH = 9;

  public static continentLabelMapping = {
    AF: 'Africa',
    AN: 'Antarctica',
    EU: 'Europe',
    NA: 'North America',
    AS: 'Asia',
    OC: 'Oceania',
    SA: 'South America'
  };

  public static INVESTOR_INTEL_TYPES = {
    ENGAGEMENT_EXAMPLE: 'ENGAGEMENT_EXAMPLE',
    EXCLUSION_RULE: 'EXCLUSION_RULE',
    ARTICLES_OPINIONS: 'ARTICLES_OPINIONS'
  };

  public static INVESTOR_REPORTS_TYPES = {
    INVESTOR_ESG_QUARTERLY_REPORTS: 'QUARTERLY_REPORTS',
    INVESTOR_ESG_ANNUALLY_REPORTS: 'ANNUALLY_REPORTS',
    INVESTOR_ESG_PRI_ESG_INCORPORATION_LEI_1_REPORTS: 'INVESTOR_ESG_PRI_ESG_INCORPORATION_LEI_1_REPORTS',
    INVESTOR_ESG_PRI_ESG_INCORPORATION_LEI_12_13_REPORTS: 'PRI_ESG_INCORPORATION_LEI_12_13_REPORTS',
    INVESTOR_ESG_PRI_ENGAGEMENT_REPORTS: 'PRI_ENGAGEMENT_OUTPUTS_AND_OUTCOMES_REPORTS',
    INVESTOR_ESG_SECTOR_FOCUS_DISCLOSURE: 'SECTOR_FOCUS_DISCLOSURE',
    INVESTOR_ESG_THEMATIC_FOCUS_DISCLOSURE: 'THEMATIC_FOCUS_DISCLOSURE'
  };

  public static PROJECT_ACTIVITY_STATUS = {
    activityStatusInProgress: 'In Progress',
    activityStatusCompleted: 'Completed'
  };

  public static CHILD_RECOMMENDATION_CLASS = 'child-res';
  public static PAD_TEXT_RIGHT_CLASS = 'text-right';

  public static PROJECT_TYPES = {
    SEARCHDELIVERY: 'Search & Delivery'
  };

  public ROLES = [
    {
      section: 'ADMIN',
      subsections: [
        {
          action: 'EDIT',
          roles: [
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.SUPER_PM_US,
            Roles.US_PSC
          ]
        },
        {
          action: 'CONTACTS',
          roles: [
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.SUPER_PM_US,
            Roles.US_PSC
          ]
        },
        {
          action: 'DOCUMENTS',
          roles: [
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU
          ]
        }
      ],
      roles: [
        Roles.SUPER_PM_EU,
        Roles.SUPER_PM_AU,
        Roles.SYS_ADMIN,
        Roles.EU_PM,
        Roles.AU_PM,
        Roles.USER_EU,
        Roles.SUPER_PM_US,
        Roles.US_USER
      ]
    },
    {
      section: 'INVESTORS',
      subsections: [
        {
          action: 'CREATE',
          roles: [
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.SUPER_PM_US
          ]
        },
        {
          action: 'BULK_REASSIGN',
          roles: [
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.SUPER_PM_US
          ]
        }
      ],
      roles: [
        Roles.AU_PM,
        Roles.EU_PM,
        Roles.SYS_ADMIN,
        Roles.USER_EU,
        Roles.SUPER_PM_EU,
        Roles.SUPER_PM_AU,
        Roles.SUPER_PM_US,
        Roles.US_USER
      ]
    },
    {
      section: 'CLIENTS',
      subsections: [
        {
          action: 'EDIT',
          roles: [
            Roles.AU_PM,
            Roles.EU_PM,
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.SUPER_PM_US,
            Roles.US_USER,
            Roles.US_PSC
          ]
        },
        {
          action: 'CREATE',
          roles: [
            Roles.AU_PM,
            Roles.EU_PM,
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.SUPER_PM_US,
            Roles.US_USER,
            Roles.US_PSC
          ]
        },
      ],
      roles: [
        Roles.AU_PM,
        Roles.EU_PM,
        Roles.SYS_ADMIN,
        Roles.SUPER_PM_EU,
        Roles.SUPER_PM_AU,
        Roles.USER_EU,
        Roles.SUPER_PM_US,
        Roles.US_USER,
        Roles.US_PSC
      ]
    },
    {
      section: 'DASHBOARD',
      subsections: [
        {
          action: 'PROJECT_OVERVIEW',
          roles: [
            Roles.AU_PM,
            Roles.EU_PM,
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.CLIENT,
            Roles.USER_EU,
            Roles.SUPER_PM_US,
            Roles.US_USER,
            Roles.US_PSC
          ]
        },
        {
          action: 'TABS',
          roles: [
            Roles.AU_PM,
            Roles.EU_PM,
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.CLIENT,
            Roles.USER_EU,
            Roles.SUPER_PM_US,
            Roles.US_USER
          ]
        },
        {
          action: 'TABS_NOBO_MAILING',
          roles: [
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.SUPER_PM_US,
            Roles.US_USER,
            Roles.US_PSC
          ]
        },
        {
          action: 'EDIT',
          roles: [
            Roles.AU_PM,
            Roles.EU_PM,
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.USER_EU,
            Roles.SUPER_PM_US
          ]
        },
      ],
      roles: [
        Roles.AU_PM,
        Roles.EU_PM,
        Roles.SYS_ADMIN,
        Roles.SUPER_PM_EU,
        Roles.SUPER_PM_AU,
        Roles.CLIENT,
        Roles.USER_EU
      ]
    },
    {
      section: 'PROJECTS',
      subsections: [
        {
          action: 'CREATE',
          roles: [
            Roles.AU_PM,
            Roles.EU_PM,
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.SUPER_PM_US,
            Roles.US_USER,
            Roles.US_PSC
          ]
        },
        {
          action: 'GENERAL',
          roles: [
            Roles.AU_PM,
            Roles.EU_PM,
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.USER_EU,
            Roles.SUPER_PM_US,
            Roles.US_USER,
            Roles.US_PSC
          ]
        },
        {
          action: 'EDIT',
          roles: [
            Roles.AU_PM,
            Roles.EU_PM,
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.SUPER_PM_US,
            Roles.US_USER,
            Roles.US_PSC
          ]
        },
      ],
      roles: [
        Roles.AU_PM,
        Roles.EU_PM,
        Roles.SYS_ADMIN,
        Roles.SUPER_PM_EU,
        Roles.SUPER_PM_AU,
        Roles.USER_EU,
        Roles.SUPER_PM_US,
        Roles.US_USER,
        Roles.US_PSC
      ]
    },
    {
      section: 'CONTACTS',
      subsections: [
        {
          action: 'CREATE',
          roles: [
            Roles.AU_PM,
            Roles.EU_PM,
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.USER_EU,
            Roles.SUPER_PM_US,
            Roles.US_USER,
            Roles.US_PSC
          ]
        },
        {
          action: 'GENERAL',
          roles: [
            Roles.AU_PM,
            Roles.EU_PM,
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.USER_EU
          ]
        },
        {
          action: 'CONTACT_LIST',
          roles: [
            Roles.AU_PM,
            Roles.EU_PM,
            Roles.SYS_ADMIN,
            Roles.SUPER_PM_EU,
            Roles.SUPER_PM_AU,
            Roles.USER_EU
          ]
        }
      ],
      roles: [
        Roles.AU_PM,
        Roles.EU_PM,
        Roles.SYS_ADMIN,
        Roles.SUPER_PM_EU,
        Roles.SUPER_PM_AU,
        Roles.USER_EU,
        Roles.SUPER_PM_US,
        Roles.US_USER,
        Roles.US_PSC
      ]
    },
    {
      section: 'REPORTS',
      subsections: [],
      roles: [
        Roles.AU_PM,
        Roles.EU_PM,
        Roles.SYS_ADMIN,
        Roles.SUPER_PM_EU,
        Roles.SUPER_PM_AU,
        Roles.CLIENT,
        Roles.USER_EU,
        Roles.SUPER_PM_US,
        Roles.US_USER,
        Roles.US_PSC
      ]
    },
  ];

  public getRolesPerSection = (section, subsection = null) => {
    if (!section) {
      return [];
    }
    const $section = this.ROLES.find(role => role.section === section.toUpperCase());

    // If we did not defined that section then allow access to it
    if (!$section) {
      return [];
    }
    if (!subsection) {
      return $section.roles;
    }

    const $subsection = $section.subsections.find(_subsection => _subsection.action === subsection.toUpperCase());
    if (!$subsection) {
      return [];
    }

    return $subsection.roles;
  }
}
