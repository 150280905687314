import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { AlertService } from '@app/services/alert.service';
import { Subscription } from 'rxjs';
import { DashboardAddMailingDocumentComponent } from './dashboard-mailing-dialog-add-doc/dashboard-mailing-add-doc-component';
import { ProjectMailingDocumentDTO } from '@app/dto/ProjectMailingDocumentDTO';
import { DashboardOrderReceiveDocumentComponent } from './dashboard-mailing-dialog-order-receive/dashboard-mailing-dialog-order-receive.component';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-mailing',
  templateUrl: './dashboard-mailing-component.html',
  styleUrls: ['./dashboard-mailing-component.scss']
})
export class DashboardMailingComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['documentName', 'description', 'copiesOrdered', 'copiesReceived', 'lastUpdate', 'actions'];
  @Input() projectId: number;
  showGrid = true;
  dataSource = new MatTableDataSource();
  canEdit = true;
  subscription: Subscription;
  noItemsText = 'Mailing Documents';

  constructor(private dashboardService: DashboardService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private dialogsService: DialogsService,
    private spinnerService: NgxSpinnerService) {
  }

  ngOnInit() {
    this.getMailingDocuments(this.projectId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getMailingDocuments(projecId: number) {
    this.spinnerService.show('mailing');
    this.subscription = this.dashboardService.getMailingDocumentsSet(projecId).subscribe(
      data => {
        this.dataSource = new MatTableDataSource(data);
        this.showGrid = data.length > 0;
        this.spinnerService.hide('mailing');
      }, e => {
        this.spinnerService.hide('mailing');
      });
  }

  onAddBtnClick() {
    this.openAddEditMilingDocumentDialog({ projectId: this.projectId });
  }

  orderReceive(element: ProjectMailingDocumentDTO, type: string) {
    const dialogRef = this.dialog.open(DashboardOrderReceiveDocumentComponent, {
      data: { document: element, actionType: type },
      width: '550px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(x => {
      this.getMailingDocuments(this.projectId);
    });
  }

  onEdit(element: ProjectMailingDocumentDTO) {
    this.openAddEditMilingDocumentDialog({ ...element });
  }

  onDelete(element: ProjectMailingDocumentDTO) {
    this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: 'Document', message: `Document: ${element.documentName}` }) });
    this.dialogsService.close$.subscribe(res => {
        if (res) {
            this.dashboardService.deleteMailingDocuments(element).subscribe(
                data => {
                  this.getMailingDocuments(this.projectId);
                  this.alertService.sendSuccess('Document has been deleted!');
                }, e => this.alertService.alert(e)
                );
        }
    });
  }

  openAddEditMilingDocumentDialog(data) {
    const dialogRef = this.dialog.open(DashboardAddMailingDocumentComponent, {
      data,
      minWidth: '550px',
      maxWidth: '1100px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(x => {
      this.getMailingDocuments(this.projectId);
    });
  }
}
