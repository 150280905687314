import { MatDialog } from '@angular/material';
import { ContactGridDTO } from './../../../dto/ContactGridDTO';
import { ContactRelatedEntityType } from '@app/enums/ContactRelatedEntityTypeEnum';
import { ContactType } from '@app/enums/ContactTypeEnum';
import { Component, OnInit } from '@angular/core';
import { ClientExternalAdvisorContactCreateDTO } from './ClientExternalAdvisorContactsDTOs';
import { AlertService } from '@app/services/alert.service';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { Utils, LookupFilter } from '@app/helper/utils';
import { Lookup } from '@app/dto/Lookup';
import { ClientExternalAdvisorContactsService } from './client-external-advisor-contacts.service';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { RelatedEntityData } from '@app/contact/contactdetail/contact-general/relatedEntityData';
import { ContactDlgComponent } from '@app/contact/contactdetail/contact-dlg.component';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import { DialogsService } from '@app/services/dialogs/dialogs.service';

@Component({
  selector: 'app-client-external-advisor-contacts',
  templateUrl: './client-external-advisor-contacts.component.html',
  styleUrls: ['./client-external-advisor-contacts.component.scss']
})
export class ClientExternalAdvisorContactsComponent implements OnInit {
  displayedColumns = ['advisorType', 'advisorName', 'contactName', 'location','jobTitle','phone','email','actions'];
  dataSource;

  advisorType?: number;
  model: ClientExternalAdvisorContactCreateDTO = new ClientExternalAdvisorContactCreateDTO();

  util: Utils;
  typeOfAdvisors: Lookup[] = []
  advisorsByType: any[] = [];
  advisorContacts: any[] = [];

  routeSub: any;

  canEdit: boolean = true;
  showGrid: boolean = false;

  noItemsText = 'External Advisor Contacts';

  constructor(
    private alertService: AlertService,
    private dialogsService: DialogsService,
    private startupdataproviderService: StartupdataproviderService,
    private service: ClientExternalAdvisorContactsService,
    private roleGuardService: RoleGuardService,
    private route: ActivatedRoute,
    private dialog : MatDialog) {
    this.util = new Utils(this.startupdataproviderService);
  }

  ngOnInit() {
    this.routeSub = this.route.parent.params.subscribe((params) => {
      this.model.clientId = +params['id'];
      this.getContacts();
    });

    this.canEdit = this.roleGuardService.hasAccess('clients', 'EDIT');
    this.getUtilsData();
  }

  onAdvisorTypeChange(id: number) {
    this.model.contactIds = [];
    this.model.advisorId = null;
    this.service.getExternalAdvisorsByType(id)
      .subscribe(data => {
        this.advisorsByType = data;
      });
  }

  onAdvisorChange(id: number) {
    this.model.contactIds = [];
    this.service.getExternalAdvisorContacts(id)
      .subscribe(data => {
        this.advisorContacts = data;
      });
  }

  addContact(form: NgForm) {
    this.service.createExternalAdvisorContact(this.model)
      .subscribe(data => {
        this.getContacts();
        this.alertService.sendSuccess('External Advisor contact added!');
        this.resetForm(form);
      },
        err => {
          const errorMessage = this.alertService.transformError(err.error);
          this.alertService.sendError(errorMessage);
        });
  }

  resetForm(form: NgForm) {
    form.resetForm();
    this.model.advisorId = null;
    this.model.contactIds = [];
  }

  getContact(data: ContactGridDTO){
    const dialogData = new RelatedEntityData();
    dialogData.contactId = data.contactId;
    dialogData.mappingId = this.model.advisorId;
    dialogData.relatedEntityTypeId = ContactRelatedEntityType.ExternalAdvisor;
    dialogData.contactTypeId = ContactType.ExternalAdvisor;
    dialogData.isAdd = false;
    dialogData.isReadOnlyMode=true;

    const dialogRef = this.dialog.open(ContactDlgComponent
      , {
        data: dialogData,
        width: '1300px',
        maxHeight: 'fit-content'
      });
    dialogRef.afterClosed().subscribe(result => this.getContacts());
  }
  private getUtilsData() {
    this.util.getLookupByType(LookupFilter.EXTADV)
      .subscribe(data => {
        this.typeOfAdvisors = data;
      });
  }

  private getContacts() {
    this.service.getClientExternalAdvisorContacts(this.model.clientId)
      .subscribe(data => {
        this.dataSource = data;
        this.showGrid = data.length > 0;
      },
        err => {
          const errorMessage = this.alertService.transformError(err.error);
          this.alertService.sendError(errorMessage);
        });
  }

  onDelete(element) {
    const contact = element;
    const message = `Link to Contact ${contact.contactName}`;
    this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: message, message: contact.contactName }) });
    this.dialogsService.close$.subscribe(res => {
      if (res) {
        this.service.deleteExternalAdvisorContact(contact.id)
          .subscribe(data => {
            this.alertService.sendSuccess(`${message} Deleted!`);
            this.getContacts();
         });
      }
    });
  }
}
