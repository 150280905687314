import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { AlertService } from '@app/services/alert.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { EventHistoryEditDialogComponent } from './event-history-edit-dialog/event-history-edit-dialog.component';
import {DeleteProjectTemplate} from '@app/services/dialogs/dialog-delete-template.config';

@Component({
  selector: 'app-dashboard-mailing-event-history',
  templateUrl: './dashboard-mailing-event-history.component.html',
  styleUrls: ['./dashboard-mailing-event-history.component.css']
})
export class DashboardMailingEventHistoryComponent implements OnInit {
  displayedColumns: string[] = ['dateTime', 'event', 'copies', 'member', 'comment', 'actions'];
  @Input() documentId: number;
  @ViewChild(MatSort) sort: MatSort;
  showGrid = false;
  dataSource = new MatTableDataSource();

  constructor(
    private dashboardService: DashboardService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private dialogsService: DialogsService
  ) { }

  ngOnInit() {
    this.getEvents();
  }

  getEvents() {
    this.dashboardService.getMailingEventsHistory(this.documentId).subscribe(data => {
      this.dataSource.sort = this.sort;
      this.dataSource.data = data;
      this.showGrid = data.length > 0;
    }, e => this.alertService.alert(e));
  }

  onEdit(element) {
    const dialogRef = this.dialog.open(EventHistoryEditDialogComponent, {
      data: { mailingDocumentCopyId: element.mailingDocumentCopyId },
      minWidth: '550px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getEvents();
      }
    });
  }

  onDelete(element) {
    this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: 'Event History Item', message: 'Event History Item' }) });
    this.dialogsService.close$.subscribe(res => {
        if (res) {
          this.dashboardService.deleteMailingEvent(element.mailingDocumentCopyId).subscribe(() => {
            this.getEvents();
            this.alertService.sendSuccess(`Event history item was successfully deleted.`);
          });
        }
    });
  }
}
