import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntelligenceProxyDataDto } from '@app/dto/IntelligenceProxyDataDto';
import { EsgSurvey } from '@app/investor/investordetail/investor-intel-esg/investor-intel-esg-survey/EsgSurvey';
import {
  InvestorIntelEngagementDto,
  InvestorIntelInvestmentFunds,
  InvestorIntelLogisticalIntelligenceDto,
  InvestorReportsExistence
} from '@app/dto/InvestorIntelDto';
import {
  InvestorValueCountryGridSourceDto
} from '@app/investor/investordetail/investor-value-country-grid/investor-value-country-grid-source-dto';
import { EsgSustainableInvestorResourcesField } from '@app/dto/EsgSustainableInvestorResourcesFields';
import { Custodian } from '@app/investor/investordetail/investor-voting-process/custodian-table/custodian-table.component';

@Injectable({
  providedIn: 'root'
})

export class InvestorsIntelligenceService {
  constructor(private http: HttpClient) {
  }

  public getProxy(investorId: number): Observable<IntelligenceProxyDataDto> {
    return this.http.get<IntelligenceProxyDataDto>(`${environment.serverUrl}investorintelligence/proxy/` + investorId).pipe(
      map(data => data));
  }

  public updateProxy(proxy: IntelligenceProxyDataDto) {
    return this.http.put<IntelligenceProxyDataDto>(`${environment.serverUrl}investorintelligence/proxy`, proxy);
  }

  public getEngagement(investorId: number): Observable<InvestorIntelEngagementDto> {
    return this.http.get<InvestorIntelEngagementDto>(`${environment.serverUrl}investorintelligence/engagement/` + investorId);
  }

  public updateEngagement(data: InvestorIntelEngagementDto) {
    return this.http.put<InvestorIntelEngagementDto>(`${environment.serverUrl}investorintelligence/engagement`, data);
  }

  public getLogisticalIntelligence(investorId: number): Observable<InvestorIntelLogisticalIntelligenceDto> {
    return this.http.get<InvestorIntelLogisticalIntelligenceDto>(`${environment.serverUrl}investorintelligence/logistical-intel/` + investorId);
  }

  public updateLogisticalIntelligence(data: InvestorIntelLogisticalIntelligenceDto) {
    return this.http.put<InvestorIntelLogisticalIntelligenceDto>(`${environment.serverUrl}investorintelligence/logistical-intel`, data);
  }

  public getInvestmentFunds(investorId: number): Observable<InvestorIntelInvestmentFunds> {
    return this.http.get<InvestorIntelInvestmentFunds>(`${environment.serverUrl}investorintelligence/esg-investment-funds/` + investorId);
  }

  public setInvestmentFunds(dto: InvestorIntelInvestmentFunds): Observable<InvestorIntelInvestmentFunds> {
    return this.http.post<InvestorIntelInvestmentFunds>(`${environment.serverUrl}investorintelligence/esg-investment-funds/`, dto);
  }

  public getEsgSurvey(investorId: number): Observable<EsgSurvey> {
    return this.http.get<EsgSurvey>(`${environment.serverUrl}investorintelligence/esg-survey/` + investorId);
  }

  public upsertEsgSurvey(dto: EsgSurvey): Observable<any> {
    return this.http.post<EsgSurvey>(`${environment.serverUrl}investorintelligence/esg-survey/`, dto);
  }

  public getEsgSustainableFields(investorId: number): Observable<EsgSustainableInvestorResourcesField> {
    return this.http.get<EsgSustainableInvestorResourcesField>(`${environment.serverUrl}investorintelligence/esg-sustainable-resources/` + investorId);
  }

  public upsertEsgSustainableFields(dto: EsgSustainableInvestorResourcesField): Observable<any> {
    return this.http.post<EsgSustainableInvestorResourcesField>(`${environment.serverUrl}investorintelligence/esg-sustainable-resources/`, dto);
  }

  public getInvestorReportExistence(investorId: number, reportType: string): Observable<InvestorReportsExistence> {
    return this.http.get<InvestorReportsExistence>(`${environment.serverUrl}investorintelligence/investor-report-exist/${investorId}/${reportType}`);
  }

  public createRelatedReportEntity(investorId: number, reportType: string): Observable<number> {
    return this.http.post<number>(`${environment.serverUrl}investorintelligence/investor-report`, { investorId, reportType });
  }

  public deleteInvestorReports(investorId: number, reportType: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.serverUrl}investorintelligence/investor-report/${investorId}/${reportType}`);
  }

  public getInvestorLocalCustodians(investorId: number): Observable<InvestorValueCountryGridSourceDto> {
    const url = `${environment.serverUrl}investorintelligence/local-custodians/${investorId}`;

    return this.http.get<InvestorValueCountryGridSourceDto>(url);
  }

  public getInvestorGlobalCustodians(investorId: number): Observable<InvestorValueCountryGridSourceDto> {
    const url = `${environment.serverUrl}investorintelligence/global-custodians/${investorId}`;

    return this.http.get<InvestorValueCountryGridSourceDto>(url);
  }

  public deleteInvestorGlobalCustodian(investorId: number): Observable<void> {
    const url = `${environment.serverUrl}investorintelligence/global-custodians/${investorId}`;

    return this.http.delete<void>(url);
  }

  public deleteInvestorLocalCustodian(investorId: number): Observable<void> {
    const url = `${environment.serverUrl}investorintelligence/local-custodians/${investorId}`;

    return this.http.delete<void>(url);
  }

  public upsertGlobalCustodian(model: any): Observable<any> {
    return this.http.post(`${environment.serverUrl}InvestorIntelligence/global-custodians`, model);
  }

  public upsertLocalCustodian(model: any): Observable<any> {
    return this.http.post(`${environment.serverUrl}InvestorIntelligence/local-custodians`, model);
  }
}
