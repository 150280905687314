import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AddInvestorCustodianDlgComponent } from './add-investor-custodian-dlg/add-investor-custodian-dlg.component';
import {
  InvestorIntelGridAddDto
} from '@app/investor/investordetail/investor-value-country-grid/investor-value-country-grid-source-dto';

@Component({
  selector: 'app-add-investor-custodian',
  templateUrl: './add-investor-custodian.component.html',
  styleUrls: ['./add-investor-custodian.component.scss']
})
export class AddInvestorCustodianComponent implements OnInit {
  @Input() investorId: number;
  @Input() disabled: boolean;
  @Input() custodianType: 'local' | 'global';

  @Output() onComplete: EventEmitter<any> = new EventEmitter<any>();

  $addCustodianDialog: MatDialogRef<any>;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  onAddCustodianClick(): void {
   this.$addCustodianDialog = this.dialog.open(AddInvestorCustodianDlgComponent,
      {
        width: '600px',
        data: {
          investorId: this.investorId,
          isGlobalCustodian: this.custodianType === 'global',
          header: this.custodianType === 'global' ? 'Add Global Custodian' : 'Add Local Custodian',
          onComplete: () => {
            this.onComplete.emit(null);
            this.$addCustodianDialog.close();
          },
          investorCustodian: new InvestorIntelGridAddDto()
        }
      });
  }
}
