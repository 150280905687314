export class ClientReportDto {
    projectId: number;
    recipients: any[];
    setOfMaterialIds: number[];
    cusips: string[];
    numberOfSets: number;
    jobDescription: string;
    recordDate: Date;
    meetingDate: Date;
    specialInstructions: string;
}