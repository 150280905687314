import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { SearchGridService } from '@app/service/search-grid/search-grid.service';
import { ProjectsService } from '@app/services/projects.service';
import { AGAINST, FOR, ONE_YEAR, ProjectTemplateService, THREE_YEARS, TWO_YEARS } from '@app/service/projectTemplateService';
import { Lookup } from '@app/dto/Lookup';
import { AlertService } from '@app/services/alert.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { AddVotingReconciliationDialogComponent } from './add-voting-reconciliation-dialog/add-voting-reconciliation-dialog.component';
import { CommonUtilityService } from '@app/utility/common-utility.service';
import {
  DynamicColumnDefinition
} from '@app/dashboard/dashboardau/dashboard-custodian-table/dashboard-custodian-table.component';
import { ShareTypesAdapter } from '@app/dashboard/dashboardau/dashboard-custodian-table/custodian-details/interfaces/share-types.interface';

@Component({
  selector: 'app-voting-reconciliation',
  templateUrl: './voting-reconciliation.component.html',
  styleUrls: ['./voting-reconciliation.component.scss']
})
export class VotingReconciliationComponent implements OnInit {

  orgDisplayedColumns: string[] = [
    'name',
    'datetime'
  ];
  displayedColumns: string[] = [];
  displayDynamicColumns: DynamicColumnDefinition[] = [];
  shareTypes: ShareTypesAdapter[] = [];
  dataSource;
  @ViewChild(MatSort) sort: MatSort;
  searchService: SearchGridService;
  additionalVotingTypes = [];

  filterParameters: FilterParameters = new FilterParameters();

  @Input() projectId;
  @Input() projectCustodianId;

  @Input() set shareTypeColumns(value) {
    this.shareTypes = Array.from(new Set([...value, new ShareTypesAdapter({ cusip: null, shareClassification: 'Total' })]));
  }

  custodianVotingTypes: Lookup[] = [];
  @Input() defaultShareType: ShareTypesAdapter = new ShareTypesAdapter();
  voteTitle: string;
  private project;
  showGrid = false;
  toggler = [];

  constructor(private projectsService: ProjectsService,
              private projectTemplateService: ProjectTemplateService,
              private dashboardService: DashboardService,
              private alertService: AlertService,
              public dialog: MatDialog,
              private commonUtility: CommonUtilityService) {
  }

  ngOnInit() {
    this.filterParameters = new FilterParameters({
      pageIndex: 0,
      pageSize: 0,
      searchTerm: null,
      filters: [
        {
          filterPorp: 'ProjectCustodianId',
          value: [this.projectCustodianId]
        },
        {
          filterPorp: 'ShareType',
          value: [this.defaultShareType.shareClassification]
        }
      ],
      sorts: []
    });

    this.projectsService.getProject(this.projectId, true).subscribe(
      data => {
        this.project = data;
        this.voteTitle = data.template === this.projectTemplateService.SHAREHOLDER_MEETING_TEMPLATE_NAME ? 'Resolutions' : 'Bid companies';
        this.shareTypes = [];
        this.filterParameters.filters[1] = new FilterProps({filterPorp: 'ShareType', value: [`${this.defaultShareType.shareClassification}`]});

        this.getData();
      }, error => {
        this.alertService.sendError(error.message);
      });
  }

  getData() {
    this.dashboardService.getProjectCustodiansVotes(this.filterParameters).subscribe(
      data => {
        const additional = this.projectTemplateService.hasFrequency(data) ?
          this.projectTemplateService.getFrequencyVoteTypes(
            false,
            'CUSTODIAL_RECONCILIATION',
            [],
            this.projectTemplateService.isFrequencyWithReverseOrder(data)
          ) : [];
        this.additionalVotingTypes = additional;
        this.custodianVotingTypes = this.projectTemplateService.getCustodialReconciliationVotedTypes(this.project.template, additional);

        if (data.length > 0) {
          this.showGrid = true;
          this.setColumns(data);
          this.setTableRows(data);
        } else {
          this.showGrid = false;
          this.dataSource = new MatTableDataSource();
          this.dataSource.sort = this.sort;
          this.dataSource.data = [];
        }
      }, error => {
        this.alertService.sendError(error.message);
      }
    );
  }

  setColumns(data) {
    this.displayedColumns = Object.assign([], this.orgDisplayedColumns);
    this.displayDynamicColumns = [];

    this.custodianVotingTypes.forEach(shareType => {
      const coldef = new DynamicColumnDefinition();
      coldef.colName = `${shareType.fieldValue}`;
      coldef.colHeaderName = `${shareType.fieldLabel}`;
      coldef.colHeaderColor = `${shareType.color}`;
      this.displayedColumns.push(`${shareType.fieldValue}`);
      this.displayDynamicColumns.push(coldef);
    });

    this.displayedColumns.push(`source`);
    this.displayedColumns.push(`type`);
  }

  setTableRows(data) {
    let isDark = false;

    const tableRows = data.sort((a, b) => a.templateProperty1.localeCompare(b.templateProperty1))
      .reduce((accumulator, item, currentIndex) => {

      if (item.votingItemChild.parentVotingItemId) {
        return accumulator;
      }

      const __items = this.findChildVotingItems(item.votingItemId, data);
      const haveChild = __items.length > 0;

      this.toggler[item.votingItemId] = true;
      const title = [];
      title.push(item.templateProperty1);
      title.push(item.templateProperty2);
      const name = title.join('. ');
      const record = {
        togglerKey: item.votingItemId,
        votingItemId: item.votingItemId,
        main: true,
        class: ``,
        isDark: isDark,
        name: name,
        datetime: '',
        recalc: false,
        haveChild,
        votingItemType: item.votingItemType
      };

      this.custodianVotingTypes.forEach((shareType) => {
        record[shareType.fieldValue] = {
          value: (item.votes.find(vote => vote.voteType === shareType.fieldValue) || {vote: 0}).vote,
          haveChild
        };
      });

      record['source'] = '';
      record['type'] = '';

      accumulator.push(record);


        const dates = this.getUniqueDatesPerShareType(item.votes);
        dates.forEach((_record, idx) => {
          const [date, source, type, entry, user] = _record.split('|');
          const row = {
            togglerKey: item.votingItemId,
            votingItemId: item.votingItemId,
            main: false,
            class: `item_${item.votingItemId}`,
            isDark: isDark,
            name: '',
            datetime: date,
            recalc: type === 'Recalc',
            votingItemType: item.votingItemType
          };

          this.custodianVotingTypes.forEach((shareType) => {
            const snapshots = (item.votes.find(vote => vote.voteType === shareType.fieldValue) || {snapshots: []}).snapshots;

            row[shareType.fieldValue] = {
              value: snapshots.filter(snapshot => snapshot.date === date).reduce((sum, res) => sum + res.voteChange, 0),
              recalcValue: snapshots.filter(snapshot => snapshot.date === date).reduce((sum, res) => sum + res.lastVoteChange, 0),
            };
          });

          row['source'] = source;
          row['type'] = entry + '-' + type + (this.parseJsonToString(user) ? ' (' + user + ')' : '');
          accumulator.push(row);

        });

        if (haveChild) {
          const result = this.setChildRows(item, __items, isDark, haveChild);
          accumulator.push(...result);
        }

        record['clickIt'] = !!dates.length;
        isDark = !isDark;
      return accumulator;
    }, []);

    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.dataSource.data = tableRows;
  }

  setChildRows = (item, children, isDark, haveChild) => {
    const rows = [];
    children.forEach((child, childIndex) => {
      const childName = `${this.indexProperties(child.votingItemChild.orderId)}. ${child.votingItemChild.name}`;
      this.toggler[childName] = true;

      // generate director top row
      const rowOne = {
        togglerKey: childName,
        votingItemId: `${item.votingItemId}_${child.votingItemChild.orderId}`,
        main: true,
        class: `item_${item.votingItemId}_${child.votingItemChild.orderId}`,
        isDark: isDark,
        name: childName,
        datetime: '',
        isChild: true,
        haveChild: false,
        votingItemType: item.votingItemType
      };

      this.custodianVotingTypes.forEach((shareType) => {

        rowOne[shareType.fieldValue] = {
          value: (child.votes.find(vote => vote.voteType === shareType.fieldValue) || {vote: 0}).vote,
        };
      });
      rowOne['clickIt'] = true;
      rowOne['source'] = '';
      rowOne['type'] = '';
      rows.push(rowOne);

      const dates = this.getUniqueDatesPerShareType(child.votes);
      dates.forEach((_record, idx) => {
        const [date, source, type, entry, user] = _record.split('|');
        const row = {
          togglerKey: childName,
          votingItemId: `${item.votingItemId}_${child.votingItemChild.orderId}`,
          main: false,
          class: `item_${item.votingItemId}_${child.votingItemChild.orderId}`,
          isDark: isDark,
          name: '',
          isChild: true,
          datetime: date,
          recalc: type === 'Recalc',
          votingItemType: item.votingItemType
        };

        this.custodianVotingTypes.forEach((shareType) => {
          const snapshots = (child.votes.find(vote => vote.voteType === shareType.fieldValue) || {snapshots: []}).snapshots;

          row[shareType.fieldValue] = {
            value: snapshots.filter(snapshot => snapshot.date === date).reduce((sum, res) => sum + res.voteChange, 0),
            recalcValue: snapshots.filter(snapshot => snapshot.date === date).reduce((sum, res) => sum + res.lastVoteChange, 0),
          };
        });
        row['clickIt'] = false;
        row['source'] = source;
        row['type'] = entry + '-' + type + (this.parseJsonToString(user) ? ' (' + user + ')' : '');
        rows.push(row);

      });
    });

    return rows;
  }


  indexProperties = (index) => this.commonUtility.indexToLetter(index, false);

  filterGrid(value, filterId) {
    if (filterId === 1) {
      this.filterParameters.filters[1] = new FilterProps({filterPorp: 'ShareType', value: [`${value.shareClassification}`]});
    }

    this.getData();
  }

  findChildVotingItems = (votingItemId, items) => {
    return items.filter(item => item.votingItemChild && (item.votingItemChild.parentVotingItemId === votingItemId));
  }

  getUniqueDatesPerShareType = (array) => array.reduce((h, obj) => [...h, ...obj.snapshots
    .map(o => `${o.date}|${o.source}|${o.type}|${o.entry}|${o.user}`)], [])
    .filter((value, index, self) => self.indexOf(value) === index).sort(function (a, b) {
      const [left, , ] = a.split('|');
      const [right, , ] = b.split('|');
      const dateA: any = new Date(left), dateB: any = new Date(right);
      return dateB - dateA;
    })

  toggleRows = (el) => {
    if (!el.clickIt) {
      return false;
    }
    this.toggler[el.togglerKey] = !this.toggler[el.togglerKey];
  }

  parseJsonToString(value: string): string | null {
    try {
      const parsedValue = JSON.parse(value);
      return parsedValue;
    } catch (error) {
      return value;
    }
  }

  onAdd() {
    const dialogRef = this.dialog.open(AddVotingReconciliationDialogComponent, {
      data: {
        projectCustodianId: this.projectCustodianId,
        projectId: this.projectId ,
        additionalVotingTypes: this.additionalVotingTypes
      },
      width: '1200px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(projectCustodianId => {
      if (projectCustodianId) {
        this.getData();
      }
    });
  }

  hideVote(element, dynCol: DynamicColumnDefinition) {
    const isFreq = this.isVotingItemTypeFrequency(element);
    const hideArr = isFreq ? [FOR, AGAINST] : [ONE_YEAR, TWO_YEARS, THREE_YEARS];

    return hideArr.indexOf(dynCol.colName) > -1;
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.shareClassification === o2.shareClassification;
  }

  isVotingItemTypeFrequency = (item) => !!item.votingItemType.match(/^frequency/gmi);

}

class FilterParameters {
  pageIndex: number;
  pageSize: number;
  searchTerm: string;
  filters: FilterProps[];
  sorts: string[];

  public constructor(init?: Partial<FilterParameters>) {
    Object.assign(this, init);
  }
}

class FilterProps {
  filterPorp: string;
  value: string[];

  public constructor(init?: Partial<FilterProps>) {
    Object.assign(this, init);
  }
}
