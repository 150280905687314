import { Lookup } from '@app/dto/Lookup';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { DirtyComponent } from '@app/common/dirty-component';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Client, ClientEngagementDetailsDTO } from '@app/dto/Client';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { CityGroup } from '@app/dto/CityGroup';
import { CountryGroup } from '@app/dto/CountryGroup';
import { FormControl } from '@angular/forms';
import { LookupFilter, Utils } from '@app/helper/utils';
import { UserService } from '@app/services/user.service';
import { AlertService } from '@app/services/alert.service';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import { NameValueModel } from '@app/models/basic-structures';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientsService } from '@app/services/clients.service';
import { IdName } from '@app/investor/investordetail/investor-intel-esg/investor-intel-esg-survey/IdName';
import { Roles } from '@app/enums/RolesEnum';

@Component({
  selector: 'app-clientdetail-general',
  templateUrl: './clientdetail-general.component.html',
  styleUrls: ['./clientdetail-general.component.scss']
})
export class ClientDetailGeneralComponent implements OnInit, DirtyComponent, AfterContentChecked {
  routeSub: any;
  model: Client;
  isEdit: boolean = false;
  clientid: number;
  disabled: boolean;
  showBillingAddressSection: boolean = false;
  requiredDiffBilling: boolean = false;

  lookuparr: Lookup[] = [];
  cityGroups: CityGroup[] = [];
  countryGroups: CountryGroup[] = [];
  sectors: Lookup[] = [];
  industries: Lookup[] = [];
  subIndustries: Lookup[] = [];
  clientTypes: IdName[] = [];
  cityGroupOptions: Observable<CityGroup[]>;
  cityControl = new FormControl();

  countryGroupOptions: Observable<CityGroup[]>;
  countryControl = new FormControl();

  billingCityGroupOptions: Observable<CityGroup[]>;
  billingCityControl = new FormControl();

  billingCountryGroupOptions: Observable<CityGroup[]>;
  billingCountryControl = new FormControl();

  util = new Utils(this.startupdataproviderService);

  userid: number;
  hasAccess: boolean = false;
  coverage: string;

  isImageUploaded = false;
  loading: boolean = true;
  websiteControl = new FormControl();
  public isFormValid = false;

  months: Lookup[] = [];
  days: number[] = [];
  jobTypes: Lookup[] = [];
  clientServices: Lookup[];
  clientTeamLookUps: Lookup[];
  clientRetainerLookUps: Lookup[];
  isPscUser: boolean;

  placeholder = 'State Of Incorporation';
  public requiredState = false;
  requiredBillingState: boolean = false;
  requiredGeneralState: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private startupdataproviderService: StartupdataproviderService,
    private clientsService: ClientsService,
    private userService: UserService,
    private alertService: AlertService,
    private roleGuardService: RoleGuardService,
    private dialogsService: DialogsService,
    private cdr: ChangeDetectorRef,
    private spinnerService: NgxSpinnerService,
  ) {
    this.showBillingAddressSection = false;
  }
  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();

  ngOnInit() {
    this.model = new Client();
    this.clientid = 0;
    this.userid = 0;
    this.coverage = this.userService.getUserCoverage();
    this.hasAccess = this.roleGuardService.hasAccess('clients', 'EDIT');
    this.isPscUser = this.userService.getUserRoleName() === Roles.US_PSC;
    this.getLookUps();

    combineLatest([
      this.clientsService.getClientTypes(),
      this.route.parent.params
    ])
      .pipe(map(result => ({ clientTypes: result[0], routeParams: result[1] })))
      .subscribe((result) => {
        this.clientTypes = result.clientTypes;
        const params = result.routeParams;

        this.clientid = +params['id'];
        if (this.clientid > 0) {
          this.getClient();
          this.countryControl.disable();
          this.cityControl.disable();
          this.billingCountryControl.disable();
          this.billingCityControl.disable();
        } else {
          this.model.coverage = this.coverage;
          this.model.hasDifferentBillingAddress = false;
          if (this.isPscUser) {
            this.model.clientTypeId = this.clientTypes.find(x => x.name === "PSC Client").id;
          }
          this.isEdit = true;
          this.loading = false;
          this.isDirty.next(true);
        }

        this.isDirty$.subscribe(data => {
          return of(data);
        });

        this.countryGroups = this.util.getCountryGroup();
        this.cityGroups = this.util.getCityGroup();
        this.util.getLookupByType(LookupFilter.GICSECTOR).subscribe(data => this.sectors = data);

        this.cityGroupOptions = this.cityControl!.valueChanges
          .pipe(
            debounceTime(1),
            distinctUntilChanged(),
            startWith(''),
            map(value => Utils.CityFilterGroup(value, this.cityGroups)
            )
          );

        this.countryGroupOptions = this.countryControl!.valueChanges
          .pipe(
            debounceTime(1),
            distinctUntilChanged(),
            startWith(''),
            map(value => Utils.CountryFilterGroup(value, this.countryGroups)
            )
          );

        this.billingCityGroupOptions = this.billingCityControl!.valueChanges
          .pipe(
            debounceTime(1),
            distinctUntilChanged(),
            startWith(''),
            map(value => Utils.CityFilterGroup(value, this.cityGroups)
            )
          );

        this.billingCountryGroupOptions = this.billingCountryControl!.valueChanges
          .pipe(
            debounceTime(1),
            distinctUntilChanged(),
            startWith(''),
            map(value => Utils.CountryFilterGroup(value, this.countryGroups)
            )
          );
      });
  }

  getClient() {
    let apiURL: string = environment.serverUrl + 'clients/' + this.clientid;
    this.httpClient.get<Client>(apiURL).subscribe(
      data => {
        this.model = data;

        if (this.model.sector) {
          this.sectorChanged(this.model.sector, 0);
          if (this.model.industry) {
            this.industryChanged(this.model.industry, 0);
          }
        }

        if (!this.model.clientEngagementDetails) {
          this.model.clientEngagementDetails = new ClientEngagementDetailsDTO();
        }

        this.loading = false;
        this.populateDays(this.model.fiscalYearEndMonth);
        if (this.model.content !== undefined) {
          this.loadImage(this.model.contentEncoded, this.model.fileName);
        }

        if (this.model.hasDifferentBillingAddress) {
          this.showBillingAddressSection = true;
          this.requiredDiffBilling = true;

          if (this.model.billingCountry == "United States" || this.model.billingCountry == "Australia") {
            this.requiredBillingState = true;
          }
        }
        else {
          this.showBillingAddressSection = false;
          this.requiredDiffBilling = false;
          this.requiredBillingState = false;
        }

        if (this.isPscUser && !(this.clientTypes.find(x => x.id == this.model.clientTypeId).name == "PSC Client")) {
          this.hasAccess = false;
        }
      },
      err => {
        this.loading = false;
        this.alertService.sendError(err.error.Message);
      }
    );

  }

  onEditBtnClick() {
    this.isEdit = true;
    this.cityControl.enable();
    this.countryControl.enable();
    this.billingCityControl.enable();
    this.billingCountryControl.enable();
    this.isDirty.next(true);
  }

  onSaveBtnClick() {
    this.spinnerService.show();
    if (this.clientid > 0) {
      this.updateClient(this.model);
    } else {
      this.model.clientId = this.clientid;
      this.addClient(this.model);
      this.isEdit = false;
    }
  }

  stateSelectionChanged(newState: NameValueModel) {
    this.model.stateName = newState.name;
    this.model.stateId = +newState.value;
  }
  /** POST: add a new client to the server */
  addClient(client: Client) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Access-Control-Allow-Origin': '*'
        })
    };

    let apiURL: string = environment.serverUrl + 'clients';

    this.httpClient.post<Client>(apiURL, client, httpOptions).subscribe(
      data => {
        this.alertService.sendSuccess('Client Created !');
        this.router.navigateByUrl(`/clients/${data.clientId}`, {});
        this.spinnerService.hide();
        this.isDirty.next(false);
      },
      err => {
        this.alertService.sendError(err.error.Message);
        this.onEditBtnClick();
        this.spinnerService.hide();
      })
  }

  /** PUT: update the hero on the server */
  updateClient(client: Client) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Access-Control-Allow-Origin': '*',
        }
      )
    };
    let apiURL: string = environment.serverUrl + 'clients';
    this.httpClient.put<Client>(apiURL, client, httpOptions).subscribe(
      data => {
        this.alertService.sendSuccess('Client Updated !');
        this.getClient();
        this.isEdit = false;
        this.isDirty.next(false);
        this.spinnerService.hide();
      },
      err => {
        this.alertService.sendError(err.error.Message);
        this.spinnerService.hide();
      }
    );

  }

  /** POST: add a new client to the server */
  deleteClient(client: Client) {
    this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: 'Client', message: client.company }) });
    this.dialogsService.close$.subscribe(res => {
      if (res) {
        const httpOptions = {
          headers: new HttpHeaders(
            {
              'Access-Control-Allow-Origin': '*'
            }
          )
        };
        let apiURL: string = environment.serverUrl + 'clients/Delete/' + client.clientId;
        this.httpClient.delete(apiURL, httpOptions).subscribe(
          data => {
            this.alertService.sendSuccess('Client Updated !');
            this.isDirty.next(false);
            this.router.navigate(['/clients']);
          },
          err => {
            this.alertService.sendError(err.error.Message);
          }
        );
      }
    });

  }

  onDeleteBtnClick() {
    if (this.clientid <= 0) {
      alert('Please create a client !');
    } else {
      this.deleteClient(this.model);
    }
  }

  onResetBtnClick() {
    this.cityControl.disable();
    this.countryControl.disable();
    this.billingCountryControl.disable();
    this.isEdit = false;
    if (this.model.clientId) this.getClient();
    this.isDirty.next(false);
  }

  onImageChangeFromFile(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0] as File;

      let preview = <HTMLImageElement>document.getElementById('imageId');

      const img = new Image();
      img.src = window.URL.createObjectURL(file);

      const imageReader = new FileReader();
      imageReader.readAsDataURL(file);
      imageReader.onload = () => {
        let imageError = <HTMLDivElement>document.getElementById('imageUploadErrorMessageId');
        const fileSize = file.size / (1024 * 1024);
        if (fileSize > 1) {
          imageError.innerText = 'Maximum logo size is 1mb!';

          preview.src = '#';

          this.model.contentType = '';
          this.model.fileName = '';
          this.model.content = [];

          return false;
        } else {
          imageError.innerText = '';

          preview.src = imageReader.result as string;

          this.model.contentType = file.type;
          this.model.fileName = file.name;

          const reader = new FileReader();
          reader.onload = () => {
            let fileAsBuffer = reader.result as ArrayBuffer;
            let fileAsBytes = new Uint8Array(fileAsBuffer);
            this.model.content = Array.from(fileAsBytes);
          };

          reader.readAsArrayBuffer(file);
        }
      };
    }
  }

  loadImage(contentEncoded: string, fileName: string) {
    let preview = <HTMLImageElement>document.getElementById('imageId');
    var binary_string = window.atob(contentEncoded);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    let blob = new Blob([bytes], { type: this.model.contentType });
    let image = new File([blob], fileName);

    const reader = new FileReader();
    reader.onload = () => {
      preview.src = reader.result as string;
    };

    reader.readAsDataURL(image);

  }

  setFormValidation(valid) {
    this.isFormValid = valid;
  }

  populateDays(month) {
    let day: number;
    if (month == "January" || month == "March" || month == "May" || month == "July" || month == "August" || month == "October" || month == "December") {
      day = 31;
    }
    else if (month == "February") {
      day = 29;
    } else {
      day = 30;
    }
    this.days = [];
    for (let i = 1; i <= day; i++) {
      this.days.push(i);
    }
  }

  sectorChanged(value, clear = 1) {
    if (clear) {
      this.model.industry = '';
      this.model.subindustry = '';
      this.subIndustries = [];
    }
    this.util.getLookupByType(LookupFilter.GICINDUSTRY).subscribe(_data => {
      this.industries = _data.filter(a => a.fieldValue.substring(0, 2) === value);
    });

  }

  industryChanged(value, clear = 1) {
    if (clear) {
      this.model.subindustry = '';
    }
    this.util.getLookupByType(LookupFilter.GICSUBINDUSTRY).subscribe(_data => this.subIndustries = _data.filter(a => a.fieldValue.substring(0, 4) === value));
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // this is necessary to fix `ExpressionChangedAfterItHasBeenCheckedError`
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  teamAvailable() {
    return this.model.clientEngagementDetails.services && this.model.clientEngagementDetails.services.indexOf('Px') >= 0;
  }

  retainerAvailable() {
    return this.model.clientEngagementDetails.services &&
      (this.model.clientEngagementDetails.services.indexOf('CG') >= 0 || this.model.clientEngagementDetails.services.indexOf('CMI') >= 0);
  }

  onServicesSelected() {
    if (!this.teamAvailable()) {
      this.model.clientEngagementDetails.team = null;
    }

    if (!this.retainerAvailable()) {
      this.model.clientEngagementDetails.retainer = null;
    }
  }

  onAddBillingAddress(element) {
    if (element) {
      this.model.hasDifferentBillingAddress = true;
      this.showBillingAddressSection = true;
      this.requiredDiffBilling = true;

      this.setRequiredBillingState();
    }
    else {
      this.showBillingAddressSection = false;
    }
  }

  private setRequiredBillingState() {
    if (this.model.billingCountry == "United States" || this.model.billingCountry == "Australia") {
      this.requiredBillingState = true;
    }
    else {
      this.model.billingStateName = null;
      this.model.billingStateId = null;
    }
  }

  billingStateSelectionChanged(newState: NameValueModel) {
    this.model.billingStateName = newState.name;
    this.model.billingStateId = +newState.value;

    this.setRequiredBillingState();
  }

  generalStateSelectionChanged(newState: NameValueModel) {
    this.model.generalStateName = newState.name;
    this.model.generalStateId = +newState.value;
  }

  selectionGeneralCountry(event) {
    this.model.generalStateName = null;
    this.model.generalStateId = null;
    this.model.stateName = null;
    this.model.stateId = null;
  }

  selectionBillingCountry(event) {
    this.model.billingStateName = null;
    this.model.billingStateId = null;
  }

  private getLookUps() {
    this.util.getLookupByType(LookupFilter.MONTHS).subscribe(m => this.months = m);
    this.util.getLookupByType(LookupFilter.CLIENT_JOB_TYPE).subscribe(x => this.jobTypes = x);
    this.util.getLookupByType(LookupFilter.CLIENT_SERVICES).subscribe(x => this.clientServices = x);
    this.util.getLookupByType(LookupFilter.CLIENT_TEAM).subscribe(x => this.clientTeamLookUps = x);
    this.util.getLookupByType(LookupFilter.CLIENT_RETAINER).subscribe(x => this.clientRetainerLookUps = x);
  }
}
