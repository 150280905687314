import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterAndPaginateOutputDTO } from '@app/components/table-filter-wrapper/interfaces';
import { Observable } from 'rxjs';
import { DataImportRequestAdapter, IDataImportRequestResults } from '@app/admin/us-data-imports/interfaces';
import { environment } from '@src/environments/environment';
import { map } from 'rxjs/operators';
import { VotingSummaryAdapter } from '@app/dashboard/dashboardau/dashboard-voting-summary/interfaces/voting-summary';

@Injectable({
  providedIn: 'root'
})
export class DashboardVotingSummaryService {

  constructor(private readonly httpClient: HttpClient) { }

  list(projectId, shareType, investorType): Observable<VotingSummaryAdapter[]> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.post<VotingSummaryAdapter[]>(`${environment.serverUrl}dashboards/project-summary-votes/${projectId}/${shareType}/${investorType}`, {}).pipe(map(data => data.map(item => new VotingSummaryAdapter(item))));
  }
}
