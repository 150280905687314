import {AuditLogConstants} from '@app/components/audit-logs/audit-logs-segment/generic-logs/auditLogConstants';

export const reformatValues = (value, format) => {
  if (AuditLogConstants.pipeFormatExceptions.indexOf(format) > -1) {
    value = value.replace(/(\|)/gm, ', ');
  } else if (AuditLogConstants.numberFormatExceptions.indexOf(format) > -1) {
    value = Number(parseFloat(value).toFixed(0)).toLocaleString('en', {
      minimumFractionDigits: 0
    });
  }

  return value;
}
