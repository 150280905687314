import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from 'src/app/services/user.service';
import {ProjectsService} from '@app/services/projects.service';
import {Project} from '@app/dto/Project';
import {DashboardService} from '@app/services/dashboard/dashboard.service';
import {ContactDisplayType} from '@app/dto/ContactDisplayType';
import {CallLog} from '@app/dto/CallLog';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { DirtyComponent } from '@app/common/dirty-component';
import { AlertService } from '@app/services/alert.service';
import {RoleGuardService} from '@app/auth/role-guard.service';

@Component({
  selector: 'app-dashboard-au-investorcallmanagement',
  templateUrl: './dashboard-au-investorcallmanagement.component.html',
  styleUrls: ['./dashboard-au-investorcallmanagement.component.scss']
})
export class DashboardAUInvestorcallmanagementComponent implements OnInit, DirtyComponent {
  projectModel: Project;
  selectedContact : any;
  newFeedBackCall: CallLog;

  projectid: number;
  investorid: number;
  userId: number;
  rolename: string;
  coverage: string;

  contactDisplays: ContactDisplayType[] = [];
  callLogs: CallLog[] = [];
  shareClassifications :string[] = [];

  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();
  isDirtyAddComment = false;
  isDirtyVoteRecords = false;
  canEditInvestor = false;

  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private alertService: AlertService,
              private projectsService: ProjectsService,
              private dashboardService: DashboardService,
              private roleGuardService: RoleGuardService) {
  }

  ngOnInit() {
    this.canEditInvestor = this.roleGuardService.hasAccess('DASHBOARD', 'EDIT');
    this.userId = 1; // to be changed with logged in user id
    this.rolename = this.userService.getUserRoleName();
    this.coverage = this.userService.getUserCoverage();

    this.route.params.subscribe((params) => {
      this.projectid = +params['id'];
      this.investorid = +params['invid'];
      this.getProjectData(this.projectid);
      this.getFeedback(this.projectid, this.investorid, this.coverage);
      this.getProjectClassifications(this.projectid);
    });

    this.isDirty$.subscribe(data =>{
      return of(data);
    });
  }

  getFeedback = (projectId, investorId, coverage) => {
    return this.dashboardService.getCallLogsAndContactsPerProjectInvestorCoverage(projectId, investorId, coverage).subscribe(
      data => {
        this.contactDisplays = data.contactDisplays;
        this.callLogs = data.callLogs;
      },
      err => {
        this.alertService.sendError(err);
      }
    );
  };

  getProjectData(projectId) {
    return this.projectsService.getProject(projectId).subscribe(
      data => {
        this.projectModel = data;
      },
      err => {
        this.alertService.sendError(err);
      }
    );
  }

  getProjectClassifications(projectId) {
    return this.projectsService.getProjectShareClassifications(projectId).subscribe(
      data => {
        this.shareClassifications = data;
      },
      err => {
        this.alertService.sendError(err);
      }
    )
  }

  contactSelect($event) {
    this.selectedContact = {};
    Object.assign(this.selectedContact, $event);
  }

  addFeedback($event: CallLog) {
    this.newFeedBackCall = $event;
  }

  getVoteRecordsState($event: boolean){
    this.isDirtyVoteRecords = $event;
    this.isDirty.next(this.getFormState(this.isDirtyVoteRecords, this.isDirtyAddComment));
  }

  getAddCommentState($event: boolean){
    this.isDirtyAddComment = $event;
    this.isDirty.next(this.getFormState(this.isDirtyVoteRecords, this.isDirtyAddComment));
  }

  getFormState(isVoteRecords: boolean, isAddComment: boolean): boolean{
    if (isVoteRecords === false && isAddComment === false) {
      return false;
    }

    return true;
  }
}
