import { BrokerReportModule } from './../modules/BrokerReportModule';
import { BeneficiaryLogsSegmentComponent } from '@app/components/audit-logs/beneficiary-logs-segment/beneficiary-logs-segment.component';
import { NgModule } from '@angular/core';
import { CountryAutocompleteComponent } from '@app/components/autocompletes/country-autocomplete/country-autocomplete.component';
import { CityAutocompleteComponent } from '@app/components/autocompletes/city-autocomplete/city-autocomplete.component';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientListComponent } from '@app/client/client-list.component';
import { ClientDetailLandingComponent } from '@app/client/clientdetail/clientdetail-landing.component';
import { ClientDetailGeneralComponent } from '@app/client/clientdetail/clientdetail-general/clientdetail-general.component';
import { WebsiteInputFieldComponent } from '@app/components/website-input-field/website-input-field.component';
import {
  ClientDetailProjectHistoryComponent
} from '@app/client/clientdetail/clientdetail-projecthistory/clientdetail-projecthistory.component';
import { ClientDocumentsComponent } from '@app/client/clientdetail/client-documents/client-documents.component';
import { DocumentsComponent } from '@app/components/documents/documents.component';
import { ProjectProxyAdvisersComponent } from '@app/project/projectdetail/project-proxy-advisers/project-proxy-advisers.component';
import { FileuploadComponent } from '@app/utility/fileupload.component';
import {
  DashboardProjectActivityComponent
} from '@app/dashboard/dashboardau/dashboard-actions/dashboard-project-activity/dashboard-project-activity.component';
import {
  ProjectInvestorUploadDialogComponent
} from '@app/project/projectdetail/prjinvdata/project-investor-upload-dialog/project-investor-upload-dialog.component';
import { FollowUpCallsComponent } from '@app/components/follow-up-calls/follow-up-calls.component';
import {
  ResolutionsClassificationComponent
} from '@app/components/autocompletes/resolutions-classification/resolutions-classification.component';
import { EsgSummaryTableComponent } from '@app/dashboard/dashboardau/esg-summary-table/esg-summary-table.component';
import {
  InvestorVotingProcessCommentsComponent
} from '@app/investor/investordetail/investor-voting-process/investor-voting-process-comments/investor-voting-process-comments.component';
import {
  InvestorIntelEsgSurveyComponent
} from '@app/investor/investordetail/investor-intel-esg/investor-intel-esg-survey/investor-intel-esg-survey.component';
import { ProjectActivityComponent } from '@app/project/projectdetail/project-activity/project-activity.component';
import { LocalCustodiansLendingComponent } from '@app/admin/local-custodians/local-custodians-lending.component';
import { CallLogTableComponent } from '@app/dashboard/dashboardau/call-log-table/call-log-table.component';

import {
  AddInvestorInitiativeDialogComponent
} from '@app/investor/investordetail/investor-intel-esg-initiative/addinvestorinitiativedialog/addinitiativedialog.component';
import { InvestorListComponent } from '@app/investor/investor-list.component';
import {
  ProjectActivityEditDialogComponent
} from '@app/project/projectdetail/project-activity/dialog/project-activity-edit-dialog/project-activity-edit-dialog.component';
import { NotificationsComponent } from '@app/header/notifications/notifications.component';
import { VotingItemsListComponent } from '@app/project/projectdetail/voting-items-list/voting-items-list.component';
import { ClientContactsComponent } from '@app/client/clientdetail/client-contacts/client-contacts.component';
import { ToggleSwitch } from '@app/components/toggle-switch/toggle-switch.component';
import { DashboardAUSummaryComponent } from '@app/dashboard/dashboardau/detail/dashboard-au-summary.component';
import {
  ProxyAdvisorContactComponent
} from '@app/admin/proxy-advisor/proxy-advisor-sub-menu/proxy-advisor-contact/proxy-advisor-contact.component';
import { InitiativeDetailComponent } from '@app/admin/initiative/initiative-sub-menu/initiativedetail/initiativedetail.component';
import { BeneficiaryDetailComponent } from '@app/admin/beneficiary/beneficiarydetail/beneficiarydetail.component';
import { DashboardAuditLogsComponent } from '@app/dashboard/dashboardau/detail/dashboard-audit-logs/dashboard-audit-logs.component';
import { ResolutionClassificationLandingComponent } from '@app/admin/resolution-classification/resolution-classification-landing.component';
import { LocalCustodiansDialogComponent } from '@app/admin/local-custodians/local-custodians-dialog/local-custodians-dialog.component';
import {
  BulkEditVotesDialogComponent
} from '@app/dashboard/dashboardau/detail/investor-call-management/vote-records/bulk-edit-dalog/bulk-edit-votes-dialog.component';
import {
  DashboardAuCustodialReconciliationTableComponent
} from '@app/dashboard/dashboardau/dashboard-custodial-reconciliation-table/dashboard-au-custodial-reconciliation-table.component';
import { ContactListComponent } from '@app/contact/contact-list.component';
import { InvestorIntelEsgComponent } from '@app/investor/investordetail/investor-intel-esg/investor-intel-esg.component';
import {
  InvValueCountryEditDialogComponent
} from '@app/investor/investordetail/investor-value-country-grid/edit-dialogue/inv-value-country-edit-dialogue/inv-value-country-edit-dialog.component';
import {
  InvestorValueSelectGroupComponent
} from '@app/investor/investordetail/investor-voting-process/investor-value-select-group/investor-value-select-group.component';
import { ProjectFinancialComponent } from '@app/project/projectdetail/project-financial/project-financial.component';
import {
  InvestorIntelEngagementExampleComponent
} from '@app/investor/investordetail/engagement-example/investor-intel-engagement-example.component';
import {
  ProjectCustodianReconciliationDialogComponent
} from '@app/project/projectdetail/project-custodial-reconcilation/dialog/project-custodian-reconciliation-dialog.component';
import { InvestorVotingProcessComponent } from '@app/investor/investordetail/investor-voting-process/investor-voting-process.component';
import {
  VotingLogsDeleteSegmentComponent
} from '@app/components/audit-logs/audit-logs-segment/voting-logs/voting-logs-delete-segment/voting-logs-delete-segment.component';
import {
  EditInvestorDialogComponent
} from '@app/project/projectdetail/prjinvdata/dialog/edit-investor-dalog/edit-investor-dialog.component';
import {
  InvestorEngagementExampleDialogComponent
} from '@app/investor/investordetail/engagement-example/engagement-example-dialog/engagement-example-dialog.component';
import { DashboardAUFeedbackComponent } from '@app/dashboard/dashboardau/detail/dashboard-au-feedback.component';
import { GridFilterComponent } from '@app/components/grid-filter/grid-filter.component';
import { AuditLogsSegmentComponent } from '@app/components/audit-logs/audit-logs-segment/audit-logs-segment.component';
import { OptionsPickerComponent } from '@app/components/options-picker/options-picker.component';
import { AuditLogsComponent } from '@app/components/audit-logs/audit-logs.component';
import {
  GenericAuditLogsInsertSegmentComponent
} from '@app/components/audit-logs/audit-logs-segment/generic-logs/generic-logs-insert-segment/generic-logs-insert-segment.component';
import {
  EsgReportsSectionComponent
} from '@app/investor/investordetail/investor-intel-esg-reporting/esg-reports-section/esg-reports-section.component';
import { ProjectRecommendationComponent } from '@app/project/projectdetail/project-recommendation.component';
import { InvestorContactComponent } from '@app/investor/investordetail/investor-contact.component';
import { DashboardCampaignTableComponent } from '@app/dashboard/dashboardau/dashboard-campaign-table/dashboard-campaign-table.component';
import {
  LocalCustodiansContactsComponent
} from '@app/admin/local-custodians/local-custodians-sub-menu/local-custodians-contacts/local-custodians-contacts.component';
import { DashboardAULandingComponent } from '@app/dashboard/dashboardau/detail/dashboard-au-landing.component';
import { ProjectRecommendationDlgComponent } from '@app/project/projectdetail/project-recommendation-dlg.component';

import {
  InvestorIntelEngagementComponent
} from '@app/investor/investordetail/investor-intel-engagement/investor-intel-engagement.component';
import {
  VotingLogsUpdateSegmentComponent
} from '@app/components/audit-logs/audit-logs-segment/voting-logs/voting-logs-update-segment/voting-logs-update-segment.component';
import { ProjectInvestorAUDataComponent } from '@app/project/projectdetail/prjinvdata/project-investor-au-data.component';
import { DatePickerComponent } from '@app/components/date-picker/date-picker.component';
import { EsgPaGeneralComponent } from '@app/admin/esg-providers-and-associations/esg-pa-sub-menu/esg-pa-general/esg-pa-general.component';
import { LoadFileSectionComponent } from '@app/components/documents/load-file-section/load-file-section.component';
import { LoadFileDialogComponent } from '@app/components/documents/load-file-dialog/load-file-dialog.component';
import { ContactConversationLogComponent } from '@app/contact/contactdetail/contact-conversationlog.component';
import {
  InvestorInitiativeGridComponent
} from '@app/investor/investordetail/investor-intel-common-grid/investor-intel-common-grid.component';
import {
  ResolutionClassificationDialogComponent
} from '@app/admin/resolution-classification/resolution-classification-dialog/resolution-classification-dialog.component';
import {
  InvestorCallManagementVoteRecordsComponent
} from '@app/dashboard/dashboardau/detail/investor-call-management/vote-records/investor-call-management-vote-records.component';
import {
  EsgFocusDisclosureComponent
} from '@app/investor/investordetail/investor-intel-esg-reporting/esg-focus-disclosure/esg-focus-disclosure.component';
import { ProxyGeneralComponent } from '@app/admin/proxy-advisor/proxy-advisor-sub-menu/proxy-advisor-general/proxy-general.component';
import { DropdownSectionComponent } from '@app/components/documents/custom-field-section/dropdown-section/dropdown-section.component';
import { RptProjectListComponent } from '@app/report/au/rpt-project-list.component';
import {
  InvestorValueCountryGridComponent
} from '@app/investor/investordetail/investor-value-country-grid/investor-value-country-grid.component';
import { CurrentActivitiesComponent } from '@app/components/current-activities/current-activities.component';
import {
  ResolutionClassificationGeneralComponent
} from '@app/admin/resolution-classification/resolution-classification-sub-menu/resolution-classification-general/resolution-classification-general.component';
import {
  InvestorIntelEsgInvestmentFundsComponent
} from '@app/investor/investordetail/investor-intel-esg-investment/investor-intel-esg-investment-funds/investor-intel-esg-investment-funds.component';
import {
  ResolutionCustomFieldsComponent
} from '@app/admin/resolution-classification/resolution-custom-fields/resolution-custom-fields.component';
import {
  EsgPaContactsComponent
} from '@app/admin/esg-providers-and-associations/esg-pa-sub-menu/esg-pa-contacts/esg-pa-contacts.component';
import {
  EsgArticlesOpinionsComponent
} from '@app/investor/investordetail/investor-intel-esg-reporting/esg-articles-opinions/esg-articles-opinions.component';
import {
  SustainableInvestorResourcesFieldsComponent
} from '@app/investor/investordetail/investor-intel-esg/sustainable-investor-resources-fields/sustainable-investor-resources-fields.component';
import {
  ActiveDirectoryUserListComponent
} from '@app/components/autocompletes/active-directory-user-list/active-directory-user-list.component';
import {
  GenericAuditLogsUpdateSegmentComponent
} from '@app/components/audit-logs/audit-logs-segment/generic-logs/generic-logs-update-segment/generic-logs-update-segment.component';
import {
  LocalCustodiansGeneralComponent
} from '@app/admin/local-custodians/local-custodians-sub-menu/local-custodians-general/local-custodians-general.component';
import {
  BenenficiarymaintenancedialogComponent
} from '@app/admin/beneficiary/benenficiarymaintenancedialog/benenficiarymaintenancedialog.component';
import { ColorLegendComponent } from '@app/components/color-legend/color-legend.component';
import { ExpandListCheckboxComponent } from '@app/components/expand-list-checkbox/expand-list-checkbox.component';
import { ProxyAdvisorLandingComponent } from '@app/admin/proxy-advisor/proxyadvisor-landing.component';
import { DialogsComponent } from '@app/services/dialogs/dialogs-component/dialogs.component';
import { EsgPaAddEditComponent } from '@app/admin/esg-providers-and-associations/esg-pa-add-edit/esg-pa-add-edit.component';
import {
  DashboardAUInvestorcallmanagementComponent
} from '@app/dashboard/dashboardau/detail/dashboard-au-investorcallmanagement.component';
import {
  InvestorIntelEsgInvestmentComponent
} from '@app/investor/investordetail/investor-intel-esg-investment/investor-intel-esg-investment.component';
import { EsgPaDialogComponent } from '@app/admin/esg-providers-and-associations/esg-pa-dialog/esg-pa-dialog.component';
import { ProjectInvestorControllerComponent } from '@app/project/projectdetail/project-investor-controller.component';
import {
  ArticlesOpinionsDialogComponent
} from '@app/investor/investordetail/investor-intel-esg-reporting/esg-articles-opinions/articles-opinions-dialog/articles-opinions-dialog.component';
import {
  DashboardAuCampaignSummaryTableComponent
} from '@app/dashboard/dashboardau/dashboard-campaign-summary-table/dashboard-au-campaign-summary-table.component';
import { InvestorAuditLogsComponent } from '@app/investor/investor-audit-logs/investor-audit-logs.component';
import {
  InvestorLogisticalIntelligenceComponent
} from '@app/investor/investordetail/investor-logistical-intelligence/investor-logistical-intelligence.component';
import {
  InvestorCallManagementCommentTableComponent
} from '@app/dashboard/dashboardau/detail/investor-call-management/comments-table/investor-call-management-comment-table.component';
import {
  ProjectFinancialEditDialogComponent
} from '@app/project/projectdetail/project-financial/dialog/project-financial-edit-dialog.component';
import {
  GlobalCustodiansGeneralComponent
} from '@app/admin/global-custodians/global-custodians-sub-menu/global-custodians-general/global-custodians-general.component';
import {
  InvestorIntelEsgEngagementComponent
} from '@app/investor/investordetail/investor-intel-esg-engagement/investor-intel-esg-engagement.component';
import { InvestorGeneralComponent } from '@app/investor/investordetail/investor-general.component';
import {
  InvestorIntelEsgReportingComponent
} from '@app/investor/investordetail/investor-intel-esg-reporting/investor-intel-esg-reporting.component';
import { AssignProjectsToClientsComponent } from '@app/components/assign-projects-to-clients/assign-projects-to-clients.component';
import {
  AddEditInvestorDialogComponent
} from '@app/project/projectdetail/prjinvdata/dialog/add-edit-investor-dialog/add-edit-investor-dialog.component';
import {
  VotingItemCreateEditDlgComponent
} from '@app/project/projectdetail/voting-items-list/voting-item-create-edit-dlg/voting-item-create-edit-dlg.component';
import { LoadFileGeneralComponent } from '@app/components/documents/load-file-general/load-file-general.component';


import { ContactGeneralComponent } from '@app/contact/contactdetail/contact-general/contact-general.component';
import { StatesComponent } from '@app/components/states/states.component';
import { ConfirmDialogComponent } from '@app/components/confirm-dialog/confirm-dialog.component';
import { DashboardListAUComponent } from '@app/dashboard/dashboardau/dashboard-list-au.component';
import { DashboardDetailListAUComponent } from '@app/dashboard/dashboardau/dashboard-detail-list-au.component';
import { ProjectListComponent } from '@app/project/project-list.component';
import { ProjectLandingComponent } from '@app/project/projectdetail/project-landing.component';
import { ContactDlgComponent } from '@app/contact/contactdetail/contact-dlg.component';
import {
  LocalCustodiansLinksComponent
} from '@app/admin/local-custodians/local-custodians-sub-menu/local-custodians-links/local-custodians-links.component';
import { EsgReportsComponent } from '@app/investor/investordetail/investor-intel-esg-reporting/esg-reports/esg-reports.component';
import {
  InvestorIntelEsgInitiativeComponent
} from '@app/investor/investordetail/investor-intel-esg-initiative/investor-intel-esg-initiative.component';
import { LocalCustodiansAddEditComponent } from '@app/admin/local-custodians/local-custodians-add-edit/local-custodians-add-edit.component';
import {
  CommentEditDialogComponent
} from '@app/dashboard/dashboardau/detail/investor-call-management/comments-table/comment-edit-dialog/comment-edit-dialog.component';
import { DashboardActionsComponent } from '@app/dashboard/dashboardau/dashboard-actions/dashboard-actions.component';

import {
  ExclusionRuleDialogComponent
} from '@app/investor/investordetail/investor-intel-esg-investment/exclusion-rule/exclusion-rule-dialog/exclusion-rule-dialog.component';
import { TypeAheadSearchComponent } from '@app/components/autocompletes/type-ahead-search/type-ahead-search.component';
import { ClearingHouseDialogComponent } from '@app/admin/clearing-houses/clearing-house-dialog/clearing-house-dialog.component';
import { ContactLandingComponent } from '@app/contact/contactdetail/contact-landing.component';

import { ContactsGridViewComponent } from '@app/contacts-grid-view/contacts-grid-view.component';
import { RptListComponent } from '@app/report/au/rpt-list.component';
import { GlobalCustodiansLendingComponent } from '@app/admin/global-custodians/global-custodians-lending.component';
import {
  VotingLogsInsertSegmentComponent
} from '@app/components/audit-logs/audit-logs-segment/voting-logs/voting-logs-insert-segment/voting-logs-insert-segment.component';
import { NumberCurrencyComponent } from '@app/components/number-currency/number-currency.component';
import { AlternativeNamesComponent } from '@app/components/alternative-names/alternative-names.component';
import { ClearingHouseAddEditComponent } from '@app/admin/clearing-houses/clearing-house-add-edit/clearing-house-add-edit.component';
import {
  GenericAuditLogsDeleteSegmentComponent
} from '@app/components/audit-logs/audit-logs-segment/generic-logs/generic-logs-delete-segment/generic-logs-delete-segment.component';
import { BeneficiaryAutocompleteComponent } from '@app/components/autocompletes/beneficiry-autocomplete/beneficiary-autocomplete.component';
import {
  InvestorCallManagementContactListComponent
} from '@app/dashboard/dashboardau/detail/investor-call-management/contact-list/investor-call-management-contact-list.component';
import { SafeStylePipe } from '@app/common/safe-style.pipe';
import { ClearingHouseLendingComponent } from '@app/admin/clearing-houses/clearing-house-lending.component';
import { InvestorProxyComponent } from '@app/investor/investordetail/investor-proxy/investor-proxy.component';
import { ExclusionRuleComponent } from '@app/investor/investordetail/investor-intel-esg-investment/exclusion-rule/exclusion-rule.component';
import { ProxyAdvisorDialogComponent } from '@app/admin/proxy-advisor/proxy-advisor-dialog/proxy-advisor-dialog.component';
import {
  ResolutionAddEditClassificationComponent
} from '@app/admin/resolution-classification/resolution-add-edit-classification/resolution-add-edit-classification.component';
import { EsgPaLandingComponent } from '@app/admin/esg-providers-and-associations/esg-pa-landing.component';
import {
  InitiativeDocumentsComponent
} from '@app/admin/initiative/initiative-sub-menu/initiative-documents/initiative-documents.component';
import { UpcomingFollowUpComponent } from '@app/components/upcoming-follow-up/upcoming-follow-up.component';
import { ProjectAuditLogsComponent } from '@app/project/project-audit-logs/project-audit-logs.component';
import { FilterModalComponent } from '@app/components/grid-filter/filter-modal/filter-modal.component';
import { InvestorLandingComponent } from '@app/investor/investordetail/investor-landing.component';
import {
  ProjectCustodialReconciliationComponent
} from '@app/project/projectdetail/project-custodial-reconcilation/project-custodial-reconciliation.component';
import { MatchInvestorDialogComponent } from '@app/project/projectdetail/prjinvdata/dialog/match-investor-dialog.component';

import { AddInitiativeDialogComponent } from '@app/admin/initiative/addinitiativedialog/addinitiativedialog.component';
import { InvestorBulkEditComponent } from '@app/investor/investor-bulk-edit/investor-bulk-edit.component';
import {
  AddEditCustomFieldComponent
} from '@app/admin/resolution-classification/resolution-custom-fields/add-edit-custom-field/add-edit-custom-field.component';
import {
  InvestorCallManagementAddCommentComponent
} from '@app/dashboard/dashboardau/detail/investor-call-management/add-comment/investor-call-management-add-comment.component';
import { InitiativeLandingComponent } from '@app/admin/initiative/initiative-landing.component';
import {
  InvestorInitiativeFieldsComponent
} from '@app/investor/investordetail/investor-intel-esg-initiative/investor-initiative-fields/investor-initiative-fields.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PpBreadcrumbsModule } from 'pp-breadcrumbs';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  ExternalAdvisorsAddEditComponent
} from '@app/admin/external-advisors/external-advisors-add-edit/external-advisors-add-edit.component';
import {
  ClientExternalAdvisorContactsComponent
} from '@app/client/clientdetail/client-external-advisor-contacts/client-external-advisor-contacts.component';
import { BeneficiaryLandingComponent } from '@app/admin/beneficiary/beneficiary-landing/beneficiary-landing.component';
import { KeyEventsComponent } from '@app/project/key-events/key-events.component';
import { AssignProjectsToTeamMembersComponent } from './assign-projects-to-team-members/assign-projects-to-team-members.component';
import { DashboardCustodianTableComponent } from '@app/dashboard/dashboardau/dashboard-custodian-table/dashboard-custodian-table.component';
import { CustodialListComponent } from '@app/project/projectdetail/custodial-list/custodial-list.component';
import {
  DashboardPositionTrackingComponent
} from '@app/dashboard/dashboardau/dashboard-position-tracking/dashboard-position-tracking.component';
import {
  VotingReconciliationComponent
} from '@app/dashboard/dashboardau/dashboard-custodian-table/custodian-details/voting-reconciliation/voting-reconciliation.component';
import {
  GlobalCustodiansCodesComponent
} from '../admin/global-custodians/global-custodians-sub-menu/global-custodians-general/global-custodians-codes.component';
import { DeleteButtonComponent } from '../common/buttons/delete/delete-button/delete-button.component';
import { DashboardMailingComponent } from '@app/dashboard/dashboardau/dashboard-mailing-table/dashboard-mailing-component';
import {
  DashboardOrderReceiveDocumentComponent
} from '@app/dashboard/dashboardau/dashboard-mailing-table/dashboard-mailing-dialog-order-receive/dashboard-mailing-dialog-order-receive.component';
import {
  DashboardAddMailingDocumentComponent
} from '@app/dashboard/dashboardau/dashboard-mailing-table/dashboard-mailing-dialog-add-doc/dashboard-mailing-add-doc-component';
import { DashboardNoboHrTableComponent } from '@app/dashboard/dashboardau/dashboard-nobo-hr-table/dashboard-nobo-hr-table.component';
import {
  DashboardMailingEventHistoryComponent
} from '@app/dashboard/dashboardau/dashboard-mailing-table/dashboard-mailing-dialog-add-doc/dashboard-mailing-event-history/dashboard-mailing-event-history.component';
import {
  EventHistoryEditDialogComponent
} from '@app/dashboard/dashboardau/dashboard-mailing-table/dashboard-mailing-dialog-add-doc/dashboard-mailing-event-history/event-history-edit-dialog/event-history-edit-dialog.component';
import {
  DashboardMailingRecipientComponent
} from '@app/dashboard/dashboardau/dashboard-mailing-recipient-table/dashboard-mailing-recipient.component';
import {
  DashboardVotingHistoryDialogComponent
} from '@app/dashboard/dashboardau/dashboard-nobo-hr-table/dashboard-voting-history-dialog/dashboard-voting-history-dialog.component';
import {
  DashboardAddEditMailingDocRecipientComponent
} from '@app/dashboard/dashboardau/dashboard-mailing-recipient-table/doc-recipient-add-edit/dashboard-mailing-doc-recipient-add-edit.component';
import {
  DashboardMailingDocRecipientSentComponent
} from '@app/dashboard/dashboardau/dashboard-mailing-recipient-table/doc-recipient-sent/dashboard-mailing-doc-recipient-sent.component';
import { AssignDirectorToVotingItemsComponent } from './assign-director-to-voting-items/assign-director-to-voting-items.component';
import { DropdownSelectComponent } from './dropdown-select/dropdown-select.component';
import {
  DashboarMailingRecipientDeliveryLabelComponent
} from '@app/dashboard/dashboardau/dashboard-mailing-recipient-table/recipient-delivery-label/recipient-delivery-label.component';
import { MatSelectSearchComponent } from './dropdown-select/mat-select-search/mat-select-search.component';
import { AddressTextareaModule } from '@app/common/address-textarea/address-textarea.module';
import { DebounceClickDirective } from '@app/common/directives/debounce-click.directive';
import { PageHeaderComponent } from './page-header/page-header.component';
import { VoteTableComponent } from './vote-table/vote-table.component';
import { AlignCellBasedOnProjectTemplateDirective } from '@app/common/directives/align-cell-based-on-project-template.directive';
import { DashboardRecipientEventHistoryComponent } from '@app/dashboard/dashboardau/dashboard-mailing-recipient-table/doc-recipient-add-edit/dashboard-recipient-event-history/dashboard-recipient-event-history.component';
import { RecipientEventHistoryEditDialogComponent } from '@app/dashboard/dashboardau/dashboard-mailing-recipient-table/doc-recipient-add-edit/dashboard-recipient-event-history/rec-evnt-hstr-edit/rec-evnt-hstr-edit.component';
import { OciExportSetUpDialogComponent } from '@app/dashboard/dashboardau/dashboard-nobo-hr-table/oci-export-set-up-dialog/oci-export-set-up-dialog.component';
import { IndividualDeliveryTicketComponent } from '@app/dashboard/dashboardau/dashboard-mailing-recipient-table/individual-delivery-ticket/individual-delivery-ticket.component';
import { IndividualTicketFormComponent } from '@app/dashboard/dashboardau/dashboard-mailing-recipient-table/individual-delivery-ticket/individual-delivery-ticket-form/individual-ticket-form.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { TableFilterWrapperModule } from '@app/components/table-filter-wrapper/table-filter-wrapper.module';
import { ExportLiveVotesDialogComponent } from './export-live-votes-dialog/export-live-votes-dialog.component';
import { CustodianReportDialogComponent } from '@app/dashboard/dashboardau/custodian-report-dialog/custodian-report-dialog.component';
import { UsActivityDashboardComponent } from './us-activity-dashboard/us-activity-dashboard.component';
import { UsActivityItemComponent } from './us-activity-dashboard/us-activity-item/us-activity-item.component';
import { DashboardVotingSummaryComponent } from '@app/dashboard/dashboardau/dashboard-voting-summary/dashboard-voting-summary.component';
import { AutocompleteComponent } from './autocompletes/autocomplete/autocomplete.component';
import { VotingSummaryTableComponent } from '@app/dashboard/dashboardau/dashboard-voting-summary/voting-summary-table/voting-summary-table.component';
import { MatMultiSortModule } from '@app/components/mat-multi-sort/mat-multi-sort.module';
import { NegativeNumbersDirective } from '@app/common/directives/negative-numbers.directive';
import { NoItemsGridModule } from '@app/modules/NoItemsGridModule';
import { DashboardVotingIntentionsComponent } from '@app/dashboard/dashboardau/detail/dashboard-voting-intentions.component';
import { InvestorVotingHistoryComponent } from '@app/investor/investordetail/investor-history-summary/investor-voting-history.component';
import { InvestorVotingSummaryTableComponent } from '@app/investor/investordetail/investor-history-summary/investor-voting-summary-table/investor-voting-summary-table.component';
import { DashboardVotingIntentionsTableComponent } from '@app/dashboard/dashboardau/detail/investor-call-management/voting-inentions-table/voting-intentions-table.component';
import { DashboardInvestorVotingComponent } from '@app/dashboard/dashboardau/detail/dashboard-investor-voting/dashboard-investor-voting.component';
import { InvestorVotingSummaryFiltersComponent } from '@app/investor/investordetail/investor-history-summary/investor-voting-summary-filters/investor-voting-summary-filters.component';
import { MultiselectChipsComponent } from './multiselect-chips/multiselect-chips.component';
import { InvestorVotingTableComponent } from '@app/dashboard/dashboardau/detail/dashboard-investor-voting/investor-voting-table/investor-voting-table.component';
import { CustodianVotesImportDialogComponent } from '@app/dashboard/dashboardau/custodian-votes-import-dialog/custodian-votes-import-dialog.component';
import { InvestorFiltersSummaryComponent } from '@app/investor/investordetail/investor-history-summary/investor-filters-summary/investor-filters-summary.component';
import { AddCustodianVotesDialogComponent } from '@app/dashboard/dashboardau/detail/dashboard-investor-voting/add-custodian-votes-dialog/add-custodian-votes-dialog.component';
import { InvestorVotingItemDetailsComponent } from '@app/investor/investordetail/investor-history-summary/investor-voting-summary-table/investor-voting-item-details/investor-voting-item-details.component';
import { AddInvestorCustodianComponent } from '@app/investor/investordetail/investor-voting-process/add-investor-custodian/add-investor-custodian.component';
import { AddInvestorCustodianDlgComponent } from '@app/investor/investordetail/investor-voting-process/add-investor-custodian/add-investor-custodian-dlg/add-investor-custodian-dlg.component';
import { VotingHistoryVoteDetailsComponent } from './voting-history-vote-details/voting-history-vote-details.component';
import { OvervoteConfirmDialogComponent } from '@app/dashboard/dashboardau/detail/dashboard-investor-voting/overvote-confirm-dialog/overvote-confirm-dialog.component';
import { CustodianVotesImportResultDlgComponent } from '@app/dashboard/dashboardau/custodian-votes-import-dialog/custodian-votes-import-result-dlg/custodian-votes-import-result-dlg.component';
import { CustodianTableComponent } from '@app/investor/investordetail/investor-voting-process/custodian-table/custodian-table.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        MatTableModule,
        MatToolbarModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatSortModule,
        MatTabsModule,
        MatInputModule,
        MatCheckboxModule,
        CdkTableModule,
        MatExpansionModule,
        MatDialogModule,
        MatDatepickerModule,
        MatListModule,
        MatNativeDateModule,
        MatRadioModule,
        MatAutocompleteModule,
        NgbModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        PpBreadcrumbsModule,
        MatPaginatorModule,
        MatBadgeModule,
        CurrencyMaskModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxSpinnerModule,
        MatChipsModule,
        AddressTextareaModule,
        MatProgressSpinnerModule,
        TableFilterWrapperModule,
        MatMultiSortModule,
        BrokerReportModule,
        NoItemsGridModule
    ],
  declarations: [
    AssignDirectorToVotingItemsComponent,
    EventHistoryEditDialogComponent,
    ExternalAdvisorsAddEditComponent,
    ClientDocumentsComponent,
    ClientDetailProjectHistoryComponent,
    WebsiteInputFieldComponent,
    ClientDetailGeneralComponent,
    ClientDetailLandingComponent,
    ClientListComponent,
    CountryAutocompleteComponent,
    CityAutocompleteComponent,
    DocumentsComponent,
    ProjectProxyAdvisersComponent,
    FileuploadComponent,
    LoadFileSectionComponent,
    ColorLegendComponent,
    LoadFileGeneralComponent,
    DropdownSectionComponent,
    ClientContactsComponent,
    ClientExternalAdvisorContactsComponent,
    ContactsGridViewComponent,
    GlobalCustodiansLendingComponent,
    LocalCustodiansLendingComponent,
    LocalCustodiansGeneralComponent,
    LocalCustodiansContactsComponent,
    ClearingHouseLendingComponent,
    GlobalCustodiansGeneralComponent,
    GlobalCustodiansCodesComponent,
    ActiveDirectoryUserListComponent,
    InvestorValueCountryGridComponent,
    LocalCustodiansAddEditComponent,
    LocalCustodiansDialogComponent,
    ClearingHouseAddEditComponent,
    ClearingHouseDialogComponent,
    ContactListComponent,
    ContactLandingComponent,
    ContactGeneralComponent,
    ContactConversationLogComponent,
    ContactDlgComponent,
    LocalCustodiansLinksComponent,
    EsgPaLandingComponent,
    EsgPaGeneralComponent,
    EsgPaAddEditComponent,
    EsgPaDialogComponent,
    EsgPaContactsComponent,
    InitiativeLandingComponent,
    InitiativeDetailComponent,
    ToggleSwitch,
    OptionsPickerComponent,
    ExpandListCheckboxComponent,
    InitiativeDocumentsComponent,
    ResolutionAddEditClassificationComponent,
    ResolutionClassificationDialogComponent,
    ResolutionClassificationLandingComponent,
    ResolutionClassificationGeneralComponent,
    ResolutionCustomFieldsComponent,
    ResolutionsClassificationComponent,
    ExclusionRuleComponent,
    ExclusionRuleDialogComponent,
    VotingItemsListComponent,
    VotingItemCreateEditDlgComponent,
    EsgArticlesOpinionsComponent,
    BeneficiaryLandingComponent,
    BeneficiaryDetailComponent,
    BenenficiarymaintenancedialogComponent,
    BeneficiaryAutocompleteComponent,
    ProjectAuditLogsComponent,
    InvestorIntelEsgEngagementComponent,
    InvestorIntelEngagementExampleComponent,
    InvestorEngagementExampleDialogComponent,
    InvestorIntelEsgInvestmentComponent,
    InvestorIntelEsgReportingComponent,
    InvestorInitiativeGridComponent,
    AlternativeNamesComponent,
    InvestorProxyComponent,
    InvestorIntelEsgComponent,
    InvestorValueSelectGroupComponent,
    InvestorVotingProcessCommentsComponent,
    InvestorIntelEngagementComponent,
    DatePickerComponent,
    InvestorLogisticalIntelligenceComponent,
    MatchInvestorDialogComponent,
    EditInvestorDialogComponent,
    ProjectInvestorUploadDialogComponent,
    GridFilterComponent,
    FilterModalComponent,
    ConfirmDialogComponent,
    ProjectFinancialEditDialogComponent,
    FollowUpCallsComponent,
    AddEditInvestorDialogComponent,
    DialogsComponent,
    UpcomingFollowUpComponent,
    CurrentActivitiesComponent,
    LoadFileDialogComponent,
    ProjectActivityEditDialogComponent,
    AuditLogsComponent,
    AuditLogsSegmentComponent,
    DashboardAuditLogsComponent,
    VotingLogsUpdateSegmentComponent,
    VotingLogsDeleteSegmentComponent,
    VotingLogsInsertSegmentComponent,
    InvestorIntelEsgInitiativeComponent,
    AddInvestorInitiativeDialogComponent,
    NumberCurrencyComponent,
    InvestorAuditLogsComponent,
    InvestorIntelEsgInvestmentFundsComponent,
    ProxyAdvisorContactComponent,
    ProxyGeneralComponent,
    EsgReportsSectionComponent,
    InvestorIntelEsgSurveyComponent,
    SustainableInvestorResourcesFieldsComponent,
    InvestorInitiativeFieldsComponent,
    EsgReportsComponent,
    EsgFocusDisclosureComponent,
    ProxyAdvisorLandingComponent,
    InvestorListComponent,
    InvestorLandingComponent,
    InvestorGeneralComponent,
    InvestorContactComponent,
    ProjectListComponent,
    ProjectLandingComponent,
    ProjectFinancialComponent,
    ProjectActivityComponent,
    StatesComponent,
    AssignProjectsToClientsComponent,
    InvestorBulkEditComponent,
    TypeAheadSearchComponent,
    NotificationsComponent,
    InvestorCallManagementContactListComponent,
    InvestorCallManagementAddCommentComponent,
    InvestorCallManagementCommentTableComponent,
    CommentEditDialogComponent,
    InvestorCallManagementVoteRecordsComponent,
    SafeStylePipe,
    DashboardActionsComponent,
    ProjectCustodialReconciliationComponent,
    ProjectCustodianReconciliationDialogComponent,
    DashboardProjectActivityComponent,
    BulkEditVotesDialogComponent,
    InvestorVotingProcessComponent,
    ProjectRecommendationComponent,
    ProjectRecommendationDlgComponent,
    ProjectInvestorAUDataComponent,
    ProjectInvestorControllerComponent,
    DashboardAULandingComponent,
    DashboardListAUComponent,
    DashboardDetailListAUComponent,
    DashboardAUFeedbackComponent,
    DashboardAUInvestorcallmanagementComponent,
    DashboardAUSummaryComponent,
    DashboardAuCampaignSummaryTableComponent,
    DashboardAuCustodialReconciliationTableComponent,
    DashboardCampaignTableComponent,
    CallLogTableComponent,
    EsgSummaryTableComponent,
    GenericAuditLogsDeleteSegmentComponent,
    GenericAuditLogsInsertSegmentComponent,
    GenericAuditLogsUpdateSegmentComponent,
    InvValueCountryEditDialogComponent,
    AddInitiativeDialogComponent,
    ArticlesOpinionsDialogComponent,
    ProxyAdvisorDialogComponent,
    AddEditCustomFieldComponent,
    RptProjectListComponent,
    RptListComponent,
    KeyEventsComponent,
    AssignProjectsToTeamMembersComponent,
    BeneficiaryLogsSegmentComponent,
    DashboardCustodianTableComponent,
    CustodialListComponent,
    DashboardPositionTrackingComponent,
    VotingReconciliationComponent,
    DeleteButtonComponent,
    DashboardMailingComponent,
    DashboardAddMailingDocumentComponent,
    DashboardOrderReceiveDocumentComponent,
    DashboardNoboHrTableComponent,
    DashboardMailingEventHistoryComponent,
    DashboardMailingRecipientComponent,
    DashboardVotingHistoryDialogComponent,
    OciExportSetUpDialogComponent,
    DashboardAddEditMailingDocRecipientComponent,
    DashboardMailingDocRecipientSentComponent,
    DropdownSelectComponent,
    DashboarMailingRecipientDeliveryLabelComponent,
    MatSelectSearchComponent,
    DebounceClickDirective,
    PageHeaderComponent,
    VoteTableComponent,
    AlignCellBasedOnProjectTemplateDirective,
    DashboardRecipientEventHistoryComponent,
    RecipientEventHistoryEditDialogComponent,
    IndividualDeliveryTicketComponent,
    IndividualTicketFormComponent,
    InfiniteScrollComponent,
    ExportLiveVotesDialogComponent,
    CustodianReportDialogComponent,
    UsActivityDashboardComponent,
    UsActivityItemComponent,
    DashboardVotingSummaryComponent,
    AutocompleteComponent,
    VotingSummaryTableComponent,
    NegativeNumbersDirective,
    DashboardVotingIntentionsComponent,
    InvestorVotingHistoryComponent,
    InvestorVotingSummaryFiltersComponent,
    MultiselectChipsComponent,
    InvestorFiltersSummaryComponent,
    AddCustodianVotesDialogComponent,
    DashboardVotingIntentionsTableComponent,
    DashboardInvestorVotingComponent,
    InvestorVotingTableComponent,
    CustodianVotesImportDialogComponent,
    CustodianVotesImportResultDlgComponent,
    InvestorVotingSummaryTableComponent,
    InvestorVotingItemDetailsComponent,
    AddInvestorCustodianComponent,
    AddInvestorCustodianDlgComponent,
    VotingHistoryVoteDetailsComponent,
    OvervoteConfirmDialogComponent,
    CustodianTableComponent
  ],
  exports: [
    EventHistoryEditDialogComponent,
    ExternalAdvisorsAddEditComponent,
    DocumentsComponent,
    ClientDocumentsComponent,
    ClientDetailProjectHistoryComponent,
    WebsiteInputFieldComponent,
    ClientDetailGeneralComponent,
    ClientDetailLandingComponent,
    ClientListComponent,
    CountryAutocompleteComponent,
    CityAutocompleteComponent,
    ProjectProxyAdvisersComponent,
    FileuploadComponent,
    LoadFileSectionComponent,
    ColorLegendComponent,
    LoadFileGeneralComponent,
    DropdownSectionComponent,
    ClientContactsComponent,
    ClientExternalAdvisorContactsComponent,
    ContactsGridViewComponent,
    GlobalCustodiansLendingComponent,
    LocalCustodiansLendingComponent,
    LocalCustodiansGeneralComponent,
    LocalCustodiansContactsComponent,
    ClearingHouseLendingComponent,
    GlobalCustodiansGeneralComponent,
    GlobalCustodiansCodesComponent,
    ActiveDirectoryUserListComponent,
    InvestorValueCountryGridComponent,
    LocalCustodiansAddEditComponent,
    LocalCustodiansDialogComponent,
    ClearingHouseAddEditComponent,
    ClearingHouseDialogComponent,
    ContactListComponent,
    ContactLandingComponent,
    ContactGeneralComponent,
    ContactConversationLogComponent,
    ContactDlgComponent,
    LocalCustodiansLinksComponent,
    EsgPaLandingComponent,
    EsgPaGeneralComponent,
    EsgPaAddEditComponent,
    EsgPaDialogComponent,
    EsgPaContactsComponent,
    InitiativeLandingComponent,
    InitiativeDetailComponent,
    ToggleSwitch,
    OptionsPickerComponent,
    ExpandListCheckboxComponent,
    InitiativeDocumentsComponent,
    ResolutionAddEditClassificationComponent,
    ResolutionClassificationDialogComponent,
    ResolutionClassificationLandingComponent,
    ResolutionClassificationGeneralComponent,
    ResolutionCustomFieldsComponent,
    ResolutionsClassificationComponent,
    ExclusionRuleComponent,
    ExclusionRuleDialogComponent,
    VotingItemsListComponent,
    VotingItemCreateEditDlgComponent,
    EsgArticlesOpinionsComponent,
    BeneficiaryLandingComponent,
    BeneficiaryDetailComponent,
    BenenficiarymaintenancedialogComponent,
    BeneficiaryAutocompleteComponent,
    ProjectAuditLogsComponent,
    InvestorIntelEsgEngagementComponent,
    InvestorIntelEngagementExampleComponent,
    InvestorEngagementExampleDialogComponent,
    InvestorIntelEsgInvestmentComponent,
    InvestorIntelEsgReportingComponent,
    InvestorInitiativeGridComponent,
    AlternativeNamesComponent,
    InvestorProxyComponent,
    InvestorIntelEsgComponent,
    InvestorValueSelectGroupComponent,
    InvestorVotingProcessCommentsComponent,
    InvestorIntelEngagementComponent,
    DatePickerComponent,
    InvestorLogisticalIntelligenceComponent,
    MatchInvestorDialogComponent,
    EditInvestorDialogComponent,
    ProjectInvestorUploadDialogComponent,
    GridFilterComponent,
    FilterModalComponent,
    ConfirmDialogComponent,
    ProjectFinancialEditDialogComponent,
    FollowUpCallsComponent,
    AddEditInvestorDialogComponent,
    DialogsComponent,
    UpcomingFollowUpComponent,
    CurrentActivitiesComponent,
    LoadFileDialogComponent,
    ProjectActivityEditDialogComponent,
    AuditLogsComponent,
    AuditLogsSegmentComponent,
    DashboardAuditLogsComponent,
    VotingLogsUpdateSegmentComponent,
    VotingLogsDeleteSegmentComponent,
    VotingLogsInsertSegmentComponent,
    InvestorIntelEsgInitiativeComponent,
    AddInvestorInitiativeDialogComponent,
    NumberCurrencyComponent,
    InvestorAuditLogsComponent,
    InvestorIntelEsgInvestmentFundsComponent,
    ProxyAdvisorContactComponent,
    ProxyGeneralComponent,
    EsgReportsSectionComponent,
    InvestorIntelEsgSurveyComponent,
    SustainableInvestorResourcesFieldsComponent,
    InvestorInitiativeFieldsComponent,
    EsgReportsComponent,
    EsgFocusDisclosureComponent,
    ProxyAdvisorLandingComponent,
    InvestorListComponent,
    InvestorLandingComponent,
    InvestorGeneralComponent,
    InvestorContactComponent,
    ProjectListComponent,
    ProjectLandingComponent,
    ProjectFinancialComponent,
    ProjectActivityComponent,
    StatesComponent,
    AssignProjectsToClientsComponent,
    InvestorBulkEditComponent,
    TypeAheadSearchComponent,
    NotificationsComponent,
    InvestorCallManagementContactListComponent,
    InvestorCallManagementAddCommentComponent,
    InvestorCallManagementCommentTableComponent,
    CommentEditDialogComponent,
    InvestorCallManagementVoteRecordsComponent,
    SafeStylePipe,
    DashboardActionsComponent,
    ProjectCustodialReconciliationComponent,
    ProjectCustodianReconciliationDialogComponent,
    DashboardProjectActivityComponent,
    BulkEditVotesDialogComponent,
    InvestorVotingProcessComponent,
    ProjectRecommendationComponent,
    ProjectRecommendationDlgComponent,
    ProjectInvestorAUDataComponent,
    ProjectInvestorControllerComponent,
    DashboardAULandingComponent,
    DashboardListAUComponent,
    DashboardDetailListAUComponent,
    DashboardAUFeedbackComponent,
    DashboardAUInvestorcallmanagementComponent,
    DashboardAUSummaryComponent,
    DashboardAuCampaignSummaryTableComponent,
    DashboardAuCustodialReconciliationTableComponent,
    DashboardCampaignTableComponent,
    CallLogTableComponent,
    EsgSummaryTableComponent,
    GenericAuditLogsDeleteSegmentComponent,
    GenericAuditLogsInsertSegmentComponent,
    GenericAuditLogsUpdateSegmentComponent,
    InvValueCountryEditDialogComponent,
    AddInitiativeDialogComponent,
    ArticlesOpinionsDialogComponent,
    ProxyAdvisorDialogComponent,
    AddEditCustomFieldComponent,
    RptProjectListComponent,
    RptListComponent,
    KeyEventsComponent,
    AssignProjectsToTeamMembersComponent,
    BeneficiaryLogsSegmentComponent,
    DashboardCustodianTableComponent,
    CustodialListComponent,
    DashboardPositionTrackingComponent,
    VotingReconciliationComponent,
    DeleteButtonComponent,
    DashboardMailingComponent,
    DashboardAddMailingDocumentComponent,
    DashboardOrderReceiveDocumentComponent,
    DashboardNoboHrTableComponent,
    DashboardMailingEventHistoryComponent,
    DashboardMailingRecipientComponent,
    DashboardVotingHistoryDialogComponent,
    OciExportSetUpDialogComponent,
    DashboardAddEditMailingDocRecipientComponent,
    DashboarMailingRecipientDeliveryLabelComponent,
    AddressTextareaModule,
    PageHeaderComponent,
    VoteTableComponent,
    DashboardRecipientEventHistoryComponent,
    RecipientEventHistoryEditDialogComponent,
    DropdownSelectComponent,
    IndividualDeliveryTicketComponent,
    IndividualTicketFormComponent,
    ExportLiveVotesDialogComponent,
    CustodianReportDialogComponent,
    DashboardVotingSummaryComponent,
    AutocompleteComponent,
    NegativeNumbersDirective,
    NoItemsGridModule,
    DashboardVotingIntentionsComponent,
    InvestorVotingHistoryComponent,
    AddCustodianVotesDialogComponent,
    InvestorVotingSummaryTableComponent,
    DashboardVotingIntentionsTableComponent,
    DashboardInvestorVotingComponent,
    InvestorVotingTableComponent,
    CustodianVotesImportDialogComponent,
    CustodianVotesImportResultDlgComponent,
    AlignCellBasedOnProjectTemplateDirective,
    InvestorVotingItemDetailsComponent,
    OvervoteConfirmDialogComponent
  ],
  entryComponents: [
    EventHistoryEditDialogComponent,
    RecipientEventHistoryEditDialogComponent,
    AddCustodianVotesDialogComponent,
    IndividualDeliveryTicketComponent,
    ExportLiveVotesDialogComponent,
    CustodianReportDialogComponent,
    CustodianVotesImportDialogComponent,
    CustodianVotesImportResultDlgComponent,
    InvestorVotingItemDetailsComponent,
    AddInvestorCustodianDlgComponent,
    OvervoteConfirmDialogComponent
  ],
  providers: [KeyValuePipe]
})

export class ComponentsModule {
}
