import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectMailingDocumentCopyDetailedDTO } from '@app/dto/ProjectMailingDocumentCopyDetailedDTO';
import { AlertService } from '@app/services/alert.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-event-history-edit-dialog',
  templateUrl: './event-history-edit-dialog.component.html',
  styleUrls: ['./event-history-edit-dialog.component.css']
})
export class EventHistoryEditDialogComponent implements OnInit {
  model = new ProjectMailingDocumentCopyDetailedDTO();
  mailingDocumentCopyId: number;
  actionTypes = ['Order', 'Receive'];
  constructor(
    private dashboardService: DashboardService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EventHistoryEditDialogComponent>,
  ) {
    this.mailingDocumentCopyId = data.mailingDocumentCopyId;
  }

  ngOnInit() {
    this.dashboardService.getMailingEvent(this.mailingDocumentCopyId).subscribe(data => {
      this.model = data;
    }, e => this.alertService.alert(e));
  }

  onCloseBtnClick(): void {
    this.dialogRef.close();
  }

  onSave() {
    this.dashboardService.updateMailingEvent(this.model).subscribe(data => {
      this.dialogRef.close(data);
    }, e => this.alertService.alert(e));

  }
}
