import { Injectable } from '@angular/core';
import { NameValueModel } from '@app/models/basic-structures';
import { BehaviorSubject, observable, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private readonly countriesWithStates = [
    { iso3: 'USA', iso2: 'US', name: 'United States'},
    { iso3: 'AUS', iso2: 'AU', name: 'Australia'},
    {iso3: 'CAN' , iso2: 'CA', name: 'Canada'}
  ]

  private coutrySource$$ = new BehaviorSubject<NameValueModel[]>([]);

  public countryStates$ = this.coutrySource$$.asObservable();


  constructor(private httpClient: HttpClient){
  }

  public selectedCountry(countryValue: string, byIso: boolean = false): void {
    const existing = this.countriesWithStates.filter(v => byIso ? v.iso2 === countryValue : v.name === countryValue)[0];
    let states: any;
    if (existing) {
      const httpOptions = {
        headers: new HttpHeaders(
          {
            'Access-Control-Allow-Origin': '*'
          }
        )
      };
      const endpoint = byIso ? 'lookups/states/iso2/' : 'lookups/states/';
      const apiURL: string = environment.serverUrl + endpoint + countryValue;
      this.httpClient.get(apiURL, httpOptions).subscribe(
      data => {
        states = data;
        this.coutrySource$$.next(states);
      },
      err => {
        console.error(err);
      }
    );
    } else {
      this.coutrySource$$.next([]);
    }
  }

  public getStates(countryValue: string, byIso: boolean = false): Observable<any> {
    const existing = this.countriesWithStates.filter(v => byIso ? v.iso2 === countryValue : v.name === countryValue)[0];
    let states: any;
    if (existing) {
      const httpOptions = {
        headers: new HttpHeaders(
          {
            'Access-Control-Allow-Origin': '*'
          }
        )
      };
      const endpoint = byIso ? 'lookups/states/iso2/' : 'lookups/states/';
      const apiURL: string = environment.serverUrl + endpoint + countryValue;
      this.httpClient.get(apiURL, httpOptions).subscribe(
      data => {
        states = data;
        return this.coutrySource$$.next(states);
      },
      err => {
        console.error(err);
      }
    );
    } else {
      return new Observable<any>();
    }
  }
}
