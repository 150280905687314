export class OptionsPickerModel<T> {
  header = '';
  titleNotSelected = 'Available options:';
  titleNotSelectedViewInDisableMode = false;
  titleSelected = '';
  noRecordsText = '';
  noRecordsLink = '';
  availableItemsText = 'Available Items';
  selectAllChosenItemsText = 'Select All Chosen Items';
  allRecords: T[] = [];
  selectedRecords: T[] = [];
  search = false;
  searchPlaceholder = 'Search by...';
  categoryCheck = false;

  constructor(props?) {
    Object.assign(this, props);
  }
}

export class ValuesLookup {
  id: number;
  name: string;
  checked?: boolean;

  constructor(props?) {
    Object.assign(this, props);

  }
}

export class ManualBrokerSearchLookup extends ValuesLookup {
  brokerId: number;
  brokerName: string;
  isGlobalBroker: boolean;
  isManualBroker: boolean;
  category: string;
  checked: false;

  constructor(props?) {
    super(props);

    const id = `${props.isGlobalBroker ? 'global' : 'local'}${props.brokerId}`;

    Object.assign(this, {
      ...props,
      id: id,
      name: props.brokerName,
      category: this.isManualBroker ? 'Manual' : 'Others'
    });
  }
}
