import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationTriggerService } from '@app/services/admin/navigation-trigger.service';
import { ResponsibilityUserObject } from '@app/dto/base/ResponsibilityUserObject';
import { ExportService } from '@app/service/export.service';
import { GlobalCustodiansDialogAddComponent } from '@app/admin/global-custodians/global-custodians-dialog-add/global-custodians-dialog-add.component';
import { SearchGridService } from '@app/service/search-grid/search-grid.service';
import { HttpClient } from '@angular/common/http';
import { RoleGuardService } from '@app/auth/role-guard.service';

@Component({
  selector: 'app-global-custodians-list',
  templateUrl: './global-custodians-list.component.html',
  styleUrls: ['global-custodians-list.component.scss'],
})

export class GlobalCustodiansListComponent implements OnInit {
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['name', 'country'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('globalCustodiansPaginator') paginator: MatPaginator;
  customPageSize: number = 20;
  pageIndex: number = 0;
  length: number;
  hasPermission:boolean = false;

  searchService: SearchGridService;

  constructor(public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private exportService: ExportService,
    private navigationTriggerService: NavigationTriggerService,
    private httpClient: HttpClient,
    private roleGuardService: RoleGuardService,) {

  }

  ngOnInit() {
    this.hasPermission = this.roleGuardService.hasAccess('ADMIN', 'EDIT');
    this.navigationTriggerService.setSubMenu(false);
    this.searchService = new SearchGridService(this.paginator, this.dataSource, this.httpClient, 'globalcustodians/global-custodians-list');
  }

  ngOnDestroy(): void {
    if (this.searchService) {
      this.searchService.unSubscribe();
    }
  }

  onEditLinkClick(element) {
    this.router.navigate(['../' + element.globalCustodianId + '/general'], { relativeTo: this.route });
  }

  downloadExport() {
    this.searchService.getAllItems().subscribe(
      data => {
        var result = this.exportService.flattenResponsibilityUserDataSource(
          data.items as ResponsibilityUserObject[],
          'Managed By');

        this.exportService.export(result, 'Global_CustodianGlobal_Custodian');
      },
      err => console.error(err)
    );
  }

  onAddBtnClick() {
    const dialogRef = this.dialog.open(GlobalCustodiansDialogAddComponent, {
      data: {},
      width: '1100px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(x => {
      this.searchService.reloadItems();
    });
  }
}
