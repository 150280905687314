import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProjectTemplate } from '@app/dto/ProjectTemplate';
import { Lookup } from '@app/dto/Lookup';
import { Observable } from 'rxjs';
import { ProjectTemplateLabels } from '@app/dto/ProjectTemplateLabels';
import { ProjectTemplateTypes } from '@app/enums/ProjectTemplateTypesEnum';
export const FOR = 'FOR';
export const AGAINST = 'AGAINST';
export const ABSTAIN = 'ABSTAIN';
export const UNDECIDED = 'UNDECIDED';
export const UNDISCLOSED = 'UNDISCLOSED';
const NOT_VOTING = 'NOT VOTING';
const NOT_APPLICABLE = 'NOT APPLICABLE';
const CANCEL = 'CANCEL';
const ACCEPT = 'ACCEPT';
const DECLINE = 'DECLINE';
const REJECT = 'REJECT';
export const IN_LINEwPADV = 'IN LINEwPADV';
const FOR_PXY = 'FOR (PXY)';
const AGAINST_PXY = 'AGAINST (PXY)';
const ABSTAIN_PXY = 'ABSTAIN (PXY)';
const ACCEPT_PXY = 'ACCEPT (PXY)';
const DECLINE_PXY = 'DECLINE (PXY)';
export const ONE_YEAR = '1 YEAR';
export const TWO_YEARS = '2 YEARS';
export const THREE_YEARS = '3 YEARS';

@Injectable()
export class ProjectTemplateService {
    public readonly SHAREHOLDER_MEETING_TEMPLATE_NAME = 'Shareholder Meeting';
    public readonly CORPORATE_ACTION_TEMPLATE_NAME = 'Corporate Action';

    public readonly votedtypecolors =
    [
        {type: 'FOR', color: '#159347'},
        {type: 'AGAINST', color: '#ea5657'},
        {type: 'ABSTAIN', color: '#999999'},
        {type: 'UNDECIDED', color: '#EC7A08'},
        {type: 'UNDISCLOSED', color: '#00A5D3'},
        {type: 'ACCEPT', color: '#159347'},
        {type: 'DECLINE', color: '#EA5657'},
        {type: 'NOT VOTING', color: '#1E1E1E'},
        {type: 'NOT APPLICABLE', color: '#1E1E1E'},
        {type: 'IN LINEwPADV', color: '#800080'},
        {type: 'FOR (PXY)', color: '#159347'},
        {type: 'AGAINST (PXY)', color: '#EA5657'},
        {type: 'ABSTAIN (PXY)', color: '#999999'},
        {type: 'ACCEPT (PXY)', color: '#159347'},
        {type: 'DECLINE (PXY)', color: '#EA5657'},
        {type: '1 YEAR', color: '#566FEA'},
        {type: '2 YEARS', color: '#EA56A7'},
        {type: '3 YEARS', color: '#EAC556'},
        {type: 'DEFAULT', color: '#000000'},
        {type: 'SPLIT', color: '#68109E'},
      ];

    constructor(private httpClient: HttpClient) { }

    getProjectItemsTemplateLabels(template: string): ProjectTemplateLabels {
        switch (template) {
            case this.CORPORATE_ACTION_TEMPLATE_NAME:
                {
                    return {
                        templateProp1Label: 'Company',
                        templateProp2Label: 'Bid'
                    };
                }
            case this.SHAREHOLDER_MEETING_TEMPLATE_NAME:
                {
                    return {
                        templateProp1Label: 'Item No',
                        templateProp2Label: 'Resolution'
                    };
                }
        }
    }

    getIntentionTypes(isSplitVote: boolean, projectTemplate: string): Lookup[] {
        const intentions: Lookup[] = [];
        const intentionArray: Array<string> = [FOR, AGAINST, ABSTAIN];
        if (!isSplitVote) {
            intentionArray.push(UNDECIDED, UNDISCLOSED, NOT_VOTING);
            if (projectTemplate !== this.SHAREHOLDER_MEETING_TEMPLATE_NAME) {
                intentionArray.push(CANCEL, ACCEPT, REJECT);
            }
        }
        let lookup: Lookup;

        for (let i = 0; i < intentionArray.length; i++) {
            lookup = new Lookup();
            lookup.lookUpId = i;
            lookup.fieldType = 'INTENTION';
            if (isSplitVote) {
                lookup.fieldLabel = intentionArray[i] + ' – Split Decision';
            } else {
                lookup.fieldLabel = intentionArray[i];
            }
            lookup.fieldValue = intentionArray[i];
            intentions.push(lookup);
        }
        return intentions;
    }

    getIntentionTypeWithoutSplitVote(projectTemplate: string, additional = [], withColors = false) {
        const votedtypeArray: Array<string> = [];
        if (projectTemplate === this.SHAREHOLDER_MEETING_TEMPLATE_NAME) {
            votedtypeArray.push(FOR, AGAINST, ABSTAIN, UNDISCLOSED, UNDECIDED, NOT_VOTING, IN_LINEwPADV);
        } else {
            votedtypeArray.push(ACCEPT, DECLINE, UNDECIDED, UNDISCLOSED, IN_LINEwPADV);
        }
        votedtypeArray.push(...additional);
        const action = withColors ? 'getTypesWithColors' : 'getTypes';

        return this[action](votedtypeArray, 'VOTED');
    }

    getVotedTypes(projectTemplate: string, additional = []): Lookup[] {
        const votedtypeArray: Array<string> = [];
        if (projectTemplate === this.SHAREHOLDER_MEETING_TEMPLATE_NAME) {
            votedtypeArray.push(FOR_PXY, FOR, AGAINST_PXY, AGAINST, ABSTAIN_PXY, ABSTAIN, UNDISCLOSED, UNDECIDED, NOT_VOTING);
        } else {
            votedtypeArray.push(ACCEPT_PXY, ACCEPT, DECLINE_PXY, DECLINE, UNDECIDED, UNDISCLOSED);
        }
        votedtypeArray.push(...additional);

        return this.getTypes(votedtypeArray, 'VOTED');
    }

    getFrequencyVoteTypes(forSelect = false, type = 'VOTED', additional = [], reverseOrder = false): Lookup[] | string[] {
        const votedtypeArray: Array<string> = [ONE_YEAR, TWO_YEARS, THREE_YEARS];
        if (reverseOrder) {
            votedtypeArray.reverse();
        }
        votedtypeArray.push(...additional);
        if (forSelect) {
            votedtypeArray.push(ABSTAIN);
            return this.getTypesWithColors(votedtypeArray, type);
        }
        return votedtypeArray;
    }

    hasFrequency(data, param = 'votingItemType'): boolean {
        return !!data.find(item => /^frequency/gmi.exec(item[param]));
    }

    isFrequencyWithReverseOrder(data, param = 'votingItemType'): boolean {
        if (Array.isArray(data)) {
            return (data.find(item => !!/^frequency/gmi.exec(item[param])) || { [param]: '' })[param].match(/3\/2\/1/gm);
        }
        return !!data.match(/3\/2\/1/gm);
    }

    hasFrequencySimple(data): boolean {
        if (Array.isArray(data)) {
            return !!(data || []).find(item => !!/^frequency/gmi.exec(item));
        }

        return !!data.includes('Frequency');
    }

    isFrequencyWithReverseOrderSimple(data, isFrequency): boolean {
        if (isFrequency) {
            const includesFrequency = Array.isArray(data) ?
                data.find(vi => vi.includes('3/2/1')) :
                data.includes('3/2/1');

            return includesFrequency;
        }

        return false;
    }

    getCustodialReconciliationVotedTypes(projectTemplate: string, additional = []): Lookup[] {
        const votedtypeArray: Array<string> = [];
        if (projectTemplate === this.SHAREHOLDER_MEETING_TEMPLATE_NAME) {
            votedtypeArray.push(FOR, AGAINST, ABSTAIN);
        } else {
            votedtypeArray.push(ACCEPT, DECLINE, UNDECIDED, UNDISCLOSED);
        }
        votedtypeArray.push(...additional);

        return this.getTypesWithColors(votedtypeArray, 'CUSTODIAL_RECONCILIATION');
    }

    getVotingIntentionsVotedTypes(projectTemplate: ProjectTemplateTypes, additional = []): Lookup[] {
        const votedtypeArray: Array<string> = [];
        if (projectTemplate == ProjectTemplateTypes.ShareHolderMeeting) {
            votedtypeArray.push(FOR, AGAINST, ABSTAIN, UNDISCLOSED, UNDECIDED, NOT_VOTING, IN_LINEwPADV);
        } else {
            votedtypeArray.push(ACCEPT, DECLINE, UNDISCLOSED, UNDECIDED, NOT_VOTING, IN_LINEwPADV);
        }
        votedtypeArray.push(...additional);

        return this.getTypesWithColors(votedtypeArray, 'CUSTODIAL_RECONCILIATION');
    }

    getInvestorVotingVotedTypes(projectTemplate: any, additional = []): Lookup[] {
        const votedtypeArray: Array<string> = [];
        if (projectTemplate == ProjectTemplateTypes.ShareHolderMeeting || projectTemplate === this.SHAREHOLDER_MEETING_TEMPLATE_NAME) {
            votedtypeArray.push(FOR, AGAINST, ABSTAIN, UNDISCLOSED, UNDECIDED, NOT_VOTING);
        } else {
            votedtypeArray.push(ACCEPT, DECLINE, UNDISCLOSED, UNDECIDED, NOT_VOTING);
        }
        votedtypeArray.push(...additional);

        return this.getTypesWithColors(votedtypeArray, 'CUSTODIAL_RECONCILIATION');
    }

    getAdvisorVoteTypes(projectTemplate: string, additional = []): Lookup[] {
        const votedtypeArray: Array<string> = [];
        if (projectTemplate === this.SHAREHOLDER_MEETING_TEMPLATE_NAME) {
            votedtypeArray.push(FOR, AGAINST, ABSTAIN, NOT_VOTING, NOT_APPLICABLE);
        } else {
            votedtypeArray.push(ACCEPT, DECLINE, NOT_APPLICABLE);
        }
        votedtypeArray.push(...additional);
        return this.getTypesWithColors(votedtypeArray, 'ADVISOR_RECOMMENDATION');
    }

    getProjectTemplate(projectId: number): Observable<ProjectTemplate> {
        const apiURL: string = environment.serverUrl + 'projects/GetProjectTemplate/' + projectId;

        return this.httpClient.get<ProjectTemplate>(apiURL);
    }

    getDirectorExceptionVoteTypes(): Lookup[] {
        const votedtypeArray: Array<string> = [];
        votedtypeArray.push(FOR, AGAINST, ABSTAIN);
        return this.getTypesWithColors(votedtypeArray, 'DIRECTOR_EXCEPTION_BOD_RECOMMENDATION');
    }

    private getTypes(values: string[], type: string): Lookup[] {
        const types: Lookup[] = [];

        let lookup: Lookup;
        for (let i = 0; i < values.length; i++) {
            lookup = new Lookup();
            lookup.lookUpId = i;
            lookup.fieldType = type;
            lookup.fieldLabel = values[i];
            lookup.fieldValue = values[i];
            types.push(lookup);
        }
        return types;
    }

    private getTypesWithColors(values: string[], type: string): Lookup[] {
        return this.getTypes(values, type).map(t => {
            const find = (this.votedtypecolors.find(tc => tc.type === t.fieldValue)) || { value: '', color: '' };
            t.color = find.color;
            return t;
        });
    }

    hideVotedTypes(projectTemplate: string, intentionType: string, votedType: string): boolean {
        if (intentionType === IN_LINEwPADV) {
            switch (projectTemplate) {
                case this.SHAREHOLDER_MEETING_TEMPLATE_NAME:
              return  [FOR, AGAINST, ABSTAIN].indexOf(votedType) > -1;
                case this.CORPORATE_ACTION_TEMPLATE_NAME:
                    return [ACCEPT, DECLINE].indexOf(votedType) > -1;
            }
        } else if (intentionType !== IN_LINEwPADV) {
            return votedType.includes('PXY');
        }
    }
}
