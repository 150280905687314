export class InvestorValueCountryConfiguration {
    investorId: number;
    header: string;
    inputPlaceholder: string;
    gridHeader: string;
    addButtonCaption: string;
    apiEndpointPath: string;
    countryEditable: boolean;
    countryRequired: boolean;
    displayExtensionFields: boolean;
    displayParticipationPolicy: boolean;    
    isEditable: boolean;    
    isLinked: boolean = false;
    linkedUrl: string;   
    hideAddForm = false; 
    showAddDialogBtn: boolean;
    custodianType: 'local' | 'global';
}