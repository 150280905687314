import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { AlertService } from '@app/services/alert.service';
import { VotingItemDto } from '@app/dto/VotingItemDto';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import { VotingItemCreateEditData } from './voting-item-create-edit-dlg/VotingItemCreateEditData';
import { VotingItemCreateEditDlgComponent } from './voting-item-create-edit-dlg/voting-item-create-edit-dlg.component';
import { VotingItemsService } from '@app/services/voting-items.service';
import { stringify } from 'querystring';
import { CommonUtilityService } from '@app/utility/common-utility.service';
import { ProjectsService } from '@app/services/projects.service';
import { ProjectTemplateTypesAsString } from '@app/enums/ProjectTemplateTypesEnum';

@Component({
  selector: 'app-voting-items-list',
  templateUrl: './voting-items-list.component.html',
  styleUrls: ['./voting-items-list.component.scss']
})
export class VotingItemsListComponent implements OnInit {
  displayedColumns: string[] =
  ['templateProp1', 'templateProp2', 'boardOfDirectorsRecommendation', 'votingItemType', 'classification', 'actions'];

  @ViewChild('sortGrid') sortGrid: MatSort;

  dataSource: MatTableDataSource<VotingItemDto> = new MatTableDataSource<VotingItemDto>();
  canEditProject: boolean;

  projectId: number;

  templateProp1Label: string;
  templateProp2Label: string;
  projectTemplate: string;

  componetTitle: string;
  addButtonLabel: string;

  showGrid: boolean;
  noItemsText = 'Resolutions';

  constructor(
    public dialog: MatDialog,
    private votingItemsService: VotingItemsService,
    private dialogsService: DialogsService,
    private alertService: AlertService,
    private projectTemplateService: ProjectTemplateService,
    private route: ActivatedRoute,
    private roleGuardService: RoleGuardService,
    private commonUtility: CommonUtilityService,
    private projectService: ProjectsService) {
  }

  ngOnInit() {
    this.route.parent.params.subscribe(async (params) => {
      this.projectId = +params['id'];

      this.projectService.getProjectType(this.projectId).subscribe(data => {
        this.canEditProject = this.roleGuardService.hasAccessByProjectType('projects', 'edit', data.name);
      });

      this.getProjectTemplatePropLabels();
    });
  }

  onEdit(element: VotingItemDto) {
    const dialogData = new VotingItemCreateEditData();
    dialogData.action = 'Edit';
    dialogData.projectTemplate = this.projectTemplate;
    dialogData.projectId = this.projectId;
    dialogData.votingItemId = element.itemId;

    const dialogRef = this.dialog.open(VotingItemCreateEditDlgComponent,
      {
        width: '600px',
        data: dialogData
      });

    dialogRef.afterClosed().subscribe(result => {
      this.getVotingItems();
    });
  }

  addVotingItem() {
    const dialogData = new VotingItemCreateEditData();
    dialogData.action = 'Add';
    dialogData.projectTemplate = this.projectTemplate;
    dialogData.projectId = this.projectId;
    dialogData.nextItemNumber = this.findNextItemNumber(this.dataSource.data).toString();

    const dialogRef = this.dialog.open(VotingItemCreateEditDlgComponent,
      {
        width: '600px',
        data: dialogData
      });

    dialogRef.afterClosed().subscribe(result => {
      this.getVotingItems();
    });
  }

  onDelete(element: VotingItemDto) {
    this.dialogsService.confirm(
      null, null, { template: DeleteProjectTemplate({ title: this.componetTitle, message: element.templateProperty1 }) }
    );
    this.dialogsService.close$.subscribe(res => {
      if (res) {
        this.votingItemsService.deleteVotingItem(element.itemId, this.projectId)
          .subscribe(
            data => {
              if (data) {
                this.getVotingItems();
                this.alertService.sendSuccess('Voting item is deleted.');
              }
            },
            err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });
      }
    });
  }

  setTableRows(data) {
    const tableRows = data.reduce((accumulator, item, currentIndex) => {
      const row = {
        templateProperty1: item.templateProperty1,
        templateProperty2: item.templateProperty2,
        date: item.date,
        votingItemType: item.votingItemType,
        issClassification: item.issClassification,
        numberOfCompleteCustomFields: item.numberOfCompleteCustomFields,
        totalNumberOfCustomFields: item.totalNumberOfCustomFields,
        isChild: false,
        border: false,
        isDark: currentIndex % 2 !== 0,
        itemId: item.itemId,
        boardOfDirectorsRecommendation: item.boardOfDirectorsRecommendation
      };

      accumulator.push(row);

      item.parentVotingItemChildren.forEach((child, childItem, index) => {
        const record = {
          parentTemplateProperty1: item.templateProperty1,
          templateProperty2: `Voting for ${child.name}`,
          isChild: true,
          isDark: false,
          border: index !== 0,
          childIndex: child.id,
          boardOfDirectorsRecommendation: child.boardOfDirectorsRecommendation
        };

        accumulator.push(record);
      });

      return accumulator;
    }, []);

    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sortGrid;
    this.dataSource.data = tableRows;
  }


  private getVotingItems() {
    this.votingItemsService.getVotingItems(this.projectId)
      .subscribe(
        data => {
          this.setTableRows(data);
          this.showGrid = data.length > 0;
        },
        err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });
  }

  private getProjectTemplatePropLabels() {
    this.projectTemplateService.getProjectTemplate(this.projectId).subscribe(data => {
      this.getVotingItems();
      const labels = this.projectTemplateService.getProjectItemsTemplateLabels(data.template);
      this.projectTemplate = data.template;
      this.templateProp1Label = labels.templateProp1Label;
      this.templateProp2Label = labels.templateProp2Label;

      if (this.projectTemplateService.SHAREHOLDER_MEETING_TEMPLATE_NAME === this.projectTemplate) {
        this.componetTitle = 'Resolutions';
        this.addButtonLabel = 'Add Resolution';
        this.templateProp2Label += ' Name';
        this.displayedColumns.splice(4, 0, 'attributes');
      } else {
        this.componetTitle = 'Bids';
        this.addButtonLabel = 'Add Bid';
        this.displayedColumns.splice(3, 1, 'date'); // remove classification and add bid date
      }
    });
  }

  getColor(completed: number, total: number) {
    if (completed === 0) {
      return CustomItemsColors.NotCompleted;
    } else if (total === completed) {
      return CustomItemsColors.Completed;
    } else {
      return CustomItemsColors.PartiallyCompleted;
    }
  }

  public getRecommendationColor(recommendation: string) {
    const recom = recommendation || '';
    const item = this.projectTemplateService.votedtypecolors.find(x => x.type.toUpperCase() === recom.toUpperCase());
    if (item) {
      return item.color;
    }
  }

  indexProperties(index) {
    const code = this.commonUtility.indexToLetter(index, false);
    return code;
  }

  private findNextItemNumber(data): number | string {
    if (this.projectTemplate.toLowerCase() !== ProjectTemplateTypesAsString.ShareHolderMeeting) {
      return '';
    }

    const sortedItemNumbers = (data || []).map((votingItem) => {
      const match = (votingItem.templateProperty1 || '').match(/\d+/);

      return match ? parseInt(match[0], 10) : 1;
    }).sort((a, b) => b - a);

    return sortedItemNumbers.length ? sortedItemNumbers[0] + 1 : 1;
  }
}

export enum CustomItemsColors {
  Completed = '#159347',
  NotCompleted = '#EA5657',
  PartiallyCompleted = '#EC7A08'
}
