import { Shareholdings } from './ProjectCustodialListDto';

export class ProjectMailingDocRecipientDetailedDTO {
  mailingDocumentRecipientId: number;
  projectId: number;
  recipientName: string;
  shareCapitals: Shareholdings[];
  setsRequired: number;
  setsSent: number;
  mailingDetails: string;
  custodianId?: number;
  isGlobalCustodian: boolean;
  custodianCodeId?: number | null;
  globalCustodianDtcCode: string | null;
  custodianIdentifierId?: null | number = null;
  custodianCountry?: null | string = null;
  projectName?: string;
}
