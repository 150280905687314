export class Client {
    clientId: number;
    crmProjectCode: string;
    coverage:string;
    company: string;
    sector: string;
    country: string;
    city: string;
    address: string;
    addressTwo: string;
    postalZipCode: string;
    stateId: number;
    stateName: string;
    phoneNo: string;
    website: string;
    comment: string;
    managedBy: string;
    content:any[];
    fileName:string;
    contentType: string;
    contentEncoded:string;
    description: string;
    ticker : string;
    cusip : string;
    outstandingShares: number ;
    fiscalYearEndMonth : string;
    fiscalYearEndDay : number;
    notes : string;
    clientEngagementDetails: ClientEngagementDetailsDTO = new ClientEngagementDetailsDTO();
    industry: string;
    subindustry: string;
    billingCountry: string;
    billingCity: string;
    billingAddress: string;
    billingAddressTwo: string;
    billingPostalZipCode: string;
    billingStateId: number;
    billingStateName: string;
    hasDifferentBillingAddress: boolean;
    generalStateId: number;
    generalStateName: string;
    clientTypeId: number;
}

export class ClientEngagementDetailsDTO {
    id: number;
    clientId: number;
    jobType: string[];
    services: string[];
    team: string;
    retainer: string;
}
