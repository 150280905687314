import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RptListComponent} from './report/au/rpt-list.component';
import {DashboardAUInvestorcallmanagementComponent} from './dashboard/dashboardau/detail/dashboard-au-investorcallmanagement.component';
import {InvestorContactComponent} from './investor/investordetail/investor-contact.component';
import {RptProjectListComponent} from './report/au/rpt-project-list.component';
import {DashboardAULandingComponent} from './dashboard/dashboardau/detail/dashboard-au-landing.component';
import {DashboardAUFeedbackComponent} from './dashboard/dashboardau/detail/dashboard-au-feedback.component';

import {DashboardAUSummaryComponent} from './dashboard/dashboardau/detail/dashboard-au-summary.component';
import {DashboardListAUComponent} from './dashboard/dashboardau/dashboard-list-au.component';
import {InvestorGeneralComponent} from './investor/investordetail/investor-general.component';
import {InvestorLandingComponent} from './investor/investordetail/investor-landing.component';
import {DocumentsComponent} from './components/documents/documents.component';
import {ContactConversationLogComponent} from './contact/contactdetail/contact-conversationlog.component';
import {InvestorListComponent} from './investor/investor-list.component';
import {DashboardDetailListAUComponent} from './dashboard/dashboardau/dashboard-detail-list-au.component';
import {ProjectsResolver} from '@app/resolvers/projects.resolver';
import {InvestorsResolver} from '@app/resolvers/investors.resolver';
import {InvestorVotingProcessComponent} from './investor/investordetail/investor-voting-process/investor-voting-process.component';
import {InvestorProxyComponent} from './investor/investordetail/investor-proxy/investor-proxy.component';
import {InvestorIntelEsgComponent} from './investor/investordetail/investor-intel-esg/investor-intel-esg.component';
import {InvestorIntelEngagementComponent} from '@app/investor/investordetail/investor-intel-engagement/investor-intel-engagement.component';
import {InvestorLogisticalIntelligenceComponent} from '@app/investor/investordetail/investor-logistical-intelligence/investor-logistical-intelligence.component';
import {DirtyCheckGuard} from './common/can-deactivate.guard';
import {RoleGuardService} from '@app/auth/role-guard.service';
import {DashboardAuditLogsComponent} from '@app/dashboard/dashboardau/detail/dashboard-audit-logs/dashboard-audit-logs.component';
import {InvestorGuardService} from '@app/auth/investor-guard.service';
import {InvestorIntelEsgEngagementComponent} from '@app/investor/investordetail/investor-intel-esg-engagement/investor-intel-esg-engagement.component';
import {InvestorIntelEsgInvestmentComponent} from '@app/investor/investordetail/investor-intel-esg-investment/investor-intel-esg-investment.component';
import {InvestorIntelEsgReportingComponent} from '@app/investor/investordetail/investor-intel-esg-reporting/investor-intel-esg-reporting.component';
import {InvestorAuditLogsComponent} from './investor/investor-audit-logs/investor-audit-logs.component';
import {InvestorBulkEditComponent} from './investor/investor-bulk-edit/investor-bulk-edit.component';
import { DashboardVotingIntentionsComponent } from './dashboard/dashboardau/detail/dashboard-voting-intentions.component';
import { InvestorVotingHistoryComponent } from '@app/investor/investordetail/investor-history-summary/investor-voting-history.component';
import { DashboardVotingHistoryComponent } from '@app/dashboard/dashboardau/detail/dashboard-voting-history/dashboard-voting-history.component';
import { DashboardInvestorVotingComponent } from '@app/dashboard/dashboardau/detail/dashboard-investor-voting/dashboard-investor-voting.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'clients',
    data: {
      breadcrumbs: 'Clients',
      section: 'clients'
    },
    canActivate: [RoleGuardService],
    canActivateChild: [RoleGuardService],
    loadChildren: './client/client.module#ClientModule'
  },
  {
    path: 'admin',
    data: {
      breadcrumbs: 'Admin',
      section: 'admin'
    },
    loadChildren: './admin/admin.module#AdminModule',
  },
  {
    path: 'investors',
    data: {
      breadcrumbs: 'Investors',
      section: 'investors'
    },
    canActivate: [RoleGuardService],
    children: [
      {
        path: '',
        component: InvestorListComponent
      },
      {
        path: 'bulk-edit',
        component: InvestorBulkEditComponent
      },
      {
        path: ':invid',
        component: InvestorLandingComponent,
        canActivate: [InvestorGuardService],
        data:
          {
            breadcrumbs: InvestorsResolver
          },
        children: [
          {
            path: 'general/:id', outlet: 'investoroutlet', component: InvestorGeneralComponent,
            canDeactivate: [DirtyCheckGuard],
            data: {
              breadcrumbs: 'General'
            }
          },
          {
            path: 'proxy/:id', outlet: 'investoroutlet', component: InvestorProxyComponent,
            canDeactivate: [DirtyCheckGuard],
            data: {
              breadcrumbs: 'Proxy'
            }
          },
          {
            path: 'votingprocess/:id', outlet: 'investoroutlet', component: InvestorVotingProcessComponent,
            canDeactivate: [DirtyCheckGuard],
            data: {
              breadcrumbs: 'Voting Process'
            }
          },
          {
            path: 'engagement/:id', outlet: 'investoroutlet', component: InvestorIntelEngagementComponent,
            canDeactivate: [DirtyCheckGuard],
            data: {
              breadcrumbs: 'Engagement'
            }
          },
          {
            path: 'esg-resources/:id', outlet: 'investoroutlet', component: InvestorIntelEsgComponent,
            canDeactivate: [DirtyCheckGuard],
            data: {
              breadcrumbs: 'ESG Resources'
            }
          },
          {
            path: 'esg-engagement/:id', outlet: 'investoroutlet', component: InvestorIntelEsgEngagementComponent,
            canDeactivate: [DirtyCheckGuard],
            data: {
              breadcrumbs: 'ESG Engagement'
            }
          },
          {
            path: 'esg-investment/:id', outlet: 'investoroutlet', component: InvestorIntelEsgInvestmentComponent,
            canDeactivate: [DirtyCheckGuard],
            data: {
              breadcrumbs: 'ESG Investment'
            }
          },
          {
            path: 'esg-reporting/:id', outlet: 'investoroutlet', component: InvestorIntelEsgReportingComponent,
            canDeactivate: [DirtyCheckGuard],
            data: {
              breadcrumbs: 'ESG Reporting'
            }
          },
          {
            path: 'logistical-intelligence/:id', outlet: 'investoroutlet', component: InvestorLogisticalIntelligenceComponent,
            canDeactivate: [DirtyCheckGuard],
            data: {
              breadcrumbs: 'Logistical Intelligence'
            }
          },
          {
            path: 'contact/:id', outlet: 'investoroutlet', component: InvestorContactComponent,
            data: {
              breadcrumbs: 'Contact'
            }
          },
          {
            path: 'document/:id/:relatedEntityType', outlet: 'investoroutlet', component: DocumentsComponent,
            data: {
              breadcrumbs: 'Document'
            }
          },
          {
            path: 'auditLogs/:id', outlet: 'investoroutlet', component: InvestorAuditLogsComponent,
            data: {
              breadcrumbs: 'Audit Logs'
            }
          },
          {
            path: 'voting-history/:id', outlet: 'investoroutlet', component: InvestorVotingHistoryComponent,
            data: {
              breadcrumbs: 'Voting History'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'projects',
    data: {
      breadcrumbs: 'Projects',
      section: 'projects'
    },
    canActivate: [RoleGuardService],
    canActivateChild: [RoleGuardService],
    loadChildren: './project/project.module#ProjectModule'
  },
  {
    path: 'contacts',
    data: {
      breadcrumbs: 'Contacts',
      section: 'contacts'
    },
    canActivate: [RoleGuardService],
    canActivateChild: [RoleGuardService],
    loadChildren: './contact/contact.module#ContactModule'
  },
  {
    path: 'dashboard',
    data: {breadcrumbs: 'Dashboard'},
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: DashboardListAUComponent
          },
          {
            path: 'detail/:id',
            data: {
              breadcrumbs: ProjectsResolver
            },
            children: [
              {
                path: '',
                component: DashboardDetailListAUComponent,
              },
              {
                path: ':invid',
                component: DashboardAULandingComponent,
                data: {
                  breadcrumbs: InvestorsResolver
                },
                children: [
                  {
                    path: 'summary/:id/:invid/Vote',
                    outlet: 'dashboardauoutlet',
                    component: DashboardAUSummaryComponent
                  },
                  {
                    path: 'investorcallmanagement/:id/:invid',
                    outlet: 'dashboardauoutlet',
                    component: DashboardAUInvestorcallmanagementComponent,
                    canDeactivate: [DirtyCheckGuard]
                  },
                  {
                    path: 'feedback/:id/:invid',
                    outlet: 'dashboardauoutlet',
                    component: DashboardAUFeedbackComponent
                  },
                  {
                    path: 'auditlogs/:id/:invid',
                    outlet: 'dashboardauoutlet',
                    component: DashboardAuditLogsComponent
                  },
                  {
                    path: 'votingintentions/:id/:invid',
                    outlet: 'dashboardauoutlet',
                    component: DashboardVotingIntentionsComponent
                  },
                  {
                    path: 'voting-history/:id/:invid',
                    outlet: 'dashboardauoutlet',
                    component: DashboardVotingHistoryComponent
                  },
                  {
                    path: 'voting/:id/:invid',
                    outlet: 'dashboardauoutlet',
                    component: DashboardInvestorVotingComponent
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'reports',
    data: {
      section: 'reports'
    },
    canActivate: [RoleGuardService],
    canActivateChild: [RoleGuardService],
    children: [
      {
        path: '',
        component: RptListComponent
      },
      {
        path: ':reportid',
        component: RptProjectListComponent
      }
    ]
  },
  {
    path: 'powerbi',
    data: {
      breadcrumbs: 'PowerBI',
      section: 'powerbi'
    },
    loadChildren: '@app/powerbi/powerbi.module#PowerbiModule',
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})], // add  `enableTracing: true` if you want to debug the links
  exports: [RouterModule]
})

export class AppRoutingModule {
}
