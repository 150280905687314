import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MatSort, MatTableDataSource, MatPaginator, PageEvent } from '@angular/material';
import { ExportService } from '../service/export.service';
import { Observable } from 'rxjs';
import { SearchGridService } from '@app/service/search-grid/search-grid.service';
import { RoleGuardService } from '@app/auth/role-guard.service';
@Component({
    selector: 'app-contact-list',
    templateUrl: './contact-list.component.html',
    styleUrls: ['contact-list.component.scss']
})
export class ContactListComponent implements OnInit {
    displayedColumns: string[] = ['name',
        'areaOfResponsibility', 'jobTitle', 'contactType', 'geographicRegion', 'country', 'directPhoneNo',
        'emailPersonal'];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource = new MatTableDataSource();
    searchService: SearchGridService;

    canAddContact = false;
    length: number = 0;
    pageSize: number = 50;
    noItemsText: string = 'Contacts';
    showGrid: boolean = true;

    constructor(private httpClient: HttpClient, private exportService: ExportService, private roleGuardService: RoleGuardService) {
    }

    ngOnInit() {
        this.searchService = new SearchGridService(this.paginator, this.dataSource, this.httpClient, 'contacts/contact-list');
        this.searchService.anyResults.subscribe(hasResults => {
            this.showGrid = hasResults;
        });

        this.canAddContact = this.roleGuardService.hasAccess('contacts', 'CREATE');
    }

    ngOnDestroy(): void {
        this.searchService.unSubscribe();
    }

    downloadExport() {
        this.searchService.getAllItems().subscribe(
            data => {
                this.exportService.export(data.items, 'Contacts');
            },
            err => console.error(err)
        );
    }
}
