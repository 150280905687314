import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AlertService } from '@app/services/alert.service';
import { ProjectMailingIndividualDeliveryTicket } from '@app/dto/ProjectMailingDeliveryLabelDTO';
import { DashboardMailingService } from '@app/services/dashboard';
import { MailingStatuses } from '@app/enums/MailingStatusesEnum';

@Component({
  selector: 'app-recipient-event-history-edit',
  templateUrl: './rec-evnt-hstr-edit.component.html',
  styleUrls: ['./rec-evnt-hstr-edit.component.scss']
})
export class RecipientEventHistoryEditDialogComponent implements OnInit {
  model: ProjectMailingIndividualDeliveryTicket = new ProjectMailingIndividualDeliveryTicket();
  eventId: number;
  projectId: number;
  disabled: boolean;
  statusId = MailingStatuses.Preparing;
  mailingStatuses = MailingStatuses;

  constructor(
    private dashboardMailingService: DashboardMailingService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RecipientEventHistoryEditDialogComponent>,
  ) {
    this.eventId = data.mailingDocumentRecipientSendId;
    this.projectId = data.projectId;
    this.statusId = data.statusId;
    this.disabled = true;
  }

  ngOnInit() {
    this.dashboardMailingService.getMailingRecepientEventDetails(this.eventId, this.projectId).subscribe(data => {
      this.model = new ProjectMailingIndividualDeliveryTicket(data);
    }, e => this.alertService.alert(e));
  }

  public get status() {
    return this.mailingStatuses[this.statusId];
  }

  onCloseBtnClick(): void {
    this.dialogRef.close();
  }

  onSave() {
    this.dashboardMailingService.updateMailingRecepientEventDetails(this.model).subscribe(data => {
      this.dialogRef.close(data);
    }, e => this.alertService.alert(e));

  }
}
