import { BeneficiaryType } from '@app/enums/BeneficiaryType';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { AuditEntityParentType } from '@app/enums/AuditEntityParentTypeEnum';

@Component({
  selector: 'app-beneficiary-history-navigation-buttons',
  templateUrl: './beneficiary-history-navigation-buttons.component.html',
  styleUrls: ['./beneficiary-history-navigation-buttons.component.scss']
})
export class BeneficiaryHistoryNavigationButtonsComponent implements OnInit {
  public isBeneficiary : boolean;
  params: any;
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              public dialog: MatDialog,
              private dialogRef: MatDialogRef<BeneficiaryHistoryNavigationButtonsComponent>) { }

  ngOnInit() {
    this.isBeneficiary = this.data.value.beneficiaryType !== BeneficiaryType.DecisionMaker;
    this.params = {
      parentId: this.data.value.id,
      auditEntityParentType: this.data.value.beneficiaryType == BeneficiaryType.DecisionMaker ? AuditEntityParentType.INVESTOR_BENEFICIARY :
                             this.data.value.beneficiaryType == BeneficiaryType.AssetManager ? AuditEntityParentType.ASSET_MANAGER :
                             this.data.value.beneficiaryType == BeneficiaryType.Fund ? AuditEntityParentType.FUND : AuditEntityParentType.ASSET_MANAGER
    };
  }

  onCloseBtnClick($event): void {
    this.dialogRef.close({data: $event});
  }
}
