import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { ProjectTemplateService } from '@app/service/projectTemplateService';

@Component({
  selector: 'app-bulk-edit-votes-dialog',
  templateUrl: 'bulk-edit-votes-dialog.component.html',
  styleUrls: ['bulk-edit-votes-dialog.component.scss'],
})

export class BulkEditVotesDialogComponent implements OnInit {

  votedTypeColors;
  intentionTypes: IntentionTypeCustom[] = [];
  votingTypes: VotingTypeCustom[] = [];
  model = {
    intentionType: '',
    votedType: '',
    intention: 0,
    voted: 0
  };
  isValidVote = true;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<BulkEditVotesDialogComponent>,
              private projectTemplateService:ProjectTemplateService) {

    this.votedTypeColors = data.votedTypeColors;
    this.intentionTypes = data.intentionTypes;
    this.votingTypes = data.votingTypes;
    this.model.intention = data.intention;
    this.model.voted = data.voted;
  }

  ngOnInit() {

  }


  onCancelBtnClick() {
    this.dialogRef.close();
  }

  onSaveBtnClick() {
    this.dialogRef.close({data: this.model});
  }

  checkVoted(value) {
    let val = parseInt(value.replace(/,/g, ''));
    if (val > this.model.intention) {
      this.isValidVote = false;
    } else {
      this.isValidVote = true;
    }
  }

  getClassName(voteType) {
    return 'vote ' + (voteType && voteType.value || '').toLowerCase().replace(/ /g, '_');
  }

  compareFn(c1: IntentionTypeCustom, c2: IntentionTypeCustom): boolean {
    return c1 && c2 ? c1.value === c2.value : c1 === c2;
  }

  hideVotedTypes(intentioType:string, votedType:string): boolean{
    return this.projectTemplateService.hideVotedTypes(this.data.projectTemplate, intentioType,votedType);
  }
}

class IntentionTypeCustom {
  value: string;
  label: string;
  color: string;
}

class VotingTypeCustom {
  value: string;
  label: string;
  color: string;
}
