import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { CampaignsService } from '@app/services/noborh/campaigns.service';
import { Campaign } from '@app/dto/campaign';
import { AlertService } from '@app/services/alert.service';
import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-export-live-votes-dialog',
  templateUrl: './export-live-votes-dialog.component.html',
  styleUrls: ['./export-live-votes-dialog.component.scss']
})
export class ExportLiveVotesDialogComponent implements OnInit, OnDestroy {
  public exportCampaignForm = new FormGroup({
    campaign: new FormControl('', Validators.required)
  });
  public campaigns: Campaign[] = [];
  private unsubscribe = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private alertService: AlertService,
    private campaignsService: CampaignsService,
    private spinnerService: NgxSpinnerService,
    private dialogRef: MatDialogRef<ExportLiveVotesDialogComponent>,
  ) { }

  public ngOnInit(): void {
    this.campaignsService.getProjectCampaigns(this.data.projectId)
    .pipe(
      takeUntil(this.unsubscribe)
    )
    .subscribe((campaigns) => {
      this.campaigns = campaigns;
    }, (error) => {
      this.alertService.sendError(error);
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onSubmit(): void {
    const campaignId = this.exportCampaignForm.get('campaign').value;
    this.spinnerService.show();

    this.campaignsService.generateExport(campaignId)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((result) => {
        this.alertService.sendSuccess(result.message, true);
        this.spinnerService.hide();
        this.dialogRef.close();
      }, (error) => {
        this.alertService.alert(error);
        this.spinnerService.hide();
      });
  }
}
