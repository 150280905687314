import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FilterContainerOptionsDTO } from '@app/components/table-filter-wrapper/interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterFormComponent } from '../filter-form-component/filter-form-component';
import { FilterOperators } from '@app/components/table-filter-wrapper/enums';

@Component({
  selector: 'app-text-filter-form',
  templateUrl: './text-filter-form.component.html',
  styleUrls: ['./text-filter-form.component.scss']
})
export class TextFilterFormComponent extends FilterFormComponent implements AfterViewInit  {
  @ViewChild('input') public input: ElementRef;

  constructor(fb: FormBuilder) {
    super(fb);
  }

  public ngAfterViewInit(): void {
    this.input.nativeElement.focus();
  }

  public setDefault( options?:  FilterContainerOptionsDTO): FormGroup {
    const defaultSearch = ((options.defaultSearch || []).find(filter => filter.operator === FilterOperators.SEARCH) || {value: ['']}).value[0];
    return this.fb.group({
      value: this.fb.control(defaultSearch , [Validators.required])
    });
  }
}
