import { NoboUploadFileComponent } from '@app/components/nobo-upload-file/nobo-upload-file.component';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorIntl,
  MatPaginatorModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import { MasterDataService } from './services/master-data.service';
import { AppConfigService } from './services/app-config.service';
import { AuthCallbackComponent } from './auth-callback.component';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ContactDlgComponent } from './contact/contactdetail/contact-dlg.component';
import { ProjectRecommendationDlgComponent } from '../app/project/projectdetail/project-recommendation-dlg.component';
import { DataService } from '../app/service/data.service';
import { startupdataProviderFactory, StartupdataproviderService } from '../app/service/startupdataprovider.service';
import { PpBreadcrumbsModule } from 'pp-breadcrumbs';
import { MsAdalAngular6Module } from 'microsoft-adal-angular6';
import { HttpServiceHelper } from './common/HttpServiceHelper';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ExportService } from './service/export.service';
import { AlertService } from './services/alert.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProjectsResolver } from '@app/resolvers/projects.resolver';
import { ClientsResolver } from '@app/resolvers/clients.resolver';
import { InvestorsResolver } from '@app/resolvers/investors.resolver';
import { ProjectTemplateService } from './service/projectTemplateService';
import { CommentEditDialogComponent } from '@app/dashboard/dashboardau/detail/investor-call-management/comments-table/comment-edit-dialog/comment-edit-dialog.component';
import { ProjectCustodianReconciliationDialogComponent } from '@app/project/projectdetail/project-custodial-reconcilation/dialog/project-custodian-reconciliation-dialog.component';
import { CustomMatPaginatorIntl } from '@app/common/custom-mat-paginator.intl';
import { BulkEditVotesDialogComponent } from '@app/dashboard/dashboardau/detail/investor-call-management/vote-records/bulk-edit-dalog/bulk-edit-votes-dialog.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig } from 'ng2-currency-mask/src/currency-mask.config';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatchInvestorDialogComponent } from './project/projectdetail/prjinvdata/dialog/match-investor-dialog.component';
import { AuthenticationInterceptor } from './auth/authentication-interceptor';
import { environment } from 'src/environments/environment';
import { EditInvestorDialogComponent } from '@app/project/projectdetail/prjinvdata/dialog/edit-investor-dalog/edit-investor-dialog.component';
import { DirtyCheckGuard } from './common/can-deactivate.guard';
import { ProjectInvestorUploadDialogComponent } from './project/projectdetail/prjinvdata/project-investor-upload-dialog/project-investor-upload-dialog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FilterModalComponent } from './components/grid-filter/filter-modal/filter-modal.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ProjectFinancialEditDialogComponent } from '@app/project/projectdetail/project-financial/dialog/project-financial-edit-dialog.component';
import { AddEditInvestorDialogComponent } from './project/projectdetail/prjinvdata/dialog/add-edit-investor-dialog/add-edit-investor-dialog.component';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { DialogsComponent } from '@app/services/dialogs/dialogs-component/dialogs.component';
import { LoadFileDialogComponent } from './components/documents/load-file-dialog/load-file-dialog.component';
import { ProjectActivityEditDialogComponent } from './project/projectdetail/project-activity/dialog/project-activity-edit-dialog/project-activity-edit-dialog.component';
import { ContactsResolver } from '@app/resolvers/contacts.resolver';
import { GenericAuditLogsDeleteSegmentComponent } from '@app/components/audit-logs/audit-logs-segment/generic-logs/generic-logs-delete-segment/generic-logs-delete-segment.component';
import { GenericAuditLogsInsertSegmentComponent } from '@app/components/audit-logs/audit-logs-segment/generic-logs/generic-logs-insert-segment/generic-logs-insert-segment.component';
import { GenericAuditLogsUpdateSegmentComponent } from '@app/components/audit-logs/audit-logs-segment/generic-logs/generic-logs-update-segment/generic-logs-update-segment.component';
import { AddInitiativeDialogComponent } from '@app/admin/initiative/addinitiativedialog/addinitiativedialog.component';
import { InitiativeResolver } from '@app/resolvers/initiative.resolver';
import { InvValueCountryEditDialogComponent } from './investor/investordetail/investor-value-country-grid/edit-dialogue/inv-value-country-edit-dialogue/inv-value-country-edit-dialog.component';
import { InvestorGuardService } from '@app/auth/investor-guard.service';
import { LocalCustodiansResolver } from '@app/resolvers/admin/local-custodians.resolver';
import { GlobalCustodiansResolver } from '@app/resolvers/admin/global-custodians.resolver';
import { ClearingHouseResolver } from '@app/resolvers/admin/clearing-house.resolver';
import { AdminProxyAdvisorResolver } from '@app/resolvers/admin/admin-proxy-advisor.resolver';
import { AdminEsgResolver } from '@app/resolvers/admin/admin-esg.resolver';
import { VotingLogsUpdateSegmentComponent } from '@app/components/audit-logs/audit-logs-segment/voting-logs/voting-logs-update-segment/voting-logs-update-segment.component';
import { VotingLogsDeleteSegmentComponent } from '@app/components/audit-logs/audit-logs-segment/voting-logs/voting-logs-delete-segment/voting-logs-delete-segment.component';
import { VotingLogsInsertSegmentComponent } from '@app/components/audit-logs/audit-logs-segment/voting-logs/voting-logs-insert-segment/voting-logs-insert-segment.component';
import { DatePipe } from '@angular/common';
import { BenenficiarymaintenancedialogComponent } from './admin/beneficiary/benenficiarymaintenancedialog/benenficiarymaintenancedialog.component';
import { BeneficiaryAutocompleteComponent } from '@app/components/autocompletes/beneficiry-autocomplete/beneficiary-autocomplete.component';
import { AddInvestorInitiativeDialogComponent } from './investor/investordetail/investor-intel-esg-initiative/addinvestorinitiativedialog/addinitiativedialog.component';
import { InvestorEngagementExampleDialogComponent } from './investor/investordetail/engagement-example/engagement-example-dialog/engagement-example-dialog.component';

import { ExclusionRuleDialogComponent } from './investor/investordetail/investor-intel-esg-investment/exclusion-rule/exclusion-rule-dialog/exclusion-rule-dialog.component';
import { ResolutionsClassificationService } from '@app/components/autocompletes/resolutions-classification/resolutions-classification.service';
import { VotingItemCreateEditDlgComponent } from './project/projectdetail/voting-items-list/voting-item-create-edit-dlg/voting-item-create-edit-dlg.component';
import { ArticlesOpinionsDialogComponent } from './investor/investordetail/investor-intel-esg-reporting/esg-articles-opinions/articles-opinions-dialog/articles-opinions-dialog.component';
import { ResolutionClassificationDialogComponent } from '@app/admin/resolution-classification/resolution-classification-dialog/resolution-classification-dialog.component';
import { ClassificationsService } from '@app/services/classifications.service';
import { ResolutionClassificationResolver } from '@app/resolvers/admin/resolution-classification.resolver';
import { ProxyAdvisorDialogComponent } from './admin/proxy-advisor/proxy-advisor-dialog/proxy-advisor-dialog.component';
import { LocalCustodiansDialogComponent } from '@app/admin/local-custodians/local-custodians-dialog/local-custodians-dialog.component';
import { AddEditCustomFieldComponent } from './admin/resolution-classification/resolution-custom-fields/add-edit-custom-field/add-edit-custom-field.component';
import { ClearingHouseDialogComponent } from '@app/admin/clearing-houses/clearing-house-dialog/clearing-house-dialog.component';
import { EsgPaDialogComponent } from '@app/admin/esg-providers-and-associations/esg-pa-dialog/esg-pa-dialog.component';
import { ComponentsModule } from '@app/components/components.module';
import { AdminExternalAdvisorResolver } from '@app/resolvers/admin/admin-external-advisor.resolver';
import { AdminModule } from '@app/admin/admin.module';
import { BeneficiaryHistoryComponent } from '@app/admin/beneficiary/beneficiary-history/beneficiary-history.component';
import { BeneficiaryHistoryNavigationButtonsComponent } from '@app/admin/beneficiary/beneficiary-history-navigation-buttons/beneficiary-history-navigation-buttons.component';
import { BeneficiaryLogsDeleteSegmentComponent } from '@app/components/audit-logs/audit-logs-segment/beneficiary-logs/beneficiary-logs-delete-segment/beneficiary-logs-delete-segment.component';
import { BeneficiaryLogsInsertSegmentComponent } from '@app/components/audit-logs/audit-logs-segment/beneficiary-logs/beneficiary-logs-insert-segment/beneficiary-logs-insert-segment.component';
import { BeneficiaryLogsUpdateSegmentComponent } from '@app/components/audit-logs/audit-logs-segment/beneficiary-logs/beneficiary-logs-update-segment/beneficiary-logs-update-segment.component';
import { BeneficiaryLogsSegmentComponent } from '@app/components/audit-logs/beneficiary-logs-segment/beneficiary-logs-segment.component';

import { PowerBIResolver } from '@app/resolvers/powerbi/powerbi.resolver';
import { CustodianDetailsComponent } from './dashboard/dashboardau/dashboard-custodian-table/custodian-details/custodian-details.component';
import { AddVotingReconciliationDialogComponent } from './dashboard/dashboardau/dashboard-custodian-table/custodian-details/voting-reconciliation/add-voting-reconciliation-dialog/add-voting-reconciliation-dialog.component';
import { DashboardAddMailingDocumentComponent } from './dashboard/dashboardau/dashboard-mailing-table/dashboard-mailing-dialog-add-doc/dashboard-mailing-add-doc-component';
import { DashboardOrderReceiveDocumentComponent } from './dashboard/dashboardau/dashboard-mailing-table/dashboard-mailing-dialog-order-receive/dashboard-mailing-dialog-order-receive.component';
import { DashboardVotingHistoryDialogComponent } from './dashboard/dashboardau/dashboard-nobo-hr-table/dashboard-voting-history-dialog/dashboard-voting-history-dialog.component';
import { DashboardAddEditMailingDocRecipientComponent } from './dashboard/dashboardau/dashboard-mailing-recipient-table/doc-recipient-add-edit/dashboard-mailing-doc-recipient-add-edit.component';
import { DashboardMailingDocRecipientSentComponent } from './dashboard/dashboardau/dashboard-mailing-recipient-table/doc-recipient-sent/dashboard-mailing-doc-recipient-sent.component';
import { DashboarMailingRecipientDeliveryLabelComponent } from './dashboard/dashboardau/dashboard-mailing-recipient-table/recipient-delivery-label/recipient-delivery-label.component';
import { VotingPatternsDialogComponent } from '@app/dashboard/dashboardau/dashboard-nobo-hr-table/voting-patterns-dialog/voting-patterns-dialog.component';
import { VotingPatternsScannedInvestorComponent } from './dashboard/dashboardau/dashboard-nobo-hr-table/voting-patterns-scanned-investor/voting-patterns-scanned-investor.component';

import { OciExportSetUpDialogComponent } from './dashboard/dashboardau/dashboard-nobo-hr-table/oci-export-set-up-dialog/oci-export-set-up-dialog.component';
import { OciExportConfirmDialogComponent } from './dashboard/dashboardau/dashboard-nobo-hr-table/oci-export-confirm-dialog/oci-export-confirm-dialog.component';
import { HttpErrorInterceptor } from '@app/interceptors/http-error.interceptor';
import { DashboardVotingHistoryComponent } from './dashboard/dashboardau/detail/dashboard-voting-history/dashboard-voting-history.component';
import { NgxWebstorageModule } from 'ngx-webstorage';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  decimal: '.',
  precision: 0,
  prefix: '',
  suffix: '',
  thousands: ','
};

export function loggerCallback(logLevel, message, piiEnabled) {
  console.log('client logging' + message);
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    NotFoundComponent,
    AuthCallbackComponent,
    BeneficiaryHistoryComponent,
    BeneficiaryHistoryNavigationButtonsComponent,
    BeneficiaryLogsDeleteSegmentComponent,
    BeneficiaryLogsInsertSegmentComponent,
    BeneficiaryLogsUpdateSegmentComponent,
    CustodianDetailsComponent,
    AddVotingReconciliationDialogComponent,
    NoboUploadFileComponent,
    VotingPatternsDialogComponent,
    VotingPatternsScannedInvestorComponent,
    OciExportConfirmDialogComponent,
    DashboardVotingHistoryComponent
  ],
  imports: [
    ComponentsModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSortModule,
    MatTabsModule,
    MatInputModule,
    MatCheckboxModule,
    CdkTableModule,
    MatExpansionModule,
    MatDialogModule,
    MatDatepickerModule,
    MatListModule,
    MatNativeDateModule,
    MatRadioModule,
    MatAutocompleteModule,
    NgbModule,
    AppRoutingModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    PpBreadcrumbsModule,
    MatPaginatorModule,
    MatBadgeModule,
    MsAdalAngular6Module.forRoot({
      tenant: '159343fa-29b0-46d9-a910-33cf2fa691b5',
      clientId: environment.activeDirectoryClientId,
      redirectUri: environment.redirectUri,
      endpoints: {},
      //Renew the token if it has less than 30 min left on the lease
      expireOffsetSeconds: 1800,
      cacheLocation: 'localStorage',
      postLogoutRedirectUri: environment.redirectUri,
    }),
    CurrencyMaskModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxSpinnerModule,
    AdminModule,
    NgxWebstorageModule.forRoot()
  ],
  exports: [
    CdkTableModule, RouterModule,
  ],
  entryComponents: [ContactDlgComponent,
    ProjectRecommendationDlgComponent,
    CommentEditDialogComponent,
    ProjectCustodianReconciliationDialogComponent,
    BulkEditVotesDialogComponent,
    EditInvestorDialogComponent,
    MatchInvestorDialogComponent,
    ProjectInvestorUploadDialogComponent,
    BulkEditVotesDialogComponent, EditInvestorDialogComponent,
    MatchInvestorDialogComponent, FilterModalComponent,
    AddEditInvestorDialogComponent,
    DialogsComponent,
    ConfirmDialogComponent, ProjectFinancialEditDialogComponent, LoadFileDialogComponent,
    ProjectActivityEditDialogComponent,
    GenericAuditLogsDeleteSegmentComponent,
    GenericAuditLogsInsertSegmentComponent,
    GenericAuditLogsUpdateSegmentComponent,
    InvValueCountryEditDialogComponent,
    VotingLogsInsertSegmentComponent,
    VotingLogsUpdateSegmentComponent,
    VotingLogsDeleteSegmentComponent,
    AddInvestorInitiativeDialogComponent,
    AddInitiativeDialogComponent,
    InvestorEngagementExampleDialogComponent,
    ExclusionRuleDialogComponent,
    BenenficiarymaintenancedialogComponent,
    BeneficiaryAutocompleteComponent,
    ProxyAdvisorDialogComponent,
    VotingItemCreateEditDlgComponent,
    LocalCustodiansDialogComponent,
    ClearingHouseDialogComponent,
    ResolutionClassificationDialogComponent,
    ArticlesOpinionsDialogComponent,
    EsgPaDialogComponent,
    AddEditCustomFieldComponent,
    BeneficiaryHistoryNavigationButtonsComponent,
    BeneficiaryLogsDeleteSegmentComponent,
    BeneficiaryLogsInsertSegmentComponent,
    BeneficiaryLogsUpdateSegmentComponent,
    BeneficiaryLogsSegmentComponent,
    CustodianDetailsComponent,
    AddVotingReconciliationDialogComponent,
    DashboardAddMailingDocumentComponent,
    DashboardOrderReceiveDocumentComponent,
    NoboUploadFileComponent,
    DashboardVotingHistoryDialogComponent,
    OciExportSetUpDialogComponent,
    OciExportConfirmDialogComponent,
    DashboardAddEditMailingDocRecipientComponent,
    DashboardMailingDocRecipientSentComponent,
    DashboarMailingRecipientDeliveryLabelComponent,
    VotingPatternsDialogComponent,
  ],
  providers: [DataService, MasterDataService, AppConfigService, HttpServiceHelper, AlertService, ExportService, ProjectTemplateService,
    StartupdataproviderService, ProjectsResolver, ContactsResolver, ClientsResolver, LocalCustodiansResolver, GlobalCustodiansResolver, PowerBIResolver,
    ClearingHouseResolver, AdminProxyAdvisorResolver, AdminEsgResolver, DatePipe, ResolutionsClassificationService,
    ResolutionClassificationResolver, AdminExternalAdvisorResolver,
    InvestorsResolver, DirtyCheckGuard, RoleGuardService, InvestorGuardService, InitiativeResolver, ClassificationsService,
    {
      provide: APP_INITIALIZER, useFactory: startupdataProviderFactory, deps: [StartupdataproviderService], multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    {provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [AlertService]
    }
    //provide: HTTP_INTERCEPTORS,
    //useClass: AdalInterceptor,
    //useFactory: startupdataProviderFactory, deps: [StartupdataproviderService], multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
