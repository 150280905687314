import { Component, Inject, OnInit } from '@angular/core';
import { VotingItemsService } from '@app/services/voting-items.service';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { VotingItemDetailsDTO } from './VotingItemDetailsDTO';

@Component({
  selector: 'app-investor-voting-item-details',
  templateUrl: './investor-voting-item-details.component.html',
  styleUrls: ['./investor-voting-item-details.component.scss']
})
export class InvestorVotingItemDetailsComponent implements OnInit {

model:VotingItemDetailsDTO = new VotingItemDetailsDTO();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<InvestorVotingItemDetailsComponent>,
    private votingItemsService: VotingItemsService) { }

  ngOnInit() {
    this.votingItemsService.getVotingItemDetails(this.data.votingItemId)
    .subscribe(result =>{
      this.model = result;
    })
  }
}
