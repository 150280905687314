export class EsgSustainableInvestorResourcesField {
    investorEsgSustainableResourcesFieldId: number;
    investorId: number;

    riContacts: number;
    riTotalResources: number;
    riSource: string;

    fundManagerContacts: number;
    fundManagerTotalResources: number;
    fundManagerSource: string;
}