import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef, MatOptionSelectionChange, MatTableDataSource} from '@angular/material';
import {environment} from '../../../environments/environment';
import {DataLookupCntryKeyValue} from '../../dto/DataLookupCntryKeyValue';
import {DataLookupIdKeyValue} from '../../dto/DataLookupIdKeyValue';
import {ProxyAdvisorRecommendations} from '@app/dto/ProxyAdvisorRecommendations';
import {ProxyAdvisorRecommendationItem} from '@app/dto/ProxyAdvisorRecommendationItem';
import {ProxyAdvisorLookup} from '@app/dto/ProxyAdvisorLookup';
import {ProjectTemplateService} from '@app/service/projectTemplateService';
import {AlertService} from '@app/services/alert.service';
import {ProjectResolutionBODRecommendation} from '@app/dto/ProjectResolutionBODRecommendation';
import {Lookup} from '@app/dto/Lookup';
import {NameValueModel} from '@app/models/basic-structures';
import { CommonUtilityService } from '@app/utility/common-utility.service';

@Component({
    selector: 'app-project-recommendation-dlg',
    templateUrl: 'project-recommendation-dlg.Component.html',
    styleUrls: ['project-recommendation-dlg.component.scss'],
})

export class ProjectRecommendationDlgComponent implements OnInit {
    projectid: number;

    displayedColumns = ['templateProp2', 'recommendation'];
    tableDynamicColumns: string[];
    action: string;
    projectProxyAdvisorReomendationLookup: DataLookupIdKeyValue[] = [];
    proxiAdvisorCountries: DataLookupCntryKeyValue[] = [];
    proxyAdvaisors: ProxyAdvisorLookup[] = [];
    dataSource: MatTableDataSource<ProxyAdvisorRecommendationItem>;
    model: ProxyAdvisorRecommendations;
    recommendations: Lookup[] = [];
    @ViewChild('pa') selectProxyAdvisor: any;
    templateProp1Label: string;
    templateProp2Label: string;
    projectTemplate: string;
    createdProxyRecommendations: any[] = [];
    bulkEditOption = false;
    voteSelectionChangeEvent: MatOptionSelectionChange;
    showBulkEdit = true;

    frequencyVoteTypes: string[] | Lookup[] = [];
    recommendationVoteTypes: Lookup[] = [];

    constructor(
        private httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private projectTemplateService: ProjectTemplateService,
        private alertService: AlertService,
        private dialogRef: MatDialogRef<ProjectRecommendationDlgComponent>,
        private commonUtility: CommonUtilityService) {
        this.projectTemplate = data.projectTemplate;
        this.model = data.model;
        this.createdProxyRecommendations = data.createdProxyRecommendations;

        this.showBulkEdit = !this.projectTemplateService.hasFrequency(this.model.proxyAdvisorRecommendationItems);

        this.frequencyVoteTypes = this.projectTemplateService.getFrequencyVoteTypes(true);
         this.setTableRows(this.model.proxyAdvisorRecommendationItems);

    }

    ngOnInit() {
        const labels = this.projectTemplateService.getProjectItemsTemplateLabels(this.projectTemplate);
        this.templateProp1Label = labels.templateProp1Label;
        this.templateProp2Label = labels.templateProp2Label;
        this.recommendations = this.projectTemplateService.getAdvisorVoteTypes(this.projectTemplate);
        if (this.projectTemplate === this.projectTemplateService.CORPORATE_ACTION_TEMPLATE_NAME) {
            this.displayedColumns.unshift('templateProp1');
        }

        const apiLookUpURL: string = environment.serverUrl + 'projects/proxy-advisors';
        this.httpClient.get<ProxyAdvisorLookup[]>(apiLookUpURL)
            .subscribe(
                data => {
                    this.proxyAdvaisors = data;
                },
                err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });
    }

    onPrxyAdvChange() {
        const proxyAdvisor = this.proxyAdvaisors.find(x => x.proxyAdvisorId === this.model.proxyAdvisorId);
        this.model.proxyAdvisorName = proxyAdvisor.name;
        this.isProxyAdvisorRecommendationsAdded();
    }

    isProxyAdvisorRecommendationsAdded(): boolean {
        const createdProxyAdvisor =
            this.createdProxyRecommendations.find(x => x.proxyId === this.model.proxyAdvisorId);
        if (createdProxyAdvisor) {
            return true;
        }

        return false;
    }

    setAssessmentLevel(assessment: ProxyAdvisorRecommendationItem, value) {
        assessment.recommendation = value;
    }

    onSaveBtnClick() {
        if (this.isProxyAdvisorRecommendationsAdded()) {
            return false;
        }

        this.addProxyAdvises();
    }

    rowsFilledIn() {
        return this.dataSource.data
            .every(function (item) {
                return (item.recommendation !== undefined && item.recommendation !== null) || item.isParentWithChild;
            });
    }

    addProxyAdvises() {
        const modelCopy = JSON.parse(JSON.stringify(this.model));
        modelCopy.proxyAdvisorRecommendationItems = this.model.proxyAdvisorRecommendationItems.filter(x => !x.isParentWithChild);

        const apiURL: string = environment.serverUrl + 'projects/proxy-advisor-recommendations';
        this.httpClient.post<boolean>(apiURL, modelCopy).subscribe(
            data => {
                if (data) {
                    this.alertService.sendSuccess('Proxy recommendations created!');
                    this.dialogRef.close(data);
                }
            },
            err => { this.alertService.sendError(' error ' + JSON.stringify(err)); }
        );
    }

    getClassName(voteType) {
      return 'vote ' + (voteType || '').toLowerCase().replace(/ /g, '_').replace(/1/, 'one')
        .replace(/2/, 'two')
        .replace(/3/, 'three');
    }

    getColor(record: ProjectResolutionBODRecommendation): string {
        const item = this.recommendations.find(x => x.fieldLabel === record.recommendation);
        if (item) {
            return item.color;
        }
    }

    changeActive(value: boolean) {
        this.bulkEditOption = value;
    }

    onBulkVoteChange(event: MatOptionSelectionChange) {
        if (event.source.value && event.isUserInput) {
            const value = event.source.value;
            const tempArray: ProxyAdvisorRecommendationItem[] = this.model.proxyAdvisorRecommendationItems.map((item) => {
                let tempItem: ProxyAdvisorRecommendationItem;
                if (!item.isParentWithChild) {
                    tempItem =  {
                    id: item.id,
                    templateProp1: item.templateProp1,
                    templateProp2: item.templateProp2,
                    relatedProjectItemId: item.relatedProjectItemId,
                    isChild: item.isChild,
                    recommendation: value,
                    votingItemType: item.votingItemType
                  };
                } else {
                    tempItem = {
                        templateProp1: item.templateProp1,
                        templateProp2: item.templateProp2,
                        isParentWithChild: item.isParentWithChild,
                        relatedProjectItemId: item.relatedProjectItemId,
                        id: item.id
                    };
                }

                return tempItem;
            });

            this.model.proxyAdvisorRecommendationItems = [...tempArray];
            this.dataSource = new MatTableDataSource<ProxyAdvisorRecommendationItem>(this.model.proxyAdvisorRecommendationItems);
        }
    }

    getRecommendationOptions(element) {
      return /^frequency/gmi.exec(element.votingItemType) ? this.frequencyVoteTypes : this.recommendations;
    }

  setTableRows(data) {
    const tableRows = data.reduce((accumulator, item, currentIndex) => {
        let templateProp1;
        if (!item.isChild) {
            templateProp1 = `${item.templateProp1}. ${item.templateProp2}`;
        } else {
            const orderLetter = this.commonUtility.indexToLetter(item.votingItemChild.orderId, false);
            templateProp1 = `${item.templateProp1}.${orderLetter} ${item.votingItemChild.name}`;
        }

        const row: ProxyAdvisorRecommendationItem = {
            templateProp1: item.templateProp1,
            templateProp2: templateProp1,
            relatedProjectItemId: item.relatedProjectItemId,
            isChild: item.isChild,
            recommendation: item.recommendation,
            votingItemType: item.votingItemType,
            isParentWithChild: item.isParentWithChild
        };

        accumulator.push(row);

      return accumulator;
    }, []);

    this.model.proxyAdvisorRecommendationItems = tableRows;
    this.dataSource = new MatTableDataSource<ProxyAdvisorRecommendationItem>(this.model.proxyAdvisorRecommendationItems);
  }
}
