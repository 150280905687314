import { DashboardAuCampaignSummaryTableService } from '@app/services/dashboard-au-campaign-summary-table.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import {
    AUDashboardResponse,
    AUCustodianSummary,
    AUCustodianDetail,
    AUProjectInvestorSummary,
    Beneficiaries
} from '../../../dto/AUDashboard';
import { UserService } from 'src/app/services/user.service';
import { ProjectTemplateTypes } from '@app/enums/ProjectTemplateTypesEnum';
import { CallLog } from '@app/dto/CallLog';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { ProjectsService } from '@app/services/projects.service';

@Component({
    selector: 'app-dashboard-au-summary',
    templateUrl: './dashboard-au-summary.component.html',
    styleUrls: ['./dashboard-au-summary.component.scss']
})
export class DashboardAUSummaryComponent implements OnInit, OnDestroy {
    routeSub: any;
    public projectTemplateType: ProjectTemplateTypes;
    public templateTypes = ProjectTemplateTypes;
    auprojectinvestorsummary: AUProjectInvestorSummary;
    aucurrentcalllogs: CallLog[] = [];
    beneficiaries: Beneficiaries[] = [];

    intentiontypecolors =
        [
            { type: 'FOR', color: '#7CFC00' },
            { type: 'ACCEPT', color: '#7CFC00' },
            { type: 'AGAINST', color: '#ff9999' },
            { type: 'DECLINE', color: '#ff9999' },
            { type: 'ABSTAIN', color: '#A9A9A9' },
            { type: 'UNDECIDED', color: '#FFA500' },
            { type: 'UNDISCLOSED', color: '#1E90FF' },
            { type: 'NOT VOTING', color: '#1E1E1E' },
            { type: 'IN LINEwPADV', color: '#800080' }
        ];

    votedtypecolors =
        [
            { type: 'FOR', color: '#7CFC00' },
            { type: 'ACCEPT', color: '#7CFC00' },
            { type: 'AGAINST', color: '#ff9999' },
            { type: 'DECLINE', color: '#ff9999' },
            { type: 'ABSTAIN', color: '#A9A9A9' },
            { type: 'UNDECIDED', color: '#FFA500' },
            { type: 'UNDISCLOSED', color: '#1E90FF' },
            { type: 'NOT VOTING', color: '#1E1E1E' }
        ];

    projectid: number;
    investorid: number;
    rolename: string;
    shareClassifications: string[] = [];

    constructor(private route: ActivatedRoute,
                private httpClient: HttpClient,
                private userService: UserService,
                private spinner: NgxSpinnerService,
                private projectService: ProjectsService,
                private dashboardSummaryService: DashboardAuCampaignSummaryTableService) {

    }

    ngOnInit() {
        this.rolename = this.userService.getUserRoleName();
        this.spinner.show();
        this.routeSub = this.route.params.subscribe((params) => {
            this.projectid = +params['id'];
            this.investorid = + params['invid'];
            this.getShareClassifications(this.projectid);
            this.getDashboard(this.projectid, this.investorid);
        });
        this.getBeneficiaries(this.projectid, this.investorid);
    }

    getBeneficiaries(projectId: number, investorId: number) {
        return this.dashboardSummaryService.getBeneficiaries(projectId, investorId).subscribe(
            data => {this.beneficiaries = data; });

    }
    getShareClassifications(projectId: number) {
        return this.projectService.getProjectShareClassifications(projectId).subscribe(
            data => {
                this.shareClassifications = data;
            });
    }

    getIntentionTypeColor(intentiontype: string) {
        if (!intentiontype) {
            return 'white';
        }
        return this.intentiontypecolors.filter(item => item.type === intentiontype)[0].color;

    }
    getVotedTypeColor(votedtype: string) {
        if (!votedtype) {
            return 'white';
        }
        return this.votedtypecolors.filter(item => item.type === votedtype)[0].color;
    }

    getDashboard(projectid: number, investorid: number) {
        const apiURL = `${environment.serverUrl}dashboards/${projectid}/${investorid}`;
        this.httpClient.get<AUDashboardResponse>(apiURL)
            .pipe(finalize(() => {
                this.spinner.hide();
            })).subscribe(
                data => {
                    const audashboardresponse: AUDashboardResponse = data;
                    this.projectTemplateType = data.projectTemplateType;
                    this.auprojectinvestorsummary = audashboardresponse.auProjectInvestorSummary;
                    this.aucurrentcalllogs = audashboardresponse.auCurrentLogCalls;
                });
    }

    checkRole(): boolean {

        if (this.userService.getUserRoleName() === 'External_Client' ||
            this.userService.getUserRoleName() === 'EU_Client') { return false; }
        return true;
    }

    ngOnDestroy() {

    }
}
