import { Component, OnInit, Output, EventEmitter, ViewChild, Inject } from '@angular/core';
import { Beneficiary } from '@app/dto/Beneficiary';
import { MAT_DIALOG_DATA } from '@angular/material';
import { BeneficiaryService } from '@app/services/admin/beneficiary.service';
import { BeneficiaryType } from '@app/enums/BeneficiaryType';
import { AlertService } from '@app/services/alert.service';
import { AuditEntityParentType } from '@app/enums/AuditEntityParentTypeEnum';


@Component({
  selector: 'app-beneficiary-history',
  templateUrl: './beneficiary-history.component.html',
  styleUrls: ['./beneficiary-history.component.scss']
})
export class BeneficiaryHistoryComponent implements OnInit {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  model: Beneficiary = new Beneficiary();
  @ViewChild('beneficiaryHistory') beneficiaryHistory: any;
  countryFacsetLabel :string = "";
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
                      private beneficiaryService: BeneficiaryService, 
                      private alertService: AlertService) { }

  ngOnInit() {

    this.beneficiaryService.getBeneficiary(this.data.value.id, this.data.value.beneficiaryType)
        .subscribe(
            data => {
                this.model = data;
                switch(data.beneficiaryType){
                  case 0: {
                    this.model.beneficiaryType = BeneficiaryType.AssetManager;
                    break;
                  }
                  case 1:{
                    this.model.beneficiaryType = BeneficiaryType.Fund;
                    break;
                  }
                  case 2: {
                    this.model.beneficiaryType = BeneficiaryType.DecisionMaker;
                    break;
                  }
                }
                this.countryFacsetLabel = 
                      this.model.country && this.model.factsetId ? `(${this.model.country} - ${this.model.factsetId})` :
                      this.model.country && !this.model.factsetId ? `(${this.model.country})` : 
                      !this.model.country && this.model.factsetId ? `(${this.model.factsetId})` : 
                      !this.model.country && !this.model.factsetId? "" : "";
            },
            err => {
              this.alertService.sendError(err.error);
            });
  }

  onCancelDialogBtnClick() {
    this.onClose.emit(true);
  }
}