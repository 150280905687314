import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {InitiativeDetailComponent} from '../initiative-sub-menu/initiativedetail/initiativedetail.component';

@Component({
  selector: 'app-add-initiative-dialog',
  templateUrl: './addinitiativedialog.component.html'
})
export class AddInitiativeDialogComponent {
  public _formValid = false;
  @ViewChild('initiative') initiativeComponent: InitiativeDetailComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddInitiativeDialogComponent>
  ) { }

  onCloseBtnClick($event?): void {
    this.dialogRef.close({data: $event});
  }

  setFormValidation(valid) {
    this._formValid = valid;
  }

  onClickReset() {
    this.initiativeComponent.onResetBtnClick();
  }
}
