import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { map } from 'rxjs/operators';
import { ProjectInvestorIntentionVoteAdapter } from '@app/dto/ProjectInvestorIntentionVoteDTO';
import { IList } from '@app/dto/base/list.interface';
import { InvestorVotingEditAdapter, InvestorVotingSaveAdapter } from '@app/dto/investor/investor-voting-edit.interface';

@Injectable({
  providedIn: 'root'
})
export class DashboardInvestorVotingService {
  constructor(
    private readonly httpClient: HttpClient
  ) { }

  custodianSearch(searchString: string, projectId: number): Observable<IList[]> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<IList[]>(`${environment.serverUrl}dashboards/investor-voting/custodians-typeahead-search/${projectId}/`, {params: {search: searchString}});
  }

  getInvestorVotes(projectId: number, investorId: number, globalCustodianId: number): Observable<InvestorVotingEditAdapter[]> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<InvestorVotingEditAdapter[]>(`${environment.serverUrl}dashboards/investor-voting/edit/${projectId}/${investorId}/${globalCustodianId}`)
      .pipe(map(data => data.map(item => new InvestorVotingEditAdapter(item))));
  }

  setInvestorVotes(data): Observable<InvestorVotingSaveAdapter> {
    const url = `${environment.serverUrl}dashboards/investor-voting/edit`;
    return this.httpClient.post<InvestorVotingSaveAdapter>(url, data);
  }

  setInvestorOvervotes(data) : Observable<InvestorVotingSaveAdapter> {
      const url = `${environment.serverUrl}dashboards/investor-voting/edit-overvoting`;
      return this.httpClient.post<InvestorVotingSaveAdapter>(url, data);
  }

  list(projectId, investorId): Observable<ProjectInvestorIntentionVoteAdapter> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<ProjectInvestorIntentionVoteAdapter>(`${environment.serverUrl}dashboards/investor-voting/${projectId}/${investorId}`, {}).pipe(map(data => new ProjectInvestorIntentionVoteAdapter( data )));
  }
}
