import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMultiSort } from './mat-multi-sort.directive';
import { MatMultiSortHeaderComponent } from './mat-multi-sort-header/mat-multi-sort-header.component';
import { MatCommonModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatCommonModule
  ],
  declarations: [MatMultiSort, MatMultiSortHeaderComponent],
  exports: [MatMultiSortHeaderComponent, MatMultiSort]
})
export class MatMultiSortModule { }
