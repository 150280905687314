import { Component, Input, forwardRef, ChangeDetectionStrategy, ViewChild, DoCheck } from '@angular/core';
import { NumberCurrencyData } from './number-currency-data.model';
import { MasterDataService } from '@app/services/master-data.service';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, Validator, AbstractControl, ValidationErrors, NgModel, NG_VALIDATORS, FormControl } from '@angular/forms';

const noop = () => {};

@Component({
  selector: 'app-number-currency',
  templateUrl: './number-currency.component.html',
  styleUrls: ['./number-currency.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberCurrencyComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumberCurrencyComponent),
      multi: true
    }
  ]
})
export class NumberCurrencyComponent implements ControlValueAccessor, Validator, DoCheck {

  constructor(public masterDataService: MasterDataService) {
    this._value =  new NumberCurrencyData();

    this.masterDataService.getCurrencies().subscribe(res => {
      if (res) {
        // Get only items with code (filter) and map to Array<string> (map)
        this.currencies = res.filter(obj => !!(obj.currencyCode)).map(obj => obj.currencyCode);
      }
    });
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    this._value = v;
    this.notifyValueChange();
  }
  @ViewChild('amount') public amount: NgModel;
  @ViewChild('currency') public currency: NgModel;
  @Input()
  disabled: boolean;
  @Input()
  required = false;
  public currencies: Array<string>;
  private onChangeCallback: (_: any) => void = noop;

  private _value: NumberCurrencyData;

  onChange: any = () => {};
  onTouch: any = () => {};

  ngDoCheck(): void {
    this.onChangeCallback(this.value);
  }

  validate(control: FormControl): ValidationErrors {
    const valid = this.amount && this.amount.valid && this.currency && this.currency.valid;

    return valid ? null : { valid };
  }

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value: any): void {
    this._value = value;
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
