import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GlobalCustodiansLendingComponent} from '@app/admin/global-custodians/global-custodians-lending.component';
import {GlobalCustodiansListComponent} from '@app/admin/global-custodians/global-custodians-list/global-custodians-list.component';
import {GlobalCustodiansResolver} from '@app/resolvers/admin/global-custodians.resolver';

const routes: Routes = [
  {
    path: '',
    component: GlobalCustodiansLendingComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        component: GlobalCustodiansListComponent,
      },
      {
        path: ':id',
        data: {breadcrumbs: GlobalCustodiansResolver, subnav: 1},
        loadChildren: './global-custodians-sub-menu/global-custodians-sub-menu.module#GlobalCustodiansSubMenuModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)], // add  `enableTracing: true` if you want to debug the links
  exports: [RouterModule]
})

export class GlobalCustodiansRoutingModule {}

