import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private alertService: AlertService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          const { error } = response;

          if (response.status === 400) {
            try {
              const errors = error.errors
                .map((e) => e.error);

              errors.forEach(element => {
                this.alertService.sendError(element);
              });
            } catch (error) {
              console.error(error);
            }
          } else if (response.status === 500) {
            this.alertService.sendError('Something went wrong! Please contact your administrator.');
          } else if (response.status === 412) {
            return throwError(response);
          }

          return throwError(response.error);
        })
      );
  }
}
