import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { AlertService } from '@app/services/alert.service';
import { ProjectMailingIndividualDeliveryTicket } from '@app/dto/ProjectMailingDeliveryLabelDTO';
import { Shareholdings } from '@app/dto/ProjectCustodialListDto';
import { ProjectMailingDocumentRecipientDTO } from '@app/dto/ProjectMailingDocumentRecipientDTO';
import { DropdownItem } from '@app/components/dropdown-select/dropdown-select.component';
import { LookupFilter, Utils } from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { combineLatest } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, first, startWith, tap } from 'rxjs/operators';
import { DashboardMailingService } from '@app/services/dashboard';
import { sanitizeFileName } from '@app/utility/Strings';
import * as FileSaver from 'file-saver';
import { NgForm } from '@angular/forms';
import { IndividualTicketFormComponent } from '@app/dashboard/dashboardau/dashboard-mailing-recipient-table/individual-delivery-ticket/individual-delivery-ticket-form/individual-ticket-form.component';

@Component({
  selector: 'app-dashboard-mailing-individual-delivery-ticket',
  templateUrl: './individual-delivery-ticket.component.html',
  styleUrls: ['./individual-delivery-ticket.component.scss']
})
export class IndividualDeliveryTicketComponent implements OnInit {
  model: ProjectMailingIndividualDeliveryTicket = new ProjectMailingIndividualDeliveryTicket();
  element: ProjectMailingDocumentRecipientDTO;
  allSetOfMaterials: DropdownItem[] = [];
  custodianName = '';
  projectId: number;
  util = new Utils(this.startupdataproviderService);
  @ViewChild('dataForm') dataForm: IndividualTicketFormComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ProjectMailingDocumentRecipientDTO,
              private dialogRef: MatDialogRef<IndividualDeliveryTicketComponent>,
              private readonly dashboardService: DashboardService,
              private readonly alertService: AlertService,
              private readonly startupdataproviderService: StartupdataproviderService,
              private readonly spinnerService: NgxSpinnerService,
              private readonly dashboardMailingService: DashboardMailingService) {

    this.element = data;
    this.custodianName = data.recipientName;
    this.projectId = data.projectId;
  }

  ngOnInit() {
    this.getData();
  }

  getData(): void {
    const cusips$ = this.dashboardService.getCusips(this.element.mailingDocumentRecipientId, this.element.projectId, false);
    const labelData$ = this.dashboardService.getDeliveryLabelData(this.projectId);
    const projectMailingDocs$ = this.util.getLookupByType(LookupFilter.PROJECT_MAILING_DOC);
    this.spinnerService.show('dashboard-mailing-individual-delivery-ticket');
    combineLatest(
      cusips$,
      labelData$,
      projectMailingDocs$,
      (cusips, labelData, projectMailingDocs) => ({cusips, labelData, projectMailingDocs}))
      .pipe(
        first()
      )
      .subscribe(pair => {
        const { projectMailingDocs, cusips, labelData } = pair;
        const { recipientName: custodianName, mailingDocumentRecipientId } = this.element;
        const shareholdings = cusips.map( item => new Shareholdings(item));
        this.allSetOfMaterials = projectMailingDocs.map(item => new DropdownItem({id: item.lookUpId.toString(), name: item.fieldValue}));
        const data = {...labelData, shareholdings, custodianName, mailingDocumentRecipientId, setOfMaterials: this.model.setOfMaterials};
        this.model = new ProjectMailingIndividualDeliveryTicket(data);
      }, e => this.alertService.alert(e),
        () => this.spinnerService.hide('dashboard-mailing-individual-delivery-ticket'));

  }


  onCloseBtnClick(): void {
    this.dialogRef.close();
  }

  onClickPrint() {
    this.spinnerService.show('dashboard-mailing-individual-delivery-ticket');
    this.dashboardMailingService.deliveryIndividualTicket(this.model).pipe(
      first(),
      finalize(() => this.spinnerService.hide('dashboard-mailing-individual-delivery-ticket'))
    ).subscribe(
      async data => {
        await this.downloadPdfReport(data.mailingDocumentRecipientSentId, this.model.custodianName, data.barcode);
      },
      e => this.alertService.alert(e)
    );
  }

  async downloadPdfReport(mailingDocumentRecipientSentId: number, recipientName: string, barcodeNumber: string): Promise<void> {
    await this.spinnerService.show('dashboard-mailing-individual-delivery-ticket');
    try {
      const blobResult = await this.dashboardMailingService.downloadDeliveryIndividualTicketPdfFile(mailingDocumentRecipientSentId);
      await FileSaver.saveAs(blobResult, sanitizeFileName(recipientName + '_' + barcodeNumber) + '.pdf');
      this.dialogRef.close(true);
    } catch (e) {
      this.alertService.sendError('Error while downloading file!');
    }
    await this.spinnerService.hide('dashboard-mailing-individual-delivery-ticket');
  }

  onSetOfMaterialsChange($event: DropdownItem[]): void {
    this.model.setOfMaterials = $event;
  }
}
