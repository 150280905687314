import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectActivity } from '@app/dto/ProjectActivity';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActiveDirectoryUser } from '@app/dto/ActiveDirectoryUser';

@Injectable({
  providedIn: 'root'
})
export class ProjectActivityService {

  constructor(private httpClient: HttpClient) { }


  public getProjectActivittiesByProjectId(projectId: number): Observable<ProjectActivity[]> {
    let apiURL: string = `${environment.serverUrl}projects/project-activity-by-id/${projectId}`;
    return this.httpClient.get<ProjectActivity[]>(apiURL);
  }

  public add(item: ProjectActivity) {
    let apiURL: string = `${environment.serverUrl}projects/add-project-activity`;
    return this.httpClient.post<ProjectActivity>(apiURL, item)
  }

  public delete(projectActivityId: number) {
    let apiURL = `${environment.serverUrl}projects/delete-project-activity/${projectActivityId}`;
    return this.httpClient.delete(apiURL);
  }

  public getProjectManager(projectId : number): Observable<ActiveDirectoryUser> {
    let apiURL = `${environment.serverUrl}projects/project-manager/${projectId}`;
    return this.httpClient.get<ActiveDirectoryUser>(apiURL); 
  }

  assignProjectTeamResponsibility(item: ProjectActivity, isProjectTeamChecked: boolean): ProjectActivity {
    //Assign a (non-AD) PROJECT TEAM user to the activity.
    if (isProjectTeamChecked) {
      item.responsibilityUser.displayName = "Project Team";
      item.responsibilityUser.mail = '';
      item.responsibilityUser.userPrincipalName = "Project Team";
      item.responsibilityUser.id = "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa";
    } else {
      item.responsibilityUser.displayName = '';
      item.responsibilityUser.mail = '';
      item.responsibilityUser.userPrincipalName = '';
      item.responsibilityUser.id = '';
    }

    let updatedModel = new ProjectActivity();
    Object.assign(updatedModel, item);
    return updatedModel;
  }
  
}
