import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { map } from 'rxjs/operators';
import {
  InvestorVotingSummaryAdapter,
  InvestorVotingSummaryInterface
} from '@app/dto/investor/investor-voting-summary.interface';
import { FilterAndPaginateOutputDTO } from '@app/components/table-filter-wrapper/interfaces';

@Injectable({
  providedIn: 'root'
})
export class InvestorVotingSummaryService {
  constructor(
    private readonly httpClient: HttpClient
  ) { }

  getInvestorVotingSummary(investorId: number, filters: FilterAndPaginateOutputDTO): Observable<InvestorVotingSummaryAdapter> {
    const url = `${environment.serverUrl}investors/${investorId}/voting-history`;

    return this.httpClient.post<InvestorVotingSummaryInterface>(url, filters)
      .pipe(map(data => new InvestorVotingSummaryAdapter(data)));
  }

  getSectorOptions(investorId: number): Observable<any> {
    const url = `${environment.serverUrl}investors/${investorId}/voting-history/filter-options/sector`;

    return this.httpClient.get(url);
  }

  getMarketOptions(investorId: number): Observable<any> {
    const url = `${environment.serverUrl}investors/${investorId}/voting-history/filter-options/market`;

    return this.httpClient.get(url);
  }

  getResolutionClassifications(investorId: number): Observable<any> {
    const url = `${environment.serverUrl}investors/${investorId}/voting-history/filter-options/resolution-classifications`;

    return this.httpClient.get(url);
  }
}
