import { Component, Input, OnInit, Output, EventEmitter, ViewChild, Predicate } from '@angular/core';
import { BroadridgeCode } from "../../../../dto/BroadridgeCode";
import { DtcCode } from "../../../../dto/DtcCode";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent, MatSort, MatTableDataSource } from '@angular/material';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { AlertService } from '@app/services/alert.service';
import { DeleteCodeTemplate } from '@app/services/dialogs/dialog-code-template-config';

@Component({
  selector: 'app-global-custodians-codes',
  templateUrl: './global-custodians-codes.component.html',
  styleUrls: ['global-custodians-general.component.scss', 'global-custodians-codes.component.scss'],
})
export class GlobalCustodiansCodesComponent implements OnInit {
  _dtcCodesDataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  _dtcCodes: DtcCode[] = [];

  private _isEdit: boolean;
  private _newIdCounter = 0;

  @Output() onFormStatusChange = new EventEmitter<string>();

  actions = {
    update: 'update',
    delete: 'delete',
    create: 'create',
  };

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  formGroup: FormGroup = new FormGroup({});

  constructor(private dialogsService: DialogsService,
    private alertService: AlertService,) {

  }

  ngOnInit(): void {
    this.formGroup.statusChanges.subscribe(status => {
      this.onFormStatusChange.emit(status);
    });

    this.InitForm({ disableForm: true });
  }

  displayedColumns: string[] = ['RowNumber', 'code', 'broadridgeCodes', 'actions'];

  @Input() set dtcCodes(value: DtcCode[]) {
    this._dtcCodes = value;
    this.setTableItems();
    this.formGroup = new FormGroup({});
    const controls = this.formGroup.controls;
    for (let control of Object.keys(controls)) this.formGroup.removeControl(control);
    this.InitForm({ disableForm: true });
  }

  @Input() freeBroadridgeCodes: BroadridgeCode[];

  @Input() custodianId: number;

  private InitForm(
    options: {
      disableForm: boolean
    } = {
        disableForm: false
      }
  ) {
    for (let dtcCode of this._dtcCodes) {
      this.formGroup.addControl('dtcCode_' + dtcCode.id, new FormControl({},
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(20)
        ]));

      this.formGroup.addControl('bdCodes_' + dtcCode.id, new FormControl());
    }

    this.formGroup.addControl('freeBdCode', new FormControl());
    if (options.disableForm) this.formGroup.disable();
  }

  setTableItems() {
    this._dtcCodesDataSource = new MatTableDataSource<DtcCode[]>();
    this._dtcCodesDataSource.sort = this.sort;
    this._dtcCodesDataSource.data = this._dtcCodes.filter(x => x.action !== 'delete');
  }

  @Input() set isEdit(value: boolean) {
    if (value) {
      this.formGroup.enable();
    } else {
      this.formGroup.disable();
    }

    this._isEdit = value;
  }

  get isEdit() {
    return this._isEdit;
  }

  getParsed(value) {
    return JSON.parse(value);
  }

  onDtcCodeChange(element: DtcCode, action: string) {
    if (action === this.actions.create || action === this.actions.update) {
      return;
    } else if (action === this.actions.delete) {
      if (element.broadridgeCodes && element.broadridgeCodes.length > 0) {
        this.dialogsService.confirm(null, null, {
          template: DeleteCodeTemplate({
            title: 'DTC Code',
            message: `Broadridge codes must be moved/deleted first for ${element.code} code!`
          })
        });
        this.dialogsService.close$.subscribe(res => { console.log(res) });
      } else {
        if (element.id > 0) {
          this.dialogsService.confirm('DTC Code', `All local custodian links to DTC code '${element.code}' will also be deleted!`, {
            okButton: {
              text: 'DELETE',
              class: 'ms-btn-red-delete',
              icon: 'ms-delete-red-icon'
            },
            cancelButton: {
              text: 'CANCEL'
            }
          });
          this.dialogsService.close$.subscribe(res => {
            if (res) {
              this.deleteIt(element);
            }
          });
        } else {
          this.deleteIt(element);
        }

      }
    }
  }

  deleteIt(element){
    this.formGroup.removeControl('dtcCode_' + element.id);
      element.action = this.actions.delete;
      this.setTableItems();
  }

  preventEditingOfElement(element: DtcCode) {
    return !element || !this.isEdit || element.action === this.actions.delete;
  }

  onAddDtcCodeClick() {
    this._dtcCodes.push({
      action: 'create',
      broadridgeCodes: [],
      code: null,
      id: this.getNewId(),
      globalCustodianId: this.custodianId,
    });

    this.setTableItems();
    this.InitForm({ disableForm: false });
  }

  removeBroadridgeCode() {

  }

  addBrCode(event: MatChipInputEvent, dtcCode: DtcCode): void {
    if (!dtcCode) throw new Error('dtcCode cannot be null');

    const { value, input } = event;

    if (!value || value === '' || this.findBrCodeInActive(value)) {
      return;
    }

    let deletedInCurrentSession = dtcCode.broadridgeCodes.find(br => br.code.toLowerCase() === value.toLowerCase());

    if (deletedInCurrentSession) {
      deletedInCurrentSession.action = null;
    } else {
      let bdCode = new BroadridgeCode();
      bdCode.code = value;
      bdCode.action = 'create';
      bdCode.dtcCodeId = dtcCode.id;
      bdCode.globalCustodianId = this.custodianId;
      bdCode.id = this.getNewId();

      dtcCode.broadridgeCodes.push(bdCode);
    }

    input.value = '';
  }

  addFreeBrCode(event: MatChipInputEvent): void {
    const { value, input } = event;

    if (!value || value === '' || this.findBrCodeInActive(value)) {
      return;
    }

    let deletedInCurrentSession = this.freeBroadridgeCodes.find(br => br.code.toLowerCase() === value.toLowerCase());
    if (deletedInCurrentSession) {
      deletedInCurrentSession.action = null;
    } else {
      let bdCode = new BroadridgeCode();
      bdCode.code = value;
      bdCode.action = 'create';
      bdCode.dtcCodeId = null;
      bdCode.globalCustodianId = this.custodianId;
      bdCode.id = this.getNewId();

      this.freeBroadridgeCodes.push(bdCode);
    }

    input.value = '';
  }

  onFreeBrCodeDeleteClick(brCode: BroadridgeCode) {
    if (brCode.action !== 'create') {
      brCode.action = 'delete';
    } else {
      this.freeBroadridgeCodes.splice(this.freeBroadridgeCodes.indexOf(brCode), 1);
    }
  }

  onBrCodeDeleteClick(brCode: BroadridgeCode, dtcCode: DtcCode) {
    this.dialogsService.confirm(null, null, {
      template: DeleteProjectTemplate({
        title: 'Broadridge Code',
        message: `${brCode.code}`
      })
    });
    this.dialogsService.close$.subscribe(res => {
      if (!res) return;

      if (brCode.action !== 'create') {
        brCode.action = 'delete';
      } else {
        dtcCode.broadridgeCodes.splice(dtcCode.broadridgeCodes.indexOf(brCode), 1);
      }
    });
  }

  private getNewId = () => --this._newIdCounter;

  findBrCodeInDeleted(code: string) {
    return this.findInAllBrCodes(x => x.code === code && x.action === 'delete');
  }

  findBrCodeInActive(code: string) {
    return this.findInAllBrCodes(x => x.code === code && x.action !== 'delete');
  }

  private findInAllBrCodes(filter: Predicate<BroadridgeCode>): BroadridgeCode {
    return this._dtcCodes
      .reduce((prev, curr) => [...prev, ...curr.broadridgeCodes], [])
      .find(filter)
      ||
      this.freeBroadridgeCodes.find(filter);
  }

  isDTCCodeEnabled(element){
    
    const res = !this.isEdit || element.id > 0;
    if(res){
      this.formGroup.controls[`dtcCode_${element.id}`].disable();
    } else {
      this.formGroup.controls[`dtcCode_${element.id}`].enable();
    }
    return res;
  }
}
