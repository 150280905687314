import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IOutputPatternVotes, IVotingPatternsScannedInvestorInterface } from '@app/dto/VotingPatternsScannedInvestorDto';
import { CustodianReportDTO } from '@app/dashboard/dashboardau/custodian-report-dialog/CustodianReportDTO';
import { FilterAndPaginateOutputDTO } from '@app/components/table-filter-wrapper/interfaces';
import { NOBORHSummary } from '@app/dto/NOBORHSummary';

@Injectable({
  providedIn: 'root'
})
export class DashboardNoborhService {

  constructor(private http: HttpClient) {
  }

  public getInvestorByControlNumber(controlNumber: number, projectId: number): Observable<IVotingPatternsScannedInvestorInterface> {
    return this.http.get<IVotingPatternsScannedInvestorInterface>(
      `${environment.serverUrl}noborh/noborh-summary/${projectId}/investor/${controlNumber}`
    );
  }

  public savePatternVotes(data: IOutputPatternVotes, projectId: number): Observable<any> {
    return this.http.post<IOutputPatternVotes>(`${environment.serverUrl}noborh/noborh-summary/${projectId}/enter-votes`, data);
  }

  public generateNoborhSummaryReport(model: CustodianReportDTO): Observable<any> {
    return this.http.get(`${environment.serverUrl}noborh/noborh-summary/file/${model.projectId}`, { responseType: 'blob', observe: 'response' });
  }

  public searchInvestorsByName(projectId: number, filters: FilterAndPaginateOutputDTO): Observable<NOBORHSummary[]> {
    const url = `${environment.serverUrl}noborh/noborh-summary/${projectId}`;

    return new Observable((observer) => {
      return this.http.post<any>(url, filters).subscribe((response) => {
        const noborhSummaries = response.items.map((item) => new NOBORHSummary(item));

        observer.next(noborhSummaries);
        observer.complete();
      }, (error) => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
