import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DirtyComponent } from '@app/common/dirty-component';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-investor-intel-esg-engagement',
  templateUrl: './investor-intel-esg-engagement.component.html',
  styleUrls: ['./investor-intel-esg-engagement.component.scss']
})
export class InvestorIntelEsgEngagementComponent implements OnInit, DirtyComponent {

  isChild = true; // it is used in the landing component to determine childs
  isEdit = false;

  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();
  investorId: number;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.investorId = +params['id'];
    });

    this.isDirty$.subscribe(data => {
      return of(data);
    });
  }
}
