import { AfterViewInit, Component, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class DropdownItem {
  id: string;
  name: string;

  public constructor(init?) {
    Object.assign(this, init);
  }
}

/**
 * Example:
 * <app-dropdown-select [dropdownOptions]="this.banks" (onSelectionChanged)="this.onBankChange($event)"></app-dropdown-select>
 */
@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss']
})
export class DropdownSelectComponent implements OnInit {

  private _dropdownOptions: DropdownItem[];
  private _initialSelectAll: boolean = true;

  public selectedOptionsCtrl: FormControl = new FormControl();

  public searchValueCtrl: FormControl = new FormControl();

  @Output() onSelectionChanged: Subject<DropdownItem[]> = new Subject<DropdownItem[]>();

  @Input() placeholder: string;
  @Input() disabled = false;

  @Input() set dropdownOptions(value: DropdownItem[]) {
    this._dropdownOptions = value;

    // load the initial list
    this.filteredItems.next(this.dropdownOptions.slice());

    this.searchValueCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItems();
      });

    this.selectedOptionsCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => this.onSelectionChanged.next(this.selectedOptionsCtrl.value));

    this.updateInitialSelectAll();
  };

  get dropdownOptions(): DropdownItem[] {
    return this._dropdownOptions;
  }

  @Input() set initialSelectAll(value: boolean) {
    this._initialSelectAll = value;
    this.updateInitialSelectAll()
  }

  public filteredItems: ReplaySubject<DropdownItem[]> = new ReplaySubject<DropdownItem[]>(1);

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();


  ngOnInit() { }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private updateInitialSelectAll() {
    if (this._initialSelectAll) {
      this.selectedOptionsCtrl.setValue(this.dropdownOptions);
    }else{
      this.selectedOptionsCtrl.setValue([]); 
    }
  }

  private filterItems() {
    if (!this.dropdownOptions) {
      return;
    }

    // get the search keyword
    let search = this.searchValueCtrl.value;

    if (!search) {
      this.filteredItems.next(this.dropdownOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the items
    this.filteredItems.next(
      this.dropdownOptions.filter(item => item.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
