import { DropdownItem } from '@app/components/dropdown-select/dropdown-select.component';
import { Shareholdings } from '@app/dto/ProjectCustodialListDto';

export class IProjectMailingDeliveryLabelInput {
  projectId: number;
  projectName: string;
  numberOfSets: number;
  numberOfSetsPerCusip: ProjectMailingDeliveryLabelDTOSetsPerCusip[];
  recipients: any[];
  recordDate: Date;
  meetingDate: Date;
  cusips: string[];
  setOfMaterials: DropdownItem[];
  jobDescription: string;
  specialInstructions: string;
  custodianName?: string;
}

export class ProjectMailingDeliveryLabelDTO {
  projectId: number;
  projectName: string;
  numberOfSets: number;
  numberOfSetsPerCusip: ProjectMailingDeliveryLabelDTOSetsPerCusip[];
  recipients: any[];
  recordDate: Date;
  meetingDate: Date;
  cusips: string[];
  setOfMaterials: DropdownItem[];
  jobDescription: string;
  specialInstructions: string;
  custodianName: string;

  public constructor(init?: Partial<IProjectMailingDeliveryLabelInput>) {
    Object.assign(this, {...init});
  }
}

export interface IProjectMailingIndividualDeliveryTicketInput {
  mailingDocumentRecipientSentId?: number;
  setOfMaterials: DropdownItem[];
  shareholdings: Shareholdings[];
  jobDescription: string;
  recordDate: Date;
  meetingDate: Date;
  specialInstructions: string;
  projectId: number;
  projectName: string;
  custodianName: string;
  comment: string;
  mailingDocumentRecipientId: number;

  shareHoldings?: Shareholdings[];
  printDate?: Date;
  printUser?: string;
}

export class ProjectMailingIndividualDeliveryTicket {
  mailingDocumentRecipientSentId?: number;
  custodianName: string;
  jobDescription: string;
  meetingDate: Date;
  projectId: number;
  projectName: string;
  recordDate: Date;
  setOfMaterials: DropdownItem[];
  shareholdings: Shareholdings[];
  specialInstructions: string;
  comment: string;
  mailingDocumentRecipientId: number;
  barcode: string;
  printDate?: Date;
  printUser?: string;
  cost?: number;
  rate?: number;
  additionalRate?: number;
  weight?: number;
  envelopes?: number;
  boxes?: number;
  pallettes?: number;
  carrier?: string;
  service?: string; 
  shipDate?: Date;

  public constructor(init?: Partial<IProjectMailingIndividualDeliveryTicketInput>) {
    if (init) {
      delete init['recipients'];
      delete init['cusips'];
    }
    Object.assign(this, {
      ...init,
      ...(init && init.shareHoldings && {shareholdings: init.shareHoldings})
    });
  }
}

export class ProjectMailingDeliveryLabelDTOSetsPerCusip {
  cusip: string;
  numberOfSets: number;
  numberOfSetsSent: number;
}
