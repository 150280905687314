import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {ExternalAdvisorsDialogComponent} from '@app/admin/external-advisors/external-advisors-dialog/external-advisors-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchGridService} from '@app/service/search-grid/search-grid.service';
import {HttpClient} from '@angular/common/http';
import {LookupFilter, Utils} from '@app/helper/utils';
import {StartupdataproviderService} from '@app/service/startupdataprovider.service';
import {Lookup} from '@app/dto/Lookup';
import {GridFilterItem, GridFilterModel} from '@app/components/grid-filter/grid-filter.models';
import {NavigationTriggerService} from '@app/services/admin/navigation-trigger.service';
import {RoleGuardService} from '@app/auth/role-guard.service';

@Component({
  selector: 'app-external-advisors-list',
  templateUrl: './external-advisors-list.component.html',
  styleUrls: ['./external-advisors-list.component.scss']
})

export class ExternalAdvisorsListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'advisorTypeName', 'country'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  filterModel = new GridFilterModel();

  dataSource = new MatTableDataSource();
  searchService: SearchGridService;
  util = new Utils(this.startupdataproviderService);
  advisorTypes: Lookup[] = [];
  defaultValue: string = 'All';
  defaultOption: Lookup = new Lookup();
  hasAccess: boolean =false;

  constructor(private httpClient: HttpClient,
              private startupdataproviderService: StartupdataproviderService,
              private route: ActivatedRoute,
              private roleGuardService: RoleGuardService,
              private navigationTriggerService: NavigationTriggerService,
              private router: Router,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.hasAccess = this.roleGuardService.hasAccess("ADMIN","EDIT")
    this.filterModel.gridFilterItems = [];
    this.navigationTriggerService.setSubMenu(false);

    this.searchService = new SearchGridService(this.paginator,
      this.dataSource,
      this.httpClient,
      'externaladvisors/get-list',
      this.filterModel);

    this.util.getLookupByType(LookupFilter.EXTADV).subscribe(data => {
      this.defaultOption.fieldLabel = this.defaultValue;
      this.defaultOption.fieldValue = this.defaultValue;

      this.advisorTypes.push(this.defaultOption);
      this.advisorTypes.push(...data);
    });
  }

  ngOnDestroy(): void {
    this.searchService.unSubscribe();
  }

  onEditLinkClick(element) {
    this.router.navigate(['../'+ element.externalAdvisorId + '/general'], {relativeTo: this.route});
  }

  filterGrid(value: Lookup) {
    const filterItem = new GridFilterItem();
    if (this.defaultValue !== value.fieldValue) {
      filterItem.filterPorp = 'AdvisorType';
      filterItem.value = [value.lookUpId.toString()];
    }

    this.filterModel = new GridFilterModel();
    this.filterModel.gridFilterItems = [filterItem];
    this.searchService.onFilterChanged(this.filterModel);
  }

  addExternalAdvisor() {
    const dialogRef = this.dialog.open(ExternalAdvisorsDialogComponent, {
      data: {},
      width: '1100px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onEditLinkClick(result);
      }
    });
  }
}
