import {Injectable, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import { map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ResolutionClassificationDTO} from '@app/dto/ResolutionClassificationDTO';

@Injectable({
  providedIn: 'root'
})
export class ResolutionsClassificationService implements OnInit {

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {

  }

  public getData(): Observable<ResolutionClassificationDTO[]> {
    return this.httpClient.get<ResolutionClassificationDTO[]>(environment.serverUrl + 'lookups/iss-classifications')
      .pipe(
        map(items => {
          return items.map(item => Object.assign({
            name: `(${item.code}) ${item.subcategory}`,
            category: item.proposalName,
            id: item.issClassificationId
          }, item));
        })
    );
  }
}
