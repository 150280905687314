export class Contact {
    contactId: number;
    mappingId: number;
    relatedEntityTypeId: number;
    contactTypeId?: number;
    contactSource: string;
    legalEntity: string;
    title: string;
    name: string;
    surname: string;
    fullname: string;
    contactName: string;
    areasOfResponsibility: string[];
    jobTitle: string;
    category: string;
    sector: string;
    geographicRegion: string;
    contactRating: string;
    contactPreference: string;
    reportsReceived?: number[];
    street: string;
    other: string;
    postalZipCode: string;
    city: string;
    state: string;
    country: string;
    directPhoneNo: string;
    directExtension: string;
    cellphone: string;
    departmentPhoneNo: string;
    departmentExtension: string;
    fax: string;
    emailPersonal: string;
    emailDepartment: string;
    emailOther: string;
    comment1: string;
    comment2: string;
    isoStr:string;
    insertTime: string;
    insertedBy: number;
    updateTime: string;
    updateBy: number;
    active: number;
    userId: number;
}
