import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { PpBreadcrumbsResolver, Breadcrumb } from 'pp-breadcrumbs';
import { ProjectsService } from '@app/services/projects.service';
import { Project } from '@app/dto/Project';
import { take, mergeMap, catchError } from 'rxjs/operators';

@Injectable()
export class ProjectsResolver extends PpBreadcrumbsResolver {
  constructor(private projectsService: ProjectsService) {
    super();
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Promise<Breadcrumb[]> | Breadcrumb[] {
    const projectId = route.params['id'];
    if (projectId == 0) {
      return of([{
        text: 'Add new project',
        path: this.getFullPath(route)
      }]);
    } else {
      return this.projectsService.getProject(projectId, true).pipe(catchError(error => {
        return EMPTY;
      }), mergeMap(data => {
        if (data) {
          return of([{
            text: data.name,
            path: this.getFullPath(route)
          }]);
        } else {
          return EMPTY;
        }
      })
      );
    }
  }
}
