export class InvestorIntelEngagementDto {
  investorId: number;
  accessibility: string = '';
  activism: string = '';
  engagementPolicy: string = '';
}

export class InvestorIntelLogisticalIntelligenceDto {
  investorId: number;
  logisticalIntelligence: string = '';
}

export class InvestorIntelInvestmentFunds {
  investorId: number;
  morningStarESGFunds?: number;
  bloomingESGFunds?: number;
  cdpESGFunds?: number;
  otherESGFunds?: number;
  otherESGFundsDetails?: string;
  totalSustainableFunds?: number;
  totalSustainableFundsCurrency?: string;
  eurosifTransparencyCode: boolean;
  eurosifFunds?:number;
  fundEcomarket?:number;
  exposureToFossilFuels?:number;
  exposureToFossilFuelsCurrency:string;
  exposureToFossilFuelsSourceInformation:string;
}

export class InvestorReportsExistence {
  reportType: string;
  documentsExists: boolean;
  id: number;
}