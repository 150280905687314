import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { UserService } from '@app/services/user.service';
import { AppConstants } from '@app/app.constants';
import { Roles } from '@app/enums/RolesEnum';
import { HttpClient } from '@angular/common/http';
import { IdName } from '@app/investor/investordetail/investor-intel-esg/investor-intel-esg-survey/IdName';
import { environment } from '@src/environments/environment';

@Injectable()
export class RoleGuardService implements CanActivate {

  private static readonly LEVELS_UP = 3;

  constructor(public router: Router, 
    public userService: UserService, 
    private appConstants: AppConstants,
    private http: HttpClient) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.checkPermissions(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {

    return this.canActivate(route);
  }

  checkPermissions(route) {
    const roleName = this.userService.getUserRoleName();
    let { section } = route.data;
    const { subsection } = route.data;

    if (typeof section === 'undefined') {
      // This will check parents deeply until find parent with defined data.section but only 3 levels up
      let i = 0;
      let _parent = route.parent;
      do {
        section = this.getParentSection(_parent);
        _parent = _parent.parent;
        i++;
      } while (typeof section === 'undefined' && _parent && i <= RoleGuardService.LEVELS_UP);
    }

    const expectedRoles = this.appConstants.getRolesPerSection(section, subsection);

    if (expectedRoles.length > 0 && expectedRoles.indexOf(<Roles>roleName) === -1) {
      this.router.navigate([AppConstants.DASHBOARD_ROUTE_PATH]);
      return false;
    }
    return true;
  }

  private getParentSection = (parent) => {
    if (!parent) {
      return undefined;
    }
    const { data: { section } } = parent;
    return section;
  };

  public hasAccess = (section, subsections) => {
    const roleName = this.userService.getUserRoleName();
    const expectedRoles = this.appConstants.getRolesPerSection(section, subsections);
    if (expectedRoles.length > 0 && expectedRoles.indexOf(<Roles>roleName) === -1) {
      return false;
    }
    return true;
  };

  public hasAccessByProjectType = (section, subsections, projectType) => {
    if (this.hasAccess(section, subsections)) {
      if (this.userService.getUserRoleName() === Roles.US_PSC && projectType.toLowerCase() != 'search & delivery') {
        return false;
      }
    } else {
      return false;
    }  
    
    return true;
  };
}
