import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from 'src/app/services/user.service';
import {DocumentRelatedEntityType} from '@app/enums/DocumentRelatedEntityType';
import {ProjectsService} from '@app/services/projects.service';
import {environment} from '../../../environments/environment';
import {AppConstants} from '@app/app.constants';
import {combineLatest, Subscription} from 'rxjs';
import {MessageService} from '@app/services/message.service';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { Roles } from '@app/enums/RolesEnum';

@Component({
  selector: 'app-project-landing',
  templateUrl: './project-landing.component.html',
  styleUrls: ['project-landing.component.css']
})

export class ProjectLandingComponent implements OnInit, OnDestroy  {
  index = 0;
  clientid: number;
  rolename: string;
  userid: number;
  coverage: string;
  routeSub: any;
  isLinkDisabled = false;
  isProduction = true;
  showGeneral;
  showActivity;
  showFinancial;
  showProjectDocument;
  showProjectCustodianRecon;
  showProjectCustodianList = false;
  showInvestorData;
  isPSC = false;
  showAgenda;
  documentRelatedEntityType: DocumentRelatedEntityType = DocumentRelatedEntityType.Project;
  userHasDashboardAccess: boolean;
  msgSubscription: Subscription;
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private router: Router,
              private userService: UserService,
              private projectService: ProjectsService,
              private messageService: MessageService,
              private roleGuardService: RoleGuardService) {
    this.isProduction = AppConstants.ENVIRONMENT_PRODUCTION_NAME === environment.name;
  }

  ngOnInit() {

    this.rolename = this.userService.getUserRoleName();
    this.userid = +0;
    this.coverage = this.userService.getUserCoverage();

    this.routeSub = this.route.params.subscribe((params) => {
      this.index = +params['id'];
      this.clientid = +params['clientid'];
      this.isLinkDisabled = this.index === 0;

      this.showGeneral = true;
      this.showActivity = true;
      this.showFinancial = true;
      this.showProjectDocument = true;
      this.showProjectCustodianRecon = true;
      this.showInvestorData = true;
      this.showAgenda = true;

      if(this.index != 0){
          this.projectService.getProject(this.index.toString(), true).subscribe((value) => {
          if (value.projectType === AppConstants.PROJECT_TYPES.SEARCHDELIVERY && this.rolename === Roles.US_PSC) {
            this.showAgenda = false;
            this.showInvestorData = false;
            this.showProjectCustodianRecon = false;
            this.isPSC = true;
            this.userHasDashboardAccess = true;
          } else {
            this.showProjectCustodianList = value.projectStatus === 'Active';
            this.userHasDashboardAccess = value.userHasDashboardAccess;
          }
        });
      }
    });

    this.initMessageService();
  }

  initMessageService() {
    this.msgSubscription = this.messageService.getMessage().subscribe(data => {
      this.showProjectCustodianList = !!data;
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.msgSubscription.unsubscribe();
  }
}
