import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentDto } from '../DocumentDto';
import { DocumentsService } from '@app/services/documents.service';
import { DocumentDialogConfiguration } from '../document-dialog-configuration';
import { LookupFilter } from '@app/helper/utils';
import { CustomFieldType } from '@app/enums/CustomFieldType';

@Component({
  selector: 'app-load-file-general',
  templateUrl: './load-file-general.component.html',
  styleUrls: ['./load-file-general.component.scss']
})
export class LoadFileGeneralComponent implements OnInit {
  dto: DocumentDto = new DocumentDto();
  @Input() configuration: DocumentDialogConfiguration= new DocumentDialogConfiguration();
  @Input() isPopup: boolean = true;
  @Input() descriptionLabel: string = 'FILE DESCRIPTION';

  showCustomField : boolean=false;
  showLink: boolean;
  placeholder: string;
  listType: LookupFilter;
  multiSelect: boolean = false;

  constructor(private spinner: NgxSpinnerService,
    private documentsService: DocumentsService) { }

  ngOnInit() {
    this.showCustomField = this.configuration.customField.showField;
    this.showLink = this.configuration.showLink;
    this.placeholder=this.configuration.customField.fieldName;
    this.listType= this.configuration.customField.listType;
    this.multiSelect = this.configuration.customField.fieldType === CustomFieldType.MultiSelect;
    this.documentsService.documentEmitted$.subscribe(data => {
      if (!data.dto) {
        this.dto = new DocumentDto();
      }
    })
  }

  public readUrl(event) {
    const input = event.target;
    this.spinner.show();
    if (input.files && input.files[0]) {
      this.dto.file = input.files[0];
      this.dto.fileName = this.dto.file.name;
      this.documentsService.emitDocument({ dto: this.dto });
    }

    this.spinner.hide();
  }

  public dragOver(event): void {
    if (event.dataTransfer.items.length > 0) {
      document.querySelectorAll('.drag-n-drop').item(0).classList.add('over');
    }
  }

  public dragOut(event): void {
    if (event.dataTransfer.items.length > 0) {
      document.querySelectorAll('.drag-n-drop').item(0).classList.remove('over');
    }
  }

  onDescriptionChange() {
    this.documentsService.emitDocument({ dto: this.dto });
  }

  onLinkChange(){
    this.documentsService.emitDocument({ dto: this.dto });
  }
  fieldChange(value){
    this.dto.link=value;
    this.documentsService.emitDocument({ dto: this.dto });
}

  listSelectionChange(value) {
    this.dto.customField = value;
    this.documentsService.emitDocument({ dto: this.dto });
  }

}
