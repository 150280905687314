export class Lookup {
    lookUpId: number | string;
    fieldType: string;
    fieldLabel: string;
    fieldValue: string;
    nbrAgainst?: number;
    color?: string;

  public constructor(init?: Partial<Lookup>) {
    Object.assign(this, init);
  }
}
