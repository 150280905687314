import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project } from '@app/dto/Project';
import { ProjectsService } from '@app/services/projects.service';
import { ActionsService } from '@app/services/actions.service';
import { environment } from '../../../environments/environment';
import { AppConstants } from '@app/app.constants';
import { UserService } from '@app/services/user.service';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { Subscription } from 'rxjs';
import { CustodianReportDialogComponent } from './custodian-report-dialog/custodian-report-dialog.component';
import { MatDialog } from '@angular/material';
import { Roles } from '@app/enums/RolesEnum';
import { CustodianVotesImportDialogComponent } from '@app/dashboard/dashboardau/custodian-votes-import-dialog/custodian-votes-import-dialog.component';

@Component({
  selector: 'app-dashboard-detail-list-au',
  templateUrl: './dashboard-detail-list-au.component.html',
  styleUrls: ['./dashboard-detail-list-au.component.scss']
})

export class DashboardDetailListAUComponent implements OnInit {
  public projectId: string;
  public selectedTabIndex: number;
  public actionCount = 0;
  public projectModel: Project;
  public shareClassifications: TabItem[] = [];
  public isProduction = false;
  public topNavProjectsVisible = false;
  public isUS = false;
  public mailingByMs = false;
  public isProxyCategory = false;
  public hasTabsPermission = false;
  public hasMailingNoboTabsPermission = false;
  public isPSCProjectType = false;
  public keyProjectInformation;
  /*
    temporarily store viewProjectButton view permission to avoid
    setting values in different event loops
  */
  public tempPerm: boolean;

  constructor(private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private actionsService: ActionsService,
    private userService: UserService,
    private roleGuardService: RoleGuardService,
    public dialog: MatDialog) {
    this.isProduction = AppConstants.ENVIRONMENT_PRODUCTION_NAME === environment.name;
  }

  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.projectId = params['id'];
      this.getProjectData(this.projectId);
      this.getActionCount(this.projectId);
      this.getProjectClassifications(this.projectId);
    });

    this.userService.loggedInUser$.subscribe(user => {
      this.tempPerm = this.userService.checkPermissionAssigned(user, 'TopNavProjectsVisible');
    });
  }

  private setTabsVisibility(data: any): void {
      this.hasTabsPermission = this.roleGuardService.hasAccess('DASHBOARD', 'TABS');
      this.hasMailingNoboTabsPermission = this.roleGuardService.hasAccess('DASHBOARD', 'TABS_NOBO_MAILING');
      this.isUS = data.marketCoverage === 'US';
      this.isProxyCategory = data.isProxyCategoryType;
      this.mailingByMs = data.mailingByMS;
      this.topNavProjectsVisible = this.tempPerm;

      if (data.projectType === AppConstants.PROJECT_TYPES.SEARCHDELIVERY && this.userService.getUserRoleName() === Roles.US_PSC) {
        this.isPSCProjectType = true;
        this.hasMailingNoboTabsPermission = false;
      }

    setTimeout(() => this.setDefaultTabSelection(), 500);
  }

  private setDefaultTabSelection(): void {
    if (!this.isUS) {
      this.selectedTabIndex = 1;
    } else {
      this.selectedTabIndex = 0;
    }
  }

  private getProjectData(projectId): Subscription {
    return this.projectsService.getProject(projectId).subscribe(
      data => {
        this.projectModel = data;
        this.keyProjectInformation = this.setKeyProjectInformation(data);
        this.setTabsVisibility(data);

      },
      err => {
        console.log(err);
      }
    );
  }

  private setKeyProjectInformation(data: Project): any {
    const meetingDate = data.projectEvents.find(event => event.description.toLowerCase() === 'meeting date');
    const recordDate = data.projectEvents.find(event => event.description.toLowerCase() === 'record date');
    const mailingDate = data.projectEvents.find(event => event.description.toLowerCase() === 'mailing date');

    return {
      tickerSymbol: data.tickerSymbol,
      meetingDate: meetingDate ? this.formatDate(meetingDate.date) : 'tbd',
      recordDate: recordDate ? this.formatDate(recordDate.date) : 'tbd',
      mailingDate: mailingDate ? this.formatDate(mailingDate.date) : 'tbd',
    };
  }

  private formatDate(dateString: Date): string {
    const date = new Date(dateString);
    const day = `0${date.getDate()}`.slice(-2);

    return `${day}/${date.toLocaleString('en-US', { month: 'short' })}/${date.getFullYear()}`;
  }

  private getActionCount(projectId): Subscription {
    return this.actionsService.getActionCount(projectId).subscribe(
      data => {
        this.actionCount = data;
      },
      err => {
        console.log(err);
      }
    );
  }

  private getProjectClassifications(projectId): Subscription {
    return this.projectsService.getProjectShareClassifications(projectId).subscribe(
      data => {
        this.shareClassifications = [];
        data.forEach((item, index) => {
          const tabItem = new TabItem();
          tabItem.title = item;
          tabItem.isSelected = index === 0;
          this.shareClassifications.push(tabItem);
        });
      }
    );
  }

  public onSelectCampaignDashboardTab(tabNumber: number): void {
    this.shareClassifications[tabNumber].isSelected = true;
  }

  generateReport() {
    const dialogRef = this.dialog.open(CustodianReportDialogComponent, {
      data: {
        projectId: this.projectId,
      },
      width: '30%'
    });
  }

  openUploadCustodianVotesDialog() {
    const dialogRef = this.dialog.open(CustodianVotesImportDialogComponent, {
      data: {
        projectId: this.projectId,
        onComplete: () => {
          dialogRef.close();
        },
      },
      maxHeight: 'calc(100vh - 90px)',
      height : 'auto',
      width: 'auto',
      minWidth: '600px',
    });
  }
}

export class TabItem {
  title: string;
  isSelected: boolean;
}
