import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SearchGridService } from '@app/service/search-grid/search-grid.service';
import { GridFilterItem, GridFilterModel } from '@app/components/grid-filter/grid-filter.models';
import { HttpClient } from '@angular/common/http';
import { DashboardAddEditMailingDocRecipientComponent } from './doc-recipient-add-edit/dashboard-mailing-doc-recipient-add-edit.component';
import { ProjectMailingDocumentRecipientDTO } from '@app/dto/ProjectMailingDocumentRecipientDTO';
import { DashboarMailingRecipientDeliveryLabelComponent } from './recipient-delivery-label/recipient-delivery-label.component';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { IndividualDeliveryTicketComponent } from '@app/dashboard/dashboardau/dashboard-mailing-recipient-table/individual-delivery-ticket/individual-delivery-ticket.component';
import * as FileSaver from 'file-saver';
import { AlertService } from '@app/services/alert.service';

import { sanitizeFileName } from '@app/utility/Strings';
// tslint:disable-next-line: max-line-length

@Component({
  selector: 'app-dashboard-mailing-recipient',
  templateUrl: './dashboard-mailing-recipient.component.html',
  styleUrls: ['./dashboard-mailing-recipient.component.scss']
})
export class DashboardMailingRecipientComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['recipientName', 'setsRequired', 'setsPendingShipment', 'setsSent', 'mailingDetails', 'lastUpdate', 'actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  showGrid = true;
  _dataSource = new MatTableDataSource();
  canEdit = true;
  noItemsText = 'Mailing Document Recipients';
  searchService: SearchGridService;
  filter: GridFilterModel = new GridFilterModel({
    gridFilterItems: [
      new GridFilterItem({ filterPorp: 'ProjectId', value: [''] })
    ]
  });
  customSize = 20;
  _projectId: number;
  projectName: string;

  @Input() set projectId(projectId) {
    this._projectId = projectId;
  }
  get projectId(): number {
    return this._projectId;
  }

  constructor(public dialog: MatDialog,
    private httpClient: HttpClient,
    private dashboardService: DashboardService,
    private dialogsService: DialogsService,
    private alertService: AlertService,) {
  }

  ngOnInit() {
    this.filter.gridFilterItems[0] = new GridFilterItem({ filterPorp: 'ProjectId', value: [`${this.projectId}`] });
    this.getData();
  }

  ngOnDestroy(): void {
    if (this.searchService) {
      this.searchService.unSubscribe();
    }
  }

  getData() {
    this.searchService = this.searchService ||
      new SearchGridService(
        this.paginator,
        this._dataSource,
        this.httpClient,
        'dashboardsmailing/mailing-document-recipient/all',
        this.filter,
        null,
        null,
        false,
        false
      );

    this.searchService.allData.subscribe(data => {
      if (typeof data.items !== 'undefined' && data.items.length > 0) {
        this.projectName = data.items[0].projectName;
        this.showGrid = true;
        data.items = data.items.map(item => {
          item.custodianPath = `/admin/${item.globalCustodianId ? 'gc' : 'lc'}/${item.globalCustodianId || item.localCustodianId}`;
          return item;
        })
      } else {
        this.showGrid = false;
      }
    });

    this.searchService.reloadItems();
  }

  onAddBtnClick() {
    this.openAddEditMilingDocRecipientDialog({ projectId: this.projectId });
  }

  onDeliveryLabelClick(){
    this.openDeliveryLabelDialog({ projectId: this.projectId });
  }

  openAddEditMilingDocRecipientDialog(data) {
    const dialogRef = this.dialog.open(DashboardAddEditMailingDocRecipientComponent, {
      data,
      minWidth: '550px',
      maxWidth: '1100px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(x => {
      this.searchService.reloadItems();
    });
  }

  openDeliveryLabelDialog(data) {
    const dialogRef = this.dialog.open(DashboarMailingRecipientDeliveryLabelComponent, {
      data,
      minWidth: '60%',
      maxWidth: '90%',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(x => {
      this.searchService.reloadItems();
    });
  }

  deliveryTicket(element: ProjectMailingDocumentRecipientDTO) {
    this.dialog.open(IndividualDeliveryTicketComponent, {
      data: element,
      width: '700px',
    }).afterClosed().subscribe(result => {
      if (result) {
        this.searchService.reloadItems();
      }
    });
  }

  onEdit(element: ProjectMailingDocumentRecipientDTO) {
    this.openAddEditMailingDocumentDialog({ ...element });
  }

  onDelete(element){
    this.dialogsService.confirm('Recipient', `Are you sure you want to delete ${element.recipientName}? Deletion will remove all associated actions for sending!`, {
      okButton: {
        text: 'DELETE',
        class: 'ms-btn-red-delete',
        icon: 'ms-delete-red-icon'
      },
      cancelButton: {
        text: 'CANCEL'
      }
    });

    this.dialogsService.close$.subscribe(res => {
        if(res) {
          this.dashboardService.deleteMailingRecipient(element.mailingDocumentRecipientId).subscribe(result => {
            this.searchService.pageIndex = 0;
            if(result == true) this.getData();
          });
        }
     });
  }

  openAddEditMailingDocumentDialog(data) {
    const dialogRef = this.dialog.open(DashboardAddEditMailingDocRecipientComponent, {
      data,
      minWidth: '550px',
      maxWidth: '1100px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(x => {
      this.searchService.reloadItems();
    });
  }

  async onExportClick(){
    try {
      const blobResult = await this.dashboardService.exportAllShipments(this.projectId);
      FileSaver.saveAs(blobResult, sanitizeFileName(this.projectName + '-shipment list-' + new Date().toISOString() + '.csv'));
    } catch (e) {
      this.alertService.alert('Error while downloading file!');
    }    
  } 
}
