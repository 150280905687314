import { AfterViewInit, Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProjectMailingIndividualDeliveryTicket } from '@app/dto/ProjectMailingDeliveryLabelDTO';
import { DropdownItem } from '@app/components/dropdown-select/dropdown-select.component';
import { LookupFilter, Utils } from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { first } from 'rxjs/operators';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-dashboard-mailing-individual-delivery-ticket-form',
  templateUrl: './individual-ticket-form.component.html',
  styleUrls: ['./individual-ticket-form.component.scss']
})
export class IndividualTicketFormComponent implements OnInit, DoCheck, AfterViewInit {
  allSetOfMaterials: DropdownItem[] = [];
  util = new Utils(this.startupdataproviderService);
  _model: ProjectMailingIndividualDeliveryTicket;
  @Input() set model(item: ProjectMailingIndividualDeliveryTicket) {

    if (item) {
      if (!this.edit) {
        this.allSetOfMaterials = item.setOfMaterials;
      }
      this._model = item;
    }
  }

  @Input() edit = false;
  @Output() modelChange = new EventEmitter<ProjectMailingIndividualDeliveryTicket>();
  @ViewChild('dataForm') dataForm: NgForm;

  constructor(private readonly startupdataproviderService: StartupdataproviderService,) {
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    if (this.edit) {
      this.util.getLookupByType(LookupFilter.PROJECT_MAILING_DOC).pipe(
        first()
      ).subscribe(data => {
        this.allSetOfMaterials = data.map(item => new DropdownItem({id: item.lookUpId.toString(), name: item.fieldValue}));
      });
    } else {

    }
  }

  onSetOfMaterialsChange($event: DropdownItem[]): void {
    this._model.setOfMaterials = $event;
  }

  public get isValid() {
    return this.dataForm.valid;
  }

  ngDoCheck(): void {
    this.modelChange.emit(this._model);
  }

  ngAfterViewInit(): void {
    this.onSetOfMaterialsChange(this.allSetOfMaterials);
  }

}
