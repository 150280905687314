import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DocumentRelatedEntityType} from '@app/enums/DocumentRelatedEntityType';
import {DocumentConfiguration} from '@app/components/documents/document-configuration';
import {RoleGuardService} from '@app/auth/role-guard.service';

@Component({
  selector: 'app-initiative-documents',
  templateUrl: './initiative-documents.component.html',
  styleUrls: ['./initiative-documents.component.scss']
})
export class InitiativeDocumentsComponent implements OnInit {
  id: string;
  documentRelatedEntityType = DocumentRelatedEntityType.Initiative;
  configuration: DocumentConfiguration = new DocumentConfiguration();
  hasAccess: boolean;

  constructor(private route: ActivatedRoute, private roleGuardService: RoleGuardService) {
  }

  ngOnInit() {
    this.hasAccess = this.roleGuardService.hasAccess('ADMIN', 'EDIT');
    this.route.parent.params.subscribe((params) => {
      this.id = params['id'];
    });

    this.configuration.documentComponentOuterDivMargins = '';
  }
}
