import {Component, OnInit, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpServiceHelper} from './common/HttpServiceHelper';
import {AlertService} from './services/alert.service';
import {Alert} from './models/basic-structures';
import {Subscription} from 'rxjs';

import {User} from './dto/User';
import {Router} from '@angular/router';
import {PpBreadcrumbsService, Breadcrumb} from 'pp-breadcrumbs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'MS Proxy';
  cnt: number = 0;
  appname: string = 'MSPLATFORMCRM';
  alertDisplayTime: number = 5000;

  /*Begin Header section */
  alerts: Alert[] = [];
  subscription: Subscription;

  /*End Header Section */

  constructor(private http: HttpClient,
              private router: Router,
              private httpService: HttpServiceHelper,
              private alertService: AlertService,
              private breadcrumbsService: PpBreadcrumbsService) {
    this.subscription = this.alertService.getAlert().subscribe(alert => {
      this.alerts.push(alert);
      if (!alert.forceManualClose)
      {
        setTimeout(() => this.closeAlert(alert)
        ,this.alertDisplayTime);
      }
    });

    this.breadcrumbsService.postProcess = (breadcrumbs: Breadcrumb[]) => {
      if (breadcrumbs.length) {
        breadcrumbs.unshift({ text: `Home`, path: '/' });
      }
      return breadcrumbs;
    };
  }

  closeAlert(alert: Alert): void {
    if (this.alerts && this.alerts.length > 0) {
      let tmpDeleteIdx: number = this.alerts.findIndex(x => x.message == alert.message);
      if (tmpDeleteIdx > -1) {
        this.alerts.splice(tmpDeleteIdx, 1);
      }
    }
  };

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
