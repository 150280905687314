export class OciExportSetUpDialogOptionsModel {
  cusips: string[];
  investorTypes: { name: string; value: number }[];
  sources: { name: string; value: number }[];
}

export class OciExportSetUpDialogSelectedOptionsModel {
  cusips: string[];
  investorTypes: number[];
  sources: number[];
}

export class OciExportSetUpDialogConfirmedOptionsModel {
  result: boolean;
  cusips: string[];
  investorTypes: { name: string; value: number }[];
  sources: { name: string; value: number }[];
}

export interface SettingsInvestorTypeInterface {
  text: string;
  value: string | number;
}

export class OciExportSetUpConfiguration{
  nextButtonText:string;
  header:string;
}