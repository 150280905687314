export class BeneficiariesList {
    investorId: number;
    investor: string;
    investorFactsetId: string;
    investorCountry: string;
    assetManager: string;
    assetManagerId: number;
    assetManagerFactsetId: string;
    fundId: number;
    fund: string;
    fundFactsetId: string;
    formattedName: string;
    responsibilityUser: any;
}
