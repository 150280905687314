import {Shareholdings} from '@app/dto/ProjectCustodialListDto';

export class CustodianDashboardDetailDTO {
  projectName: string;
  custodianName: string ;
  country: string;
  bankBroker: string;
  parentName: string | null;
  globalCustodianId?: number | null;
  localCustodianId?: number | null;
  parentId?: number | null;
  custodianType: string;
  custodianShares : CustodianSharesDTO;

  public constructor(init?: Partial<CustodianDashboardDetailDTO>) {
    Object.assign(this, init);
  }
}

export class CustodianSharesDTO
{
  total? : number | null;
  totalGlobalHoldings?: number | null;
  sharesDetail: Shareholdings  [];
}
