import {Component, Input, OnInit} from '@angular/core';
import {ResolutionClassificationCustomFieldDto} from '@app/dto/ResolutionClassificationCustomFieldDto';
import {AlertService} from '@app/services/alert.service';
import {AddEditCustomFieldComponentData} from './add-edit-custom-field/AddEditCustomFieldComponent';
import {MatDialog} from '@angular/material';
import {AddEditCustomFieldComponent} from './add-edit-custom-field/add-edit-custom-field.component';
import {DialogsService} from '@app/services/dialogs/dialogs.service';
import {DeleteProjectTemplate} from '@app/services/dialogs/dialog-delete-template.config';
import {ResolutionClassificationCastumFieldsService} from '@app/services/resolution-classification-custom-fields.service';
import {CustomFieldType} from '@app/enums/CustomFieldType';
import {CopyCustomFieldsTemplate} from '@app/services/dialogs/dialog-copy-template.config copy';
import {ResolutionClassificationDTO} from '@app/dto/ResolutionClassificationDTO';
import {RoleGuardService} from '@app/auth/role-guard.service';

@Component({
  selector: 'app-resolution-custom-fields',
  templateUrl: './resolution-custom-fields.component.html',
  styleUrls: ['./resolution-custom-fields.component.scss']
})
export class ResolutionCustomFieldsComponent implements OnInit {

  @Input() classificationId: number;
  eCustomFieldType = CustomFieldType;
  classificationToCopy: ResolutionClassificationDTO;
  classificationToCopyId: number;

  customFields: ResolutionClassificationCustomFieldDto[] = [];
  hasAccess : boolean = false;

  constructor(
    private alertService: AlertService,
    public dialog: MatDialog,
    private dialogsService: DialogsService,
    private roleGuardService: RoleGuardService,
    private service: ResolutionClassificationCastumFieldsService
  ) { }

  ngOnInit() {
    this.hasAccess = this.roleGuardService.hasAccess('ADMIN', 'EDIT');
    this.getCustomFields();
  }

  getCustomFields(): void {
    this.service.getAllCutomFields(this.classificationId)
      .subscribe(
        data => {
          this.customFields = data;
        },
        err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });
  }

  addNewCustomField() {
    const dialogData = new AddEditCustomFieldComponentData();
    dialogData.action = 'Add';
    dialogData.item = new ResolutionClassificationCustomFieldDto({ options: [] });
    dialogData.item.issClassificationId = this.classificationId;

    const dialogRef = this.dialog.open(AddEditCustomFieldComponent,
      {
        width: '600px',
        data: dialogData
      });

    dialogRef.afterClosed().subscribe(result => {
      this.getCustomFields();
    });
  }

  editCustomField(item: ResolutionClassificationCustomFieldDto) {
    const dialogData = new AddEditCustomFieldComponentData();
    dialogData.action = 'Update';
    dialogData.item = JSON.parse(JSON.stringify(item));

    const dialogRef = this.dialog.open(AddEditCustomFieldComponent,
      {
        width: '600px',
        data: dialogData
      });

    dialogRef.afterClosed().subscribe(result => {
      this.getCustomFields();
    });
  }

  onDeleteCustomField(item: ResolutionClassificationCustomFieldDto) {
    this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: 'Custom field', message: item.fieldName }) });
    this.dialogsService.close$.subscribe(res => {
      if (res) {
        this.service.deleteCustomField(item.id)
          .subscribe(
            data => {
              if (data) {
                this.getCustomFields();
                this.alertService.sendSuccess('Custom field is deleted.');
              }
            },
            err => { this.alertService.sendError(err.error.Message); });
      }
    });
  }

  classificationIdChanged(selctedClassification: ResolutionClassificationDTO) {
    this.classificationToCopy = selctedClassification;
  }

  copyParameterFields() {
    this.dialogsService.confirm(null, null, { template: CopyCustomFieldsTemplate({ message: this.classificationToCopy.name }) });

    this.dialogsService.close$.subscribe(res => {
      if (res) {
        this.service.copyCustomFields(this.classificationToCopy.issClassificationId, this.classificationId)
          .subscribe(
            data => {
              if (data) {
                this.getCustomFields();
              }
            },
            err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });
      }
    });
  }
}
