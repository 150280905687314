import { BeneficiaryType } from '@app/enums/BeneficiaryType';
import { Component, OnInit, ViewChild, Input, DoCheck, Output, EventEmitter, forwardRef } from '@angular/core';
import { NgModel, FormControl, ValidationErrors, ControlValueAccessor, Validator, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, BehaviorSubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { BeneficiaryService } from '@app/services/admin/beneficiary.service';
import { AlertService } from '@app/services/alert.service';
import { NameValueModel } from '@app/models/basic-structures';

@Component({
  selector: 'app-beneficiary-autocomplete',
  templateUrl: './beneficiary-autocomplete.component.html',
  styleUrls: ['./beneficiary-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BeneficiaryAutocompleteComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BeneficiaryAutocompleteComponent),
      multi: true
    }
  ]
})
export class BeneficiaryAutocompleteComponent implements OnInit, DoCheck, ControlValueAccessor, Validator {
  @ViewChild('currentBeneficiaryValue') public currentBeneficiaryValue: NgModel;
  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public beneficiaryType : BeneficiaryType;
  @Input() clearOnBlur = false;
  @Output() onSelectionChanged = new EventEmitter<NameValueModel>();
  filteredResults: NameValueModel[] = [];
  preselectedValue: NameValueModel ;
  selectedValue: NameValueModel;
  private innerValue: any = undefined;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  public beneficiaryValueChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private beneficiaryService: BeneficiaryService, private alertService:AlertService) {
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.onChangeCallback(v);
      this.innerValue = v;
    }
  }

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  validate(control: FormControl): ValidationErrors {
    const valid = this.currentBeneficiaryValue && this.currentBeneficiaryValue.valid;
    return valid ? null : { valid };
  }

  ngDoCheck() {
    this.onChangeCallback(this.value);
  }

  doSearch(value) {
    this.beneficiaryValueChanges.next(value);
  }

  public onTouched() {
    this.onTouchedCallback();
  }

  ngOnInit() {
    if (!this.innerValue) {
      this.innerValue = undefined;
    }
    
    this.initializeBeneficiaryFilters();
  }

  initializeBeneficiaryFilters() {
    this.beneficiaryValueChanges.pipe(
      debounceTime(500)
    ).subscribe((input) => {
        this.getBeneficiaries(input);
    });
  }

  onSelected(selectedBeneficiary: NameValueModel) {
    if(selectedBeneficiary){
      this.innerValue = selectedBeneficiary;
    }
    else{
      selectedBeneficiary = this.innerValue;
    }
    this.onSelectionChanged.emit(selectedBeneficiary);
  }

  onBlur(value) {
    if (this.clearOnBlur) {
      if (this.filteredResults.length==0) {
        this.innerValue = undefined;
        this.onSelectionChanged.emit({ name: "", value:""});
      }
    }
  }

  displayFn(autoCompleteResult: NameValueModel) {
    if (autoCompleteResult) {
        this.innerValue= autoCompleteResult;
        if(typeof(autoCompleteResult)==='object') {
            return autoCompleteResult.name;
        } 
        else {
          return autoCompleteResult;
        }
    }
  }

  getBeneficiaries(value:string){
    if(this.beneficiaryType == undefined || value.length <=2)
    {
      return;
    }
    this.beneficiaryService.getfilteredBeneficiaries(value, this.beneficiaryType).subscribe(data  => {
        this.filteredResults = [];
        this.filteredResults.push(...data.map(b =>{ return {name: b.beneficiary, value: b.beneficiaryId.toString()}}));
        },
        err => {
            this.alertService.sendError(' error ' + JSON.stringify(err));
        });
  }
}


