// #2586 - custom textarea directive, adding row and characters limitations
import { AfterViewChecked, AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appAddressTextarea]'
})
export class AddressTextareaDirective implements AfterViewChecked  {
  private initiated = false;
  @Input() limitPerRow = 40;
  @Input() maxRows = 7;

  // tslint:disable-next-line:max-line-length
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef,
              private hostElement: ElementRef) {
  }

  // tslint:disable-next-line:no-redundant-jsdoc
  /**
   * This prevent pasting of invalid data
   */
  @HostListener('paste', ['$event']) onPaste(event) {
    const value = event.clipboardData.getData('text/plain');
    if (this.validateIt(value, event)) {
      this.hostElement.nativeElement['value'] = value;
    }
    event.preventDefault();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const current: string = this.el.nativeElement.value;


    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key, current.slice(position)].join('');

    // We need to know when to allow pressing Enter and this to produce a new line and when have to be handled from the rest logic
    const nextRows = next.split('\n');
    const allowEnter = nextRows.length < this.maxRows;


    const controlOrCommand = (event.ctrlKey === true || event.metaKey === true);
    if (this.specialKeys.indexOf(event.key) !== -1 ||
      (event.key === 'Enter' && allowEnter) ||
      // Allow: Ctrl+A and Command+A
      (event.key === 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (event.key === 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (event.key === 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (event.key === 'x' && controlOrCommand)) {

      return;
    }

    this.validateIt(next, event);
  }

  // With this we remove the empty blank lines
  ngAfterViewChecked() {
    this.el.nativeElement.value = this.removeEmptyLine(this.el.nativeElement.value);

  }

  validateIt = (next, event) => {
    let errorInRow = false;
    // Because instead new line into the `next` property we have just the text 'Enter'
    // that is why we have to emulate a new line into the next text
    if (event.key === 'Enter' && String(next).match(new RegExp(/enter/gmi))) {
      next += '\n';
    }

    // Check if we have more thn the desired characters per some of the row
    // This actually block from adding more character per one row
    const rowsNew = next.split('\n');
    rowsNew.forEach((row, index) => {
      if (row.length > this.limitPerRow) {
        errorInRow = true;
      }
    });
    const blockEnterCurrent = rowsNew.length > this.maxRows;

    if (blockEnterCurrent || errorInRow) {
      event.preventDefault();

      return false;
    }

    return true;
  }
  removeEmptyLine = (text) => text.replace(/(\r?\n)\s*\1+/g, '$1');

}
