import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {StaticLookup} from '@app/helper/staticlookup';
import {Lookup} from '@app/dto/Lookup';
import {ProjectInvestorUploadDto} from './project-investor-upload-dto';
import {AlertService} from '@app/services/alert.service';
import * as XLSX from 'xlsx';
import {ProjectInvestorDataService} from '@app/services/project-investor-data.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {finalize} from 'rxjs/operators';
import {ProcessExcelFileService} from './process-excel-file.service';

@Component({
  selector: 'app-project-investor-upload-dialog',
  templateUrl: './project-investor-upload-dialog.component.html',
  styleUrls: ['./project-investor-upload-dialog.component.scss']
})
export class ProjectInvestorUploadDialogComponent implements OnInit {
  staticLookupService = new StaticLookup()
  templates: Lookup[];
  usageTypes: Lookup[];
  model: ProjectInvestorUploadDto;
  file: File;
  fileName: string;
  arrayBuffer: any;

  constructor(
    public dialogRef: MatDialogRef<ProjectInvestorUploadDialogComponent>,
    private alertService: AlertService,
    private projectInvestorService: ProjectInvestorDataService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.templates = this.staticLookupService.getInvestorUploadExcelTemplates();
    this.usageTypes = this.staticLookupService.getInvestorUploadUsageDataType();
    this.model = new ProjectInvestorUploadDto();
    this.model.projectId = this.data.projectId;
  }

  public readUrl(event) {
    const input = event.target;
    if (input.files && input.files[0]) {
      this.file = input.files[0];
      this.fileName = this.file.name;
      this.processExcelFile();
    }
  }

  public dragOver(event): void {
    if (event.dataTransfer.items.length > 0) {
      document.querySelectorAll('.drag-n-drop').item(0).classList.add('over');
    }
  }

  public dragOut(event): void {
    if (event.dataTransfer.items.length > 0) {
      document.querySelectorAll('.drag-n-drop').item(0).classList.remove('over');
    }
  }

  public upload() {
    this.spinner.show();
    this.projectInvestorService.uploadProjectInvestorData(this.model)
      .pipe(finalize(() => {
        this.spinner.hide();
        this.dialogRef.close();
      }))
      .subscribe(
        r => {
          this.alertService.sendSuccess('Successfully uploaded investor file!');
        },
        err => {
          this.alertService.sendError(err.error.Message);
        }
      );
  }

  public praseFile(){
    this.processExcelFile();
  }

  private processExcelFile() {
    if(!this.file || !this.model.excelTemplate){
      return;
    }

    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.spinner.show();
      this.arrayBuffer = fileReader.result;
      let data = new Uint8Array(this.arrayBuffer);
      let arr = new Array();
      for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      let bstr = arr.join('');
      let workbook = XLSX.read(bstr, { type: 'binary' });
      let first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];

      const excelService = new ProcessExcelFileService();

      if (this.model.excelTemplate === 'EU') {
        this.model.investors = excelService.readEUExcelFile(worksheet);
      } else {
        this.model.investors = excelService.readEAUExcelFile(worksheet);
      }

      this.spinner.hide();
    };

    fileReader.readAsArrayBuffer(this.file);
  }
}
