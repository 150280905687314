import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationTriggerService} from '@app/services/admin/navigation-trigger.service';

@Component({
  selector: 'app-admin-landing',
  templateUrl: './admin-landing.component.html',
  styleUrls: ['./admin-landing.component.css']
})
export class AdminLandingComponent implements OnInit {
  hasActiveChild = true;

  constructor(private router: Router, private route: ActivatedRoute,
              private navigationTriggerService: NavigationTriggerService) {

  }

  ngOnInit() {
    this.navigationTriggerService.setSubMenu(false);
  }

  onActivate(event: any) {
    if (event.isChild) {
      this.hasActiveChild = true;
    } else {
      this.hasActiveChild = false;
    }
  }
}
