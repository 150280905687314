import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { FilterContainerOptionsDTO } from '@app/components/table-filter-wrapper/interfaces';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs/Subject';

@Directive({
  selector: '[appTableFilter]'
})
export class TableFilterDirective implements OnDestroy, AfterViewInit, OnInit  {

  private storageKey;
  public removeEventListener: () => void;
  public filterApplied: boolean;
  @Input('appTableFilter') filterOptions: FilterContainerOptionsDTO;
  @Output() nestedComponentChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() filterChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() endpointEmitter: Subject<string> = new Subject<string>();

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private readonly storage: SessionStorageService
  ) { }

  ngAfterViewInit() {
    this.appendButton();
  }

  appendButton() {
    // Get parent of the original input element
    const element = this.elRef.nativeElement;

    const divElement = this.renderer.createElement('div');
    this.renderer.addClass(divElement, 'ms-filter-icon');

    const self = this;
    this.removeEventListener = this.renderer.listen(divElement, 'click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.nestedComponentChange.emit({event, options: self.filterOptions});
    });

    this.renderer.appendChild(element, divElement);
    this.renderer.removeAttribute(element, 'appTableFilter');
    this.renderer.addClass(element, 'ms-filtered-column');

  }

  ngOnDestroy() {
    this.removeEventListener();
  }

  ngOnInit(): void {
    this.endpointEmitter.subscribe(key => {
      this.storageKey = key;
      const filterData = this.storage.retrieve(key);
      const { filters = [] } = filterData;

      const filterIndex = filters.findIndex(_filter => _filter.fieldName === this.filterOptions.fieldName);
      if (filterIndex > -1) {
        const element = this.elRef.nativeElement;
        this.renderer.addClass(element, 'ms-filter-applied');
      }
    });

    this.filterChange.subscribe(input => {
      const element = this.elRef.nativeElement;
      this.filterApplied = input;
      if (input) {
        this.renderer.addClass(element, 'ms-filter-applied');
      } else {
        this.renderer.removeClass(element, 'ms-filter-applied');
      }
    });
  }
}
