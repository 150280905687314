import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {EsgPaAddEditComponent} from '@app/admin/esg-providers-and-associations/esg-pa-add-edit/esg-pa-add-edit.component';

@Component({
  selector: 'app-esg-pa-dialog',
  templateUrl: './esg-pa-dialog.component.html',
  styleUrls: ['./esg-pa-dialog.component.scss']
})
export class EsgPaDialogComponent implements OnInit {
  public _formValid = false;
  @ViewChild('esg') esg: EsgPaAddEditComponent;
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              private dialogRef: MatDialogRef<EsgPaDialogComponent>) {
  }

  ngOnInit() {
  }

  setFormValidation(valid) {
    this._formValid = valid;
  }

  formSaved(flag) {
    this.dialogRef.close(flag);
  }

  onClickReset() {
    this.esg.reset();
  }
}
