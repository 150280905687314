import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material';
import { ExportService } from 'src/app/service/export.service';
import { UserService } from 'src/app/services/user.service';
import { ProjectList } from 'src/app/dto/ProjectList';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { myProjectsFilters } from './dashboard-list-au.config';
import { FiltersService } from '@app/services/filters/filters.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-dashboard-list-au',
  templateUrl: './dashboard-list-au.component.html',
  styleUrls: ['dashboard-list-au.component.scss']
})

export class DashboardListAUComponent implements OnInit, AfterViewInit {
  public myProjectsFilters = myProjectsFilters;
  public dashboardprojects: ProjectList[] = [];
  public displayedColumns: string[] = [
    'name',
    'clientName',
    'type',
    'year',
    'market',
    'projectManager',
    'assistantManager',
    'startDate',
    'endDate',
    'status',
  ];
  public dataSource = new MatTableDataSource();
  public endpoint = 'Dashboards/projects';
  public canSeeProjects = false;
  public noItemsText = 'Projects';
  public showGrid = true;
  public pageSize = 10;

  constructor(
    private httpClient: HttpClient,
    private exportService: ExportService,
    private userService: UserService,
    private startupdataproviderService: StartupdataproviderService,
    private roleGuardService: RoleGuardService,
    private filtersService: FiltersService,
    private dashboardService: DashboardService) {
  }

  public ngOnInit(): void {
    this.canSeeProjects = this.roleGuardService.hasAccess('DASHBOARD', 'PROJECT_OVERVIEW');

    if (this.userService.getUserRoleName() === 'External_Client') {
      this.endpoint = `${this.endpoint}?email=${this.userService.getUserEmail()}`;
    }
  }

  public ngAfterViewInit(): void {
    const controllerName = 'dashboardFilters';

    forkJoin({
      statuses : this.filtersService.getFilterOptions(`${controllerName}/options/statuses`),
      markets : this.filtersService.getFilterOptions(`${controllerName}/options/markets`),
      types : this.filtersService.getFilterOptions(`${controllerName}/options/types`),
      years : this.filtersService.getFilterOptions(`${controllerName}/options/years`)
    }).subscribe((result) => {
      this.myProjectsFilters.status.options = result.statuses;
      this.myProjectsFilters.market.options = result.markets;
      this.myProjectsFilters.type.options = result.types;
      this.myProjectsFilters.year.options = result.years;
    });
  }

  public setTableData(data): void {
    this.dataSource.data = data.items;
  }

  public downloadExport(): void {
    this.dashboardService.getAllProjects().subscribe(
      data => {
        this.exportService.export(data, 'Projects');
      },
      err => console.error(err)
    );
  }
}
