import { RoleGuardService } from './../auth/role-guard.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientList } from '../dto/ClientList';
import {
  MatTableModule,
  MatSortModule, MatPaginatorModule,
  MatSelectModule, MatDatepicker, MatNativeDateModule,
  MatSort, MatTableDataSource, MatPaginator, PageEvent
} from '@angular/material';

import { Router, ActivatedRoute } from '@angular/router';
import { ExportService } from '../service/export.service';
import { UserService } from '../services/user.service';
import { SearchGridService } from '@app/service/search-grid/search-grid.service';

@Component({
  selector: 'app-clientlist',
  templateUrl: './client-list.component.html',
  styleUrls: ['client-list.component.scss']
})
export class ClientListComponent implements OnInit {
  clients: ClientList[] = [];
  hasPermission: boolean;
  displayedColumns: string[] = ['company', 'country', 'managedBy'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource();
  searchService: SearchGridService;
  routeSub;
  userid: number;
  coverage: string;

   // MatPaginator Output
  length: number = 0;
  pageSize: number = 50;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private exportService: ExportService,
    private userService: UserService,
    private roleGuardService : RoleGuardService ) {
  }

  ngOnInit() {
    this.hasPermission = this.roleGuardService.hasAccess('clients', 'CREATE');;
    this.searchService = new SearchGridService(this.paginator, this.dataSource, this.httpClient, 'clients/client-list');
}

ngOnDestroy(): void {
    this.searchService.unSubscribe();
}

downloadExport() {
    this.searchService.getAllItems().subscribe(
        data => {
            this.exportService.export(data.items, 'Clients');
        },
        err => console.error(err)
    );
}
}
