import { UserService } from './../../services/user.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActionsService } from '@app/services/actions.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectActivityGrid } from '@app/dto/ProjectActivityGrid';
import {
  ProjectActivityGridWithCounter
} from '@app/dashboard/dashboardau/dashboard-actions/dashboard-project-activity/ProjectProjectActivityGridWithCounter';
import { Observable, Subscription } from 'rxjs';
import { AppConstants } from '@app/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-current-activities',
  templateUrl: './current-activities.component.html',
  styleUrls: ['./current-activities.component.scss']
})
export class CurrentActivitiesComponent implements OnInit, OnDestroy {
  @Input() isPersonalDashboard: boolean;
  activities: ProjectActivityGrid[] = [];
  activitiesWithCounter: ProjectActivityGridWithCounter;
  isEdit = [];
  skip = 0;
  take = 3;
  activityStatusInProgress: string = AppConstants.PROJECT_ACTIVITY_STATUS.activityStatusInProgress;
  activityStatusCompleted: string = AppConstants.PROJECT_ACTIVITY_STATUS.activityStatusCompleted;
  activityStatuses: string[] = [
    'Not Started',
    'In Progress',
    'Completed',
    'Abandoned'
  ];
  loadMore = false;
  activitiesCounter: number;
  _projectId: string;

  private activityUpdatedSubscription: Subscription;

  noItemsText = 'Activities';
  showGrid = true;

  @Input() set projectId(projectId) {
    this._projectId = projectId;
  }

  get projectId() {
    return this._projectId;
  }

  constructor(private actionsService: ActionsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private userService: UserService,
    private spinnerService: NgxSpinnerService) {
    this.matIconRegistry.addSvgIcon('target',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/target.svg'));
  }

  ngOnInit() {
    this.getActivities();

    this.activityUpdatedSubscription = this.actionsService.activityUpdatedSubject.subscribe(activityUpdated => {
      this.getActivities();
    });
  }

  ngOnDestroy(): void {
    if (this.activityUpdatedSubscription) {
      this.activityUpdatedSubscription.unsubscribe();
    }
  }

  public getActivities() {
    const userEmail = this.userService.getUserEmail();
    let result: Observable<ProjectActivityGridWithCounter>;

    this.spinnerService.show('current-activities');
    result = this.isPersonalDashboard ? this.actionsService.getActivitiesPersonal(this.skip, this.take, userEmail) :
      this.actionsService.getActivities(+this.projectId, this.skip, this.take);
    result.subscribe(data => {
        this.clearActivities();
        this.activities.push(...data.items);
        this.activitiesCounter = data.totalResults;
        this.showGrid = data.items.length > 0;
        this.loadMore = this.activitiesCounter > this.take && this.activities.length < this.activitiesCounter;
        this.spinnerService.hide('current-activities');
      },
      (err) => {
        this.spinnerService.hide('current-activities');
      }
    );
  }

  onCancelClick(index: number) {
    this.isEdit[index] = false;
  }

  onSaveBtnClick(activityId: number, activityStatus: string, index: number) {
    this.actionsService.updateActivityStatus(activityId, activityStatus)
      .subscribe(() => {
      }, (err) => { console.log(err) })

    this.isEdit[index] = false;
  }

  fetchRecords() {
    this.take += this.take;
    this.getActivities();
  }

  clearActivities() {
    this.activitiesWithCounter = undefined;
    this.activities = [];
    this.loadMore = false;
  }
}
