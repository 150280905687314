import { ProxyAdvisorRecommendationComment } from './ProxyAdvisorRecommendationComment';

export class ProxyAdvisorRecommendationGroupedItem {

    constructor() {
        this.proxyAdvisorRecommendationComment = new ProxyAdvisorRecommendationComment;
    }

    proxyAdvisorId: number;
    proxyAdvisorName: string;
    recommendation: string;
    iscPercent: number;
    projectPxyAdvResolutionRecommendationId: number;
    proxyAdvisorRecommendationComment: ProxyAdvisorRecommendationComment;
    hasComment: boolean;
}
