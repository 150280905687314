import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {DashboardAuCustodialReconciliationTableComponent} from '@app/dashboard/dashboardau/dashboard-custodial-reconciliation-table/dashboard-au-custodial-reconciliation-table.component';

@Injectable({
  providedIn: 'root'
})
export class DashboardAuCustodialReconciliationTableService {
  constructor(private http: HttpClient) {
  }

  public getData(projectId: string): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.serverUrl}dashboards/${projectId}/reconciliation-summary`);
  }
}
