import {Injectable} from '@angular/core';
import { DialogsEnums } from './dialogs.enums';
import {DialogsConfig} from '@app/services/dialogs/dialogs.config';
import {MatDialog} from '@angular/material/dialog';
import { DialogsComponent } from '@app/services/dialogs/dialogs-component/dialogs.component';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {
  private closeSubject = new Subject<boolean>();
  public close$: Observable<any> = this.closeSubject.asObservable();

  constructor(public dialog: MatDialog) {
  }

  alert(title: string | null = null, content: any = null, config?: DialogsConfig) {
    return this.createDialog(DialogsEnums.Alert, title, content, config);
  }

  confirm(title: string | null = null, content: any = null, config?: DialogsConfig) {
    return this.createDialog(DialogsEnums.Confirm, title, content, config);
  }

  prompt(title: string | null = null, content: any = null, config?: DialogsConfig) {
    return this.createDialog(DialogsEnums.Prompt, title, content, config);
  }

  private createDialog( type: DialogsEnums, title: string | null, message: any, config?: DialogsConfig ) {
    const dialogRef = this.dialog.open(DialogsComponent, {
      data: {
        type,
        title,
        message,
        config
      },
      autoFocus: false,
      minWidth: 400,
    });

    dialogRef.afterClosed().subscribe(payload => {
      this.closeSubject.next(payload);
      this.closeSubject.unsubscribe();

      this.closeSubject = new Subject<boolean>();
      this.close$ = this.closeSubject.asObservable();
    });


  }
}

