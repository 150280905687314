import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, takeUntil } from 'rxjs/operators';
import { DashboardInvestorVotingService } from '@app/services/dashboard/dashboard-investor-voting.service';
import { Subject } from 'rxjs-compat';
import { ProjectInvestorShareAdapter } from '@app/dto/ProjectInvestorShareDTO';
import { ProjectInvestorIntentionVoteAdapter } from '@app/dto/ProjectInvestorIntentionVoteDTO';
import { MatDialog } from '@angular/material';
// tslint:disable-next-line: max-line-length
import { AddCustodianVotesDialogComponent } from '@app/dashboard/dashboardau/detail/dashboard-investor-voting/add-custodian-votes-dialog/add-custodian-votes-dialog.component';

@Component({
  selector: 'app-dashboard-investor-voting',
  templateUrl: './dashboard-investor-voting.component.html',
  styleUrls: ['./dashboard-investor-voting.component.scss']
})
export class DashboardInvestorVotingComponent {
  private $unsubscribe = new Subject<void>();

  investorId: number;
  projectId: number;
  shares: ProjectInvestorShareAdapter[] = [];
  allVotes: ProjectInvestorIntentionVoteAdapter;
  constructor(
    private route: ActivatedRoute,
    private readonly dashboardInvestorVotingService: DashboardInvestorVotingService,
    public dialog: MatDialog) {
      this.route.params.pipe(first()).subscribe((params) => {
        this.investorId = +params['invid'];
        this.projectId = +params['id'];
        this.getData();
      });
    }

  getData() {
    this.dashboardInvestorVotingService.list(this.projectId, this.investorId)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe(item => {
        this.allVotes = item;
        this.shares = item.shares.map(share => new ProjectInvestorShareAdapter(share));
      });
  }

  addVotes() {
    this.dialog.open(AddCustodianVotesDialogComponent,
      {
        width: '1200px',
        maxHeight: 'fit-content',
        data: {
          investorId: this.investorId,
          projectId: this.projectId
        }
      }).afterClosed().subscribe(result => {
        if (result) {
          this.getData();
        }
    });
  }

  public onVotesEdited(): void {
    this.getData();
  }
}
