export class ProjectCustodialListDto {
  projectCustodianId: number;
  name: string;
  parentCode: string | null;
  parentName: string | null;
  country: string | null;
  shareholdings: Shareholdings[];
  codes: string[];
}

export class Shareholdings {
  id?: number;
  name: string;
  shareCapital: number;
  childrenHoldings?: number;
  cusip: string;

  public constructor(init?: Partial<Shareholdings>) {
    Object.assign(this, init);
  }
}
