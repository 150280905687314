import { ProjectInvestorShareDTO } from '@app/dto/ProjectInvestorShareDTO';

export interface IList {
  name: string;
  value: any;
}

export class ListAdapter implements IList {
  name: string;
  value: any;

  constructor(init?: Partial<IList>) {
    Object.assign(this, init);
  }
}
