import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OciExportSetUpDialogOptionsModel, OciExportSetUpDialogSelectedOptionsModel, SettingsInvestorTypeInterface } from './oci-export-set-up-dialog.models';

@Component({
  selector: 'app-oci-export-set-up-dialog',
  templateUrl: './oci-export-set-up-dialog.component.html',
  styleUrls: ['./oci-export-set-up-dialog.component.scss'],
})
export class OciExportSetUpDialogComponent implements OnInit {

  inestorTypes: SettingsInvestorTypeInterface[];

  constructor(
    private dialogRef: MatDialogRef<OciExportSetUpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dashboardService: DashboardService,
    private spinnerService: NgxSpinnerService,
  ) {
    if (data.default) {
      this.selectedOptions = data.default;
    }
  }

  selectedOptions: OciExportSetUpDialogSelectedOptionsModel = {
    cusips: [],
    investorTypes: [],
    sources: [],
  };

  options: OciExportSetUpDialogOptionsModel = {
    cusips: [],
    investorTypes: [],
    sources: [],
  };

  ngOnInit() {
    this.loadData();
  }

  onConfirm(result: boolean) {
    this.dialogRef.close({
      ...this.data,
      result,
      ...Object.assign(this.selectedOptions, {
        investorTypes: this.options.investorTypes.filter((it) =>
          this.selectedOptions.investorTypes.find((x) => x == it.value)
        ),
        sources: this.options.sources.filter((source) =>
          this.selectedOptions.sources.find(
            (sourceId) => sourceId == source.value
          )
        ),
      })
    })
  }

  private loadData() {
    this.spinnerService.show();
    this.dashboardService
      .getOciExportOptions(this.data.projectId)
      .subscribe((response: OciExportSetUpDialogOptionsModel) => {
        this.options = response;
        this.spinnerService.hide();
      });
  }

  isAnySelectedOption(): boolean {
    return !!Object.keys(this.selectedOptions).every(
      (key) =>
        Array.isArray(this.selectedOptions[key]) &&
        this.selectedOptions[key].length
    );
  }
}
