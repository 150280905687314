import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {AlertService} from '@app/services/alert.service';
import {ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {environment} from 'src/environments/environment';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {ProjectInvestorDataService} from '@app/services/project-investor-data.service';
import {ProjectUnmatchedInvestorDto} from '@app/dto/ProjectUnmatchedInvestorDto';
import {ProjectInvestorAUDataRecord} from '@app/dto/ProjectInvestorAUData';
import {BeneficiariesList} from '../project-investor-upload-dialog/BeneficiariesListDto';
import {Observable, of} from 'rxjs';
import {ActiveDirectoryUser} from '@app/dto/ActiveDirectoryUser';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-match-investor-dialog',
  templateUrl: './match-investor-dialog.component.html',
  styleUrls: ['./match-investor-dialog.component.scss']
})
export class MatchInvestorDialogComponent implements OnInit, AfterViewInit {
  dataSource = [];
  displayedColumns = ['investor', 'matchInPercentages', 'assetManager', 'fund'];
  model: ProjectUnmatchedInvestorDto = new ProjectUnmatchedInvestorDto();
  investorDataControl = new FormControl();
  investorDataControlFilteredOptions: Observable<BeneficiariesList[]> = new Observable<BeneficiariesList[]>();
  isInvestorDataSelectChange = false;
  lookupSelectedValue: string;
  canChooseFromAllInvestors = false;
  originalData: ProjectInvestorAUDataRecord;
  matchedInvestor: ProjectUnmatchedInvestorDto;
  saveAlternativeName: boolean;
  beneficiaryMatch: string;
  private arrowSymbol = '\u21D2';

  constructor(private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MatchInvestorDialogComponent>,
    private alertService: AlertService,
    private projectInvestorDataService: ProjectInvestorDataService,
    private spinnerService: NgxSpinnerService) {
    this.originalData = data.item;
  }

  ngOnInit() {
    this.investorDataControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((input) => {
        this.filterInvestorData(input);
      });
  }

  ngAfterViewInit() {
    this.getProjectInvestorExceptionData(this.originalData.projectId, this.originalData.projectInvestorId);
  }

  onCancelBtnClick() {
    this.dialogRef.close();
  }

  getProjectInvestorExceptionData(projId: number, projInvId: number) {
    setTimeout(() => this.spinnerService.show());

    this.projectInvestorDataService.getUnmatchedInvestor(projId, projInvId).subscribe(
      data => {
        this.spinnerService.hide();
        this.model = data;
        this.model.investor = data.investor;
        this.model.investorId = data.investorId;
        this.dataSource = [...data.matchingSuggestions];
        this.matchedInvestor = this.dataSource[0];
      }, err => this.spinnerService.hide());
  }

  matchInvestor(item: ProjectUnmatchedInvestorDto) {
    this.projectInvestorDataService.matchInvestor(item).subscribe(
      () => {
        this.alertService.sendSuccess('Replaced unmatched with matched record !');
        this.dialogRef.close(true);
      },
      (err) => {
        err.error !== undefined ? this.alertService.sendError(err.error.Message) : this.alertService.sendError(err);
        this.dialogRef.close();
      });
  }

  private filterInvestorData(value: string): void {
    if (this.isInvestorDataSelectChange) {
      this.isInvestorDataSelectChange = false;
      return;
    }

    if (!value || value.length < 2) {
      this.investorDataControlFilteredOptions = new Observable<BeneficiariesList[]>();
      return;
    }

    this.projectInvestorDataService.getBeneficiariesData(this.originalData.projectId, value).subscribe(data => {
      data.forEach(option => option.formattedName = this.getOptionName(option));
      this.investorDataControlFilteredOptions = of(data);
    })
  }

  onBlur() {
    if (!this.model.investorId) {
      this.model.investorId = undefined;
      this.model.investor = '';
      this.model.city = '';
      this.model.assetManagerId = undefined;
      this.model.fundId = undefined;
    }
  }

  onSuggestedInvestorSelected(item) {
    if (!item.investorId) {
      this.lookupSelectedValue = '';
      this.model.investorId = undefined;
      this.model.investor = '';
      this.model.city = '';
      this.model.assetManagerId = undefined;
      this.model.fundId = undefined;
      this.canChooseFromAllInvestors = true;
      this.assignInvestorResponsibilityUser(this.model, null);
    } else {
      this.canChooseFromAllInvestors = false;
      this.model.investorId = item.investorId;
      this.model.investor = item.investor;
      this.model.city = item.city;
      this.model.assetManagerId = item.assetManagerId;
      this.model.fundId = item.fundId;
      this.assignInvestorResponsibilityUser(this.model, item.responsibilityUser);

      var benefList = Object.assign(new BeneficiariesList(), item);
      benefList.investorCountry = item.city;
      benefList.formattedName = item.investor;

      this.onSelected(benefList);
    }
    this.model.saveAlternativeName = false;
  }

  onSelected(item: BeneficiariesList) {
    this.isInvestorDataSelectChange = true;
    this.model.investorId = item.investorId;
    this.model.investor = item.investor;
    this.model.city = item.investorCountry;
    this.model.assetManagerId = item.assetManagerId;
    this.model.fundId = item.fundId;

    this.assignInvestorResponsibilityUser(this.model, item.responsibilityUser);

    this.lookupSelectedValue = item.formattedName;
    this.beneficiaryMatch = this.getBeneficiaryRelation(item);
  }

  assignInvestorResponsibilityUser(item: ProjectUnmatchedInvestorDto, responsibilityUser: ActiveDirectoryUser) {
    if (!responsibilityUser) {
      item.investorResponsibilityUser.displayName = '';
      item.investorResponsibilityUser.mail = '';
      item.investorResponsibilityUser.userPrincipalName = '';
      item.investorResponsibilityUser.id = '';
    } else {
      item.investorResponsibilityUser.displayName = responsibilityUser.displayName;
      item.investorResponsibilityUser.mail = responsibilityUser.mail;
      item.investorResponsibilityUser.userPrincipalName = responsibilityUser.userPrincipalName;
      item.investorResponsibilityUser.id = responsibilityUser.id;
    }
  }

  resetSelectedMatch() {
    this.beneficiaryMatch = '';
    this.model.investorId = null;

    this.model.investorResponsibilityUser.displayName = '';
    this.model.investorResponsibilityUser.mail = '';
    this.model.investorResponsibilityUser.userPrincipalName = '';
    this.model.investorResponsibilityUser.id = '';
  }

  private getOptionName(option: BeneficiariesList): string {
    if (option.fund) {
      return this.constructName(option.fund, option.fundFactsetId, 'Fund');
    } else if (option.assetManager) {
      return this.constructName(option.assetManager, option.assetManagerFactsetId, 'Asset Manager');
    } else {
      let investorName = this.constructName(option.investor, option.investorFactsetId, 'Investor');
      if (option.investorCountry !== '' && option.investorCountry != null) {
        investorName += ` (${option.investorCountry})`;
      }

      return investorName;
    }
  }

  private constructName(name: string, factsetId: string, type: string): string {
    return name + (factsetId ? ' ' + factsetId : '') + ` (${type})`;
  }

  private getBeneficiaryRelation(beneficiary: BeneficiariesList): string {
    let relation = '';

    relation += this.getBeneficiaryItem(beneficiary.fund, BeneficiaryItemTypes.Fund);
    relation += this.getBeneficiaryItem(beneficiary.assetManager, BeneficiaryItemTypes.AssetManager);
    relation += `${beneficiary.investor}`;

    return relation;
  }

  private getBeneficiaryItem(item: string, type: BeneficiaryItemTypes): string {
    if (item) {
      return `${item} ${this.arrowSymbol} `;
    } else {
      return `(no ${type}) ${this.arrowSymbol} `;
    }
  }
}

export enum BeneficiaryItemTypes {
  Fund = 'fund',
  AssetManager = 'asset manager',
  Investor = 'investor'
}
