import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {NavigationTriggerService} from '@app/services/admin/navigation-trigger.service';

@Component({
  selector: 'app-admin-nav',
  templateUrl: './admin-nav.component.html',
  styleUrls: ['./admin-nav.component.scss']
})
export class AdminNavComponent {
  subMenu: false;
  backLink;
  userid: number;
  rolename: string;
  coverage: string;
  routeHub;
  isExpanded: boolean = true;
  @Input() hasActiveChild: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private navigationTriggerService: NavigationTriggerService) {


  }

  ngOnInit() {
    this.navigationTriggerService.shouldShowSubMenu().subscribe(data => {
      this.subMenu = data.menu;
      this.backLink = data.backLink;
    });
    this.userid = 0;
    this.rolename = this.userService.getUserRoleName();
    this.coverage = this.userService.getUserCoverage();
  }

}
