import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {MatSort, MatTableDataSource} from '@angular/material';
import { CallLog } from '@app/dto/CallLog';

@Component({
  selector: 'app-call-log-table',
  templateUrl: './call-log-table.component.html',
  styleUrls: ['call-log-table.component.scss']
})
export class CallLogTableComponent implements OnInit {
  dataSource;
  displayedColumns = [
    'contactName',
    'username',
    'comment',
    'entryDate',
    'followup',
    'followupStatus',
    'datetime'
  ];
  @Input() data: CallLog[] = [];
  @ViewChild(MatSort) sort: MatSort;
  
noItemsText:string='Call Logs';
showGrid:boolean;

  constructor() {
  }

  ngOnInit() {
    this.setDataSource(this.data);
  }

  setDataSource(data) {
    this.dataSource = new MatTableDataSource<CallLog[]>();
    this.dataSource.sort = this.sort;
    this.dataSource.data = data;
    this.showGrid = data.length > 0;
  }
}
