import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material';

@Component({
    selector: 'app-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitch {
    @Input() label: string;
    @Input() info: string;
    @Input() toggleNames:string[] = ['YES', 'NO'];
    @Input() setCustomStyle:boolean = false;
    @Input() value: boolean = false;
    @Input() disabled: boolean;
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {

    }    

    changeValue(value) {      
        if(this.value != value){
            this.value = value;
            this.valueChange.emit(value);
        }          
    }
}
