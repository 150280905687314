import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvestorVotingSummaryTableComponent } from './investor-voting-summary-table/investor-voting-summary-table.component';
import {
  MultiselectChipsGroupOptionModel,
  MultiselectChipsOptionModel
} from '@app/components/multiselect-chips/multiselect-chips.component';
import { InvestorVotingSummaryService } from '@app/services/investors';
import { InvestorVotingSummaryAdapter } from '@app/dto/investor/investor-voting-summary.interface';
import { forkJoin, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  FilterAndPaginateOutputDTO,
  FilterAndPaginateDTO,
  PaginationDTO
} from '@app/components/table-filter-wrapper/interfaces';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { Utils } from '@app/helper/utils';
import { KeyValuePipe } from '@angular/common';
import { AppConstants } from '@app/app.constants';

export class Filters {
  public resolutionClassifications: MultiselectChipsOptionModel[] = [];
  public sectorOptions: MultiselectChipsOptionModel[] = [];
  public marketOptions: MultiselectChipsOptionModel[] = [];
  public proxyAdvisorsOptions: MultiselectChipsOptionModel[] = [];
  public shareClassification = 'Total';
  public client: string;
  public fromDate: Date;
  public toDate: Date;

  constructor(init = {}) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'app-investor-voting-summary',
  templateUrl: './investor-voting-history.component.html',
  styleUrls: ['./investor-voting-history.component.scss']
})
export class InvestorVotingHistoryComponent implements OnInit, OnDestroy {
  @ViewChild('investorVotingSummaryTable') investorVotingSummaryTableComponent: InvestorVotingSummaryTableComponent;
  investorId: number;
  public toggleMenu = false;
  public selectedFilters = new Filters();
  public allFilters = new Filters();
  public investorVotingSummary: InvestorVotingSummaryAdapter;
  public proxyAdvisorsNamesSubject: Subject<string[]> = new Subject();
  public shareClassificationSubject: Subject<string> = new Subject();
  public applyFiltersSubject: Subject<Filters> = new Subject();
  private unsubscribe: Subject<void> = new Subject();
  util = new Utils(this.startupdataproviderService);

  constructor(
    private route: ActivatedRoute,
    private investorVotingSummaryService: InvestorVotingSummaryService,
    private startupdataproviderService: StartupdataproviderService,
    private keyValuePipe: KeyValuePipe
  ) { }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.closest('.filters__toggle') || event.target.classList.contains('.filters__toggle')) {
      this.toggleMenu = !this.toggleMenu;

      return;
    }

    if (
      !event.target.closest('.filters__menu')
      && !event.target.closest('.cdk-overlay-container')
      && !event.target.closest('.mat-calendar-table')
    ) {
      this.toggleMenu = false;
    }
  }

  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.investorId = +params['id'];

      this.requestFilters();
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onFiltersApply(selectedFilters: Filters): void {
    this.selectedFilters = selectedFilters;
    this.applyFiltersSubject.next(this.selectedFilters);
    this.toggleMenu = false;
  }

  public columnsChange(selectedFilters: Filters): void {
    this.selectedFilters.proxyAdvisorsOptions = selectedFilters.proxyAdvisorsOptions;
    this.selectedFilters.shareClassification = selectedFilters.shareClassification;

    this.proxyAdvisorsNamesSubject.next(selectedFilters.proxyAdvisorsOptions.map((x) => x.value));
    this.shareClassificationSubject.next(selectedFilters.shareClassification);
  }

  private requestFilters(): void {
    forkJoin({
        sectors: this.investorVotingSummaryService.getSectorOptions(this.investorId),
        markets: this.investorVotingSummaryService.getMarketOptions(this.investorId),
        resolutionClassifications: this.investorVotingSummaryService. getResolutionClassifications(this.investorId),
        investorVotingSummary: this.requestInvestorVotingSummary(this.investorId)
      }).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((response) => {
        const sectorOptions = response.sectors.map((sector) =>
          new MultiselectChipsOptionModel({ name: sector.name, value: sector.value, checked: true })
        );
        const resolutionClassifications = response.resolutionClassifications.map((resClassification) =>
          new MultiselectChipsOptionModel({ name: resClassification.name, value: resClassification.value || '%NULL%', checked: true })
        );
        const proxyAdvisorsOptions = response.investorVotingSummary.proxyAdvisorsNames.map((pxyName) =>
          new MultiselectChipsOptionModel({ name: pxyName, value: pxyName, checked: true })
        );

      // tslint:disable-next-line:max-line-length
        const marketOptions =  this.keyValuePipe.transform<string[]>(this.util.getCountriesByRegionGroup()).reduce((accumulator, region, index) => {
          const countries = region.value.filter(country => {
            return response.markets.findIndex(m => m.name.toLowerCase() === country.toLowerCase()) > -1;
          }).map((market) =>
            new MultiselectChipsOptionModel({ name: market, value: market, checked: true })
          );
          if (countries.length) {
            accumulator.push(new MultiselectChipsGroupOptionModel({key: this.getContinentLabel(region.key), value: countries}));
          }
          return accumulator;
        }, []);

        this.allFilters = new Filters({
          sectorOptions,
          marketOptions,
          resolutionClassifications,
          proxyAdvisorsOptions
        });
        this.selectedFilters = new Filters({
          resolutionClassifications,
          sectorOptions,
          marketOptions,
          proxyAdvisorsOptions
        });

        this.investorVotingSummary = response.investorVotingSummary;
        this.proxyAdvisorsNamesSubject.next(response.investorVotingSummary.proxyAdvisorsNames);
        this.shareClassificationSubject.next(this.selectedFilters.shareClassification);
      });
  }

  getContinentLabel(region: string) {
    return AppConstants.continentLabelMapping[region];
  }

  private requestInvestorVotingSummary(investorId: number): Observable<InvestorVotingSummaryAdapter> {
    return this.investorVotingSummaryService
      .getInvestorVotingSummary(
        investorId,
        new FilterAndPaginateOutputDTO(
          new FilterAndPaginateDTO({
            pagination: new PaginationDTO(1, 10),
          })
        )
      );
  }
}
