import { LookupFilter } from '@app/helper/utils';
import { Utils } from '@app/helper/utils';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource} from '@angular/material';
import { CallLog } from '../../../dto/CallLog';
import { ContactDisplayType } from '../../../dto/ContactDisplayType';
import { CalllogFeedback } from '../../../dto/CalllogFeedback';
import { Lookup } from 'src/app/dto/Lookup';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';

@Component({
    selector: 'app-dashboard-au-feedback',
    templateUrl: './dashboard-au-feedback.component.html',
    styleUrls: ['./dashboard-au-feedback.component.scss']
})
export class DashboardAUFeedbackComponent implements OnInit {
    routeSub: any;

    displayContactColumns: string[] = [
        'fullName',
        'areaOfResponsibility',
        'phone',
        'email',
    ]

    displayCalllogColumns: string[] =
        [
            'contactName',
            'comment',
            'followup',
            'username',
            'edit',
            'delete'
        ]

    dsContact: MatTableDataSource<ContactDisplayType>;
    dsCallLog: MatTableDataSource<CallLog>;

    model: CallLog;

    calllogcommenttypes: Lookup[] = [];
    calllogstatus: Lookup[] = [];

    projectid: number;
    investorid: number;
    userid: number;
    rolename: string;

    disableForm: boolean;
    callLogFeedback: CalllogFeedback;
    callLogs: CallLog[] = [];
    contactDisplays: ContactDisplayType[] = [];

    isAddNew: boolean;
    util = new Utils(this.startUpDataProvider);

    versionid: number;

    constructor(private route: ActivatedRoute, private httpClient: HttpClient, private userService: UserService, 
                    private startUpDataProvider : StartupdataproviderService, private alertService: AlertService) {
        this.model = new CallLog();
        this.versionid = 1;
    }

    ngOnInit() {
        this.userid = 0;
        this.util.getLookupByType(LookupFilter.COMMENTTYPE).subscribe(data => this.calllogcommenttypes = data);
        this.rolename = this.userService.getUserRoleName();
        this.routeSub = this.route.params.subscribe((params) => {
            this.projectid = +params['id'];
            this.investorid = +params['invid'];
            this.getFeedback(this.projectid, this.investorid);
            this.isAddNew = true;
        });
    }

    getFeedback(projectid: number, investorid: number) {
        let apiURL: string = environment.serverUrl + 'dashboards/call-logs-and-contacts/' + projectid + '/' + investorid;
        this.httpClient.get<CalllogFeedback>(apiURL).subscribe(
            data => {
                this.callLogFeedback = data;
                this.callLogs = this.callLogFeedback.callLogs;
                this.contactDisplays = this.callLogFeedback.contactDisplays;

                this.dsContact = new MatTableDataSource<ContactDisplayType>();
                this.dsCallLog = new MatTableDataSource<CallLog>();
                this.dsContact.data = this.contactDisplays;
                this.dsCallLog.data = this.callLogs;
            },
            err => console.error(err)
        );
    }

    onNavigate(elem) {
        this.model.contactName = elem.fullName;
        this.model.commentType = 'Investor';
        this.model.contactId = elem.contactId;
    }

    addUpdateCallLog() {
        let callLog: CallLog = new CallLog();
        if (!this.isAddNew) {
            callLog = this.dsCallLog.data.filter(x => x.callLogId == this.model.callLogId)[0];
        }

        callLog.projectId = this.projectid;
        callLog.investorId = this.investorid;
        callLog.contactId = this.model.contactId;
        callLog.contactName = this.model.contactName;
        callLog.commentType = this.model.commentType;
        callLog.followup = this.model.followup;
        callLog.entryDate = this.model.entryDate;
        callLog.username = this.model.username;
        callLog.comment = this.model.comment;
        callLog.userId = this.userid;

        let apiURL: string = environment.serverUrl + 'dashboards/feedback';
        this.httpClient.post<number>(apiURL, callLog).subscribe(
            data => {
                if (this.isAddNew) {
                    callLog.callLogId = data;
                    this.alertService.sendSuccess("Feedback is updated.");
                }
                else {
                    this.callLogs = this.callLogs.filter(x => x.callLogId != this.model.callLogId);
                    this.isAddNew = true;
                    this.alertService.sendSuccess("Feedback is added.");
                }

                this.model = new CallLog();
                this.callLogs.push(callLog);
                this.dsCallLog = new MatTableDataSource(this.callLogs);
            },
            err => {
                this.alertService.sendError(' error ' + JSON.stringify(err));
            });
    }

    onCancelCallLog() {
        this.model = new CallLog();
        this.isAddNew = true;
    }

    onEditCallLogClick(item: CallLog) {
        this.model = { ...item };
        this.isAddNew = false;
    }

    onDeleteCallLogClick(item: CallLog) {
        if (!this.isAddNew && item.callLogId == this.model.callLogId){
            this.alertService.sendWarning('Can not delete item while updating it.');
            return;
        }

        let apiURL: string = environment.serverUrl + 'dashboards/feedback/' + item.callLogId + '/' + this.userid;
        this.httpClient.delete<boolean>(apiURL).subscribe(
            data => {
                if (data) {
                    this.callLogs = this.callLogs.filter(x => x.callLogId != item.callLogId);
                    this.dsCallLog = new MatTableDataSource(this.callLogs);
                    this.alertService.sendSuccess("Feedback is deleted.");
                }
            },
            err => {
                this.alertService.sendError(' error ' + JSON.stringify(err));
            })
    }
}
