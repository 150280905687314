import {AlertService} from 'src/app/services/alert.service';
import {LocalCustodiansService} from '../../../../services/admin/local-custodians.service';
import {LocalCustodian} from '../../../../dto/LocalCustodian';
import {Component, OnInit} from '@angular/core';
import {ContactRelatedEntityType} from 'src/app/enums/ContactRelatedEntityTypeEnum';
import {ActivatedRoute} from '@angular/router';
import {ContactType} from 'src/app/enums/ContactTypeEnum';

@Component({
  selector: 'app-local-custodians-contacts',
  templateUrl: './local-custodians-contacts.component.html',
  styleUrls: ['local-custodians-contacts.component.scss'],
})
export class LocalCustodiansContactsComponent implements OnInit {
  contactType: ContactType;
  contactRelatedEntityType: ContactRelatedEntityType;
  localCustodianId:number;
  model : LocalCustodian= new LocalCustodian()

  constructor(private route: ActivatedRoute, private localCustodianService: LocalCustodiansService, private alertService: AlertService) {
  }

  ngOnInit() {
    this.contactType = ContactType.LocalCustodian;
    this.contactRelatedEntityType = ContactRelatedEntityType.LocalCustodian;
    this.route.parent.params.subscribe((params) => {
      this.localCustodianId =  +params['id'];
    });
    this.localCustodianService.get(this.localCustodianId).subscribe(
      data => {
        this.model = data;
      },
      err => this.alertService.sendError(err)
    );
  }
}
