export enum ContactRelatedEntityType {
    ClearingHouse = 1,
    GlobalCustodian = 2,
    Investor = 3,
    LocalCustodian = 4,
    ProxyAdvisor = 5,
    Esg = 6,
    Client = 7,
    ExternalAdvisor = 8
}
