import { Component, OnInit, forwardRef, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { CustodiansList } from '@app/dto/ProjectCustodians';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { noop, BehaviorSubject } from 'rxjs';
import { debounceTime, filter, flatMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-type-ahead-search',
  templateUrl: './type-ahead-search.component.html',
  styleUrls: ['./type-ahead-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeAheadSearchComponent),
      multi: true
    }]
})

export class TypeAheadSearchComponent implements OnInit {
  @ViewChild('currentValue') public currentValue: NgModel;
  public placeholder = 'Custodian';
  @Output() onSelectionChanged = new EventEmitter<CustodiansList>();
  @Input() withCodes = false;
  @Input() onlyGlobalCustodians = false;
  @Input() required = false;
  @Input() uniqueCustodians = false;
  @Input() projectId = 0;

  public filteredItems: CustodiansList[];
  private innerValue;
  public selectedItemChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    if (!this.innerValue) {
      this.innerValue = { name: '', custodianId: 0, isGlobalCustodian: false };
    }

    this.initializeFilters();
  }

  doSearch(value) {
    this.selectedItemChanges.next(value);
    this.innerValue = { name: value, custodianId: 0, isGlobalCustodian: false };
  }

  initializeFilters() {
    this.selectedItemChanges.pipe(
      debounceTime(500)
    ).subscribe((input) => {
      if (input) {
        const url = this.uniqueCustodians ? `${environment.serverUrl}dashboardsmailing/mailing-document-recepients/${input}/${this.projectId}`:`${environment.serverUrl}custodian/${input}`;
        this.http.get<CustodiansList[]>(url)
          .pipe(
            map(items => items.filter(item => this.onlyGlobalCustodians && item.isGlobalCustodian || !this.onlyGlobalCustodians)),
            map(items => this.withCodes ? items.reduce((result, item) => {
              if (item.codes && item.codes.length && item.isGlobalCustodian) {
                item.codes.map(code => result.push({...item, ...{codeId: code.id, codeName: code.name}}));
              } else {
                result.push(item);
              }
              return result;
            }, []) : items),
            map(items => this.withCodes ? this.setItemsWithCode(items) : this.setItemsWithoutCode(items))
          )
          .subscribe(filteredResults => {
            if (filteredResults) {
              this.filteredItems = filteredResults;
            }
          });
      }
    });
  }

  setItemsWithoutCode = (items) => {
    return items.map(x => {
      x.name = x.isGlobalCustodian ? `${x.name} (Global)` : `${x.name} (Local)`;
      return x;
    });
  }

  setItemsWithCode = (items) => {
    return items.map(x => {
      x.name = x.isGlobalCustodian ? this.setGlobalCustodianName(x) : this.setLocalCustodianName(x);
      return x;
    });
  }

  setGlobalCustodianName = (item) => {
    const name = [`${item.name}`];
    if (item.codeName ) {
      name.push(`(DTC: <${item.codeName}>)`);
    }
    return name.join(' ');
  }

  setLocalCustodianName = (item) => {
    return item.codes && item.codes.length ? `${item.name} (BR: < ${(item.codes.map(code => code.name) || []).join(', ')}>)`: `${item.name}`;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.onChangeCallback(v);
    }
  }

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value && value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngDoCheck() {
    this.onChangeCallback(this.value);
  }

  public onTouched() {
    this.onTouchedCallback();
  }

  onSelected(selectedItem: CustodiansList) {
    this.innerValue = selectedItem;
    this.onSelectionChanged.emit(selectedItem);
  }

  displayFn(autoCompleteResult) {
    if (autoCompleteResult) {
      this.innerValue = autoCompleteResult;
      if (typeof (autoCompleteResult) === 'object') {
        return autoCompleteResult.name;
      } else {
        return autoCompleteResult;
      }
    }
  }

  onBlur() {
    if (!(this.innerValue.custodianId > 0)) {
      this.innerValue.name = '';
      this.onSelectionChanged.emit({ name: '', custodianId: 0, isGlobalCustodian: false, codes: [] });
    }
  }

  public resetValue() {
    this.writeValue({ name: '', custodianId: 0, isGlobalCustodian: false });
  }
}
