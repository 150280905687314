import { Injectable } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { AlertService } from './alert.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TokenResponse } from '@app/dto/TokenResponse';
import { Router } from '@angular/router';
import { UIPermissionDTO, UIRolePermissionRequest } from '@app/dto/UIPermissionDTOs';
import { AppConfigService } from './app-config.service';
import { User } from '@app/dto/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public graphApiAccessToken: any;
  private cache$: Observable<UIPermissionDTO>[] = [];

  private loggedInUser = new BehaviorSubject<User>(null);
  public loggedInUser$ = this.loggedInUser.asObservable();

  constructor(private adalService: MsAdalAngular6Service, private alertService: AlertService, private http: HttpClient, private router: Router, private appConfigService: AppConfigService) { }

  public checkRoleAssigned(requiredRole: string): boolean {
    if (this.adalService.userInfo && this.adalService.userInfo.profile && this.adalService.userInfo.profile.roles && requiredRole) {
      if (this.adalService.userInfo.profile.roles.indexOf(requiredRole.toLowerCase()) > -1) {
        return true;
      }
    }
    return false;
  }

  public checkPermissionAssigned(loggedInUser: User, permission: string): boolean {
    let permissionFound: boolean = false;
    if (loggedInUser && loggedInUser.permissions && loggedInUser.permissions.length > 0 && permission) {
      loggedInUser.permissions.forEach((x: UIPermissionDTO) => {
        if (x.permissionName.toLowerCase() == permission.toLowerCase()) {
          permissionFound = true;
        }

      });
    }
    return permissionFound;
  }

  public getUserEmail(): string {
    return this.adalService.LoggedInUserEmail
  }

  public getUserProfile(): any {
    return this.adalService.userInfo.profile;
  }

  public getUserCoverage(): string {
    let tmpCoverage: string = "";
    if (this.userContainsRole()) {
        let tmpRole: string = this.getUserRoleName().toLowerCase();
        
        switch(tmpRole){
          case "au_admin": {
            tmpCoverage = "AU";
            break;
          }
          case "eu_client":{
            tmpCoverage = "EU";
            break;
          }
          case "eu_pm":{
            tmpCoverage = "EU";
            break;
          }
          case "eu_user":{
            tmpCoverage = "EU";
            break;
          }
          case "external_client":
            tmpCoverage = "AU";
            break;
          case "super_pm_au": {
            tmpCoverage = "AU";
            break;
          }
          default:{
            tmpCoverage = this.adalService.userInfo.profile.roles[0].slice(0, 2);
            break;
          }
        }
    }

    return tmpCoverage;
    
  }

  public getUserRoleName(): string {

    if (this.userContainsRole()) {
      return this.adalService.userInfo.profile.roles[0];
    }
    else {
      return "";
    }
  }

  public getGraphApiAccessToken(): Observable<TokenResponse> {
    const url = `${environment.serverUrl}JwtToken/graphApiToken`;
    return this.http.get<TokenResponse>(url);
  }

  public getUserPermissions(roleName: string): Observable<UIPermissionDTO[]> {
    let upr: UIRolePermissionRequest = new UIRolePermissionRequest();
    upr.aDRoleKey = roleName;
    return this.http.post<UIPermissionDTO[]>(this.appConfigService.getActiveWebApiHost() + 'user/GetUserPermissions', upr);
  }



  private userContainsRole(): boolean {

    if (this.adalService && this.adalService.userInfo && this.adalService.userInfo.profile && this.adalService.userInfo.profile.roles &&
      this.adalService.userInfo.profile.roles.length > 0) {
      return true;
    }
    return false;
  }

  clearCache() {
    this.cache$ = null;
  }

  public setLoggedInUser = (user: User) => {
    this.loggedInUser.next(user);
  }
}


