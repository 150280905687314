import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSort, MatTableDataSource } from '@angular/material';
import { AlertService } from '@app/services/alert.service';
import { CustodianDashboardDetailDTO } from '@app/dto/CustodianDashboardDetailDTO';
import { CustodiansService } from '@app/services/custodians.service';
import { ShareTypesAdapter } from '@app/dashboard/dashboardau/dashboard-custodian-table/custodian-details/interfaces/share-types.interface';

@Component({
  selector: 'app-custodian-details',
  templateUrl: './custodian-details.component.html',
  styleUrls: ['./custodian-details.component.scss']
})
export class CustodianDetailsComponent implements OnInit {

  model: CustodianDashboardDetailDTO = new CustodianDashboardDetailDTO();
  projectId;
  projectCustodianId;
  displayedColumns: string[] = [
    'total',
  ];
  displayDynamicColumns = [];
  shareTypeColumns = [];
  dropdownShareTypeColumns = [];
  dataSource;
  @ViewChild(MatSort) sort: MatSort;
  showGrid = false;
  defaultShareType: ShareTypesAdapter;

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              private alertService: AlertService,
              private custodiansService: CustodiansService,
              private dialogRef: MatDialogRef<CustodianDetailsComponent>) {
  }

  ngOnInit() {
    this.projectId = this.data.projectId;
    this.projectCustodianId = this.data.projectCustodianId;
    this.defaultShareType = this.data.defaultShareType;
    this.getData();
  }

  getData() {
    this.custodiansService.getCustodianDetails(this.projectId, this.projectCustodianId).subscribe(
      data => {
        this.model = data;

        this.generateTable();
      }, err => {
        this.alertService.sendError(err.message);
      }
    );
  }

  generateTable() {
    this.showGrid = true;
    const record = {
      total: this.model.custodianShares.total,
      custodianType: this.model.custodianType,
      totalGlobalHoldings: this.model.custodianShares.totalGlobalHoldings,
    };

    this.shareTypeColumns = this.extractUniqueShareTypes(this.model.custodianShares.sharesDetail);
    this.dropdownShareTypeColumns = this.createDropDownShareTypeColumns(this.shareTypeColumns);

    this.displayDynamicColumns = [];

    this.shareTypeColumns.forEach((shareType) => {
      const item = this.model.custodianShares.sharesDetail.find(share => share.name === shareType);
      this.displayedColumns.push(`${shareType}`);
      this.displayDynamicColumns.push(`${shareType}`);
      record[`${shareType}`] = {shareCapital: item.shareCapital, childrenHoldings: item.childrenHoldings};
    });

    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.dataSource.data = [record];
  }

  extractUniqueShareTypes = array => array.reduce((h, obj) => [...h, ...obj.name], [])
    .filter((value, index, self) => self.indexOf(value) === index);

  getClassificationCusip(classification: string): string {
    const cusip = this.model.custodianShares.sharesDetail.find(c => c.name === classification).cusip;
    if (cusip) {
      return `(${cusip})`;
    }

    return;
  }

  createDropDownShareTypeColumns(shareTypes: any[]): any[] {
    const dropdownShareTypes: ShareTypesAdapter[] = [];
    for (let i = 0; i < shareTypes.length; i++) {
      const cusip = this.model.custodianShares.sharesDetail.find(s => s.name === shareTypes[i]).cusip;
      dropdownShareTypes.push(new ShareTypesAdapter({cusip: cusip, shareClassification: shareTypes[i]}));
    }

    return dropdownShareTypes;
  }
}
