import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DocumentsService } from './../../../services/documents.service';
import { DocumentDto } from '../DocumentDto';
import { UserService } from './../../../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-load-file-section',
  templateUrl: './load-file-section.component.html',
  styleUrls: ['./load-file-section.component.scss']
})
export class LoadFileSectionComponent implements OnInit {
  childDto: DocumentDto = new DocumentDto();  
  @Input() relatedEntityId:number;
  @Input() relatedEnityType:number;
  @Output() loadedDocuments = new EventEmitter();
  @ViewChild('uploadForm') public sectionForm: NgForm;
  
  constructor(private documentsService: DocumentsService,
    private userService: UserService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.documentsService.documentEmitted$.subscribe(data => {
      if (data.dto) {
        this.childDto.file = data.dto.file;
        this.childDto.fileDescription = data.dto.fileDescription;
        this.childDto.fileName = data.dto.fileName;
      } else{
        this.childDto = new DocumentDto();
      }
    });
  }

  UploadFiles(){
    this.spinner.show();
    const fileToUpload: FormData = this.documentsService.setDocumentProps(this.childDto.file,
      this.childDto.fileDescription,
      this.relatedEntityId,
      this.relatedEnityType,
      this.userService.getUserProfile(),
      this.childDto.customField,
      this.childDto.link);
      
    return this.documentsService.uploadDocument(fileToUpload)
        .subscribe((response: boolean) => {
            if (response) {
                this.childDto = undefined;  
                this.sectionForm.form.reset();
                this.documentsService.emitDocument({dto: this.childDto});              
            } 

            this.spinner.hide();
            this.loadDocuments();
        },
            err => console.log(JSON.stringify(err))
        );
  }

  loadDocuments(){
    this.loadedDocuments.emit('load');
  }
}
