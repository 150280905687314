import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { OciExportSetUpDialogComponent } from "../oci-export-set-up-dialog/oci-export-set-up-dialog.component";
import { OciExportConfirmDialogDisplayData, OciExportDialogModel } from "./oci-export-confirm-dialog.models";

@Component({
  selector: "app-oci-export-confirm-dialog",
  templateUrl: "./oci-export-confirm-dialog.component.html",
  styleUrls: ["./oci-export-confirm-dialog.component.scss"],
})
export class OciExportConfirmDialogComponent implements OnInit {

  displayData: OciExportConfirmDialogDisplayData;

  constructor(
    private dialogRef: MatDialogRef<OciExportSetUpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OciExportDialogModel
  ) {}

  ngOnInit() {
    this.displayData = {
      cusips: this.data.cusips,
      sources: this.data.sources.map((x) => x.name),
      investorTypes: this.data.investorTypes.map((x) => x.name),
      numberOfInvestors: this.data.numberOfInvestors,
    };
  }

  onConfirm(isConfirm: boolean) {
    this.dialogRef.close(isConfirm);
  }
}
