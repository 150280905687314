import {Injectable} from '@angular/core';
import {AppConfigService} from '@app/services/app-config.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ResolutionClassificationItemDTO} from '@app/dto/ResolutionClassificationItemDTO';
import {map, publishReplay, refCount} from 'rxjs/operators';
import { ResolutionClassificationCustomFieldDto, CustomFieldOptionDto } from '@app/dto/ResolutionClassificationCustomFieldDto';

@Injectable({
  providedIn: 'root'
})
export class ResolutionClassificationCastumFieldsService {

  constructor(private http: HttpClient, private appConfigService: AppConfigService) {
  }

  public getAllCutomFields(classificationId: number): Observable<ResolutionClassificationCustomFieldDto[]> {
    return this.http.get<ResolutionClassificationCustomFieldDto[]>(`${environment.serverUrl}classifications/custom-fields/${classificationId}`);
  }

  public deleteCustomField(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.serverUrl}classifications/custom-field/${id}`);
  }

  public updateCustomField(item: ResolutionClassificationCustomFieldDto): Observable<boolean> {
    return this.http.put<boolean>(`${environment.serverUrl}classifications/custom-field`, item);
  }

  public addCustomField(item: ResolutionClassificationCustomFieldDto): Observable<boolean> {
    return this.http.post<boolean>(`${environment.serverUrl}classifications/custom-field`, item);
  }

  public getCutomFieldTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.serverUrl}classifications/custom-field-types`);
  }

  public getOptionsSourceTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.serverUrl}classifications/option-source-types`);
  }

  public getOptionsByOptionSource(optionSourceId): Observable<CustomFieldOptionDto[]> {
    return this.http.get<CustomFieldOptionDto[]>(`${environment.serverUrl}classifications/options-by-option-source/${optionSourceId}`);
  }

  public copyCustomFields(sourceResolutionId: number, targetResolutionId: number): Observable<boolean> {
    return this.http.post<boolean>(`${environment.serverUrl}classifications/copy-custom-fields`, { sourceResolutionId: sourceResolutionId,  targetResolutionId: targetResolutionId });
  }
}
