import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DashboardAuCustodialReconciliationTableService } from '@app/services/dashboard-au-custodial-reconciliation-table.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ProjectTemplateTypes } from '@app/enums/ProjectTemplateTypesEnum';

@Component({
  selector: 'app-dashboard-au-custodial-reconciliation-table',
  templateUrl: './dashboard-au-custodial-reconciliation-table.html',
  styleUrls: ['dashboard-au-custodial-reconciliation-table.scss']
})

export class DashboardAuCustodialReconciliationTableComponent implements OnInit {
  dataSource;
  items;
  projectType: ProjectTemplateTypes;
  _tableRows = [];
  @Input() projectId: string;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'name'
  ];
  resolutionContentColumns = [
    {
      name: 'For',
      parameter: 'forVote'
    },
    {
      name: '% ISC',
      parameter: 'forISCPercent'
    },
    {
      name: 'Against',
      parameter: 'againstVote'
    },
    {
      name: '% ISC',
      parameter: 'againstISCPercent'
    },
    {
      name: 'Abstain',
      parameter: 'abstainVote'
    },
    {
      name: '% ISC',
      parameter: 'abstainISCPercent'
    },
  ];

  bitContentColumns = [
    {
      name: 'Accept',
      parameter: 'acceptVote'
    },
    {
      name: '% ISC',
      parameter: 'acceptISCPercent'
    },
    {
      name: 'Decline',
      parameter: 'declineVote'
    },
    {
      name: '% ISC',
      parameter: 'declineISCPercent'
    },
    {
      name: 'Undisclosed',
      parameter: 'undisclosedVote'
    },
    {
      name: '% ISC',
      parameter: 'undisclosedISCPercent'
    },
    {
      name: 'Undecided',
      parameter: 'undecidedVote'
    },
    {
      name: '% ISC',
      parameter: 'undecidedISCPercent'
    },
  ];
  columnsToDisplay;
  displayDynamicColumns: DynamicColumnDefinition[] = [];

  constructor(private dashboardAuCustodialReconciliationTableService: DashboardAuCustodialReconciliationTableService) {
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    return this.dashboardAuCustodialReconciliationTableService.getData(this.projectId).subscribe(
      data => {
        if(!data.length) { return; }
        this.projectType = data[0].projectTypeId;
        this.columnsToDisplay = this.projectType === ProjectTemplateTypes.ShareHolderMeeting ?
          this.resolutionContentColumns : this.bitContentColumns;
        this.items = this.extractUniqueResolution(data);
        const custodians = this.groupByCustodianId(data);
        this.items.forEach(item => {
          this.columnsToDisplay.forEach(row => {
            const coldef = new DynamicColumnDefinition();
            coldef.colName = `${item.itemId}_${row.parameter}`;
            coldef.colHeaderName = row.name;
            this.displayedColumns.push(`${item.itemId}_${row.parameter}`);
            this.displayDynamicColumns.push(coldef);
          });
        });

        const tableRows = Object.keys(custodians).map(key => {
          const record = custodians[key];
          const r = {
            name: record[0].custodianName
          };
          this.items.forEach(item => {
            this.columnsToDisplay.forEach(row => {
              const currentRow = record.find(rr => rr.itemId === item.itemId) || [];
              r[`${item.itemId}_${row.parameter}`] = Number(currentRow[row.parameter]);
            });
          });
          return r;
        });

        this.dataSource = new MatTableDataSource();
        this.dataSource.sort = this.sort;
        this.dataSource.data = tableRows;
        this._tableRows = tableRows;
      },
      err => {
        console.log(err);
      }
    );
  }

  getTotal = (element) => {
    return this._tableRows.map(t => t[element]).reduce((acc, value) => acc + value, 0);
  }

  extractUniqueResolution = array => array.reduce((h, obj) => h.includes(obj.itemId) ? h : [...h, {
    itemId: obj.itemId,
    colHeader: this.projectType === ProjectTemplateTypes.ShareHolderMeeting ? obj.displayField2 : obj.displayField1
  }], []).filter((value, index, self) => self.map(x => x.itemId).indexOf(value.itemId) === index)


  groupByCustodianId = array => array.reduce((h, obj) => Object.assign(h, { [obj.custodianId]: (h[obj.custodianId] || []).concat(obj) }), {});
}

export class DynamicColumnDefinition {
  colName: string;
  colHeaderName: string;
}
