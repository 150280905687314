import { VotingItemDto } from "./VotingItemDto";

export class DashboardProjectInvestorAU {
    isAlternateRow: boolean;
    isChildRow: boolean;
    projectId: number;
    projectTypeId: number;
    investorId: number;
    projectInvestorDataId: number;
    responsibility: string;
    investorName: string;
    location: string;
    shareholdingAsOf: string;
    shareholding: number;
    usageType: string;
    percentofISC: number;
    status: string;
    lastCallLog: LastCallLogInfoDto;
    followup: string;
    proxyAdvisors: ProxyAdvisorInfoDto[];
    esg: string;
    votingPlatform: string;
    decisionMakers: string;
    hasSplitVote: boolean;
    itemVotes: DashboardProjectInvestorFrontBackOfficeVote[] = [];
    teamActivities: TeamActivityDto[] = [];
}

export class LastCallLogInfoDto {
    comment: string;
    commentDate: string;
    commentType: string;
}

export class ProxyAdvisorInfoDto {
    name: string;
    dependency: string;
}

export class CampaignDashboardDto {
    projectTemplateType: number;
    votingItems: VotingItemDto[];
    projectInvestors: DashboardProjectInvestorAU[];
}

export class DashboardProjectInvestorFrontBackOfficeVote {
    displayField1 : string;
    displayField2 : string;
    itemId : number;
    frontOfficeIntentionType: string;
    frontOfficeIntention: number;
    backOfficeVotedType: string;
    backOfficeVoted: number;
}

export class TeamActivityDto{
    activity: string;
    projectTeamActivityId: string;
    status: string;
    projectInvestorDataId: number;
    taskId: number;
    sartDate: Date;
}
