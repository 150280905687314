import { ResponsibilityUserObject } from "./base/ResponsibilityUserObject";

export class ProxyAdvisor extends ResponsibilityUserObject {
    proxyAdvisorId: number;
    proxyAdvisorParentGroupId: number;
    proxyAdvisorParentGroupName: string;
    name: string;
    address: string;
    city: string;
    state: string;
    country: string;
    postalZipCode: string;
    phone: string;
    email: string;
    website: string;
    comments: string;
    proxyVotingGuideline: string;
    votingPlatform: string;
    coverage: string;
    corporateGovernance: string;
    shareHolderMeeting: string;
    engagementPolicy: string;
    shortName: string;
    managedBy: number;

}
