export class InvestorValueCountryGridSourceDto {
    investorId: number;
    lookup: InvestorValueCountryGridLookupDto[];
    records: InvestorValueCountryGridItemDto[];
    extensionFields: InvestorIntelGridExtensionFieldDefinition[];
}

export class InvestorValueCountryGridLookupDto {
    id: number;
    name: string;
    iso3: string;
    country: string;
    responsibilityUser: string;

    constructor(init = {}) {
      Object.assign(this, init);
    }
}


export class InvestorValueCountryGridItemDto {
    id: number;
    relatedEntityId: number;
    name: string;
    iso3: string;
    country: string;
    extensionFields: InvestorIntelGridExtensionField[];
    participationPolicy: string;
    dtcCode : string;
}

export class InvestorIntelGridAddDto {
    id: number;
    investorId: number;
    relatedEntityId: number;
    relatedEntityName: string;
    iso3: string;
    country: string;
    accountNumbers: AccountNumber[] = [];
    extensionFields: InvestorIntelGridExtensionField[];
    participationPolicy: string;
}

export class AccountNumber {
  id: number;
  number: string;
  deleted: boolean;

  constructor(init = {}) {
    Object.assign(this, init);
  }
}

export class InvestorIntelGridExtensionFieldDefinition {
    name: string;
    inputType: string;
    lookup: InvestorValueCountryGridLookupDto[];
    isReadOnly: boolean;
}

export class InvestorIntelGridExtensionField {
    name: string;
    value: string;
}
