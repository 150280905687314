import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  usrid : number; 
  rolename: string;
  coverage: string; 
  username: string;
  constructor( protected http: HttpClient,private router: Router) { }

  ngOnInit() {
    alert('Calling HomeComponent');
   
  }


  

}