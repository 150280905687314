import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-overvote-confirm-dialog',
  templateUrl: './overvote-confirm-dialog.component.html',
  styleUrls: ['./overvote-confirm-dialog.component.scss']
})
export class OvervoteConfirmDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<OvervoteConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onConfirm(isConfirm: boolean) {
    this.dialogRef.close(isConfirm);
  }
}
