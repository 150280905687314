import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Client} from '@app/dto/Client';
import {map, publishReplay, refCount} from 'rxjs/operators';
import { ClientProject } from '@app/dto/ClientProject';
import { ClientFinancial } from '@app/client/clientdetail/client-financials/ClientFinancial';
import { IdName } from '@app/investor/investordetail/investor-intel-esg/investor-intel-esg-survey/IdName';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  private cache$: Observable<Client[]>[] = [];

  constructor(private http: HttpClient) {
  }

  public getClient(id: any, useCache: boolean = false): Observable<Client> {
    const url = `${environment.serverUrl}clients/${id}`;
    if (!this.cache$[url] || !useCache) {
      this.cache$[url] = this.http
        .get<Client>(url).pipe(
          map(data => data),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        );
    }

    return this.cache$[url];
  }

  public getClientProjects(clientid: number): Observable<ClientProject[]> {
    const url = `${environment.serverUrl}clients/client-projects-by-id/${clientid}`;
    return this.http.get<ClientProject[]>(url);
  }

  clearCache() {
    this.cache$ = null;
  }

  public getClientFinancials(clientId: number): Observable<ClientFinancial[]> {
    const url = `${environment.serverUrl}clients/get-client-financials/${clientId}`;
    return this.http.get<ClientFinancial[]>(url);
  }

  public addClientFinancial(clientFinancial: ClientFinancial): Observable<ClientFinancial> {
    const url = `${environment.serverUrl}clients/${clientFinancial.clientId}/financial`;

    return this.http.post<ClientFinancial>(url, clientFinancial);
  }

  public editClientFinancial(clientFinancial: ClientFinancial): Observable<ClientFinancial> {
    const url = `${environment.serverUrl}clients/${clientFinancial.clientId}/financial/${clientFinancial.clientFinancialId}`;

    return this.http.put<ClientFinancial>(url, clientFinancial);
  }

  public deleteClientFinancial(clientFinancial: ClientFinancial): Observable<ClientFinancial> {
    const url = `${environment.serverUrl}clients/${clientFinancial.clientId}/financial/${clientFinancial.clientFinancialId}`;

    return this.http.delete<ClientFinancial>(url);
  }

  public getClientTypes(): Observable<IdName[]> {
    const url = `${environment.serverUrl}clients/client-types`;

    return this.http.get<IdName[]>(url);
  }
}
