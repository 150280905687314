export class UIPermissionDTO
{
    public iD: number;
    public permissionName: string;
    public permissionDescription: string;
}

export class UIRolePermissionAssignmentDTO
{
    public iD: number;
    public aDRoleKe: string;
    public assignedPermissionId:string;
    public assignedPermission : UIPermissionDTO
}

export class UIRolePermissionRequest
{
    public  aDRoleKey:string;
}
