export class UsActivityDashboardGeneralInfo {
    isAvailable: boolean;
    activities: UsActivityItemInfo[]
}

export class UsActivityItemInfo {
    task: string;
    status: string;
    completedDate: Date;
    user: string;
}