import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ShareHoldersAlternativeNames } from '@app/dto/ShareHoldersAlternativeNames';
import { AlternativeNameTypeEnum } from '@app/enums/AlternativeNameTypeEnum';

@Injectable({
  providedIn: 'root'
})
export class ShareholderalternativenamesService {

  constructor(private http: HttpClient) { }

  
  public deleteAlternativeName(id:number){
    return this.http.delete(
        `${environment.serverUrl}shareholderalternativenames/deactivate-shareholderalternativename/${id}`);
  }

  
  public getShareHolderAlternativeNames(shareHolderId:number, type : AlternativeNameTypeEnum) {
    return this.http.get<ShareHoldersAlternativeNames []>(
      `${environment.serverUrl}shareholderalternativenames/shareholderalternativenames/${shareHolderId}/${type}`); 
  }
}
