import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GenericEndpointSertvice {
    constructor(private http: HttpClient) {
    }

    public getById<T>(urlPath: string, id: string): Observable<T> {
        return this.http.get<T>(`${environment.serverUrl}${urlPath}/${id}`).pipe(
            map(data => data),
            publishReplay(1), // this tells Rx to cache the latest emitted
            refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        );
    }

    public create<T>(urlPath: string, model: T): Observable<T> {
        return this.http.post<T>(`${environment.serverUrl}${urlPath}`, model);
    }

    public update<T>(urlPath: string, model: T): Observable<T> {
        return this.http.put<T>(`${environment.serverUrl}${urlPath}`, model);
    }

    public delete(urlPath: string, id: number): Observable<any> {
        const url = `${environment.serverUrl}${urlPath}/${id}`;
        return this.http.delete(url);
    }
}
