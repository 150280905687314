import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {MatSort, MatTableDataSource} from '@angular/material';
import { EsgService } from '@app/services/esg.service';
import { DashboardEsg } from '@app/dto/DashboardEsg';

@Component({
  selector: 'app-esg-summary-table',
  templateUrl: './esg-summary-table.component.html',
  styleUrls: ['esg-summary-table.component.scss']
})

export class EsgSummaryTableComponent implements OnInit {
  dataSource;
  @ViewChild(MatSort) sort: MatSort;
  @Input('projectIdNumber') projectId:number;
  displayDynamicColumns: DynamicColumnDefinition[] = [
    {
      colName: 'name',
      colHeaderName: 'Name',
    },
    {
      colName: 'iscPercentAsString',
      colHeaderName: '% OF ISC'
    }
  ];
  displayedColumns: string[] = [];
  noItemsText:string='Esgs';
  showGrid:boolean;

  constructor(private esgService: EsgService) {
  }


  ngOnInit() {
    this.setData();
  }

  setData() {
    this.dataSource = new MatTableDataSource();
    if (this.projectId) {
      this.esgService.getEsgsByProjectId(this.projectId).subscribe(data => {
        data.forEach(esg => esg.iscPercentAsString = esg.iscPercent.toLocaleString('en', {
          minimumFractionDigits: 2 }));
        this.dataSource.data = data;
        this.showGrid = data.length > 0;
      })
    }    

    this.dataSource.sort = this.sort;
    this.displayedColumns = this.displayDynamicColumns.map(column => column.colName);
  }
}

export class DynamicColumnDefinition {
  colName: string;
  colHeaderName: string;
}
