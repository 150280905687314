import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AlertService} from '@app/services/alert.service';
import {NameValueModel} from '@app/models/basic-structures';
import {FormControl} from '@angular/forms';
import {ExternalAdvisorDto} from '@app/dto/ExternalAdvisorDto';
import {AdminExternalAdvisorService} from '@app/services/admin/admin-external-advisor.service';
import {Lookup} from '@app/dto/Lookup';
import {LookupFilter, Utils} from '@app/helper/utils';
import {StartupdataproviderService} from '@app/service/startupdataprovider.service';

@Component({
  selector: 'app-external-advisors-add-edit',
  templateUrl: './external-advisors-add-edit.component.html',
  styleUrls: ['external-advisors-add-edit.component.scss'],
})
export class ExternalAdvisorsAddEditComponent implements OnInit, AfterViewInit {
  loading = false;
  _memoryModel: ExternalAdvisorDto;
  _model: ExternalAdvisorDto = new ExternalAdvisorDto();
  util = new Utils(this.startupdataproviderService);
  typeOfAdvisors: Lookup[] = [];

  @Input() set model(value) {
    if (value) {
      this.loading = true;
      this._model = value;
      this._memoryModel = JSON.parse(JSON.stringify(value));
    }
  }

  @ViewChild('genericForm') genericForm: any;
  @Input() isEdit = false;
  @Input() dialog = false;
  @Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isFormSaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  websiteControl = new FormControl();

  constructor(private alertService: AlertService,
              private startupdataproviderService: StartupdataproviderService,
              private adminExternalAdvisorService: AdminExternalAdvisorService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.getUtilsData();
  }

  edit(flag) {
    this.isEdit = flag;
    if (!flag) {
      this.reset();
    }
  }

  reset() {
    if (this._memoryModel) {
      this._model = JSON.parse(JSON.stringify(this._memoryModel));
    } else {
      this.genericForm.reset();
    }
  }

  save() {
    const modelAction = {
      action: 'create',
      successMsg: 'External Advisor has been added successfully!'
    };
    if (this._model.externalAdvisorId > 0) {
      modelAction.action = 'update';
      modelAction.successMsg = 'External Advisor has been updated successfully!';
    }
    this.saveExternalAdvisor(modelAction);
  }

  saveExternalAdvisor({action, successMsg}) {
    this.adminExternalAdvisorService[action](this._model).subscribe(
      data => {
        this._memoryModel = data;
        this.alertService.sendSuccess(successMsg);
        this.isFormSaved.emit(data);
      },
      err => {
        this.alertService.sendError(err.error.Message);
      }
    );
  }

  countrySelectionChanged(newCountry: NameValueModel) {
    this._model.country = newCountry.name;
  }

  citySelectionChanged(city: NameValueModel) {
    this._model.city = city.name;
  }

  ngAfterViewInit() {
    this.genericForm.statusChanges.subscribe(value => {
      const valid = value === 'VALID';
      this.isFormValid.emit(valid);
    });
  }

  getUtilsData() {
    this.util.getLookupByType(LookupFilter.EXTADV)
      .subscribe(data => {
        this.typeOfAdvisors = data;
      });
  }

}
