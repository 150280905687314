import { UserService } from './../../../services/user.service';
import { DocumentsService } from './../../../services/documents.service';
import { DocumentsComponent } from './../documents.component';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlertService } from '@app/services/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentDto } from '../DocumentDto';
import { DocumentDialogConfiguration } from '../document-dialog-configuration';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-load-file-dialog',
  templateUrl: './load-file-dialog.component.html',
  styleUrls: ['./load-file-dialog.component.scss']
})
export class LoadFileDialogComponent implements OnInit {
  dialogConfiguration: DocumentDialogConfiguration = new DocumentDialogConfiguration();
  relatedEntityId: number;
  relatedEntityType: number;
  childDto: DocumentDto = new DocumentDto();
  inMemory: false;

  @ViewChild('uploadForm') uploadForm: NgForm;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DocumentsComponent>,
    private alertService: AlertService,
    private documentsService: DocumentsService,
    private userService: UserService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.relatedEntityId = this.data.entityId;
    this.relatedEntityType = this.data.entityType;
    this.dialogConfiguration = this.data.dialogConfiguration ? this.data.dialogConfiguration : this.dialogConfiguration;
    this.inMemory = this.data.inMemory;

    this.documentsService.documentEmitted$.subscribe(data => {
      if (data.dto) {
        this.childDto.file = data.dto.file;
        this.childDto.fileDescription = data.dto.fileDescription;
        this.childDto.fileName = data.dto.fileName;
        this.childDto.customField = data.dto.customField;
        this.childDto.link = data.dto.link;
      } else {
        this.childDto = new DocumentDto();
      }
    });
  }

  UploadFiles() {
    if (this.inMemory) {
      this.documentsService.uploadDocumentInMemory(this.childDto);
      this.dialogRef.close();
    } else {
      this.spinner.show();
      const fileToUpload: FormData = this.documentsService.setDocumentProps(this.childDto.file,
        this.childDto.fileDescription,
        this.relatedEntityId,
        this.relatedEntityType,
        this.userService.getUserProfile(),
        this.childDto.customField,
        this.childDto.link);

      return this.documentsService.uploadDocument(fileToUpload)
        .subscribe((response: boolean) => {
          if (response) {
            this.childDto = undefined;
            this.documentsService.emitDocument({ dto: this.childDto });
            this.alertService.sendSuccess("Document Uploaded!");
          } else {
            this.alertService.sendError("Document Upload failed!");
          }
          this.spinner.hide();
          this.dialogRef.close();
        },
          err => this.alertService.sendError(err)
        );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  disableSaveBtn(configuration: DocumentDialogConfiguration): boolean {
    return !configuration.isDocumentUploadOptional ? this.uploadForm.form.invalid || !this.childDto.file :
      this.uploadForm.form.invalid || (!this.childDto.file && !this.childDto.fileDescription);
  }
}
