import { ChangeDetectorRef, Component, Inject, OnInit, AfterContentChecked } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProxyAdvisorRecommendationComment } from '@app/dto/ProxyAdvisorRecommendationComment';

@Component({
  selector: 'app-proj-recom-comment-navigation',
  templateUrl: './proj-recom-comment-navigation.component.html',
  styleUrls: ['./proj-recom-comment-navigation.component.scss']
})
export class ProjRecomCommentNavigationComponent implements OnInit, AfterContentChecked {
  public title: string;
  public model :ProxyAdvisorRecommendationComment = new ProxyAdvisorRecommendationComment();
  public isFormValid = false;
  public viewModeOnly: boolean;

  constructor( @Inject(MAT_DIALOG_DATA) public data: ProxyAdvisorRecommendationComment,
  private dialogRef: MatDialogRef<ProjRecomCommentNavigationComponent>,
  private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.model= this.data;
    if(this.data.id >0 ){
      this.title =  'COMMENT';
      this.viewModeOnly = true;
    }
    else {
      this.title =  'Add Comment';
      this.viewModeOnly = false;
    }
  }

  setFormValidation(valid) {
    this.isFormValid = valid;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  onCancelBtnClick($event): void {
    this.dialogRef.close({ data: $event });
  }

  onCloseBtnClick($event){
    this.onCancelBtnClick($event);
  }
}
