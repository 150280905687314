import { IdName } from "./IdName";

export class EsgSurvey{
    id:number;
    investorId:number;
    timing?:string;
    periodInDays?:number;
    lastSubmissionDate?:Date;
    nextSubmissionDate?:Date;
    thematicFocuses: IdName[];
}