import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ProjectActivity} from '@app/dto/ProjectActivity';
import {Lookup} from '@app/dto/Lookup';
import {ProjectTask} from '@app/dto/ProjectTask';
import {ProjectActivityService} from '@app/services/project-activity.service';
import {StartupdataproviderService} from '@app/service/startupdataprovider.service';
import {LookupFilter, Utils} from '@app/helper/utils';
import {ProjectActivityConstants} from '../../constants';

@Component({
  selector: 'app-project-activity-edit-dialog',
  templateUrl: './project-activity-edit-dialog.component.html',
  styleUrls: ['./project-activity-edit-dialog.component.scss']
})

export class ProjectActivityEditDialogComponent implements OnInit {
  model: ProjectActivity;
  statusDisabled : boolean = false;

  activities: Lookup[] = [];
  activityTypes: Lookup[] = [];
  projectTasks: ProjectTask[] = [];
  filteredProjectTasks: ProjectTask[] = [];
  investorsTasks: ProjectTask[] = [];
  activityStatuses: string[] = [];
  util = new Utils(this.startupdataproviderService);
  activityTypeSingleResponsibilityValue: string = ProjectActivityConstants.activityTypesValues.activityTypeSingleResponsibilityValue;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProjectActivityEditDialogComponent>,
    private projectActivityService: ProjectActivityService,
    private startupdataproviderService: StartupdataproviderService,) {
    this.model = data.model;
    this.util.getLookupByType(LookupFilter.PRJACT).subscribe(r=> this.activities = r);
    this.util.getLookupByType(LookupFilter.PRJACTTYP).subscribe(r => this.activityTypes = r);
    this.projectTasks = data.projectTasks;
    this.investorsTasks = data.investorsTasks;
    this.activityStatuses = data.activityStatuses;
    this.filteredProjectTasks = this.projectTasks.filter(a => a.lookUpId === this.model.activityId);
  }

  ngOnInit() {
    this.statusDisabled = this.model.responsibilityUser.displayName === 'Investor Specific' && this.investorsTasks.find(x => x.taskId === this.model.taskId) ? true : false;
    this.setActivityType();
  }

  setActivityType() {
    if(this.model.responsibilityUser.displayName === 'Investor Specific' && this.investorsTasks.find(x => x.taskId === this.model.taskId)) {
      this.model.activityType = ProjectActivityConstants.activityTypesValues.activityTypeWholeTeamResponsibilityValue;
    } else if(this.model.responsibilityUser.displayName === 'Project Team') {
      this.model.activityType = ProjectActivityConstants.activityTypesValues.activityTypeDuplicateForEachTeamMemberValue;
    } else if(this.model.responsibilityUser.displayName === 'Whole team') {
      this.model.activityType = ProjectActivityConstants.activityTypesValues.activityTypeWholeTeamResponsibilityValue;
    } else {
      this.model.activityType = ProjectActivityConstants.activityTypesValues.activityTypeSingleResponsibilityValue;
    }
  }

  onProjectActivityChange(data) {
    this.projectTeamsSelectionChange(false);
    this.filteredProjectTasks = this.projectTasks.filter(a => a.lookUpId === data);
  }

  projectTeamsSelectionChange(checked: boolean) {
    this.model = this.projectActivityService.assignProjectTeamResponsibility(this.model, checked);
  }

  onSaveBtnClick(){
    this.model.activityName = this.activities.find(a => a.lookUpId === this.model.activityId).fieldLabel;
    this.model.taskName = this.filteredProjectTasks.find(a => a.taskId === this.model.taskId).taskDesc;
    this.dialogRef.close({data: this.model});
  }
}
