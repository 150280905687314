import { Component } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html'
})
export class FileuploadComponent {
  public progress: number;
  public message: string;
  constructor(private http: HttpClient) { }

  upload(files) {
    if (files.length === 0) {
      return;
    }
    const formData = new FormData();

    for (let file of files) {
      formData.append(file.name, file);
    }    
    //FileUploadController
    let apiURL: string = environment.serverUrl + 'fileuploads/UploadFile';
    const uploadReq = new HttpRequest('POST', apiURL, formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
    if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
    } else { 
    if (event.type === HttpEventType.Response) {
        this.message = event.body.toString();
    }
    }
    });
  }
}