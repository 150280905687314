import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSelectChange, MatOptionSelectionChange} from '@angular/material';
import {StartupdataproviderService} from '@app/service/startupdataprovider.service';
import {InitiativesService} from '@app/services/initiatives.service';
import {GenericEndpointSertvice} from '@app/services/generic-endpoints.service';
import {AlertService} from '@app/services/alert.service';
import {EndpointConstants} from '@app/services/constants/endpoint.constants';
import {InvestorIntelItem} from '@app/dto/InvestorIntelItem';
import {RegionCountryMultiselectHelper} from '@app/helper/RegionCountryMultiselectHelper';
import {NgModel} from '@angular/forms';
import {AppConstants} from '@app/app.constants';
import {Observable, combineLatest} from 'rxjs';
import {DocumentConfiguration} from '@app/components/documents/document-configuration';
import {DocumentDialogConfiguration} from '@app/components/documents/document-dialog-configuration';
import {LookupFilter, Utils} from '@app/helper/utils';
import {DocumentRelatedEntityType} from '@app/enums/DocumentRelatedEntityType';
import {Lookup} from '@app/dto/Lookup';
import {DocumentUploadType} from '@app/components/documents/DocumentUploadType';
import {InvestorsService} from '@app/services/investors';
import {MasterDataService} from '@app/services/master-data.service';
import {ResolutionsClassificationService} from '@app/components/autocompletes/resolutions-classification/resolutions-classification.service';

@Component({
  selector: 'app-exclusion-rule-dialog',
  templateUrl: './exclusion-rule-dialog.component.html',
  styleUrls: ['./exclusion-rule-dialog.component.scss']
})
export class ExclusionRuleDialogComponent implements OnInit {
  isCreate: boolean = true;
  popupTitle: string;
  model: InvestorIntelItem = new InvestorIntelItem();
  impactsList: string[];
  groupedCountries: { [key: string]: string[]; };
  util = new Utils(this.startupdataproviderService);
  sectors: any;
  thematicFocusPickerData = {
    noRecordsText: 'There are still not thematic focus selected',
    noRecordsLink: 'Edit the thematic focus...',
    allRecords: [],
    selectedRecords: [],
    titleNotSelected: 'Thematic focus options'
  };
  availableCompanies = {
    noRecordsText: 'There are still not companies selected',
    noRecordsLink: 'Edit the affected companies...',
    allRecords: [],
    selectedRecords: [],
    titleNotSelected: '',
    availableItemsText: 'Available Companies',
    selectAllChosenItemsText: 'Select All Chosen Companies',
    searchPlaceholder: 'Search by Company Name...',
    search: true,
    categoryCheck: true
  };
  regionalSectorSelectionEvent: MatOptionSelectionChange;
  shouldUseRouteParamsInDocuments: boolean = false;
  documentRelatedEntityType: DocumentRelatedEntityType = DocumentRelatedEntityType.InvestorIntelItem;
  documentConfiguration: DocumentConfiguration;
  areDocumentsInMemory: boolean = true;
  createdEntityId?: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ExclusionRuleDialogComponent>,
              private startupdataproviderService: StartupdataproviderService,
              private alertService: AlertService,
              private initiativeService: InitiativesService,
              private genericEndpointService: GenericEndpointSertvice,
              private masterDataService: MasterDataService,
              private resolutionsClassificationService: ResolutionsClassificationService) {
  }

  ngOnInit() {
    this.isCreate = this.data.isCreate;
    this.popupTitle = this.data.title;
    if (this.isCreate) {
      this.model.investorId = this.data.id;
      combineLatest(this.getThematicFocusOptionsObservable(), this.getAvailableCompaniesObservable(),
        (thematicFocuses, _availableCompanies) => ({thematicFocuses, _availableCompanies}))
        .subscribe(pair => {
          this.thematicFocusSubscriber(pair.thematicFocuses);
          this.availableCompaniesSubscriber(pair._availableCompanies);
        });
      // this.getThematicFocusOptionsObservable().subscribe(this.thematicFocusSubscriber.bind(this));
    } else {
      this.areDocumentsInMemory = false;
      combineLatest(this.getByIdObservable(this.data.id), this.getThematicFocusOptionsObservable(), this.getAvailableCompaniesObservable(),
        (item, thematicFocuses, _availableCompanies) => ({item, thematicFocuses, _availableCompanies}))
        .subscribe(pair => {
          this.thematicFocusSubscriber(pair.thematicFocuses);
          this.availableCompaniesSubscriber(pair._availableCompanies);
          this.getByIdSubscriber(pair.item);

        });
    }

    this.initialiseUtils();
    this.buildDocumentConfiguration();
  }

  initialiseUtils() {
    this.util.getLookupByType(LookupFilter.GICSECTOR)
      .subscribe(data => this.sectors = data);

    this.groupedCountries = this.util.getCountriesByRegionGroup();

    this.util.getLookupByType(LookupFilter.EXCIMP)
      .subscribe((data: Lookup[]) => {
        this.impactsList = data.map((item: Lookup) => {
          return item.fieldValue;
        });
      });
  }

  buildDocumentConfiguration() {
    this.documentConfiguration = new DocumentConfiguration();
    this.documentConfiguration.gridName = 'Sources';
    this.documentConfiguration.columnsNames = ['Source document', 'Source description', 'Source Link'];
    this.documentConfiguration.documentComponentOuterDivMargins = 'mt-5';
    this.documentConfiguration.uploadBtnText = 'ADD SOURCE';
    this.documentConfiguration.uploadBtnIconClass = 'ms-plus-icon';
    this.documentConfiguration.uploadType = DocumentUploadType.SOURCE;

    this.documentConfiguration.dialogConfiguration = new DocumentDialogConfiguration();
    this.documentConfiguration.dialogConfiguration.dialogTitle = 'Add New Source';
    this.documentConfiguration.dialogConfiguration.descriptionFieldLabel = 'SOURCE DESCRIPTION';
    this.documentConfiguration.dialogConfiguration.showLink=true;
    this.documentConfiguration.dialogConfiguration.isDocumentUploadOptional = true;
  }

  getAvailableCompaniesObservable(): Observable<any> {
    return this.masterDataService.getCompaniesBySector();
  }

  availableCompaniesSubscriber(data) {
    this.availableCompanies.allRecords = data;
  }

  getThematicFocusOptionsObservable(): Observable<any> {
    return this.resolutionsClassificationService.getData();
  }

  thematicFocusSubscriber(data) {
    this.thematicFocusPickerData.allRecords = data;
  }

  getThematicFocusSelection(ids) {
    if (!this.thematicFocusPickerData.allRecords.length) {
      return [];
    }
    const selection = [];
    for (const option of this.thematicFocusPickerData.allRecords) {
      if (ids.includes(option.id.toString())) {
        selection.push(JSON.parse(JSON.stringify(option)));
      }
    }
    return selection;
  }

  getAffectedCompaniesSelection(names) {
    if (!this.availableCompanies.allRecords.length) {
      return [];
    }
    const selection = [];
    for (const option of this.availableCompanies.allRecords) {
      if (names.includes(option.name)) {
        selection.push(JSON.parse(JSON.stringify(option)));
      }
    }

    return selection;
  }

  getByIdObservable(id): Observable<InvestorIntelItem> {
    return this.genericEndpointService.getById<InvestorIntelItem>(EndpointConstants.EXCLUSION_RULE, id);
  }

  getByIdSubscriber(data) {
    const regionFocuses = new Set<string>(data.regionFocuses);
    RegionCountryMultiselectHelper.selectRegions(this.groupedCountries, regionFocuses);
    data.regionFocuses = Array.from(regionFocuses);
    this.thematicFocusPickerData.selectedRecords = this.getThematicFocusSelection(data.thematicFocuses);
    this.availableCompanies.selectedRecords = this.getAffectedCompaniesSelection(data.affectedCompanies);
    this.model = data;
  }

  getContinentLabel(region: string) {
    return AppConstants.continentLabelMapping[region];
  }

  onCountrySelectionChange(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.regionalSectorSelectionEvent = event;
    }
  }

  onContinentSelectionChange(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.regionalSectorSelectionEvent = event;
    }
  }

  selectionChange(event: MatSelectChange, select: NgModel) {
    const value: string = this.regionalSectorSelectionEvent.source.value;
    const isRegion = !!this.groupedCountries[this.regionalSectorSelectionEvent.source.value];
    const isSelected: boolean = this.regionalSectorSelectionEvent.source.selected;
    const resultToEmit = new Set<string>(event.value);
    if (isRegion) {
      RegionCountryMultiselectHelper.handleRegionSelection(this.groupedCountries, resultToEmit, value, isSelected);
    } else {
      RegionCountryMultiselectHelper.handleCountrySelection(this.groupedCountries, resultToEmit, value, isSelected);
    }
    const result = Array.from(resultToEmit);
    select.update.emit(result);
  }

  onCloseBtnClick(): void {
    this.dialogRef.close();
  }

  onSave() {
    this.model.regionFocuses = RegionCountryMultiselectHelper.cleanRegionFocuses(this.groupedCountries, this.model.regionFocuses);
    this.saveThematicFocusSelection();
    this.saveAffectedCompaniesSelection();

    if (this.isCreate) {
      this.genericEndpointService.create<InvestorIntelItem>(EndpointConstants.EXCLUSION_RULE, this.model).subscribe(
        data => {
          this.alertService.sendSuccess('Rule Created!');
          this.createdEntityId = data.investorIntelItemId;
          this.dialogRef.close();
        },
        err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        }
      );
    } else {
      this.genericEndpointService.update<InvestorIntelItem>(EndpointConstants.EXCLUSION_RULE, this.model).subscribe(
        data => {
          this.model = data;
          this.alertService.sendSuccess('Rule Updated!');
          this.dialogRef.close();
        },
        err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        }
      );
    }
  }

  saveThematicFocusSelection() {
    this.model.thematicFocuses = [];
    for (const option of this.thematicFocusPickerData.selectedRecords) {
      this.model.thematicFocuses.push(option.id);
    }
  }

  saveAffectedCompaniesSelection() {
    this.model.affectedCompanies = [];
    for (const option of this.availableCompanies.selectedRecords) {
      this.model.affectedCompanies.push(option.name);
    }
  }

  getDisplayName(select : NgModel){
    return RegionCountryMultiselectHelper.getDisplayName(select.model, this.groupedCountries)
  }
}
