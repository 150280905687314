export class InvestorInitiative {
    esgInitiativeId:number;
    name: string;
    impacts: string[];
    activated: boolean;
    deactivatedDate?: Date;
    summary: string;
    hasSectorFocus: boolean = false;
    sectorFocuses: string[];
    hasRegionFocus: boolean = false;
    regionFocuses: string[];
    hasThematicFocus: boolean = false;
    thematicFocuses: string[];
    parentEsgInitiativeId: number;
    investorId: number;
}
