import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CustodiansPerProjectPost, ProjectCustodianVoteItem } from '@app/dto/ProjectCustodians';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { CustodiansService } from '@app/services/custodians.service';
import { ProjectTemplateTypes } from '@app/enums/ProjectTemplateTypesEnum';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { AlertService } from '@app/services/alert.service';
import { UserService } from '@app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { Lookup } from '@app/dto/Lookup';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DirtyComponent } from '@app/common/dirty-component';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypeAheadSearchComponent } from '@app/components/autocompletes/type-ahead-search/type-ahead-search.component';
import { map, finalize } from 'rxjs/operators';
import { ProjectsService } from '@app/services/projects.service';

@Component({
  selector: 'app-project-custodial-reconciliation',
  templateUrl: './project-custodial-reconciliation.component.html',
  styleUrls: ['project-custodial-reconciliation.component.scss']
})
export class ProjectCustodialReconciliationComponent implements OnInit, AfterViewInit, DirtyComponent {
  @Input() projectId: number;
  @Input() isEditable = true;
  @Input() private useCaching: boolean;
  @ViewChild(MatSort) sort: MatSort;

  data;
  deleteVoteIds = [];
  typeColors: any[] =
    [
      { type: 'FOR', color: '#159347' },
      { type: 'AGAINST', color: '#EA5657' },
      { type: 'ABSTAIN', color: '#999999' },
      { type: 'UNDECIDED', color: '#EC7A08' },
      { type: 'UNDISCLOSED', color: '#00A5D3' },
      { type: 'ACCEPT', color: '#159347' },
      { type: 'DECLINE', color: '#EA5657' },
      { type: 'NOT VOTING', color: '#1E1E1E' },
      { type: 'DEFAULT', color: '#000000' }
    ];
  columns = [
    {
      parameter: 'voteType',
      name: 'Intention'
    },
    {
      parameter: 'vote_isc',
      name: 'Shares (ISC%)',
    },
  ];
  displayedColumns: string[] = [
    'name'
  ];
  dataSource: MatTableDataSource<any>;
  displayDynamicColumns: DynamicColumnDefinition[] = [];
  projectType: ProjectTemplateTypes;
  custodians = [];
  totalColumns = [];
  voteTitle: string;
  isEdit = false;
  intentionTypes: Lookup[] = [];
  columnEditType = ColumnEditType;
  userId: number;
  rolename: string;
  coverage: string;

  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();
  canEditProject = false;
  canCreateProject = false;

  custodiansPerProjectPost: CustodiansPerProjectPost = new CustodiansPerProjectPost();
  @ViewChild(TypeAheadSearchComponent) typeAheadSearch: TypeAheadSearchComponent;

  constructor(private custodiansService: CustodiansService,
    private projectTemplateService: ProjectTemplateService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private userService: UserService,
    private route: ActivatedRoute,
    private roleGuardService: RoleGuardService,
    private dialogsService: DialogsService,
    private spinnerService: NgxSpinnerService,
    private projectService: ProjectsService) {
  }

  public ngOnInit(): void {
    this.spinnerService.show('custodial-reconciliation');
  }

  public ngAfterViewInit(): void {
    // execute in the next event loop
    setTimeout(() => {
      this.userId = 1;
      this.rolename = this.userService.getUserRoleName();
      this.coverage = this.userService.getUserCoverage();
      this.route.parent.params.subscribe((params) => {
        this.projectId = +params['id'];
        this.projectService.getProjectType(this.projectId).subscribe(data => {
          this.canEditProject = this.roleGuardService.hasAccessByProjectType('projects', 'edit', data.name);
          this.canCreateProject = this.roleGuardService.hasAccess('projects', 'create');
        });

        if (this.isEditable) {
          this.columns.push({
            parameter: 'actions',
            name: 'Actions'
          });
        }
        this.getProjectTemplate();
      });

      this.isDirty$.subscribe(data => {
        return of(data);
      });
    }, 0);
  }

  getProjectTemplate() {
    this.projectTemplateService.getProjectTemplate(this.projectId).subscribe(data => {
      this.intentionTypes = this.projectTemplateService.getCustodialReconciliationVotedTypes(data.template);
    }, err => {
      this.alertService.sendError(' error ' + JSON.stringify(err));
    });
    this.getData();
  }

  getData() {
    return this.custodiansService.getCustodiansVotesByProject(this.projectId, this.useCaching).subscribe(
        data => {
          this.deleteVoteIds = [];
          this.data = data;
          this.projectId = data.projectId;
          this.projectType = data.projectTypeId;
          this.voteTitle = this.projectType === ProjectTemplateTypes.ShareHolderMeeting ? 'Resolutions' : 'Bid companies';
          this.custodians = this.groupByCustodianVoteItemId(data.custodians);
          this.setTableData(data);
          this.spinnerService.hide('custodial-reconciliation');
        },
        err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
          this.spinnerService.hide('custodial-reconciliation');
        }
      );
  }

  setTableData(data) {
    /* First part
    *  We define the columns in our table
    */
    this.setColumns(data);
    // End First Part


    /*Second Part
    * This part put the real data to the table
    */
    this.setTableRows(data);
    // End of Second Part
  }

  setColumns(data) {
    const displayedColumns = [
      'name'
    ];
    const displayDynamicColumns = [];

    this.custodians.forEach(custodian => {
      this.columns.forEach(row => {
        const coldef = new DynamicColumnDefinition();
        coldef.colName = `${custodian.custodianId}_${row.parameter}`;
        coldef.colHeaderName = row.name;
        coldef.colHeaderColor = 'inherit';
        if (row.parameter !== 'actions') {
          coldef.colEdit = true;
          coldef.colEditType = row.parameter === 'voteType' ? ColumnEditType.SELECT : ColumnEditType.INPUT;
        }
        coldef.colCustodian = custodian.custodianId;
        coldef.isGlobalCustodian = custodian.isGlobalCustodian;
        displayedColumns.push(`${custodian.custodianId}_${row.parameter}`);
        displayDynamicColumns.push(coldef);
      });
    });

    this.totalColumns = this.extractUniqueVoteTypes(data.voteItems);
    this.totalColumns.forEach(voteType => {
      this.columns.forEach(row => {
        if (row.parameter === 'voteType' || row.parameter === 'actions') {
          return;
        }
        const coldef = new DynamicColumnDefinition();
        coldef.colName = `${voteType}_${row.parameter}`;
        coldef.colHeaderName = row.name;
        coldef.colHeaderColor = this.getVoteTypeColor(voteType);
        coldef.colTotal = true;
        displayedColumns.push(`${voteType}_${row.parameter}`);
        displayDynamicColumns.push(coldef);
      });
    });

    this.displayedColumns = displayedColumns;
    this.displayDynamicColumns = displayDynamicColumns;
  }

  setTableRows(data) {
    const tableRows = data.voteItems.reduce((accumulator, item, currentIndex) => {
      const isDark = currentIndex % 2 === 1;
      const record = {
        name: this.projectType === ProjectTemplateTypes.ShareHolderMeeting ?
          `${item.displayField1} ${item.displayField2}` : item.displayField1,
        isDark: isDark,
        itemId: item.itemId,
        projectId: data.projectId,
        projectTypeId: data.projectTypeId,
        index: 0,
        isChild: !!item.parentDisplayField1,
      };
      // This parameter is used to define the additional rows which need to be added to the table grouped per itemId
      const moreRows = [];

      this.custodians.forEach(custodian => {
        const votesForItem = (custodian.votes[item.itemId] || []).sort(function (a, b) {
          return a.id - b.id;
        });
        votesForItem.forEach((vote, index) => {
          const moreRecords = {};
          this.columns.forEach(row => {
            const value = {
              id: vote.id,
              itemId: vote.itemId,
              value: row.parameter === 'vote_isc' ? { vote: vote.vote, isc: vote.isc } : vote[row.parameter],
              parameter: row.parameter,
              color: this.getTypeColor(vote[row.parameter]),
              isEditable: ['voteType', 'vote_isc'].indexOf(row.parameter) > -1,
              removeIt: index > 0 // this is how we identify if the action button need to be SPLIT or REMOVE
            };
            if (index === 0) {
              record[`${custodian.custodianId}_${row.parameter}`] = value;
            } else {
              // If the voted for the item are more then one then these are the new split records
              moreRecords[`${custodian.custodianId}_${row.parameter}`] = value;
            }
          });
          // And lets add a new row for the same itemId
          if (index > 0) {
            moreRecords['index'] = index;
            moreRecords['itemId'] = item.itemId;
            moreRows.push(moreRecords);
          }
        });
      });
      // Here are and the records for the total columns
      this.totalColumns.forEach(voteType => {
        const votesForTotal = data.voteItems.find(vote => vote.itemId === item.itemId);
        const voteTypeObj = votesForTotal.voteTotals.find(vote => vote.voteType === voteType);
        this.columns.forEach(row => {
          if (row.parameter === 'voteType' || row.parameter === 'actions') {
            return;
          }
          const value = {
            value: row.parameter === 'vote_isc' ? { vote: voteTypeObj.vote, isc: voteTypeObj.isc } : voteTypeObj[row.parameter],
            color: 'inherit'
          };
          record[`${voteType}_${row.parameter}`] = value;
        });
      });
      accumulator.push(record);
      // So we need to add and the additional rows
      if (moreRows.length > 0) {
        moreRows.reduce((results, row) => {
          // If we already has a row with this index for the itemId, we just add the new columns to the row
          const resultIndex = results.findIndex(r => r.itemId === row.itemId && r.index === row.index);
          if (resultIndex > -1) {
            results[resultIndex] = { ...results[resultIndex], ...row };
          } else {
            // Or add a new row with the needed columns
            results.push(row);
          }
          return results;
        }, []).forEach((row) => {
          // and let mark if the row have to be with the background of the parent
          row.isDark = isDark;
          accumulator.push(row);
        });
      }
      return accumulator;
    }, []);

    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.dataSource.data = tableRows;
  }

  splitVote(vote, dynColumn) {
    const idx = new Date().valueOf();
    const itemId = vote[dynColumn.colName].itemId,
      custodianId = dynColumn.colCustodian,
      isGlobalCustodian = dynColumn.isGlobalCustodian,
      _data = this.dataSource.data;

    /* Lets find how much are the rows for the selected item and get the next index parameter.
    *  We using this `index` to identify, when split the vote, if we need to add a new row to the table
    *  or we just need to add the new columns to already exist row
    */
    const allForItem = _data.filter(data => data.itemId === itemId);
    const nextIndex = Math.max.apply(Math, allForItem.filter(item => item[`${custodianId}_voteType`]).map(function (rec) {
      return rec.index || 0;
    })) + 1;

    const newRecord = this.columns.reduce((res, column) => {
      res[`${custodianId}_${column.parameter}`] = {
        id: idx,
        itemId: itemId,
        custodianId: custodianId,
        isGlobalCustodian: isGlobalCustodian,
        projectId: vote.projectId,
        projectTypeId: vote.projectTypeId,
        userId: 0,
        value: column.parameter === 'vote_isc' ? { vote: 0, isc: 0 } : null,
        parameter: column.parameter,
        color: this.getTypeColor(''),
        isEditable: ['voteType', 'vote_isc'].indexOf(column.parameter) > -1,
        removeIt: true,
        new: true
      };

      return res;
    }, {});

    // Check if we already has a row in table and just extend the columns
    if (allForItem[nextIndex]) {
      const resultIndex = _data.findIndex(r => r.itemId === allForItem[nextIndex].itemId && r.index === allForItem[nextIndex].index);
      _data[resultIndex] = { ...allForItem[nextIndex], ...newRecord };
    } else {
      // Else we need to add a new row to table
      const lastItem = allForItem[allForItem.length - 1];
      // We check in whole records what have to be the index of the desired new row
      const resultIndex = _data.findIndex(r => r.itemId === lastItem.itemId && r.index === lastItem.index) + 1;
      // And we add a new row to the table right after the last item for the item and custodian
      _data.splice(resultIndex, 0, {
        isDark: lastItem.isDark,
        itemId: itemId,
        index: nextIndex,
        ...newRecord
      });
    }

    this.dataSource.data = _data;
  }

  removeVote(vote, dynColumn) {

    const itemId = vote[dynColumn.colName].itemId,
      index = vote.index,
      voteId = vote[dynColumn.colName].id,
      custodianId = dynColumn.colCustodian;
    let _data = this.dataSource.data;

    // Add this item for deleting if it is not a new
    if (!vote[dynColumn.colName].new) {
      this.deleteVoteIds.push(voteId);
    }

    // Find the item for the selected custodian and remove it
    const findIndex = this.dataSource.data.findIndex(data => data.itemId === itemId && data.index === index);
    this.columns.forEach(column => {
      delete this.dataSource.data[findIndex][`${custodianId}_${column.parameter}`];
    });

    // Now we need to move all items below the removed one step up and reindex them
    const allForItem = _data.filter(data => data[dynColumn.colName] && data[dynColumn.colName].itemId === itemId);
    // This parameter contain all items which need to be moved/transferred
    const transfer = [];
    const columns = this.columns;
    allForItem.forEach(function (row, idx) {
      if (!row.index) {
        return;
      }
      const _findIndex = _data.findIndex(data => data.itemId === row.itemId && data.index === row.index);
      columns.forEach(column => {
        if (!transfer[idx]) {
          transfer[idx] = [];
        }
        transfer[idx][`${custodianId}_${column.parameter}`] = _data[_findIndex][`${custodianId}_${column.parameter}`];
        delete _data[_findIndex][`${custodianId}_${column.parameter}`];
      });
    });

    // This will remove the row from table if no vote have into it
    _data = _data.filter(data => Object.keys(data).length > 3);


    // Let's re-add all items again for the custodian and re-index these
    const _allForItem = _data.filter(data => data.itemId === itemId);
    const nextIndex = Math.max.apply(Math, _allForItem.filter(item => item[`${custodianId}_voteType`]).map(function (rec) {
      return rec.index || 0;
    })) + 1;

    let i = nextIndex;
    transfer.forEach(function (item) {
      if (_allForItem[i]) {
        const resultIndex = _data.findIndex(r => r.itemId === _allForItem[nextIndex].itemId && r.index === _allForItem[nextIndex].index);
        _data[resultIndex] = { ..._allForItem[nextIndex], ...item };
      } else {
        const lastItem = _allForItem[_allForItem.length - 1];
        const resultIndex = _data.findIndex(r => r.itemId === lastItem.itemId && r.index === lastItem.index) + 1;

        _data.splice(resultIndex, 0, {
          isDark: lastItem.isDark,
          itemId: itemId,
          index: i,
          ...item
        });

      }
      i++;
    })
    this.dataSource.data = _data;
  }

  checkMax(vote, dynColumn) {
    const itemId = vote[dynColumn.colName].itemId,
      _data = this.dataSource.data;

    const allForItem = _data.filter(data => data[dynColumn.colName] && data[dynColumn.colName].itemId === itemId);

    return this.intentionTypes.length < allForItem.length + 1;

  }

  isActionColumn(columnName) {
    return /actions/.test(columnName);
  }

  removeCustodian(item) {
    this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: 'Custodian', message: item.custodianName }) });
    this.dialogsService.close$.subscribe(async res => {
      if (res) {
        this.custodiansService.deleteCustodiansFromProject(this.projectId, item.custodianId, item.isGlobalCustodian).subscribe(result => {
          if (result) {
            this.getData();
          }
        });
      }
    });
    return true;
  }

  onCancelClick() {
    this.isEdit = false;
    this.isDirty.next(false);
    this.getData();
  }

  onSaveBtnClick() {
    this.isEdit = false;
    this.isDirty.next(false);
    const _data = this.dataSource.data;
    const result = Object.values(_data).reduce((_result, item) => {
      return [..._result, ...Object.values(item).filter(rec => rec && typeof rec === 'object' && rec['isEditable'])];
    }, []).reduce((accumulator, item) => {
      const findItem = accumulator.findIndex(a => a.id === item.id);
      if (findItem > -1) {
        if (item.parameter === 'vote_isc') {
          accumulator[findItem] = { ...accumulator[findItem], ...item.value };
        } else {
          accumulator[findItem][item.parameter] = item.value;
        }
      } else {
        let row = {
          id: item.id,
          itemId: item.itemId,
          custodianId: item.custodianId,
          isGlobalCustodian: item.isGlobalCustodian,
          projectTypeId: item.projectTypeId,
          new: item.new
        };
        if (item.parameter === 'vote_isc') {
          row = { ...row, ...item.value };
        } else {
          row[item.parameter] = item.value;
        }
        accumulator.push(row);
      }
      return accumulator;
    }, []).map(item => {
      item.id = item.new ? null : item.id;
      delete item.new;
      item.projectId = this.projectId;
      return item;
    });

    this.spinnerService.show();
    this.custodiansService.deleteCustodianVotes(this.deleteVoteIds).pipe(
      finalize(() => { this.spinnerService.hide(); })
    ).subscribe(
      x => {
        this.deleteVoteIds = [];
        this.custodiansService.updateCustodianVotes(result).subscribe(
          data => {
            this.alertService.sendSuccess('Project was updated successfully!');
            this.getData();
          }, err => {
            this.alertService.sendError(' error ' + JSON.stringify(err));
          });
      });
  }

  onEdit() {
    this.isEdit = true;
    this.isDirty.next(true);
  }

  addCustodianBtn() {
    if (this.custodiansPerProjectPost.custodian !== undefined && this.custodiansPerProjectPost.custodian.custodianId > 0) {
      this.custodiansPerProjectPost.projectId = this.projectId;
      this.custodiansPerProjectPost.userId = this.userId;

      this.custodiansService.saveCustodiansPerProject(this.custodiansPerProjectPost).subscribe(
        data => {
          if (data) {
            this.alertService.sendSuccess('Update Successful!');
            this.getData();
            this.resetcustodianFields();
          };
        },
        err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        }
      );
    }
  }

  onCustodianSelected($event) {
    this.custodiansPerProjectPost.custodian = $event;
  }

  resetcustodianFields() {
    this.typeAheadSearch.resetValue();
    this.custodiansPerProjectPost.custodian = undefined;
  }

  compareFn(c1: Lookup, c2: Lookup): boolean {
    return c1 === c2;
  }

  getClassName(voteType) {
    return 'vote ' + (voteType || '').toLowerCase().replace(/ /g, '_');
  }

  getTypeColor(type: string) {
    if (!type) {
      return 'inherit';
    }
    return (this.typeColors.find(item => item.type === type) || { color: 'inherit' }).color;
  }

  groupByItemId = array => array.reduce((h, obj) => Object.assign(h, { [obj.itemId]: (h[obj.itemId] || []).concat(obj) }), {});
  groupByCustodianVoteItemId = array => array.map(a => {
    a.votes = this.groupByItemId(a.votes);
    return a;
  });
  getVoteTypeColor = voteType => (this.typeColors.find(vote => vote.type === voteType) || { color: 'inherit' }).color;
  extractUniqueVoteTypes = array => array.reduce((h, obj) => [...h, ...obj.voteTotals.map(o => o.voteType)], [])
    .filter((value, index, self) => self.indexOf(value) === index);
}

export class DynamicColumnDefinition {
  colName: string;
  colHeaderName: string;
  colHeaderColor: string;
  colEdit = false;
  colEditType: ColumnEditType | null = null;
  colTotal = false;
  colCustodian?: number;
  isGlobalCustodian: boolean;
}

enum ColumnEditType {
  SELECT = 1,
  INPUT = 2
}
