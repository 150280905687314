import { Injectable, OnInit } from '@angular/core';
import { DashboardEsg } from '@app/dto/DashboardEsg';
import { AppConfigService } from './app-config.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EsgService implements OnInit{

  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) { }

  ngOnInit() {
   
}

  public getEsgsByProjectId(projectId:number): Observable<DashboardEsg[]>{
    let apiURL = this.appConfigService.getActiveWebApiHost() + 'esgs/EsgsByProjectId/' + projectId;
    return this.httpClient.get<DashboardEsg[]>(apiURL);
    };
}
