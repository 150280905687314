import { FilterDto } from '@app/service/search-grid/SearchDto';
import { GridFilterItem } from "@app/components/grid-filter/grid-filter.models";
import { ActiveDirectoryUser } from "./ActiveDirectoryUser";

export class InvestorBulkEditResponsibility {
    targetResponsibilityUser: ActiveDirectoryUser;
    investorsToChange : InvestorsToChange [];
    areAllForUpdate : boolean;
    filters : FilterDto[]= [];
}

export class InvestorsToChange
{
    sourceResponsibilityUser :number;
    sourceInvestorId : number;
}