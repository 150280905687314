import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {CallLog} from '@app/dto/CallLog';
import {Lookup} from '@app/dto/Lookup';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ContactDisplayType} from '@app/dto/ContactDisplayType';
import {MsAdalAngular6Service} from 'microsoft-adal-angular6';
import {DashboardService} from '@app/services/dashboard/dashboard.service';
import {AlertService} from '@app/services/alert.service';
import {UserService} from '@app/services/user.service';
import { NgForm } from '@angular/forms';
import {finalize} from 'rxjs/operators';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { LookupFilter, Utils } from '@app/helper/utils';

@Component({
  selector: 'app-investor-call-management-add-comment',
  templateUrl: './investor-call-management-add-comment.component.html',
  styleUrls: ['investor-call-management-add-comment.component.scss']
})

export class InvestorCallManagementAddCommentComponent implements OnInit {
  model: CallLog;
  callLogCommentTypes: Lookup[] = [];
  _contact: ContactDisplayType;
  coverage: string;
  saving = false;
  util = new Utils(this.startUpDataProvider);
  @Output() addFeedbackCall = new EventEmitter();

  @ViewChild('addCommentForm') addCommentForm: NgForm;

  @Input()
  set projectId(data) {
    this.model.projectId = data;
  }

  @Input()
  set investorId(data) {
    this.model.investorId = data;
  }

  @Input()
  set contact(data) {
    if (data) {
      this.model.contactName = data.fullName;
      this.model.contactId = data.contactId;
      this.model.commentType = data.contactTypeCode;
    }

    const {userInfo: {profile: {name: username = ''}}} = this.msadService;
    this.model.username = username;
  }

  isDirty = false;
  @Output() public getAddCommentFormState = new EventEmitter<boolean>();

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private dashboardService: DashboardService,
              private alertService: AlertService,
              private userService: UserService,
              private msadService: MsAdalAngular6Service,
              private startUpDataProvider : StartupdataproviderService) {

    this.model = new CallLog();
    this.matIconRegistry.addSvgIcon(
      'icon_calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/navigation-icons/calendar.svg')
    );
  }

  ngOnInit() {
    this.util.getLookupByType(LookupFilter.COMMENTTYPE).subscribe(data => this.callLogCommentTypes = data);
    this.addCommentForm.valueChanges.subscribe(value => {
      this.isDirty = this.addCommentForm.dirty;
      this.getAddCommentFormState.emit(this.isDirty);
    });

    this.model.entryDate = new Date();
    this.model.coverage = this.userService.getUserCoverage();
  }

  saveCallFeedback() {
    if (this.saving) {
      return;
    }

    this.saving = true;
    const self = this;
    this.model.updateTime = new Date();

    this.dashboardService.saveCallFeedback(this.model).pipe(finalize(() => {
      this.saving = false;
    })).subscribe(
      data => {
        this.addFeedbackCall.emit({...this.model, callLogId: data});
        this.alertService.sendSuccess('Feedback is created.');

        const newModel = new CallLog();
        newModel.projectId = this.model.projectId;
        newModel.investorId = this.model.investorId;
        newModel.username = this.model.username;
        newModel.coverage = this.model.coverage;
        newModel.entryDate = new Date();
        this.addCommentForm.resetForm(newModel);
        //need to set the date to undefined as otherwise angular is setting it to null
        //and it is afterwards converted to date time min in js (1st Jan 1970)
        this.model.followup = undefined;
        this.isDirty = false;
        this.getAddCommentFormState.emit(this.isDirty);
      },
      err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      }
    );
  }
}
