import { Component, Input, OnInit } from '@angular/core';
import { ProjectTemplateService } from '@app/service/projectTemplateService';

@Component({
  selector: 'app-voting-history-vote-details',
  templateUrl: './voting-history-vote-details.component.html',
  styleUrls: ['./voting-history-vote-details.component.scss']
})
export class VotingHistoryVoteDetailsComponent implements OnInit {

  @Input() votes: any[];
  constructor(private projectTemplateService: ProjectTemplateService) { }

  ngOnInit() { }

  getColorByVoteType(voteType: string): string {
    if (!voteType) return '';
    return this.projectTemplateService.votedtypecolors.find(c => c.type === voteType).color;
  }

}
