import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  FilterAndPaginateOutputDTO,
  FilterAndPaginateDTO,
  PaginationDTO
} from '@app/components/table-filter-wrapper/interfaces';
import { InvestorVotingSummaryService } from '@app/services/investors';
import { InvestorVotingSummaryAdapter } from '@app/dto/investor/investor-voting-summary.interface';

@Component({
  selector: 'app-dashboard-voting-history',
  templateUrl: './dashboard-voting-history.component.html',
  styleUrls: ['./dashboard-voting-history.component.scss']
})
export class DashboardVotingHistoryComponent implements OnInit {
  investorId: number;
  projectId: number;
  public investorVotingSummary: InvestorVotingSummaryAdapter;
  public proxyAdvisorsNamesSubject: Subject<string[]> = new Subject();
  public shareClassificationSubject: Subject<string> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private investorVotingSummaryService: InvestorVotingSummaryService
  ) { }

  ngOnInit() {
    this.route.params.pipe(first()).subscribe((params) => {
      this.investorId = +params['invid'];
      this.projectId = +params['id'];

      this.getInvestorVotingSummary(this.investorId);
    });
  }

  private getInvestorVotingSummary(investorId: number): void {
    this.investorVotingSummaryService
      .getInvestorVotingSummary(
        investorId,
        new FilterAndPaginateOutputDTO(
          new FilterAndPaginateDTO({
            pagination: new PaginationDTO(1, 10),
          })
        )
      ).subscribe((response) => {
        this.investorVotingSummary = response;
        this.proxyAdvisorsNamesSubject.next(response.proxyAdvisorsNames);
        this.shareClassificationSubject.next('Total');
      });
  }
}
