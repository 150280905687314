import {AfterContentChecked, ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {RoleGuardService} from '@app/auth/role-guard.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AddEditCustodialComponent} from '@app/project/projectdetail/custodial-list/add-edit-custodial/add-edit-custodial.component';

@Component({
  selector: 'app-custodial-dialog',
  templateUrl: './custodial-dialog.component.html',
  styleUrls: ['./custodial-dialog.component.scss']
})
export class CustodialDialogComponent implements OnInit, AfterContentChecked {
  public _formValid = false;
  public update = false;
  hasAccess = false;
  @ViewChild('custodial') custodial: AddEditCustodialComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private roleGuardService: RoleGuardService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CustodialDialogComponent>
  ) {
    if (data.projectCustodianId) {
      this.update = true;
    }
  }

  ngOnInit() {
    this.hasAccess = this.roleGuardService.hasAccess('PROJECTS', 'EDIT');
  }

  setFormValidation(valid) {
    this._formValid = valid;
  }

  formSaved(flag) {
    this.dialogRef.close(flag);
  }

  onClickReset() {
    this.custodial.reset();
  }

  // this is necessary to fix `ExpressionChangedAfterItHasBeenCheckedError`
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
}
