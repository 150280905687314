export enum ContactType {
  ClearingHouse = 1,
  GlobalCustodian = 2,
  Investor = 3,
  LocalCustodian = 4,
  NotDefined = 5,
  OtherInvestor = 6,
  PrivateInvestor = 7,
  ProxyAdvisor = 8,
  RetailInvestor = 9,
  Esg = 10,
  Customer = 11,
  ExternalAdvisor = 12
}
