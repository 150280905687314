import { Component, OnInit, Input } from '@angular/core';
import { GenericEndpointSertvice } from '@app/services/generic-endpoints.service';
import { EndpointConstants } from '@app/services/constants/endpoint.constants';
import { InvestorIntelItem } from '@app/dto/InvestorIntelItem';
import { Observable } from 'rxjs';
import { AlertService } from '@app/services/alert.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LookupFilter, Utils } from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { ResolutionsClassificationService } from '@app/components/autocompletes/resolutions-classification/resolutions-classification.service';
import { AppConstants } from '@app/app.constants';

@Component({
  selector: 'app-esg-focus-disclosure',
  templateUrl: './esg-focus-disclosure.component.html',
  styleUrls: ['./esg-focus-disclosure.component.scss']
})
export class EsgFocusDisclosureComponent implements OnInit {

  @Input() investorId: string;
  @Input() label: string;
  @Input() focusType: string;
  @Input() disabled: boolean;

  util = new Utils(this.startupdataproviderService);
  model: InvestorIntelItem;
  hasFocus: boolean = false;
  sectors: any;

  thematicFocusType: string = AppConstants.INVESTOR_REPORTS_TYPES.INVESTOR_ESG_THEMATIC_FOCUS_DISCLOSURE;
  sectorFocusType: string = AppConstants.INVESTOR_REPORTS_TYPES.INVESTOR_ESG_SECTOR_FOCUS_DISCLOSURE;

  thematicFocusPickerData = {
    noRecordsText: 'There are still not thematic focus selected',
    noRecordsLink: 'Edit the thematic focus...',
    allRecords: [],
    selectedRecords: [],
    titleNotSelectedViewInDisableMode: true,
    titleNotSelected: 'Thematic Focus:'
  };

  constructor(private genericEndpointService: GenericEndpointSertvice,
    private resolutionsClassificationService: ResolutionsClassificationService,
    private http: HttpClient,
    private startupdataproviderService: StartupdataproviderService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.getEsgDisclosureByIdSubscriber();

    this.util.getLookupByType(LookupFilter.GICSECTOR)
      .subscribe(data => this.sectors = data);
  }

  private initializeModelData() {
    this.model.investorId = +this.investorId;
    this.model.name = this.label;
    this.model.itemType = this.focusType;
    this.model.hasSectorFocus = this.model.sectorFocuses !== undefined && this.model.sectorFocuses.length > 0;

    this.saveThematicFocusSelection();
    this.model.hasThematicFocus = this.model.thematicFocuses !== undefined && this.model.thematicFocuses.length > 0;
  }

  onFocusChange(value: boolean) {
    this.hasFocus = value;
  }

  onSaveFocusDisclosure() {
    if (this.hasFocus) {
      this.initializeModelData();
      this.upsertEsgDisclosure(this.model).subscribe(data => {
        let message = `${this.label} successfully ${(this.model.investorIntelItemId ? 'updated' : 'created')}!`;
        this.alertService.sendSuccess(message);

        this.getEsgDisclosureByIdSubscriber();
      }, err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });
    } else {
      if (this.model.investorIntelItemId) {
        this.deleteEsgDisclosure(this.model.investorIntelItemId).subscribe(data => {
          this.alertService.sendSuccess(`${this.label} successfully deleted!`);

          this.getEsgDisclosureByIdSubscriber();
        }, err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });
      } else {
        this.model = new InvestorIntelItem();
      }
    }
  }

  public getEsgDisclosureByIdSubscriber() {
    this.getEsgDisclosureById(+this.investorId, this.focusType).subscribe(data => {
      if (data) {
        this.model = data;
        this.hasFocus = true;
      } else {
        this.model = new InvestorIntelItem();
        this.thematicFocusPickerData.selectedRecords = [];
        this.hasFocus = false;
      }

      this.thematicFocusPickerData = this.resetThematicFocusPickerData();
      this.getThematicFocusOptions();
    })
  }

  getThematicFocusOptions() {
    this.resolutionsClassificationService.getData().subscribe(
      data => {
        this.thematicFocusPickerData.allRecords = data;
        if (this.model.thematicFocuses) {
          this.thematicFocusPickerData.selectedRecords = this.getThematicFocusSelection(this.model.thematicFocuses);
          this.thematicFocusPickerData = JSON.parse(JSON.stringify(this.thematicFocusPickerData));
        }
      }
    );
  }

  private getThematicFocusSelection(ids) {
    if (!this.thematicFocusPickerData.allRecords.length) {
      return [];
    }

    const selection = [];
    for (const option of this.thematicFocusPickerData.allRecords) {
      if (ids.includes(option.id.toString())) {
        selection.push(JSON.parse(JSON.stringify(option)));
      }
    }

    return selection;
  }

  saveThematicFocusSelection() {
    this.model.thematicFocuses = [];
    for (const option of this.thematicFocusPickerData.selectedRecords) {
      this.model.thematicFocuses.push(option.id);
    }
  }

  private resetThematicFocusPickerData(): any {
    return {
      noRecordsText: 'There are still not thematic focus selected',
      noRecordsLink: 'Edit the thematic focus...',
      allRecords: [],
      selectedRecords: [],
      titleNotSelectedViewInDisableMode: true,
      titleNotSelected: 'Thematic Focus:'
    }
  }

  private getEsgDisclosureById(investorId: number, focusType: string): Observable<InvestorIntelItem> {
    return this.http.get<InvestorIntelItem>(`${environment.serverUrl}${EndpointConstants.FOCUS_DISCLOSURE}/${investorId}/${focusType}`);
  }

  private upsertEsgDisclosure(item: InvestorIntelItem): Observable<InvestorIntelItem> {
    return this.genericEndpointService.create<InvestorIntelItem>(EndpointConstants.FOCUS_DISCLOSURE, item);
  }

  private deleteEsgDisclosure(id: number): Observable<any> {
    return this.genericEndpointService.delete(EndpointConstants.FOCUS_DISCLOSURE, id);
  }
}
