import { Component, OnInit, Input } from '@angular/core';
import { EsgSustainableInvestorResourcesField } from '@app/dto/EsgSustainableInvestorResourcesFields';
import { InvestorsIntelligenceService } from '@app/services/investors';
import { AlertService } from '@app/services/alert.service';

@Component({
  selector: 'app-sustainable-investor-resources-fields',
  templateUrl: './sustainable-investor-resources-fields.component.html',
  styleUrls: ['./sustainable-investor-resources-fields.component.scss']
})
export class SustainableInvestorResourcesFieldsComponent implements OnInit {
  @Input()
  investorId: number;

  isEdit: boolean = false;
  model: EsgSustainableInvestorResourcesField = new EsgSustainableInvestorResourcesField();

  constructor(
    private investorsIntelligenceService: InvestorsIntelligenceService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.investorsIntelligenceService.getEsgSustainableFields(this.investorId)
      .subscribe((data) => {
        this.model = data;
        this.model.investorId = this.investorId;

        this.isEdit = false;
      });
  }

  onEditBtnClick() {
    this.isEdit = true;
  }

  onCancelBtnClick() {
    this.getData();
  }

  public onSaveBtnClick() {
    this.investorsIntelligenceService.upsertEsgSustainableFields(this.model).subscribe(data => {
      let message = `Sustainable Investor Resource ${this.model.investorEsgSustainableResourcesFieldId ? 'updated' : 'created'}!`;
      this.alertService.sendSuccess(message);

      this.isEdit = false;
    }, err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });
  }
}
