import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Alert } from './../models/basic-structures';

@Injectable({
  providedIn: 'root'
})

//NOTES: The Alert type field for Bootstrap alerts is a string and has the following acceptable values:
//  Success, Info, Warning, Danger, Primary, Secondary, Dark, Light
export class AlertService {
  private subject = new Subject<Alert>();
  constructor() { }

  //Set forceManualClose to TRUE if you do not want the message to close automatically.
  sendInfo(msg: string, forceManualClose: boolean = false) {
    let tmpAlert: Alert = { type: "info", message: msg, forceManualClose: forceManualClose };
    this.sendAlert(tmpAlert);
  }

  sendSuccess(msg: string, forceManualClose: boolean = false) {
    let tmpAlert: Alert = { type: "success", message: msg, forceManualClose: forceManualClose };
    this.sendAlert(tmpAlert);
  }

  sendWarning(msg: string, forceManualClose: boolean = false) {
    let tmpAlert: Alert = { type: "warning", message: msg, forceManualClose: forceManualClose };
    this.sendAlert(tmpAlert);
  }

  sendError(msg: string, forceManualClose: boolean = false) {
    let tmpAlert: Alert = { type: "danger", message: msg, forceManualClose: forceManualClose };
    tmpAlert.forceManualClose = true;
    this.sendAlert(tmpAlert);
  }

  transformError(error: any): string {
    let errorMessage: string;
    if (error.hasOwnProperty('Message')) {
      errorMessage = error.Message;
    } else {
      const arrayOfErrorMessages: string[] = [];
      // tslint:disable-next-line: forin
      for (const key in error) {
        for (const message of error[key]) {
          arrayOfErrorMessages.push(message);
        }
      }

      errorMessage = arrayOfErrorMessages.join('. ');
    }

    return errorMessage;
  }

  alert(e: any) {

    if ((e.error || {}).InnerMessages) {
      for (const msg of e.error.InnerMessages) {
        this.sendError(msg);
      }
    } else if (e.error) {
      const errorMessage = this.transformError(e.error.errors || e.error);
      this.sendError(errorMessage);
    } else {
      this.sendError(e.message);
    }
  }

  sendAlert(alert: Alert) {
    this.subject.next(alert);
  }

  clearAlerts() {
    this.subject.next();
  }

  getAlert(): Observable<Alert> {
    return this.subject.asObservable();
  }
}
