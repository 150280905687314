import { RoleGuardService } from './../../../../auth/role-guard.service';
import {AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AlertService} from '@app/services/alert.service';
import {AdminEsgService} from '@app/services/admin/admin-esg.service';
import {Esg} from '@app/dto/Esg';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'app-esg-pa-general',
  templateUrl: './esg-pa-general.component.html',
  styleUrls: ['esg-pa-general.component.scss'],
})
export class EsgPaGeneralComponent implements OnInit, AfterViewChecked {
  esgId: number;
  public _formValid = false;
  isEdit: boolean = false;
  model: Esg;
  @ViewChild('esg') esg: any;
  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();
  hasPermission: boolean = false;

  constructor(private route: ActivatedRoute,
              private alertService: AlertService,
              private cdRef : ChangeDetectorRef,
              private adminEsgService: AdminEsgService,
              private roleGuardService: RoleGuardService) {
  }

  ngOnInit() {
    this.hasPermission = this.roleGuardService.hasAccess('ADMIN', 'EDIT')
    this.route.parent.params.subscribe((params) => {
      this.esgId = +params['id'];
      this.isDirty$.subscribe(data => {
        return of(data);
      });
      this.getEsgRecord();
    });
  }

  getEsgRecord() {
    this.adminEsgService.get(this.esgId).subscribe(data => {
        this.model = data;
      },
      err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      });
  }

  edit(flag) {
    this.isEdit = flag;
    this.esg.edit(flag);

    this.isDirty.next(this.isEdit);
  }

  setFormValidation(valid) {
    this._formValid = valid;
  }

  formSaved(flag) {
    this.isDirty.next(false);
    this.edit(false);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
