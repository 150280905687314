export class CustodiansLink {
    linkedLocalCustodianId?: number | null;
    localCustodianId: number;
    globalCustodianId: number;
    globalCustodianName: string | null;
    country: string;
    subAccount: string;
    notes: string;
    dtcCodeId?: number | null;
    dtcCode: string | null;

    public constructor(init?: Partial<CustodiansLink>) {
        Object.assign(this, init);
      }
}
