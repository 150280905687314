import { NOBORHVotingSummary } from './NOBORHVotingSummary';

export class NOBORHSummary {
    investorId: number;
    id: number;
    investorName: string;
    addresses: string[];
    email: string;
    phoneNumber: string;
    postalCode: string;
    investorTypeDescription: string;
    voteStatus: string;
    liveVote: string;
    shares: number;
    cusip: string;
    howVotedDescription: string;
    votedDate: Date;
    controlNumber: string;
    callSequenceNumber: number;
    voteType: string;
    votingSummary: NOBORHVotingSummary[] = [];
    source: string;
    emailMaterialStatus: InNOBORNEmailMaterialStatus;

    constructor(init = {}) {
      Object.assign(this, init);
    }
}

export class InNOBORNEmailMaterialStatus {
  status: string;
  statusUpdateDate: null | string;
  updatedBy: null | string;
  requestMaterialsDate: null | Date;
}

export class OutNOBORNEmailMaterialStatus {
  status: string;
  investorId: number;
}
