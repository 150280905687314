import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICustodianVotesImportFile } from './custodian-votes-import-file.interface';

@Injectable({
  providedIn: 'root'
})
export class CustodianVotesImportDialogService {
  files$: BehaviorSubject<ICustodianVotesImportFile[]>;
  files: Array<ICustodianVotesImportFile> = [];
  constructor() {
    this.files$ = new BehaviorSubject([]);
    this.files = [];
  }

  public getAll() {
    this.files$.next(this.files);
  }

  public add(file: ICustodianVotesImportFile) {
    this.files.push(file);
    this.files$.next(this.files);
  }

  public edit(file: ICustodianVotesImportFile) {
    const findElem = this.files.find(p => p.index === file.index);
    findElem.fileSource = file.fileSource;
    this.files$.next(this.files);
  }

  public remove(index: number) {
    this.files = this.files.filter(p => {
      return p.index !== index;
    });

    this.files$.next(this.files);
  }

  public clear() {
    this.files = [];

    this.files$.next(this.files);
  }
}
