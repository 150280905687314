import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { InvestorIntelItem } from '@app/dto/InvestorIntelItem';
import { FilterDto } from '@app/service/search-grid/SearchDto';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { InvestorInitiativeGridComponent } from '../../investor-intel-common-grid/investor-intel-common-grid.component';
import { EndpointConstants } from '@app/services/constants/endpoint.constants';
import { AppConstants } from '@app/app.constants';
import { ArticlesOpinionsDialogComponent } from './articles-opinions-dialog/articles-opinions-dialog.component';

@Component({
  selector: 'app-esg-articles-opinions',
  templateUrl: './esg-articles-opinions.component.html',
  styleUrls: ['./esg-articles-opinions.component.scss']
})
export class EsgArticlesOpinionsComponent implements OnInit {

  @Input()
  investorId: number;
  @ViewChild(InvestorInitiativeGridComponent) gridChild: InvestorInitiativeGridComponent;

  displayedGridColumns: string[] = ['name', 'impact', 'sectorFocus', 'regionalFocus', 'summary', 'actions'];
  additionalGridFilter: FilterDto[];
  gridRequestUrl = EndpointConstants.ALL_ARTICLES_OPINIONS;
  gridIdKeyName: string = 'investorIntelItemId';
  gridDeleteUrl = EndpointConstants.ARTICLES_OPINIONS;
  deleteConfirmationTitle: string = 'Article/Opinion'
  deleteMessageMappingKey: string = 'name';
  noItemsGridText = 'There are no Articles/Opinions';

  constructor(
    private httpClient: HttpClient,
    public dialog: MatDialog,
  ) {
  }
  ngOnInit() {
    this.additionalGridFilter = [
      new FilterDto({ filterPorp: "investorId", value: [this.investorId + ''] }),
      new FilterDto({ filterPorp: "itemType", value: [AppConstants.INVESTOR_INTEL_TYPES.ARTICLES_OPINIONS] })
    ];
  }

  refreshGridData() {
    this.gridChild.fetchGridData();
  }

  onEditClicked(item: InvestorIntelItem) {
    const dialogRef = this.dialog.open(ArticlesOpinionsDialogComponent, {
      width: '1000px',
      maxHeight: 'fit-content',
      data: {
        investorId: this.investorId,
        isCreate: false,
        id: item.investorIntelItemId,
        title: 'Edit Article/Opinion'
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      this.refreshGridData();
    });
  }

  onAddBtnClick() {
    const dialogRef = this.dialog.open(ArticlesOpinionsDialogComponent, {
      width: '1000px',
      maxHeight: 'fit-content',
      data: {
        id: this.investorId,
        isCreate: true,
        title: 'Add New Article/Opinion:'
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      this.refreshGridData();
    });
  }

}
