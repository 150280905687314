import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ProjectInvestorShareAdapter } from '@app/dto/ProjectInvestorShareDTO';
import { ProjectInvestorIntentionVoteAdapter } from '@app/dto/ProjectInvestorIntentionVoteDTO';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { ProjectTemplateTypes } from '@app/enums/ProjectTemplateTypesEnum';
// tslint:disable-next-line: max-line-length
import { DynamicColumnDefinition } from '@app/project/projectdetail/project-custodial-reconcilation/project-custodial-reconciliation.component';
import { VoteWithPercentage } from '@app/dashboard/dashboardau/dashboard-voting-summary/interfaces/voting-summary';
import { CommonUtilityService } from '@app/utility/common-utility.service';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { AddCustodianVotesDialogComponent } from '../add-custodian-votes-dialog/add-custodian-votes-dialog.component';

@Component({
  selector: 'app-investor-voting-table',
  templateUrl: './investor-voting-table.component.html',
  styleUrls: ['./investor-voting-table.component.scss']
})
export class InvestorVotingTableComponent implements OnInit {
  columns = [];
  displayDynamicColumns: DynamicColumnDefinition[] = [];
  staticColumns = ['source', 'shares', 'undeclaredIntentions'];
  projectId;
  investorId;
  projectType;
  votingItemTypes = [];
  isCorporateAction = false;
  isFrequency = false;
  toggler = [];
  showEditButton = false;
  shares: ProjectInvestorShareAdapter[] = [];
  public dataSource = new MatTableDataSource();
  @Input() data: ProjectInvestorShareAdapter;
  @Input() allData: ProjectInvestorIntentionVoteAdapter;
  @Output() votesEdited = new EventEmitter<void>();

  constructor(private projectTemplateService: ProjectTemplateService,
              private commonUtilityService: CommonUtilityService,
              private dialog: MatDialog) {

  }

  ngOnInit() {
    this.projectId = this.allData.projectId;
    this.investorId = this.allData.investorId;
    this.projectType = this.allData.projectType;
    this.votingItemTypes = this.allData.votingItemTypes;
    this.isCorporateAction = this.projectType === ProjectTemplateTypes.CorporateAction;
    this.isFrequency = this.projectTemplateService.hasFrequencySimple(this.votingItemTypes);
    this.setDataSource();
  }

  setDataSource() {
    const additional = this.isFrequency
      ? this.projectTemplateService.getFrequencyVoteTypes(
        false,
        'CUSTODIAL_RECONCILIATION',
        [],
        this.projectTemplateService.isFrequencyWithReverseOrderSimple(this.votingItemTypes, this.isFrequency))
      : [];
    const votingTypes = this.projectTemplateService.getInvestorVotingVotedTypes(this.projectType, additional);

    this.setColumns(votingTypes);
    this.setTableRows(votingTypes, this.data.votingItems, this.data.shares);
  }

  setColumns(votingTypes) {
    this.columns = [];
    this.displayDynamicColumns = [];
    if (!this.isCorporateAction) {
      this.columns.push('resolution');
    } else {
      this.columns.push('company');
      this.columns.push('bid');
    }
    this.columns = [...this.columns, ...this.staticColumns];
    this.columns.push('actions');

    votingTypes.forEach(votingType => {
      const coldef = new DynamicColumnDefinition();
      coldef.colName = `${votingType.fieldValue}`;
      coldef.colHeaderName = votingType.fieldLabel;
      coldef.colHeaderColor = votingType.color;

      this.columns.push(`${votingType.fieldValue}`);
      this.displayDynamicColumns.push(coldef);
    });
  }

  setTableRows(votingTypes, votingItems, totalShares) {
    let isDark = false;

    const tableRows = votingItems.reduce((accumulator, row) => {
      if (row.votingItemChild) {
        const letter = this.commonUtilityService.indexToLetter(row.votingItemChild.orderId, false);
        row.templateProperty1 = letter;
        row.templateProperty2 = row.votingItemChild.name;
      }

      this.toggler[row.votingItemId] = true;

      row.shares = totalShares;
      row.isDark = isDark;
      row.main = true;
      row.togglerKey = row.votingItemId;
      row['clickIt'] = !this.isDirectorException(row.votingItemId);

      row.undeclaredIntention = new VoteWithPercentage(row.undeclaredIntention, this.data.shares);
      votingTypes.forEach(item => {
        const result = row.votes.find(vote => vote.voteType === item.fieldValue);
        row[`${item.fieldValue}`] = new VoteWithPercentage(result, this.data.shares);
      });

      accumulator.push(row);

      // Push investor rows
      row.custodians.forEach(custodian => {
        if (this.isDirectorException(row.votingItemId)) {
          return;
        }

        const custodianShares = custodian.custodianVotes.map(v => v.vote).reduce((v1, v2) => v1 + v2, 0);

        const record = {
          custodianName: custodian.custodianName,
          custodianId: custodian.custodianId,
          undeclaredIntention: new VoteWithPercentage(0, 0),
          shares: custodianShares,
          main: false,
          togglerKey: row.votingItemId,
          togglerKeyChild: `${row.votingItemId}|${custodian.custodianId}`,
          isDark: isDark,
          isCustodianChild: false,
          clickIt: custodian.votesSnapshots.length > 0,
          allowEdit: true
        };

        this.toggler[record.togglerKeyChild] = true;
        record['isCustodianChild'] = custodian.votesSnapshots.length > 0;

        votingTypes.forEach(item => {
            const result = custodian.custodianVotes.find(vote => vote.voteType === item.fieldValue);
            record[item.fieldValue] =  new VoteWithPercentage(result, totalShares);
        });

        accumulator.push(record);

        const snapshots = this.groupBySnapshotDateTime(custodian.votesSnapshots, custodian.custodianId);
        Object.keys(snapshots).forEach((date) => {
          const snap = snapshots[date];
          const snapshotRecord = {
            custodianName: date,
            snapshot: true,
            shares: undefined,
            undeclaredIntention: undefined,
            main: false,
            togglerKey: row.votingItemId,
            togglerKeyChild: record.togglerKeyChild,
            isDark: isDark,
            allowEdit: false
          };

            votingTypes.forEach(item => {
            const result = snap.find(vote => vote.voteType === item.fieldValue);
            snapshotRecord[item.fieldValue] =  new VoteWithPercentage(result, totalShares);
          });

          accumulator.push(snapshotRecord);
        });

      });

      isDark = !isDark;

      return accumulator;
    }, []);

    this.dataSource.data = tableRows;
  }

  groupBySnapshotDateTime = (votesSnapshots, cId) => {
    const groupedSnapshots = votesSnapshots.filter(votes => votes.globalCustodianId === cId || votes.globalCustodianId === null).reduce(
      (acc, curr) => {
        if (!acc[curr.snapshotDateTime]) {
          acc[curr.snapshotDateTime] = [];
        }
        acc[curr.snapshotDateTime].push(curr);

        return acc;
      }, []);

      const sortedSnapshots = Object.keys(groupedSnapshots).sort(function (a, b) {
        return new Date(b).getTime() - new Date(a).getTime();
      });

      const result = {};

      sortedSnapshots.forEach((item) => {
        result[item] = groupedSnapshots[item];
      });

      return result;
  }

  isDirectorException(votingItemId) {
    return this.data.votingItems.findIndex(row => row.votingItemChild && row.votingItemChild.parentVotingItemId === votingItemId) > -1;
  }

  showCell(element) {
    const isDirectorException = this.isDirectorException(element.votingItemId);

    if (isDirectorException) {
      return false;
    }

    if (!element.main) {
      return false;
    }

    return true;
  }

  toggleRows = (el) => {
    if (!el.clickIt) {
      return false;
    }
    const key = el.custodianId !== undefined ? el.togglerKeyChild : el.togglerKey;
    this.toggler[key] = !this.toggler[key];
  }

  hideIt(row) {
  return (this.toggler[row.togglerKey] && !row.main) ||
  (row.togglerKeyChild && this.toggler[row.togglerKeyChild] &&
    (row.custodianId === undefined));
  }

  showToggleArrow(element) {
    return element.clickIt && element.custodianId !== undefined;
  }

  onEdit(element) {
    this.dialog.open(AddCustodianVotesDialogComponent,
      {
        width: '1200px',
        maxHeight: 'fit-content',
        data: {
          investorId: this.investorId,
          projectId: this.projectId,
          custodianName: element.custodianName,
          custodianId: element.custodianId,
          isEditMode: true
        }
      }).afterClosed().subscribe(result  => {
        if (result) {
          this.votesEdited.emit();
        }
     });
  }
}
