import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EsgSurvey } from './EsgSurvey';
import { InvestorsIntelligenceService } from '@app/services/investors';
import { Utils, LookupFilter } from '@app/helper/utils';
import { AlertService } from '@app/services/alert.service';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { InitiativesService } from '@app/services/initiatives.service';
import { IdName } from './IdName';
import {ResolutionsClassificationService} from '@app/components/autocompletes/resolutions-classification/resolutions-classification.service';

@Component({
  selector: 'app-investor-intel-esg-survey',
  templateUrl: './investor-intel-esg-survey.component.html',
  styleUrls: ['./investor-intel-esg-survey.component.scss']
})
export class InvestorIntelEsgSurveyComponent implements OnInit {

  @Input() investorId: number;
  @Output() dirtyCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  model: EsgSurvey = new EsgSurvey;
  util = new Utils(this.startupdataproviderService);
  timings: string[];
  isEdit: boolean = false;
  thematicFocusPickerData = {
    noRecordsText: 'There are still not thematic focus selected',
    noRecordsLink: 'Edit the thematic focus...',
    allRecords: [],
    selectedRecords: [],
    titleNotSelectedViewInDisableMode: true,
    titleNotSelected:'Thematic Focus:'
  };

  constructor(private service: InvestorsIntelligenceService,
              private startupdataproviderService: StartupdataproviderService,
              private alertService: AlertService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private initiativeService: InitiativesService,
              private resolutionsClassificationService: ResolutionsClassificationService) {
    this.matIconRegistry.addSvgIcon(
      'icon_calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/navigation-icons/calendar.svg')
    );
  }

  ngOnInit() {
    this.service.getEsgSurvey(this.investorId).subscribe(data => {
      this.model = data;
      this.model.investorId = this.investorId;
      this.getThematicFocusOptions();
    })

    this.util.getLookupByType(LookupFilter.TIMING)
      .subscribe(data => {
        this.timings = data.map(x => x.fieldValue);
      });
  }

  onEditBtnClick() {
    this.isEdit = true;
    this.dirtyCheck.emit(this.isEdit);
  }

  onCancelBtnClick() {
    this.isEdit = false;
    this.dirtyCheck.emit(this.isEdit);
  }

  onSaveBtnClick() {
    this.saveThematicFocusSelection();
    this.service.upsertEsgSurvey(this.model).subscribe(data =>{
      let message = 'Inhouse Esg Survey Timing successfully ' + (this.model.id ? 'updated' : 'created') + '!';
      this.alertService.sendSuccess(message);

      this.isEdit = false;
      this.dirtyCheck.emit(this.isEdit);
    }, err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });
  }

  onTimingChange(value: string) {
    if (value !== 'Other') {
      this.model.periodInDays = null;
    }
  }

  getThematicFocusOptions() {
    this.resolutionsClassificationService.getData().subscribe(
      data => {
        this.thematicFocusPickerData.allRecords = data;
        if (this.model.thematicFocuses) {
          this.thematicFocusPickerData.selectedRecords = this.getThematicFocusSelection(this.model.thematicFocuses);
          this.thematicFocusPickerData = JSON.parse(JSON.stringify(this.thematicFocusPickerData));
        }
      }
    );
  }

  private getThematicFocusSelection(ids) {
    if (!this.thematicFocusPickerData.allRecords.length) {
      return [];
    }

    const selection = [];
    for (const option of this.thematicFocusPickerData.allRecords) {
      if (ids.some(x => x.id === option.id)) {
        selection.push(JSON.parse(JSON.stringify(option)));
      }
    }

    return selection;
  }

  saveThematicFocusSelection() {
    this.model.thematicFocuses = [];
    for (const option of this.thematicFocusPickerData.selectedRecords) {
      const thematicFocus = new IdName();
      thematicFocus.id = option.id;
      thematicFocus.name = option.subcategory;

      this.model.thematicFocuses.push(thematicFocus);
    }
  }
}
