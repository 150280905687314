import {Lookup} from '../dto/Lookup';

export class StaticLookup{
    getInvestorUploadExcelTemplates(): Lookup[]
    {
        let xlsxTmpls: Lookup[] =[];
        let xlsTmplArray: Array<string>=['AU','EU','US','Other'];
        let lookup: Lookup;
        for(var i = 0; i < xlsTmplArray.length; i++){
            lookup = new Lookup();
            lookup.lookUpId = i;
            lookup.fieldType = 'INVDATAXLXSTMPL';
            lookup.fieldLabel = xlsTmplArray[i];
            lookup.fieldValue = xlsTmplArray[i];
            xlsxTmpls.push(lookup);
        }
        return xlsxTmpls;
    }

    getInvestorUploadUsageDataType(): Lookup[]
    {
        let usageDataTypes: Lookup[] =[];
        let usageDataTypeArray: Array<string>=['Bid','Vote'];
        let lookup: Lookup;
        for(var i = 0; i < usageDataTypeArray.length; i++){
            lookup = new Lookup();
            lookup.lookUpId = i;
            lookup.fieldType = 'INVUSAGEDATATYPE';
            lookup.fieldLabel = usageDataTypeArray[i];
            lookup.fieldValue = usageDataTypeArray[i];
            usageDataTypes.push(lookup);
        }
        return usageDataTypes;
    }
}