import { FilterContainerType } from '@app/components/table-filter-wrapper/enums';
import { Observable, Subject } from 'rxjs';
import { FilterDTO } from '@app/components/table-filter-wrapper/interfaces/filter-and-paginate';

export interface IFilterContainerOptions {
  title?: string;
  type: FilterContainerType;
  fieldName: string;
  options?: Subject<SelectOptions[]> | Observable<SelectOptions[]> | SelectOptions[];
  defaultOptions?: FilterDTO;
}

export class FilterContainerOptionsDTO {
  title?: string;
  defaultSearch?: any[];
  fieldName: string;
  type: FilterContainerType = FilterContainerType.TEXT;
  options: Observable<SelectOptions[]> | Subject<SelectOptions[]> |  SelectOptions[] = [];
  defaultOptions?: FilterDTO;

  public constructor(init?: Partial<IFilterContainerOptions>) {
    Object.assign(this, init);
  }
}

export class SelectOptions {
  value: string | number;
  name: string;
}

export class DynamicFilterContentComponentDto {
  data: any;
  options: any;
  values: any;
  resetForm: () => void;
  isFormValid: boolean;
}
