import { Component, OnInit, Input } from '@angular/core';
import { DocumentRelatedEntityType } from '@app/enums/DocumentRelatedEntityType';
import { DocumentConfiguration } from '@app/components/documents/document-configuration';
import { DocumentUploadType } from '@app/components/documents/DocumentUploadType';
import { DocumentDialogConfiguration } from '@app/components/documents/document-dialog-configuration';
import { AppConstants } from '@app/app.constants';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { AlertService } from '@app/services/alert.service';
import { InvestorsIntelligenceService } from '@app/services/investors';
import { FieldOptionsType, CustomFields } from '@app/components/documents/CustomFields';
import { CustomFieldType } from '@app/enums/CustomFieldType';
import { LookupFilter } from '@app/helper/utils';

@Component({
  selector: 'app-esg-reports',
  templateUrl: './esg-reports.component.html',
  styleUrls: ['./esg-reports.component.scss']
})
export class EsgReportsComponent implements OnInit {
  @Input()
  investorId: number;
  @Input()
  label: string;
  @Input()
  reportType: string;
  @Input() disabled:boolean;

  hasReports: boolean = false;
  sources: Array<any>;
  relatedEntityId: number;
  relatedEntityType: DocumentRelatedEntityType = DocumentRelatedEntityType.InvestorEsgReports;

  documentConfiguration: DocumentConfiguration;

  constructor(
    private dialogsService: DialogsService,
    private investorIntelService: InvestorsIntelligenceService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.getReportsExistence();
    this.buildDocumentConfiguration();
  }

  getReportsExistence() {
    this.investorIntelService.getInvestorReportExistence(this.investorId, this.reportType)
      .subscribe(reportExistence => {
        if (reportExistence) {
          this.hasReports = reportExistence.documentsExists;
          this.relatedEntityId = reportExistence.id;
        }
      }, err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      });
  }

  buildDocumentConfiguration() {
    this.documentConfiguration = new DocumentConfiguration();
    this.documentConfiguration.gridName = "Sources";
    this.documentConfiguration.columnsNames = ['Source document', 'Source description', 'Source Link'];
    this.documentConfiguration.documentComponentOuterDivMargins = 'mt-1 ml-0 mr-0';
    this.documentConfiguration.uploadBtnText = 'ADD SOURCE';
    this.documentConfiguration.uploadBtnIconClass = 'ms-plus-icon';
    this.documentConfiguration.uploadType = DocumentUploadType.SOURCE;

    this.documentConfiguration.dialogConfiguration = new DocumentDialogConfiguration();
    this.documentConfiguration.dialogConfiguration.dialogTitle = 'Add New Source';
    this.documentConfiguration.dialogConfiguration.descriptionFieldLabel = 'SOURCE DESCRIPTION';
    this.documentConfiguration.dialogConfiguration.isDocumentUploadOptional = true;
    this.documentConfiguration.dialogConfiguration.showLink=true;
    if(this.reportType == AppConstants.INVESTOR_REPORTS_TYPES.INVESTOR_ESG_PRI_ESG_INCORPORATION_LEI_1_REPORTS){
      this.documentConfiguration.dialogConfiguration.customField = new CustomFields();
      this.documentConfiguration.dialogConfiguration.customField.fieldName ="IMPACT";
      this.documentConfiguration.dialogConfiguration.customField.fieldOptions = FieldOptionsType.DropDown;
      this.documentConfiguration.dialogConfiguration.customField.fieldType = CustomFieldType.SingleSelect;
      this.documentConfiguration.dialogConfiguration.customField.listType = LookupFilter.INIIMP;
      this.documentConfiguration.dialogConfiguration.customField.showField=true;
      this.documentConfiguration.columnsNames =  ['Source document', 'Source description','Source Link', 'impact'];
    }
  }

  onDocumentsChange(allDocuments) {
    this.sources = allDocuments;
  }

  changeHavingReports(value: boolean) {
    if (value === false && this.sources.length) {
      this.onDelete();
    } else if (value === true && !this.relatedEntityId) {
      this.investorIntelService
        .createRelatedReportEntity(this.investorId, this.reportType)
        .subscribe(id => {
          this.relatedEntityId = id;
        }, err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        });
    }
  }

  onDelete() {
    this.dialogsService.confirm('Delete Reports', 'This action will delete all entries. Are you sure you want to proceed?');
    this.dialogsService.close$.subscribe(confirmed => {
      if (confirmed) {
        this.investorIntelService
          .deleteInvestorReports(this.investorId, this.reportType)
          .subscribe((areDeleted) => {
            if (areDeleted) {
              this.alertService.sendSuccess("All Entities were deleted!")
            } else {
              this.alertService.sendError('Some Entities can not be deleted!');
              this.hasReports = true;
            }
          }, err => {
            this.alertService.sendError(' error ' + JSON.stringify(err));
            this.hasReports = true;
          });
      } else {
        this.hasReports = true;
      }
    });
  }
}
