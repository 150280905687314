import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatSort, MatSortable, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Lookup } from '@app/dto/LookUp';
import { ONE_YEAR, ProjectTemplateService, THREE_YEARS, TWO_YEARS } from '@app/service/projectTemplateService';
import { AlertService } from '@app/services/alert.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { CommonUtilityService } from '@app/utility/common-utility.service';
import { LookupFilter, Utils } from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { InNOBORNEmailMaterialStatus, OutNOBORNEmailMaterialStatus } from '@app/dto/NOBORHSummary';

@Component({
  selector: 'app-dashboard-voting-history-dialof',
  templateUrl: './dashboard-voting-history-dialog.component.html',
  styleUrls: ['./dashboard-voting-history-dialog.component.scss']
})
export class DashboardVotingHistoryDialogComponent implements OnInit {
  dataSource: MatTableDataSource<VotingHistoryItems> = new MatTableDataSource<VotingHistoryItems>([]);
  displayDynamicColumns = [];
  investorId: number;
  displayedColumns: string[] = ['templateProperty1', 'templateProperty2'];
  templateProp1Label: string;
  templateProp2Label: string;
  projectTemplate: string;
  projectId: number;
  investorName: string;
  emailMaterialStatus: InNOBORNEmailMaterialStatus;
  recommendations: Lookup[] = [];
  emailMaterials: string[] = [];
  emailMaterialsModel = new OutNOBORNEmailMaterialStatus();
  @ViewChild(MatSort) sort: MatSort;
  util = new Utils(this.startupdataproviderService);
  isOci = false;
  loading = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DashboardVotingHistoryDialogComponent>,
    private alertService: AlertService,
    private dashboardService: DashboardService,
    private projectTemplateService: ProjectTemplateService,
    private commonUtility: CommonUtilityService,
    private startupdataproviderService: StartupdataproviderService,
    ) {
      this.investorId = data.investorId;
      this.projectId = data.projectId;
      this.investorName = data.investorName;
      this.emailMaterialStatus = data.emailMaterialStatus;
      this.emailMaterialsModel = {
        status: this.emailMaterialStatus.status,
        investorId: data.investorId
      };
     }

  ngOnInit() {
    this.getLookups();
    this.getProjectTemplatePropLabels();
    this.dashboardService.getNoboVotingHistory(this.investorId).subscribe(data => {
      this.isOci = data.length > 0 && data[0].source == 'OCI' ;
      this.setTableData(data);
      this.loading = false;
    }, e => this.alertService.alert(e));
  }

  getLookups() {
    this.util.getLookupByType(LookupFilter.NOBO_RO_EMAIL_MTRLS)
      .subscribe((data: Lookup[]) => {
        this.emailMaterials = data.map((item: Lookup) => {
          return item.fieldValue;
        });
      });
  }

  setTableData(data) {
    this.setColumns(data);
    this.setTableRows(data);
  }

  setColumns(data) {
    const record = data;
    const dates = this.getUniqueDates(data);
    this.displayDynamicColumns = [];
    dates.forEach(date => {
      const colHeader = date;
      this.displayedColumns.push(colHeader);

      const {source = null, insertBy = null} = record.find(x => x.votedDate === colHeader);
      const dynamic = new DynamicColumnDefinition({
        colHeader,
        source,
        insertBy
      });

      this.displayDynamicColumns.push(dynamic);
    });
  }

  setTableRows(data) {
    const _data = this.createInternalVotingItems(data);
    const resolutions = this.getUniqueResolutions(_data);
    const tableRows = resolutions.reduce((accumulator, name, currentIndex) => {
      const resolution = _data.find(item => item.templateProperty1 === name);
      const __items = this.findChildVotingItems(resolution.votingItemId, _data);
      const haveChild = __items.length > 0;

      const record = {
        templateProperty1: resolution.templateProperty1,
        templateProperty2: resolution.templateProperty2,
        haveChild
      };


      this.displayDynamicColumns.forEach((date, index) => {
        const vote = (data.find(item => item.votedDate === date.colHeader && item.templateProperty1 === name && !haveChild) || {vote: ''}).vote;
        record[date.colHeader] = vote;
      });

      accumulator.push(record);

      if (haveChild) {
        const result = this.setChildRows(resolution, __items);
        accumulator.push(...result);
      }

      return accumulator;
    }, []);

    this.dataSource = new MatTableDataSource();
    this.sort.sort(({ id: 'shares', start: 'desc'}) as MatSortable);
    this.dataSource.sort = this.sort;
    this.dataSource.data = tableRows;
  }

  setChildRows = (resolution, items) => {
    const rows = [];
    const childrenNames = this.getUniqueDirectors(items);

    childrenNames.forEach((childName, childNameIndex) => {
      const child = items.find(i => i.votingItemChild.name === childName);
        const _name = `${this.indexProperties(child.votingItemChild.orderId)}. ${child.votingItemChild.name}`;
        const record = {
          templateProperty1: '',
          templateProperty2: _name,
          haveChild: false,
          isChild: true
        };

        this.displayDynamicColumns.forEach((date, index) => {
          // tslint:disable-next-line:max-line-length
          const vote = (items.find(item => item.votedDate === date.colHeader && item.votingItemChild.parentVotingItemId === resolution.votingItemId && item.votingItemChild.name === childName) || {vote: ''}).vote;
          record[date.colHeader] = vote;
        });
        rows.push(record);


    });
    return rows;
  }

  findChildVotingItems = (votingItemId, items) => {
    return items.filter(item => item.votingItemChild && (item.votingItemChild.parentVotingItemId === votingItemId));
  }

  createInternalVotingItems = (array) => array.reduce((h, obj) => {
    if (obj.votingItemChild.parentVotingItemId && !h.find(_i => _i.votingItemId === obj.votingItemChild.parentVotingItemId)) {
      h.push({
        templateProperty1: obj.templateProperty1,
        templateProperty2: obj.templateProperty2,
        vote: null,
        votingItemChild: {
          name: null,
          orderId: 0,
          parentVotingItemId: 0,
        },
        votingItemId: obj.votingItemChild.parentVotingItemId
      });
    }
    h.push(obj);
    return h;
  }, [])

  private getProjectTemplatePropLabels() {
    this.projectTemplateService.getProjectTemplate(this.projectId).subscribe(data => {
      this.recommendations = this.projectTemplateService.getAdvisorVoteTypes(data.template, [ONE_YEAR, TWO_YEARS, THREE_YEARS]);
      const labels = this.projectTemplateService.getProjectItemsTemplateLabels(data.template);
      this.projectTemplate = data.template;
      this.templateProp1Label = labels.templateProp1Label;
      this.templateProp2Label = labels.templateProp2Label;

    }, e => this.alertService.alert(e));
  }

  getUniqueDates = (array) => array.reduce((h, obj) => [...h, ...obj.votedDate], [])
  .filter((value, index, self) => self.indexOf(value) === index).sort(function(a, b) {
    const dateA: any = new Date(a), dateB: any = new Date(b);
    return dateA - dateB;
  })

  getUniqueResolutions = data => data.reduce((h, obj) => [...h, ...obj.templateProperty1], [])
  .filter((value, index, self) => self.indexOf(value) === index)

  getUniqueDirectors = data => data.reduce((h, obj) => [...h, ...obj.votingItemChild.name], [])
    .filter((value, index, self) => self.indexOf(value) === index)

  getColor(recommendation: string): string {
    if (recommendation) {
      const item = this.recommendations.find(x => x.fieldLabel.toUpperCase() === recommendation.toUpperCase());
      if (item) {
        return item.color;
      }
    }
    return 'inherit';
  }
  indexProperties = (index) => this.commonUtility.indexToLetter(index, false);

  onClickSave() {
    this.dashboardService.setNoboHistoryStatus(this.emailMaterialsModel).subscribe(data => {
      this.dialogRef.close({data});
    }, e => this.alertService.alert(e));
  }
}

interface VotingHistoryItems {
  templateProperty1: string;
  templateProperty2: string;
  vote: string;
  votedDate: Date;
  source: string;
}
export class DynamicColumnDefinition {
  colHeader: string;
  source: string;
  insertBy: string;

  public constructor(init?) {
    Object.assign(this, init);
  }
}
