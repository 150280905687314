import { CustomFieldType } from "@app/enums/CustomFieldType";
import { LookupFilter } from "@app/helper/utils";

export class CustomFields{
    fieldType: CustomFieldType;
    fieldName: string;
    fieldOptions: FieldOptionsType;
    showField: boolean = false;
    listType : LookupFilter
}

export enum FieldOptionsType{
    DropDown = 1
}