import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { AlertService } from '@app/services/alert.service';
import { DashboardMailingService } from '@app/services/dashboard';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { RecipientEventHistoryEditDialogComponent } from './rec-evnt-hstr-edit/rec-evnt-hstr-edit.component';
import * as FileSaver from 'file-saver';
import { sanitizeFileName } from '@app/utility/Strings';
import { NgxSpinnerService } from 'ngx-spinner';
import { MailingStatuses } from '@app/enums/MailingStatusesEnum';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import { DialogsService } from '@app/services/dialogs/dialogs.service';

@Component({
  selector: 'app-dashboard-recipient-event-history',
  templateUrl: './dashboard-recipient-event-history.component.html',
  styleUrls: ['./dashboard-recipient-event-history.component.scss']
})

export class DashboardRecipientEventHistoryComponent implements OnInit {
  displayedColumns: string[] = ['dateTime', 'barcodeNumber', 'setsSend', 'status', 'member', 'comment', 'actions'];
  @Input() mailingDocumentRecipientId: number;
  @Input() projectId: number;
  @Input() recipientName: string;
  @ViewChild(MatSort) sort: MatSort;
  showGrid = false;
  dataSource = new MatTableDataSource();
  mailingStatuses = MailingStatuses;


  constructor(
    private dashboardService: DashboardService,
    private dashboardMailingService: DashboardMailingService,
    private alertService: AlertService,
    private readonly spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    private dialogsService: DialogsService
    ) {
  }


  ngOnInit() {
    this.getEvents();
  }

  getEvents() {
    this.dashboardService.getMailingRecepientEventsHistory(this.mailingDocumentRecipientId).subscribe(data => {
      this.createTable(data);;
      this.showGrid = data.length > 0;
    },
      e => this.alertService.alert(e)
    );
  }
  
  onEdit(element) {
    const dialogRef = this.dialog.open(RecipientEventHistoryEditDialogComponent, {
      data: {
        mailingDocumentRecipientSendId: element.mailingDocumentRecipientSentId,
        projectId: this.projectId,
        statusId: element.statusId
      },
      width: '700px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getEvents();
      }
    });
  }

  async downloadPdf(element): Promise<void> {
    const {mailingDocumentRecipientSentId, barcodeNumber} = element;
    await this.spinnerService.show('dashboard-recipient-event-history');
    try {
      const blobResult = await this.dashboardMailingService.downloadDeliveryIndividualTicketPdfFile(mailingDocumentRecipientSentId);
      await FileSaver.saveAs(blobResult, sanitizeFileName(this.recipientName + '_' + barcodeNumber) + '.pdf');
    } catch (e)
    {
      this.alertService.sendError('Error while downloading file!' );
    }
    await this.spinnerService.hide('dashboard-recipient-event-history');
  }

  onDelete(element) {
    this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: 'Shipment', message: `Shipment ${element.barcodeNumber}` }) });
    this.dialogsService.close$.subscribe(res => {
        if (res) {
          this.dashboardService.deleteShipment(element.mailingDocumentRecipientSentId).subscribe(() => {
            this.getEvents();
            this.alertService.sendSuccess(`Shipment ${element.barcodeNumber} was successfully deleted.`);
          });
        }
    });
  }

  createTable(data)  {
    const historyTable= data.map(row => {
      return {
        ...row,
        isShipped: row.statusId== MailingStatuses.Shipped
      }
    });
    
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.dataSource.data = historyTable;
  }
}
