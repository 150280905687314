export class Esg {
  esgId: number;
  esgName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  postalZipCode: string;
  phone: string;
  email: string;
  website: string;
  comment: string;
}
