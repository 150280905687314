import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FilterAndPaginateService } from '@app/components/table-filter-wrapper/services';
import {
  DynamicFilterContentComponentDto,
  FilterAndPaginateDTO,
  FilterAndPaginateOutputDTO,
  FilterContainerOptionsDTO,
  FilterDTO,
  IRequestResults,
  PaginationDTO,
  SortDTO
} from '@app/components/table-filter-wrapper/interfaces';
import { MatMenu, MatMenuTrigger, MatPaginator, PageEvent } from '@angular/material';
import { ContentObserver } from '@angular/cdk/observers';
import { TableFilterDirective } from '@app/components/table-filter-wrapper/directives/table-filter';
import { AlertService } from '@app/services/alert.service';
import { FilterContainerType, FilterOperators } from '@app/components/table-filter-wrapper/enums';
import { TextFilterFormComponent } from '@app/components/table-filter-wrapper/components/text-filter-form/text-filter-form.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Sort } from '@angular/material/sort/typings/sort';
import {
  DateRangeFilterFormComponent,
  MultiselectFilterFormComponent,
  NumberRangeFilterFormComponent
} from '@app/components/table-filter-wrapper/components';
import { SessionStorageService } from 'ngx-webstorage';
import { MatMultiSort } from '@app/components/mat-multi-sort/mat-multi-sort.directive';

function feed<T>(from: Observable<T>, to: Subject<T>): Subscription {
  return from.subscribe(
    data => to.next(data),
    err => to.error(err),
    () => to.complete(),
  );
}

@Component({
  selector: 'app-table-wrapper',
  templateUrl: './table-wrapper.component.html',
  styleUrls: ['./table-wrapper.component.scss']
})
export class TableWrapperComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {

  constructor(
    private readonly resolver: ComponentFactoryResolver,
    private readonly filterAndPaginateService: FilterAndPaginateService,
    private readonly obs: ContentObserver,
    private readonly renderer: Renderer2,
    private readonly alertService: AlertService,
    private readonly spinnerService: NgxSpinnerService,
    private readonly cdr: ChangeDetectorRef,
    private readonly storage: SessionStorageService
  ) {
    this.index = this.getRandomIndex();
  }

  get items() {
    return this.data.items;
  }
  private static readonly COMPONENTS = {
    [FilterContainerType.TEXT]: TextFilterFormComponent,
    [FilterContainerType.MULTISELECT]: MultiselectFilterFormComponent,
    [FilterContainerType.NUMBER_RANGE]: NumberRangeFilterFormComponent,
    [FilterContainerType.DATE_RANGE]: DateRangeFilterFormComponent,
    [FilterContainerType.DATE_RANGE_MULTISELECT]: DateRangeFilterFormComponent,
  };

  private static readonly OPERATORS_BY_FILTERTYPE = {
    [FilterContainerType.TEXT]: FilterOperators.SEARCH,
    [FilterContainerType.MULTISELECT]: FilterOperators.IN,
    [FilterContainerType.NUMBER_RANGE]: FilterOperators.RANGE,
    [FilterContainerType.DATE_RANGE]: FilterOperators.RANGE,
    [FilterContainerType.DATE_RANGE_MULTISELECT]: [FilterOperators.RANGE, FilterOperators.IN]
  };
  private unsubscribe$ = new Subject<void>();


  public currentComponentInstance: DynamicFilterContentComponentDto;
  public currentFilterDirective: TableFilterDirective;
  public length = 0;
  public pageIndex = 0;
  public filterOptions: FilterContainerOptionsDTO = new FilterContainerOptionsDTO();

  public data: IRequestResults;
  public showNoContent: boolean;
  public index;


  public mousePosition = { x: 0, y: 0 };
  @Input() endpoint: string;
  @Input() noItemsText = 'No results for the applied filters!';
  @Input() public pageSize = 20;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('content') content: ElementRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('targetMenu') targetMenu: ElementRef<HTMLElement>;
  @ViewChild('filterContainer', {read: ViewContainerRef}) filterContainer: ViewContainerRef;
  @ViewChild('tableFilterPanel') tableFilterPanel!: MatMenu;
  @ViewChild('clickMenuTrigger') clickMenuTrigger: MatMenuTrigger;
  public filters: FilterAndPaginateDTO;
  @ContentChildren(TableFilterDirective, {descendants: true}) templates: QueryList<TableFilterDirective>;
  @ContentChild(MatMultiSort) sort: MatMultiSort;
  @Output() dataSource: EventEmitter<any> = new EventEmitter<any>();

  endpointEmitter: Subject<string> = new Subject<string>();

  // This is used to keep the popup open until click on the Apply | Reset buttons or on the overlay
  private static configureTableFilterPanelClose(old: MatMenu['close']): MatMenu['close'] {
    const upd = new EventEmitter<'click'>();
    feed(upd.pipe(
      filter(event => {
        return event !== 'click';
      }),
    ), old);
    return upd;
  }

  ngOnInit() {
    this.checkRequiredFields('endpoint', this.endpoint);
    this.filterContainer.clear();
    setTimeout(() => this.spinnerService.show(`table-wrapper-${this.index}`), 0);
    this.filters = new FilterAndPaginateDTO({ pagination: new PaginationDTO(this.pageIndex + 1, this.pageSize) });
  }

  initPaginationChange() {
    this.paginator.page.subscribe((pageEvent: PageEvent) => {
      this.pageSize = pageEvent.pageSize;
      this.pageIndex = pageEvent.pageIndex;
      this.filters.pagination = new PaginationDTO(this.pageIndex + 1, this.pageSize);
      this.doSearch();
    });
  }

  initSortChange() {
    if (this.sort) {
      this.sort.sortChange.subscribe((sort: Sort) => {
        this.filters.sort = this.sort.actives.map((active, index) => new SortDTO(active, this.sort.directions[index])).reverse();
        this.resetPaginator();
        this.doSearch();
      });
    }
  }

  setDefaultFiltersAndSearch(templates, initial = false) {
    const defaultFilterTemplates = templates.filter((item, index, array) => {
      return item.filterOptions.defaultOptions instanceof FilterDTO;
    });

    const filters = defaultFilterTemplates.map(template => template.filterOptions.defaultOptions) || [];
    if ( initial || filters.length > 0 ) {
      this.filters.filters = filters;
      this.doSearch();
    }
  }
  ngAfterViewInit() {
    if (this.sort && this.sort.active) {
      this.filters.sort = [new SortDTO(this.sort.active, this.sort.direction)];
    }

    this.initPaginationChange();
    this.initSortChange();

    this.setDefaultFiltersAndSearch(this.templates, true);
    this.templates.changes.subscribe(data => {
      this.setDefaultFiltersAndSearch(data);

      data.forEach((template) => {
        template.endpointEmitter.next(this.endpoint);
        template.nestedComponentChange.subscribe(({event, options}) => {
          this.filterContainer.clear();
          const {x, y} = this.mousePosition;

          this.currentFilterDirective = template;
          this.renderer.setStyle(this.targetMenu.nativeElement, 'left', `${x}px`);
          this.renderer.setStyle(this.targetMenu.nativeElement, 'top', `${y}px`);

          if ( options && options.fieldName) {
            const { filters } = this.filters;

            const defaultFilters = filters.filter(_filter => _filter.fieldName === options.fieldName);
            if (defaultFilters.length) {
              options.defaultSearch = defaultFilters;
            } else {
              delete options.defaultSearch;
            }

            this.filterOptions = new FilterContainerOptionsDTO(options);

            try {
              const component = TableWrapperComponent.COMPONENTS[options.type];
              const factory = this.resolver.resolveComponentFactory(component);
              const componentRef = this.filterContainer.createComponent(factory);
              (<DynamicFilterContentComponentDto>componentRef.instance).data = options;
              this.currentComponentInstance = <DynamicFilterContentComponentDto>componentRef.instance;
            } catch (error) {
              this.alertService.sendError(error);
            }

            this.trigger.openMenu();
          }

        });
      });
    });

    // tslint:disable-next-line:max-line-length
    (this.tableFilterPanel as any).closed = this.tableFilterPanel.close = TableWrapperComponent.configureTableFilterPanelClose(this.tableFilterPanel.close);

  }

  relativeCoords ( event, container ) {
    const bounds = container.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return {x: x, y: y};
  }

  tableFilterPanelOpened() {

  }

  onMouseMove($event: MouseEvent, container: HTMLDivElement) {
    this.mousePosition = this.relativeCoords($event, container);
  }

  resetFilter() {
    this.doFilterAddRemove(false);
  }

  applyFilters() {
    this.doFilterAddRemove(true);
  }

  resetPaginator() {
    this.pageIndex = 0;
    this.filters.pagination = new PaginationDTO(this.pageIndex + 1, this.pageSize);
  }

  get canReset() {
    if ( this.currentComponentInstance ) {
      const {options: {fieldName, type}, values} = this.currentComponentInstance;
      const {filters} = this.filters;
      const filterIndex = filters.findIndex(_filter => _filter.fieldName === fieldName);

      return filterIndex > -1;
    }
    return false;
  }

  doFilterAddRemove( apply = false) {
    const { options: {fieldName, type} , values} = this.currentComponentInstance;
    const { filters } = this.filters;

    // check if we have this filter into the already applied filters
    const foundFilters = filters.filter(_filter => _filter.fieldName === fieldName);
    if (!apply) {
      if (foundFilters.length) {
        this.filters.filters = filters.filter(_filter => _filter.fieldName !== fieldName);
        this.currentFilterDirective.filterChange.next(false);
      }
    } else {
      if (foundFilters.length) {
        this.filters.filters = filters.filter(_filter => _filter.fieldName !== fieldName);
      }
      const operator = TableWrapperComponent.OPERATORS_BY_FILTERTYPE[type];
      const operators = Array.isArray(operator) ? operator : [operator];
      let emptyFilter = false;
      operators.forEach((operatorType, index) => {
        const value = Array.isArray(operator) && operator.length > 1 ? values[index] : values;
        if (
          typeof value === 'undefined' ||
          // This will check if the filter RANGE have any field different from NULL, if not then do not add that filter to the request
          operatorType === FilterOperators.RANGE && !value.filter(v => v !== null).length
        ) {
          return;
        }
        emptyFilter = true;
        const newFilter = new FilterDTO(fieldName, value, operatorType);
        this.filters.filters = [...this.filters.filters, newFilter];
      });

      this.currentFilterDirective.filterChange.next(emptyFilter);
    }
    this.resetPaginator();
    this.clickMenuTrigger.closeMenu();
    this.doSearch();
  }

  private doSearch(): void {
    if (this.endpoint) {
      this.storage.store(this.endpoint, this.filters);
      setTimeout(() => this.spinnerService.show(`table-wrapper-${this.index}`), 0);
      this.filterAndPaginateService.list(this.endpoint, new FilterAndPaginateOutputDTO(this.filters))
        .pipe(
          takeUntil(this.unsubscribe$),
          finalize(() => this.spinnerService.hide(`table-wrapper-${this.index}`))
        )
        .subscribe(data => {
          this.data = data;
          this.length = data.totalItems;
          this.dataSource.emit(data);

          if (this.filters.filters.length && this.length === 0) {
            this.showNoContent = true;
          } else {
            this.showNoContent = false;
          }
      });
    }
  }

  public reloadItems(): void {
    this.doSearch();
  }

  checkRequiredFields(inputName, input) {
    if ( input === null) {
      throw new Error(`Attribute '${inputName}' is required`);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // this is necessary to fix `ExpressionChangedAfterItHasBeenCheckedError`
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  getRandomIndex = () => {
    return new Date().valueOf() + Math.floor((Math.random()) * (9999 - 99)) + 99;
  }
}
