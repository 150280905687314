import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ActivatedRoute} from '@angular/router';
import {Investor} from 'src/app/dto/Investor';
import {MatDialog} from '@angular/material';
import {ContactRelatedEntityType} from '@app/enums/ContactRelatedEntityTypeEnum';
import {ContactType} from '@app/enums/ContactTypeEnum';
import {ClientsService} from '@app/services/clients.service';
import {Client} from '@app/dto/Client';

@Component({
  selector: 'app-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['client-contacts.component.scss']
})

export class ClientContactsComponent implements OnInit {
  model: Client;
  clientId: string;
  routeSub: any;
  contactType: ContactType;
  contactRelatedEntityType: ContactRelatedEntityType;

  constructor(private activeroute: ActivatedRoute,
              private  httpClient: HttpClient,
              private clientsService: ClientsService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.contactType = ContactType.Customer;
    this.contactRelatedEntityType = ContactRelatedEntityType.Client;
    this.model = new Client();

    this.routeSub = this.activeroute.parent.params.subscribe((params) => {
      this.clientId = params['id'];
      this.getClient();
    });
  }

  getClient() {
    this.clientsService.getClient(this.clientId).subscribe(
      data => {   
        this.model = data;
      },
      err => console.error(err)
    );
  }
}
