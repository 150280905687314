import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { InitiativesService } from '@app/services/initiatives.service';
import { Initiative } from '@app/dto/Initiative';
import { InvestorInitiative } from '@app/dto/InvestorInitiative';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { Utils, LookupFilter } from '@app/helper/utils';
import { AlertService } from '@app/services/alert.service';
import { Lookup } from '@app/dto/Lookup';
import { RegionCountryMultiselectHelper } from '@app/helper/RegionCountryMultiselectHelper';
import { DocumentConfiguration } from '@app/components/documents/document-configuration';
import { Observable, combineLatest } from 'rxjs';
import { DocumentDialogConfiguration } from '@app/components/documents/document-dialog-configuration';
import { DocumentUploadType } from '@app/components/documents/DocumentUploadType';
import {ResolutionsClassificationService} from '@app/components/autocompletes/resolutions-classification/resolutions-classification.service';

@Component({
  templateUrl: './addinvestorinitiativedialog.component.html',
  styleUrls: ['./addInitiativedialog.component.scss']
})
export class AddInvestorInitiativeDialogComponent {
  isCreate: boolean = true;
  popupTitle: string;
  isChoosingInitiativeView: boolean = true;
  availableInitiatives: Initiative[];
  selectedInitiative: Initiative;
  model: InvestorInitiative = new InvestorInitiative();
  impactsList: string[];
  groupedCountries: { [key: string]: string[]; };
  util = new Utils(this.startupdataproviderService);
  sectors: any;
  thematicFocusPickerData = {
    noRecordsText: 'There are still not thematic focus selected',
    noRecordsLink: 'Edit the thematic focus...',
    allRecords: [],
    selectedRecords: []
  };
  areDocumentsInMemory: boolean = true;
  createdEntityId?: number;
  documentConfiguration: DocumentConfiguration;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<AddInvestorInitiativeDialogComponent>,
              private initiativeService: InitiativesService,
              private startupdataproviderService: StartupdataproviderService,
              private alertService: AlertService,
              private resolutionsClassificationService: ResolutionsClassificationService
  ) { }


  ngOnInit() {
    this.isCreate = this.data.isCreate;
    this.popupTitle = this.data.title;
    if (this.isCreate) {
      this.areDocumentsInMemory = true;
      this.getAvailableInitiatives();
      this.getThematicFocusOptionsObservable().subscribe(this.thematicFocusSubscriber.bind(this));
    } else {
      this.areDocumentsInMemory = false;
      combineLatest(this.getInitiativeObservable(this.data.initiativeId), this.getThematicFocusOptionsObservable(),
        (initiative, thematicFocuses) => ({ initiative, thematicFocuses }))
        .subscribe(pair => {
          this.thematicFocusSubscriber(pair.thematicFocuses);
          this.initiativeSubscriber(pair.initiative);
        });
    }

    this.initialiseUtils();

    this.buildDocumentConfiguration();
  }

  initialiseUtils() {
    this.util.getLookupByType(LookupFilter.GICSECTOR)
      .subscribe(data => this.sectors = data);

    this.groupedCountries = this.util.getCountriesByRegionGroup();

    this.util.getLookupByType(LookupFilter.INIIMP)
      .subscribe((data: Lookup[]) => {
        this.impactsList = data.map((item: Lookup) => {
          return item.fieldValue;
        });
      });
  }

  getThematicFocusOptionsObservable(): Observable<any> {
    return this.resolutionsClassificationService.getData();
  }

  thematicFocusSubscriber(data) {
    this.thematicFocusPickerData.allRecords = data;
  }

  getThematicFocusSelection(ids) {
    if (!this.thematicFocusPickerData.allRecords.length) {
      return [];
    }
    const selection = [];
    for (const option of this.thematicFocusPickerData.allRecords) {
      if (ids.includes(option.id.toString())) {
        selection.push(JSON.parse(JSON.stringify(option)));
      }
    }
    return selection;
  }


  getAvailableInitiatives() {
    this.initiativeService
      .getInvestorAvailableInitiatives(this.data.investorId)
      .subscribe((data) => {
        this.availableInitiatives = data;
      });
  }

  getInitiativeObservable(initiativeId): Observable<Initiative> {
    return this.initiativeService.getInitiative(initiativeId)
  }

  initiativeSubscriber(data) {
    const regionFocuses = new Set<string>(data.regionFocuses);
    RegionCountryMultiselectHelper.selectRegions(this.groupedCountries, regionFocuses);
    data.regionFocuses = Array.from(regionFocuses);
    this.thematicFocusPickerData.selectedRecords = this.getThematicFocusSelection(data.thematicFocuses);
    // when reusing the getInitiative in the service changing the type to InvestorInitiative is required
    this.model = <InvestorInitiative><any>data;
  }

  onCloseBtnClick(): void {
    this.dialogRef.close(false);
  }

  onNext() {
    const investorInitiative = this.getInvestorInitiativeFromParentInitiative(this.selectedInitiative);
    const regionFocuses = new Set(investorInitiative.regionFocuses);
    RegionCountryMultiselectHelper.selectRegions(this.groupedCountries, regionFocuses);
    investorInitiative.regionFocuses = Array.from(regionFocuses);

    this.model = investorInitiative;

    if (this.model.thematicFocuses) {
      this.thematicFocusPickerData.selectedRecords = this.getThematicFocusSelection(this.model.thematicFocuses);
      // Force child ( OptionsPicker ) to detect changes
      this.thematicFocusPickerData = JSON.parse(JSON.stringify(this.thematicFocusPickerData));
    }

    this.isChoosingInitiativeView = false;
  }

  onSave() {
    this.model.regionFocuses = RegionCountryMultiselectHelper.cleanRegionFocuses(this.groupedCountries, this.model.regionFocuses);
    if (this.isCreate) {
      this.initiativeService.createInvestorInitiative(this.model).subscribe(
        data => {
          this.model = data;
          this.createdEntityId = this.model.esgInitiativeId;
          this.alertService.sendSuccess('Esg Initiative Created!');
          this.dialogRef.close(true);
        },
        err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        }
      );
    } else {
      this.initiativeService.updateInvestorInitiative(this.model).subscribe(
        data => {
          this.model = data;
          this.alertService.sendSuccess('Esg Initiative Updated!');
          this.dialogRef.close(true);
        },
        err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        }
      );
    }
  }

  getInvestorInitiativeFromParentInitiative(templateInitiative: Initiative): InvestorInitiative {
    const initiative = new InvestorInitiative();
    initiative.name = templateInitiative.name;
    initiative.impacts = templateInitiative.impacts && templateInitiative.impacts.map(i => i);
    initiative.activated = templateInitiative.activated;
    initiative.deactivatedDate = templateInitiative.deactivatedDate;
    initiative.summary = templateInitiative.summary;
    initiative.hasSectorFocus = templateInitiative.hasSectorFocus;
    initiative.sectorFocuses = templateInitiative.sectorFocuses && templateInitiative.sectorFocuses.map(f => f);
    initiative.hasRegionFocus = templateInitiative.hasRegionFocus;
    initiative.regionFocuses = templateInitiative.regionFocuses && templateInitiative.regionFocuses.map(f => f);;
    initiative.hasThematicFocus = templateInitiative.hasThematicFocus;
    initiative.thematicFocuses = templateInitiative.thematicFocuses && templateInitiative.thematicFocuses.map(f => f);
    initiative.parentEsgInitiativeId = templateInitiative.esgInitiativeId;
    initiative.investorId = this.data.investorId;

    return initiative;
  }

  buildDocumentConfiguration() {
    this.documentConfiguration = new DocumentConfiguration();
    this.documentConfiguration.gridName = "Sources";
    this.documentConfiguration.columnsNames = ['Source document', 'Source description', 'Source Link'];
    this.documentConfiguration.documentComponentOuterDivMargins = 'mt-5';
    this.documentConfiguration.uploadBtnText = 'ADD SOURCE';
    this.documentConfiguration.uploadBtnIconClass = 'ms-plus-icon';
    this.documentConfiguration.uploadType = DocumentUploadType.SOURCE;

    this.documentConfiguration.dialogConfiguration = new DocumentDialogConfiguration();
    this.documentConfiguration.dialogConfiguration.dialogTitle = 'Add New Source';
    this.documentConfiguration.dialogConfiguration.descriptionFieldLabel = 'SOURCE DESCRIPTION';
    this.documentConfiguration.dialogConfiguration.isDocumentUploadOptional = true;
    this.documentConfiguration.dialogConfiguration.showLink=true;
  }

  onCancel() {
    // As the initiave is already created we should delete it along with all of its related entities
    // This is done to make it possible to add documents when creating an initiative
    if (!this.model.esgInitiativeId) {
      return;
    }

    if (this.isCreate) {
      this.initiativeService.hardDeleteInvestorInitiative(this.model.esgInitiativeId).subscribe(data => { },
        err => {
          const errorMessage = this.alertService.transformError(err);
          console.log(errorMessage);
        });
    }
  }
}
