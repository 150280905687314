import { FilterContainerOptionsDTO } from '@app/components/table-filter-wrapper/interfaces/filter-container-options';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export interface IFilterFormComponent {
  formGroup: FormGroup;
  options: FilterContainerOptionsDTO;
  setDefault(_): any;
  resetForm(): void;
}

@Component({ template: '' })
export class FilterFormComponent<T = any> implements IFilterFormComponent, OnDestroy {
  public unsubscribe$ = new Subject<void>();
  formGroup: FormGroup;
  options: FilterContainerOptionsDTO;
  public setDefault(_: FilterContainerOptionsDTO) {
    return this.formGroup;
  }

  @Input() set data(options: FilterContainerOptionsDTO) {
    this.options = options;
    this.formGroup = this.setDefault(options);
  }

  constructor(protected readonly fb: FormBuilder) {
  }

  public get values(): any {
    return [this.formGroup.getRawValue().value];
  }

  public resetForm(): void {
    this.formGroup.reset();
  }

  public get isFormValid(): boolean {
    return this.formGroup.valid;
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
