import { NoItemsGridModule } from '@app/modules/NoItemsGridModule';
import { NoItemsGridComponent } from './../components/no-items-grid/no-items-grid.component';
import { MatTableModule, MatDialogModule } from '@angular/material';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgModule } from "@angular/core";
import { BrokerTypeDialogComponent } from "@app/project/projectdetail/custodial-list/broker-type-dialog/broker-type-dialog.component";
import { BrokerTypeReportComponent } from "@app/project/projectdetail/custodial-list/broker-type-report/broker-type-report.component";
import { CommonModule } from '@angular/common';

@NgModule({
    imports:[
        NgxSpinnerModule,
        MatTableModule,
        MatDialogModule,
        CommonModule,
        NoItemsGridModule
    ],
    declarations:[
        BrokerTypeReportComponent,
        BrokerTypeDialogComponent,
    ],
    entryComponents:[
        BrokerTypeReportComponent,
        BrokerTypeDialogComponent
    ],
    exports:[
        BrokerTypeReportComponent,
        BrokerTypeDialogComponent
    ]
})

export class BrokerReportModule {}