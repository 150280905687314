import { Component, OnInit, Input, Inject } from '@angular/core';
import { InvestorValueCountryConfiguration } from '../../investor-value-country-configuration';
import { InvestorIntelGridAddDto, InvestorIntelGridExtensionFieldDefinition, InvestorValueCountryGridLookupDto } from '../../investor-value-country-grid-source-dto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NameValueModel } from '@app/models/basic-structures';
import { InvestorValueCountryGridService } from '@app/services/investors';
import { AlertService } from '@app/services/alert.service';

@Component({
  selector: 'app-inv-value-country-edit-dialog',
  templateUrl: './inv-value-country-edit-dialog.component.html',
  styleUrls: ['./inv-value-country-edit-dialog.component.scss']
})
export class InvValueCountryEditDialogComponent implements OnInit {

  configuration: InvestorValueCountryConfiguration;
  model: InvestorIntelGridAddDto;
  extensionFieldColumns : InvestorIntelGridExtensionFieldDefinition[] = [];
  lookups: InvestorValueCountryGridLookupDto[] = [];

  constructor(
    private service: InvestorValueCountryGridService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<InvValueCountryEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.configuration = this.data.configuration;
    this.lookups = this.data.lookups;
    this.model = this.data.model;
    this.extensionFieldColumns = this.data.extensionFieldColumns;
  }

  getInputExtensionFields(type: string) {
    return this.extensionFieldColumns.filter(x => x.inputType.toLowerCase() === type);
  }

  getExtensionFieldModel(fieldName: string) {
    return this.model.extensionFields.find(i => i.name === fieldName).value;
  }

  countrySelectionChanged(newCountry: NameValueModel) {
    this.model.country = newCountry.name;
    this.model.iso3 = newCountry.value;
  }

  onExtensionFieldSelectChange(fieldName: string, value: string) {
    let modelItem = this.model.extensionFields.find(x => x.name.toLowerCase() === fieldName.toLowerCase());
    modelItem.value = value;
  }

  onRelatedEntityChange(id: number) {
    let entity = this.lookups.find(x => x.id === id);
    this.model.relatedEntityId = id;
    this.model.relatedEntityName = entity.name;
  }

  onSave() {
    this.service.updateRow(this.configuration.apiEndpointPath, this.model).subscribe(data => {

      if(this.configuration.isLinked){
        this.alertService.sendSuccess('Link between the global and the local custodian is updated!');
        this.dialogRef.close();
      } else{
        this.alertService.sendSuccess('Voting Behavior Record Updated!');
        this.dialogRef.close();
      }
    }, err => {
      this.alertService.sendError(err.error.Message);
    });
  }
}
