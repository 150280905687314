import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { InvestorVotingSummaryService } from '@app/services/investors';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  InvestorVotingSummaryAdapter,
  InvestorVotingSummaryProject,
  InvestorVotingSummaryInterface,
  InvestorVotingSummaryProjectVotingItems
} from '@app/dto/investor/investor-voting-summary.interface';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { CommonUtilityService } from '@app/utility/common-utility.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  FilterAndPaginateDTO,
  IFilterAndPaginate,
  FilterAndPaginateOutputDTO,
  FilterContainerOptionsDTO,
  PaginationDTO
} from '@app/components/table-filter-wrapper/interfaces';
import { FilterOperators } from '@app/components/table-filter-wrapper/enums';
import { Filters } from '@app/investor/investordetail/investor-history-summary/investor-voting-history.component';
import { InvestorVotingItemDetailsComponent } from './investor-voting-item-details/investor-voting-item-details.component';

@Component({
  selector: 'app-investor-voting-summary-table',
  templateUrl: './investor-voting-summary-table.component.html',
  styleUrls: ['./investor-voting-summary-table.component.scss']
})
export class InvestorVotingSummaryTableComponent implements OnInit, OnChanges {
  @Input() investorId: number;
  @Input() investorVotingSummary: InvestorVotingSummaryAdapter;
  @Input() proxyAdvisorsNamesSubject: Subject<string[]>;
  @Input() shareClassificationSubject: Subject<string>;
  @Input() applyFiltersSubject: Subject<Filters> = new Subject();

  public columns = ['project', 'resolution', 'intention', 'voted'];
  public proxyAdvisorsNames: string[] = [];
  public shareClassification: string;
  public toggler = [];
  public dataSource = new MatTableDataSource();
  public showGrid = false;
  public showLoading = false;
  private unsubscribe$ = new Subject<void>();
  private defaultColumns = ['project', 'resolution', 'intention', 'voted'];
  private votingOptions: any[] = this.projectTemplateService.votedtypecolors;
  private projectsSubject = new Subject<InvestorVotingSummaryProject[]>();
  private filters = new FilterAndPaginateDTO({
    pagination: new PaginationDTO(1, 10),
  });
  private numberOfPages = 0;
  private minRows = 30;
  private splitVote = 'SPLIT';

  constructor(
    private readonly investorVotingSummaryService: InvestorVotingSummaryService,
    private projectTemplateService: ProjectTemplateService,
    private commonUtility: CommonUtilityService,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog
  ) { }

  public ngOnInit(): void {
    this.spinnerService.show('loading-more');

    this.proxyAdvisorsNamesSubject.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((pxyNames: string[]) => {
      this.proxyAdvisorsNames = pxyNames;
      this.generateColumns();
    });

    this.shareClassificationSubject.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((shareClassification: string) => {
      this.shareClassification = shareClassification;

      this.dataSource.data.forEach((row: any) => {
        row['shareClassificationData'] = this.setupIntentions(row.votingItem);
      });
    });

    this.applyFiltersSubject.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((filters: Filters) => {
      this.applyFilters(filters);
    });

    this.projectsSubject.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((projects) => {
      const generatedRows = this.generateRows(projects, this.filters.pagination.page);

      this.dataSource.data = [...this.dataSource.data, ...generatedRows];

      if (
        this.dataSource.data.length < this.minRows
        && this.numberOfPages > this.filters.pagination.page
      ) {
        this.requestNextPage();
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.investorVotingSummary && changes.investorVotingSummary.currentValue) {
      this.showGrid = (this.investorVotingSummary.projects || []).length > 0;
      this.numberOfPages = this.investorVotingSummary.numberOfPages;
      this.projectsSubject.next(this.investorVotingSummary.projects);
    }
  }

  public onIntersectionInView(): void {
    if (this.showLoading) {
      return;
    }

    if (this.numberOfPages > this.filters.pagination.page) {
      this.requestNextPage();
    }
  }

  generateColumns() {
    this.columns = [...this.defaultColumns, ...this.proxyAdvisorsNames];
  }

  generateRows(data: InvestorVotingSummaryProject[], page: number): any[] {
    const tableRows = data.reduce((accumulator, item: InvestorVotingSummaryProject) => {
      this.toggler[item.projectName] = false;

      if (item.votingItems.length === 0) {
        const record = this.generateRow({
          recommendations: {},
          templateProperty1: '',
          templateProperty2: '',
          isChild: false
        } as InvestorVotingSummaryProjectVotingItems, item, 0);

        accumulator.push(record);

        return accumulator;
      }

      item.votingItems.forEach((votingItem, index) => {
        const record = this.generateRow(votingItem, item, index);

        accumulator.push(record);
      });

      return accumulator;
    }, []);

    return tableRows;
  }

  getColor = (value): string => {
    if (value) {
      return (this.votingOptions.find(x => x.type === value) || { color: 'inherit' }).color;
    }

    return this.votingOptions.find(x => x.type === 'DEFAULT').color;
  }

  toggleRows = (el): void => {
    this.toggler[el.project.name] = !this.toggler[el.project.name];
  }

  indexProperties = (index) => this.commonUtility.indexToLetter(index, false);

  onVotingItemClick(element) {
    const dialogRef = this.dialog.open(InvestorVotingItemDetailsComponent,
      {
        width: '600px',
        data: { votingItemId : element.resolution.votingItemId }
      });
  }

  voteDetailsTooltipMouseEnter(event) {
    const td = event.target;
    const boundingClientRect = td.getBoundingClientRect();
    const tooltip = td.querySelector('.vote-details-tooltip');

    if (tooltip) {
      tooltip.style.left = `${boundingClientRect.right}px`;
      tooltip.style.top = `${boundingClientRect.top}px`;
      tooltip.style.display = 'block';
    }    
  }

  voteDetailsTooltipMouseLeave(event) {
    const tooltip = event.target.querySelector('.vote-details-tooltip');
    
    if (tooltip) {
    tooltip.style.display = 'none';
    }
  }

  private generateRow(votingItem: InvestorVotingSummaryProjectVotingItems, item: InvestorVotingSummaryProject, index: number) {
    const title = [];
    // tslint:disable-next-line:max-line-length
    title.push(votingItem.isChild && votingItem.templateProperty1 ? this.indexProperties(+votingItem.templateProperty1) : votingItem.templateProperty1);
    title.push(votingItem.templateProperty2);

    const record = {
      project: {
        name: item.projectName,
        client: item.clientName,
        market: item.marketCoverage,
        sector: item.sector,
        endDate: item.endDate,
        rowspan: item.votingItems.length === 0 ? 1 : item.votingItems.length,
      },
      resolution: {
        name: title.join('. '),
        isChild: votingItem.isChild,
        votingItemId: votingItem.votingItemId
      },
      votingItem,
      voteList : votingItem.votes[this.shareClassification].votes.map(c => ({voteType : c.voteType, votePercentage : c.votePercentage})),
      intentionList: votingItem.votes[this.shareClassification].intentions.map(c => ({voteType : c.intentionType, votePercentage : c.intentionPercentage})),
      index
    };

    this.proxyAdvisorsNames.forEach(advisors => {
      const recommendation = votingItem.recommendations[advisors];

      record[advisors] = {
        voteType: recommendation || '--',
        voteColor: this.getColor(recommendation)
      };
    });

    record['shareClassificationData'] = this.setupIntentions(votingItem);

    return record;
  }

  private defaultIntention(): any {
    return {
      intention: '--',
      intentionColor: 'inherit',
      voted: '--',
      votedColor: 'inherit'
    };
  }

  private setupIntentions(votingItem: any): any {
    if (!votingItem.votes) {
      return this.defaultIntention();
    }

    const votes = votingItem.votes[this.shareClassification];

    if (!votes.intentions.length && !votes.votes.length) {
      return this.defaultIntention();
    }

    let intention = votes.intentions[0];
    let intentionColor;
    let voted = votes.votes[0];
    let votedColor;

    if (intention) {
      const intentionIsSplit = intention.intentionPercentage < 70 && votes.intentions.length > 1;

      intentionColor = intentionIsSplit ? this.getColor(this.splitVote) : this.getColor(intention.intentionType);
      intention = intentionIsSplit
        ? 'SPLIT'
        : `${intention.intentionType} (${intention.intentionPercentage}%)`;
    }

    if (voted) {
      const voteIsSplit = voted.votePercentage < 70 && votes.votes.length > 1;

      votedColor = voteIsSplit ? this.getColor(this.splitVote) : this.getColor(voted.voteType);
      voted = voteIsSplit
        ? 'SPLIT'
        : `${voted.voteType.toUpperCase()} (${voted.votePercentage}%)`;
    }

    return {
      intention,
      intentionColor,
      voted,
      votedColor
    };
  }

  private getInvestorVotingSummary(investorId: number, filters: FilterAndPaginateOutputDTO): Observable<InvestorVotingSummaryAdapter> {
    return this.investorVotingSummaryService
      .getInvestorVotingSummary(investorId, filters)
      .pipe(
        takeUntil(this.unsubscribe$),
      );
  }

  private getData(): void {
    const body = new FilterAndPaginateOutputDTO(this.filters);

    this.dataSource.data = [];
    this.getInvestorVotingSummary(this.investorId, body).subscribe((data) => {
      this.showGrid = (data.projects || []).length > 0;
      this.numberOfPages = data.numberOfPages;
      this.projectsSubject.next(data.projects);
    });
  }

  private requestNextPage(): void {
    this.showLoading = true;
    this.filters.pagination.page += 1;

    const body = new FilterAndPaginateOutputDTO(this.filters);

    this.getInvestorVotingSummary(this.investorId, body).subscribe((data) => {
      this.showLoading = false;

      this.projectsSubject.next(data.projects);
    });
  }

  private applyFilters(filter: Filters): void {
    this.filters.filters = [];
    this.filters.pagination.page = 1;

    if (filter.client) {
      this.filters.filters.push({
        fieldName: 'ClientName',
        operator: FilterOperators.SEARCH,
        value: [filter.client]
      });
    }

    if (filter.sectorOptions) {
      this.filters.filters.push({
        fieldName: 'Sector',
        operator: FilterOperators.IN,
        value: filter.sectorOptions.map(x => x.value),
      });
    }

    if (filter.marketOptions) {
      this.filters.filters.push({
        fieldName: 'Market',
        operator: FilterOperators.IN,
        value: filter.marketOptions.map(x => x.value),
      });
    }

    if (filter.resolutionClassifications) {
      this.filters.filters.push({
        fieldName: 'Resolutions.IssClassificationId',
        operator: FilterOperators.CUSTOM,
        value: filter.resolutionClassifications.map(x => x.value !== '%NULL%' ? x.value : null),
      });
    }

    if (filter.fromDate || filter.toDate) {
      this.filters.filters.push({
        fieldName: 'endDate',
        operator: FilterOperators.RANGE,
        value: [filter.fromDate || null, filter.toDate || null],
      });
    }

    this.getData();
  }
}
