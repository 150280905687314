import { DirtyComponent } from '@app/common/dirty-component';
import { Component, OnInit, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { ProjectResolutionBODRecommendation } from '../../dto/ProjectResolutionBODRecommendation';
import { UserService } from 'src/app/services/user.service';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { Lookup } from '@app/dto/Lookup';
import { AlertService } from '@app/services/alert.service';
import { ProjectTemplateTypes } from '@app/enums/ProjectTemplateTypesEnum';
import { ProxyAdvisorRecommendationsGrouped } from '@app/dto/ProxyAdvisorRecommendationsGrouped';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { CommonUtilityService } from '@app/utility/common-utility.service';
import { ProjectsService } from '@app/services/projects.service';
import { map } from 'rxjs/operators';
import { VoteTableDto } from '@app/dto/VoteTableDto';

@Component({
  selector: 'app-project-recommendation',
  templateUrl: './project-recommendation.component.html',
  styleUrls: ['project-recommendation.component.scss']
})

export class ProjectRecommendationComponent implements OnInit, DirtyComponent {
  projectProxyAdvisorRecommendationsGrouped: ProxyAdvisorRecommendationsGrouped[];
  recommendations: Lookup[] = [];
  frequencyVoteTypes: string[] | Lookup[] = [];
  recommendationVoteTypes: Lookup[] = [];
  showProxyRecommendation: boolean;
  isEdit = false;
  disableForm: boolean;
  routeSub: any;
  projectId: number;
  rolename: string;
  coverage: string;
  dataSource: MatTableDataSource<ProjectResolutionBODRecommendation>;
  displayedColumns = ['itemNo', 'description', 'recommendation'];
  columnsPxyAdvisorRecommendations = [];
  columnsPxyAdvisorRecommendationsUpper = [];
  templateProp1Label: string;
  templateProp2Label: string;
  projectTemplate: string;
  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();
  canEditProject = false;
  canCreateProject = false;
  noItemsText = 'Recommendations';
  showGrid: boolean;
  public items: VoteTableDto[];

  constructor(private route: ActivatedRoute,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private projectTemplateService: ProjectTemplateService,
    private projectsService: ProjectsService,
    private userService: UserService,
    public dialog: MatDialog,
    private roleGuardService: RoleGuardService,
    private commonUtility: CommonUtilityService,
    private projectService: ProjectsService
  ) {
  }

  ngOnInit() {
    this.rolename = this.userService.getUserRoleName();
    this.coverage = this.userService.getUserCoverage();

    this.routeSub = this.route.parent.params.subscribe(async (params) => {
      this.projectId = +params['id'];

      this.projectService.getProjectType(this.projectId).subscribe(data =>{
        this.canEditProject = this.roleGuardService.hasAccessByProjectType('projects', 'edit', data.name);
        // TODO: When PSC type of project can be created replace canEditProject with canCreateProject
        // when calling the app-project-proxy-advisers component
        this.canCreateProject = this.roleGuardService.hasAccess('projects', 'create');
      })

      this.getData();
    });

    this.isDirty$.subscribe(data => {
      return of(data);
    });
  }

  validateState($event: boolean) {
    this.isEdit = $event;
    this.isDirty.next(this.isEdit);
  }

  getData() {
    combineLatest([
      this.projectTemplateService.getProjectTemplate(this.projectId),
      this.projectsService.getBoardOfDirectorsRecommendations(this.projectId)
    ]).pipe(map(results => ({
      projectTemplate: results[0], recommendations: results[1]
    }))).subscribe(data => {
      const recommendationsData = data.recommendations.reduce(
        (prev, curr) => [
          ...prev,
          { ...curr },
          ...curr.childRecommendations.map(ch => ch)
        ], []);

      this.items = this.mapRecommendationsDataToVoteTable(data.recommendations);
      this.dataSource = new MatTableDataSource<ProjectResolutionBODRecommendation>();
      this.dataSource.data = recommendationsData;

      this.showGrid = recommendationsData.length > 0;

      if (recommendationsData && recommendationsData.length > 0
        && recommendationsData[0].projectTemplateId === ProjectTemplateTypes.ShareHolderMeeting) {
        this.displayedColumns = ['description', 'recommendation'];
        this.dataSource.data.forEach(x => x.templateProp2 = `${x.templateProp1}. ${x.templateProp2}`);
      }


      const labels = this.projectTemplateService.getProjectItemsTemplateLabels(data.projectTemplate.template);
      this.projectTemplate = data.projectTemplate.template;
      this.templateProp1Label = labels.templateProp1Label;
      this.templateProp2Label = labels.templateProp2Label;

      // tslint:disable-next-line:max-line-length
      const additional = this.projectTemplateService.hasFrequency(recommendationsData) ? this.projectTemplateService.getFrequencyVoteTypes(false) : [];

      this.recommendations = this.projectTemplateService.getAdvisorVoteTypes(data.projectTemplate.template, additional);

      this.frequencyVoteTypes = this.projectTemplateService.getFrequencyVoteTypes(true);
      this.recommendationVoteTypes = this.projectTemplateService.getAdvisorVoteTypes(data.projectTemplate.template);
    });
  }

  getProjectTemplatePropLabels() {
    this.projectTemplateService.getProjectTemplate(this.projectId).subscribe(data => {
      const labels = this.projectTemplateService.getProjectItemsTemplateLabels(data.template);
      this.projectTemplate = data.template;
      this.templateProp1Label = labels.templateProp1Label;
      this.templateProp2Label = labels.templateProp2Label;
      this.recommendations = this.projectTemplateService.getAdvisorVoteTypes(data.template);
    },
      err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });
  }

  getBoardOfDirectorsRecommendations() {
    const apiURL: string = environment.serverUrl + 'projects/project-bod-recommendations/' + this.projectId;
    this.httpClient.get<ProjectResolutionBODRecommendation[]>(apiURL).subscribe(
      data => {
        data = data.reduce((prev, curr) => [...prev, { ...curr }, ...curr.childRecommendations.map((ch, index) => {
          ch.templateProp1 = curr.templateProp1 + '.' + this.commonUtility.indexToLetter(ch.childVotingItemOrderId, false);
          ch.isChild = true;
          return ch;
        })], []);

        this.dataSource = new MatTableDataSource<ProjectResolutionBODRecommendation>();
        this.dataSource.data = data;

        this.showGrid = data.length > 0;

        if (data && data.length > 0 && data[0].projectTemplateId === ProjectTemplateTypes.ShareHolderMeeting) {
          this.displayedColumns = ['description', 'recommendation'];
          this.dataSource.data.forEach(x => x.templateProp2 = `${x.templateProp1}. ${x.templateProp2}`);
        }
      },
      err => { this.alertService.sendError(' error ' + JSON.stringify(err)); }
    );
  }

  onCancelClick() {
    this.getBoardOfDirectorsRecommendations();
    this.validateState(this.isEdit = false);
  }

  onSaveBtnClick() {
    const filteredRecommendations =
      this.dataSource.data
        .filter(x => !x.childRecommendations || !x.childRecommendations.length)
        .map(x => {
          const foundItem = this.items.find(item => item.id === x.id);

          x.recommendation = foundItem.value;

          return x;
        });

    const apiURL: string = environment.serverUrl + 'projects/project-bod-recommendations';

    this.httpClient.put<boolean>(apiURL, filteredRecommendations).subscribe(
      data => {
        this.alertService.sendSuccess('Project Resolution Board of Directors Updated');
        this.isEdit = false;
        this.validateState(this.isEdit);
      },
      err => { this.alertService.sendError(' error ' + JSON.stringify(err)); }
    );
  }

  getClassName(voteType) {
    return 'vote ' + (voteType || '').toLowerCase().replace(/ /g, '_').replace(/1/, 'one')
      .replace(/2/, 'two')
      .replace(/3/, 'three');
  }

  getColor(recommendation: string): string {
    const item = this.recommendations.find(x => x.fieldLabel.toUpperCase() === recommendation.toUpperCase());

    if (item) {
      return item.color;
    }
  }

  getRecommendationOptions(element) {
    return /^frequency/gmi.exec(element.votingItemType) ? this.frequencyVoteTypes : this.recommendationVoteTypes;
  }

  trackByFn(index, item) {
    return index;
  }

  private mapRecommendationsDataToVoteTable(recommendations: any[]): VoteTableDto[] {
    const items = [];

    recommendations.forEach(recommendation => {
      const isParentWithChild = recommendation.childRecommendations && recommendation.childRecommendations.length > 0;
      const recommendationVoteTableDto = new VoteTableDto({
        templateProp: `${recommendation.templateProp1}. ${recommendation.templateProp2}`,
        isParentWithChild: isParentWithChild,
        votingItemType: recommendation.votingItemType,
        value: recommendation.recommendation,
        id: recommendation.id
      });

      items.push(recommendationVoteTableDto);

      if (isParentWithChild) {
        recommendation.childRecommendations.map(child => {
          const letter = this.commonUtility.indexToLetter(child.childVotingItemOrderId, false);
          const childRecommendationVoteTableDto = new VoteTableDto({
            templateProp: `${recommendation.templateProp1}.${letter} ${child.templateProp2}`,
            votingItemType: recommendation.votingItemType,
            value: child.recommendation,
            isChild: true,
            id: child.id
          });

          items.push(childRecommendationVoteTableDto);
        });
      }
    });

    return items;
  }
}
