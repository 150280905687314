import { Shareholdings } from './ProjectCustodialListDto';

export class ProjectMailingDocRecipientSentDTO {
    mailingDocumentRecipientId: number;
    mailingDocumentRecipientSentId: number;
    projectId: number;
    setsSent: number;
    setsRequired: number;
    shareCapitals: Shareholdings[];
    recipientName: string;
    comment: string;
    date: Date;
    loggedUser: string;
    dateCreated: Date;
    barcodeNumber: string;

  public constructor(init?: Partial<any>) {
    Object.assign(this, init);
  }
}
