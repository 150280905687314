import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { VotingItemsService } from '@app/services/voting-items.service';
import { Lookup } from '@app/dto/Lookup';
import { CommonUtilityService } from '@app/utility/common-utility.service';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { VoteTableDto } from '@app/dto/VoteTableDto';
import {
  VotingPatternsScannedInvestorComponent
} from '@app/dashboard/dashboardau/dashboard-nobo-hr-table/voting-patterns-scanned-investor/voting-patterns-scanned-investor.component';
import { VoteToPatternDTO } from '@app/dto/VotingPatternsScannedInvestorDto';

@Component({
  selector: 'app-voting-patterns-dialog',
  templateUrl: './voting-patterns-dialog.component.html',
  styleUrls: [
    './voting-patterns-dialog.component.scss',
    '../styles/investor-voting-dialog.scss'
  ]
})
export class VotingPatternsDialogComponent implements OnInit {
  public items: VoteTableDto[] = [];
  public projectTemplate: string;
  public projectId: number;
  @ViewChild('scannedInvestors') private scannedInvestors: VotingPatternsScannedInvestorComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<VotingPatternsDialogComponent>,
    private votingItemsService: VotingItemsService,
    private commonUtilityService: CommonUtilityService,
    private projectTemplateService: ProjectTemplateService
  ) {
    this.projectId = data.projectId;
  }

  ngOnInit() {
    combineLatest([
      this.projectTemplateService.getProjectTemplate(this.projectId),
      this.votingItemsService.getVotingItems(this.projectId)
    ]).pipe(
      map(results => ({
          projectTemplate: results[0], votingItems: results[1]
        })
      )
    ).subscribe(result => {
      const { votingItems, projectTemplate } = result;

      this.setProjectTemplate(projectTemplate.template);
      this.items = this.mapVotingItems(votingItems);
    });
  }

  private setProjectTemplate(template: string): void {
    this.projectTemplate = template;
  }

  private mapVotingItems(votingItems: any): any[] {
    const items = [];

    votingItems.forEach(item => {
      const hasChildren = item.parentVotingItemChildren.length !== 0;

      items.push(
        new VoteTableDto({
          ...item,
          templateProp: `${item.templateProperty1}. ${item.templateProperty2}`,
          votingItemType: item.votingItemType,
          isParentWithChild: hasChildren,
        })
      );

      if (hasChildren) {
        item.parentVotingItemChildren.forEach(parentVotingItemChild => {
          const letter = this.commonUtilityService.indexToLetter(parentVotingItemChild.id, false);

          items.push(
            new VoteTableDto({
              ...item,
              votingItemType: item.votingItemType,
              templateProp: `${item.templateProperty1}.${letter}  ${parentVotingItemChild.name}`,
              isChild: true,
              orderId: parentVotingItemChild.id
            })
          );
        });
      }
    });

    return items;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  private isValidVoteTable(): boolean {
    return !this.items.filter(item => !item.value
      && (item.votingItemType !== 'Director Exception' || item.votingItemType === 'Director Exception' && item.isChild)).length;
  }

  public get isFormValid(): boolean {
    return this.isValidVoteTable() && this.scannedInvestors.isValid;
  }

  public onSave(): void {
    const votingPattern = this.items.reduce((prev, item) => {
      // tslint:disable-next-line:max-line-length
      return [...prev, ...((item.votingItemType !== 'Director Exception' || item.votingItemType === 'Director Exception' && item.isChild) ? [new VoteToPatternDTO(item)] : [])];
    }, []);
    const scannedInvestors = this.scannedInvestors.investors;
    const output = {
      votingPattern,
      scannedInvestors
    };

    this.dialogRef.close(output);
  }
}
