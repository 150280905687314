export class OciExportConfirmDialogDisplayData {
  cusips: string[];
  sources: string[];
  investorTypes: string[];
  numberOfInvestors: number;
}

export class OciExportDialogModel {
  projectName: string;
  numberOfInvestors: number;
  cusips: string[];
  sources: { name: string; value: number }[];
  investorTypes: { name: string; value: number }[];
}
