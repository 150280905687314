import {ProjectInvestorMatchingSuggestion} from '@app/dto/ProjectUnmatchedInvestorDto';
import { ActiveDirectoryUser } from './ActiveDirectoryUser';

export class ProjectInvestorAUDataRecord{
    projectInvestorId:number;
    projectId:number;
    investorId :number;
    responsibilityUser: ActiveDirectoryUser;
    project:string;
    investor:string;
    investorFileName:string;
    assetManager: string;
    fund: string;
    city:string;
    shareCapitalItems: InvestorShareCapitalItem[];
    coverage:string;
    uploadTemplate:string;
    usageType:string;
    status:string;
    edit:string;
    action: string;
    matchingSuggestions: ProjectInvestorMatchingSuggestion[];
}

export class InvestorShareCapitalItem {
    source: string;
    sourceId: number;
    shareType: string;
    shareTypeId: number;
    shareCapital: number;
    isPrimarySource: boolean;

    public constructor(init?:Partial<InvestorShareCapitalItem>) {
        Object.assign(this, init);
    }
}

export class InvestorSharesKeyValuePairDto {
  id: number;
  name: string;
}

export class InvestorSourceKeyValuePairDto extends InvestorSharesKeyValuePairDto {
  isPrimarySource: boolean;
}

export class ProjectInvestorAUDataUploadResponse {
  matchedData: ProjectInvestorAUDataRecord[] = [];
  unmatchedData: ProjectInvestorAUDataRecord[] = [];
  shareSources: string[] = [];
  shareTypes: string[] = [];
  sourceKeyValuePairs: InvestorSourceKeyValuePairDto[] = [];
  shareTypeKeyValuePairs: InvestorSharesKeyValuePairDto[] = [];
}

export class InvestorAURecord
{
    projectId: number;
    investorId : number;
    previousInvestorId: number;
    investor:string;
    location:string;
    shareHolding:number;
    previousShareHolding:number;
    uploadTemplate: string;
    usageType: string;
    status:string;
    updateBy:number;
}

