import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { ProjectTemplateService, ABSTAIN, UNDISCLOSED, UNDECIDED, IN_LINEwPADV } from '@app/service/projectTemplateService';
import { Lookup } from '@app/dto/Lookup';
import { AlertService } from '@app/services/alert.service';
import { InvestorVote, SaveVotesDTO } from '@app/dto/InvestorVote';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { ProjectCustodianReconciliationDialogComponent } from '@app/project/projectdetail/project-custodial-reconcilation/dialog/project-custodian-reconciliation-dialog.component';
import { BulkEditVotesDialogComponent } from '@app/dashboard/dashboardau/detail/investor-call-management/vote-records/bulk-edit-dalog/bulk-edit-votes-dialog.component';
import { RoleGuardService } from '@app/auth/role-guard.service';

@Component({
  selector: 'app-investor-call-management-vote-records',
  templateUrl: './investor-call-management-vote-records.component.html',
  styleUrls: ['./investor-call-management-vote-records.component.scss']
})

export class InvestorCallManagementVoteRecordsComponent implements OnInit {
  memory = [];
  isEdit = false;
  templateProp1Label: string;
  templateProp2Label: string;
  projectTemplate: string;
  intentionTypes: IntentionTypeCustom[] = [];
  votedTypes: VotingTypeCustom[] = [];
  resetMatOption = { value: null };
  normalIntentionTypes: Lookup[] = [];
  splitIntetionTypes: IntentionTypeCustom[] = [];
  votesToDelete = [];
  userId: number;
  votes: ExtendedInvestorVote[];
  dataSource: MatTableDataSource<ExtendedInvestorVote>;
  displayedColumns: string[] = [
    'itemNo',
    'description',
    'intention',
    'intentionType',
    'voted',
    'votedType',
    'resolutionId'
  ];
  hasNegativeNumberError = false;
  hasVoteCountGreaterThanIntentionError = false;
  @Input() projectId: number;
  @Input() investorId: number;
  @Input() shareClassification: string;
  @Output() getVoteRecordsFormState = new EventEmitter<boolean>();
  frequencyVotedTypes: VotingTypeCustom[] = [];
  frequencyIntentionTypes: VotingTypeCustom[] = [];
  votedtypecolors = this.projectTemplateService.votedtypecolors;

  canEditInvestor = false;
  hasFrequency = false;

  constructor(private projectTemplateService: ProjectTemplateService,
    private alertService: AlertService,
    private dashboardService: DashboardService,
    public dialog: MatDialog,
    private roleGuardService: RoleGuardService) {

  }

  ngOnInit() {
    this.canEditInvestor = this.roleGuardService.hasAccess('DASHBOARD', 'EDIT');

    this.userId = 1;
    this.setupIntentionAndVotingBasedOnProjectType(this.projectId);
    this.getVotes(this.projectId, this.investorId, this.shareClassification);
  }

  setupIntentionAndVotingBasedOnProjectType(projectId: number) {
    this.projectTemplateService.getProjectTemplate(projectId).subscribe(data => {
      const labels = this.projectTemplateService.getProjectItemsTemplateLabels(data.template);
      this.templateProp1Label = labels.templateProp1Label;
      this.templateProp2Label = labels.templateProp2Label;
      this.projectTemplate = data.template;

      const fVotedTypes: any = this.projectTemplateService.getFrequencyVoteTypes(true, 'VOTED', [UNDISCLOSED, UNDECIDED]);
      const fIntentionTypes: any = this.projectTemplateService.getFrequencyVoteTypes(true, 'VOTED', [UNDISCLOSED, UNDECIDED, IN_LINEwPADV]);
      this.frequencyVotedTypes = fVotedTypes.reduce((a, t) => {
          a.push({
            value: t.fieldValue,
            label: t.fieldLabel,
            color: t.color
          });
          return a;
        }, []);

        this.frequencyIntentionTypes = fIntentionTypes.reduce((a, t) => {
          a.push({
            value: t.fieldValue,
            label: t.fieldLabel,
            color: t.color
          });
          return a;
        }, []);
      this.normalIntentionTypes = this.projectTemplateService.getIntentionTypeWithoutSplitVote(this.projectTemplate);
      this.splitIntetionTypes = this.projectTemplateService.getIntentionTypes(true, this.projectTemplate).map(t => {
        const find = (this.votedtypecolors.find(tc => tc.type === t.fieldValue)) || { value: '', color: '' };
        return {
          value: t.fieldValue,
          label: t.fieldLabel,
          color: find.color
        };
      });
      this.intentionTypes = this.normalIntentionTypes.map(t => {
        const find = (this.votedtypecolors.find(tc => tc.type.toUpperCase() === t.fieldValue.toUpperCase())) || { value: '', color: '' };
        return {
          value: t.fieldValue,
          label: t.fieldLabel,
          color: find.color
        };
      });

      this.votedTypes = this.projectTemplateService.getVotedTypes(this.projectTemplate).map(t => {
        const find = (this.votedtypecolors.find(tc => tc.type === t.fieldValue)) || { value: '', color: '' };
        return {
          value: t.fieldValue,
          label: t.fieldLabel,
          color: find.color
        };
      });
    },
      err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      });
  }

  getVotes(projectId: number, investorId: number, shareClassification: string) {
    this.memory = [];
    this.dashboardService.getDashboardVotes(projectId, investorId, shareClassification).subscribe(
      data => {
        this.votes = data;
        this.hasFrequency = this.projectTemplateService.hasFrequency(data);
        this.votes.forEach(vote => {
          vote.userId = this.userId;
          vote.templateProp2 = isNaN(+vote.templateProp2) ?
            vote.templateProp2 :
            Number(parseFloat(vote.templateProp2).toFixed(2)).toLocaleString('en', {
              minimumFractionDigits: 2
            });
          vote.votedType = vote.isInlineVote ? `${vote.votedType} (PXY)` : vote.votedType;
        });
        this.dataSource = new MatTableDataSource<ExtendedInvestorVote>();
        this.dataSource.data = this.orderByParent(this.votes);
        const dat = this.addColorsToVotes(this.votes);
        this.setInitialNumbersInMemory(dat);
      },
      err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      }
    );
  }

  setInitialNumbersInMemory(data) {
    this.memory = data.reduce((a, b) => {
      if (!b.primaryVoteId) {
        const _intention = this.dataSource.data.reduce((_a, el) => {
          let value = 0;
          if (el.primaryVoteId === b.voteId) {
            value = el.intention;
          }
          return _a + value;
        }, 0);
        const _voted = this.dataSource.data.reduce((_a, el) => {
          let value = 0;
          if (el.primaryVoteId === b.voteId) {
            value = el.voted;
          }
          return _a + value;
        }, 0);
        a[b.voteId] = {
          intention: b.intention + _intention,
          voted: b.voted + _voted
        };
      }
      return a;
    }, []);
  }

  getInitialNumbersFromMemory(voteId) {
    return this.memory[voteId];
  }

  addColorsToVotes(data) {
    const votes = [];
    data.forEach(v => {
      if (!v.intentionType || (v.intentionType && !v.intentionType.color)) {
        v.intentionType = {
          value: v.intentionType || '-',
          color: v.intentionType ? this.getVotedTypeColor(v.intentionType.toUpperCase()) : 'inherit'
        };
      }
      if (!v.votedType || (v.votedType && !v.votedType.color)) {
        v.votedType = {
          value: v.votedType || '-',
          color: v.votedType ? this.getVotedTypeColor(v.votedType.toUpperCase()) : 'inherit'
        };
      }
      votes.push(v);
    });

    return votes;
  }

  getClassName(voteType) {
    return 'vote ' + (voteType && voteType.value || '').toLowerCase().replace(/1/, 'one')
      .replace(/2/, 'two').replace(/3/, 'three')
      .replace(/[^a-z]+/gmi, '_');
  }

  getVotedTypeColor(votedtype: string) {
    if (!votedtype) {
      return 'white';
    }
    return (this.votedtypecolors.find(item => item.type.toUpperCase() === votedtype.toUpperCase()) || { color: 'inherit' }).color;
  }

  orderByParent(votes: ExtendedInvestorVote[]) {
    const parents = votes.filter(v => !v.primaryVoteId);
    const result = [];
    parents.forEach((p, index) => {
      p.isDark = index % 2 === 1;

      result.push(p);
      const child = votes.filter(v => v.primaryVoteId === p.voteId).map(v => {
        v.isDark = p.isDark;
        return v;
      });
      result.push(...child);
    });
    return result;
  }

  removeVote(vote: ExtendedInvestorVote) {
    if (!vote.new) { this.votesToDelete.push(vote); }
    vote.voted = 0;
    vote.intention = 0;
    this.calculateIntention(vote);
    this.calculateVotes(vote);
    this.dataSource.data = this.dataSource.data.filter(v => v.voteId !== vote.voteId);
  }

  splitVote(vote: ExtendedInvestorVote) {
    const _vote = Object.assign({}, vote);

    const newRow = new ExtendedInvestorVote();
    newRow.primaryVoteId = _vote.voteId;
    newRow.intention = 0;
    newRow.voted = 0;
    newRow.voteId = new Date().getTime();
    newRow.new = true;
    newRow.templateProp1 = _vote.templateProp1;
    newRow.templateProp2 = _vote.templateProp2;
    newRow.shareCapitalId = _vote.shareCapitalId;
    newRow.votingItemType = _vote.votingItemType;

    newRow.projectTypeId = _vote.projectTypeId;
    newRow.relatedProjectItemId = _vote.relatedProjectItemId;
    newRow.userId = _vote.userId;
    newRow.hasSplitVote = _vote.hasSplitVote;
    newRow.financialId = _vote.financialId;

    const _data = [...this.dataSource.data];
    _data.push(newRow);
    this.addColorsToVotes(_data);
    this.dataSource = new MatTableDataSource<ExtendedInvestorVote>();
    this.dataSource.data = [...this.orderByParent(_data)];
  }

  checkMax(element) {
    return this.dataSource.data.filter(d => d.primaryVoteId === element.voteId).length >= this.intentionTypes.length;
  }

  compareFn(c1: IntentionTypeCustom, c2: IntentionTypeCustom): boolean {
    return c1 && c2 ? c1.value === c2.value : c1 === c2;
  }

  calculateIntention(element) {
    if (!element.primaryVoteId) {
      const thisIndex = this.dataSource.data.map(row => row.voteId).indexOf(element.voteId);
      const _intention = this.dataSource.data.reduce((a, el) => {
        let value = 0;
        if (el.primaryVoteId === element.voteId) {
          value = el.intention;
        }
        return a + value;
      }, 0);

      const _value = element.intention + _intention;

      this.dataSource.data[thisIndex].intention = element.intention;
      this.memory[element.voteId] = {
        intention: element.intention,
        voted: _value
      };
      this.validateValues();
      this.validateVoteCounts();
      return;
    }

    const memory = this.getInitialNumbersFromMemory(element.primaryVoteId);
    const mainVoteIndex = this.dataSource.data.map(row => row.voteId).indexOf(element.primaryVoteId);
    const intention = this.dataSource.data.reduce((a, el) => {
      let value = 0;

      if (el.primaryVoteId === element.primaryVoteId) {
        value = el.intention;
      }
      return a - value;
    }, memory.intention);
    this.dataSource.data[mainVoteIndex].intention = intention;
    this.validateValues();
    this.validateVoteCounts();
  }

  calculateVotes(element) {
    if (!element.primaryVoteId) {
      const thisVoteIndex = this.dataSource.data.map(row => row.voteId).indexOf(element.voteId);
      const _voted = this.dataSource.data.reduce((a, el) => {
        let value = 0;
        if (el.primaryVoteId === element.voteId) {
          value = el.voted;
        }
        return a + value;
      }, 0);

      const _value = element.voted + _voted;

      this.dataSource.data[thisVoteIndex].voted = element.voted;
      this.memory[element.voteId] = {
        intention: element.intention,
        voted: _value
      };
      this.validateValues();
      this.validateVoteCounts();
      return;
    }

    const memory = this.getInitialNumbersFromMemory(element.primaryVoteId);
    const mainVoteIndex = this.dataSource.data.map(row => row.voteId).indexOf(element.primaryVoteId);
    const voted = this.dataSource.data.reduce((a, el) => {
      let value = 0;
      if (el.primaryVoteId === element.primaryVoteId) {
        value = el.voted;
      }
      return a - value;
    }, memory.voted);
    this.dataSource.data[mainVoteIndex].voted = voted;
    this.validateValues();
    this.validateVoteCounts();
  }

  onCancelClick() {
    this.votesToDelete = [];
    this.getVotes(this.projectId, this.investorId, this.shareClassification);
    this.isEdit = false;
    this.getVoteRecordsFormState.emit(this.isEdit);
  }

  onSaveBtnClick(dataSource) {
    const votes = dataSource.data.map(v => {
      v.intentionType = v.intentionType.value !== '-' ? v.intentionType.value : null;
      v.isInlineVote = (v.votedType && v.votedType.value && v.votedType.value.includes('PXY') ? true : false);
      v.votedType = v.votedType.value !== '-' ? v.isInlineVote ? v.votedType.value.replace(' (PXY)', '') : v.votedType.value : null;
      v.voteId = v.new ? null : v.voteId;

      delete v.isDark;
      delete v.new;

      return v;
    });


    const saveVotesDTO = new SaveVotesDTO();
    saveVotesDTO.votesToUpdate = votes;

    if (this.votesToDelete.length) {
      saveVotesDTO.votesToDelete = this.votesToDelete.map(v => {
        v.intentionType = v.intentionType.value !== '-' ? v.intentionType.value : null;
        v.isInlineVote = (v.votedType && v.votedType.value && v.votedType.value.includes('PXY') ? true : false);
        v.votedType = v.votedType.value !== '-' ? v.isInlineVote ? v.votedType.value.replace(' (PXY)', '') : v.votedType.value : null;
        v.voteId = v.new ? null : v.voteId;

        delete v.isDark;
        delete v.new;

        return v;
      });
    }
    this.updateDashboardVotes(saveVotesDTO);

    this.isEdit = false;
    this.getVoteRecordsFormState.emit(this.isEdit);
  }

  private updateDashboardVotes(saveVotesDTO: SaveVotesDTO) {
    this.dashboardService.updateDashboardVotes(saveVotesDTO).subscribe(
      data => {
        this.alertService.sendSuccess('Votes are updated.');

        this.getVotes(this.projectId, this.investorId, this.shareClassification);

        this.isEdit = false;
      },
      err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      }
    );
  }

  validateVoteCounts() {
    const relatedProjectItems = this.dataSource.data.map(x => x.relatedProjectItemId)
      .filter((i, index, arr) => arr.indexOf(i) === index);
    let valid = true;
    relatedProjectItems.forEach(element => {
      const itemVotes = this.dataSource.data.filter(x => x.relatedProjectItemId === element);
      const intentionSum = itemVotes.map(a => a.intention).reduce((a, b) => a + b, 0);
      const votedSum = itemVotes.map(a => a.voted).reduce((a, b) => a + b, 0);
      valid = valid && (intentionSum >= votedSum);
    });
    this.hasVoteCountGreaterThanIntentionError = !valid;
  }

  private validateValues() {
    if (this.dataSource.data.filter(x => x.intention < 0 || x.voted < 0).length > 0) {
      this.hasNegativeNumberError = true;
    } else {
      this.hasNegativeNumberError = false;
    }
  }

  bulkEdit() {
    const first = (this.dataSource.data || []).find(row => !row.primaryVoteId);
    const sums = (this.dataSource.data || []).reduce((res, item) => {
      if (item.relatedProjectItemId === first.relatedProjectItemId) {
        res['intention'] += item.intention;
        res['voted'] += item.voted;
      }
      return res;
    }, { intention: 0, voted: 0 });

    const dialogRef = this.dialog.open(BulkEditVotesDialogComponent,
      {
        width: '600px',

        data:
        {
          status: 'started',
          votedTypeColors: this.votedtypecolors,
          intentionTypes: this.intentionTypes,
          votingTypes: this.votedTypes,
          projectTemplate:this.projectTemplate,
          ...sums
        }
      });
    dialogRef.afterClosed().subscribe(result => {

      (this.dataSource.data || []).filter(row => row.primaryVoteId).forEach(vote => this.votesToDelete.push(vote));
      this.dataSource.data = (this.dataSource.data || []).filter(row => !row.primaryVoteId).map(vote => {
        vote.voted = result.data.voted;
        vote.intention = result.data.intention;
        vote.intentionType = result.data.intentionType;
        vote.votedType = result.data.votedType;
        return vote;
      });
      this.onSaveBtnClick(this.dataSource);
    });
  }

  onEditBtnClick() {
    this.isEdit = true;
    this.getVoteRecordsFormState.emit(this.isEdit);
  }

  getOptions(element, type = 'voted') {
    return (/^frequency/gmi.exec(element.votingItemType)) ? this[`frequency${this.capitalizeFirstLetter(type)}Types`] : this[`${type}Types`];
  }

  capitalizeFirstLetter(string) {
    return string.replace(/^./, str => str.toUpperCase());
  }

  hideVotedTypes(intentionType:string, votedType:string): boolean {
    return this.projectTemplateService.hideVotedTypes(this.projectTemplate, intentionType, votedType);
  }
}

class ExtendedInvestorVote extends InvestorVote {
  isDark: boolean;
  new: boolean;
}

class IntentionTypeCustom {
  value: string;
  label: string;
  color: string;
}

class VotingTypeCustom {
  value: string;
  label: string;
  color: string;
}
