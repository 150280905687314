import {Component, Input, OnInit} from '@angular/core';
import {convertPascalCaseToString, convertToStringIfBoolean} from '@app/utility/Strings';
import {isDate} from '@app/utility/Dates';
import { AuditLogConstants } from '../auditLogConstants';
import {reformatValues} from '@app/utility/auditLog';

@Component({
  selector: 'app-generic-logs-update-segment',
  templateUrl: './generic-logs-update-segment.component.html',
  styleUrls: ['generic-logs-update-segment.component.scss']
})

export class GenericAuditLogsUpdateSegmentComponent implements OnInit {
  private static readonly EMPTY = '( Empty )';
  _data;
  isOpen = false;
  @Input() public data: any;

  constructor() {
  }

  ngOnInit() {
    const values = this.data.ColumnValues;
    this._data = Object.keys(values).map(key => {
      const item = {
        key: convertPascalCaseToString(key),
        old: isDate(values[key]) ? new Date(values[key]).toLocaleString() : values[key],
        oldIsDate: (AuditLogConstants.dateColumnsFormatExceptions.indexOf(key) < 0) && isDate(values[key]),
      };

      let updatedData = {};
      const updatedObject = this.data.Changes.find(change => change.ColumnName === key);
      if (updatedObject) {

        updatedObject.NewValue = reformatValues(updatedObject.NewValue, key);
        updatedObject.OriginalValue = reformatValues(updatedObject.OriginalValue, key);

        updatedData = {
          old: isDate(updatedObject.OriginalValue) ? new Date(updatedObject.OriginalValue).toLocaleString() : convertToStringIfBoolean(updatedObject.OriginalValue) || GenericAuditLogsUpdateSegmentComponent.EMPTY,
          new: isDate(updatedObject.NewValue) ? new Date(updatedObject.NewValue).toLocaleString() : convertToStringIfBoolean(updatedObject.NewValue) || GenericAuditLogsUpdateSegmentComponent.EMPTY,
          classDefinition: updatedObject.ColumnName.toLowerCase(),
          oldIsDate: (AuditLogConstants.dateColumnsFormatExceptions.indexOf(updatedObject.ColumnName) < 0) && isDate(updatedObject.OriginalValue),
          newIsDate: (AuditLogConstants.dateColumnsFormatExceptions.indexOf(updatedObject.ColumnName) < 0) && isDate(updatedObject.NewValue),
        };
      }

      return {...item, ...updatedData};
    });
  }
}
