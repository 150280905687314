import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportProject, ReportRequestParameter } from '../../dto/report/au/Report';
import { environment } from '../../../environments/environment';
import { ProjectsService } from '@app/services/projects.service';
import { ClientProjectAssignmentDTO } from '@app/dto/ClientProjectAssignment';
import {
    MatTableModule,
    MatSortModule, MatPaginatorModule,
    MatSort, MatTableDataSource
} from '@angular/material';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from '@app/services/alert.service';

@Component({
    selector: 'app-rpt-project-list',
    templateUrl: './rpt-project-list.component.html',
    styleUrls: ['rpt-project-list.component.css']
})

export class RptProjectListComponent implements OnInit {
    projectStatusFilter: string = 'All';
    reportprojects: ReportProject[] = [];
    displayedColumns: string[] = ['projectName', 'projectStatus'];
    @ViewChild(MatSort) sort: MatSort;
    dataSource;
    routeSub;
    userid: number;
    rolename: string;
    coverage: string;
    reportid: number;
    projectid: number;
    reportProject: ReportProject;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private httpClient: HttpClient,
        private userService: UserService,
        private alertService: AlertService) {
    }

    ngOnInit() {
        this.userid = 0;
        this.rolename = this.userService.getUserRoleName();
        this.coverage = this.userService.getUserCoverage();

        this.routeSub = this.route.params.subscribe((params) => {
            this.reportid = +params['reportid'];
            this.getProjects(this.rolename, this.reportid);
        });
    }
    getProjects(rolename: string, reportid: number) {

        let apiURL: string = environment.serverUrl + 'reports/' + this.projectStatusFilter;

        this.httpClient.get<ReportProject[]>(apiURL).subscribe(
            data => {
                data.forEach(item => item.reportId = reportid);
                this.dataSource = new MatTableDataSource<ReportProject[]>();
                this.dataSource.sort = this.sort;
                this.dataSource.data = data;
            },
            err => { this.alertService.sendError(err); }
        );
    }

    onDownloadReport(elem: ReportProject) {
        let rptParameter: ReportRequestParameter = new ReportRequestParameter();
        rptParameter.projectId = elem.projectId;
        rptParameter.usageType = elem.projectType;
        rptParameter.coverage = elem.coverage;
        rptParameter.userid = this.userid;
        rptParameter.rolename = this.rolename;
        rptParameter.reportClassificationCode = this.reportid;
        rptParameter.reportName = elem.projectName;


        let apiURL: string = environment.serverUrl + 'reports/' + this.reportid + '/' + elem.projectId + '?reportName=' +  encodeURIComponent(elem.projectName);
        let getfileheaders = new HttpHeaders().set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        this.httpClient.get(apiURL, { responseType: 'blob', headers: getfileheaders }).subscribe(
            data => {
                let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var blobURL = window.URL.createObjectURL(blob);
                var anchor = document.createElement("a");
                anchor.download = elem.projectName + '-' + new Date().toISOString() + '.xlsx';
                anchor.href = blobURL;
                anchor.click();
            },
            err => { this.alertService.sendError(err); }
        );
    }

    onStatusFilterChenge() {
        this.getProjects(this.rolename, this.reportid);
    }
}