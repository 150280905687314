import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {GlobalCustodiansGeneralComponent} from '@app/admin/global-custodians/global-custodians-sub-menu/global-custodians-general/global-custodians-general.component';


@Component({
  selector: 'app-global-custodians-dialog-add',
  templateUrl: './global-custodians-dialog-add.component.html',
  styleUrls: ['global-custodians-dialog-add.component.scss']
})
export class GlobalCustodiansDialogAddComponent {
  @ViewChild(GlobalCustodiansGeneralComponent) child: GlobalCustodiansGeneralComponent;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GlobalCustodiansDialogAddComponent>
  ) { }

  onClickSave() {
    this.child.onSaveBtnClick();
  }

  onClickReset() {
    this.child.onResetBtnClick();
  }

  onCloseBtnClick(): void {
    this.dialogRef.close();
  }

  formSaved(flag) {
    if(flag){
      this.onCloseBtnClick();
    }
  }
}
