import {Component, Inject, OnInit} from '@angular/core';
import {LookupFilter, Utils} from 'src/app/helper/utils';
import {StartupdataproviderService} from '@app/service/startupdataprovider.service';
import {Lookup} from '@app/dto/Lookup';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AlertService} from '@app/services/alert.service';
import {ProjectFinancialDetail} from '@app/dto/ProjectFinancialDetail';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { PROJECT_FINANCIAL_DETAILS_COMMENT_MAX_LENGTH } from '@app/project/project.constants';
import { AppConstants } from '@app/app.constants';

@Component({
  selector: 'app-project-financial-edit-dialog',
  templateUrl: './project-financial-edit-dialog.component.html',
  styleUrls: ['project-financial-edit-dialog.component.scss'],
})
export class ProjectFinancialEditDialogComponent implements OnInit {
  shareClassifications: Lookup[] = [];
  model: ProjectFinancialDetail;
  projectFinancialDetails: ProjectFinancialDetail[] = [];
  public commentFieldMaxLength = PROJECT_FINANCIAL_DETAILS_COMMENT_MAX_LENGTH;
  public isUsProject: boolean;
  public cusipLength = AppConstants.CUSIP_LENGTH;

  constructor(private startupdataproviderService: StartupdataproviderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProjectFinancialEditDialogComponent>,
    private alertService: AlertService,
    private roleGuardService:RoleGuardService) {
    const util = new Utils(this.startupdataproviderService);
    util.getLookupByType(LookupFilter.SHARECLASSIFICATION).subscribe(r => this.shareClassifications = r);

    this.model = data.model;
    this.projectFinancialDetails = data.projectFinancialDetails;
    this.isUsProject = data.isUsProject;
  }

  ngOnInit() {
  }


  onSaveBtnClick() {
    this.dialogRef.close({ data: this.model });
  }

  onChange() {
    const isShareNumber = this.isValidNumber(this.model.numberOfShare);
    this.model.numberOfShare = isShareNumber ? +this.model.numberOfShare : null;

    const isExchangeRatioNumber = this.isValidNumber(this.model.exchangeRatio);
    this.model.exchangeRatio = isExchangeRatioNumber ? +this.model.exchangeRatio : 1;

    if (isShareNumber) {
      this.model.ordinaryEquivalent = this.model.numberOfShare * this.model.exchangeRatio;
    } else {
      this.model.ordinaryEquivalent = undefined;
    }
  }

  isValidNumber(valueAsString) {
    const value = +valueAsString;
    return !isNaN(value) && value > 0;
  }

  isExistRecord() {
    // This is an additional validation if the combination shareClassification and ISIN is already exist for other record in the list
    if (this.model && this.model.shareClassification) {
      const foundRecord = this.projectFinancialDetails
        .find(row => this.model.projectFinancialDetailId !== row.projectFinancialDetailId
          && row.shareClassification === this.model.shareClassification
          && row.isin === this.model.isin);
      if (foundRecord) {
        return true;
      }
    }
    return false;
  }
}
