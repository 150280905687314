import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  MatSort, MatTableDataSource, MatPaginator
} from '@angular/material';
import { ExportService } from '../service/export.service';
import { ProjectList } from '../dto/ProjectList';
import { SearchGridService } from '@app/service/search-grid/search-grid.service';
import { RoleGuardService } from '@app/auth/role-guard.service';


@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['project-list.component.scss']
})

export class ProjectListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'clientName', 'type', 'year', 'marketCapitialization', 'sector',
    'country', 'pMId', 'projectStartDate', 'projectEndDate'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource();
  searchService: SearchGridService;
  canAddProject: boolean = false;

  constructor(private httpClient: HttpClient, private exportService: ExportService,
    private roleguardService: RoleGuardService) { }

  ngOnInit() {
    this.searchService = new SearchGridService(this.paginator, this.dataSource, this.httpClient, 'projects/projects');

    // For PSC roles the user can add project but only of its specific type
    this.canAddProject = this.roleguardService.hasAccess("PROJECTS", "CREATE");
  }

  ngOnDestroy(): void {
    this.searchService.unSubscribe();
  }

  downloadExport() {
    this.searchService.getAllItems().subscribe(
      data => {
        this.exportService.export(data.items, 'Projects');
      },
      err => console.error(err)
    );
  }
}
