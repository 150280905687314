import { ActiveDirectoryUser } from "./ActiveDirectoryUser";

export class ProjectActivity {
  checked: boolean;
  projectActivityId: number;
  projectId: number;
  activityId: number;
  taskId: number;
  activityName: string;
  taskName: string;
  description: string;
  startDate?: Date;
  endDate?: Date;
  responsibilityUser: ActiveDirectoryUser;
  status: string;
  activityType: string;
  statusChangedByName?: string | null;
  isDefault: boolean;
  marketCoverage: string;
  projectStatus: string;
  taskShouldNotHaveDuplicates: boolean;
}
