import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';
import { Investor } from '@app/dto/Investor';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { InvestorsVotingProcess } from '@app/dto/InvestorsVotingProcess';
import { InvestorBulkEditResponsibility } from '@app/dto/InvestorBulkEditResponsibility';

@Injectable({
  providedIn: 'root'
})
export class InvestorsService {
  private cache$: Observable<Investor[]>[] = [];
  constructor(private http: HttpClient) { }

  public getInvestor(investorId: string, useCache: boolean = false): Observable<Investor> {
    if (!this.cache$[investorId] || !useCache) {
      this.cache$[investorId] = this.http
        .get<Investor>(`${environment.serverUrl}investors/investor-by-id/${investorId}`).pipe(
          map(data => data),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        );
    }

    return this.cache$[investorId];
  }

  public  createInvestor(investor: Investor){
    return  this.http.post<Investor>(`${environment.serverUrl}investors`, investor);
  }

  public  updateInvestor(investor: Investor){
    return  this.http.put<Investor>(`${environment.serverUrl}investors`, investor);
  }

  public getVotingProcess(investorId: number): Observable<InvestorsVotingProcess> {
    return this.http.get<InvestorsVotingProcess>(`${environment.serverUrl}investorintelligence/voting-process/${investorId}`);
  }

  public updateVotingProcess(data): Observable<InvestorsVotingProcess>{
    return this.http.put<InvestorsVotingProcess>(`${environment.serverUrl}investorintelligence/voting-process`, data);
  }

  public bulkEditResponsibility(data: InvestorBulkEditResponsibility): Observable<number> {
    return this.http.post<number>(`${environment.serverUrl}investors/bulk-reassign`, data);
  }

  clearCache(investorId: number|null = null) {
    if (this.cache$ && investorId) {
      this.cache$[investorId] = null;
    } else {
      this.cache$ = null;
    }
  }
}
