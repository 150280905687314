import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SelectOptions } from '@app/components/table-filter-wrapper/interfaces';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  constructor(
    private http: HttpClient
  ) { }

  public getFilterOptions(endpoint: string): Observable<SelectOptions[]> {
    const url = `${environment.serverUrl}${endpoint}`;

    return this.http.get<SelectOptions[]>(url);
  }
}
