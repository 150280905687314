import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { AddInvestorInitiativeDialogComponent } from './addinvestorinitiativedialog/addinitiativedialog.component';
import { InvestorInitiativeGridComponent } from '../investor-intel-common-grid/investor-intel-common-grid.component';
import { FilterDto } from '@app/service/search-grid/SearchDto';

@Component({
  selector: 'app-investor-intel-esg-initiative',
  templateUrl: './investor-intel-esg-initiative.component.html',
  styleUrls: ['./investor-intel-esg-initiative.component.scss']
})
export class InvestorIntelEsgInitiativeComponent implements OnInit {
  @Input()
  investorId: number;
  @ViewChild(InvestorInitiativeGridComponent) gridChild: InvestorInitiativeGridComponent;

  displayedGridColumns: string[] = ['initiativeName', 'impact', 'sectorFocus', 'regionalFocus', 'parentActivated', 'summary', 'actions'];
  additionalGridFilter: FilterDto;
  gridRequestUrl = 'esginitiatives/all-investor-initiatives';
  gridIdKeyName = 'esgInitiativeId';
  gridDeleteUrl = 'esginitiatives/soft-delete-investor-initiative';
  deleteConfirmationTitle = 'Initiative';
  deleteMessageMappingKey = 'name';
  noItemsGridText = 'There are no Initiatives';

  constructor(
    private httpClient: HttpClient,
    public dialog: MatDialog,
  ) {
  }
  ngOnInit() {
    this.additionalGridFilter = new FilterDto({ filterPorp: 'investorId', value: [this.investorId + ''] });
  }

  refreshGridData() {
    this.gridChild.fetchGridData();
  }

  onEditClicked(initiative: any) {
    const dialogRef = this.dialog.open(AddInvestorInitiativeDialogComponent, {
      width: '1000px',
      maxHeight: 'fit-content',
      data: {
        investorId: this.investorId,
        isCreate: false,
        initiativeId: initiative.esgInitiativeId,
        title: 'Edit initiative'
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.refreshGridData();
      }
    });
  }

  onAddBtnClick() {
    const dialogRef = this.dialog.open(AddInvestorInitiativeDialogComponent, {
      width: '1000px',
      maxHeight: 'fit-content',
      data: {
        investorId: this.investorId,
        isCreate: true,
        title: 'Add New Initiative:'
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.refreshGridData();
      }
    });
  }
}
