import { AuditBeneficiaryActionType } from './../enums/AuditBeneficiaryActionTypeEnum';
import {AuditActionType} from '@app/enums/AuditActionTypeEnum';

export const convertPascalCaseToString = (string) => {
  return string
    .replace(/([a-z\d])([A-Z])/g, '$1 $2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1 $2')
    .replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
};


export const humaniseString = (string) => {
  const realStrings = {
    [AuditActionType.DELETE]: 'Deleted',
    [AuditActionType.INSERT]: 'Added',
    [AuditActionType.UPDATE]: 'Updated'
  };
  return (realStrings[string]) ? realStrings[string] : string;
};

export const convertToStringIfBoolean = (value, debug = false) => {
  return `${value}`;
};

export const beneficiaryString = (string) => {
    switch (string){
      case 'Added':{return [AuditBeneficiaryActionType.INSERT]};
      case 'Update': {return [AuditBeneficiaryActionType.UPDATE]};
      case 'Delete' : {return  [AuditBeneficiaryActionType.DELETE]};
    }
};

export const sanitizeFileName = (value: string): string => {
  const specialChars = [
    "?",
    "[",
    "]",
    "/",
    "\\",
    "=",
    "<",
    ">",
    ":",
    ";",
    ",",
    "'",
    '"',
    "&",
    "$",
    "#",
    "*",
    "(",
    ")",
    "|",
    "~",
    "`",
    "!",
    "{",
    "}",
    "%",
    "+",
  ];

  for (let specialChar of specialChars) value = value.replace(specialChar, "");
  value = value.replace(" ", "_");

  return value;
};
