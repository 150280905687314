import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ContactConversationLogComponent } from '@app/contact/contactdetail/contact-conversationlog.component';
import { VoteWithPercentage } from '@app/dashboard/dashboardau/dashboard-voting-summary/interfaces/voting-summary';
import { ProjectInvestorShareDTO } from '@app/dto/ProjectInvestorShareDTO';
import { VoteWithPercentageEdit } from '@app/dto/VoteWithPercentageEdit';
import { ProjectTemplateTypes } from '@app/enums/ProjectTemplateTypesEnum';
// tslint:disable-next-line: max-line-length
import { DynamicColumnDefinition } from '@app/project/projectdetail/project-custodial-reconcilation/project-custodial-reconciliation.component';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { CommonUtilityService } from '@app/utility/common-utility.service';

@Component({
    selector: 'app-voting-intentions-table',
    templateUrl: './voting-intentions-table.component.html',
    styleUrls: ['./voting-intentions-table.component.scss']
  })
  export class DashboardVotingIntentionsTableComponent implements OnInit {
    columns = [];
    displayDynamicColumns: DynamicColumnDefinition[] = [];
    staticColumns = ['shares', 'undeclaredIntentions'];
    @Input() projectId: number;
    @Input() investorId: number;
    @Input() share: ProjectInvestorShareDTO;
    @Input() projectType: ProjectTemplateTypes;
    @Input() votingItemTypes: string[];
    @Input() isEdit: boolean;
    public dataSource = new MatTableDataSource();
    isFrequency: boolean;
    isCorporateAction: boolean;
    hasVoteCountGreaterThanIntentionError = false;
    allIntentions: ProjectInvestorShareDTO;
    @Output() isValid = new EventEmitter<boolean>();


    constructor(private projectTemplateService: ProjectTemplateService,
        private commonUtilityService: CommonUtilityService) {}

    ngOnInit(): void {
      this.allIntentions = this.share;
      this.isCorporateAction = this.projectType === ProjectTemplateTypes.CorporateAction;
      this.isFrequency = this.projectTemplateService.hasFrequencySimple(this.votingItemTypes);
      this.setDataSource();
    }

    setDataSource() {
      const additional = this.isFrequency
        ? this.projectTemplateService.getFrequencyVoteTypes(
          false,
          'CUSTODIAL_RECONCILIATION',
          [],
          this.projectTemplateService.isFrequencyWithReverseOrderSimple(this.votingItemTypes, this.isFrequency))
        : [];
      const votingTypes = this.projectTemplateService.getVotingIntentionsVotedTypes(this.projectType, additional);

      this.setColumns(votingTypes);
      this.setTableRows(votingTypes, this.share.votingItems);
    }

    setColumns(votingTypes) {
    this.columns = [];
    this.displayDynamicColumns = [];
    if (!this.isCorporateAction) {
      this.columns.push('resolution');
    } else {
      this.columns.push('company');
      this.columns.push('bid');
    }
    this.columns = [...this.columns, ...this.staticColumns];

    votingTypes.forEach(votingType => {
      const coldef = new DynamicColumnDefinition();
      coldef.colName = `${votingType.fieldValue}`;
      coldef.colHeaderName = votingType.fieldLabel;
      coldef.colHeaderColor = votingType.color;

      this.columns.push(`${votingType.fieldValue}`);
      this.displayDynamicColumns.push(coldef);
    });
  }

  setTableRows(votingTypes, votingItems) {

    const tableRows = votingItems.reduce((accumulator, row) => {
      if (row.votingItemChild) {
        const letter = this.commonUtilityService.indexToLetter(row.votingItemChild.orderId, false);
        row.templateProperty1 = letter;
        row.templateProperty2 = row.votingItemChild.name;
      }
      row.undeclaredIntention = new VoteWithPercentage(row.undeclaredIntention, this.share.shares);
      votingTypes.forEach(item => {
        const result = row.votes.find(vote => vote.voteType === item.fieldValue);
        row[`${item.fieldValue}`] = new VoteWithPercentageEdit(result, this.share.shares);
      });

      accumulator.push(row);
      return accumulator;
    }, []);

    this.dataSource.data = tableRows;
  }

  isDirectorException(votingItemId) {
    return this.share.votingItems.findIndex(row => row.votingItemChild && row.votingItemChild.parentVotingItemId === votingItemId) > -1;
  }

  isTotalsTable() {
    return this.share.shareClassification === 'Total';
  }

  calculateIntention(elementDynColm, element) {
    const index = element.votes.findIndex(vote => vote.voteType === elementDynColm.voteType );
    element.votes[index].vote = elementDynColm.votes;

    if (this.validateIntentionCounts(element) || elementDynColm.votes > this.allIntentions.shares) {
      this.hasVoteCountGreaterThanIntentionError = true;
    } else {
      this.hasVoteCountGreaterThanIntentionError = false;
    }
    this.isValid.emit(!this.hasVoteCountGreaterThanIntentionError);
  }



  validateIntentionCounts(element) {
    let total = 0;
    element.votes.forEach(vote => total += vote.vote);

    if (total > this.share.shares) {
      return true;
    }

    return false;
  }
}
