import {Component, Inject, OnInit, EventEmitter, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {CallLog} from '@app/dto/CallLog';
import {Lookup} from '@app/dto/Lookup';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MsAdalAngular6Service} from 'microsoft-adal-angular6';
import {ValidationHelper} from '@app/helper/validationHelper';
import { Utils, LookupFilter } from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';

@Component({
  selector: 'app-comment-edit-dialog',
  templateUrl: 'comment-edit-dialog.component.html',
  styleUrls: ['comment-edit-dialog.component.scss']
})
export class CommentEditDialogComponent implements OnInit {
  validationHelper = new ValidationHelper();
  model: CallLog;
  callLogCommentTypes: Lookup[] = [];
  util = new Utils(this.startUpDataProvider);

  constructor(
    public dialogRef: MatDialogRef<CommentEditDialogComponent>,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private startUpDataProvider: StartupdataproviderService,
    private msadService: MsAdalAngular6Service,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.matIconRegistry.addSvgIcon(
      'icon_calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/navigation-icons/calendar.svg')
    );
  }

  ngOnInit() {
    this.util.getLookupByType(LookupFilter.COMMENTTYPE).subscribe(data => this.callLogCommentTypes = data);
    // will log the entire data object
    this.model = {...this.data};
    this.model.followupTime = this.model.followupTimePicked? this.model.followup : null;
    const {userInfo: {profile: {name: username = ''}}} = this.msadService;
    this.model.username = this.model.username || username;
    this.model.userId = 0;

    // needed so that followup is correctly handled and not thrown to 1st Jan 1970
    if (!this.model.followup == null) {
      this.model.followup = undefined;
    }
  }

  saveComment() {
    // needed so that followup is correctly handled and not thrown to 1st Jan 1970
    if (!this.validationHelper.isValidDate(this.model.followup)) {
      this.model.followup = undefined;
    }
    this.dialogRef.close({data: this.model});
  }
}
