import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExternalAdvisorsComponent} from '@app/admin/external-advisors/external-advisors.component';
import {ExternalAdvisorsRoutingModule} from '@app/admin/external-advisors/external-advisors-routing.module';
import {ExternalAdvisorsListComponent} from './external-advisors-list/external-advisors-list.component';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {CdkTableModule} from '@angular/cdk/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ComponentsModule} from '@app/components/components.module';
import {ExternalAdvisorsDialogComponent} from '@app/admin/external-advisors/external-advisors-dialog/external-advisors-dialog.component';
import {AdminExternalAdvisorResolver} from '@app/resolvers/admin/admin-external-advisor.resolver';

@NgModule({
  imports: [
    ComponentsModule,

    FormsModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,

    MatSortModule,
    MatTabsModule,
    MatInputModule,
    MatCheckboxModule,
    CdkTableModule,
    MatExpansionModule,
    MatDialogModule,
    MatDatepickerModule,
    MatListModule,
    MatNativeDateModule,
    MatRadioModule,
    MatAutocompleteModule,
    NgbModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,


    CommonModule,
    ExternalAdvisorsRoutingModule,
    MatTableModule,
  ],
  declarations: [

    ExternalAdvisorsComponent,
    ExternalAdvisorsListComponent,
    ExternalAdvisorsDialogComponent,


  ],
  exports: [
    ExternalAdvisorsListComponent
  ],
  entryComponents: [
    ExternalAdvisorsDialogComponent
  ],
  providers: [
    AdminExternalAdvisorResolver
  ],
  bootstrap: [ExternalAdvisorsComponent]
})
export class ExternalAdvisorsModule {
}
