import {templateInterpolator} from '@app/helper/templateInterpolator';
import {DialogsConfig, DialogTemplateConfig} from '@app/services/dialogs/dialogs.config';

export class DialogGeneralTemplateConfig {
  title: string;
  message: string;
  config: DialogsConfig;

  public constructor(init?: Partial<DialogTemplateConfig>, params?: any) {
    init.title = templateInterpolator(init.title, params);
    init.message = templateInterpolator(init.message, params);
    Object.assign(this, init);
  }
}
