import {AuditActionType} from '@app/enums/AuditActionTypeEnum';
import {AuditEntityType} from '@app/enums/AuditEntityTypeEnum';

export class AuditLogDTO {
  auditId: number;
  auditData: any;
  tablePK: number;
  title?: string;
  auditAction: AuditActionType;
  auditUser: string;
  auditUserId: number;
  auditDate: string;
  entityType: AuditEntityType;
  entityParent: string;
  parentPK: number;
}

export class AuditLogGroupedDTO {
  date: string;
  logs: AuditLogDTO[];
}

