import { FilterType, GridFilterItem } from '@app/components/grid-filter/grid-filter.models';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'
  ]})
export class FilterModalComponent {
  filters: GridFilterItem[] = [];
  responsibility: string [] = [];
  country: string [] =[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: GridFilterItem[],
    private dialogRef: MatDialogRef<FilterModalComponent>) {
      this.filters = data;
  }
  
  onCancelFilterClick(): void {
    this.dialogRef.close();
  }

  onApplyFilterClick(): void {
    this.validateAutocompletes();
    this.dialogRef.close(this.filters);
  }

  private validateAutocompletes(){
    this.filters = this.filters.slice(0,1);
    if(this.responsibility){
      this.filters.push(new GridFilterItem ({
            filterPorp: "responsibility", 
            filterName: "Responsibility", 
            filterType: FilterType.AutoCompleteUser,
            value :  [this.responsibility["id"]] 
          }));
    }
    if(this.country){
      this.filters.push(new GridFilterItem ({
            filterPorp: "country", 
            filterName: "Country", 
            filterType: FilterType.AutoCompleteCountry,
            value :  [this.country["name"]] 
          }));
    }
   }
}
