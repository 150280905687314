import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { OptionsPickerModel, ValuesLookup, ManualBrokerSearchLookup } from '@app/dto/OptionsPicker';
import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

export const _filter = (opt, value: string) => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => {
    return item.name.toLowerCase().indexOf(filterValue) >= 0;
  });
};

@Component({
  selector: 'app-options-picker',
  templateUrl: './options-picker.component.html',
  styleUrls: ['./options-picker.component.scss']
})
export class OptionsPickerComponent implements OnInit {
  _configuration;
  @ViewChild('scrollableContainer') scrollableContainer: ElementRef<HTMLElement>;
  @Input() public disabled = false;
  @Input() public useInfiniteScroll = false;
  @Output() disabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() set configuration(obj: OptionsPickerModel<ValuesLookup> | OptionsPickerModel<ManualBrokerSearchLookup>) {
    this.checkConfigCategorized(obj);
    if (obj) {
      this._configuration = {...this._configuration, ...obj};
      this.calculateNotSelectedRecords();
    }
  }

  @Output() configurationChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public searchValueChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');

  editIt = false;
  model = {
    allNotSelectedRecords: 0,
    allSelectedRecords: 0,
    allNotSelectedRecordsCheck: false,
    allSelectedRecordsCheck: false,
    indeterminateAllNotSelectedRecords: false,
    indeterminateAllSelectedRecords: false,
  };

  allNotSelectedRecords: ValuesLookup[] = [];
  _allNotSelectedRecords: ValuesLookup[] = [];
  allSelectedRecords: ValuesLookup[] = [];
  allNotSelectedRecordsCategorized = [];
  allNotSelectedRecordsOptions = [];
  categorized = false;
  private page = 1;
  private pageSize = 30;
  private searchString = '';

  constructor() {
    this._configuration = new OptionsPickerModel();
  }

  ngOnInit() {
    this.initCategoriesOptions();
    this.calculateNotSelectedRecords();
  }

  public onIntersectionInView() {
    this.page = this.page + 1;
    const data = this.createData(this.searchString);
    this.setAllNotSelectedRecords(data);
  }

  doSearch(value) {
    this.searchValueChanges.next(value);
  }

  initCategoriesOptions = () => {
    this.searchValueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        map(name => {
          this.searchString = name || '';
          this.page = 1;

          return this.createData(name);
        })
      ).subscribe(data => {
        if (this.scrollableContainer) {
          this.scrollableContainer.nativeElement.scrollTo(0, 0);
        }
        this.setAllNotSelectedRecords(data);
      });
  }

  private _filterGroup(value: string) {
    if (value) {
      return this.allNotSelectedRecordsCategorized
        .map(group => ({title: group.title, items: _filter(group.items, value)}))
        .filter(group => group.items.length > 0);
    }

    return this.allNotSelectedRecordsCategorized;
  }

  checkConfigCategorized( config ) {
    if (!config || !config.allRecords[0]) {
      this.categorized = false;
    } else if ( config.allRecords[0].category ) {
      this.categorized = true;
    } else {
      this.categorized = false;
    }
  }

  changeDisabled(value) {
    this.disabled = value;
    this.disabledChange.emit(value);
  }

  calculateNotSelectedRecords() {
    this.allSelectedRecords = (this._configuration || {selectedRecords: []}).selectedRecords || [];
    this.editIt = this.allSelectedRecords.length > 0;
    const allSelectedIds = this.allSelectedRecords.map(record => record.id);
    this.allNotSelectedRecords = ((this._configuration || {allRecords: []}).allRecords || []).filter(record => allSelectedIds.indexOf(record.id) === -1);
    this.updateCategories();
  }

  updateCategories() {
    if (this.categorized) {
      this.allNotSelectedRecordsCategorized = this.sortRecordsByCategory(this.allNotSelectedRecords);
    }

    const data = this.createData('');
    this.setAllNotSelectedRecords(data);
  }

  bulkUpdateCheckbox($event, records) {
    for (const r of records) {
      r.checked = $event.checked;
    }
    if (records === this.allNotSelectedRecords) {
      this.model.allNotSelectedRecords = (this.allNotSelectedRecords || []).filter(rec => rec.checked).length;
    } else if (records === this.allSelectedRecords) {
      this.model.allSelectedRecords = (this.allSelectedRecords || []).filter(rec => rec.checked).length;
    }
  }

  updateBulkCheckbox() {
    this.model.allNotSelectedRecords = (this.allNotSelectedRecords || []).filter(rec => rec.checked).length;
    this.model.allSelectedRecords = (this.allSelectedRecords || []).filter(rec => rec.checked).length;
    this.model.indeterminateAllNotSelectedRecords = this.model.allNotSelectedRecords > 0 && this.model.allNotSelectedRecords !== (this.allNotSelectedRecords || []).length;
    this.model.indeterminateAllSelectedRecords = this.model.allSelectedRecords > 0 && this.model.allSelectedRecords !== (this.allSelectedRecords || []).length;
    this.model.allNotSelectedRecordsCheck = this.model.allNotSelectedRecords && this.model.allNotSelectedRecords !== (this.allNotSelectedRecords || []).length;
    this.model.allSelectedRecordsCheck = this.model.allSelectedRecords && this.model.allSelectedRecords !== (this.allSelectedRecords || []).length;
  }

  moveToSelected() {
    const allChecked = (this.allNotSelectedRecords || []).reduce((accomulator, rec) => {
      if (rec.checked) {
        accomulator.selected = [...accomulator.selected, rec];
      } else {
        accomulator.notSelected = [...accomulator.notSelected, rec];
      }

      return accomulator;
    }, {selected: [], notSelected: []});
    this.allSelectedRecords = [...this.allSelectedRecords, ...allChecked.selected.map(row => {
      row.checked = false;
      return row;
    })];
    this.allNotSelectedRecords = allChecked.notSelected;
    this.resetPageOnSelectAll();
    this.updateConfig();
    this.updateBulkCheckbox();
  }

  moveToNotSelected() {
    const allChecked = (this.allSelectedRecords || []).reduce((accomulator, rec) => {
      if (rec.checked) {
        accomulator.selected = [...accomulator.selected, rec];
      } else {
        accomulator.notSelected = [...accomulator.notSelected, rec];
      }

      return accomulator;
    }, {selected: [], notSelected: []});

    this.allNotSelectedRecords = [...this.allNotSelectedRecords, ...allChecked.selected.map(row => {
      row.checked = false;
      return row;
    })];

    this.allSelectedRecords = allChecked.notSelected;
    this.updateConfig();
    this.updateBulkCheckbox();
    this.updateCategories();
  }

  updateConfig() {
    this._configuration.selectedRecords = this.allSelectedRecords;
    this.configurationChange.emit(this._configuration);
  }

  sortRecordsByCategory(records) {
    const result = [];
    const dictionary = {};
    for ( const record of records ) {
      if (!dictionary[record.category]) {
        dictionary[record.category] = [];
      }
      dictionary[record.category].push(record);
    }
    for ( const category of Object.keys(dictionary).sort((a, b) => a.localeCompare(b))) {
      result.push({
        title: category,
        items: dictionary[category]
      });
    }
    return result;
  }

    private setAllNotSelectedRecords(data) {
      let records;

      if (this.useInfiniteScroll && data.length) {
        const sliceTo = this.page * this.pageSize;

        records = data.map(x => {
          const categoryName = x.items[0].category;

          if (categoryName === 'Others') {
            return {
              ...x,
              items: x.items ? x.items.slice(0, sliceTo) : []
            };
          } else {
            return x;
          }
        });
      } else {
        records = data;
      }

      if (this.categorized) {
        this.allNotSelectedRecordsOptions = records;
      } else {
        this._allNotSelectedRecords = records;
      }
    }

    private createData(searchString: string): any {
      if (this.categorized) {
        return searchString ? this._filterGroup(searchString) : this.allNotSelectedRecordsCategorized.slice();
      } else {
        return searchString ? _filter(this.allNotSelectedRecords, searchString) : this.allNotSelectedRecords.slice();
      }
    }

    private resetPageOnSelectAll() {
      if (this.model.allNotSelectedRecords === this.page * this.pageSize) {
        this.page = 1;
      }
    }
}
