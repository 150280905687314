export class InvestorIntelItem {
    investorIntelItemId: number;
    name: string;
    impacts: string[];
    date?: Date;
    deactivatedDate?: Date;
    summary: string;
    exclusionImpact: string;
    hasSectorFocus = false;
    sectorFocuses: string[];
    hasRegionFocus = false;
    regionFocuses: string[];
    hasThematicFocus = false;
    thematicFocuses: string[];
    affectedCompanies: string[];
    investorId: number;
    itemType: string;
    customTextFeild: string;
}
