import { FilterOperators, SortOperators } from '@app/components/table-filter-wrapper/enums';

export interface IFilterAndPaginate extends IFilterAndOrder {
  pagination: PaginationDTO;
}

export interface IFilterAndOrder {
  filters: Array<FilterDTO>;
  sort: SortDTO[];
}

export class FilterAndPaginateDTO implements IFilterAndPaginate {
  filters: Array<FilterDTO> = [];
  pagination: PaginationDTO = new PaginationDTO();
  sort: SortDTO[] = [];

  constructor(init?: Partial<IFilterAndPaginate>) {
    Object.assign(this, init);
  }
}

export class FilterAndPaginateOutputDTO {
  pagination: PaginationDTO;
  columns: Array<Array<FilterDTO>>;
  sort: SortDTO[];

  constructor(init?: Partial<FilterAndPaginateDTO>) {
    this.pagination = init.pagination || new PaginationDTO();
    this.columns = Object.values(this.groupByFieldName(init.filters.map(item =>  this.convertDates(item))));
    this.sort = init.sort || [];
  }

  private convertDates(item: FilterDTO) {
    const { value } = item;
    let values;
    if (!Array.isArray(value)) { values = [value]; } else { values = value; }
    item.value = values.map((val, index) => {
      if (val instanceof Date ) {
        const date = new Date(val);
        if (values.length === 2) {
          if (index === 0) {
            date.setHours(0, 0, 0, 0);
          } else {
            date.setHours(23, 59, 59, 999);
          }
          val = new Date(date).toISOString();
        }
      }

      return val;
    });

    return item;
  }

  groupByFieldName = array => array.reduce(
    (result, item) => {
      if (!result[item.fieldName]) {
        result[item.fieldName] = [];
      }
      result[item.fieldName].push(item);
      return result;
    }, []
  )
}

interface IPagination {
  page: number;
  pageSize: number;
}

export class PaginationDTO implements IPagination {
  page: number;
  pageSize: number;

  constructor(pageIndex = 0, pageSize = 20) {
    this.page = pageIndex;
    this.pageSize = pageSize;
  }
}

interface IFilter {
  fieldName: string;
  operator: FilterOperators;
  value: string[] | number[] | Date[];
}

export class FilterDTO implements IFilter {
  public fieldName: string;
  public operator: FilterOperators = FilterOperators.EQUAL;
  public value: null | string[] | number[] | Date[];

  constructor(fieldName: string, value: null | string[] | number[] | Date[], operator: FilterOperators = FilterOperators.EQUAL) {
    this.fieldName = fieldName;
    this.value = value;
    this.operator = operator;
  }
}

interface ISort {
  fieldName: string;
  orderType: SortOperators;
}

export class SortDTO implements ISort {
  fieldName: string;
  orderType: SortOperators;

  constructor(fieldName, orderType) {
    this.fieldName = fieldName;
    this.orderType = orderType;
  }
}
