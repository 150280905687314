import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of, EMPTY} from 'rxjs';
import {PpBreadcrumbsResolver, Breadcrumb} from 'pp-breadcrumbs';
import {catchError, mergeMap} from 'rxjs/operators';
import {GlobalCustodiansService} from '@app/services/admin/global-custodians.service';
import {LocalCustodiansService} from '@app/services/admin/local-custodians.service';

@Injectable()
export class LocalCustodiansResolver extends PpBreadcrumbsResolver {
  constructor(private localCustodiansService: LocalCustodiansService) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Promise<Breadcrumb[]> | Breadcrumb[] {
    const id = Number(route.params['id']);
    if (id === 0) {
      return of([{
        text: 'Add new clearing house',
        path: this.getFullPath(route)
      }]);
    } else {
      return this.localCustodiansService.get(id, true).pipe(catchError(error => {
          return EMPTY;
        }), mergeMap(data => {
          if (data) {
            return of([{
              text: data.name,
              path: this.getFullPath(route)
            }]);
          } else {
            return EMPTY;
          }
        })
      );
    }
  }
}
