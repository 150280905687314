import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '@app/services/user.service';

@Component({
    selector: 'app-clientdetail-landing',
    templateUrl: './clientdetail-landing.component.html',
    styleUrls: ['clientdetail-landing.component.css']
  })
  export class ClientDetailLandingComponent implements OnInit {
    index = 0;
    routeSub: any;
    clientid:number;
    rolename:string;
    userid:number;
    coverage:string;

    constructor(private route: ActivatedRoute, private userService: UserService, private router: Router) {
    }
    ngOnInit() {
      this.rolename = this.userService.getUserRoleName();
      this.userid = 0;
      this.coverage = this.userService.getUserCoverage();

      this.routeSub = this.route.params.subscribe((params) => {
            this.clientid = +params['clientid'];

      });
    }
  }
