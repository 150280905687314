import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input, OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { matSortAnimations, MatSortHeader, MatSortHeaderIntl } from '@angular/material';
import { MatMultiSort } from '@app/components/mat-multi-sort/mat-multi-sort.directive';
import { CdkCellDef, CdkFooterCellDef, CdkHeaderCellDef } from '@angular/cdk/table';



/** Column definition associated with a `MatSortHeader`. */
interface C2MatSortHeaderColumnDef {
  name: string;
  _name: string;
  stickyEnd: any;
  _stickyEnd: boolean;
  cell: CdkCellDef;
  headerCell: CdkHeaderCellDef;
  footerCell: CdkFooterCellDef;
  cssClassFriendlyName: string;
  sticky: boolean;
  hasStickyChanged: () => boolean;
  _hasStickyChanged: boolean;
  resetStickyChanged: () => void;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[mat-multi-sort-header]',
  exportAs: 'matMultiSortHeader',
  templateUrl: './mat-multi-sort-header.component.html',
  styleUrls: ['./mat-multi-sort-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    matSortAnimations.indicator,
    matSortAnimations.leftPointer,
    matSortAnimations.rightPointer,
    matSortAnimations.arrowOpacity,
    matSortAnimations.arrowPosition,
    matSortAnimations.allowChildren
  ]
})
export class MatMultiSortHeaderComponent extends MatSortHeader implements OnInit {
  start = 'asc' as 'asc' | 'desc';
  @Input('mat-multi-sort-header') id: string;
  @Input('appTableFilter') appTableFilter;

  constructor(public _intl: MatSortHeaderIntl,
              changeDetectorRef: ChangeDetectorRef,
              @Optional() public _sort: MatMultiSort,
              @Inject('C2_SORT_HEADER_COLUMN_DEF') @Optional() public _columnDef: C2MatSortHeaderColumnDef) {
    super(_intl, changeDetectorRef, _sort, _columnDef);
  }

  @HostListener('mouseenter', ['true'])
  @HostListener('longpress', ['true'])
  @HostListener('mouseleave', ['false'])
  __setIndicatorHintVisible(visible: string | boolean) {
    super._setIndicatorHintVisible(visible as boolean);
  }

  @HostListener('click', ['$event'])
  handleClick(evt: MouseEvent) {
    this._sort.direction = this.getSortDirection();
    super._handleClick();
  }

  ngOnInit() {
    if (!this.id) {
      this.id = this.appTableFilter.fieldName;
    }
    super.ngOnInit();

  }

  _isSorted() {
    return this._sort.actives.findIndex(activeId => activeId === this.id) > -1;
  }

  _updateArrowDirection() {
    this._arrowDirection = this.getSortDirection();
  }

  @HostBinding('attr.aria-sort')
  _getAriaSortAttribute() {
    if (!this._isSorted()) {
      return null;
    }

    return this.getSortDirection() === 'asc' ? 'ascending' : 'descending';
  }

  _renderArrow() {
    return !this._isDisabled() || this._isSorted();
  }

  getSortDirection(): 'asc' | 'desc' | '' {
    const i = this._sort.actives.findIndex(activeIds => activeIds === this.id);
    const direction = this._sort.directions[i];
    return this._isSorted() ? direction : (this.start || this._sort.start);
  }

}
