import { ProjectInvestorShareDTO } from './ProjectInvestorShareDTO';

export class ProjectInvestorIntentionVoteDTO {
    projectId: number;
    investorId: number;
    projectType: string;
    shares: ProjectInvestorShareDTO[];
    votingItemTypes: string[];
}

export class ProjectInvestorIntentionVoteAdapter  {
  investorId: number;
  projectId: number;
  projectType: string;
  shares: ProjectInvestorShareDTO[];
  votingItemTypes: string[];

  constructor(init?: Partial<ProjectInvestorIntentionVoteDTO>) {
    Object.assign(this, init);
  }
}
