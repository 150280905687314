import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {NotificationListDto} from '@app/dto/NotificationsDto';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) {
  }

  public getNewCount(): Observable<any> {
    const url = `${environment.serverUrl}UserNotifications/new-notifications`;
    return this.http.get<any>(url);
  }

  public setSeen(): Promise<boolean> {
    const url = `${environment.serverUrl}UserNotifications/mark-seen-notitifcations`;
    return this.http.post<boolean>(url, {}).toPromise();
  }

  public getNotifications(skip, take): Observable<NotificationListDto> {
    const url = `${environment.serverUrl}UserNotifications/${skip}/${take}`;
    return this.http.get<NotificationListDto>(url);
  }
}
