import {Component, OnInit} from '@angular/core';
import {NotificationsService} from '@app/services/notifications.service';
import {Observable, timer, of} from 'rxjs';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {switchMap, catchError} from 'rxjs/operators';
import {AlertService} from '@app/services/alert.service';
import {NotificationDto} from '@app/dto/NotificationsDto';
import {MAT_MENU_SCROLL_STRATEGY} from '@angular/material';
import {Overlay, BlockScrollStrategy} from '@angular/cdk/overlay';
import {Router} from '@angular/router';
import {NotificationRelatedEntityTypeEnum} from '@app/enums/NotificationRelatedEntityTypeEnum';

const TAKE_N_RECORDS_AT_ONCE = 5;

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [
    {provide: MAT_MENU_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay]}
  ]
})
export class NotificationsComponent implements OnInit {
  public readonly NOTIFICATION_DATA = [
    {
      relatedEntityTypeId: NotificationRelatedEntityTypeEnum.ProxyAdvice,
      icon: 'bell',
      linkTemplate: '/projects/:id/recommendation' // use :id in the place of the id of the link
    },
    {
      relatedEntityTypeId: NotificationRelatedEntityTypeEnum.CallManagement,
      icon: 'bell',
      linkTemplate: 'dashboard/detail/:id/:secondaryId/(dashboardauoutlet:investorcallmanagement/:id/:secondaryId)' // use :id in the place of the id of the link
    },
    {
      relatedEntityTypeId: NotificationRelatedEntityTypeEnum.ProjectActivity,
      icon: 'bell',
      linkTemplate: 'projects/:id/activity' // use :id in the place of the id of the link
    },
    {
      relatedEntityTypeId: NotificationRelatedEntityTypeEnum.KeyEvent,
      icon: 'calendar',
      linkTemplate: 'projects/:id/general' // use :id in the place of the id of the link
    }
  ];
  notificationCount = 0;
  skip = 0;
  take = TAKE_N_RECORDS_AT_ONCE;
  totalResults = 0;
  notifications: NotificationDto[] = [];
  showLoadMore = false;

  constructor(private notificationsService: NotificationsService,
              private iconRegistry: MatIconRegistry,
              private alertService: AlertService,
              private router: Router,
              private sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'bell',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/navigation-icons/bell.svg'));

    iconRegistry.addSvgIcon(
      'calendar',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/navigation-icons/calendar.svg'));
  }

  ngOnInit() {
    this.initNotifications();
  }

  initNotifications() {
    timer(0, 10000)
      .pipe(
        switchMap(_ => this.notificationsService.getNewCount()),
        catchError(error => of(`Bad request: ${error}`))
      ).subscribe(data => {
      this.notificationCount = data;
    });


  }

  notificationsOpened() {
    this.skip = 0;
    this.totalResults = 0;
    this.notifications = [];
    this.loadNotifications(null);
  }


  loadNotifications($event) {
    if ($event) {
      $event.stopPropagation();
    }
    if (!this.skip || (this.skip && this.skip < this.totalResults)) {
      this.notificationsService.getNotifications(this.skip, this.take).subscribe(data => {

          if (this.notificationCount > 0 && this.skip === 0) {
            this.notificationCount = 0;
            this.notificationsService.setSeen();
          }

          this.skip += data.items.length;
          this.totalResults = data.totalResults;
          data.items = this.extendDataItem(data.items);
          this.notifications = [...this.notifications, ...data.items];

          if (this.skip === this.totalResults) {
            this.showLoadMore = false;
          } else {
            this.showLoadMore = true;
          }


        },
        err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        });
    }
  }

  goTo(link) {
    this.router.navigateByUrl(link);
  }

  extendDataItem(items) {
    const self = this;
    return items.map((item) => {
      const _object = self.NOTIFICATION_DATA.find(note => note.relatedEntityTypeId === item.relatedEntityTypeId);
      item.icon = (_object || {icon: 'bell'}).icon;
      item.link = (_object || {linkTemplate: ''}).linkTemplate
        .replace(/:id/g, item.mappingId)
        .replace(/:secondaryId/g, item.secondaryMappingId);
      return item;
    });
  }
}
