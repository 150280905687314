import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() plaintText = false;
  @Input() title: string;
  @Input() isEdit: boolean;
  @Input() isInvalid: boolean;
  @Input() isSubTitle: boolean;
  @Output() cancelBtnClick = new EventEmitter<void>();
  @Output() saveBtnClick = new EventEmitter<void>();
  @Output() editBtnClick = new EventEmitter<void>();

  constructor() { }

  public emitCancelBtnClick(): void {
    this.cancelBtnClick.emit();
  }

  public emitSaveBtnClick(): void {
    this.saveBtnClick.emit();
  }

  public emitEditBtnClick(): void {
    this.editBtnClick.emit();
  }
}
