import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilityService {

  constructor() { }

  indexToLetter(index: number, isZeroBased = true) {
    return String.fromCharCode(97 + index - (isZeroBased ? 0 : 1));
  }
}
