export class InvestorValueSelectGroupModel {
  header: string = '';
  noRecordsText: string = '';
  noRecordsLink: string = '';
  allRecords: ValuesLookup[] = [];
  selectedRecords: ValuesLookup[] = [];
}

export class InvestorsVotingProcess {
  investorId: number;
  decisionMakersLookup?: ValuesLookup[];
  votingPlatformLookup?: ValuesLookup[];
  decisionMakers: ValuesLookup[];
  votingPlatforms: ValuesLookup[];
  comment: string | null;
}

export class ValuesLookup {
  id: number;
  name: string;
  checked?: boolean;
}
