import { ShareVotingItemsDTO } from './ShareVotingItemsDTO';

export class ProjectInvestorShareDTO {
    shareClassificationId: number;
    shareClassification: string;
    shares: number;
    votingItems: ShareVotingItemsDTO[];
}

export class ProjectInvestorShareAdapter {
  shareClassification: string;
  shareClassificationId: number;
  shares: number;
  votingItems: ShareVotingItemsDTO[];

  constructor(init?: Partial<ProjectInvestorShareDTO>) {
    Object.assign(this, init);
  }
}
