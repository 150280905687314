import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-voting-logs-update-segment',
  templateUrl: './voting-logs-update-segment.component.html',
  styleUrls: ['voting-logs-update-segment.component.scss']
})

export class VotingLogsUpdateSegmentComponent implements OnInit {

  _data = [];
  isOpen = false;
  @Input() public data: any;

  constructor() {
  }

  getPrimaryVote = (elements) => {
    const primaryVoteIndex = elements.findIndex(record => record.ColumnValues.PrimaryVoteId === null);
    const primaryVote = {...elements[primaryVoteIndex]};
    delete elements[primaryVoteIndex];
    return primaryVote;
  };

  separatedVotes = (elements) => {
    return elements.reduce((result, element) => {
      if (element.Action === 'Insert') {
        result.insert.push(element);
      } else if (element.Action === 'Delete') {
        result.delete.push(element);
      } else {
        result.update.push(element);
      }
      return result;
    }, {update: [], insert: [], delete: []});
  };

  getVotes = (data) => this.getIntentions(data, ['VotedType', 'Voted']);

  getIntentions = (data, fields = ['IntentionType', 'Intention']) => {
    return fields.map(param => {
      const values = [];
      let result = {
        key: param
      };
      let haveChange = false;
      ['OriginalValue', 'NewValue'].forEach(valueType => {
        result = {...result, ...{
            [valueType]: ((data.Changes || []).find(_item => _item.ColumnName === param) || {[valueType]: (data.ColumnValues[param] || 'Empty')})[valueType],
          }};
        haveChange = haveChange || !!(data.Changes || []).find(_item => _item.ColumnName === param)
      });
      return {...result, haveChange};
    });
  }

  combineData = (data, action = 'Update') => {
    const arrayValueTypes = ['OriginalValue', 'NewValue'];
    if (action === 'Insert') {
      arrayValueTypes.shift();
    } else if (action === 'Delete') {
      arrayValueTypes.pop();
    }
    return {...arrayValueTypes.reduce((result, valueType) => {
      result[valueType] = data.map(item => {
        return {
          key: item.key,
          value: item[valueType],
          haveChange: item.haveChange
        };
      });

      return result;
    }, {}), ...{actionType: action}};
  };

  getDifference = (data) => {
    return {
      intentions: this.combineData(this.getIntentions(data), data.Action),
      voting: this.combineData(this.getVotes(data), data.Action)
    };
  }

  processSeparatedVotes = (data) => {
    return ['update', 'delete', 'insert'].reduce((result, actions) => {
      result = [...result, ...data[actions].map(item => {
        return this.getDifference(item);
      })];
      return result;
    }, []);
  };

  ngOnInit() {
    const data = this.groupByResolution(this.data);

    const changes = [];
    const logs = Object.keys(data).reduce((result, key) => {
      const elements = data[key];
      const primaryVote = this.getPrimaryVote(elements);
      const separatedVotes = this.separatedVotes(elements);
      // If we do not have any updates do not put into the log
      if (!(primaryVote.Changes || []).length &&
        !(separatedVotes.insert.length + separatedVotes.delete.length + separatedVotes.update.length)) {
        return result;
      }
      result.push({
        title: primaryVote.ColumnValues.VotingTypeTitle,
        items: [this.getDifference(primaryVote), ...this.processSeparatedVotes(separatedVotes)]
      });

      return result;
    }, []);

    this._data = logs;
  }

  groupByResolution = (data) => data.reduce((result, item) => {
    const resolutionName = item.ColumnValues.VotingTypeTitle;
    if (!result[resolutionName]) {
      result[resolutionName] = [];
    }
    result[resolutionName].push(item);
    return result;
  }, []);

}
