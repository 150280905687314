import { NgModule } from '@angular/core';
import { AddressTextareaDirective } from './address-textarea.directive';

@NgModule({
  imports: [],
  declarations: [AddressTextareaDirective],
  exports: [AddressTextareaDirective],
  providers: [],
})
export class AddressTextareaModule { }
