import { DirtyComponent } from '@app/common/dirty-component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS, MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { StartupdataproviderService } from '../../../service/startupdataprovider.service';
import { ProjectActivity } from '../../../dto/ProjectActivity';
import { LookupFilter, Utils } from '../../../helper/utils';
import { Lookup } from '../../../dto/Lookup';
import { ProjectTask } from '../../../dto/ProjectTask';
import { NgForm } from '@angular/forms';
import { AlertService } from '@app/services/alert.service';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/components/date-picker/date-picker.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { ProjectActivityEditDialogComponent } from './dialog/project-activity-edit-dialog/project-activity-edit-dialog.component';
import { ProjectActivityService } from '@app/services/project-activity.service';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import { ProjectActivityConstants } from './constants';
import { AppConstants } from '@app/app.constants';
import { ActiveDirectoryUser } from '@app/dto/ActiveDirectoryUser';
import { ProjectsService } from '@app/services/projects.service';

@Component({
    selector: 'app-project-activity',
    templateUrl: './project-activity.component.html',
    styleUrls: ['project-activity.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})

export class ProjectActivityComponent implements OnInit, DirtyComponent {
    displayedColumns: string[] = ['activityName', 'taskName', 'description', 'startDate', 'endDate', 'status', 'responsibility', 'projectActivityId'];
    activityStatuses: string[] = ['Not Started', 'In Progress', 'Completed', 'Abandoned'];
    activityTypeSingleResponsibilityValue: string = ProjectActivityConstants.activityTypesValues.activityTypeSingleResponsibilityValue;
    activityStatusInProgress: string = AppConstants.PROJECT_ACTIVITY_STATUS.activityStatusInProgress;
    activityStatusCompleted: string = AppConstants.PROJECT_ACTIVITY_STATUS.activityStatusCompleted;
    activityTypes: Lookup[] = [];
    @ViewChild(MatSort) sort: MatSort;
    dataSource;
    isEdit: boolean = false;
    model: ProjectActivity;
    routeSub: any;
    projectid: number;

    activities: Lookup[] = [];
    projecttasks: ProjectTask[] = [];
    filteredprojecttasks: ProjectTask[] = [];
    investorsTasks: ProjectTask[] = [];
    util = new Utils(this.startupdataproviderService);

    canEdit: boolean = false;
    public isDirty = new BehaviorSubject<boolean>(false);
    public isDirty$ = this.isDirty.asObservable();

    public assignToProjectTeamDisabled = false;

    projectManager: ActiveDirectoryUser = new ActiveDirectoryUser();
    formActive = true;

    noItemsText: string = 'Activities';
    showGrid: boolean;
    activityTypeEnabled: boolean = true;
    usProxyActivityName = "US:PROXY";
    usMarket = "US";
    preparingProjectStatus = "Preparing";
    projectStatus: string;
    projectMarket: string;

    constructor(private route: ActivatedRoute,
        private httpClient: HttpClient,
        private startupdataproviderService: StartupdataproviderService,
        private alertService: AlertService,
        private roleguardService: RoleGuardService,
        public dialog: MatDialog,
        private projectActivityService: ProjectActivityService,
        private dialogsService: DialogsService,
        private projectService: ProjectsService) {
        this.model = new ProjectActivity();
        this.model.responsibilityUser = new ActiveDirectoryUser();
    }

    ngOnInit() {
        this.model.status = 'Not Started';
        this.dataSource = new MatTableDataSource<ProjectActivity[]>();
        this.dataSource.sort = this.sort;
        this.routeSub = this.route.parent.params.subscribe((params) => {
            this.projectid = +params['id'];
            if (this.projectid > 0) {
                this.projectService.getProjectType(this.projectid).subscribe(data =>{
                    this.canEdit =  this.roleguardService.hasAccessByProjectType('projects', 'edit', data.name);
                  })

                this.projectActivityService.getProjectManager(this.projectid).subscribe(p => {
                    this.projectManager = p;
                });
                this.getProjectActivity(this.projectid);
            }

            this.util.getLookupByType(LookupFilter.PRJACT).subscribe(r => this.activities = r);
            this.util.getLookupByType(LookupFilter.PRJACTTYP).subscribe(r => this.activityTypes = r);
            this.requestProjectTask();
            this.loadProjectActivityInvestorsTasks();
            this.isEdit = false;
        });

        this.projectService.getProject(this.projectid).subscribe(
            data => {
                this.projectMarket = data.marketCoverage;
                this.projectStatus = data.projectStatus;
            })

        this.isDirty$.subscribe(data => {
            return of(data);
        });
    }

    requestProjectTask() {
        let apiURL: string = environment.serverUrl + 'lookups/GetProjectTask';
        return this.httpClient.get<ProjectTask[]>(apiURL).subscribe(
            data => this.projecttasks = data
        );
    }

    loadProjectActivityInvestorsTasks() {
        let apiURL: string = environment.serverUrl + 'lookups/project-investors-tasks';
        this.httpClient.get<ProjectTask[]>(apiURL).subscribe(
            data => {
                this.investorsTasks = data;
            });
    }

    getProjectActivity(id: number) {
        this.projectActivityService.getProjectActivittiesByProjectId(id).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data);                
                this.showGrid = data.length > 0;                
            });
    }

    onProjectActivityChange(data) {
        this.projectTeamsSelectionChange(false);
        this.model.activityType = undefined;
        this.filteredprojecttasks = this.projecttasks.filter(a => a.lookUpId === data);
    }

    onProjectTaskChange(data) {
        if (this.investorsTasks.findIndex(x => x.taskId === data) > -1) {
            this.activityTypeEnabled = false;
            this.model.activityType = ProjectActivityConstants.activityTypesValues.activityTypeWholeTeamResponsibilityValue;
        } else {
            this.activityTypeEnabled = true;
            this.model.activityType = undefined;
        }
    }


    onActivityTypeChange(data) {
        if (data === ProjectActivityConstants.activityTypesValues.activityTypeSingleResponsibilityValue) {
            let index = this.projecttasks.findIndex(x => x.taskId == this.model.taskId);
            const isNetsuiteOption = this.projecttasks[index].taskDesc.toLowerCase().includes("netsuite");
            if (isNetsuiteOption) {
                this.util.setActiveDirectoryUser(this.model.responsibilityUser, this.projectManager);
            }
        }
    }

    addProjectActivity(form: NgForm) {
        if (!this.checkActivityStartDate(this.model)) return;

        const projectActivity = this.buildProjectActivity();
        this.projectActivityService.add(projectActivity).subscribe(
            data => {
                this.isEdit = false;
                this.assignToProjectTeamDisabled = false;
                this.getProjectActivity(this.projectid);
                this.alertService.sendSuccess('Project Activity successfully added!');
                this.resetForm(form);
            },
            err => {
                const errorMessage = this.alertService.transformError(err.error);
                this.alertService.sendError(errorMessage);
            }
        );
    }

    buildProjectActivity(): ProjectActivity {
        const projectActivity = new ProjectActivity();
        projectActivity.activityId = this.model.activityId;
        projectActivity.activityName = this.activities.find(a => a.lookUpId === this.model.activityId).fieldLabel;
        projectActivity.taskId = this.model.taskId;
        projectActivity.taskName = this.filteredprojecttasks.find(a => a.taskId === this.model.taskId).taskDesc;
        projectActivity.description = this.model.description;
        projectActivity.startDate = this.model.startDate;
        projectActivity.endDate = this.model.endDate;
        projectActivity.status = this.model.status;
        projectActivity.responsibilityUser = this.model.responsibilityUser;
        projectActivity.projectId = this.projectid;
        projectActivity.activityType = this.model.activityType;
        projectActivity.marketCoverage = this.model.marketCoverage;
        projectActivity.isDefault = this.model.isDefault;

        return projectActivity;
    }

    resetForm(form: NgForm) {
        form.resetForm({ status: 'Not Started', responsibilityUser: {} });
        this.model.projectActivityId = 0;
        this.model.projectId = this.projectid;
        this.model.activityId = 0;
        this.model.taskId = 0;
        this.model.activityType = undefined;
    }

    onDelete(item: ProjectActivity) {
        this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: 'Activity', message: `Activity: ${item.activityName}` }) });
        this.dialogsService.close$.subscribe(res => {
            if (res) {
                this.projectActivityService.delete(item.projectActivityId).subscribe(
                    data => {
                        this.isEdit = false;
                        this.getProjectActivity(this.projectid);
                        this.alertService.sendSuccess('Project Activity Deleted!');
                    }, err => {
                        this.alertService.sendError("Error occured when deleting project activity!");
                    });
            }
        });
    }

    onEdit(item) {
        const dialogRef = this.dialog.open(ProjectActivityEditDialogComponent,
            {
                data:
                {
                    model: { ...item },
                    projectTasks: this.projecttasks,
                    investorsTasks: this.investorsTasks,
                    activityStatuses: this.activityStatuses
                },
                autoFocus: false
            });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.onUpdate(result.data);
            }
        });
    }

    onUpdate(projectActivity) {
        if (!this.checkActivityStartDate(projectActivity)) return;

        this.projectActivityService.add(projectActivity).subscribe(data => {
            this.getProjectActivity(this.projectid);
            this.alertService.sendSuccess('Project Activity has been updated successfully');
        },
            err => {
                const errorMessage = this.alertService.transformError(err.error);
                this.alertService.sendError(errorMessage);
            })
    }

    projectTeamsSelectionChange(checked: boolean) {
        this.model = this.projectActivityService.assignProjectTeamResponsibility(this.model, checked);
    }

    checkActivityStartDate(item: ProjectActivity) {
        if (item.startDate != null && item.endDate != null &&
            new Date(item.startDate) > new Date(item.endDate)) {
            this.alertService.sendError('Activity start date can not be greater than end date!');
            return false;
        }

        return true;
    }

    haveToBeDisabled(task: ProjectTask){  
        if(this.dataSource.data.length == 0 && this.checkIfActivityNameIsUsProxy() && this.projectMarket === this.usMarket){
            return true;
        }
        else if(this.dataSource.data.length > 0){
            return this.checkIfActivityNameIsUsProxy() && ((this.projectMarket === this.usMarket && this.checkIfTaskIsAlreadyAdded(task.taskId))
            || (this.projectMarket === this.usMarket && this.projectStatus === this.preparingProjectStatus));
        }

        return false;
    }

    checkIfTaskIsAlreadyAdded(taskId: number){       
        if(this.dataSource.data.some((x: { taskId: number; taskShouldNotHaveDuplicates: boolean;}) => x.taskId === taskId && x.taskShouldNotHaveDuplicates === true)){
            return true;
        };

        return false;
    }    

    checkIfActivityNameIsUsProxy(){ 
        var usProxy = this.activities.find(a => a.lookUpId === this.model.activityId);

        if(usProxy != undefined){
            return usProxy.fieldLabel === this.usProxyActivityName;
        }

        return false;
    }
}
