import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { DocumentRelatedEntityType } from '@app/enums/DocumentRelatedEntityType';

@Component({
  selector: 'app-investor-landing',
  templateUrl: './investor-landing.component.html',
  styleUrls: ['investor-landing.component.css']
})

export class InvestorLandingComponent implements OnInit {
  index = 0;
  routeSub: any;
  showGeneral;
  showIntel;
  showContact;
  showInvestorDocument;
  showAuditLogs;
  documentRelatedEntityType: DocumentRelatedEntityType = DocumentRelatedEntityType.Investor;
  isExpanded: boolean = true;
  hasActiveChild: boolean = true;

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe((params) => {
      this.index = +params['invid'];
      if (this.index > 0) {
        this.showGeneral = true;
        this.showIntel = true;
        this.showContact = true;
        this.showInvestorDocument = true;
        this.showAuditLogs = true;
      } else {
        this.showGeneral = true;
        this.showIntel = false;
        this.showContact = false;
        this.showInvestorDocument = false;
        this.showAuditLogs = false;
      }

      this.router.navigate([{ outlets: { investoroutlet: ['general', this.index] } }], { skipLocationChange: true, relativeTo: this.route });
    });
  }

  onActivate(event: any) {
    if (event.isChild) {
      this.hasActiveChild = true;
    } else {
      this.hasActiveChild = false;
    }
  }
}