import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClientModule } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private env = environment;
  private activeWebApiHost: string;
  
  constructor() { 
    this.activeWebApiHost = this.env.serverUrl;
  }

  public getActiveWebApiHost():string {
    return this.activeWebApiHost;
  }

}
