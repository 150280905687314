import { ActiveDirectoryUser } from "./ActiveDirectoryUser";

export class ProjectUnmatchedInvestorDto {
  prjInvDataExceptionId: number;
  projectId: number;
  investorId: number;
  investor: string;
  assetManagerId: number;
  fundId: number;
  responsibility: string;
  city: string;
  saveAlternativeName: boolean;
  matchingSuggestions: ProjectInvestorMatchingSuggestion[];
  investorResponsibilityUser: ActiveDirectoryUser;
}

export class ProjectInvestorMatchingSuggestion {
  investorId: number;
  assetManagerId: number;
  fundId: number;
  investor: string;
  assetManager: string;
  fund: string;
  city: string;
  legacyId: string;
}
