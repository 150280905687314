import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {InvestorIntelEngagementDto} from '@app/dto/InvestorIntelDto';
import {InvestorsIntelligenceService} from '@app/services/investors';
import {ActivatedRoute} from '@angular/router';
import {AlertService} from '@app/services/alert.service';
import {Lookup} from '@app/dto/Lookup';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DirtyComponent } from '@app/common/dirty-component';

@Component({
  selector: 'app-investor-intel-engagement',
  templateUrl: 'investor-intel-engagement.component.html',
  styleUrls: ['investor-intel-engagement.component.scss']
})

export class InvestorIntelEngagementComponent implements OnInit, DirtyComponent {
  isChild = true; // it is used in the landing component to determine childs
  investorId: number;
  isEdit = false;
  model: InvestorIntelEngagementDto;
  accessibilities: Lookup[] = [
    {
      lookUpId: 1,
      fieldType: '',
      fieldLabel: 'test1',
      fieldValue: 'test1',
    },
    {
      lookUpId: 2,
      fieldType: '',
      fieldLabel: 'test2',
      fieldValue: 'test2',
    }
  ];
  activisms: Lookup[] = [
    {
      lookUpId: 1,
      fieldType: '',
      fieldLabel: 'test1',
      fieldValue: 'test1',
    },
    {
      lookUpId: 2,
      fieldType: '',
      fieldLabel: 'test2',
      fieldValue: 'test2',
    }
  ];

  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();

  constructor(private investorsIntelligenceService: InvestorsIntelligenceService,
              private alertService: AlertService,
              private route: ActivatedRoute) {
    this.model = new InvestorIntelEngagementDto();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.investorId = +params['id'];
      this.getData();
    });

    this.isDirty$.subscribe(data => {
      return of(data);
    });
  }

  getData() {
    this.investorsIntelligenceService.getEngagement(this.investorId).subscribe(
      data => {
        this.model = data;
      },
      err => {
        this.alertService.sendError(JSON.stringify(err));
      });
  }

  onCancelClick() {
    this.getData();
    this.isEdit = false;
    this.isDirty.next(this.isEdit);
  }

  onSaveBtnClick() {
    this.investorsIntelligenceService.updateEngagement(this.model).subscribe(
      data => {
        this.isEdit = false;
        this.isDirty.next(this.isEdit);
        this.alertService.sendSuccess('Engagement was updated successfully!');
      },
      err => {
        this.alertService.sendError(JSON.stringify(err));
      });
  }

  onEditBtnClick(){
    this.isEdit = true;
    this.isDirty.next(this.isEdit);
  }
}
