import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {
  ProjectCustodians,
  CustodiansList,
  CustodiansPerProjectPost, ProjectCustodianVote
} from '@app/dto/ProjectCustodians';
import {InvestorVote} from '@app/dto/InvestorVote';
import {ProjectCustodialListDto} from '@app/dto/ProjectCustodialListDto';
import {CustodianDashboardDetailDTO} from '@app/dto/CustodianDashboardDetailDTO';

@Injectable({
  providedIn: 'root'
})
export class CustodiansService {
  private _custodianVotesByProject: ProjectCustodians;
  /*
    get(); set();
    can be replaced with new ProjectCustodians()
    as soon as ProjectCustodians and internal classes get their proper contructors
  */
  get custodianVotesByProject(): any {
    return {
      ...this._custodianVotesByProject,
      custodians: this._custodianVotesByProject.custodians.map((custodian) => ({ ...custodian })),
      voteItems: this._custodianVotesByProject.voteItems.map((voteItem) => ({ ...voteItem }))
    };
  }
  set custodianVotesByProject(value: any) {
    this._custodianVotesByProject = value;
  }
  private projectId: number;

  constructor(private http: HttpClient) {
  }

  public getCustodiansVotesByProject(projectId: number, useCaching?: boolean): Observable<ProjectCustodians> {
    if (!useCaching) {
      return this.http.get<ProjectCustodians>(`${environment.serverUrl}custodian/votes/${projectId}`);
    } else {
      return this.getCachedCustodiansVotesByProject(projectId);
    }
  }

  private getCachedCustodiansVotesByProject(projectId: number): Observable<ProjectCustodians> {
    if (this.projectId === projectId && this.custodianVotesByProject) {
      return new Observable((observer) => {
        observer.next(this.custodianVotesByProject);
        observer.complete();
      });
    } else {
      this.projectId = projectId;

      return new Observable((observer) => {
        return this.http.get<ProjectCustodians>(`${environment.serverUrl}custodian/votes/${projectId}`)
        .subscribe((response) => {
          this.custodianVotesByProject = response;

          observer.next(this.custodianVotesByProject);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
      });
    }
  }

  public getCustodialListByProject(projectId: number): Observable<ProjectCustodialListDto[]> {
    return this.http.get<ProjectCustodialListDto[]>(`${environment.serverUrl}projects/custodians/${projectId}`);
  }

  public getAllCustodians(): Observable<CustodiansList[]> {
    return this.http.get<CustodiansList[]>(`${environment.serverUrl}custodian`);
  }

  public saveCustodiansPerProject(data: CustodiansPerProjectPost): Observable<CustodiansPerProjectPost> {
    return this.http.post<CustodiansPerProjectPost>(`${environment.serverUrl}custodian`, data);
  }

  public deleteCustodiansFromProject(projectId: number, custodianId: number, isGlobalCustodian: boolean): Observable<any> {
    return this.http.delete<any>(`${environment.serverUrl}custodian/${custodianId}/${projectId}/${isGlobalCustodian}`);
  }

  public deleteProjectCustodian(projectCustodianId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.serverUrl}projects/custodians/project-custodian/${projectCustodianId}`);
  }

  public updateCustodianVotes(data: ProjectCustodianVote[]): Observable<ProjectCustodianVote[]> {
    return this.http.put<ProjectCustodianVote[]>(`${environment.serverUrl}custodian/votes`, data);
  }

  public deleteCustodianVotes(data: any[]): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: data
    };
    return this.http.delete<any[]>(`${environment.serverUrl}custodian/split-votes`, httpOptions);
  }

  public getCustodianDetails(projectId: number, projectCustodianId: number): Observable<CustodianDashboardDetailDTO> {
    return this.http.get<CustodianDashboardDetailDTO>(`${environment.serverUrl}custodian/dashboard-custodian-detail/${projectId}/${projectCustodianId}`);
  }
}
