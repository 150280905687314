import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GlobalCustodian } from 'src/app/dto/GlobalCustodian';
import { NameValueModel } from 'src/app/models/basic-structures';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { DirtyComponent } from 'src/app/common/dirty-component';
import { RoleGuardService } from 'src/app/auth/role-guard.service';
import { GlobalCustodiansService } from 'src/app/services/admin/global-custodians.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActiveDirectoryUserListComponent } from 'src/app/components/autocompletes/active-directory-user-list/active-directory-user-list.component';
import { FormControl } from '@angular/forms';
import { Lookup } from '@app/dto/Lookup';
import { LookupFilter, Utils } from '@app/helper/utils';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';

@Component({
  selector: 'app-global-custodians-general',
  templateUrl: './global-custodians-general.component.html',
  styleUrls: ['global-custodians-general.component.scss'],
})
export class GlobalCustodiansGeneralComponent implements OnInit, DirtyComponent, AfterContentChecked {
  @ViewChild(ActiveDirectoryUserListComponent) responsibility: ActiveDirectoryUserListComponent;
  @Input()
  isCreateModal: boolean = false;
  model: GlobalCustodian;
  isEdit: boolean = false;
  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();
  hasPermission: boolean = false;
  public initialState;
  public loading: boolean = true;
  websiteControl = new FormControl();
  isDtcCodesFormValid: boolean;

  util = new Utils(this.startupdataproviderService);
  locationTypes: string[];
  brokerVoteTypes: string[];

  @ViewChild('globalcustodianForm') form: any;
  @Output() isFormSaved: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(private alertService: AlertService,
    private roleGuardService: RoleGuardService,
    private gcService: GlobalCustodiansService,
    private route: ActivatedRoute,
    private startupdataproviderService: StartupdataproviderService,
    private cdr: ChangeDetectorRef,
    private router: Router) {
  }

  ngOnInit() {
    this.model = new GlobalCustodian();
    this.initialiseUtils();

    this.initialState = this.copyObject(this.model);
    if (!this.isCreateModal) {
      this.route.parent.params.subscribe((params) => {
        const gcId = +params['id'];
        this.getGlobalCustodianData(gcId);
      });
    } else {
      this.loading = false;
    }
    this.hasPermission = this.roleGuardService.hasAccess('ADMIN', 'EDIT');
    this.isDirty$.subscribe(data => {
      return of(data);
    });

    if (this.isCreateModal && this.hasPermission) {
      this.onEditBtnClick();
    }
  }

  getGlobalCustodianData(gcId: number) {
    this.gcService.get(gcId, false).subscribe(data => {
      this.model = data;
      this.initialState = this.copyObject(this.model);
      this.loading = false;
    },
      err => {
        this.alertService.sendError(err.error.Message);
        this.loading = false;
      });
  }

  countrySelectionChanged(newCountry: NameValueModel) {
    this.model.country = newCountry.name;
  }

  citySelectionChanged(city: NameValueModel) {
    this.model.city = city.name;
  }

  reorgCitySelectionChanged(city: NameValueModel) {
    this.model.reorgCity = city.name;
  }

  onCancelClick() {
    this.getGlobalCustodianData(this.model.globalCustodianId);
    this.isEdit = false;
    this.isDirty.next(this.isEdit);
  }

  onSaveBtnClick() {
    let request = this.isCreateModal ? this.gcService.create(this.model)
      : this.gcService.update(this.model);

    request.subscribe(data => {
      this.model = data;
      this.isFormSaved.emit(true);
      this.alertService.sendSuccess('Global Custodian Created/Updated!');
      if (this.isCreateModal) {
        this.router.navigate(['/admin/gc/' + data.globalCustodianId + '/general'], { relativeTo: this.route })
      }
      else {
        this.isEdit = false;
        this.isDirty.next(this.isEdit);
      }
    },
      e => {
        if ((e.error || {}).InnerMessages) {
          for (let msg of e.error.InnerMessages) {
            this.alertService.sendError(msg);
          }
        } else {
          const errorMessage = this.alertService.transformError(e.error.errors || e.error);
          this.alertService.sendError(errorMessage);
        }
      });
  }

  onEditBtnClick() {
    this.isEdit = true;
    this.isDirty.next(this.isEdit);
  }
  onResetBtnClick() {
    this.model = this.copyObject(this.initialState);
    this.responsibility.resetValue();
  }
  copyObject(obj) {
    // Primitive types only
    return JSON.parse(JSON.stringify(obj));
  }

  initialiseUtils() {
    this.util.getLookupByType(LookupFilter.CUSTLOCTYPE)
      .subscribe((data: Lookup[]) => {
        this.locationTypes = data.map((item: Lookup) => item.fieldLabel);
      });

    this.util.getLookupByType(LookupFilter.BROKERVOTETYPE)
      .subscribe((data: Lookup[]) => {
        this.brokerVoteTypes = data.map((item: Lookup) => item.fieldLabel);
      });
  }

  // this is necessary to fix `ExpressionChangedAfterItHasBeenCheckedError`
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  onDtcCodesFormStatusChange(status: string) {
    this.isDtcCodesFormValid = status === 'VALID';
  }
  onDisableManualBrokerSearch(event){
    this.model.isManualBrokerSearch = event;
  }
}
