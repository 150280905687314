import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-no-items-grid',
  templateUrl: './no-items-grid.component.html',
  styleUrls: ['./no-items-grid.component.scss']
})
export class NoItemsGridComponent implements OnInit {
  @Input() noItemsText: string;

  constructor() { }

  ngOnInit() {
  }
}
