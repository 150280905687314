import {DialogsEnums} from '@app/services/dialogs/dialogs.enums';
import {DialogsConfig} from '@app/services/dialogs/dialogs.config';

export class DialogConfigButton {
  text: string;
  class?: string;
  icon?: string;
}

export class DialogsGeneralInput {
  type: DialogsEnums;
  title: string;
  message: any;
  content?: any;
  config?: DialogsConfig;
}
