import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientExternalAdvisorContactCreateDTO, ClientExternalAdvisorContactDTO } from './ClientExternalAdvisorContactsDTOs';

@Injectable({
  providedIn: 'root'
})
export class ClientExternalAdvisorContactsService {
  constructor(private http: HttpClient) {
  }

  public getClientExternalAdvisorContacts(clientId: number): Observable<ClientExternalAdvisorContactDTO[]> {
    return this.http.get<ClientExternalAdvisorContactDTO[]>(`${environment.serverUrl}clients/client-external-advisor-contacts/${clientId}`);
  }

  public createExternalAdvisorContact(data: ClientExternalAdvisorContactCreateDTO): Observable<boolean> {
    return this.http.post<boolean>(`${environment.serverUrl}clients/add-external-advisor-contact`, data);
  }

  public getExternalAdvisorsByType(typeId: number): Observable<any> {
    return this.http.get<any>(`${environment.serverUrl}clients/external-advisors-by-type/${typeId}`);
  }

  public getExternalAdvisorContacts(advisorId: number): Observable<any> {
    return this.http.get<any>(`${environment.serverUrl}clients/external-advisors-contacts/${advisorId}`);
  }

  public deleteExternalAdvisorContact(id: number) {
    return this.http.delete(`${environment.serverUrl}clients/delete-extrenal-advisor-contact/${id}`);
  }
}

