import {Injectable} from '@angular/core';
import {AppConfigService} from '@app/services/app-config.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ResolutionClassificationItemDTO} from '@app/dto/ResolutionClassificationItemDTO';
import {map, publishReplay, refCount} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClassificationsService {
  private cache$: Observable<ResolutionClassificationItemDTO[]>[] = [];

  constructor(private http: HttpClient, private appConfigService: AppConfigService) {
  }

  public getAll(): Observable<string[]> {
    return this.http.get<string[]>(environment.serverUrl + 'classifications/categories/');
  }

  public addClassification(data: ResolutionClassificationItemDTO): Observable<ResolutionClassificationItemDTO> {
    return this.http.post<ResolutionClassificationItemDTO>(`${environment.serverUrl}classifications/`, data);
  }

  public getClassificationRecord(id: string, useCache: boolean = false): Observable<ResolutionClassificationItemDTO> {
    if (!this.cache$[id] || !useCache) {
      this.cache$[id] = this.http
        .get<ResolutionClassificationItemDTO>(`${environment.serverUrl}classifications/${id}`).pipe(
          map(data => data),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        );
    }
    return this.cache$[id];
  }

  clearCache(id: number | null = null) {
    if (this.cache$ && id) {
      this.cache$[id] = null;
    } else {
      this.cache$ = null;
    }
  }
}
