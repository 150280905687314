import {BehaviorSubject, Observable} from 'rxjs';
import {MatPaginator, MatTableDataSource, PageEvent, Sort} from '@angular/material';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {debounceTime} from 'rxjs/operators';
import {SearchDto, FilterDto, SortDto} from './SearchDto';
import {GridFilterModel} from '@app/components/grid-filter/grid-filter.models';

export class SearchGridService {
  private searchTermChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private searchTerm: string;
  public length: number = 0;
  public pageSize: number = 50;
  public pageIndex: number = 0;
  public additionalData: any = {};
  private flagInitial = true;

  public filterModel: GridFilterModel;
  private additionalFilter: FilterDto | FilterDto[];
  private sortList: SortDto[] = [];
  public anyResults: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public allData: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private paginator: MatPaginator,
              private dataSource: MatTableDataSource<any>,
              private httpClient: HttpClient,
              private getURL: string,
              filter: GridFilterModel = null,
              additionalFilter: FilterDto | FilterDto[] = null,
              customPageSize = null,
              private isMultiColumnSort = false,
              private withInitialRequest = true,
              private sortListInitial = []) {
    this.sortList = sortListInitial;
    if (withInitialRequest) {
      this.flagInitial = false;
    }
    if (customPageSize) {
      this.pageSize = customPageSize;
    }

    this.filterModel = filter;
    this.additionalFilter = additionalFilter;
    this.length = dataSource.data.length;
    this.pageIndex = this.length > 0 && this.pageIndex == 0 ? 1 : this.pageIndex;
    this.searchTermChanges.pipe(debounceTime(500))
      .subscribe((searchTerm) => {
        this.getItemsWithTerm(searchTerm);
      });
  }

  public reloadItems() {
    this.getItems(this.pageIndex, this.pageSize);
  }

  public getItemsWithTerm(searchTerm: string) {
    if (this.flagInitial) {
      this.flagInitial = false;
      return;
    }

    this.searchTerm = searchTerm;
    this.pageIndex = 0;
    this.paginator.firstPage();
    this.getItems(this.pageIndex, this.pageSize);
  }

  public getAllItems(): Observable<any> {
    let searchDto = new SearchDto();
    searchDto.pageIndex = 0;
    searchDto.pageSize = this.length;
    searchDto.searchTerm = this.searchTerm;
    this.addFilters(searchDto);

    let apiURL: string = environment.serverUrl + this.getURL;

    return this.httpClient.post<any>(apiURL, searchDto);
  }

  public onPageEvent(pageEvent: PageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.getItems(pageEvent.pageIndex, pageEvent.pageSize);
  }

  public onFilterChanged(filter: GridFilterModel) {
    this.filterModel.gridFilterItems = filter.gridFilterItems;
    this.pageIndex = 0;
    this.paginator.firstPage();
    this.getItems(this.pageIndex, this.pageSize);
  }

  public onSortChanged(sort: Sort) {
    if (this.isMultiColumnSort) {
      this.updateSortListMultiColumnSort(sort);
    } else {
      this.sortList = [new SortDto({sortPorp: sort.active, sortOrder: sort.direction})];
    }

    this.getItems(this.pageIndex, this.pageSize);
  }

  public unSubscribe() {
    this.searchTermChanges.unsubscribe();
  }

  public doSearch(value) {
    this.searchTermChanges.next(value);
  }

  private updateSortListMultiColumnSort(sort: Sort) {
    const existingSort = this.sortList.find((sortItem, index) => sortItem.sortPorp == sort.active);
    if (existingSort) {
      if (sort.direction == '') {
        const existingSortIndex = this.sortList.indexOf(existingSort);
        this.sortList.splice(existingSortIndex);
      }
      else {
        existingSort.sortOrder = sort.direction;
      }
    }
    else {
      this.sortList.push(new SortDto({sortPorp: sort.active, sortOrder: sort.direction}));
    }
  }

  private getItems(pageIndex: number, pageSize: number) {
    let searchDto = new SearchDto();
    searchDto.pageIndex = pageIndex;
    searchDto.pageSize = pageSize;
    searchDto.searchTerm = this.searchTerm;
    searchDto.sorts = this.sortList;
    this.addFilters(searchDto);

    let apiURL: string = environment.serverUrl + this.getURL;

    this.httpClient.post<any>(apiURL, searchDto).subscribe(
      data => {
        this.length = data.totalResults;
        this.dataSource.data = data.items;
        this.additionalData = data.additionalData;
        this.anyResults.next(data.items.length > 0);
        this.allData.next(data);
      },
      err => console.error(err)
    );
  }

  private addFilters(dto: SearchDto) {
    dto.filters = [];
    if (this.additionalFilter && Array.isArray(this.additionalFilter)) {
      dto.filters = dto.filters.concat(this.additionalFilter);
    } else if (this.additionalFilter) {
      dto.filters.push(this.additionalFilter as FilterDto);
    }

    if (!this.filterModel) {
      return;
    }

    this.filterModel.gridFilterItems.forEach((item, index) => {
      if (item.value && item.value.length > 0) {
        dto.filters.push(
          new FilterDto({filterPorp: item.filterPorp, value: item.value}));
      }
    });
  }
}
