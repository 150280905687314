import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  MatSort, MatTableDataSource, MatPaginator
} from '@angular/material';
import { ExportService } from '@app/service/export.service';
import { SearchGridService } from '@app/service/search-grid/search-grid.service';
import { RoleGuardService } from '@app/auth/role-guard.service';

@Component({
  selector: 'app-investor-list',
  templateUrl: './investor-list.component.html'
})
export class InvestorListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['company', 'category', 'sector', 'coverage','country', 'city', 'responsibility'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource();
  searchService: SearchGridService;
  canAddInvestor: boolean = false;
  canAssignResponsibility: boolean = false;

  constructor(private httpClient: HttpClient, private exportService: ExportService,
    private roleGuardService: RoleGuardService) {
  }

  ngOnInit() {
    this.searchService = new SearchGridService(this.paginator, this.dataSource, this.httpClient, 'investors/investors');
    this.canAddInvestor = this.roleGuardService.hasAccess('INVESTORS', 'CREATE');
    this.canAssignResponsibility = this.roleGuardService.hasAccess('INVESTORS', 'BULK_REASSIGN');
  }

  ngOnDestroy(): void {
    this.searchService.unSubscribe();
  }

  downloadInvestors() {
    this.searchService.getAllItems().subscribe(
      data => {
        this.exportService.export(data.items, 'Investors');
      },
      err => console.error(err)
    );
  }
}
