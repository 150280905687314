import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ResponsibilityDto } from './ResponsibilityDto';
import { ActiveDirectoryUserListComponent } from '../autocompletes/active-directory-user-list/active-directory-user-list.component';
import { AlertService } from '@app/services/alert.service';
import { ActiveDirectoryUser } from '@app/dto/ActiveDirectoryUser';

@Component({
  selector: 'app-assign-projects-to-team-members',
  templateUrl: './assign-projects-to-team-members.component.html',
  styleUrls: ['./assign-projects-to-team-members.component.scss']
})
export class AssignProjectsToTeamMembersComponent implements OnInit {
  @ViewChild(ActiveDirectoryUserListComponent) аctiveDirectoryUserListComponent: ActiveDirectoryUserListComponent;
  @Input() public teamMembers: ResponsibilityDto[] = [];
  @Input() public disabled: boolean;
  @Input() public managerId:string;
  @Input() public assistantManagerId: string;
  @Output() public teamMembersChange: EventEmitter<ResponsibilityDto[]> = new EventEmitter<ResponsibilityDto[]>();

  public selectedUser: ActiveDirectoryUser;

  constructor(private alertService: AlertService) { }

  ngOnInit() {
  }

  addTeamMember(selectedUser){    
    const selectedUserId = selectedUser.id;
    if (!selectedUserId) {
      this.alertService.sendError(`Team member must be provided.`);
      return;
    } else if (this.teamMembers.find(x => x.activeDirectoryId == selectedUserId) ||
                  (selectedUserId === this.managerId || selectedUserId === this.assistantManagerId)) {
      this.alertService.sendError(`Duplicate team member: ${selectedUser.displayName}.`);
      this.аctiveDirectoryUserListComponent.resetValue();
      return;
    }  
    
    const teamMember = new ResponsibilityDto();
    // The UserId is not available from the ActivetDirectoryDto
    teamMember.activeDirectoryId = selectedUser.id;
    teamMember.activeDirectoryDisplayName = selectedUser.displayName;
    teamMember.email = selectedUser.mail;
    teamMember.canDelete = true;
    teamMember.isManual = true;

    this.аctiveDirectoryUserListComponent.resetValue();

    this.teamMembers.push(teamMember);
    this.teamMembersChange.emit(this.teamMembers);
  }

  deleteTeamMember(teamMember){
    const indexOfElement = this.teamMembers.indexOf(teamMember);
    this.teamMembers.splice(indexOfElement, 1);
    this.teamMembersChange.emit(this.teamMembers);
  }
}
