export class Report
{
    reportClassificatonId: number;
    reportName:string;
    userid:number;
    rolename:string;
    coverage:string;

}

export class ReportProject
{
    reportId:number;
    projectId:number;
    projectName:string;
    projectType:string;
    coverage:string;
}

export class ReportRequestParameter
{
    projectId:number;
    investorId:number;
    usageType:string;
    coverage:string
    userid:number;
    rolename:string;
    fileFormat:string;
    reportName:string;
    reportClassificationCode:number;
    rootFilePath:string;
    headerUrl:string;
    companyUrl:string;
}