import {Component, OnInit} from '@angular/core';
import {NavigationTriggerService} from '@app/services/admin/navigation-trigger.service';

@Component({
  selector: 'app-local-custodians-lending',
  templateUrl: './local-custodians-lending.component.html',
  styleUrls: ['./local-custodians-lending.component.scss']
})
export class LocalCustodiansLendingComponent implements OnInit {
  isChild = true;
  constructor(private navigationTriggerService: NavigationTriggerService) {

  }

  ngOnInit() {
    this.navigationTriggerService.setSubMenu(false);
  }
}
