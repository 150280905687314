import { IdName } from './IdName';
import { Project } from './Project';
import { NoborhUploadSource } from './NoborhUploadSource';

export class Campaign {
  public cusip: string;
  public id: number;
  public investorTypeId: number;
  public investorType: IdName;
  public projectId: number;
  public project: any;
  public sourceId: number;
  public noborhUploadSource: NoborhUploadSource;

  constructor(init?: any) {
    Object.assign(this, {
      ...init,
      investorType: new IdName(init.investorType),
      noborhUploadSource: new NoborhUploadSource(init.noborhUploadSource)
    });
  }
}
