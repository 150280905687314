export class ProjectFinancialDetail {
  projectFinancialDetailId: number;
  projectFinancialId?: number;
  projectFinacialId?: number;
  prjInvDataShareClassId?: number;
  projectId: number;
  securityType: string;
  shareClassification: string;
  numberOfShare: number;
  exchangeRatio: number;
  ordinaryEquivalent: number;
  comment: string;
  isin: string;
  sedol: string;
  cusip: string;

  public constructor(init?: Partial<ProjectFinancialDetail>) {
    Object.assign(this, init);
  }
}
