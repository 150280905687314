import { Component, OnInit, Input } from '@angular/core';
import { InvestorValueCountryGridLookupDto, InvestorValueCountryGridItemDto, InvestorIntelGridAddDto, InvestorIntelGridExtensionFieldDefinition, InvestorIntelGridExtensionField } from './investor-value-country-grid-source-dto';
import { InvestorValueCountryGridService } from '@app/services/investors';
import { InvestorValueCountryConfiguration } from './investor-value-country-configuration';
import { NameValueModel } from '@app/models/basic-structures';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import { AlertService } from '@app/services/alert.service';
import { InvValueCountryEditDialogComponent } from './edit-dialogue/inv-value-country-edit-dialogue/inv-value-country-edit-dialog.component';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-investor-value-country-grid',
  templateUrl: './investor-value-country-grid.component.html',
  styleUrls: ['./investor-value-country-grid.component.scss']
})

export class InvestorValueCountryGridComponent implements OnInit {
  @Input()
  configuration: InvestorValueCountryConfiguration = new InvestorValueCountryConfiguration();
  @Input() public disabled = false;
  investorId: number;
  lookups: InvestorValueCountryGridLookupDto[] = [];
  dataSource: InvestorValueCountryGridItemDto[];
  model: InvestorIntelGridAddDto = new InvestorIntelGridAddDto();
  displayedColumns: string[] = [];
  extensionFieldColumns: InvestorIntelGridExtensionFieldDefinition[] = [];
  isCountryRequired: boolean;
  routerLink: string;
  noItemsText: string;
  showGrid:boolean;

  constructor(private service: InvestorValueCountryGridService,
    private dialogsService: DialogsService,
    private alerService: AlertService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.addDisplayedColumns();
    this.getGridData();

    if(this.configuration.isLinked){
      this.disabled = false;
    }
  }

  onAddRecord() {
    if (this.model.relatedEntityId != null) {
      this.service.addRow(this.configuration.apiEndpointPath, this.model).subscribe(data => {
        this.resetModel();
        this.getGridData();
      }, err => {
        this.alerService.sendError(err.error.Message);
      });
    }
  }

  onRelatedEntityChange(id: number) {
    let entity = this.lookups.find(x => x.id === id);
    this.model.relatedEntityId = id;
    this.model.relatedEntityName = entity.name;
    this.model.investorId = this.investorId;

    if(!this.configuration.isLinked) {
      if (entity.country && entity.iso3) {
        this.model.country = entity.country;
        this.model.iso3 = entity.iso3;
      }
    }else{
      this.onExtensionFieldSelectChange('Responsibility', entity.responsibilityUser)
    }

  }

  countrySelectionChanged(newCountry: NameValueModel) {
    this.model.country = newCountry.name;
    this.model.iso3 = newCountry.value;
  }

  onExtensionFieldSelectChange(fieldName: string, value: string) {
    let modelItem = this.model.extensionFields.find(x => x.name.toLowerCase() === fieldName.toLowerCase());
    modelItem.value = value;
  }

  onDeleteBtnClick(element) {
    this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: this.configuration.header, message: element.name }) });
    this.dialogsService.close$.subscribe(res => {
      if (res) {
        this.service.deleteRow(this.configuration.apiEndpointPath, element.id).subscribe(data => {
          this.addDisplayedColumns();
          this.getGridData();
        });
      }
    });
  }

  setExtensionFieldCellValue(element: InvestorValueCountryGridItemDto, fieldName: string) {
    let extField = element.extensionFields.find(x => x.name.toLowerCase() === fieldName.toLowerCase());
    return extField.value;
  }

  getInputExtensionFields(type: string) {
    return this.extensionFieldColumns.filter(x => x.inputType.toLowerCase() === type);
  }

  getExtensionFieldModel(fieldName: string) {
    return this.model.extensionFields.find(i => i.name === fieldName).value;
  }

  onEdit(element: InvestorValueCountryGridItemDto) {
    let entity = this.lookups.find(x => x.name === element.name);
    let editDialogModel = new InvestorIntelGridAddDto();
    editDialogModel.id = element.id;
    editDialogModel.investorId = this.investorId;
    editDialogModel.relatedEntityId = entity.id;
    editDialogModel.relatedEntityName = element.name;
    editDialogModel.iso3 = element.iso3;
    editDialogModel.country = element.country;
    editDialogModel.extensionFields = [...element.extensionFields];
    editDialogModel.participationPolicy = element.participationPolicy;

    let editConfig = {...this.configuration};
    if(!this.configuration.isLinked){
      editConfig.countryEditable = false;
    }

    let dialogRef = this.dialog.open(InvValueCountryEditDialogComponent, {
      data: {
        configuration: editConfig,
        model: editDialogModel,
        extensionFieldColumns: [...this.extensionFieldColumns],
        lookups: [...this.lookups]
      }
    });

    dialogRef.afterClosed().subscribe(r=> {
      this.addDisplayedColumns();
      this.getGridData();
    });
  }

  private getGridData() {
    this.service.getGridData(this.configuration.apiEndpointPath, this.configuration.investorId).subscribe(data => {
      this.investorId = data.investorId;
      this.lookups = data.lookup;
      this.dataSource = data.records;
      this.showGrid = data.records.length > 0;
      this.noItemsText = `${this.configuration.gridHeader}s`;
      if (data.extensionFields && this.configuration.displayExtensionFields) {
        this.extensionFieldColumns = data.extensionFields;
      }

      this.model.extensionFields = [];
      this.extensionFieldColumns.forEach(extField => {
        //tuck in the extension fields before the Actions column
        this.displayedColumns.splice(this.displayedColumns.length - 1, 0, `ext_${extField.name}`);
        let modelItem = new InvestorIntelGridExtensionField();
        modelItem.name = extField.name;
        this.model.extensionFields.push(modelItem);
      });
    });
  }

  onLinkClick(id: number) {
      this.routerLink = this.configuration.linkedUrl.replace('element.id', id.toString()).toString();
  }

  onInvestorCustodianAdded() {
    this.getGridData();
  }

  private resetModel() {
    this.model.relatedEntityId = null;
    this.model.relatedEntityName = null;
    this.model.investorId = this.investorId;
    this.model.iso3 = null;
    this.model.country = null;
    this.model.extensionFields = [];
    this.extensionFieldColumns = [];
    this.addDisplayedColumns();
  }

  private addDisplayedColumns() {
    if (this.configuration.displayParticipationPolicy) {
      this.displayedColumns = ['country', 'entity', 'participationPolicy', 'actions'];
    }else {
      if(this.configuration.isLinked){
        this.displayedColumns = ['dtcCode', 'linkedEntity', 'country', 'actions'];
        return;
      }
      this.displayedColumns = ['entity', 'country', 'actions'];
    }
  }
}
