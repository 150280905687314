export class SearchDto {
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filters: FilterDto[];
    sorts: SortDto[] = [];
}

export class FilterDto {
    filterPorp: string;
    value: string[];

    public constructor(init?:Partial<FilterDto>) {
        Object.assign(this, init);
    }
}

export class SortDto {
    sortPorp: string;
    sortOrder: string;

    public constructor(init?:Partial<SortDto>) {
        Object.assign(this, init);
    }
}