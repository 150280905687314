import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { MatSort, MatSortable, MatTableDataSource } from '@angular/material';
import { VoteWithPercentage, VotingSummaryAdapter } from '@app/dashboard/dashboardau/dashboard-voting-summary/interfaces/voting-summary';
import { ProjectTemplate } from '@app/dto/ProjectTemplate';
import { VotingItemDropdownDto } from '@app/dto/VotingItemDto';
import { DynamicColumnDefinition } from '@app/dashboard/dashboardau/dashboard-custodian-table/dashboard-custodian-table.component';
import { Sort } from '@angular/material/sort';
import { CommonUtilityService } from '@app/utility/common-utility.service';

@Component({
  selector: 'app-voting-summary-table',
  templateUrl: './voting-summary-table.component.html',
  styleUrls: ['./voting-summary-table.component.scss']
})
export class VotingSummaryTableComponent implements OnInit, OnChanges {
  columns = [];
  displayDynamicColumns: DynamicColumnDefinition[] = [];
  staticColumns = ['allShares', 'voted', 'notVoted'];
  dataSource;
  showGrid = false;
  @Input('dataSource') data: VotingSummaryAdapter[];
  @Input() projectId: string;
  @Input() projectType: ProjectTemplate;
  @Input() votingItems: VotingItemDropdownDto[];
  @ViewChild(MatSort) sort: MatSort;
  constructor(private projectTemplateService: ProjectTemplateService,
              private spinnerService: NgxSpinnerService,
              private commonUtilityService: CommonUtilityService) { }

  ngOnInit() {
    this.spinnerService.show('voting-summary');
  }

  setDataSource() {
    const additional = this.projectTemplateService.hasFrequency(this.votingItems)
      ? this.projectTemplateService.getFrequencyVoteTypes(
        false,
        'CUSTODIAL_RECONCILIATION',
        [],
        this.projectTemplateService.isFrequencyWithReverseOrder(this.votingItems))
      : [];
    const votingTypes = this.projectTemplateService.getCustodialReconciliationVotedTypes(this.projectType.template, additional);

    this.setColumns(votingTypes);
    this.setTableRows(votingTypes);
  }

  setColumns(votingTypes) {
    this.columns = [];
    this.displayDynamicColumns = [];
    if (this.projectType.template === this.projectTemplateService.SHAREHOLDER_MEETING_TEMPLATE_NAME) {
      this.columns.push('resolution');
    } else {
      this.columns.push('company');
      this.columns.push('bid');
    }
    this.columns = [...this.columns, ...this.staticColumns];

    votingTypes.forEach(votingType => {
      const coldef = new DynamicColumnDefinition();
      coldef.colName = `${votingType.fieldValue}`;
      coldef.colHeaderName = votingType.fieldLabel;
      coldef.colHeaderColor = votingType.color;

      this.columns.push(`${votingType.fieldValue}`);
      this.displayDynamicColumns.push(coldef);
    });
  }

  setTableRows(votingTypes) {
    const tableRows = this.data.map(row => {
      if (row.votingItemChild) {
        const letter = this.commonUtilityService.indexToLetter(row.votingItemChild.orderId, false);
        row.templateProperty1 = letter;
        row.templateProperty2 = row.votingItemChild.name;
      }
      row.voted = new VoteWithPercentage(row.voted, row.allShares);
      row.notVoted = new VoteWithPercentage(row.notVoted, row.allShares);
      votingTypes.forEach(item => {
        const result = row.votes.find(vote => vote.voteType === item.fieldValue);
        row[`${item.fieldValue}`] = new VoteWithPercentage(result, row.allShares);
      });
      return row;
    });

    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.dataSource.data = tableRows;
  }

  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'allShares': return compare(a.allShares, b.allShares, isAsc);
        default: return compare(a[sort.active].votes, b[sort.active].votes, isAsc);
      }
    });
  }

  isDirectorException(votingItemId) {
    return this.data.findIndex(row => row.votingItemChild && row.votingItemChild.parentVotingItemId === votingItemId) > -1;
  }

  ngOnChanges(changes: any) {
    if (
      this.votingItems !== undefined &&
      this.projectType !== undefined &&
      this.data !== undefined && this.data.length
    ) {
      this.showGrid = true;
      this.setDataSource();
    }
    if (this.data) {
      this.spinnerService.hide('voting-summary');
    }
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
