import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CalllogFeedback } from '@app/dto/CalllogFeedback';
import { CallLog } from '@app/dto/CallLog';
import { Project } from '@app/dto/Project';
import { InvestorVote, SaveVotesDTO } from '@app/dto/InvestorVote';
import { CampaignDashboardDto } from '@app/dto/DashboardProjectInvestorAU';
import { ValidationHelper } from '@app/helper/validationHelper';
import { ProjectCustodianShareClassificationInsertModel } from '@app/dashboard/dashboardau/dashboard-custodian-table/custodian-details/voting-reconciliation/add-voting-reconciliation-dialog/DTOs/ProjectCustodianShareClassificationInsertModel';
import { ProjectMailingDocumentDTO } from '@app/dto/ProjectMailingDocumentDTO';
import { ProjectMailingDocumentOrderReceiveDTO } from '@app/dto/ProjectMailingDocumentOrderReceiveDTO';
import { ProjectMailingDocumentCopyDetailedDTO } from '@app/dto/ProjectMailingDocumentCopyDetailedDTO';
import { ProjectMailingDocRecipientDetailedDTO } from '@app/dto/ProjectMailingDocRecipientDetailedDTO';
import { ProjectMailingDocRecipientSentDTO } from '@app/dto/ProjectMailingDocRecipientSentDTO';
import { IProjectMailingDeliveryLabelInput, ProjectMailingIndividualDeliveryTicket } from '@app/dto/ProjectMailingDeliveryLabelDTO';
import { OutNOBORNEmailMaterialStatus } from '@app/dto/NOBORHSummary';
import { OciSetUpDto } from '@app/dto/OciSetUpDto';
import { SearchDto } from '@app/service/search-grid/SearchDto';
import { ProjectInvestorIntentionVoteDTO } from '@app/dto/ProjectInvestorIntentionVoteDTO';
import { ListAdapter } from '@app/dto/base/list.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  validationHelper = new ValidationHelper();
  constructor(private http: HttpClient) {
  }

  public getCallLogsAndContactsPerProjectInvestorCoverage(projectId: string, investorId: string, coverage: string): Observable<any> {
    return this.http
      .get<CalllogFeedback>(`${environment.serverUrl}dashboards/call-logs-and-contacts/${projectId}/${investorId}`);
  }

  public getAllProjects(): Observable<Project[]> {
    const url = `${environment.serverUrl}dashboards/projects`;

    return this.http.get<Project[]>(url);
  }

  public saveCallFeedback(model: CallLog): Observable<any> {
    // The date fields need to be reinstantiated
    // as if they have not been edited they come back as strings.
    if (this.validationHelper.isValidDate(model.followup)) {
      model.followup = new Date(model.followup);

      if (model.followupTime) {
        model.followupTime = new Date(model.followupTime);
        model.followupTimePicked = true;
        model.followup.setHours(model.followupTime.getHours());
        model.followup.setMinutes(model.followupTime.getMinutes());
      } else {
        model.followupTimePicked = false;
        model.followup.setHours(0);
        model.followup.setMinutes(0);
      }
    } else {
      model.followupTimePicked = false;
    }

    return this.http.post<CallLog>(`${environment.serverUrl}dashboards/feedback`, model);
  }

  public async deleteCallFeedback(model: CallLog): Promise<any> {
    return this.http.delete(`${environment.serverUrl}dashboards/feedback/${model.callLogId}/${model.userId || 0}`).toPromise();
  }

  public completeCallLog(projectId: number, callLogId: number): Observable<any> {
    return this.http.post(`${environment.serverUrl}dashboards/${projectId}/call-logs/complete/${callLogId}`, null);
  }

  public getDashboardVotes(projectId: number, investorId: number, shareClassification: string): Observable<any> {
    return this.http
      .get<InvestorVote[]>(`${environment.serverUrl}dashboards/investor-votes/${projectId}/${investorId}/${shareClassification}`);
  }

  public getCampaignDashboard(coverage: string, projectId: number, shareClassification: string) : Observable<any> {
    return this.http.get<CampaignDashboardDto>(`${environment.serverUrl}dashboards/campaign-dashboard/${coverage}/${projectId}/${shareClassification}`);
  }

  public updateDashboardVotes(saveVotesDTO: SaveVotesDTO): Observable<any> {
    return this.http.post<SaveVotesDTO>(`${environment.serverUrl}dashboards/investor-votes`, saveVotesDTO);
  }

  public getProjectCustodiansVotes(filter): Observable<any> {
    return this.http.post<SaveVotesDTO>(`${environment.serverUrl}dashboards/project-custodians-votes`, filter);
  }

  public getProjectCustodiansVotesById(projectCustodianId: number): Observable<ProjectCustodianShareClassificationInsertModel[]> {
    return this.http.get<ProjectCustodianShareClassificationInsertModel[]>(`${environment.serverUrl}dashboards/project-custodians-insert-votes/${projectCustodianId}`);
  }

  public addProjectCustodiansVotes(projectCustodianVotes: ProjectCustodianShareClassificationInsertModel[]): Observable<number> {
    return this.http.post<number>(`${environment.serverUrl}dashboards/project-custodians-insert-votes/`, projectCustodianVotes);
  }

  public getMailingDocumentsSet(projectId: number): Observable<ProjectMailingDocumentDTO[]>{
    return this.http.get<ProjectMailingDocumentDTO[]>(`${environment.serverUrl}dashboardsmailing/mailing-document-set/all/${projectId}`);
  }

  public addMailingDocuments(document: ProjectMailingDocumentDTO): Observable<any>{
    return this.http.post<ProjectMailingDocumentDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-set`, document);
  }

  public editMailingDocuments(document: ProjectMailingDocumentDTO): Observable<any>{
    return this.http.put<ProjectMailingDocumentDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-set/edit`, document);
  }
  public addCopiesToMailingDocument(document: ProjectMailingDocumentOrderReceiveDTO): Observable<any> {
    return this.http.post<ProjectMailingDocumentOrderReceiveDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-copy/add`, document);
  }

  public deleteMailingDocuments(document: ProjectMailingDocumentDTO): Observable<any> {
    return this.http.delete(`${environment.serverUrl}dashboardsmailing/mailing-document-set/${document.mailingDocumentId}`);
  }

  public getMailingEventsHistory(documentId: number): Observable<ProjectMailingDocumentDTO[]> {
    return this.http.get<ProjectMailingDocumentDTO[]>(`${environment.serverUrl}dashboardsmailing/mailing-document-copy/all/${documentId}`);
  }

  public getMailingEvent(mailingDocumentCopyId: number): Observable<ProjectMailingDocumentCopyDetailedDTO> {
    return this.http.get<ProjectMailingDocumentCopyDetailedDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-copy/${mailingDocumentCopyId}`);
  }

  public updateMailingEvent(event: ProjectMailingDocumentCopyDetailedDTO): Observable<ProjectMailingDocumentCopyDetailedDTO> {
    return this.http.put<ProjectMailingDocumentCopyDetailedDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-copy/edit`, event);
  }

  public deleteMailingEvent(id: number): Observable<any> {
    return this.http.delete(`${environment.serverUrl}dashboardsmailing/mailing-document-copy/delete/${id}`);
  }

  public getNoboVotingHistory(noboInvestorId): Observable<any>{
    return this.http.get<any>(`${environment.serverUrl}noborh/noborh-history/${noboInvestorId}`)
  }

  public setNoboHistoryStatus(data: OutNOBORNEmailMaterialStatus): Observable<any>{
    return this.http.put<OutNOBORNEmailMaterialStatus>(`${environment.serverUrl}noborh/noborh-history/edit`, data);
  }

  public addMailingDocumentRecipient(recipient: ProjectMailingDocRecipientDetailedDTO): Observable<any>{
    return this.http.post<ProjectMailingDocRecipientDetailedDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient`, recipient);
  }

  public editMailingDocumentRecipient(recipient: ProjectMailingDocRecipientDetailedDTO): Observable<any>{
    return this.http.put<ProjectMailingDocRecipientDetailedDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient/edit`, recipient);
  }

  public addSentToMailingDocumentRecipient(recipient: ProjectMailingDocRecipientSentDTO): Observable<any> {
    return this.http.post<ProjectMailingDocRecipientSentDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient/shipment`, recipient);
  }

  public getDeliveryLabelData(projectId: number): Observable<IProjectMailingDeliveryLabelInput>{
    return this.http.get<IProjectMailingDeliveryLabelInput>(`${environment.serverUrl}dashboardsmailing/mailing-document-label-print/${projectId}`);
  }

  public getCusips(mailingDocRecId: number, projectId: number, isEditMode: boolean){
    return this.http.get<any[]>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient-set-cusip/${mailingDocRecId}/${projectId}/${isEditMode}`);
  }

  public getOciExportOptions(projectId: number) : Observable<any> {
    return this.http.get<any[]>(`${environment.serverUrl}noborh/oci-setup-options/${projectId}`);
  }

  public printClientReport(data: ProjectMailingIndividualDeliveryTicket[]): Observable<any>{
    return this.http.post(`${environment.serverUrl}dashboardsmailing/client-pdf-report`, data, {
      responseType: "blob",
      observe:'response'
    });
  }

  public ociSetUp(model: OciSetUpDto): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}noborh/oci-setup`, model);
  }

  public ociSetUpNumberOfInvestors(model: OciSetUpDto): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}noborh/oci-setup/number-of-investors`, model);
  }

  public downloadProxyVotingList(searchDto:SearchDto): Observable<any>{
    let url = environment.serverUrl + 'noborh/proxy-voting-list';
    return this.http.post(url, searchDto, { responseType: 'blob', observe:'response' });
  }

  deleteMailingRecipient(mailingDocumentRecipientId: number): Observable<any> {
    return this.http.delete<any>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient/delete/${mailingDocumentRecipientId}`);
  }

  public getMailingRecepientEventsHistory(recipientId: number): Observable<ProjectMailingDocRecipientSentDTO[]> {
    return this.http.get<ProjectMailingDocRecipientSentDTO[]>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient/all/${recipientId}`);
  }

  public getMailingRecepientEventDetails(eventId: number, projectId: number): Observable<ProjectMailingDocRecipientSentDTO> {
    return this.http.get<ProjectMailingDocRecipientSentDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient-details/${eventId}/${projectId}`);
  }

  public updateMailingRecepientEventDetails(historyEvent: ProjectMailingDocRecipientSentDTO): Observable<ProjectMailingDocRecipientSentDTO> {
    return this.http.put<ProjectMailingDocRecipientSentDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient-details/edit`, historyEvent);
  }

  public exportAllShipments(projectId: number): Promise<Blob> {
    let getfileheaders = new HttpHeaders().set('Accept', 'application/csv');
    return this.http.get(`${environment.serverUrl}dashboardsmailing/mailing-document-export-shipments/${projectId}`, { responseType: 'blob', headers: getfileheaders }).toPromise();
  }

  public deleteShipment(id: number): Observable<any> {
    return this.http.delete(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient/shipment/delete/${id}`);
  }

  public getInvestorVotingIntentions(projectId: number, investorId: number): Observable<ProjectInvestorIntentionVoteDTO>{
    return this.http.get<ProjectInvestorIntentionVoteDTO>(`${environment.serverUrl}dashboards/voting-intentions/${projectId}/${investorId}`);
  }

  public editInvestorVotingIntentions(votingIntentions: ProjectInvestorIntentionVoteDTO): Observable<ProjectInvestorIntentionVoteDTO>{
    return this.http.put<ProjectInvestorIntentionVoteDTO>(`${environment.serverUrl}dashboards/voting-intentions/edit`, votingIntentions);
  }

  public getSourceList(): Observable<ListAdapter[]> {
    return this.http.get<ListAdapter[]>(`${environment.serverUrl}dashboards/campaign-dashboard/au-file-source-options`)
      .pipe(map(data => data.map(item => new ListAdapter(item))));
  }
  
  public investorVotesFileUpload(projectId: number, fileName: string, auSourceFileOptionId: number, file: any): Observable<any> {
    let formData = new FormData();
    formData.append('projectId', projectId.toString());
    formData.append('AuSourceFileOptionId', auSourceFileOptionId.toString());
    formData.append('fileName', fileName);
    formData.append('file', file, fileName);

    return this.http.post(environment.serverUrl + 'dashboards/campaign-dashboard/upload-file-votes', formData);
  }
}
