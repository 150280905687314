import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient,HttpBackend, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Report } from '../../dto/report/au/Report';
import { environment } from '../../../environments/environment';

import {
    MatTableModule,
    MatSortModule, MatPaginatorModule,
    MatSort, MatTableDataSource} from '@angular/material';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-rpt-list',
    templateUrl: './rpt-list.component.html',
    styleUrls: ['rpt-list.component.css']
})

export class RptListComponent implements OnInit, OnDestroy {
    reports: Report[] = [];
    displayedColumns: string[] = ['reportName'];
    @ViewChild(MatSort) sort: MatSort;
    dataSource;
    routeSub;
    userid:number;
    rolename:string;
    coverage:string;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private httpClient: HttpClient,handler: HttpBackend,
        private userService: UserService) {
            this.httpClient = new HttpClient(handler);
        }
    ngOnInit() {
        this.userid = 0;
        this.rolename = this.userService.getUserRoleName();
        this.coverage = this.userService.getUserCoverage();
        this.routeSub = this.route.params.subscribe((params) => {
            this.getReports(this.userid,this.rolename,this.coverage);
        });
    }
    getReports(userid:number,rolename:string,coverage:string) {
        
        let rpt1 : Report = new Report();
        rpt1.reportClassificatonId=1;
        rpt1.reportName = "Project Summary Report";
        rpt1.coverage = this.coverage;
        rpt1.rolename = this.rolename;
        rpt1.userid = +this.userid;
        

        let rpt2 : Report = new Report();
        rpt2.reportClassificatonId=2;
        rpt2.reportName = "Project Call Logs";
        rpt2.coverage = this.coverage;
        rpt2.rolename = this.rolename;
        rpt2.userid = +this.userid;

        this.reports.push(rpt1);
        this.reports.push(rpt2);
        this.dataSource = new MatTableDataSource<Report[]>();  
        this.dataSource.sort = this.sort;
        
        this.dataSource.data = this.reports; 

        /*
        let apiURL: string = environment.serverUrl + 'projects/GetProjectsByCoverage/'+userid+'/'+rolename+'/'+coverage;
        this.httpClient.get<Project[]>(apiURL).subscribe(
           data => {
            this.dataSource = new MatTableDataSource<Project[]>();  
            this.dataSource.sort = this.sort;
            //this.projects = data; 
            this.dataSource.data = data; 
          },
           err => console.error(err)
           );
        */
    }


    ngOnDestroy() {
    }

}