import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableWrapperComponent } from '@app/components/table-filter-wrapper/containers';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatDatepickerModule,
  MatMenuModule,
  MatPaginatorModule
} from '@angular/material';
import { ObserversModule } from '@angular/cdk/observers';
import { TableFilterDirective } from './directives/table-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FilterFormComponent,
  MultiselectFilterFormComponent,
  NumberRangeFilterFormComponent,
  TextFilterFormComponent
} from '@app/components/table-filter-wrapper/components';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DateRangeFilterFormComponent } from '@app/components/table-filter-wrapper/components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatPaginatorModule,
    MatIconModule,
    MatDatepickerModule,
    ObserversModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatCheckboxModule,
    CurrencyMaskModule
  ],
  exports: [
    TableWrapperComponent,
    TableFilterDirective,
  ],
  declarations: [
    TableWrapperComponent,
    TableFilterDirective,
    TextFilterFormComponent,
    MultiselectFilterFormComponent,
    FilterFormComponent,
    NumberRangeFilterFormComponent,
    DateRangeFilterFormComponent
  ],
  entryComponents: [
    TextFilterFormComponent,
    MultiselectFilterFormComponent,
    NumberRangeFilterFormComponent,
    DateRangeFilterFormComponent
  ]
})
export class TableFilterWrapperModule {
}
