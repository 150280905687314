import {Component, OnInit} from '@angular/core';
import {NavigationTriggerService} from '@app/services/admin/navigation-trigger.service';

@Component({
    selector: 'app-initiative-landing',
    templateUrl: './initiative-landing.component.html'
})

export class InitiativeLandingComponent implements OnInit{
    constructor(private navigationTriggerService: NavigationTriggerService) {
    }

    ngOnInit() {
        this.navigationTriggerService.setSubMenu(false);
      }
}
