export class DashboardAuCompaignSummary {
  name: string|null;
  projectId: number;
  itemId: number;
  displayField1: string;
  displayField2: string;
  forIntentionShares: string;
  forIntentionIsc: string;
  forVotedShares: string;
  forVotedIsc: string;
  againstIntentionShares: string;
  againstIntentionIsc: string;
  againstVotedShares: string;
  againstVotedIsc: string;
  abstainIntentionShares: string;
  abstainIntentionIsc: string;
  abstainVotedShares: string;
  abstainVotedIsc: string;
  undisclosedIntentionShares: string;
  undisclosedIntentionIsc: string;
  undisclosedVotedShares: string;
  undisclosedVotedIsc: string;
  undecidedIntentionShares: string;
  undecidedIntentionIsc: string;
  undecidedVotedShares: string;
  undecidedVotedIsc: string;
  notvotingIntentionShares: string;
  notvotingIntentionIsc: string;
  notvotingVotedShares: string;
  notvotingVotedIsc: string;
  totalsIntentionShares: string;
  totalsIntentionIsc: string;
  totalsVotedShares: string;
  totalsVotedIsc: string;
  acceptIntentionShares: string|null;
  acceptIntentionIsc: string|null;
  acceptVotedShares: string|null;
  acceptVotedIsc: string|null;
  declineIntentionShares: string|null;
  declineIntentionIsc: string|null;
  declineVotedShares: string|null;
  declineVotedIsc: string|null;

  constructor(init: any = {}) {
    Object.assign(this, init);
  }
}
