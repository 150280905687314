import { Component, OnInit, OnDestroy } from '@angular/core';
import { InvestorValueCountryConfiguration } from '../investor-value-country-grid/investor-value-country-configuration';
import { ActivatedRoute, Router } from '@angular/router';
import { InvestorsService, InvestorsIntelligenceService } from '@app/services/investors';
import { AlertService } from '@app/services/alert.service';
import { InvestorsVotingProcess, InvestorValueSelectGroupModel } from '@app/dto/InvestorsVotingProcess';
import { DirtyComponent } from '@app/common/dirty-component';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Custodian } from '@app/investor/investordetail/investor-voting-process/custodian-table/custodian-table.component';
import {
  AddInvestorCustodianDlgComponent
} from './add-investor-custodian/add-investor-custodian-dlg/add-investor-custodian-dlg.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';

@Component({
  selector: 'app-investor-voting-process',
  templateUrl: './investor-voting-process.component.html',
  styleUrls: ['./investor-voting-process.component.scss']
})
export class InvestorVotingProcessComponent implements OnInit, DirtyComponent, OnDestroy {
  isEdit = false;
  votingPlatformModel: InvestorValueSelectGroupModel;
  decisionMakerModel: InvestorValueSelectGroupModel;
  investorId: number;
  routeSub: any;
  isChild = true; // it is used in the landing component to determine childs
  model: InvestorsVotingProcess = new InvestorsVotingProcess();

  public isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();
  public localCutodians: Custodian[] = [];
  public globalCustodians: Custodian[] = [];
  private unsubscribe: Subject<void> = new Subject<void>();
  private custodianDialog: MatDialogRef<AddInvestorCustodianDlgComponent>;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private investorsService: InvestorsService,
              private dialogsService: DialogsService,
              private investorsIntelligenceService: InvestorsIntelligenceService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe((params) => {
      this.investorId = +params['id'];
      this.buildVotingProcessConfigurations();
      this.getCustodians(this.investorId);
    });

    this.isDirty$.subscribe(data => {
      return of(data);
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onCancelClick() {
    this.isEdit = false;
    this.isDirty.next(this.isEdit);
    this.buildVotingProcessConfigurations();
  }

  onSaveBtnClick() {
    this.model.decisionMakers = this.decisionMakerModel.selectedRecords || [];
    this.model.votingPlatforms = this.votingPlatformModel.selectedRecords || [];
    this.isEdit = false;

    this.investorsService.updateVotingProcess(this.model).subscribe(
      data => {
        this.buildVotingProcessConfigurations();
        this.isDirty.next(this.isEdit);
        this.alertService.sendSuccess('Record was updated successfully!');
      },
        err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        });
  }

  onEditBtnClick() {
    this.isEdit = true;
    this.isDirty.next(this.isEdit);
  }

  public onLocalCustodianDelete(custodian: Custodian): void {
    this.dialogsService.confirm(null, null, {
      template: DeleteProjectTemplate({ title: 'Local Custodian', message: custodian.name })
    });

    this.dialogsService.close$.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((confirm: boolean) => {
      if (confirm) {
        this.investorsIntelligenceService.deleteInvestorLocalCustodian(custodian.id).pipe(
          takeUntil(this.unsubscribe)
        ).subscribe(() => {
          this.localCutodians = this.localCutodians.filter(x => x.id !== custodian.id);

          this.alertService.sendSuccess(`${custodian.name} has been removed from the investor`);
        });
      }
    });
  }

  public onGlobalCustodianDelete(custodian: Custodian): void {
    this.dialogsService.confirm(null, null, {
      template: DeleteProjectTemplate({ title: 'Global Custodian', message: custodian.name })
    });

    this.dialogsService.close$.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((confirm: boolean) => {
      if (confirm) {
        this.investorsIntelligenceService.deleteInvestorGlobalCustodian(custodian.id).pipe(
          takeUntil(this.unsubscribe)
        ).subscribe(() => {
          this.globalCustodians = this.globalCustodians.filter(x => x.id !== custodian.id);

          this.alertService.sendSuccess(`${custodian.name} has been removed from the investor`);
        });
      }
    });
  }

  public onLocalCustodianEdit(custodian: Custodian): void {
    const isGlobalCustodian = false;

    this.openCustodianDialog(custodian, 'Edit Local Custodian', true, isGlobalCustodian);
  }

  public onGlobalCustodianEdit(custodian: Custodian): void {
    this.openCustodianDialog(custodian, 'Edit Global Custodian', true);
  }

  public onGlobalCustodianAdd(): void {
    this.openCustodianDialog(new Custodian(), 'Add Global Custodian', false);
  }

  public onLocalCustodianAdd(): void {
    const isGlobalCustodian = false;

    this.openCustodianDialog(new Custodian(), 'Add Local Custodian', false, isGlobalCustodian);
  }

  private getCustodians(investorId: number): void {
    this.getLocalCustodians(investorId);
    this.getGlobalCustodians(investorId);
  }

  private getLocalCustodians(investorId: number): void {
    this.investorsIntelligenceService.getInvestorLocalCustodians(investorId)
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((response) => {
        this.localCutodians = response.records.map((x) => new Custodian(x));
      });
  }

  private getGlobalCustodians(investorId: number): void {
    this.investorsIntelligenceService.getInvestorGlobalCustodians(investorId)
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((response) => {
        this.globalCustodians = response.records.map((x) => new Custodian(x));
      });
  }

  private openCustodianDialog(investorCustodian: Custodian, title: string, isEdit: boolean, isGlobalCustodian = true) {
    this.custodianDialog = this.dialog.open(
      AddInvestorCustodianDlgComponent,
      {
        width: '600px',
        data: {
          isGlobalCustodian,
          title,
          investorCustodian,
          isEdit,
          investorId: this.investorId
        }
      });

      this.custodianDialog.afterClosed().pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((formData) => {
        if (formData) {
          this.upsertCustodian(formData, isGlobalCustodian, isEdit);
        }
      });
  }

  private upsertCustodian(formData, isGlobalCustodian = true, formEdit): void {
    if (isGlobalCustodian) {
      this.investorsIntelligenceService.upsertGlobalCustodian(formData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.getGlobalCustodians(this.investorId);

          if (formEdit) {
            this.alertService.sendSuccess('Global custodian has been edited successfully');
          } else {
            this.alertService.sendSuccess('Global custodian has been added to the investor');
          }
        });
    } else {
      this.investorsIntelligenceService.upsertLocalCustodian(formData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.getLocalCustodians(this.investorId);

          if (formEdit) {
            this.alertService.sendSuccess('Local custodian has been edited successfully');
          } else {
            this.alertService.sendSuccess('Local custodian has been added to the investor');
          }
        });
      }
  }

  private buildVotingProcessConfigurations() {
    this.investorsService.getVotingProcess(this.investorId).subscribe(
      data => {
        this.model = data;
        this.votingPlatformModel = new InvestorValueSelectGroupModel();
        this.votingPlatformModel.header = 'Voting Platform';
        this.votingPlatformModel.noRecordsText = 'There are still not voting platform selected';
        this.votingPlatformModel.noRecordsLink = 'Edit the voting platform...';
        this.votingPlatformModel.allRecords = this.model.votingPlatformLookup;
        this.votingPlatformModel.selectedRecords = this.model.votingPlatforms;

        this.decisionMakerModel = new InvestorValueSelectGroupModel();
        this.decisionMakerModel.header = 'Decision Makers';
        this.decisionMakerModel.noRecordsText = 'There are still not decision makers selected';
        this.decisionMakerModel.noRecordsLink = 'Edit the decision makers...';
        this.decisionMakerModel.allRecords = this.model.decisionMakersLookup;
        this.decisionMakerModel.selectedRecords = this.model.decisionMakers;
      },
      err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      }
    );
  }
}
