export class GridFilterModel {
    public buttonLabel:string;
    public gridFilterItems: GridFilterItem[];

    public constructor(init?:Partial<GridFilterModel>) {
        Object.assign(this, init);
    }
}

export class GridFilterItem {
    public filterPorp: string;
    public filterName: string;
    public filterType: FilterType = FilterType.Selection;
    public filterOptions: FilterOption[];
    public value: string[];

    public constructor(init?:Partial<GridFilterItem>) {
        Object.assign(this, init);
    }
}

export class FilterOption {
    public label: string;
    public value: any;

    public constructor(init?:Partial<FilterOption>) {
        Object.assign(this, init);
    }
}

export enum FilterType {
    Selection = 'Selection',
    AutoCompleteUser = 'AutoCompleteUser',
    AutoCompleteCountry = 'AutoCompleteCountry'
}