import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {UserService} from '@app/services/user.service';
import {SearchGridService} from '@app/service/search-grid/search-grid.service';
import {FilterDto} from '@app/service/search-grid/SearchDto';

@Component({
    selector: 'app-clientdetail-projecthistory',
    templateUrl: './clientdetail-projecthistory.component.html',
    styleUrls: ['./clientdetail-projecthistory.component.scss']
  })

  export class ClientDetailProjectHistoryComponent implements OnInit {
    routeSub: any;
    clientid: number;
    dataSource = new MatTableDataSource();
    displayedColumns: string[] = [
    'projectName',
    'projectType',
    'projectYear',
    'projectStartDate',
    'projectEndDate',
    'projectStatus',
    'pmId'
    ];

    userid: number;
    rolename:string;
    coverage:string;

    searchService: SearchGridService;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
      private route: ActivatedRoute,
      private userService: UserService,
      private httpClient: HttpClient,
    )
    {

    }
    ngOnInit() {
      this.userid = 0;
      this.rolename = this.userService.getUserRoleName();
      this.coverage = this.userService.getUserCoverage();

      this.routeSub = this.route.parent.params.subscribe((params) => {
        this.clientid = +params['id'];

        if (this.clientid > 0) {
          this.searchService = new SearchGridService(
            this.paginator,
            this.dataSource,
            this.httpClient,
             'clients/client-projects-by-id',
             null,
             new FilterDto({ filterPorp: "clientId", value: [this.clientid.toString()] }),
             20);
        }
      });
    }
  }
