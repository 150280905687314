import {DialogGeneralTemplateConfig} from '@app/services/dialogs/dialog-general-template.config';

export function DeleteProjectTemplate(_params = {}) {
  const params = {...{title: 'Project', message: ''}, ..._params};

  return new DialogGeneralTemplateConfig({
    title: 'Delete ${title}',
    message: 'Are you sure you want to delete <br /><strong>"${message}"</strong>?',
    config: {
      okButton: {
        text: 'DELETE',
        class: 'ms-btn-red-delete',
        icon: 'ms-delete-red-icon'
      },
      cancelButton: {
        text: 'CANCEL'
      }
    }
  }, params);
}
