import { ClassificationOptionSourceTypeEnum } from "@app/enums/ClassificationOptionSourceTypeEnum";

export class ResolutionClassificationCustomFieldDto{
    id?: number;
    issClassificationId: number;
    fieldName: string;
    fieldTypeId: number;
    value: number;
    optionSourceTypeId: number = ClassificationOptionSourceTypeEnum.Custom;
    options: CustomFieldOptionDto[];
    values: number[];

    public constructor(init?:Partial<ResolutionClassificationCustomFieldDto>) {
        Object.assign(this, init);
    }
}

export class CustomFieldOptionDto {
    id?: number;
    display: string;
    active: boolean;
    canBeDeleted: boolean;

    public constructor(init?:Partial<CustomFieldOptionDto>) {
        Object.assign(this, init);
    }
}