import { CityGroup } from '../dto/CityGroup';
import { CountryGroup } from '../dto/CountryGroup';
import { Region } from '../dto/Region';
import { Lookup } from '../dto/LookUp';
import { SubRegion } from '../dto/SubRegion';
import { StartupdataproviderService } from '../service/startupdataprovider.service';
import { NameValueModel } from '../models/basic-structures';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActiveDirectoryUser } from '@app/dto/ActiveDirectoryUser';

export enum LookupFilter {
    APPINTARY = 'APPINTARY',
    CONAOR = 'CONAOR',
    CONRATING = 'CONRATING',
    CONTACTITLE = 'CONTACTITLE',
    CONWTC = 'CONWTC',
    CWOSCOMP = 'CWOSCOMP',
    CWOSCURR = 'CWOSCURR',
    CWOSMAND = 'CWOSMAND',
    INVCAT = 'INVCAT',
    INVDIS = 'INVDIS',
    INVDMK = 'INVDMK',
    INVENG = 'INVENG',
    INVLOD = 'INVLOD',
    INVTYP = 'INVTYP',
    MARKET = 'MARKET',
    MTXRESCAT = 'MTXRESCAT',
    MTXRESVAL = 'MTXRESVAL',
    PGRTYP = 'PGRTYP',
    PRJACT = 'PRJACT',
    PRJACTTYP = 'PRJACTTYP',
    PRJDATADEF = 'PRJDATADEF',
    PRJDATASRC = 'PRJDATASRC',
    PRJIMPORTANTEVENTS = 'PRJIMPORTANTEVENTS',
    PRJMETHOD = 'PRJMETHOD',
    PRJPROXYVOTING = 'PRJPROXYVOTING',
    PRJPROXYVOTINT = 'PRJPROXYVOTINT',
    PRJREP = 'PRJREP',
    PRJRES = 'PRJRES',
    PRJTSK = 'PRJTSK',
    PRJTYP = 'PRJTYP',
    PRJTYPEUS = 'PRJTYPEUS',
    PRJTMPL = 'PRJTMPL',
    PRXADV = 'PRXADV',
    TENDINT = 'TENDINT',
    TSHACT = 'TSHACT',
    USRDIVISION = 'USRDIVISION',
    USRMARKET = 'USRMARKET',
    VOTATT = 'VOTATT',
    VOTPTF = 'VOTPTF',
    VOTRAT = 'VOTRAT',
    WORLDS = 'WORLDS',
    YEAROFMANDATE = 'YEAROFMANDATE',
    GICSECTOR = 'GICSECTOR',
    CURRENCY = 'CURRENCY',
    SHARECLASSIFICATION = 'SHARECLASSIFICATION',
    PRJSECTYPE = 'PRJSECTYPE',
    PRJMARKETCOVERAGE = 'PRJMARKETCOVERAGE',
    CONTACTSOURCE = 'CONTACTSOURCE',
    INIIMP = 'INIIMP',
    INITYP = 'INITYP',
    EXCIMP = 'EXCIMP',
    PRXADVVP = 'PRXADVVP',
    TIMING = 'TIMING',
    CLDOCTYPE = 'CLDOCTYPE',
    REPORTTYPE = 'REPORTTYPE',
    COMMENTTYPE = 'COMMENTTYPE',
    EXTADV = 'EXTADV',
    MONTHS = 'MONTHS',
    GICINDUSTRY = 'GICINDUSTRY',
    GICSUBINDUSTRY = 'GICSUBINDUSTRY',
    CLIENT_JOB_TYPE = 'CLIENT_JOB_TYPE',
    CLIENT_SERVICES = 'CLIENT_SERVICES',
    CLIENT_TEAM = 'CLIENT_TEAM',
    CLIENT_RETAINER = 'CLIENT_RETAINER',
    PROJECT_DEFAULT_EVENT = 'PROJECT_DEFAULT_EVENT',
    CUSTLOCTYPE = 'CUSTLOCTYPE',
    BROKERVOTETYPE = 'BROKERVOTETYPE',
    PROJECT_REPORING_CYCLE = 'PRJREPCYC',
    PROJECT_US_EVENT = 'PROJECT_US_EVENT',
    VOTE_CUST_REC_SOURCE = 'VOTECUSTRECSOURCE',
    PROJECT_MAILING_DOC = 'PROJECTMAILINGDOC',
    NOBO_FILES = 'NOBOFILES',
    NOBO_RO_EMAIL_MTRLS = 'NOBOROEMAILMTRLS',
    VOTING_SUMMARY_INVESTORS = 'VOTSUMINVESTORS'
}

export class Utils {
    static filter(opt: string[], value: string): string[] {
        const filterValue: string = value.toLowerCase();
        return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
    }

    static CityFilterGroup(value: string, cityGroups: CityGroup[]): CityGroup[] {
        if (!value) { value = 'A'; }
        if (value) {
            return cityGroups
                .map(group => ({ letter: group.letter, names: Utils.filter(group.names, value) }))
                .filter(group => group.names.length > 0);
        }
        return cityGroups;
    }

    static CountryFilterGroup(value: string, countryGroups: CountryGroup[]): CountryGroup[] {
        if (value) {
            return countryGroups
                .map(group => ({ letter: group.letter, names: Utils.filter(group.names, value) }))
                .filter(group => group.names.length > 0);
        }
        return countryGroups;
    }

    public getCountryByValue(value: string): Object {
        return this.regionarr.find(region => region.country === value) || {};
    }

    public CountryAutocomplete(filterValue: string, withIsoCode: boolean): NameValueModel[] {
        const minimumSearchLength = 2;
        const rtnArray: NameValueModel[] = [];
        if (filterValue && filterValue.length > 2) {
            this.regionarr.forEach((x: Region) => {
                if (x.country.toLowerCase().includes(filterValue.toLowerCase())) {
                    let tmpVal = x.country;
                    if (withIsoCode) { tmpVal = x.iso3; }
                    rtnArray.push({ name: x.country, value: tmpVal });
                }
            });
        }

        return rtnArray;
    }

    getLookupByType(filter: LookupFilter): Observable<Lookup[]> {
        return this.startupdataproviderService.getLookups().pipe(
          map(data => {
            return data.filter(a => a.fieldType === filter);
          })
        );
    }

    getCoverageList(): string[] {
        return ['AU', 'EU', 'US'];
    }

    getRegions(country): number {
        return this.regionarr.find(x => x.country == country).regionId;
    }

    setActiveDirectoryUser(target: ActiveDirectoryUser, source: ActiveDirectoryUser): void {
        if (!source) {
            return;
        }

        target.id = source.id;
        target.displayName = source.displayName;
        target.mail = source.mail;
        target.userPrincipalName = source.userPrincipalName;
    }

    transform(value: string) {
        const regexPattern1 = '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$';
        const regexPattern2 = '([^@])([a-z]*\.)(com|net|edu|org)(\.au)?(\/\S*)?$'
        if (!value.match(regexPattern1)) {
            let result: string = '';
            let match;
            if (match = value.match(regexPattern2)) {
                if (match[0].indexOf('.') == 0) {
                    result = `http:\/\/www${match[0]}`
                } else {
                    result = `http:\/\/www.${match[0]}`;
                }
            }
            return result;
        }
        return value;
    }

    openWebSite(website) {
        const site = this.transform(website);
        window.open(
            site,
            '_blank'
        );

    }

    public get regionarr(): Region[] {
        return this.startupdataproviderService.Regions() || [];
    }

    public get subregionarr(): SubRegion[] {
        return this.startupdataproviderService.getSubRegions(this.regionarr) || [];
    }

    constructor(private startupdataproviderService: StartupdataproviderService) {

    }

    getCountryGroup(): CountryGroup[] {
        const countryGroups: CountryGroup[] = this.startupdataproviderService.groupCountry(this.regionarr);
        return countryGroups;
    }
    getCityGroup(): CityGroup[] {
        const cityGroups: CityGroup[] = this.startupdataproviderService.groupCity();
        return cityGroups;
    }
    getCountriesByRegionGroup() {
        const regions = this.startupdataproviderService.Regions();
        const groupedCountries = regions.reduce((prev, curr) => {
            if (!curr.continent) {
                return prev;
            }

            if (!prev[curr.continent]) {
                prev[curr.continent] = [];
            }
            prev[curr.continent].push(curr.country);

            return prev;
        }, {});

        return groupedCountries;
    }
}
