import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import {RoleGuardService} from '@app/auth/role-guard.service';
@Component({
  selector: 'app-dashboard-au-landing',
  templateUrl: './dashboard-au-landing.component.html',
  styleUrls: ['./dashboard-au-landing.component.scss']
})
export class DashboardAULandingComponent implements OnInit, OnDestroy {
  routeSub: any;

  showBidSummary;
  showVoteSummary;
  showInvestorCallManagement;
  showFeedback;
  showHistory;
  showVotingIntentions;
  isProduction = false;

  index: number = 0;
  invid: number = 0;
  cov: string;
  userid: number;
  rolename: string;
  tmpl: string;

  canSeeInvestorList = false;


  constructor(private route: ActivatedRoute,
              private router: Router,
              private httpClient: HttpClient,
              private userService: UserService,
              private roleGuardService: RoleGuardService
  ) {

  }


  ngOnInit() {
    this.canSeeInvestorList = this.roleGuardService.hasAccess('DASHBOARD', 'PROJECT_OVERVIEW');
    this.cov = this.userService.getUserCoverage();
    this.userid = 0;
    this.rolename = this.userService.getUserRoleName();

    this.routeSub = this.route.params.subscribe((params) => {
      this.index = +params['id'];
      this.invid = +params['invid'];
      this.tmpl = 'Vote';
      this.showInvestorCallManagement = true;
      this.showFeedback = true;
      this.showHistory = true;
      this.showVotingIntentions = !environment.production;
      this.isProduction = environment.production;
    });
  }

  ngOnDestroy() {

  }
}
