interface ShareTypes {
  cusip: string;
  shareClassification: string;
  projectFinacialId?: number;
  prjInvDataShareClassId?: number;
}

export class ShareTypesAdapter {
  cusip: string;
  shareClassification: string;
  projectFinacialId?: number;
  prjInvDataShareClassId?: number;

  public constructor(init?: Partial<ShareTypes>) {
    Object.assign(this, init);
  }
}
