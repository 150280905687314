import { Beneficiary } from '../../dto/Beneficiary';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BeneficiaryFilter } from '@app/dto/BeneficiaryFilter';
import { BeneficiaryType } from '@app/enums/BeneficiaryType';

@Injectable({
    providedIn: 'root'
  })

export class BeneficiaryService{
    private cache$: Observable<Beneficiary[]>[] = [];

    constructor(private http: HttpClient) {
    }

    public createBeneficiary(beneficiary: Beneficiary): Observable<Beneficiary> {
        beneficiary.beneficiaryType = beneficiary.beneficiaryType == "Asset Manager" ? "AssetManager": "Fund";
        return beneficiary.beneficiaryType == "AssetManager"?
                this.http.post<Beneficiary>(`${environment.serverUrl}beneficiaries/add-asset-manager/`, beneficiary) :
                this.http.post<Beneficiary>(`${environment.serverUrl}beneficiaries/add-fund/`, beneficiary); 
    }

    public updateBeneficiary(beneficiary : Beneficiary): Observable<Beneficiary> {
        beneficiary.beneficiaryType = beneficiary.beneficiaryType == "Asset Manager" ? "AssetManager": "Fund";
        return beneficiary.beneficiaryType == "AssetManager"?
                this.http.put<Beneficiary>(`${environment.serverUrl}beneficiaries/update-asset-manager/`, beneficiary) :
                this.http.put<Beneficiary>(`${environment.serverUrl}beneficiaries/update-fund/`, beneficiary);
         
    }

    public getfilteredBeneficiaries(search : string, beneficiaryType: BeneficiaryType): Observable<BeneficiaryFilter[]> {
        return this.http.get<BeneficiaryFilter[]>(
                    `${environment.serverUrl}beneficiaries/beneficiaryFilter/
                     ${search}/${this.getEnumKeyByEnumValue(BeneficiaryType, beneficiaryType)}`);
    }

    public getBeneficiary(id: number,  beneficiaryType : BeneficiaryType) : Observable<Beneficiary>{
        switch(beneficiaryType){
            case BeneficiaryType.AssetManager: {
               return this.http.get<Beneficiary>(`${environment.serverUrl}beneficiaries/asset-manager/${id}`);
            }
            case BeneficiaryType.Fund: {
                return this.http.get<Beneficiary>(`${environment.serverUrl}beneficiaries/fund/${id}`);
            }
            case BeneficiaryType.DecisionMaker: {
                return this.http.get<Beneficiary>(`${environment.serverUrl}beneficiaries/investor/${id}`);
            }
        }         
    }

    public deactivateFund(id: number) {
        return this.http.delete(
            `${environment.serverUrl}beneficiaries/deactivate/fund/${id}`);
    }

    public deactivateAssetManager(id: number) {
        return this.http.delete(
            `${environment.serverUrl}beneficiaries/deactivate/assetManager/${id}`);
    }

    private getEnumKeyByEnumValue(enumToValidate, enumValue) {
        let keys = Object.keys(enumToValidate).filter(x => enumToValidate[x] == enumValue);
        return keys.length > 0 ? keys[0] : null;
    }
}
