export class VoteTableDto {
  templateProp: string;
  votingItemType: string;
  value: string;
  isChild: boolean;
  id: number;
  orderId?: number;
  itemId?: number;
  isParentWithChild: boolean;

  public constructor(input) {
    Object.assign(this, input);
  }
}
