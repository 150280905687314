import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Investor } from 'src/app/dto/Investor';
import {MatDialog} from '@angular/material';
import { ContactRelatedEntityType } from '@app/enums/ContactRelatedEntityTypeEnum';
import { ContactType } from '@app/enums/ContactTypeEnum';
  @Component({
    selector: 'app-investor-contact',
    templateUrl: './investor-contact.component.html',
    styleUrls: ['investor-contact.component.css']
  })
  
  export class InvestorContactComponent implements OnInit {
    model: Investor;
    investorid: number;
    routeSub: any;
    contactType : ContactType;
    contactRelatedEntityType: ContactRelatedEntityType;
    constructor(private activeroute: ActivatedRoute, 
      private  httpClient: HttpClient
      , public dialog: MatDialog) {
    }
  
    ngOnInit() {
      this.contactType = ContactType.Investor;
      this.contactRelatedEntityType = ContactRelatedEntityType.Investor;
      this.model = new Investor();
      this.routeSub = this.activeroute.params.subscribe((params) => {
        this.investorid = +params['id'];
        this.getInvestor();
      });
    }
    getInvestor() {
      let apiURL: string = environment.serverUrl + 'investors/investor-by-id/' + this.investorid;
      this.httpClient.get<Investor>(apiURL).subscribe(
        data => {
          this.model = data;
        },
        err => console.error(err)
      );
    }
  }