import {Component, OnInit, Input, ViewChild} from '@angular/core';
import { ProjectsService } from '@app/services/projects.service';
import { ClientProjectAssignmentDTO } from '@app/dto/ClientProjectAssignment';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import {AlertService} from '@app/services/alert.service';
import {ActiveDirectoryUserListComponent} from '@app/components/autocompletes/active-directory-user-list/active-directory-user-list.component';
import { ActiveDirectoryUser } from '@app/dto/ActiveDirectoryUser';

@Component({
  selector: 'app-assign-projects-to-clients',
  templateUrl: './assign-projects-to-clients.component.html',
  styleUrls: ['./assign-projects-to-clients.component.scss']
})
export class AssignProjectsToClientsComponent implements OnInit {
  @Input() public projectId: number;
  @Input() public disabled = false;
  public showContent: boolean = false;
  public errorOccured: boolean = false;
  public errMsg: string;
  public assignedClients: ClientProjectAssignmentDTO[] = [];
  public newAssignment: ClientProjectAssignmentDTO;
  public selectedUser: ActiveDirectoryUser;
  @ViewChild(ActiveDirectoryUserListComponent) аctiveDirectoryUserListComponent: ActiveDirectoryUserListComponent;

  constructor(private projectService: ProjectsService,
              private dialogsService: DialogsService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.selectedUser = new ActiveDirectoryUser();
    this.newAssignment = new ClientProjectAssignmentDTO();
    this.newAssignment.projectId = this.projectId;
    this.getAssignments();
  }

  getAssignments() {
    this.projectService.getClientProjectAssignment(this.projectId)
      .subscribe(x => {
        this.assignedClients = x.filter(x => x.active);
        this.errorOccured = false;
        this.showContent = true;
      },
        err => {
          this.errorOccured = true;
          this.errMsg = JSON.stringify(err);
        });
  }

  delete(cpa: ClientProjectAssignmentDTO) {
    this.dialogsService.confirm('Delete Client Assignment', `Delete ${cpa.clientEmailAddress} from the project?`);
    this.dialogsService.close$.subscribe(res => {
      cpa.active = false;
      this.addAssignment(cpa);
    });
  }

  addAssignment(cpa: ClientProjectAssignmentDTO, selectedUser: ActiveDirectoryUser | null = null) {
    if (selectedUser && this.assignedClients.find(client => client.clientEmailAddress === selectedUser.userPrincipalName)) {
      this.alertService.sendError(`Duplicate Client: ${selectedUser.displayName}.`);
    } else {
      this.projectService.updateClientProjectAssignment(cpa)
        .subscribe(x => {
            this.аctiveDirectoryUserListComponent.resetValue();
            this.getAssignments();
          },
          err => {
            this.errorOccured = true;
          if (err.error) {
            this.errMsg = this.alertService.transformError(err.error);
          } else{
            this.errMsg = JSON.stringify(err);
          }  

          this.alertService.sendError(this.errMsg);
          });
    }
  }
}




