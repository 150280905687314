import { FollowUp } from '@app/dto/FollowUp';
import { UserService } from '@app/services/user.service';
import { ActionsService } from '@app/services/actions.service';
import { Component, OnInit, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { FollowUpCallsWithCounterDto } from '@app/dto/FollowUpCallsWithCounterDto';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-follow-up-calls',
  templateUrl: './follow-up-calls.component.html',
  styleUrls: ['./follow-up-calls.component.scss']
})
export class FollowUpCallsComponent implements OnInit {
  skip = 0;
  take = 3;
  followUps: FollowUp[] = [];
  followUpsCallWithCounter: FollowUpCallsWithCounterDto[];
  followUpsCounter: number;
  loadMore = false;
  _projectId: string;
  noItemsText = 'Follow-up Calls';
  showGrid = true;
  @Input() isPersonalDashboard: boolean;
  @Input() set projectId(projectId) {
    this._projectId = projectId;
  }

  get projectId() {
    return this._projectId;
  }

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private actionService: ActionsService,
    private userService: UserService,
    private spinnerService: NgxSpinnerService) {
    this.matIconRegistry.addSvgIcon('phone', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/phone.svg'));

  }

  ngOnInit() {
    this.getFollowUps();
  }

  public getFollowUps() {
    const userName = this.userService.getUserProfile().name;
    let result: any;

    this.spinnerService.show('follow-up-calls');

    result = this.isPersonalDashboard ? this.actionService.getFollowUps(userName, this.skip, this.take) :
      this.actionService.getFollowups(+this.projectId, this.skip, this.take);
    result.subscribe(data => {
        this.followUps.push(...data.followUps);
        this.followUpsCounter = data.counter;
        this.loadMore = this.followUpsCounter > this.take && this.followUps.length < this.followUpsCounter;
        this.spinnerService.hide('follow-up-calls');
      },
      (err) => {
        this.spinnerService.hide('follow-up-calls');
      }
    );

  }

  fetchRecords() {
    this.take += this.take;
    this.followUps = [];
    this.followUpsCallWithCounter = [];
    this.loadMore = false;
    this.getFollowUps();
  }
}
