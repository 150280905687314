import { AlertService } from './../../../services/alert.service';
import { Component, Input, AfterViewInit, OnInit, ViewChild, OnDestroy, AfterContentChecked, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { SearchGridService } from '@app/service/search-grid/search-grid.service';
import { GridFilterItem, GridFilterModel } from '@app/components/grid-filter/grid-filter.models';
import { HttpClient } from '@angular/common/http';
import { NoboUploadFileComponent } from '@app/components/nobo-upload-file/nobo-upload-file.component';
import { DashboardVotingHistoryDialogComponent } from './dashboard-voting-history-dialog/dashboard-voting-history-dialog.component';
import { CommonUtilityService } from '@app/utility/common-utility.service';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import {
  VotingPatternsDialogComponent
} from '@app/dashboard/dashboardau/dashboard-nobo-hr-table/voting-patterns-dialog/voting-patterns-dialog.component';
import { OciExportSetUpDialogComponent } from './oci-export-set-up-dialog/oci-export-set-up-dialog.component';
import { OciExportSetUpConfiguration, OciExportSetUpDialogConfirmedOptionsModel, OciExportSetUpDialogSelectedOptionsModel } from './oci-export-set-up-dialog/oci-export-set-up-dialog.models';
import { OciExportConfirmDialogComponent } from './oci-export-confirm-dialog/oci-export-confirm-dialog.component';
import { ExportLiveVotesDialogComponent } from '@app/components/export-live-votes-dialog/export-live-votes-dialog.component';
import { take, takeUntil, finalize  } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { DashboardNoborhService } from '@app/services/dashboard/dashboard-noborh.service';
import { FilterDto, SearchDto } from '@app/service/search-grid/SearchDto';
import * as FileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterContainerType } from '@app/components/table-filter-wrapper/enums';
import { noboFilters } from './dashboard-nobo-rh-table.config';
import { TableWrapperComponent } from '@app/components/table-filter-wrapper/containers/table-wrapper/table-wrapper.component';
import { FiltersService } from '@app/services/filters/filters.service';

@Component({
  selector: 'app-dashboard-nobo-hr-table',
  templateUrl: './dashboard-nobo-hr-table.component.html',
  styleUrls: ['./dashboard-nobo-hr-table.component.scss']
})
export class DashboardNoboHrTableComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {
  @Input() projectId: number;
  @Input() projectName: string;
  @Input() ociSetUpDate: Date;
  @ViewChild('topScrollBar') topScrollBar: ElementRef;
  @ViewChild('bottomScrollBar') bottomScrollBar: ElementRef;
  @ViewChild('noboSummary') noboSummaryTable: TableWrapperComponent;

  dataSource = new MatTableDataSource();
  _dataSource = new MatTableDataSource();
  displayedColumns: string[] = [];
  originalColumns: string[] = [
    'investorName',
    'addresses',
    'contact',
    'emailMaterials',
    'investorTypeDescription',
    'cusip',
    'controlNumber',
    'callSequenceNumber',
    'shares',
    'voteStatus',
    'liveVote',
    'voteType',
    'source',
  ];
  displayDynamicColumns: DynamicColumnDefinition[] = [];
  votingOptions: any[] = this.projectTemplateService.votedtypecolors;
  topScrollbarWidth = '';
  showTopScrollBar = true;
  showGrid = false;
  numberOfInvestors = 0;
  public filterContainerType = FilterContainerType;
  public endpoint = '';
  public noboFilters = noboFilters;
  private unsubscribe$ = new Subject<void>();
  private initialLoad = true;

  constructor(
    private alertService: AlertService,
    private httpClient: HttpClient,
    private cdref: ChangeDetectorRef,
    public dialog: MatDialog,
    private projectTemplateService: ProjectTemplateService,
    private commonUtility: CommonUtilityService,
    private dialogsService: DialogsService,
    private dashboardService: DashboardService,
    private readonly dashboardNoborhService: DashboardNoborhService,
    private ngxSpinnerService: NgxSpinnerService,
    private filtersService: FiltersService
  ) { }

  ngOnInit() {
    this.endpoint = `noborh/noborh-summary/${this.projectId}`;
    this.scroll();
  }

  ngAfterViewInit() {
    const controllerName = 'noborh';

    forkJoin({
      cusips : this.filtersService.getFilterOptions(`${controllerName}/noborh-summary/options/cusip/${this.projectId}`),
      investorTypes: this.filtersService.getFilterOptions(`${controllerName}/noborh-summary/options/investor-type/${this.projectId}`),
      voteStatuses: this.filtersService.getFilterOptions(`${controllerName}/noborh-summary/options/vote-status/${this.projectId}`),
      howVoted: this.filtersService.getFilterOptions(`${controllerName}/noborh-summary/options/vote-type/${this.projectId}`),
      sources: this.filtersService.getFilterOptions(`${controllerName}/noborh-summary/options/source/${this.projectId}`),
      emailMaterials: this.filtersService.getFilterOptions(`${controllerName}/noborh-summary/options/email-materials/${this.projectId}`)
    }).subscribe((result) => {
      this.noboFilters.cusip.options = result.cusips;
      this.noboFilters.voteStatus.options = result.voteStatuses;
      this.noboFilters.investorType.options = result.investorTypes;
      this.noboFilters.howVoted.options = result.howVoted;
      this.noboFilters.source.options = result.sources;
      this.noboFilters.emailMaterials.options = result.emailMaterials;
    });
  }

  setNOBORHSummary(data) {
    this.numberOfInvestors = data.totalItems;
    this.setAdditionalColumns(data);
    this.setUpGrid(data);

    /*if (this.numberOfInvestors === 0 && this.initialLoad) {
      this.showGrid = false;
    } else {
      this.showGrid = true;
    }

    this.initialLoad = false;*/
  }

  setAdditionalColumns(data) {
    this.displayedColumns = Object.assign([], this.originalColumns);
    this.displayDynamicColumns = [];
    const columns = data.items.find(x => x.votingSummary.length > 0);
    if (columns) {
      columns.votingSummary.forEach(vot => {
        const columnname = vot.votingItemChild.parentVotingItemId
          ? `${vot.templateNumber}. ${this.indexProperties(vot.votingItemChild.orderId)}`
          : vot.templateNumber;
        this.displayedColumns.push(columnname);
        const dynamic = new DynamicColumnDefinition();
        dynamic.colHeader = columnname;
        dynamic.colName = vot.votingItemChild.parentVotingItemId ? vot.votingItemChild.name : vot.template;
        this.displayDynamicColumns.push(dynamic);
      });
    }
  }

  setUpGrid(data) {
    const padding = 15;
    const tableRows = data.items.reduce((accumulator, item) => {
      let address;
      let shortAddresses;
      let postalCode;
      let contact;
      item.addresses.forEach(element => {
        if (element && element != '') {
          shortAddresses = shortAddresses ? shortAddresses += element + '<br>' : element + '<br>';
          address = address ? address += `${element}` : `${element}`;
        }
      });
      postalCode = ' Postal Code: ' + (item.postalCode ? item.postalCode : '');
      address += postalCode;
      shortAddresses += postalCode;
      if (item.phoneNumber && item.phoneNumber != '') {
        contact = item.phoneNumber + '<br>';
      }
      if (item.email && item.email != '') {
        contact ? contact += item.email : contact = item.email;
      }
      const record = {
        investorId: item.investorId,
        id: item.id,
        investorName: item.investorName,
        addresses: address,
        shortAddress: shortAddresses ? shortAddresses.substring(0, 55) : '',
        contact: contact,
        emailMaterials: item.emailMaterialStatus,
        investorTypeDescription: item.investorTypeDescription,
        voteStatus: item.voteStatus,
        liveVote: item.liveVote,
        shares: item.shares,
        cusip: item.cusip,
        voteType: item.voteType,
        votedDate: item.votedDate,
        controlNumber: +item.controlNumber,
        callSequenceNumber: ('0'.repeat(padding) + item.callSequenceNumber).slice(-padding),
        projectInvestorId: item.projectInvestorId,
        source: item.source,
        insertBy: item.insertBy
      };

      item.votingSummary.forEach(vot => {
        const columnname = vot.votingItemChild.parentVotingItemId ? `${vot.templateNumber}. ${this.indexProperties(vot.votingItemChild.orderId)}` : vot.templateNumber;
        record[columnname] = vot.votedAction;
      });
      accumulator.push(record);
      return accumulator;
    }, []);
    this.dataSource.data = tableRows;
  }

  ngOnDestroy() {
    this.bottomScrollBar.nativeElement.removeAllListeners();
    this.topScrollBar.nativeElement.removeAllListeners();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getColor(value) {
    if (value) {
      return (this.votingOptions.find(x => x.type === value) || { color: 'inherit' }).color;
    } else { return this.votingOptions.find(x => x.type === 'DEFAULT').color; }
  }

  openUploadFileDialog() {
    const dialogRef = this.dialog.open(NoboUploadFileComponent, {
      data: {
        projectId: this.projectId
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      this.noboSummaryTable.reloadItems();
    });
  }

  onOciSetUp(defaultData: OciExportSetUpDialogSelectedOptionsModel = null) {

    const dialogConfiguration = new OciExportSetUpConfiguration();
    dialogConfiguration.header = 'OCI Setup Parameters';
    dialogConfiguration.nextButtonText = 'Continue';

    const dialogRef = this.dialog.open(OciExportSetUpDialogComponent, {
      data: {
        projectId: this.projectId,
        configuration: dialogConfiguration,
        default: defaultData
      },
      width: '95%',
    });

    dialogRef
      .afterClosed()
      .subscribe((data: OciExportSetUpDialogConfirmedOptionsModel) => {
        if (data.result) { this.onOciSetUpConfirm(data); }
      });
  }

  onOciSetUpConfirm(data: OciExportSetUpDialogConfirmedOptionsModel) {
    const getOciSetUpDto = () => ({
      cusips: data.cusips,
      investorTypeIds: data.investorTypes.map((x) => x.value),
      sourceIds: data.sources.map((x) => x.value),
      projectId: this.projectId,
    });

    this.dashboardService
      .ociSetUpNumberOfInvestors(getOciSetUpDto())
      .pipe(take(1))
      .subscribe((numberOfInvestors) => {
        const dialogRef = this.dialog.open(OciExportConfirmDialogComponent, {
          data: {
            projectName: this.projectName,
            numberOfInvestors: numberOfInvestors,
            cusips: data.cusips,
            sources: data.sources,
            investorTypes: data.investorTypes,
          },
          width: '60%',
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.ngxSpinnerService.show('oci-export');
            this.dashboardService
              .ociSetUp(getOciSetUpDto())
              .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => this.ngxSpinnerService.hide('oci-export'))
              )
              .subscribe((result) => {
                  this.ociSetUpDate = result.setupDate;
                  this.alertService.sendSuccess(result.message, true);
                },
                (e) => this.alertService.alert(e)
              );
          } else {
            this.onOciSetUp({
              cusips: getOciSetUpDto().cusips,
              investorTypes: getOciSetUpDto().investorTypeIds,
              sources: getOciSetUpDto().sourceIds
            });
          }
        });
      });
  }

  public openExportVotesDialog() {
    const { projectId } = this;

    this.dialog.open(ExportLiveVotesDialogComponent, {
      data: { projectId }
    });
  }

  public openVotingPatternDialog() {
    const { projectId } = this;

      this.dialog.open(VotingPatternsDialogComponent, {
        data: { projectId },
        width: '95%',
        maxWidth: 'unset'
      }).afterClosed()
        .subscribe(result => {
          if (result) {
            this.dashboardNoborhService.savePatternVotes(result, this.projectId)
              .pipe(take(1), takeUntil(this.unsubscribe$))
              .subscribe(
                () => this.noboSummaryTable.reloadItems(),
                error => this.alertService.alert(error),
              );
          }
        });

  }

  showVotingHistory(row) {
    const dialogRef = this.dialog.open(DashboardVotingHistoryDialogComponent, {
      data: {
        investorId: row.investorId,
        projectId: this.projectId,
        investorName: row.investorName,
        emailMaterialStatus: row.emailMaterials
      },
      width: '1100px',
      maxHeight: 'fit-content'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.noboSummaryTable.reloadItems();
      }
    });
  }

  ngDoCheck() {
    if (this.topScrollbarWidth != this.bottomScrollBar.nativeElement.scrollWidth + 'px') {
      this.topScrollbarWidth = this.bottomScrollBar.nativeElement.scrollWidth + 'px';
    }
  }

  scroll() {
    this.topScrollBar.nativeElement.addEventListener('scroll', () => {
      if (this.showScrollBar()) {
        this.topScrollbarWidth = this.bottomScrollBar.nativeElement.scrollWidth + 'px';
        this.bottomScrollBar.nativeElement.scrollLeft = this.topScrollBar.nativeElement.scrollLeft;
      }
    });
    this.bottomScrollBar.nativeElement.addEventListener('scroll', () => {
      if (this.showScrollBar()) {
        this.topScrollbarWidth = this.bottomScrollBar.nativeElement.scrollWidth + 'px';
        this.topScrollBar.nativeElement.scrollLeft = this.bottomScrollBar.nativeElement.scrollLeft;
      }
    });

  }
  showScrollBar(): boolean {
    return this.showTopScrollBar = this.bottomScrollBar.nativeElement.scrollWidth > this.bottomScrollBar.nativeElement.offsetWidth;
  }

  indexProperties = (index) => this.commonUtility.indexToLetter(index, false);

  downloadProxyVotingList(){
    const dialogConfiguration = new OciExportSetUpConfiguration();
    dialogConfiguration.header = 'Setup Parameters';
    dialogConfiguration.nextButtonText = 'Download';

    const dialogRef = this.dialog.open(OciExportSetUpDialogComponent, {
      data: {
        projectId: this.projectId,
        configuration: dialogConfiguration
      },
      width: '95%',
    });

    dialogRef
      .afterClosed()
      .subscribe((data: any) => {
        if (data.result) {
          const searchDto = new SearchDto();
          searchDto.filters = [
            new FilterDto({ filterPorp: 'ProjectId', value: [this.projectId.toString()] }),
            new FilterDto({ filterPorp: 'Cusips', value: data.cusips}),
            new FilterDto({ filterPorp: 'InvestorTypes', value: data.investorTypes.map((x) => x.value) }),
            new FilterDto({ filterPorp: 'Sources', value: data.sources.map((x) => x.value) })
          ];

          this.dashboardService.downloadProxyVotingList(searchDto).subscribe(
          response => {
            const contentDisposition = response.headers.get('content-disposition');
            const fileName = contentDisposition.split(';')[1].trim().split('=')[1].replace(/["']/g, "");

            FileSaver.saveAs(response.body, `${fileName}.zip`);
          },
          error => {
            this.alertService.sendError(`Error: ${error.message}`);
          })
        }
      });
  }
}

export class DynamicColumnDefinition {
  colHeader: string;
  colName: string;
}
