import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ResponsibilityUserObject } from '@app/dto/base/ResponsibilityUserObject';

@Injectable()
export class ExportService {
    private EXCEL_TYPE: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    private EXCEL_EXTENSION: string = '.xlsx';

  public export(data: any, fileName: string){
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    
    const blob: Blob = new Blob([excelBuffer], {type: this.EXCEL_TYPE});
    FileSaver.saveAs(blob, fileName + '_export_' + new  Date().getTime() + this.EXCEL_EXTENSION);
  }

  public exportAdminData(data: ResponsibilityUserObject[], fileName: string, displayNameCaption: string) {
    this.export(this.flattenResponsibilityUserDataSource(data, displayNameCaption), fileName);
  }

  public flattenResponsibilityUserDataSource(data: ResponsibilityUserObject[], displayNameCaption: string) {
    var result = data;
    result.map(x=> {
      let item: any = x;
      item[displayNameCaption] = x.responsibilityUser? x.responsibilityUser.displayName : "";      
      delete item.responsibilityUser;
    });
    return result;
  }
}