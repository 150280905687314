import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { VotingItemEditDto, CustomFieldDto, VotingItemDto, VotingItemDropdownDto } from '@app/dto/VotingItemDto';
import { environment } from 'src/environments/environment';
import { IdName } from '@app/investor/investordetail/investor-intel-esg/investor-intel-esg-survey/IdName';
import { VotingItemDetailsDTO } from '@app/investor/investordetail/investor-history-summary/investor-voting-summary-table/investor-voting-item-details/VotingItemDetailsDTO';

@Injectable({
  providedIn: 'root'
})
export class VotingItemsService {
  public static VOTING_TYPE_DIRECTOR = 2;
  public static DEFAULT_VOTING_ITEM_TYPE_NAME = 'standard';

  constructor(private httpClient: HttpClient) { }

  public getVotingItem(votingItemId: number, projectId: number): Observable<VotingItemEditDto> {
    let apiURL: string = `${environment.serverUrl}projects/voting-item/${projectId}/${votingItemId}`;
    return this.httpClient.get<VotingItemEditDto>(apiURL);
  }

  public getClassificationTemplateFields(classificationId: number, projectId: number): Observable<CustomFieldDto[]> {
    let apiURL: string = `${environment.serverUrl}projects/classification-template-fields/${projectId}/${classificationId}`;
    return this.httpClient.get<CustomFieldDto[]>(apiURL);
  }

  public addVotingItem(votingItemDto: VotingItemEditDto): Observable<boolean> {
    let apiURL: string = `${environment.serverUrl}projects/voting-item`;
    return this.httpClient.post<boolean>(apiURL, votingItemDto);
  }

  public editVotingItem(votingItemDto: VotingItemEditDto): Observable<boolean> {
    let apiURL: string = `${environment.serverUrl}projects/voting-item`;
    return this.httpClient.put<boolean>(apiURL, votingItemDto);
  }

  public deleteVotingItem(votingItemId: number, projectId: number): Observable<boolean> {
    let apiURL: string = `${environment.serverUrl}projects/voting-item/${projectId}/${votingItemId}`;
    return this.httpClient.delete<boolean>(apiURL)
  }

  public getVotingItems(projectId: number): Observable<VotingItemDto[]> {
    let apiURL: string = `${environment.serverUrl}projects/voting-items/${projectId}`;
    return this.httpClient.get<VotingItemDto[]>(apiURL);
  }

  public getVotingItemsDropdown(projectId: number): Observable<VotingItemDropdownDto[]> {
    let apiURL: string = `${environment.serverUrl}projects/voting-items-dropdown/${projectId}`;
    return this.httpClient.get<VotingItemDropdownDto[]>(apiURL);
  }

  public getVotingItemTypes(projectId: number):Observable<IdName[]>{
    let apiURL: string = `${environment.serverUrl}lookups/voting-item-types/${projectId}`;
    return this.httpClient.get<IdName[]>(apiURL);
  }

  public getVotingItemDetails(votingItemId: number): Observable<any> {
    const url = `${environment.serverUrl}projects/voting-item/details/${votingItemId}`;
    return this.httpClient.get<VotingItemDetailsDTO>(url);
  }
}
