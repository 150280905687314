import {Component, Input, Output, EventEmitter, DoCheck, OnInit} from '@angular/core';

@Component({
  selector: 'app-expand-list-checkbox',
  templateUrl: './expand-list-checkbox.component.html',
  styleUrls: ['./expand-list-checkbox.component.scss']
})
export class ExpandListCheckboxComponent implements DoCheck, OnInit  {

  @Input()
  public title: string;

  @Input() set items(data) {
    if (data) {
      this._items = data;
      this.updateBulkCheckbox();
    }
  }
  @Input() categoryCheck: boolean = false;

  public _items: Array<{ name: string, checked: boolean, id?: number; }> = [];
  public open = true;

  allSelectedRecordsCheck = false;
  indeterminateAllSelectedRecords = false;
  allSelectedRecords = [];

  @Output() boxChanged: EventEmitter<boolean> = new EventEmitter<boolean>();


  toggleList() {
    this.open = !this.open;
  }

  onChange(item) {
    this.boxChanged.emit();
    if (item.checked) {
      this.allSelectedRecords = [...this.allSelectedRecords, item];
    } else {
      this.allSelectedRecords = this.allSelectedRecords.filter(record => record.id !== item.id);
    }

    this.updateBulkCheckbox();
  }

  bulkUpdateCheckbox($event) {
    for (const r of this._items) {
      r.checked = $event.checked;
    }
    this.boxChanged.emit();
  }

  updateBulkCheckbox() {
    this.allSelectedRecordsCheck = this.allSelectedRecords.length === this._items.length;
    this.indeterminateAllSelectedRecords = this.allSelectedRecords.length && this._items.length !== (this.allSelectedRecords || []).length;
  }

  ngDoCheck() {
    for (const r of this._items) {
      this.allSelectedRecords = this.allSelectedRecords.filter(record => record.id !== r.id);
      if (r.checked) {
        this.allSelectedRecords = [...this.allSelectedRecords, r];
      }
    }

    this.updateBulkCheckbox();
  }

  ngOnInit(){
  }

}
