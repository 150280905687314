import { VoteTableDto } from '@app/dto/VoteTableDto';

export interface IVotingPatternsScannedInvestorInterface {
  controlNumber: number;
  investorName: string;
  investorId: number;
  addresses: string[];
  shares: number;
}

interface IVotingPatternsScannedInvestorInterfaceWithPosition extends IVotingPatternsScannedInvestorInterface {
  position: number;
}

export class VotingPatternsScannedInvestorDto {
  position: number;
  address: string;
  controlNumber: number;
  investorName: string;
  investorId: number;
  shares: number;

  public constructor(input?: Partial<IVotingPatternsScannedInvestorInterfaceWithPosition>) {
    Object.assign(this, {
      ...input,
      ...(input.addresses && {
        address: input.addresses.filter(v => v).join(', ')
      })
    });
  }
}

export class VoteToPatternDTO {
  itemId: number;
  isChild: boolean;
  orderId: number | null;
  voteType: string;

  public constructor(input?: Partial<VoteTableDto>) {
    Object.assign(this, {
      itemId: input.itemId,
      isChild: !!input.isChild,
      orderId: input.orderId || null,
      voteType: input.value
    });
  }
}

export interface IOutputPatternVotes {
  votingPattern: VoteToPatternDTO[];
  scannedInvestors: string[];
}


