import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Initiative} from '@app/dto/Initiative';
import {InitiativesService} from '@app/services/initiatives.service';
import {StartupdataproviderService} from '@app/service/startupdataprovider.service';
import {LookupFilter, Utils} from '@app/helper/utils';
import {MatOptionSelectionChange, MatSelectChange} from '@angular/material';
import {Lookup} from '@app/dto/Lookup';
import {AlertService} from '@app/services/alert.service';
import {DocumentRelatedEntityType} from '@app/enums/DocumentRelatedEntityType';
import {DatePipe} from '@angular/common';
import {FormControl, NgModel} from '@angular/forms';
import {AppConstants} from '@app/app.constants';
import {RegionCountryMultiselectHelper} from '@app/helper/RegionCountryMultiselectHelper';
import { BehaviorSubject, of } from 'rxjs';
import {ResolutionsClassificationService} from '@app/components/autocompletes/resolutions-classification/resolutions-classification.service';
import {RoleGuardService} from '@app/auth/role-guard.service';

@Component({
    selector: 'app-initiativedetail',
    templateUrl: './initiativedetail.component.html',
    styleUrls: ['./initiativedetail.component.scss']
})

export class InitiativeDetailComponent implements OnInit {
    isEditMode: boolean = false;
    disableForm: boolean = true;
    @Input() isCreateView: boolean = false;
    @Output() onClose: EventEmitter<number> = new EventEmitter<number>();
    @Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('initiativeForm') initiativeForm: any;
    loading: boolean = false;
    impactsList: string[];
    initiativeTypes: string[];
    util = new Utils(this.startupdataproviderService);
    documentRelatedEntityType: DocumentRelatedEntityType = DocumentRelatedEntityType.Initiative;
    model: Initiative = new Initiative();
    id: number;
    sectors: any;
    groupedCountries: {};
    regionalSectorSelectionEvent: MatOptionSelectionChange;
    hasDeactivatedInitiative: boolean = false;
    thematicFocusPickerData = {
        noRecordsText: 'There are still not thematic focus selected',
        noRecordsLink: 'Edit the thematic focus...',
        allRecords: [],
        selectedRecords: []
    };
    public isDirty = new BehaviorSubject<boolean>(false);
    public isDirty$ = this.isDirty.asObservable();
    websiteControl = new FormControl();
    hasAccess: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private initiativeService: InitiativesService,
        private startupdataproviderService: StartupdataproviderService,
        private roleGuardService: RoleGuardService,
        private alertService: AlertService,
        public datePipe: DatePipe,
        private resolutionsClassificationService: ResolutionsClassificationService
    ) {

    }

    ngOnInit(): void {
        this.hasAccess = this.roleGuardService.hasAccess('ADMIN', 'EDIT');
        this.initialiseUtils();
        this.getThematicFocusOptions();

        if (this.isCreateView) {
            this.model = new Initiative();
            this.model.activated = true;
            this.disableForm = false;
            this.loading=false;
        } else {
            this.route.parent.params.subscribe((params) => {
                this.id = params['id'];
                this.loading=true;
                this.getInitiative(this.id);
            });
        }

        this.isDirty$.subscribe(data => {
            return of(data);
        });
    }

    ngAfterViewInit() {
        if(this.initiativeForm){
            this.initiativeForm.statusChanges.subscribe(value => {
                const valid = value === 'VALID';
                this.isFormValid.emit(valid);
            });
        }
    }

    initialiseUtils() {
        this.util.getLookupByType(LookupFilter.GICSECTOR)
            .subscribe(data => this.sectors = data);

        this.groupedCountries = this.util.getCountriesByRegionGroup();

        this.util.getLookupByType(LookupFilter.INIIMP)
          .subscribe((impactList: Lookup[]) => {
            this.impactsList = impactList.map((item: Lookup) => {
                return item.fieldValue;
            });
          });

        this.util.getLookupByType(LookupFilter.INITYP)
          .subscribe((initiativeTypes: Lookup[]) => {
            this.initiativeTypes = initiativeTypes.map((item: Lookup) => {
                return item.fieldValue;
            });
          });
    }

    getInitiative(id) {
        this.initiativeService.getInitiative(id)
            .subscribe(
                data => {
                    const regionFocuses = new Set(data.regionFocuses);
                    RegionCountryMultiselectHelper.selectRegions(this.groupedCountries, regionFocuses);
                    data.regionFocuses = Array.from(regionFocuses);
                    this.thematicFocusPickerData.selectedRecords = this.getThematicFocusSelection(data.thematicFocuses);
                    this.loading = false;
                    this.model = data;
                },
                err => {
                    const regionFocuses = new Set(this.model.regionFocuses);
                    RegionCountryMultiselectHelper.selectRegions(this.groupedCountries, regionFocuses);
                    this.model.regionFocuses = Array.from(regionFocuses);
                    this.loading = false;
                }
            );
    }

    getThematicFocusOptions() {
      this.resolutionsClassificationService.getData().subscribe(
        data => {
          this.thematicFocusPickerData.allRecords = data;
          if (this.model.thematicFocuses) {
            this.thematicFocusPickerData.selectedRecords = this.getThematicFocusSelection(this.model.thematicFocuses);
            // Force child ( OptionsPicker ) to detect changes
            this.thematicFocusPickerData = JSON.parse(JSON.stringify(this.thematicFocusPickerData));
          }
        }
      );
    }

    getThematicFocusSelection(ids) {
        if (!this.thematicFocusPickerData.allRecords.length) {
            return [];
        }
        const selection = [];
        for (const option of this.thematicFocusPickerData.allRecords) {
            if (ids.includes(option.id.toString())) {
                selection.push(JSON.parse(JSON.stringify(option)));
            }
        }
        return selection;
    }

    saveThematicFocusSelection() {
        this.model.thematicFocuses = [];
        for (const option of this.thematicFocusPickerData.selectedRecords) {
            this.model.thematicFocuses.push(option.id);
        }
    }

    changeActive(value: boolean) {
        if (!value) {
            this.hasDeactivatedInitiative = true;
        } else {
            this.hasDeactivatedInitiative = false;
        }
    }

    formatDeactivatedDate() {
        if (!this.model.activated) {
            const dateToFormat = this.hasDeactivatedInitiative ? new Date() : this.model.deactivatedDate;
            const date = this.datePipe.transform(dateToFormat, 'dd MMM yyyy h:mm a'); // TODO: move as constant
            const formattedDate = `Inactive since: ${date}`;
            return formattedDate;
        }

        return "";
    }

    // country changed
    onCountrySelectionChange(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            this.regionalSectorSelectionEvent = event;
        }
    }

    // continent change
    onContinentSelectionChange(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            this.regionalSectorSelectionEvent = event;
        }
    }

    // select change
    selectionChange(event: MatSelectChange, select: NgModel) {
        const value: string = this.regionalSectorSelectionEvent.source.value;
        const isRegion = !!this.groupedCountries[this.regionalSectorSelectionEvent.source.value];
        const isSelected: boolean = this.regionalSectorSelectionEvent.source.selected;
        const resultToEmit = new Set<string>(event.value);
        if (isRegion) {
            RegionCountryMultiselectHelper.handleRegionSelection(this.groupedCountries, resultToEmit, value, isSelected);
        } else {
            RegionCountryMultiselectHelper.handleCountrySelection(this.groupedCountries, resultToEmit, value, isSelected);
        }
        const result = Array.from(resultToEmit);
        select.update.emit(result);
    }

    getContinentLabel(region: string) {
        return AppConstants.continentLabelMapping[region];
    }

    onEditBtnClick() {
        this.isEditMode = true;
        this.disableForm = false;
        this.isDirty.next(this.isEditMode);
    }

    onCancelBtnClick() {
        this.isEditMode = false;
        this.disableForm = true;
        this.isDirty.next(this.isEditMode);
    }

    onCancelDialogBtnClick() {
        if (this.isCreateView) {
            this.onClose.emit(undefined);
        }
    }

    onResetBtnClick() {
        this.model = new Initiative();
        this.model.activated = true;
        this.loading=false;
    }

    onCreateBtnClick() {
        this.model.regionFocuses = RegionCountryMultiselectHelper.cleanRegionFocuses(this.groupedCountries, this.model.regionFocuses);
        this.saveThematicFocusSelection();
        this.initiativeService.createInitiative(this.model).subscribe(
            data => {
                this.model = data;
                this.alertService.sendSuccess('Esg Initiative Created!');
                this.onClose.emit(this.model.esgInitiativeId);
            },
            err => {
                this.alertService.sendError(' error ' + JSON.stringify(err));
            }
        );
    }

    onSaveBtnClick() {
        this.isEditMode = false;
        this.disableForm = true;
        this.model.regionFocuses = RegionCountryMultiselectHelper.cleanRegionFocuses(this.groupedCountries, this.model.regionFocuses);
        this.saveThematicFocusSelection();
        this.initiativeService.updateInitiative(this.model).subscribe(
            data => {
                const regionFocuses = new Set(data.regionFocuses);
                RegionCountryMultiselectHelper.selectRegions(this.groupedCountries, regionFocuses);
                data.regionFocuses = Array.from(regionFocuses);
                this.model = data;
                this.alertService.sendSuccess('Esg Initiative Updated!');
                this.isDirty.next(this.isEditMode);
                this.onClose.emit(this.model.esgInitiativeId);
            },
            err => {
                const regionFocuses = new Set(this.model.regionFocuses);
                RegionCountryMultiselectHelper.selectRegions(this.groupedCountries, regionFocuses);
                this.model.regionFocuses = Array.from(regionFocuses);
                this.alertService.sendError(' error ' + JSON.stringify(err));
            }
        );
    }
    getDisplayName(select : NgModel){
        return RegionCountryMultiselectHelper.getDisplayName(select.model, this.groupedCountries)
    }
}
