import { UserService } from '@app/services/user.service';
import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActionsService } from '@app/services/actions.service';
import { FollowUpGrid } from '@app/dashboard/dashboardau/dashboard-actions/FollowUpGrid';
import { AlertService } from '@app/services/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-upcoming-follow-up',
  templateUrl: './upcoming-follow-up.component.html',
  styleUrls: ['./upcoming-follow-up.component.scss']
})
export class UpcomingFollowUpComponent implements OnInit {
  @Input() isPersonalDashboard: boolean;
  upcomingFollowUps: MatTableDataSource<FollowUpGrid> = new MatTableDataSource<FollowUpGrid>();
  upcomingFollowUpsList: FollowUpGrid[] = [];
  followupsCount: number;
  showLoadMoreBtn = false;

  displayUpcomingFollowupColumns: string[] = [
    'followup',
    'project',
    'contactName',
    'investorName',
    'comment',
    'action'
  ];
  _projectId: string;
  @Input() set projectId(projectId) {
    this._projectId = projectId;
  }
  get projectId() {
    return this._projectId;
  }

  noItemsText = 'Upcoming Follow-ups';
  showGrid: boolean;

  constructor(private actionsService: ActionsService,
    private userService: UserService,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService) {
  }

  ngOnInit() {
    this.getUpcomingFollowUps();
  }
  public getUpcomingFollowUps() {
    const userName = this.userService.getUserProfile().name;

    this.spinnerService.show('upcoming-follow-ups');

    const result = this.isPersonalDashboard ?
      this.actionsService.getUpcomingFollowupsPersonal(this.upcomingFollowUps.data.length, 5, userName) :
      this.actionsService.getUpcomingFollowups(+this.projectId, this.upcomingFollowUps.data.length, 5);
    result.subscribe(data => {
        this.upcomingFollowUpsList.push(...data.followUps);
        this.upcomingFollowUps.data = data.followUps;
        this.followupsCount = data.counter;
        this.showLoadMoreBtn = this.followupsCount > 5 && this.upcomingFollowUpsList.length < this.followupsCount;
        this.showGrid = data.followUps.length > 0;
        this.spinnerService.hide('upcoming-follow-ups');
      },
      (err) => {
        this.alertService.sendError(err);
        this.spinnerService.hide('upcoming-follow-ups');
      }
    );
  }
}
