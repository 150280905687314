import {Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from 'src/app/services/user.service';

@Component({
    selector: 'app-project-investor-controller',
    templateUrl: './project-investor-controller.component.html'
    /*styleUrls: ['project-list.component.css'] */
})

export class ProjectInvestorControllerComponent
{
    routeSub;
    clientid:number;
    projectid:number;
    userid:number;
    rolename:string;
    coverage:string;


    constructor(private route: ActivatedRoute,
        private router: Router,
        private httpClient: HttpClient,
        private userService: UserService) {
    }
    ngOnInit() {
        this.rolename = this.userService.getUserRoleName();
        this.userid = 0;
        this.coverage = this.userService.getUserCoverage();

        this.routeSub = this.route.params.subscribe((params) => {
            this.clientid =+ params['clientid'];
            this.projectid =+ params['id'];
            
            switch(this.coverage) { 
                /* case "EU": { 
                    this.router.navigate(['./investordata',this.clientid ,this.projectid,this.rolename,this.userid, this.coverage]);
                    break; 
                } */ 
                case "AU": { 
                    //this.router.navigate(['./',this.clientid ,10,this.rolename,this.userid, this.coverage]);
                   
                    this.router.navigate(['/','investordata', { outlets: { projectoutlet: [this.clientid ,10,this.rolename,this.userid, this.coverage] } }]);
                    //this.router.navigateByUrl('/(projectoutlet:investordata/1/1/AA/1/AB)');

                    /* <a [routerLink]="['/', 'red-pill', { outlets: { side: ['neo'], footer: ['trinity'] } }]">
                    Nav with a named outlet
                    </a> */


                    break; 
                    /* this.router.navigate(['projectinvestorcontroller',this.clientid ,10,this.rolename,this.userid, this.coverage]);
                    break;  */
                } 
                default: { 
                    break;              
                } 
            }
            
        });
        
    }

}
