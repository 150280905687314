// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  name: 'development',
  serverUrl: 'https://mscrmwebapidev.azurewebsites.net/api/',

  appUrl: 'http://localhost:4200',
  redirectUri: 'http://localhost:4200',
  externalInvestorUrl: 'https://msplatformcrminvestor.azurewebsites.net/investor/',
  activeDirectoryClientId : 'be180495-5912-4184-a378-9e0b9ddb7e90',
  graphApiBaseUrl : 'https://graph.microsoft.com/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
