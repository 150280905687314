import {ResolutionClassificationCustomFieldDto} from '@app/dto/ResolutionClassificationCustomFieldDto';

export class AddEditCustomFieldComponentData {    
    issClassificationId: number;
    action: string;
    item: ResolutionClassificationCustomFieldDto;

    public constructor(init?:Partial<AddEditCustomFieldComponentData>) {
        Object.assign(this, init);
    }
}
