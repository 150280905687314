import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ExternalAdvisorsAddEditComponent} from '@app/admin/external-advisors/external-advisors-add-edit/external-advisors-add-edit.component';
import {RoleGuardService} from '@app/auth/role-guard.service';

@Component({
  selector: 'app-external-advisors-dialog',
  templateUrl: './external-advisors-dialog.component.html',
  styleUrls: ['./external-advisors-dialog.component.scss']
})
export class ExternalAdvisorsDialogComponent implements OnInit {
  public _formValid = false;
  @ViewChild('externalAdvisor') externalAdvisor: ExternalAdvisorsAddEditComponent;
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              private roleGuardService: RoleGuardService,
              private dialogRef: MatDialogRef<ExternalAdvisorsAddEditComponent>) {
  }

  hasAccess : boolean =false;

  ngOnInit() {
    this.hasAccess = this.roleGuardService.hasAccess('ADMIN', 'EDIT');
  }

  setFormValidation(valid) {
    this._formValid = valid;
  }

  formSaved(flag) {
    this.dialogRef.close(flag);
  }

  onClickReset() {
    this.externalAdvisor.reset();
  }
}
