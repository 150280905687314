export class ClientExternalAdvisorContactCreateDTO{
    advisorId?: number;
    contactIds: number[];
    clientId?: number
}

export class ClientExternalAdvisorContactDTO{
    id: number;
    advisorTypeId: number;
    advisorType: string
    advisorId: number;
    advisorName: string;
    contactId: number;
    contactName: string;
    jobTitle: string;
    phone: string;
    email: string;
    contactCity:string;
}