import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSort, MatTableDataSource } from '@angular/material';
import { Lookup } from '@app/dto/LookUp';
import { LookupFilter, Utils } from '@app/helper/utils';
import { AGAINST, FOR, ONE_YEAR, ProjectTemplateService, THREE_YEARS, TWO_YEARS } from '@app/service/projectTemplateService';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { AlertService } from '@app/services/alert.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { CustodianVotesExceeShareCapitalTemplate } from '@app/services/dialogs/dialog-custodian-share-capital-template-config';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { ProjectsService } from '@app/services/projects.service';
import { ProjectCustodianShareClassificationInsertModel } from './DTOs/ProjectCustodianShareClassificationInsertModel';
import { CommonUtilityService } from '@app/utility/common-utility.service';
import { DynamicColumnDefinition } from '@app/dashboard/dashboardau/dashboard-custodian-table/dashboard-custodian-table.component';

@Component({
  selector: 'app-add-voting-reconciliation-dialog',
  templateUrl: './add-voting-reconciliation-dialog.component.html',
  styleUrls: ['./add-voting-reconciliation-dialog.component.scss']
})

export class AddVotingReconciliationDialogComponent implements OnInit {

  orgDisplayedColumns: string[] = [
    'shareType',
    'name'
  ];
  displayedColumns: string[] = [];
  displayDynamicColumns: DynamicColumnDefinition[] = [];
  dataSource;
  @ViewChild(MatSort) sort: MatSort;

  model: ProjectCustodianShareClassificationInsertModel[] = [];
  projectCustodianId: number;
  type: string;
  source: string;
  sources: Lookup[] = [];
  types: string [] = [Types.UPDATE, Types.RECALC];

  custodianVotingTypes: Lookup[] = [];
  additionalVotingTypes = [];
  voteTitle: string;
  projectId: string;
  private project;

  util = new Utils(this.startupdataproviderService);

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddVotingReconciliationDialogComponent>,
    private startupdataproviderService: StartupdataproviderService,
    private dashboardService: DashboardService,
    private projectsService: ProjectsService,
    private projectTemplateService: ProjectTemplateService,
    private alertService: AlertService,
    private dialogsService: DialogsService,
    private commonUtility: CommonUtilityService) { }

  ngOnInit() {
    this.type = Types.UPDATE;
    this.projectCustodianId = this.data.projectCustodianId;
    this.projectId = this.data.projectId;
    this.additionalVotingTypes = this.data.additionalVotingTypes;

    this.projectsService.getProject(this.projectId, true).subscribe(
      data => {
        this.project = data;
        this.voteTitle = data.template === this.projectTemplateService.SHAREHOLDER_MEETING_TEMPLATE_NAME ? 'Resolutions' : 'Bid companies';
        this.getProjectCustodianVotesById();
      }, error => {
        this.alertService.sendError(error.message);
      });

    this.util.getLookupByType(LookupFilter.VOTE_CUST_REC_SOURCE).subscribe(data => this.sources = data);
  }

  getProjectCustodianVotesById() {
    this.dashboardService.getProjectCustodiansVotesById(this.projectCustodianId).subscribe(data => {
      this.custodianVotingTypes = this.projectTemplateService.getCustodialReconciliationVotedTypes(
        this.project.template,
        this.additionalVotingTypes
      );

      if (data.length > 0) {
        this.model = data;
        this.setColumns(data);
        this.setTableRows(data);
      } else {
        this.dataSource = new MatTableDataSource();
        this.dataSource.sort = this.sort;
        this.dataSource.data = [];
      }
    }, error => {
      this.alertService.sendError(error.message);
    });
  }

  setColumns(data) {
    this.displayedColumns = Object.assign([], this.orgDisplayedColumns);

    this.displayDynamicColumns = [];

    this.custodianVotingTypes.forEach(shareType => {
      const coldef = new DynamicColumnDefinition();
      coldef.colName = `${shareType.fieldValue}`;
      coldef.colHeaderName = `${shareType.fieldLabel}`;
      coldef.colHeaderColor = `${shareType.color}`;
      this.displayedColumns.push(`${shareType.fieldValue}`);
      this.displayDynamicColumns.push(coldef);
    });
  }

  setTableRows(data) {
    let isDark = false;

    const tableRows = data.reduce((accumulator, item, currentIndex) => {
      item.votingItems.sort((a, b) => a.templateProperty1.localeCompare(b.templateProperty1)).forEach((_record, index) => {
        if (_record.votingItemChild.parentVotingItemId) {
          return;
        }

        const __items = this.findChildVotingItems(_record.votingItemId, item.votingItems);
        const haveChild = __items.length > 0;

        const name = `${_record.templateProperty1}. ${_record.templateProperty2}`;
        const row = {
          name: name,
          votingItemId: _record.votingItemId,
          projectCustodianId: this.projectCustodianId,
          class: ``,
          isDark: isDark,
          shareClassificationId: item.shareClassificationId,
          shareType: !index ? item.shareClassification : '',
          border: index === 0,
          isChild: false,
          votingItemType: _record.votingItemType
        };


        this.custodianVotingTypes.forEach((shareType) => {
          const itemVote = _record.votes.find(vote => vote.voteType === shareType.fieldValue);
          row[shareType.fieldValue] = {
            vote: (itemVote || {vote: 0}).vote,
            voteId: (itemVote || {voteId: null}).voteId,
            haveChild
          };
        });

        accumulator.push(row);
        if (haveChild) {
          const result = this.setChildRows(_record, __items, item, isDark);
          accumulator.push(...result);

        }

      });
      isDark = !isDark;

      return accumulator;
    }, []);

    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.dataSource.data = tableRows;
  }

  setChildRows = (parent, children, item, isDark) => {
    const rows = [];
    children.forEach((child, childIndex) => {
      const childName = `${this.indexProperties(child.votingItemChild.orderId)}. ${child.votingItemChild.name}`;

      const row = {
        name: childName,
        votingItemId: parent.votingItemId,
        projectCustodianId: this.projectCustodianId,
        class: ``,
        isDark: isDark,
        isCellWhite: true,
        shareClassificationId: item.shareClassificationId,
        shareType: ``,
        border: false,
        isChild: true,
        votingItemType: parent.votingItemType
      };
      this.custodianVotingTypes.forEach((shareType) => {

        const itemVote = child.votes.find(vote => vote.voteType === shareType.fieldValue);
        row[shareType.fieldValue] = {
          vote: ( itemVote || {vote: 0}).vote,
          voteId: (itemVote || {voteId: null}).voteId
        };
      });

      rows.push(row);
    });
    return rows;
  }

  changeType(value: string) {
    this.model.forEach(x => x.type = value);
  }

  changeSource(value: string) {
    this.model.forEach(x => x.source = value);
  }

  onSave() {
    const data = this.dataSource.data;

    this.model.forEach(x => {
      if (!x.type) {
        x.type = this.type;
      }

      x.entry = EntryTypes.MANUAL;
      x.votingItems.forEach(y => y.votes.forEach(c => {

        c.vote = 0;
        if (c.voteType) {
          const vote = data.find(k => k[c.voteType].voteId === c.voteId);

          if (vote && vote[c.voteType]) {
            c.vote = vote[c.voteType].vote;
          }
        }
      }));
    });

    this.dashboardService.addProjectCustodiansVotes(this.model).subscribe(id => {
      if (id) {
        this.dialogRef.close(id);
      }
    }, e => {
      const errorMessage = this.alertService.transformError(e.error);
      this.dialogsService.confirm(
        null,
        null,
        { template: CustodianVotesExceeShareCapitalTemplate({message: errorMessage }) }
      );
    });
  }

  findChildVotingItems = (votingItemId, items) => {
    return items.filter(item => item.votingItemChild && (item.votingItemChild.parentVotingItemId === votingItemId));
  }

  indexProperties = (index) => this.commonUtility.indexToLetter(index, false);

  hideVote(element, dynCol: DynamicColumnDefinition) {
    const isFreq = this.isVotingItemTypeFrequency(element);
    const hideArr = isFreq ? [FOR, AGAINST] : [ONE_YEAR, TWO_YEARS, THREE_YEARS];

    return hideArr.indexOf(dynCol.colName) > -1;
  }

  isVotingItemTypeFrequency = (item) => !!item.votingItemType.match(/^frequency/gmi);
}

export enum Types {
  UPDATE = 'Update',
  RECALC = 'Recalc'
}

export enum EntryTypes {
  MANUAL = 'Manual',
  IMPORT = 'Import'
}
