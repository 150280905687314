import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ProjectActivityGrid } from '@app/dto/ProjectActivityGrid';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ActionsService } from '@app/services/actions.service';
import { UserService } from '@app/services/user.service';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { SortDto } from '@app/service/search-grid/SearchDto';
import { SearchGridService } from '@app/service/search-grid/search-grid.service';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ProjectActivitiesHeaders } from './ProjectActivitiesHeadersEnum';
import { AppConstants } from '@app/app.constants';
import { FilterContainerOptionsDTO, SelectOptions } from '@app/components/table-filter-wrapper/interfaces';
import { FilterContainerType } from '@app/components/table-filter-wrapper/enums';
import { TableWrapperComponent } from '@app/components/table-filter-wrapper/containers/table-wrapper/table-wrapper.component';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-project-activity-grid',
  templateUrl: './dashboard-project-activity.component.html',
  styleUrls: ['dashboard-project-activity.component.scss']
})

export class DashboardProjectActivityComponent implements OnInit, OnDestroy {
  private activityUpdatedSubscription: Subscription;

  projectActivities = new MatTableDataSource();

  projectActivitiesList: ProjectActivityGrid[] = [];
  activityStatusInProgress: string = AppConstants.PROJECT_ACTIVITY_STATUS.activityStatusInProgress;
  activityStatusCompleted: string = AppConstants.PROJECT_ACTIVITY_STATUS.activityStatusCompleted;
  activityStatuses: string[] = [
    'Not Started',
    'In Progress',
    'Completed'
  ];
  displayProjectActivitiesColumns: string[] = [
    'enddate',
    'activity',
    'description',
    'status',
    'actions'
  ];

  gridHeader: string;
  loadedProjectActivities;
  showLoadMore = true;
  defaultNumberOfRecords = 5;
  isEdit = [];

  @Input() projectId: string;
  @Input() investorId: string;
  @Input() activityType: string;

  @ViewChild('activitiesPaginator') paginator: MatPaginator;
  @ViewChild('userPersonalActivities') userPersonalActivities: TableWrapperComponent;

  pageSize = 20;
  pageIndex = 0;
  length: number;

  ACTIONS = 'actions';
  PERSONAL = 'personal';
  INVESTOR = 'investor';
  canEditInvestor = false;

  searchService: SearchGridService;

  private personalActivitiesSortList: SortDto[] = [];

  noItemsText = 'Activities';
  showGrid = true;

  filterContainerType = FilterContainerType;
  @Output() public open = new EventEmitter();

  statusSubject = new BehaviorSubject<SelectOptions[]>([]);
  statusSubject$ = this.statusSubject.asObservable();
  // @ts-ignore
  userPersonalActivityFilters = {
    project: new FilterContainerOptionsDTO({
      type: this.filterContainerType.TEXT,
      fieldName: 'project',
      title: 'Filter by Project Name'
    }),
    investor: new FilterContainerOptionsDTO({
      type: this.filterContainerType.TEXT,
      fieldName: 'investor',
      title: 'Filter by Investor Name'
    }),
    activity: new FilterContainerOptionsDTO({
      type: this.filterContainerType.TEXT,
      fieldName: 'activity',
      title: 'Filter by Activity'
    }),
    description: new FilterContainerOptionsDTO({
      type: this.filterContainerType.TEXT,
      fieldName: 'description',
      title: 'Filter by Task Description'
    }),
    status: new FilterContainerOptionsDTO({
      type: this.filterContainerType.MULTISELECT,
      fieldName: 'status',
      title: 'Filter by Status',
      options: this.statusSubject$
    }),
    shares: new FilterContainerOptionsDTO({
      type: this.filterContainerType.NUMBER_RANGE,
      fieldName: 'shares',
      title: 'Filter by Shares'
    }),
    endDate: new FilterContainerOptionsDTO({
      type: this.filterContainerType.DATE_RANGE,
      fieldName: 'endDate',
      title: 'Filter by End Date'
    })
  };


  constructor(private actionsService: ActionsService,
    private userService: UserService,
    private roleGuardService: RoleGuardService,
    private httpClient: HttpClient, ) {
  }

  getStatusOptions() {
    this.actionsService.getStatusOptions().pipe(first()).subscribe(data => this.statusSubject.next(data));
  }

  ngOnInit() {
    this.canEditInvestor = this.roleGuardService.hasAccess('DASHBOARD', 'EDIT');

    switch (this.activityType) {
      case this.ACTIONS:
        this.addColumnsToDisplayActionsTab();
        this.getProjectActivities(this.projectActivities.data.length, this.defaultNumberOfRecords);

        this.activityUpdatedSubscription = this.actionsService.activityUpdatedSubject.subscribe(activityUpdated => {
          if (activityUpdated) {
            const countOfActivititiesToTake = this.projectActivities.data.length;
            this.projectActivitiesList = [];
            this.projectActivities.data = this.projectActivitiesList;

            this.getProjectActivities(0, countOfActivititiesToTake);
          }
        });
        break;
      case this.PERSONAL:
        this.addColumnsToDisplayPersonalDashboard();
        this.gridHeader = ProjectActivitiesHeaders.MyActivities;

        /*this.searchService =
          new SearchGridService(
            this.paginator,
            this.projectActivities,
            this.httpClient,
            'Actions/personal-activities',
            null,
            new FilterDto({ filterPorp: 'UserEmail', value: [this.userService.getUserEmail()] }),
            20,
            true);

        this.searchService.anyResults.subscribe(hasResults => {
          this.showGrid = hasResults;
        });*/

        this.getStatusOptions();
        this.activityUpdatedSubscription = this.actionsService.activityUpdatedSubject.subscribe(activityUpdated => {
          if (activityUpdated) {
            this.userPersonalActivities.reloadItems();
            this.getStatusOptions();
          }
        });

        break;
      case this.INVESTOR:
        this.addColumnsToDisplayInvestor();
        this.getProjectActivitiesPerInvestor(this.projectId, this.investorId);
        break;
    }
  }

  setUserPersonalActivitiesData(data) {
    this.projectActivities = new MatTableDataSource(data.items);
  }

  ngOnDestroy(): void {
    if (this.searchService) {
      this.searchService.unSubscribe();
    }

    if (this.activityUpdatedSubscription) {
      this.activityUpdatedSubscription.unsubscribe();
    }
  }

  public getProjectActivities(skip: number, take: number) {
    this.actionsService.getProjectActivitiesForGrid(+this.projectId, skip, take).subscribe(data => {
      this.projectActivitiesList.push(...data.items);
      this.projectActivities.data = this.projectActivitiesList;
      this.showGrid = data.totalResults > 0;
      this.gridHeader = ProjectActivitiesHeaders.ProjectActivities;
      if (this.projectActivitiesList.length >= data.totalResults) {
        this.showLoadMore = false;
      }
    },
      (err) => { console.log(err); });
  }

  public saveStatus(activityId: number, activityStatus: string, i: number) {
    this.actionsService.updateActivityStatus(activityId, activityStatus)
      .subscribe(() => {
        this.isEdit[i] = false;

        if (this.activityType === this.INVESTOR) {
          this.getProjectActivitiesPerInvestor(this.projectId, this.investorId);
        }
      }, (err) => { console.log(err); });
  }

  public getProjectActivitiesPerInvestor(projectId: string, investorId: string) {
    this.actionsService.getActivitiesPerInvestor(+projectId, +investorId).subscribe(data => {
      this.projectActivities.data = data;
      this.showGrid = data.length > 0;
      this.gridHeader = ProjectActivitiesHeaders.InvestorActivities;
    });
  }

  public loadMore() {
    this.getProjectActivities(this.projectActivities.data.length, this.defaultNumberOfRecords);
  }

  public addColumnsToDisplayPersonalDashboard() {
    const _list = ['project',
      'investor',
      'shares'];

    this.displayProjectActivitiesColumns.splice(1, 0, ..._list);
  }

  public addColumnsToDisplayActionsTab() {
    this.displayProjectActivitiesColumns.splice(0, 0, 'startdate');
    this.displayProjectActivitiesColumns.splice(4, 0, 'managedBy');
  }

  public addColumnsToDisplayInvestor() {
    this.displayProjectActivitiesColumns.splice(0, 0, 'startdate');
    this.displayProjectActivitiesColumns.splice(2, 0, 'managedBy');
  }

  triggerEvent($event: MouseEvent) {
    this.open.emit('TUP');
  }

}
