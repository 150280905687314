import { LookupFilter, Utils } from '@app/helper/utils';
import { Component, Inject, OnInit } from '@angular/core';
import { AlertService } from '@app/services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nobo-upload-file',
  templateUrl: './nobo-upload-file.component.html',
  styleUrls: ['./nobo-upload-file.component.scss']
})
export class NoboUploadFileComponent implements OnInit {
  fileTypes;
  file: File;
  fileName: string;
  projectId: number;
  uploadDocument: NOBORHUploadDocumentDto; 
  util = new Utils(this.startupdataproviderService);

  constructor( public dialogRef: MatDialogRef<NoboUploadFileComponent>,
    private alertService: AlertService,
    private startupdataproviderService: StartupdataproviderService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpClient: HttpClient) { }

  ngOnInit() {
    this.projectId = this.data.projectId;
    this.uploadDocument = new NOBORHUploadDocumentDto();
    this.uploadDocument.projectId = this.projectId;
    this.util.getLookupByType(LookupFilter.NOBO_FILES).subscribe(type => { 
      this.fileTypes = type;
    },
    err => {
      this.alertService.sendError(err);
    });
  }

  public readUrl(event) {
    const input = event.target;
    if (input.files && input.files[0]) {
      if (!this.isFileFormatValid(input.files[0].name)) {
        this.alertService.sendError('Select a valid csv file format!');
        return;
      }      

      this.file = input.files[0];
      this.fileName = this.file.name;
    }
  }

  isFileFormatValid(name){
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() !== 'csv') {
      return false;
    }

    return true
  }

  public dragOver(event): void {
    if (event.dataTransfer.items.length > 0) {
      document.querySelectorAll('.drag-n-drop').item(0).classList.add('over');
    }
  }

  public dragOut(event): void {
    if (event.dataTransfer.items.length > 0) {
      document.querySelectorAll('.drag-n-drop').item(0).classList.remove('over');
    }
  }

  public upload() {
    this.uploadDocument.file = this.file;
    const fileFormat = this.createFile(this.uploadDocument);
    this.spinner.show();
    this.httpClient.post(environment.serverUrl + 'noborh/noborh-upload-file', fileFormat)
      .subscribe(result =>{
        if (result) {
          this.spinner.hide();
          this.dialogRef.close();
        }
      }, e => { 
        this.alertService.alert(e);
        this.spinner.hide();
      })  
  }

  createFile(document: NOBORHUploadDocumentDto): FormData {
    let formData: FormData = new FormData();
    formData.append("FileType", document.fileType);
    formData.append("ProjectId", document.projectId.toString());
    if(document.file){
      formData.append("File", document.file, document.file.name);
    }
    
    return formData;
  } 
}

export class NOBORHUploadDocumentDto{
  file: File;
  fileType: string;
  projectId:number;
}
