import {ContactGeneralService} from './../services/contact-general.service';
import {RoleGuardService} from '@app/auth/role-guard.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable, of, EMPTY, empty} from 'rxjs';
import {PpBreadcrumbsResolver, Breadcrumb} from 'pp-breadcrumbs';
import {mergeMap, catchError} from 'rxjs/operators';


@Injectable()
export class ContactsResolver extends PpBreadcrumbsResolver {
  constructor(private contactService: ContactGeneralService, private roleGuardService: RoleGuardService, private router: Router) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Promise<Breadcrumb[]> | Breadcrumb[] {
    const id = Number(route.params['id']);
    if (id === 0) {
      return of([{
        text: 'Add new Contact',
        path: this.getFullPath(route)
      }]);
    } else {
      return this.contactService.getContact(id).pipe(catchError(() => {
          return EMPTY;
        }),
        mergeMap(data => {
          if (data) {
            return of([{
              text: data.name,
              path: this.getFullPath(route)
            }]);
          } else {
            return EMPTY;
          }
        }));
    }
  }
}
