import { FilterDTO, IFilterAndOrder, PaginationDTO, SortDTO } from '@app/components/table-filter-wrapper/interfaces';
import { AppConstants } from '@app/app.constants';
import { isObject } from 'util';

export interface IVotingSummary {
  votingItemId: number;
  parentVotingItemId?: number;
  templateProperty1: string;
  templateProperty2: string;
  allShares: number;
  voted: number | VoteWithPercentage;
  notVoted: number | VoteWithPercentage;
  votes: ICustodianVote[];
  isParentVotingItem: boolean;
}

export class VotingSummaryAdapter {
  allShares: number;
  notVoted: number | VoteWithPercentage;
  parentVotingItemId: number;
  templateProperty1: string;
  templateProperty2: string;
  voted: number | VoteWithPercentage;
  votes: ICustodianVote[];
  votingItemId: number;
  votingItemChild: IVotingItemChild;
  isParentVotingItem: boolean;

  constructor(init?: Partial<IVotingSummary>) {
    Object.assign(this, init);
  }
}

export interface IVotingItemChild {
  parentVotingItemId: number;
  name: string;
  orderId: number;
}

export interface ICustodianVote {
  voteId: number;
  voteType: string;
  vote: number;
}

export class FilterAndOrderDTO implements IFilterAndOrder {
  filters: Array<FilterDTO> = [];
  pagination: PaginationDTO = new PaginationDTO();
  sort: SortDTO[] = [];

  constructor(init?: Partial<IFilterAndOrder>) {
    Object.assign(this, init);
  }
}

export class FilterVotsumOutputDTO {
  columns: Array<Array<FilterDTO>>;
  sort: SortDTO[];

  constructor(init?: Partial<FilterAndOrderDTO>) {
    this.columns = Object.values(this.groupByFieldName(init.filters));
    this.sort = init.sort || [];
  }

  groupByFieldName = array => array.reduce(
    (result, item) => {
      if (!result[item.fieldName]) {
        result[item.fieldName] = [];
      }
      result[item.fieldName].push(item);
      return result;
    }, []
  )
}

export class VoteWithPercentage {
  votes: number;
  percentage: string;

  constructor(votes, all) {
    this.votes = votes && isObject(votes) ? votes.vote : votes || 0;
    this.percentage = this.votes ? this.calcPercent(this.votes, all) : '0%';
  }

  calcPercent = (item, all) => {
    return `${(item === 0 || all === 0) ? 0 : ((item / all) * 100).toFixed(AppConstants.DECIMALS_FIX)}%`;
  }
}


