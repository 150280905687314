import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-proxy-advisor-dialog',
  templateUrl: './proxy-advisor-dialog.component.html',
  styleUrls: ['./proxy-advisor-dialog.component.scss']
})
export class ProxyAdvisorDialogComponent {
  public isFormValid = false;

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              private dialogRef: MatDialogRef<ProxyAdvisorDialogComponent>) { }


  onCloseBtnClick($event): void {
    this.dialogRef.close({data: $event});
  }

  setFormValidation(valid) {
    this.isFormValid = valid;
  }
}
