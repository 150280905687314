import {Component, OnInit, ViewChild, DoCheck, forwardRef, Input, Output, EventEmitter} from '@angular/core';
import { UserService } from '@app/services/user.service';
import { debounceTime } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { NgModel, ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, NG_VALIDATORS, FormControl, ValidationErrors } from '@angular/forms';
import { ActiveDirectoryUser } from '@app/dto/ActiveDirectoryUser';


const noop = () => {/* */
};

@Component({
  selector: 'app-active-directory-user-list',
  templateUrl: './active-directory-user-list.component.html',
  styleUrls: ['./active-directory-user-list.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActiveDirectoryUserListComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ActiveDirectoryUserListComponent),
      multi: true
    }]
})
export class ActiveDirectoryUserListComponent implements OnInit, DoCheck, ControlValueAccessor, Validator {
  @ViewChild('currentUserValue') public currentUserValue: NgModel;
  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public placeholder = 'Managed By';
  @Input() public applyClientEmailFilter: boolean = false;
  @Input() public allowBothUserAndClientSearch: boolean = false;
  @Input('floatLabel') public floatLabel = 'never';
  @Output() onSelectionChanged = new EventEmitter<ActiveDirectoryUser>();
  public filteredUsers: ActiveDirectoryUser[];
  private graphApiBaseUrl = 'https://graph.microsoft.com/v1.0/users';
  private graphApiAccessToken: string;
  @Input() innerValue;
  public selectedUserChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor(private userService: UserService, private httpClient: HttpClient) { }

  async ngOnInit() {
    if (!this.innerValue) {
      this.innerValue = { displayName: '', id: '', mail: '', userPrincipalName: '' };
    }
    this.userService.getGraphApiAccessToken().subscribe(tokenResponse => {
      this.graphApiAccessToken = tokenResponse.access_token;
      this.loadExistingUser();
      this.initializeFilters();
    });
  }

  doSearch(value) {
    this.selectedUserChanges.next(value);
    this.innerValue = { displayName: value, id: '', mail: '', userPrincipalName: '' };
  }

  loadExistingUser() {
    if (this.innerValue.id) {
      this.httpClient.get<{ value: ActiveDirectoryUser[] }>(`${this.graphApiBaseUrl}?$filter=id eq '${this.innerValue.id}'`,
        { headers: new HttpHeaders({ "Authorization": `Bearer ${this.graphApiAccessToken}` }) })
        .subscribe(data => {
          if (data.value.length != 1) {
            this.innerValue = { displayName: '', id: '', mail: '', userPrincipalName: '' };
          } else {
            this.innerValue = data.value[0];
          }
        });
    }
  }

  initializeFilters() {
    this.selectedUserChanges.pipe(
      debounceTime(500)
    ).subscribe((input) => {
      if (input) {
        this.httpClient.get<{ value: ActiveDirectoryUser[] }>(`${this.graphApiBaseUrl}?$filter=startswith(displayName,'${input}')`,
          { headers: new HttpHeaders({ "Authorization": `Bearer ${this.graphApiAccessToken}` }) })
          .subscribe(filteredResults => {
            //Graph API Does not support Not Equal operations,
            //therefore we manually need to remove users without emails
            let usersWithEmails = [];

            if (this.applyClientEmailFilter) {
              usersWithEmails = filteredResults.value.filter(x => x.userPrincipalName.includes('client'));
            } else if (this.allowBothUserAndClientSearch) {
              usersWithEmails = filteredResults.value;
            }
            else {
              usersWithEmails = filteredResults.value.filter(x => !x.userPrincipalName.includes('client'));
            }

            this.filteredUsers = usersWithEmails;
          })
      }
    });
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.onChangeCallback(v);
    }
  }

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value && value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngDoCheck() {
    this.onChangeCallback(this.value);
  }

  public onTouched() {
    this.onTouchedCallback();
  }

  onSelected(selectedUser: ActiveDirectoryUser) {
    this.innerValue = selectedUser;
    this.onSelectionChanged.emit(selectedUser);
  }

  displayFn(autoCompleteResult: ActiveDirectoryUser) {
    if (autoCompleteResult) {
      return autoCompleteResult.displayName;
    }
  }

  validate(control: FormControl): ValidationErrors {
    const valid = this.required && this.innerValue.id || !this.required;
    return valid ? null : { valid };
  }

  onBlur() {
    if (!this.innerValue.id) {
      this.innerValue.displayName = '';
      this.onSelectionChanged.emit({ displayName: '', id: '', mail: '', userPrincipalName: '' });
    }
  }

  public resetValue() {
    this.writeValue({ displayName: '', id: '', mail: '', userPrincipalName: '' });
  }
}
