import { ResponsibilityUserObject } from "./base/ResponsibilityUserObject";

export class ClearingHouse extends ResponsibilityUserObject {
    clearingHouseId: number;
    name: string;
    address: string;
    city: string;
    country: string;
    phone: string;
    website: string;
    operationalProcess: string;
}
