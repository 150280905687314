import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {StartupdataproviderService} from 'src/app/service/startupdataprovider.service';
import {ProjectFinancial} from 'src/app/dto/ProjectFinancial';
import {ProjectFinancialDetail} from 'src/app/dto/ProjectFinancialDetail';
import {Lookup} from 'src/app/dto/Lookup';
import {LookupFilter, Utils} from 'src/app/helper/utils';
import {ProjectsService} from 'src/app/services/projects.service';
import {AlertService} from 'src/app/services/alert.service';
import {ProjectFinancialEditDialogComponent} from '@app/project/projectdetail/project-financial/dialog/project-financial-edit-dialog.component';
import {RoleGuardService} from '@app/auth/role-guard.service';
import {DeleteProjectTemplate} from '@app/services/dialogs/dialog-delete-template.config';
import {DialogsService} from '@app/services/dialogs/dialogs.service';
import {NgForm} from '@angular/forms';
import { PROJECT_FINANCIAL_DETAILS_COMMENT_MAX_LENGTH } from '@app/project/project.constants';
import { AppConstants } from '@app/app.constants';

@Component({
  selector: 'app-project-financial',
  templateUrl: './project-financial.component.html',
  styleUrls: ['project-financial.component.scss']
})
export class ProjectFinancialComponent implements OnInit {

  displayedColumns: string[] = [
    'shareClassification',
    'numberOfShare',
    'exchangeRatio',
    'ordinaryEquivalent',
    'isin',
    'sedol',
    'cusip',
    'comment',
    'action'
  ];
  @ViewChild(MatSort) sort: MatSort;
  dataSource;
  public _SHARES = 'SHARES';
  model: ProjectFinancial;
  modelDetail: ProjectFinancialDetail;
  projectFinancialDetails: ProjectFinancialDetail[] = [];
  shareClassifications: Lookup[] = [];
  securityTypes: Lookup[] = [];
  projectId: number;
  canEditProject = false;
  canCreateProject = false;
  securityType;
  totalShares;
  noItemsText = 'Financial Activities';
  showGrid: boolean;
  public commentFieldMaxLength = PROJECT_FINANCIAL_DETAILS_COMMENT_MAX_LENGTH;
  public cusipLength = AppConstants.CUSIP_LENGTH;
  public isUsProject = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private startupdataproviderService: StartupdataproviderService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private projectService: ProjectsService,
    private roleGuardService: RoleGuardService,
    private dialogsService: DialogsService) {
    this.model = new ProjectFinancial();
    this.modelDetail = new ProjectFinancialDetail();
    this.modelDetail.exchangeRatio = 1;
    this.modelDetail.ordinaryEquivalent = 1;
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<ProjectFinancialDetail[]>();
    this.dataSource.sort = this.sort;
    this.route.parent.params.subscribe((params) => {
      this.projectId = +params['id'];

      this.projectService.getProjectType(this.projectId).subscribe(data => {
        this.canEditProject = this.roleGuardService.hasAccessByProjectType('projects', 'edit', data.name);
        // TODO: When PSC roles user can create a project use this to Add shares or type of security
        this.canCreateProject = this.roleGuardService.hasAccessByProjectType('projects', 'create', data.name);
      });

      this.projectService.getProject(this.projectId).subscribe((project) => {
        this.isUsProject = project.marketCoverage === 'US';
      });

      if (this.projectId > 0) {
        this.getProjectFinancial();
      }
      const util = new Utils(this.startupdataproviderService);
      util.getLookupByType(LookupFilter.SHARECLASSIFICATION).subscribe(r => this.shareClassifications = r);
      util.getLookupByType(LookupFilter.PRJSECTYPE).subscribe(r => this.securityTypes = r);
    });
  }

  onChange() {
    const isShareNumber = this.isValidNumber(this.modelDetail.numberOfShare);
    this.modelDetail.numberOfShare = isShareNumber ? +this.modelDetail.numberOfShare : null;

    const isExchangeRatioNumber = this.isValidNumber(this.modelDetail.exchangeRatio);
    this.modelDetail.exchangeRatio = isExchangeRatioNumber ? +this.modelDetail.exchangeRatio : 1;

    if (isShareNumber) {
      this.modelDetail.ordinaryEquivalent = this.modelDetail.numberOfShare * this.modelDetail.exchangeRatio;
    } else {
      this.modelDetail.ordinaryEquivalent = undefined;
    }
  }

  isValidNumber(valueAsString) {
    const value = +valueAsString;
    return !isNaN(value) && value > 0;
  }

  getProjectFinancial() {
    this.projectService.getProjectFinancial(this.projectId).subscribe(
      data => {
        this.model = new ProjectFinancial();
        this.model.securityType = this.securityType = data.securityType;
        this.model.totalShares = this.totalShares = data.totalShares;

        this.projectFinancialDetails = data.projectFinancialDetails;
        this.dataSource = new MatTableDataSource(this.projectFinancialDetails);
        this.showGrid = this.projectFinancialDetails.length > 0;
      });
  }

  isExistRecord() {
    // This is an additional validation if the combination shareClassification and ISIN is already exist into the list

    if (this.modelDetail && this.modelDetail.shareClassification) {
      // tslint:disable-next-line:max-line-length
      const foundRecord = this.projectFinancialDetails.find(row => row.shareClassification === this.modelDetail.shareClassification && row.isin === this.modelDetail.isin);
      if (foundRecord) {
        return true;
      }
    }
    return false;
  }


  resetForm(form: NgForm) {
    form.resetForm({ status: 'Not Started', responsibilityUser: {} });
    this.model = new ProjectFinancial();
    this.modelDetail = new ProjectFinancialDetail();
    this.modelDetail.exchangeRatio = 1;
    this.modelDetail.ordinaryEquivalent = 1;
    this.model.securityType = this.securityType;
    this.model.totalShares = this.totalShares;
  }

  onEdit(item) {
    const dialogRef = this.dialog.open(ProjectFinancialEditDialogComponent,
      {
        data:
        {
          model: { ...item, ...{ securityType: this.model.securityType } },
          projectFinancialDetails: this.projectFinancialDetails,
          isUsProject: this.isUsProject
        }
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.data.projectFinancialDetailId) {
          this.onSave(result.data);
        } else {
          this.onCreate(new ProjectFinancialDetail({...result.data, projectId: this.projectId}));
        }
      }
    });
  }

  onSave(projectFinancialDetail) {
    this.projectService.updateProjectFinancial(projectFinancialDetail).subscribe(
      data => {
        this.getProjectFinancial();
        this.alertService.sendSuccess('Project Financial has been updated successfully!');
      }, error => this.alertService.alert(error)
    );
  }

  onDelete(item) {
    this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: 'Share', message: item.shareClassification }) });
    this.dialogsService.close$.subscribe(async res => {
      if (res) {
        const result = await this.projectService.deleteProjectFinancial(this.projectId, item.projectFinancialDetailId);
        this.getProjectFinancial();
      } else {
        return true;
      }
    });
  }

  addFinancial() {
    const item = { ...new ProjectFinancialDetail({exchangeRatio: 1, ordinaryEquivalent: 1,  securityType: this.model.securityType })};
    this.onEdit(item);
  }

  onCreate(projectFinancialRecord) {
    this.projectService.addProjectFinancial(projectFinancialRecord).subscribe(
      data => {
        this.model = data;
        this.getProjectFinancial();
        this.alertService.sendSuccess('Created Project Financial !');
      }, error => this.alertService.alert(error)
    );
  }
}
