import {Component, DoCheck, forwardRef, Input, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import {StateService} from '@app/services/state.service';
import {NameValueModel} from '@app/models/basic-structures';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel, ValidationErrors, Validator} from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import {Utils} from '@app/helper/utils';
import {StartupdataproviderService} from '@app/service/startupdataprovider.service';
import {distinctUntilChanged, startWith, debounceTime } from 'rxjs/operators';

const noop = () => {/* */
};

@Component({
  selector: 'app-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatesComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => StatesComponent),
      multi: true
    }
  ]
})
export class StatesComponent implements OnInit, DoCheck, ControlValueAccessor, Validator {

  @ViewChild('currentStateValue') public currentStateValue: NgModel;
  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public placeholder:string;
  @Input()
  set country(country) {
    if (this.countryValue !== '' && this.countryValue !== country) {
      this.innerValue = undefined;
    }

    this.countryValue = country;
    const _country = this.util.getCountryByValue(this.countryValue);
    if (typeof _country.iso !== 'undefined') {
      this.countrySelected(_country.iso);
    }
  }

  @Input()
  set billingCountry(billingCountry) {
    if (this.billingCountryValue !== '' && this.billingCountryValue !== billingCountry) {
      this.innerValue = undefined;
    }

    this.billingCountryValue = billingCountry;
    const _country = this.util.getCountryByValue(this.billingCountryValue);
    if (typeof _country.iso !== 'undefined') {
      this.stateService.getStates(_country.iso, true);
    }
  }

  private countryValue = '';
  private billingCountryValue = '';
  private innerValue: any = undefined;
  public states: NameValueModel []= [];
  statesForView : NameValueModel []= [];
  public countryStates$: Observable<NameValueModel[]>;
  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  private util;
  public stateValueChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Output() onSelectionChanged = new EventEmitter<NameValueModel>();
  
  constructor(private stateService: StateService, private startupdataproviderService: StartupdataproviderService) {
    this.util = new Utils(this.startupdataproviderService);
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v && v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value !== this.innerValue) {
      if (value) {
        this.innerValue = value;
      } else {
        this.innerValue = undefined;
      }
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? Number(c1.value) === c2 : c1 === c2;
  }

  ngDoCheck() {
    this.onChangeCallback(this.value);
  }

  ngOnInit() {
    if (!this.innerValue) {
      this.innerValue = undefined;
    }
  
    this.countryStates$ = this.stateService.countryStates$;
    this.stateService.selectedCountry('');
    this.stateValueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      startWith(''))
      .subscribe(() => {
      this.states= [];
      this.countryStates$.forEach(s=> this.states.push(...s));
    });
    this.initializeFilters();
  }

  initializeFilters() {
    this.countryStates$.pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.statesForView = this.filterState('');
    });
  }

  doSearch(value) {
    this.stateValueChanges.next(value);
    this.statesForView = this.filterState(value).map(data=> ({name: data.name, value:data.value}));
  }

  countrySelected(selectedcountry) {
    this.stateService.selectedCountry(selectedcountry, true);
  }

  onSelected(selectedState: NameValueModel) {
    this.innerValue = selectedState.name;
    this.onSelectionChanged.emit(selectedState);
  }

  validate(control: FormControl): ValidationErrors {
    const valid = this.currentStateValue && this.currentStateValue.valid;
    return valid ? null : {valid};
  }

  private filterState(value: string): NameValueModel[]{
    if(value){
    const filterValue = value.toLocaleLowerCase();
    return this.states.filter(x=> x.name.toLocaleLowerCase().indexOf(filterValue)===0);
    }
    else{
      return this.states;
    }
  }

  public onTouched() {
    this.onTouchedCallback();
  }
}
