import {Component, OnInit} from '@angular/core';
import {ContactRelatedEntityType} from '@app/enums/ContactRelatedEntityTypeEnum';
import {ActivatedRoute} from '@angular/router';
import {AlertService} from '@app/services/alert.service';
import {AdminEsgService} from '@app/services/admin/admin-esg.service';
import {Esg} from '@app/dto/Esg';
import {ContactType} from '@app/enums/ContactTypeEnum';

@Component({
  selector: 'app-esg-pa-contacts',
  templateUrl: './esg-pa-contacts.component.html',
  styleUrls: ['./esg-pa-contacts.component.scss']
})
export class EsgPaContactsComponent implements OnInit {

  contactType: ContactType;
  contactRelatedEntityType: ContactRelatedEntityType;
  esgId: number;
  model: Esg = new Esg();

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private adminEsgService: AdminEsgService) { }

  ngOnInit() {
    this.contactType = ContactType.Esg;
    this.contactRelatedEntityType = ContactRelatedEntityType.Esg;
    this.route.parent.params.subscribe((params) => {
      this.esgId = +params['id'];
    });

    this.adminEsgService.get(this.esgId).subscribe(data => {
      this.model = data;
    },
    err => {
      this.alertService.sendError(' error ' + JSON.stringify(err));
    });
  }
}
