import {Component, Input, OnInit} from '@angular/core';
import {AuditLogsService} from '@app/services/audit-logs.service';
import { AuditLogGroupedDTO} from '@app/dto/AuditLogDTO';

@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['audit-logs.component.scss']
})

export class AuditLogsComponent implements OnInit {

  @Input() params: any;
  @Input() isOpen: boolean;
  @Input() isBeneficiary: boolean;
  auditLogsData: AuditLogGroupedDTO[] = [];

  constructor(private auditLogsService: AuditLogsService) {

  }

  ngOnInit() {
    this.auditLogsService.getAuditLog(this.params).subscribe((result) => {
      this.auditLogsData = this.groupLogs(result);
    });
  }


  groupLogs = (logs) => {
    const groups = logs.reduce((_groups, log) => {
      const date = log.auditDate.split('T')[0];
      if (!_groups[date]) {
        _groups[date] = [];
      }
      _groups[date].push(log);
      return _groups;
    }, {});


    return Object.keys(groups).map((date) => {
      return {
        date,
        logs: groups[date]
      };
    }).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .map(group => {
        if (group.date === new Date().toISOString().split('T')[0]) {
          group.date = 'TODAY';
        }
        return group;
      });
  }
}
