import { Component } from '@angular/core';
import { NumberRangeFilterFormComponent } from '../number-range-filter-form/number-range-filter-form.component';
import { FormBuilder } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-date-range-filter-form',
  templateUrl: './date-range-filter-form.component.html',
  styleUrls: ['./date-range-filter-form.component.scss']
})
export class DateRangeFilterFormComponent extends NumberRangeFilterFormComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    fb: FormBuilder,
  ) {
    super(fb);

    this.matIconRegistry.addSvgIcon(
      'icon_calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/navigation-icons/calendar.svg')
    );
  }
}
