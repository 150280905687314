import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of, EMPTY} from 'rxjs';
import {PpBreadcrumbsResolver, Breadcrumb} from 'pp-breadcrumbs';
import {catchError, mergeMap} from 'rxjs/operators';

@Injectable()
export class PowerBIResolver extends PpBreadcrumbsResolver {
  constructor() {
    super();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Promise<Breadcrumb[]> | Breadcrumb[] {
    const reportId = route.params['id'];
    if (reportId) {
      return of([{
        text: `Report ${reportId}`,
        path: this.getFullPath(route)
      }]);
      } else {
        return EMPTY;
    }

  }
}
