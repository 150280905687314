import {Component, Inject, OnInit} from '@angular/core';
import {ProjectInvestorDataAddDto} from '@app/dto/ProjectInvestorDataAddDto';
import {Observable} from 'rxjs';
import {InvestorDataLookupDto} from '@app/dto/InvestorDataLookupDto';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {ProjectInvestorDataService} from '@app/services/project-investor-data.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {EditInvestorDialogComponent} from '@app/project/projectdetail/prjinvdata/dialog/edit-investor-dalog/edit-investor-dialog.component';
import {InvestorShareCapitalItem, InvestorSharesKeyValuePairDto, InvestorSourceKeyValuePairDto} from '@app/dto/ProjectInvestorAUData';
import {AlertService} from '@app/services/alert.service';
import {ActiveDirectoryUser} from '@app/dto/ActiveDirectoryUser';

@Component({
  selector: 'app-add-edit-investor-dialog',
  templateUrl: './add-edit-investor-dialog.component.html',
  styleUrls: ['./add-edit-investor-dialog.component.scss']
})
export class AddEditInvestorDialogComponent implements OnInit {
  model: ProjectInvestorDataAddDto = new ProjectInvestorDataAddDto({ responsibilityUser: new ActiveDirectoryUser() });
  shareSources: InvestorSourceKeyValuePairDto[] = [];
  shareTypes: InvestorSharesKeyValuePairDto[] = [];
  investorShareCapitalItems: InvestorShareCapitalItem[] = [];
  investorDataControl = new FormControl();
  investorDataControlFilteredOptions: Observable<InvestorDataLookupDto[]> = new Observable<InvestorDataLookupDto[]>();
  isInvestorDataSelectChange = false;
  isNew = true;

  constructor(private projectInvestorDataService: ProjectInvestorDataService,
              private dialogRef: MatDialogRef<EditInvestorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private alertService: AlertService) {
    this.shareSources = data.sourceKeyValuePairs;
    this.shareTypes = data.shareTypeKeyValuePairs;
    if (data.item) {
      const _d = JSON.parse(JSON.stringify(this.data.item))
      this.model = {..._d, ...{investorShareCapitalItems: _d.shareCapitalItems}} ;
      if (this.model.city) {
        this.model.investor += ` (${this.model.city})`;
      }
      this.isNew = false;
    } else {
      this.model.investorShareCapitalItems = [];
      this.buildTheGrid();
    }
  }


  ngOnInit() {
    this.investorDataControl.valueChanges
      .pipe(debounceTime(500)).subscribe((input) => {
      this.filterInvestorData(input);
    });
  }

  private buildTheGrid(){
    this.shareSources.forEach((source, index) => {
      this.shareTypes.forEach(type => {
        const item = {
          sourceId: source.id,
          source: source.name,
          shareTypeId: type.id,
          shareType: type.name,
          shareCapital: 0,
          isPrimarySource: !!source.isPrimarySource
        };
        this.model.investorShareCapitalItems.push(item);
      });
    });
  }


  public getShareCapitalValue = (source, type) => (this.model.investorShareCapitalItems.find(item => item.sourceId === source.id && item.shareTypeId === type.id) || {shareCapital: 0}).shareCapital;

  public setShareCapitalValue(value, source, type) {
    const shareCapitalItem = this.model.investorShareCapitalItems.findIndex(item => item.sourceId === source.id && item.shareTypeId === type.id)
    if (shareCapitalItem > -1) {
      this.model.investorShareCapitalItems[shareCapitalItem].shareCapital = +value;
    }
  }

  private filterInvestorData(value: string): void {
    // The method is fired after an investor is selected from the auto-complete
    if (this.isInvestorDataSelectChange) {
      this.isInvestorDataSelectChange = false;
      return;
    }

    if (!value || value.length < 2) {
      this.investorDataControlFilteredOptions = new Observable<InvestorDataLookupDto[]>();
      this.model.investorId = undefined;
      return;
    }

    this.investorDataControlFilteredOptions = this.projectInvestorDataService
    .getInvestorData(this.data.projectId, value);
  }

  onBlur() {
    if (!this.model.investorId) {
      this.model.investorId = undefined;
      this.model.investor = '';
      this.model.city = '';
    }
  }

  onSelected(item){
    this.isInvestorDataSelectChange = true;
    this.model.investorId = item.investorId;
    this.model.investor = item.name + (item.location ? ` ( ${item.location} )` : '');
    this.model.city = item.location;
    if (item.responsibilityUser) {
      this.model.responsibilityUser.displayName = item.responsibilityUser.displayName;
      this.model.responsibilityUser.id = item.responsibilityUser.id;
      this.model.responsibilityUser.mail = item.responsibilityUser.mail;
      this.model.responsibilityUser.userPrincipalName = item.responsibilityUser.userPrincipalName;
    }
  }

  onSaveBtnClick() {
    this.dialogRef.close(this.model);
  }
}

