import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { InvestorInitiative } from '@app/dto/InvestorInitiative';
import { Initiative } from '@app/dto/Initiative';
import { MatSelectChange, MatOptionSelectionChange } from '@angular/material';
import { RegionCountryMultiselectHelper } from '@app/helper/RegionCountryMultiselectHelper';
import { NgModel } from '@angular/forms';
import { AppConstants } from '@app/app.constants';
import { DatePipe } from '@angular/common';
import { DocumentConfiguration } from '@app/components/documents/document-configuration';
import { DocumentRelatedEntityType } from '@app/enums/DocumentRelatedEntityType';
import { OptionsPickerModel } from '@app/dto/OptionsPicker';

@Component({
  selector: 'app-investor-initiative-fields',
  templateUrl: './investor-initiative-fields.component.html',
  styleUrls: ['./investor-initiative-fields.component.scss']
})
export class InvestorInitiativeFieldsComponent {
  @Input() isInPopup: boolean = true;
  @Input() disableForm: boolean;
  @Input() impactsList: string[];
  @Input() model: InvestorInitiative;
  @Input() sectors: string[];
  @Input() groupedCountries: { [key: string]: string[]; };
  @Input() thematicFocusPickerData = {
    noRecordsText: 'There are still not thematic focus selected',
    noRecordsLink: 'Edit the thematic focus...',
    allRecords: [],
    selectedRecords: []
  };
  @Input() showDocuments;
  @Input() areDocumentsInMemory: boolean;
  @Input() createdEntityId?: number;
  @Input() documentConfiguration: DocumentConfiguration;
  @Output() modelChange: EventEmitter<InvestorInitiative> = new EventEmitter<InvestorInitiative>();
  documentRelatedEntityType: DocumentRelatedEntityType = DocumentRelatedEntityType.Initiative;

  hasDeactivatedInitiative: boolean = false;
  regionalSectorSelectionEvent: MatOptionSelectionChange;

  constructor(
    public datePipe: DatePipe
  ) {

  }

  changeActive(value: boolean) {
    if (!value) {
      this.hasDeactivatedInitiative = true;
    } else {
      this.hasDeactivatedInitiative = false;
    }
  }

  formatDeactivatedDate() {
    if (!this.model.activated) {
      const dateToFormat = this.hasDeactivatedInitiative ? new Date() : this.model.deactivatedDate;
      const date = this.datePipe.transform(dateToFormat, 'dd MMM yyyy h:mm a');
      const formattedDate = `Inactive since: ${date}`;
      return formattedDate;
    }

    return "";
  }

  getContinentLabel(region: string) {
    return AppConstants.continentLabelMapping[region];
  }

  // country changed
  onCountrySelectionChange(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.regionalSectorSelectionEvent = event;
    }
  }

  // continent change
  onContinentSelectionChange(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.regionalSectorSelectionEvent = event;
    }
  }

  // select change
  selectionChange(event: MatSelectChange, select: NgModel) {
    const value: string = this.regionalSectorSelectionEvent.source.value;
    const isRegion = !!this.groupedCountries[this.regionalSectorSelectionEvent.source.value];
    const isSelected: boolean = this.regionalSectorSelectionEvent.source.selected;
    const resultToEmit = new Set<string>(event.value);
    if (isRegion) {
      RegionCountryMultiselectHelper.handleRegionSelection(this.groupedCountries, resultToEmit, value, isSelected);
    } else {
      RegionCountryMultiselectHelper.handleCountrySelection(this.groupedCountries, resultToEmit, value, isSelected);
    }
    const result = Array.from(resultToEmit);
    select.update.emit(result);
  }

  getDisplayName(select: NgModel) {
    return RegionCountryMultiselectHelper.getDisplayName(select.model, this.groupedCountries)
  }

  onThematicFocusSelection(event: any){
    this.thematicFocusPickerData = event;
    this.model.thematicFocuses = [];
    for (const option of this.thematicFocusPickerData.selectedRecords) {
      this.model.thematicFocuses.push(option.id);
    }
  }
}