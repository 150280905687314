import { AlertService } from '@app/services/alert.service';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuditLogDTO} from '@app/dto/AuditLogDTO';
import { mergeMap } from 'rxjs/operators';
import {AuditEntityParentType} from '@app/enums/AuditEntityParentTypeEnum';

@Injectable({
  providedIn: 'root'
})
export class AuditLogsService {

  constructor(private http: HttpClient, private alertService: AlertService) {
  }

  public getAuditLog(data: any): Observable<any> {

    if (data.auditEntityParentType === AuditEntityParentType.PROJECT_INVESTOR_DATA) {
      return from(this.getProjectInvestorDataId(data)).pipe(mergeMap(parentId => {
        return this.http.get<AuditLogDTO>(`${environment.serverUrl}AuditLog/for-parent/${data.auditEntityParentType}/${parentId}`);
      }));
    } else if(data.auditEntityParentType==AuditEntityParentType.ASSET_MANAGER || 
              data.auditEntityParentType==AuditEntityParentType.FUND || 
              data.auditEntityParentType == AuditEntityParentType.INVESTOR_BENEFICIARY){
      return this.http.get<AuditLogDTO>(`${environment.serverUrl}AuditLog/for-entity-children/${data.auditEntityParentType}/${data.parentId}`);
    } else {
          return this.http.get<AuditLogDTO>(`${environment.serverUrl}AuditLog/for-parent/${data.auditEntityParentType}/${data.parentId}`);
    }
  }

  private getProjectInvestorDataId({projectId, investorId}): Promise<number> {
    return this.http.get<number>(`${environment.serverUrl}projects/project-investor-data-id/${projectId}/${investorId}`).toPromise();
  }

}
