import { Component, DoCheck, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgModel,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { NameValueModel } from '../../../models/basic-structures';
import { debounceTime } from 'rxjs/operators';
import { MasterDataService } from '../../../services/master-data.service';
import { BehaviorSubject } from 'rxjs';

const noop = () => {/* */
};

@Component({
  selector: 'app-city-autocomplete',
  templateUrl: './city-autocomplete.component.html',
  styleUrls: ['./city-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CityAutocompleteComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CityAutocompleteComponent),
      multi: true
    }
  ]
})
export class CityAutocompleteComponent implements OnInit, DoCheck, ControlValueAccessor, Validator {

  private countryValue: string;

  @ViewChild('currentCityValue') public currentCityValue: NgModel;
  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public placeholder = 'City';
  // Preselected value for country
  @Input()
  set country(country) {
    this.countryValue = !country ? '' : (typeof country === 'string' ? country : country.name);
    this.filteredResults = [];
    this.innerValue = undefined;
  }
  @Output() onSelectionChanged = new EventEmitter<NameValueModel>();
  cityFilterForm: FormGroup;
  filteredResults: NameValueModel[];
  preselectedValue: NameValueModel;
  selectedValue: NameValueModel;

  private innerValue: any = undefined;

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  public cityValueChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private fb: FormBuilder, private masterDataService: MasterDataService) {
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.onChangeCallback(v);
    }
  }

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  validate(control: FormControl): ValidationErrors {
    const valid = this.currentCityValue && this.currentCityValue.valid;
    return valid ? null : { valid };
  }

  ngDoCheck() {
    this.onChangeCallback(this.value);
  }

  doSearch(value) {
    this.cityValueChanges.next(value);
  }

  public onTouched() {
    this.onTouchedCallback();
  }

  ngOnInit() {
    if (!this.innerValue) {
      this.innerValue = undefined;
    }
    this.initializeFilters();
  }

  initializeFilters() {
    this.cityValueChanges.pipe(
      debounceTime(500)
    ).subscribe((input) => {
      this.masterDataService.searchForCity(input, this.countryValue)
        .subscribe((response) => {
          if (response) {
            this.filteredResults = response;
          }
        });
    });
  }

  onSelected(selectedCity: NameValueModel) {
    if (selectedCity) {
      this.innerValue = selectedCity;
    }
    else {
      selectedCity = this.innerValue;
    }
    this.onSelectionChanged.emit(selectedCity);
  }

  onBlur(value) {
    if (!value) {
      this.innerValue = undefined;
      this.onSelectionChanged.emit({ name: "", value: "" });
    }
  }

  displayFn(autoCompleteResult: NameValueModel) {
    if (autoCompleteResult) {
      this.innerValue = autoCompleteResult;
      if (typeof (autoCompleteResult) === 'object') {
        return autoCompleteResult.name;
      }
      else {
        return autoCompleteResult;
      }
    }
  }
}
