import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ClassificationsService} from 'src/app/services/classifications.service';
import {ResolutionClassificationItemDTO} from 'src/app/dto/ResolutionClassificationItemDTO';
import {AlertService} from 'src/app/services/alert.service';

@Component({
  selector: 'app-resolution-classification-general',
  templateUrl: './resolution-classification-general.component.html',
  styleUrls: ['./resolution-classification-general.component.scss']
})

export class ResolutionClassificationGeneralComponent implements OnInit {
  classificationId;
  model: ResolutionClassificationItemDTO;

  constructor(private route: ActivatedRoute,
              private classificationsService: ClassificationsService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.route.parent.params.subscribe((params) => {
      this.classificationId = +params['id'];

      this.getClassificationRecord();
    });
  }

  getClassificationRecord() {
    this.classificationsService.getClassificationRecord(this.classificationId).subscribe(data => {
        this.model = data;
      },
      err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      }
    );
  }
}
