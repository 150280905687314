import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, forwardRef, DoCheck, AfterViewInit } from '@angular/core';

const noop = () => {};

@Component({
  selector: 'app-website-input-field',
  templateUrl: './website-input-field.component.html',
  styleUrls: ['./website-input-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WebsiteInputFieldComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WebsiteInputFieldComponent),
      multi: true
    }
  ]
})
export class WebsiteInputFieldComponent implements OnInit, DoCheck, ControlValueAccessor,Validator, AfterViewInit  {
  @ViewChild('web') public web : NgModel;
  @ViewChild('webForm') webForm: any;
  @Input() placeholder : string = "Website";
  @Input() disabled : boolean = false;
  @Input() required: boolean = false;
  @Output() public onWebsiteChanges = new EventEmitter<string[]>();
  @Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  private innerValue;
  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback: () => void = noop;


  constructor() { }

  ngAfterViewInit(): void {
    this.webForm.statusChanges.subscribe(value => {
      const valid = value === 'VALID';
      this.isFormValid.emit(valid);
    });
  }
  
  ngOnInit() {
    if (!this.innerValue) {
      this.innerValue = "";
    }
  }

  validate(control: FormControl): ValidationErrors {
    const valid = (this.required && this.innerValue) || !this.required;
    return valid ? null : { valid };
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.onChangeCallback(v);
    }
  }

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value && value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngDoCheck() {
    this.onChangeCallback(this.value);
  }

  public onTouched() {
    this.onTouchedCallback();
  }

  onChange(value){
    this.onWebsiteChanges.emit(value);
    this.innerValue=value;
  }
}
