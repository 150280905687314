import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelectChange } from '@angular/material';
import { VotingItemCreateEditData } from './VotingItemCreateEditData';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { AlertService } from '@app/services/alert.service';
import { VotingItemsService } from '@app/services/voting-items.service';
import { VotingItemEditDto } from '@app/dto/VotingItemDto';
import { CustomFieldType } from '@app/enums/CustomFieldType';
import { ResolutionClassificationDTO } from '@app/dto/ResolutionClassificationDTO';
import { IdName } from '@app/investor/investordetail/investor-intel-esg/investor-intel-esg-survey/IdName';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Lookup } from '@app/dto/LookUp';
import { VotingItemsTypesEnum } from '@app/enums/VotingItemsTypesEnum';

@Component({
  selector: 'app-voting-item-create-edit-dlg',
  templateUrl: './voting-item-create-edit-dlg.component.html',
  styleUrls: ['./voting-item-create-edit-dlg.component.scss']
})
export class VotingItemCreateEditDlgComponent implements OnInit {
  componentHeader: string;
  itemName: string;
  templateProp1Label: string;
  templateProp2Label: string;
  model: VotingItemEditDto = new VotingItemEditDto();
  modelLoaded = false;
  eCustomFieldType = CustomFieldType;
  votingItemTypes: IdName[] = [];
  boardOfDirectorsRecommendations: Lookup[] = [];
  VOTING_ITEM_DIRECTOR = VotingItemsService.VOTING_TYPE_DIRECTOR;
  private defaultVotingItemTypeName = VotingItemsService.DEFAULT_VOTING_ITEM_TYPE_NAME;
  votingItemsTypesEnum = VotingItemsTypesEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VotingItemCreateEditData,
    private dialogRef: MatDialogRef<VotingItemCreateEditDlgComponent>,
    public projectTemplateService: ProjectTemplateService,
    private alertService: AlertService,
    private votingItemsService: VotingItemsService) {
  }

  ngOnInit() {
    if (this.data.action === 'Add') {
      this.model.projectId = this.data.projectId;
      this.model.templateProperty1 = this.data.nextItemNumber;
      this.modelLoaded = true;

      this.votingItemsService.getVotingItemTypes(this.model.projectId).subscribe(data => {
        this.votingItemTypes = data;
        this.setInitialVotingType();
      });

    } else {
      combineLatest([
        this.votingItemsService.getVotingItemTypes(this.data.projectId),
        this.votingItemsService.getVotingItem(this.data.votingItemId, this.data.projectId)
      ]).pipe(map(results => ({
        votingItemTypes: results[0], votingItem: results[1]
      }))).subscribe(data => {
        this.votingItemTypes = data.votingItemTypes;
        this.model = data.votingItem;
        this.modelLoaded = true;
        this.resolutionTypeChange(new MatSelectChange(null, data.votingItem.votingItemTypeId));
      }), err => {
        this.alertService.alert(err);
      };
    }
    this.prepareLabels();
  }

  setInitialVotingType() {
    const standartVotingItem = this.votingItemTypes.find(
      (votingItemType) => votingItemType.name.toLowerCase() === this.defaultVotingItemTypeName);

    if (standartVotingItem) {
      this.model.votingItemTypeId = standartVotingItem.id;
    }

    if (this.votingItemTypes.length === 1) {
      this.model.votingItemTypeId = this.votingItemTypes[0].id;
    }
    this.boardOfDirectorsRecommendations =
      this.projectTemplateService.getIntentionTypeWithoutSplitVote(this.data.projectTemplate, [], true);
  }

  onSave() {
    this.model.votingItemType = (this.votingItemTypes.find(item => item.id === this.model.votingItemTypeId) || { name: '' }).name;
    if (this.VOTING_ITEM_DIRECTOR !== this.model.votingItemTypeId) {
      this.model.votingItemChildren = [];
    }
    const request = this.data.action === 'Add' ? this.votingItemsService.addVotingItem(this.model) :
      this.votingItemsService.editVotingItem(this.model);

    request.subscribe(data => {
      if (data) {
        this.alertService.sendSuccess(`${this.itemName} is ${this.data.action === 'Add' ? 'Created' : 'Updated'}.`);
        this.dialogRef.close();
      }
    },
      err => this.alertService.alert(err));
  }

  classificationIdChanged(classification: ResolutionClassificationDTO) {
    this.model.issClassificationId = classification.issClassificationId;
    if (classification.issClassificationId) {
      this.votingItemsService.getClassificationTemplateFields(classification.issClassificationId, this.model.projectId)
        .subscribe(data => {
          this.model.customFields = data;
        },
          err => { this.alertService.sendError(' error ' + JSON.stringify(err)); });
    }
  }

  public resolutionTypeChange(change: MatSelectChange) {
    switch (change.value) {
      case this.votingItemsTypesEnum.Frequency_123:
        this.boardOfDirectorsRecommendations =
          this.projectTemplateService.getFrequencyVoteTypes(true, null, [], true) as Lookup[];
        break;
      case this.votingItemsTypesEnum.Frequency_321:
        this.boardOfDirectorsRecommendations =
          this.projectTemplateService.getFrequencyVoteTypes(true) as Lookup[];
        break;
      default:
        this.boardOfDirectorsRecommendations =
          this.projectTemplateService.getIntentionTypeWithoutSplitVote(this.data.projectTemplate, [], true);
        break;
    }
  }

  private prepareLabels() {
    const labels = this.projectTemplateService.getProjectItemsTemplateLabels(this.data.projectTemplate);
    this.templateProp1Label = labels.templateProp1Label;
    this.templateProp2Label = labels.templateProp2Label;

    if (this.data.projectTemplate === this.projectTemplateService.CORPORATE_ACTION_TEMPLATE_NAME) {
      this.itemName = 'Bid';
    } else {
      this.itemName = 'Resolution';
      this.templateProp2Label += ' Name';
    }

    this.componentHeader = this.data.action === 'Add' ? 'Add New ' : 'Edit ';
    this.componentHeader += this.itemName;
  }
}
