import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {ProjectInvestorAUDataRecord, ProjectInvestorAUDataUploadResponse,} from '../../../dto/ProjectInvestorAUData';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {Lookup} from 'src/app/dto/Lookup';
import {StaticLookup} from '../../../helper/staticlookup';

import {UserService} from 'src/app/services/user.service';
import {ProjectInvestorDataAddDto} from '@app/dto/ProjectInvestorDataAddDto';
import {AlertService} from '@app/services/alert.service';
import {ProjectInvestorDataService} from '@app/services/project-investor-data.service';
import {InvestorDataLookupDto} from '@app/dto/InvestorDataLookupDto';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {MatchInvestorDialogComponent} from './dialog/match-investor-dialog.component';
import {ProjectInvestorUploadDialogComponent} from './project-investor-upload-dialog/project-investor-upload-dialog.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {AddEditInvestorDialogComponent} from '@app/project/projectdetail/prjinvdata/dialog/add-edit-investor-dialog/add-edit-investor-dialog.component';
import {InvestorSharesKeyValuePairDto, InvestorSourceKeyValuePairDto} from '@app/dto/ProjectInvestorAUData';
import {RoleGuardService} from '@app/auth/role-guard.service';
import {DeleteProjectTemplate} from '@app/services/dialogs/dialog-delete-template.config';
import {DialogsService} from '@app/services/dialogs/dialogs.service';
import {ExportService} from '@app/service/export.service';
import {ActiveDirectoryUser} from '@app/dto/ActiveDirectoryUser';
import { ProjectsService } from '@app/services/projects.service';

@Component({
  selector: 'app-project-investor-au-data',
  templateUrl: './project-investor-au-data.component.html',
  styleUrls: ['project-investor-au-data.component.scss']
})
export class ProjectInvestorAUDataComponent implements OnInit {
  arrayBuffer: any;
  file: File;
  dataSourceMatched = new MatTableDataSource();
  @ViewChild('sortMatched') sortMatched: MatSort;

  dataSourceUnmatched = new MatTableDataSource();
  @ViewChild('sortUnmatched') sortUnmatched: MatSort;

  columnsMatched: string[] = [];
  columnsUnmatched: string[] = [];
  headerRowColumns: string[] = [];
  shareSources: string[] = [];
  shareTypes: string[];
  shareColGrpDef: any[] = [];

  routeSub: any;
  projectid: number;
  projectname: string;
  updateby: number;
  active: number;
  xlxstmpls: Lookup[] = [];
  invuploadUsagedataTypes: Lookup[] = [];
  showUsageDataType: boolean;

  staticLookup: StaticLookup;
  selectedTemplate: string;
  selectedUsageDataType: string;

  isEdit: boolean = false;
  showUploadFileStat: boolean = false;
  showAddDetail: boolean = false;

  model: ProjectInvestorAUDataRecord;
  version: number;

  userid: number;
  rolename: string;
  coverage: string;

  modelDto: ProjectInvestorDataAddDto = new ProjectInvestorDataAddDto({responsibilityUser: new ActiveDirectoryUser()});
  investorDataControl = new FormControl();
  investorDataControlFilteredOptions: Observable<InvestorDataLookupDto[]> = new Observable<InvestorDataLookupDto[]>();
  isInvestorDataSelectChange: boolean = false;
  shareTypeKeyValuePairs: InvestorSharesKeyValuePairDto[] = [];
  sourceKeyValuePairs: InvestorSourceKeyValuePairDto[] = [];
  canEditProject = false;
  canCreateProject = false;

  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private userService: UserService,
              private projectInvestorDataService: ProjectInvestorDataService,
              public dialog: MatDialog,
              private alertService: AlertService,
              private spinnerService: NgxSpinnerService,
              private roleGuardService: RoleGuardService,
              private dialogsService: DialogsService,
              private exportService: ExportService,
              private projectService: ProjectsService) {
    this.staticLookup = new StaticLookup();
    this.model = new ProjectInvestorAUDataRecord();
  }

  ngOnInit() {
    this.xlxstmpls = this.staticLookup.getInvestorUploadExcelTemplates();
    this.invuploadUsagedataTypes = this.staticLookup.getInvestorUploadUsageDataType();
    this.updateby = 1;
    this.active = 1;
    this.userid = 0;
    this.rolename = this.userService.getUserRoleName();
    this.coverage = this.userService.getUserCoverage();
    this.routeSub = this.route.parent.params.subscribe((params) => {
      this.projectid = +params['id'];

      this.projectService.getProjectType(this.projectid).subscribe(data =>{
        this.canEditProject = this.roleGuardService.hasAccessByProjectType('projects', 'edit', data.name);
        this.canCreateProject = this.roleGuardService.hasAccess('projects', 'create');
      })
    });

    this.getProjectInvestorAUDataByProject();

  }

  getProjectInvestorAUDataByProject() {
    this.spinnerService.show();
    let apiURL: string = environment.serverUrl + 'projects/project-investor-au-data-response/' + this.projectid;
    this.httpClient.get<ProjectInvestorAUDataUploadResponse>(apiURL).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe(data => {

      this.dataSourceMatched = new MatTableDataSource();
      this.dataSourceUnmatched = new MatTableDataSource();
      this.dataSourceMatched.data = data.matchedData;
      this.dataSourceMatched.sort = this.sortMatched;
      this.dataSourceUnmatched.data = data.unmatchedData;
      this.dataSourceUnmatched.sort = this.sortUnmatched;
      this.shareSources = data.shareSources;
      this.shareTypeKeyValuePairs = data.shareTypeKeyValuePairs;

      //Order the Share types with the Ordinary shares first.
      //The rest can sort themselves out whichever way they please.
      this.shareTypes = data.shareTypes.reduce((acc, element) => {
        if (element.toUpperCase().includes('ORDINARY')) {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      this.sourceKeyValuePairs = data.sourceKeyValuePairs.reduce((acc, element) => {
        if (element.name.toUpperCase().includes('ORDINARY')) {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      this.buildColumnDefinitions();
    });
  }

  buildColumnDefinitions() {
    this.columnsMatched = ['investor', 'assetManager', 'fund', 'fileInvestor', 'responsibility', 'city'];
    this.columnsUnmatched = ['fileInvestor', 'responsibility', 'city'];

    this.headerRowColumns = ['group-row-common'];
    this.shareColGrpDef = [];

    this.shareSources.forEach(source => {
      this.headerRowColumns.push(`source_${source}`);
      this.shareTypes.forEach(shareType => {
        let colDef = {source: source, type: shareType};
        this.shareColGrpDef.push(colDef);
        this.columnsMatched.push(`shareCol_${colDef.source}_${colDef.type}`);
        this.columnsUnmatched.push(`shareCol_${colDef.source}_${colDef.type}`);
      });
    });
    this.columnsMatched.push('action');
    this.columnsUnmatched.push('action');
    this.headerRowColumns.push('group-row-actions');
  }

  onDownloadBtnClick() {
    let repo :any[]= this.dataSourceMatched.data;
       let flatten= repo.reduce((acc, value) => {
        if (!value.shareCapitalItems) {
          acc.push({...value, ...{
            source: '',
            shareType: '',
            shareCapital: ''
          }});
        }
        else {
          const lins = value.shareCapitalItems.map((item)=>{
            return {...value, ...{
                source: item.source,
                shareType: item.shareType,
                shareCapital: item.shareCapital
              }
            }
          })

          acc = [...acc, ...lins]
        }
      return acc;
     }, []);

    let file=flatten.map(x=> {
      return {Project: x.project,
              Investor: x.investor,
              AssetManager: x.assetManager,
              Fund: x.fund,
              InvestorFileName: x.investorFileName,
              Responsibility: x.responsibilityUser? x.responsibilityUser.displayName : "",
              Location: x.city,
              Source: x.source,
              ShareType: x.shareType,
              ShareCapital: x.shareCapital
    }});
    this.exportService.export(file,"Project Investor Report");
  }

  //Move the record to exception List marking status as UNMATCH
  onUnMatchClick(elm) {
    let unMatchRecord: ProjectInvestorAUDataRecord;
    unMatchRecord = elm;
    let apiURL: string = environment.serverUrl + 'projects/unmatch-investor';
    this.httpClient.post<ProjectInvestorAUDataRecord[]>(apiURL, unMatchRecord).subscribe(
      data => {
        this.getProjectInvestorAUDataByProject();
        this.alertService.sendSuccess('Moved investor to unmatched list !');


      },
      err => {
        this.alertService.sendError(err);
      }
    );
  }

  onDelete(element, status: string) {
    const { projectInvestorId, projectId, investorId, investor = null, matchingSuggestions = null} = element;

    let title = investor;

    if (status.toUpperCase() === 'UNMATCHED'){
      title = matchingSuggestions ? `${matchingSuggestions.length} Match suggestions...` : 'No match found!';
    }
    this.dialogsService.confirm(null, null, {template: DeleteProjectTemplate({title: `${status} Investor`, message: title})});
    this.dialogsService.close$.subscribe(res => {
      if (res) {
        const apiURL: string = environment.serverUrl + 'projects/au-project-investor/' + projectInvestorId + '/' + projectId + '/' + investorId + '/' + status.toUpperCase();
        this.httpClient.delete(apiURL).subscribe(
          () => {
            this.alertService.sendSuccess('Deleted Successfully!');
            this.getProjectInvestorAUDataByProject();
          },
          err => {
            this.alertService.sendError(err);
          }
        );
      }
    });

  }

  isAddInvestorBtnActive = () => this.shareSources.length === 0;

  onAddBtnClick() {
    const dialogRef = this.dialog.open(AddEditInvestorDialogComponent,
      {
        width: '600px',
        data: {
          shareTypeKeyValuePairs: this.shareTypeKeyValuePairs,
          sourceKeyValuePairs: this.sourceKeyValuePairs,
          projectId: this.projectid
        }
      });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) this.saveRecord(result);
      });

  }

  saveRecord(data) {
    data.projectId = +this.projectid;
    this.spinnerService.show();
    this.projectInvestorDataService.addProjectInvestorData(data)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe(result => {
          if (result) {
            this.alertService.sendSuccess('Investor added successfully!');

            this.getProjectInvestorAUDataByProject();
          } else {
            this.alertService.sendError('There was problem adding the investor.');
          }
        },
        (err) => {
          err.error !== undefined ? this.alertService.sendError(err.error.Message) : this.alertService.sendError(err);
        });
  }

  onEdit(elem) {
    const item = Object.assign({}, elem);

    const dialogRef = this.dialog.open(AddEditInvestorDialogComponent,
      {
        width: '600px',
        autoFocus: false,
        data: {
          item: item,
          shareTypeKeyValuePairs: this.shareTypeKeyValuePairs,
          sourceKeyValuePairs: this.sourceKeyValuePairs,
          projectId: this.projectid
        },
      });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) this.onUpdate(result);
      });

  }

  onUpdate(_data) {
    this.spinnerService.show();
    this.projectInvestorDataService.updateProjectInvestorData(_data)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe(data => {
          this.getProjectInvestorAUDataByProject();
          this.alertService.sendSuccess('Investor Data updated!');
        },
        err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        });
  }


  onBlur() {
    if (!this.modelDto.investorId) {
      this.modelDto.investorId = undefined;
      this.modelDto.investor = '';
      this.modelDto.city = '';
    }
  }

  openMatchDialog(item: ProjectInvestorAUDataRecord) {

    const dialogRef = this.dialog.open(MatchInvestorDialogComponent,
      {
        width: '85%',
        maxHeight: 'fit-content',
        data:
          {
            item: item
          }
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getProjectInvestorAUDataByProject();
      }
    });
  }

  openUploadDialog() {
    this.showUploadFileStat = true;
    const dialogRef = this.dialog.open(ProjectInvestorUploadDialogComponent, {
      data: {
        projectId: this.projectid
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      this.getProjectInvestorAUDataByProject();
    });
  }

  getShareholding(element: ProjectInvestorAUDataRecord, shareTypeCol: any) {
    return (element.shareCapitalItems.find(x => x.source === shareTypeCol.source
      && x.shareType === shareTypeCol.type) || {shareCapital: ''}).shareCapital;
  }
}
