import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FilterModalComponent } from './filter-modal/filter-modal.component';
import { GridFilterModel } from './grid-filter.models';

@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss']
})
export class GridFilterComponent implements OnInit {
  @Input() public filterModel: GridFilterModel;
  @Output() public onFilterChanged = new EventEmitter<GridFilterModel>();

  appliedFiltesCount: number;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.updateAppliedFiltersCount();
  }

  onFilterClick() {
    const dialogRef = this.dialog.open(FilterModalComponent,
      {
        data: this.filterModel.gridFilterItems
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filterModel.gridFilterItems = result;
        this.onFilterChanged.emit(this.filterModel);
        this.updateAppliedFiltersCount();
      }
    });
  }

  private updateAppliedFiltersCount(){
    this.appliedFiltesCount = this.filterModel.gridFilterItems.reduce(function (a, b) {
      return a + b['value'].length;
    }, 0);
  }
}
