import {Component, Input, forwardRef, HostBinding} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-investor-voting-process-comments',
  templateUrl: 'investor-voting-process-comments.component.html',
  styleUrls: ['investor-voting-process-comments.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InvestorVotingProcessCommentsComponent),
      multi: true
    }
  ]
})
export class InvestorVotingProcessCommentsComponent implements ControlValueAccessor {
  public val = '';
  @Input() disabled: false;
  @Input() showHead: true;
  @Input() label: string = 'Your comment';

  constructor() {
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  get value() {
    return this.val;
  }

  set value(val) {
    if( val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any) {

    if(value) {
      this.value = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void;
}
