import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { DashboardAuCompaignSummary } from '../dto/DashboardAuCompaignSummary';
import { Beneficiaries } from '@app/dto/AUDashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardAuCampaignSummaryTableService {
  private campaignSummaryPerInvestor: DashboardAuCompaignSummary[] = [];
  private campaignSummary: DashboardAuCompaignSummary[] = [];
  private projectId: string;

  constructor(private http: HttpClient) {
  }

  public getData(projectId: string, shareClassification: string): Observable<DashboardAuCompaignSummary[]> {
    if (this.projectId === projectId && this.campaignSummary.length) {
      return new Observable((observer) => {
        observer.next(this.campaignSummary);
        observer.complete();
      });
    } else {
      this.projectId = projectId;

      return new Observable((observer) => {
        const url = `${environment.serverUrl}dashboards/voting-summary/${projectId}/${shareClassification}`;

        return this.http.get<DashboardAuCompaignSummary[]>(url).subscribe((result) => {
          this.campaignSummary = result.map((entity) => new DashboardAuCompaignSummary(entity));

          observer.next(this.campaignSummary);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
      });
    }
  }

  public getDataPerInvestor(projectId: string, investorId: number, shareClassification: string): Observable<any> {
      if (this.projectId === projectId && this.campaignSummaryPerInvestor.length) {
        return new Observable((observer) => {
          observer.next(this.campaignSummaryPerInvestor);
          observer.complete();
        });
      } else {
        this.projectId = projectId;

        return new Observable((observer) => {
          const url = `${environment.serverUrl}dashboards/investor-voting-summary/${projectId}/${investorId}/${shareClassification}`;

          return this.http.get<DashboardAuCompaignSummary[]>(url).subscribe((result) => {
            this.campaignSummaryPerInvestor = result.map((entity) => new DashboardAuCompaignSummary(entity));

            observer.next(this.campaignSummaryPerInvestor);
            observer.complete();
          }, (error) => {
            observer.error(error);
            observer.complete();
          });
        });
      }
  }

  public getBeneficiaries(projectId: number, investorId: number): Observable<any> {
    return this.http.get<Beneficiaries[]>(`${environment.serverUrl}dashboards/beneficiaries/${projectId}/${investorId}`);
  }
}
