import { DialogGeneralTemplateConfig } from "./dialog-general-template.config";

export function CustodianVotesExceeShareCapitalTemplate(_params) {
    return new DialogGeneralTemplateConfig({
        title:'Votes exceed share capital',
        message: _params.message,
        config:{
            okButton:{
                text:'OK',
            },
            cancelButton:{
                text:'CANCEL'
            }
        }
    },  _params)

}