import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { InvestorsService } from '@app/services/investors';
import { AppConstants } from '@app/app.constants';

@Injectable()
export class InvestorGuardService implements CanActivate {

    constructor(public router: Router, public investorService: InvestorsService) {
    }


    canActivate(route: ActivatedRouteSnapshot) {
        const investorId = route.params.invid;
        if (investorId === "0") {
            return true;
        }
        return this.investorService.getInvestor(investorId, true)
            .pipe(
                switchMap(() => {
                    return Observable.of(true);
                }),
                catchError((err) => {
                    if (err.status === 403) {
                        this.router.navigate([AppConstants.DASHBOARD_ROUTE_PATH]);
                        return Observable.of(false)
                    }

                    return Observable.of(true);
                })
            );
    }
}
