import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {startWith, map, debounceTime} from 'rxjs/operators';
import {ResolutionsClassificationService} from '@app/components/autocompletes/resolutions-classification/resolutions-classification.service';
import {ResolutionClassificationDTO} from '@app/dto/ResolutionClassificationDTO';

export interface StateGroup {
  category: string;
  options: ResolutionClassificationDTO[];
}

export const _filter = (opt: ResolutionClassificationDTO[], value: string): ResolutionClassificationDTO[] => {
  const filterValue = value.toLowerCase();

  const res = opt.filter(item => {
    return item.name.toLowerCase().indexOf(filterValue) >= 0;
  });
  return res;
};

enum Status {
  Loading = 'Loading...',
  Done = 'Classification'
}

@Component({
  selector: 'app-resolutions-classification',
  templateUrl: './resolutions-classification.component.html',
  styleUrls: ['./resolutions-classification.component.scss'],
})
export class ResolutionsClassificationComponent implements OnInit {

  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public placeholderDisplay = Status.Done;
  @Input() value: number;
  @Output() valueChange = new EventEmitter();
  innerValue: ResolutionClassificationDTO;
  placeholder = Status.Done;

  public valueChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');

  stateForm: FormGroup = this._formBuilder.group({
    stateGroup: [{value: '', disabled: this.disabled}],
  });
  stateGroups: StateGroup[] = [];

  stateGroupOptions: Observable<StateGroup[]>;

  constructor(private _formBuilder: FormBuilder, private resolutionsClassificationService: ResolutionsClassificationService) {
    this.innerValue = new ResolutionClassificationDTO();
  }

  ngOnInit() {
    this.disabled ? this.stateForm.get('stateGroup').disable() : this.stateForm.get('stateGroup').enable();
    this.placeholder = Status.Loading;
    this.resolutionsClassificationService.getData().subscribe(
      data => {
        const result = this.groupResults(data);
        this.stateGroups = Object.keys(result).map(key => {
          return {
            category: key,
            options: result[key]
          };
        });
        this.placeholder = this.placeholderDisplay;
        if (this.value) {
          const classificationObject = data.find(item => item.issClassificationId === this.value);
          this.innerValue = {...classificationObject, name: `(${classificationObject.code}) ${classificationObject.subcategory}`};
        }
        this.initStateGroups();
      }
    );
  }

  doSearch(_value) {
    this.valueChanges.next(_value);
  }

  initStateGroups = () => {
    this.stateGroupOptions = this.stateForm.get('stateGroup')!.valueChanges
      .pipe(
        startWith<string | ResolutionClassificationDTO>(this.innerValue),
        debounceTime(500),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterGroup(name) : this.stateGroups.slice())
      );
  }

  groupResults = (data) => {
    return data.reduce((result, row) => {
      (result[row['proposalName']] = result[row['proposalName']] || []).push({...row, name: `(${row.code}) ${row.subcategory}`});
      return result;
    }, {});
  }

  onSelected = (_value) => {
    if (_value.issClassificationId !== this.innerValue.issClassificationId) {
      this.innerValue = _value;
      this.valueChange.emit(_value);
    }

    return false;
  }

  onBlur() {
    if (!this.innerValue.issClassificationId) {
      this.innerValue.name = '';
    }
  }

  getOptionText = (option) => {
    return option ? option.name : undefined;
  }

  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups
        .map(group => ({category: group.category, options: _filter(group.options, value)}))
        .filter(group => group.options.length > 0);
    }

    return this.stateGroups;
  }
}
