import { AppConstants } from "@app/app.constants";

export class RegionCountryMultiselectHelper {
    constructor() {
    }
    static cleanRegionFocuses(
        groupedCountries: { [key: string]: string[]; },
        selected: string[]): string[] {

        const uniqueSelected = new Set(selected);

        Object.keys(groupedCountries).forEach((r) => {
            uniqueSelected.delete(r);
        });

        return Array.from(uniqueSelected);
    }

    static handleRegionSelection(
        groupedCountries: { [key: string]: string[]; },
        currentSelected: Set<string>,
        value: string, selected: boolean) {
        groupedCountries[value].forEach((c: string) => {
            if (selected) {
                currentSelected.add(c);
            } else {
                currentSelected.delete(c);
            }
        });
    }

    static handleCountrySelection(
        groupedCountries: { [key: string]: string[]; },
        currentSelected: Set<string>,
        countryValue: string,
        selected: boolean
    ) {
        const countryRegion = RegionCountryMultiselectHelper.findRegionByCountry(groupedCountries, countryValue);
        if (!selected) {
            currentSelected.delete(countryRegion);
        } else {
            RegionCountryMultiselectHelper.selectRegion(groupedCountries, currentSelected, countryRegion);
        }
    }

    static selectRegions(
        groupedCountries: { [key: string]: string[]; },
        selected: Set<string>
    ) {
        for (const region in groupedCountries) {
            RegionCountryMultiselectHelper.selectRegion(groupedCountries, selected, region)
        }
    }

    static selectRegion(
        groupedCountries: { [key: string]: string[]; },
        currentSelected: Set<string>,
        region: string) {
        const allCountriesInThisRegion = groupedCountries[region];

        let countOfSelectedCountriesInThisRegion: number = 0;
        allCountriesInThisRegion.forEach((c) => {
            if (currentSelected.has(c)) {
                countOfSelectedCountriesInThisRegion++;
            }
        })

        if (allCountriesInThisRegion.length === countOfSelectedCountriesInThisRegion) {
            currentSelected.add(region);
        }
    }

    static findRegionByCountry(
        groupedCountries: { [key: string]: string[]; },
        countryName: string): string {
        for (const region in groupedCountries) {
            const countriesInThisRegion = groupedCountries[region];
            for (let i = 0; i < countriesInThisRegion.length; i++) {
                const currentCountry = countriesInThisRegion[i];

                if (currentCountry == countryName) {
                    return region;
                }
            }
        }
    }

    static getDisplayName(select: string[], groupedCountries: { [key: string]: string[]; }){
        if((select && Array.isArray(select) && select.length >0)) {
            let selected = new Set<string>(select);
            let result :string[]= [];
            let singleCountryResult : string[]=[];
            let countries=[];
            for(const region in groupedCountries) {
                const allCountriesInThisRegion = groupedCountries[region];
                let countOfSelectedCountriesInThisRegion: number = 0;
                allCountriesInThisRegion.forEach((c) => {
                    if (selected.has(c)) {
                        countOfSelectedCountriesInThisRegion++;
                        countries.push(c);
                    } 
                });
                if (allCountriesInThisRegion.length === countOfSelectedCountriesInThisRegion) {
                    countries= [];
                    if(Object.keys(AppConstants.continentLabelMapping).find(r=> r.includes(region))){
                        result.push(AppConstants.continentLabelMapping[region]) 
                    }
                }else {
                    if(countOfSelectedCountriesInThisRegion >0){
                        singleCountryResult = [... countries];
                    }
                }
            }
            return result.concat(singleCountryResult.sort()).join(', ');
        }
        return undefined;
    }
}
