import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { SearchGridService } from '@app/service/search-grid/search-grid.service';
import { HttpClient } from '@angular/common/http';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { GridFilterModel, GridFilterItem, FilterOption, FilterType } from '@app/components/grid-filter/grid-filter.models';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { InvestorBulkEditResponsibility } from '@app/dto/InvestorBulkEditResponsibility';
import { InvestorsService } from '@app/services/investors';
import { AlertService } from '@app/services/alert.service';
import { FilterDto } from '@app/service/search-grid/SearchDto';
import { ActiveDirectoryUser } from '@app/dto/ActiveDirectoryUser';

@Component({
  selector: 'app-investor-bulk-edit',
  templateUrl: './investor-bulk-edit.component.html',
  styleUrls: ['./investor-bulk-edit.component.scss']
})
export class InvestorBulkEditComponent implements OnInit {
  displayedColumns: string[] = ['selection', 'company', 'category', 'sector', 'country', 'city', 'responsibility'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  canAssignResponsibility: boolean = false;
  showGrid: boolean = true;
  model: InvestorBulkEditResponsibility = new InvestorBulkEditResponsibility();
  dataSource = new MatTableDataSource();
  searchService: SearchGridService;

  filter: GridFilterModel = new GridFilterModel({
    buttonLabel: 'Filter Investors',
    gridFilterItems: [new GridFilterItem({
        filterName: 'Coverage',
        filterPorp: 'coverage',
        filterOptions: [
            new FilterOption({ label: 'AU', value: 'AU' }),
            new FilterOption({ label: 'EU', value: 'EU' }),
            new FilterOption({ label: 'US', value: 'US' })],
        value: ['AU','EU','US']
    })]
});

  constructor(private httpClient: HttpClient,
    private roleGuardService: RoleGuardService,
    private dialogsService: DialogsService,
    private investorService: InvestorsService,
    private alertService: AlertService) {
  }

  ngOnInit() {
    let resposibilityFilter = new GridFilterItem({
      filterName:"Responsibility",
      filterPorp:"responsibility",
      filterType: FilterType.AutoCompleteUser,
      value: ['']
    });
    this.filter.gridFilterItems.push(resposibilityFilter);
    let countryFilter = new GridFilterItem({
      filterName:"Country",
      filterPorp:"country",
      filterType: FilterType.AutoCompleteCountry,
      value: ['']
    });
    this.filter.gridFilterItems.push(countryFilter);
    this.searchService = new SearchGridService(
      this.paginator,
      this.dataSource,
      this.httpClient,
      'investors/investors-filtered',
      this.filter
    );
    this.model.investorsToChange =  [];
    this.searchService.anyResults.subscribe(hasResults => {
      this.showGrid = hasResults;
    });

    this.addFiltersToModel(this.filter.gridFilterItems);
    this.canAssignResponsibility = this.roleGuardService.hasAccess('INVESTORS', 'BULK_REASSIGN');
  }

  assignResponsibility() {
    const selectedRecords = this.getSelectedRecords();
    const numberOFReassignedInvestors =   this.model.areAllForUpdate ? this.searchService.length : selectedRecords.length;
    if (!numberOFReassignedInvestors) {
      return;
    }
    this.model.investorsToChange = [];
    this.dialogsService.confirm('Bulk Edit', `Please confirm you would like to reassign ${numberOFReassignedInvestors} investors`);
    this.dialogsService.close$.subscribe(res => {
      if (res) {
        if(!this.model.areAllForUpdate) {
            this.dataSource.data.filter((i: any) => i.checked).map((i: any) =>
            this.model.investorsToChange.push({sourceResponsibilityUser:i.responsibilityUserId,sourceInvestorId: i.investorId }));
         }
         else {
          this.addFiltersToModel(this.searchService.filterModel.gridFilterItems);
         }
        this.investorService.bulkEditResponsibility(this.model)
            .subscribe(
              editedRecordsCount => {
                this.model.areAllForUpdate = false;
                this.setAllChecked(false);
                this.searchService.reloadItems();
                this.alertService.sendSuccess(`The change is completed. ${editedRecordsCount} records updated.`)
              },
              err => {
                this.alertService.sendError(' error ' + JSON.stringify(err));
              }
            );
      }
    });
  }

  getSelectedRecords(): any[] {
    return this.dataSource.data.filter((i: any) => i.checked);
  }

  isSomethingChecked() {
    const res = this.dataSource.data.some((x: any) => x.checked);
    return res;
  }

  setAllChecked(checked: boolean) {
    this.model.areAllForUpdate = checked;
    this.dataSource.data.forEach((i: any) => i.checked = checked);
  }

  updateChecked() {
    this.model.areAllForUpdate = this.dataSource.data.every((i: any) => i.checked);
  }

  ngOnDestroy(): void {
    this.searchService.unSubscribe();
  }

  private addFiltersToModel(filters: GridFilterItem[]){
    this.model.filters = [];
    filters.forEach((item) => {
      let filter : FilterDto = new FilterDto;
      filter.filterPorp = item.filterPorp;
      filter.value = item.value;
      this.model.filters.push(filter);
    });
  }
}
