import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatOptionSelectionChange, MatSelectChange } from '@angular/material';
import { InitiativesService } from '@app/services/initiatives.service';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { Utils, LookupFilter } from '@app/helper/utils';
import { AlertService } from '@app/services/alert.service';
import { Lookup } from '@app/dto/Lookup';
import { RegionCountryMultiselectHelper } from '@app/helper/RegionCountryMultiselectHelper';
import { DocumentConfiguration } from '@app/components/documents/document-configuration';
import { Observable, combineLatest } from 'rxjs';
import { GenericEndpointSertvice } from '@app/services/generic-endpoints.service';
import { InvestorIntelItem } from '@app/dto/InvestorIntelItem';
import { EndpointConstants } from '@app/services/constants/endpoint.constants';
import { AppConstants } from '@app/app.constants';
import { NgModel } from '@angular/forms';
import { DocumentDialogConfiguration } from '@app/components/documents/document-dialog-configuration';
import { DocumentRelatedEntityType } from '@app/enums/DocumentRelatedEntityType';
import { DocumentUploadType } from '@app/components/documents/DocumentUploadType';
import {ResolutionsClassificationService} from '@app/components/autocompletes/resolutions-classification/resolutions-classification.service';

@Component({
  templateUrl: './engagement-example-dialog.component.html',
  styleUrls: ['./engagement-example-dialog.component.scss']
})
export class InvestorEngagementExampleDialogComponent implements OnInit{
  isCreate: boolean = true;
  popupTitle: string;
  model: InvestorIntelItem = new InvestorIntelItem();
  impactsList: string[];
  groupedCountries: { [key: string]: string[]; };
  util = new Utils(this.startupdataproviderService);
  sectors: any;
  thematicFocusPickerData = {
    noRecordsText: 'There are still not thematic focus selected',
    noRecordsLink: 'Edit the thematic focus...',
    allRecords: [],
    selectedRecords: []
  };
  regionalSectorSelectionEvent: MatOptionSelectionChange;
  shouldUseRouteParamsInDocuments: boolean = false;
  documentRelatedEntityType: DocumentRelatedEntityType = DocumentRelatedEntityType.InvestorIntelItem;
  documentConfiguration: DocumentConfiguration;
  areDocumentsInMemory: boolean = true;
  createdEntityId?: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<InvestorEngagementExampleDialogComponent>,
              private startupdataproviderService: StartupdataproviderService,
              private alertService: AlertService,
              private initiativeService: InitiativesService,
              private genericEndpointService: GenericEndpointSertvice,
              private resolutionsClassificationService: ResolutionsClassificationService) {
  }


  ngOnInit() {
    this.isCreate = this.data.isCreate;
    this.popupTitle = this.data.title;
    if (this.isCreate) {
      this.model.investorId = this.data.id;
      this.getThematicFocusOptionsObservable().subscribe(this.thematicFocusSubscriber.bind(this));
    } else {
      this.areDocumentsInMemory = false;
      combineLatest(this.getByIdObservable(this.data.id), this.getThematicFocusOptionsObservable(),
        (item, thematicFocuses) => ({ item, thematicFocuses }))
        .subscribe(pair => {
          this.thematicFocusSubscriber(pair.thematicFocuses);
          this.getByIdSubscriber(pair.item);
        });
    }

    this.initialiseUtils();
    this.buildDocumentConfiguration();
  }

  initialiseUtils() {
    this.util.getLookupByType(LookupFilter.GICSECTOR)
      .subscribe(data => this.sectors = data);

    this.groupedCountries = this.util.getCountriesByRegionGroup();

    this.util.getLookupByType(LookupFilter.INIIMP)
      .subscribe((data: Lookup[]) => {
        this.impactsList = data.map((item: Lookup) => {
          return item.fieldValue;
        });
      });
  }

  buildDocumentConfiguration() {
    this.documentConfiguration = new DocumentConfiguration();
    this.documentConfiguration.gridName = "Sources";
    this.documentConfiguration.columnsNames = ['Source document', 'Source description', 'Source Link'];
    this.documentConfiguration.documentComponentOuterDivMargins = 'mt-5';
    this.documentConfiguration.uploadBtnText = 'ADD SOURCE';
    this.documentConfiguration.uploadBtnIconClass = 'ms-plus-icon';
    this.documentConfiguration.uploadType = DocumentUploadType.SOURCE;

    this.documentConfiguration.dialogConfiguration = new DocumentDialogConfiguration();
    this.documentConfiguration.dialogConfiguration.dialogTitle = 'Add New Source';
    this.documentConfiguration.dialogConfiguration.descriptionFieldLabel = 'SOURCE DESCRIPTION';
    this.documentConfiguration.dialogConfiguration.showLink=true;
    this.documentConfiguration.dialogConfiguration.isDocumentUploadOptional = true;
  }

  getThematicFocusOptionsObservable(): Observable<any> {
    return this.resolutionsClassificationService.getData();
  }

  thematicFocusSubscriber(data) {
    this.thematicFocusPickerData.allRecords = data;
  }

  getThematicFocusSelection(ids) {
    if (!this.thematicFocusPickerData.allRecords.length) {
      return [];
    }
    const selection = [];
    for (const option of this.thematicFocusPickerData.allRecords) {
      if (ids.includes(option.id.toString())) {
        selection.push(JSON.parse(JSON.stringify(option)));
      }
    }
    return selection;
  }

  getByIdObservable(id): Observable<InvestorIntelItem> {
    return this.genericEndpointService.getById<InvestorIntelItem>(EndpointConstants.ENGAGEMENT_EXAMPLE, id);
  }

  getByIdSubscriber(data) {
    const regionFocuses = new Set<string>(data.regionFocuses);
    RegionCountryMultiselectHelper.selectRegions(this.groupedCountries, regionFocuses);
    data.regionFocuses = Array.from(regionFocuses);
    this.thematicFocusPickerData.selectedRecords = this.getThematicFocusSelection(data.thematicFocuses);
    this.model = data;
  }

  getContinentLabel(region: string) {
    return AppConstants.continentLabelMapping[region];
  }

  onCountrySelectionChange(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.regionalSectorSelectionEvent = event;
    }
  }

  onContinentSelectionChange(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.regionalSectorSelectionEvent = event;
    }
  }

  selectionChange(event: MatSelectChange, select: NgModel) {
    const value: string = this.regionalSectorSelectionEvent.source.value;
    const isRegion = !!this.groupedCountries[this.regionalSectorSelectionEvent.source.value];
    const isSelected: boolean = this.regionalSectorSelectionEvent.source.selected;
    const resultToEmit = new Set<string>(event.value);
    if (isRegion) {
      RegionCountryMultiselectHelper.handleRegionSelection(this.groupedCountries, resultToEmit, value, isSelected);
    } else {
      RegionCountryMultiselectHelper.handleCountrySelection(this.groupedCountries, resultToEmit, value, isSelected);
    }
    const result = Array.from(resultToEmit);
    select.update.emit(result);

  }

  onCloseBtnClick(): void {
    this.dialogRef.close();
  }

  onSave() {
    this.model.regionFocuses = RegionCountryMultiselectHelper.cleanRegionFocuses(this.groupedCountries, this.model.regionFocuses);
    this.saveThematicFocusSelection();

    if (this.isCreate) {
      this.genericEndpointService.create<InvestorIntelItem>(EndpointConstants.ENGAGEMENT_EXAMPLE, this.model).subscribe(
        data => {
          this.alertService.sendSuccess('Example/Case Created!');
          this.createdEntityId = data.investorIntelItemId;
          this.dialogRef.close();
        },
        err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        }
      )
    } else {
      this.genericEndpointService.update<InvestorIntelItem>(EndpointConstants.ENGAGEMENT_EXAMPLE, this.model).subscribe(
        data => {
          this.model = data;
          this.alertService.sendSuccess('Example/Case Updated!');
          this.dialogRef.close();
        },
        err => {
          this.alertService.sendError(' error ' + JSON.stringify(err));
        }
      );
    }
  }

  saveThematicFocusSelection() {
    this.model.thematicFocuses = [];
    for (const option of this.thematicFocusPickerData.selectedRecords) {
      this.model.thematicFocuses.push(option.id);
    }
  }
  getDisplayName(select : NgModel){
    return RegionCountryMultiselectHelper.getDisplayName(select.model, this.groupedCountries)
  }
}
