import { Component, Input, OnInit, } from '@angular/core';
import { Lookup } from '@app/dto/Lookup';
import { ProjectsService } from '@app/services/projects.service';
import { ProjectTemplateTypesAsString } from '@app/enums/ProjectTemplateTypesEnum';
import { ProxyAdvisorRecommendationsGrouped } from '@app/dto/ProxyAdvisorRecommendationsGrouped';
import { ProjectRecommendationDlgComponent } from '@app/project/projectdetail/project-recommendation-dlg.component';
import { ProxyAdvisorRecommendationItem } from '@app/dto/ProxyAdvisorRecommendationItem';
import { ProxyAdvisorRecommendations } from '@app/dto/ProxyAdvisorRecommendations';
import { ProjectResolutionBODRecommendation } from '@app/dto/ProjectResolutionBODRecommendation';
import { MatDialog, MatIconRegistry } from '@angular/material';
import { ProxyAdvisorRecommendationGroupedItem } from '@app/dto/ProxyAdvisorRecommendationGroupedItem';
import { AlertService } from '@app/services/alert.service';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { DialogsService } from '@app/services/dialogs/dialogs.service';
import { DeleteProjectTemplate } from '@app/services/dialogs/dialog-delete-template.config';
import { CommonUtilityService } from '@app/utility/common-utility.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjRecomCommentNavigationComponent } from '@app/project/proj-recom-comment-navigation/proj-recom-comment-navigation.component';
import { ProxyAdvisorRecommendationComment } from '@app/dto/ProxyAdvisorRecommendationComment';

@Component({
  selector: 'app-project-proxy-advisers',
  templateUrl: 'project-proxy-advisers.component.html',
  styleUrls: ['project-proxy-advisers.component.scss']
})
export class ProjectProxyAdvisersComponent implements OnInit {
  @Input() inputRecommendations = false;
  @Input() recommendations: Lookup[] = [];
  @Input() projectId: number = null;
  @Input() isEdit = false;
  @Input() isEditable = true;
  @Input() showLegend = true;
  @Input() shouldFormatSection: boolean;

  public showProxyRecommendation = false;
  public buildingRecommendation: ProxyAdvisorRecommendationsGrouped;
  public isShareHolderMeeting: boolean;
  dataSource: ProxyAdvisorRecommendationsGrouped[] = [];
  columns = [];
  noItemsText = 'Proxy Advisors';
  projectTemplate: string;
  bodRecommendation: ProjectResolutionBODRecommendation[] = [];
  additional = [];

  constructor(private projectsService: ProjectsService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private projectTemplateService: ProjectTemplateService,
    private dialogsService: DialogsService,
    private commonUtility: CommonUtilityService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {

    this.matIconRegistry.addSvgIcon('Addcomment', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/Addcomment.svg'));
    this.matIconRegistry.addSvgIcon('Comment', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/Comment.svg'));
  }

  ngOnInit() {
    this.getProjectTemplate();
  }

  getProjectTemplate() {
    this.projectsService.getProject(this.projectId, true).subscribe((value) => {
      this.projectTemplate = value.template;
      this.isShareHolderMeeting = this.projectTemplate.toLowerCase() === ProjectTemplateTypesAsString.ShareHolderMeeting;

      if (!this.inputRecommendations) {
        this.recommendations = this.projectTemplateService.getAdvisorVoteTypes(value.template, this.additional);
      }

      this.getData();
    });
  }

  getData() {
    this.columns = [];
    this.projectsService.getProxyRecommendations(this.projectId).subscribe(
      data => {
        this.setTableRows(data);
        this.addEmptyRecommendationsForNewResolutions();
        this.showProxyRecommendation = data.length > 0;

        if (this.isShareHolderMeeting) {
          this.columns.push('resolution');
        } else {
          this.columns.push('company');
          this.columns.push('bid');
        }

        this.columns.push('bod');

        if (data.length && data.length > 0) {
          this.buildingRecommendation = data.sort((a, b) => b.recommendations.length - a.recommendations.length)[0];
          this.buildingRecommendation.recommendations.forEach((element, i) => {
            this.columns.push(`intention_${i}_upper`);
          });
        }
      });
  }

  setTableRows(data) {
    const tableRows = data.reduce((accumulator, item, currentIndex) => {
      if (item.votingItemChild) {
        if (!accumulator.find(el => el.templateProp2 === item.templateProp2)) {
          accumulator.push({
            templateProp1: item.templateProp1,
            templateProp2: item.templateProp2,
            recommendations: [{...item.recommendations[0], ...{iscPercent: null, proxyAdvisorId: null, recommendation: null}}]
          });
        }
      }

      const row: ProxyAdvisorRecommendationsGrouped = {
        projectId: item.projectId,
        projectTemplateId: item.projectTemplateId,
        relatedProjectItemId: item.relatedProjectItemId,
        templateProp1: item.templateProp1,
        templateProp2: !item.votingItemChild
          ? item.templateProp2
          : `${this.commonUtility.indexToLetter(item.votingItemChild.orderId, false)} ${item.votingItemChild.name}`,
        votingItemChild: item.votingItemChild,
        votingItemType: item.votingItemType,
        recommendations: item.recommendations.map((recommendation) => ({
          ...recommendation,
          recommendation: recommendation.recommendation.toUpperCase(),
          hasComment: recommendation.proxyAdvisorRecommendationComments !== null,
          hasActions: !!recommendation
        })),
        bod: (item.boardOfDirectorsRecommendation || '').toUpperCase()
      };

      accumulator.push(row);

      return accumulator;
    }, []);

    this.dataSource = tableRows;
  }

  addEmptyRecommendationsForNewResolutions() {
    // This is corner case. By default once set new resolutions cannot be added to the project.
    // Yet, if such are added to avoid the disfunctionality of the page empty models are added where
    // no votes for the new resolutions exist.
    const recommendationsCount = (this.dataSource[0] || {recommendations: []}).recommendations.length;
    this.dataSource.forEach(item => {
      if (item.recommendations.length < recommendationsCount) {
        const arr = [];
        for (let index = 0; index < (recommendationsCount - item.recommendations.length); index++) {
          arr[index] = new ProxyAdvisorRecommendationGroupedItem();
        }

        item.recommendations.push(...arr);
      }
    });
  }

  onAddRecommendationBtnClick() {
    if (!this.isEdit) {
      return;
    }

    const model = new ProxyAdvisorRecommendations();
    model.projectId = this.projectId;

    if (this.dataSource.length) {
      model.projectTemplateId = this.dataSource[0].projectTemplateId;

      model.proxyAdvisorRecommendationItems = this.dataSource.map((recommendation) => {
        return {
          templateProp1: recommendation.templateProp1,
          templateProp2: recommendation.templateProp2,
          relatedProjectItemId: recommendation.relatedProjectItemId,
          votingItemType: recommendation.votingItemType,
          votingItemChild: recommendation.votingItemChild,
          isChild: !!recommendation.votingItemChild,
          isParentWithChild: !recommendation.votingItemChild && recommendation.votingItemType === 'Director Exception'
        };
      });

      const dialogRef = this.dialog.open(ProjectRecommendationDlgComponent,
        {
          data:
          {
            projectTemplate: this.projectTemplate,
            model: model,
            createdProxyRecommendations: this.dataSource.length > 0 ?
              this.dataSource[0].recommendations
                .map((x) => ({ proxyId: x.proxyAdvisorId })) : [],
          },
          width: '700px'
        }).afterClosed().subscribe(result => {
        if (result) {
          this.getData();
        }
      });
    }
  }

  onDeleteRecommendationBtnClick(projectId: number, recommendation: ProxyAdvisorRecommendationGroupedItem, proxyAdvisorName: string) {
    this.dialogsService.confirm(null, null, { template: DeleteProjectTemplate({ title: 'Recommendation', message: proxyAdvisorName }) });
    this.dialogsService.close$.subscribe(res => {
      if (res) {
        this.projectsService.deleteProxyRecommendations(projectId, recommendation).subscribe(
          data => {
            if (data) {
              this.getData();
              this.alertService.sendSuccess('Proxy advisor recommendations deleted.');
            }
          },
          err => {
            this.alertService.sendError(' error ' + JSON.stringify(err));
          }
        );
      }
    });
    return true;
  }

  getColor(recommendation: string): string {
    const item = this.recommendations.find(x => x.fieldLabel.toUpperCase() === recommendation.toUpperCase());
    if (item) {
      return item.color;
    }
  }

  onCommentClick(recommendation) {
    const commentModel = new  ProxyAdvisorRecommendationComment();
    commentModel.projectPxyAdvResolutionRecommendationId = recommendation.projectPxyAdvResolutionRecommendationId;

    if (recommendation.proxyAdvisorRecommendationComments) {
      commentModel.id = recommendation.proxyAdvisorRecommendationComments.id;
      commentModel.comment = recommendation.proxyAdvisorRecommendationComments.comment;
    }

    const dialogRef = this.dialog.open(ProjRecomCommentNavigationComponent,
      {
        data: commentModel,
        width: '510px',
        maxHeight: 'fit-content'
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }
}
