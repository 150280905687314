import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StartupdataproviderService } from '@app/service/startupdataprovider.service';
import { Utils, LookupFilter } from '@app/helper/utils';
import { Lookup } from '@app/dto/Lookup';

@Component({
  selector: 'app-dropdown-section',
  templateUrl: './dropdown-section.component.html',
  styleUrls: ['./dropdown-section.component.scss']
})
export class DropdownSectionComponent implements OnInit {
  @Input() placeholder : string;
  @Input() listType : LookupFilter;
  @Input() multiselect : boolean = false;
  @Output() public onListChanged = new EventEmitter<string[]>();
  listSource: string[]= [];
  util = new Utils(this.startupdataproviderService);

  constructor(private startupdataproviderService: StartupdataproviderService,) { }

  ngOnInit() {

      this.util.getLookupByType(this.listType)
      .subscribe((data: Lookup[]) => {
        this.listSource = data.map((item: Lookup) => {
          return item.fieldValue;
        });
      });

  }

  onSelectChange(value){
    if (this.multiselect && value){
      let _value = value;
     value = _value.join(',');
    }

    this.onListChanged.next(value);
  }


}
