import { Component, Input, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { DashboardAuCampaignSummaryTableService } from '@app/services/dashboard-au-campaign-summary-table.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { DashboardAuCompaignSummary } from '@app/dto/DashboardAuCompaignSummary';
import { ProjectTemplateTypes } from '@app/enums/ProjectTemplateTypesEnum';
import { ProjectTemplateService } from '@app/service/projectTemplateService';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const frequencyColumns = {
  1: {
    className: 'bg-one_year',
    items: [
      {
        key: 'oneyearIntentionShares',
        value: 'Shares'
      },
      {
        key: 'oneyearIntentionIsc',
        value: '%'
      },
      {
        key: 'oneyearVotedShares',
        value: 'Shares'
      },
      {
        key: 'oneyearVotedIsc',
        value: '%'
      },
    ]
  },
  2: {
    className: 'bg-two_years',
    items: [
      {
        key: 'twoyearsIntentionShares',
        value: 'Shares'
      },
      {
        key: 'twoyearsIntentionIsc',
        value: '%'
      },
      {
        key: 'twoyearsVotedShares',
        value: 'Shares'
      },
      {
        key: 'twoyearsVotedIsc',
        value: '%'
      },
    ]
  },
  3: {
    className: 'bg-three_years',
    items: [
      {
        key: 'threeyearsIntentionShares',
        value: 'Shares'
      },
      {
        key: 'threeyearsIntentionIsc',
        value: '%'
      },
      {
        key: 'threeyearsVotedShares',
        value: 'Shares'
      },
      {
        key: 'threeyearsVotedIsc',
        value: '%'
      },
    ]
  }
};
@Component({
  selector: 'app-dashboard-au-campaign-summary-table',
  templateUrl: './dashboard-au-campaign-summary-table.html',
  styleUrls: ['dashboard-au-campaign-summary-table.scss']
})

export class DashboardAuCampaignSummaryTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() projectId: string;
  @Input() investorId = 0;
  @Input() hideLegend = false;
  @Input() shareClassification: string;
  @ViewChild(MatSort) sort: MatSort;

  public dataSource: MatTableDataSource<DashboardAuCompaignSummary[]>;
  public displayedColumnsShareholderMeeting = [
    'resolutions',
    'forIntentionShares',
    'forIntentionIsc',
    'forVotedShares',
    'forVotedIsc',
    'againstIntentionShares',
    'againstIntentionIsc',
    'againstVotedShares',
    'againstVotedIsc',
    'abstainIntentionShares',
    'abstainIntentionIsc',
    'abstainVotedShares',
    'abstainVotedIsc',
    'undisclosedIntentionShares',
    'undisclosedIntentionIsc',
    'undisclosedVotedShares',
    'undisclosedVotedIsc',
    'undecidedIntentionShares',
    'undecidedIntentionIsc',
    'undecidedVotedShares',
    'undecidedVotedIsc',
    'notvotingIntentionShares',
    'notvotingIntentionIsc',
    'notvotingVotedShares',
    'notvotingVotedIsc',
    'inlinewpadvIntentionShares',
    'inlinewpadvIntentionIsc',
    'inlinewpadvVotedShares',
    'inlinewpadvVotedIsc',
    'totalsIntentionShares',
    'totalsIntentionIsc',
    'totalsVotedShares',
    'totalsVotedIsc'
  ];
  public displayedColumnsCorporateAction = [
    'bidCompanies',
    'acceptIntentionShares',
    'acceptIntentionIsc',
    'acceptVotedShares',
    'acceptVotedIsc',
    'declineIntentionShares',
    'declineIntentionIsc',
    'declineVotedShares',
    'declineVotedIsc',
    'undisclosedIntentionShares',
    'undisclosedIntentionIsc',
    'undisclosedVotedShares',
    'undisclosedVotedIsc',
    'undecidedIntentionShares',
    'undecidedIntentionIsc',
    'undecidedVotedShares',
    'undecidedVotedIsc',
    'inlinewpadvIntentionShares',
    'inlinewpadvIntentionIsc',
    'inlinewpadvVotedShares',
    'inlinewpadvVotedIsc',
    'totalsIntentionShares',
    'totalsIntentionIsc',
    'totalsVotedShares',
    'totalsVotedIsc'
  ];

  displayFrequencyColumns = [];
  displayFrequencyDynColumns = [];
  displayFrequencyHeaderColumns: string[] = [];
  displayedColumns: string[];
  projectType: ProjectTemplateTypes;
  projectTemplateTypes = ProjectTemplateTypes;
  voteTitle: string;
  recommendations = [];
  private unsubscribe = new Subject<void>();

  constructor(
    private projectTemplateService: ProjectTemplateService,
    private dashboardAuCampaignSummaryTableService: DashboardAuCampaignSummaryTableService,
    private spinnerService: NgxSpinnerService
  ) {

  }

  public ngOnInit(): void {
    this.spinnerService.show('campaign-summary');
  }

  public ngAfterViewInit(): void {
    // execute in the next event loop
    setTimeout(() => {
      if (this.projectId) {
        this.setInitialData();
      }
    }, 0);
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setInitialData() {
    if (this.investorId === 0) {
      return this.dashboardAuCampaignSummaryTableService
      .getData(this.projectId, this.shareClassification)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        data => {
          this.setDataSource(data);
          this.spinnerService.hide('campaign-summary');
        });
    } else {
      return this.dashboardAuCampaignSummaryTableService
        .getDataPerInvestor(this.projectId, this.investorId, this.shareClassification)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          data => {
            this.setDataSource(data);
            this.spinnerService.hide('campaign-summary');
          });
    }

  }

  setDataSource(data) {
    this.projectType = (data[0] || {}).projectTypeId || ProjectTemplateTypes.ShareHolderMeeting;
    const template = this.projectType === ProjectTemplateTypes.ShareHolderMeeting
      ? this.projectTemplateService.SHAREHOLDER_MEETING_TEMPLATE_NAME
      : this.projectTemplateService.CORPORATE_ACTION_TEMPLATE_NAME;
    const additional = this.projectTemplateService.hasFrequency(data) ? this.projectTemplateService.getFrequencyVoteTypes(false) : [];
    this.recommendations = this.projectTemplateService.getIntentionTypeWithoutSplitVote(template, additional, true);

    this.displayedColumns = this.projectType === ProjectTemplateTypes.ShareHolderMeeting
      ? this.displayedColumnsShareholderMeeting
      : this.displayedColumnsCorporateAction;
    this.voteTitle = this.projectType === ProjectTemplateTypes.ShareHolderMeeting ? 'Resolutions' : 'Bid companies';
    this.dataSource = new MatTableDataSource<DashboardAuCompaignSummary[]>();
    this.dataSource.sort = this.sort;

    this.defineFrequencyColumns(data);
    this.dataSource.data = data;
  }

  private defineFrequencyColumns(data): void {
    const label = data.find(item => /^frequency/gmi.exec(item['votingItemType']));
    if (label && label.votingItemType) {
      const txt = label.votingItemType;
      const numbers = txt.match(/\d/g);
      const columns = [];
      numbers.forEach(number => {
        columns.push(...frequencyColumns[number].items);
        this.displayFrequencyHeaderColumns.push(frequencyColumns[number].className);
      });
      this.displayFrequencyColumns = columns;
      this.displayedColumns.splice(this.displayedColumns.length - 4, 0, ...columns.map(column => column.key));
    }
  }
}
