import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {AuditLogDTO} from '@app/dto/AuditLogDTO';
import {GenericAuditLogsDeleteSegmentComponent} from './generic-logs/generic-logs-delete-segment/generic-logs-delete-segment.component';
import {GenericAuditLogsUpdateSegmentComponent} from './generic-logs/generic-logs-update-segment/generic-logs-update-segment.component';
import {GenericAuditLogsInsertSegmentComponent} from './generic-logs/generic-logs-insert-segment/generic-logs-insert-segment.component';
import {convertPascalCaseToString, humaniseString} from '@app/utility/Strings';
import {AlertService} from '@app/services/alert.service';
import {VotingLogsInsertSegmentComponent} from '@app/components/audit-logs/audit-logs-segment/voting-logs/voting-logs-insert-segment/voting-logs-insert-segment.component';
import {VotingLogsUpdateSegmentComponent} from '@app/components/audit-logs/audit-logs-segment/voting-logs/voting-logs-update-segment/voting-logs-update-segment.component';
import {VotingLogsDeleteSegmentComponent} from '@app/components/audit-logs/audit-logs-segment/voting-logs/voting-logs-delete-segment/voting-logs-delete-segment.component';

@Component({
  selector: 'app-audit-logs-segment',
  templateUrl: './audit-logs-segment.component.html',
  styleUrls: ['audit-logs-segment.component.scss']
})

export class AuditLogsSegmentComponent implements OnInit {
  private static readonly COMPONENTS = {
    'GenericAuditLogs': {
      'Added': GenericAuditLogsInsertSegmentComponent,
      'Update': GenericAuditLogsUpdateSegmentComponent,
      'Delete': GenericAuditLogsDeleteSegmentComponent,
    },
    'Vote': {
      'Added': VotingLogsInsertSegmentComponent,
      'Update': VotingLogsUpdateSegmentComponent,
      'Delete': VotingLogsDeleteSegmentComponent,
    }
  };
  @Input() isOpen;

  @Input() public log: AuditLogDTO = new AuditLogDTO();
  @ViewChild('container', {read: ViewContainerRef}) entry: ViewContainerRef;

  constructor(private resolver: ComponentFactoryResolver,
              private alertService: AlertService) {
  }

  toggleContent = () => this.isOpen = !this.isOpen;

  ngOnInit() {
    this.entry.clear();

    if ( this.log.auditData ) {
      try {
        const componentName = AuditLogsSegmentComponent.COMPONENTS[this.log.entityType] ? this.log.entityType : 'GenericAuditLogs';
        const component = AuditLogsSegmentComponent.COMPONENTS[componentName][this.log.auditAction];
        const factory = this.resolver.resolveComponentFactory(component);
        const componentRef = this.entry.createComponent(factory);
        (<ComponentDto>componentRef.instance).data = JSON.parse(this.log.auditData);
      } catch (error) {
        this.alertService.sendError(JSON.stringify(error));
      }
    }
  }

  _humaniseString = (string) => {
      return humaniseString(string);
  }

  _convertPascalCaseToString = (string) => {
    return convertPascalCaseToString(string);
  }
}

class ComponentDto {
  data: any;
}
