import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  OnDestroy,
  EventEmitter,
  ElementRef
} from '@angular/core';

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html'
})
export class InfiniteScrollComponent implements OnInit, OnDestroy {
  @Input() options = {};
  @Input() height: number;
  @Output() intersectionInView = new EventEmitter();
  @ViewChild('intersection') intersection: ElementRef<HTMLElement>;
  @ViewChild('content') content: ElementRef<HTMLElement>;

  private observer: IntersectionObserver;

  constructor() { }

  ngOnInit() {
    const options = {
      root: null,
      ...this.options
    };

   this.observer = new IntersectionObserver((([entry]) => {
     const hasScrollableContent = this.content.nativeElement.clientHeight > this.height;

      if (entry.isIntersecting && hasScrollableContent) {
        this.intersectionInView.emit();
      }
    }).bind(this), options);

    this.observer.observe(this.intersection.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}
