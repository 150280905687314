import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Project} from '@app/dto/Project';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {ClientProjectAssignmentDTO} from '@app/dto/ClientProjectAssignment';
import {AppConfigService} from './app-config.service';
import {ProjectClient} from '@app/dto/ProjectClient';
import {ProxyAdvisorRecommendationsGrouped} from '@app/dto/ProxyAdvisorRecommendationsGrouped';
import {ProxyAdvisorRecommendationGroupedItem} from '@app/dto/ProxyAdvisorRecommendationGroupedItem';
import {ProjectResolutionBODRecommendation} from '@app/dto/ProjectResolutionBODRecommendation';
import {ProjectFinancial} from '@app/dto/ProjectFinancial';
import {ProjectFinancialDetail} from '@app/dto/ProjectFinancialDetail';
import { ResponsibilityDto } from '@app/components/assign-projects-to-team-members/ResponsibilityDto';
import { ProjectCategory } from '@app/dto/ProjectCategory';
import {ProjectCustodianDto} from '@app/dto/ProjectCustodianDto';
import { BroadridgeReport } from '@app/dto/BroadridgeReport';
import { IdName } from '@app/investor/investordetail/investor-intel-esg/investor-intel-esg-survey/IdName';
import { ProxyAdvisorRecommendationComment } from '@app/dto/ProxyAdvisorRecommendationComment';
import { BrokerReport } from '@app/dto/BrokerReport';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private cache$: Observable<Project[]>[] = [];

  constructor(private http: HttpClient, private appConfigService: AppConfigService) {
  }

  public getProject(projectId: string | number , useCache: boolean = false): Observable<Project> {
    if (!this.cache$[projectId] || !useCache) {
      this.cache$[projectId] = this.http
        .get<Project>(`${environment.serverUrl}projects/${projectId}`).pipe(
          map(data => data),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        );
    }

    return this.cache$[projectId];
  }

  public getclientlist(): Observable<ProjectClient[]> {
    return this.http.get<ProjectClient[]>(environment.serverUrl + 'projects/clientlist/');
  }

  public getClientProjectAssignment(projectId: number): Observable<ClientProjectAssignmentDTO[]> {
    return this.http.get<ClientProjectAssignmentDTO[]>(`${environment.serverUrl}projects/GetPrjClientAssignments/${projectId}`).pipe(
      map(data => data)
    );
  }

  public updateClientProjectAssignment(cpa: ClientProjectAssignmentDTO): Observable<ClientProjectAssignmentDTO> {
    return this.http.post<ClientProjectAssignmentDTO>(`${environment.serverUrl}projects/UpdateClientProjectAssignment`, cpa);
  }

  public getProxyRecommendations(projectId: number): Observable<ProxyAdvisorRecommendationsGrouped[]> {
    const url = `${environment.serverUrl}projects/project-proxy-recommendations-by-item/${projectId}`;
    return this.http.get<ProxyAdvisorRecommendationsGrouped[]>(url);
  }

  public addProxyRecommendationComment(comment: ProxyAdvisorRecommendationComment): Observable<ProxyAdvisorRecommendationComment> {
    const url = `${environment.serverUrl}projects/proxy-advisor-recommendations-comment`;
    return this.http.post<ProxyAdvisorRecommendationComment>(url, comment);
  }

  public editProxyRecommendationComment(comment: ProxyAdvisorRecommendationComment): Observable<ProxyAdvisorRecommendationComment> {
    const url = `${environment.serverUrl}projects/edit-proxy-advisor-recommendations-comment`;
    return this.http.put<ProxyAdvisorRecommendationComment>(url, comment);
  }

  public deleteProxyRecommendationComment(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.serverUrl}projects/delete-proxy-advisor-recommendations-comment/${id}`);
  }

  public getProxyRecommendationComment(id: number): Observable<ProxyAdvisorRecommendationComment> {
    return this.http.get<ProxyAdvisorRecommendationComment>(`${environment.serverUrl}projects/proxy-advisor-recommendations-comment/${id}`);
  }

  public deleteProxyRecommendations(projectId: number, recommendation: ProxyAdvisorRecommendationGroupedItem): Observable<boolean> {
    const url = `${environment.serverUrl}projects/delete-proxy-advisor-recommendations/${projectId}/${recommendation.proxyAdvisorId}`;
    return this.http.delete<boolean>(url);
  }

  public getBoardOfDirectorsRecommendations(projectId: number): Observable<ProjectResolutionBODRecommendation[]> {
    return this.http.get<ProjectResolutionBODRecommendation[]>(`${environment.serverUrl}projects/project-bod-recommendations/${projectId}`);
  }

  public getProjectFinancial(projectId: number): Observable<ProjectFinancial> {
    return this.http.get<ProjectFinancial>(`${environment.serverUrl}projects/get-project-financial-by-id/${projectId}`);
  }

  public addProjectFinancial(projectFinancial: ProjectFinancialDetail): Observable<ProjectFinancial> {
    return this.http.post<ProjectFinancial>(`${environment.serverUrl}projects/add-project-financial`, projectFinancial);
  }

  public updateProjectFinancial(projectFinancial: ProjectFinancialDetail): Observable<ProjectFinancial> {
    return this.http.put<ProjectFinancial>(`${environment.serverUrl}projects/edit-project-financial`, projectFinancial);
  }

  public deleteProjectFinancial(projectId: number, projectFinancialDetailId: number): Promise<boolean> {
    const url = `${environment.serverUrl}projects/delete-project-financial-by-id/${projectId}/${projectFinancialDetailId}`;
    return this.http.delete<boolean>(url).toPromise();
  }

  public getProjectTeamMembers(projectId: number): Observable<ResponsibilityDto[]> {
    return this.http.get<ResponsibilityDto[]>(`${environment.serverUrl}projects/project-responsibilityList/${projectId}`);
  }

  public getProjectShareClassifications(projectId) {
    return this.http.get<string[]>(`${environment.serverUrl}projects/share-classifications/${projectId}`);
  }

  public getProjectShareClassificationsPair(projectId) {
    return this.http.get<any[]>(`${environment.serverUrl}projects/share-classification-pair/${projectId}`);
  }

  public getProjectCategories(): Observable<ProjectCategory[]> {
    return this.http.get<ProjectCategory[]>(`${environment.serverUrl}projects/categories/`);
  }

  public getProjectVotingItemList(projectId): Observable<any[]> {
    return this.http.get<any[]>(`${environment.serverUrl}projects/voting-items-list/${projectId}`);
  }

  public getParentCustodians(projectId, localCustodianId): Observable<any[]> {
    return this.http.get<any[]>(`${environment.serverUrl}custodian/local-global-custodian-link/${projectId}/${localCustodianId}`);
  }

  public addProjectCustodian(custodian: ProjectCustodianDto): Observable<any> {
    return this.http.post<ProjectCustodianDto>(`${environment.serverUrl}projects/custodians/project-custodian`, custodian);
  }

  public getProjectCustodianById(projectCustodianId: number): Observable<ProjectCustodianDto> {
    return this.http.get<ProjectCustodianDto>(`${environment.serverUrl}projects/custodians/project-custodian/${projectCustodianId}`);
  }

  public addProjectToBroadridgeReport(broadridgeReport: BroadridgeReport): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}projects/add-project-broadridge-report/`, broadridgeReport);
  }

  public getProjectType(projectId: number): Observable<IdName> {
    return this.http.get<IdName>(`${environment.serverUrl}projects/type/${projectId}`);
  }

  public getBrokerTypeReport(projectId: number): Observable<BrokerReport[]> {
    return this.http.get<BrokerReport[]>(`${environment.serverUrl}projects/broker-report/${projectId}`);
  }

  clearCache(projectId: number | null = null) {
    if (this.cache$ && projectId) {
      this.cache$[projectId] = null;
    } else {
      this.cache$ = null;
    }
  }
}
