import { Component, OnInit, Input} from '@angular/core';
import { ProjectTemplateTypes } from '@app/enums/ProjectTemplateTypesEnum';
import { Lookup } from '@app/dto/Lookup';

@Component({
  selector: 'app-color-legend',
  templateUrl: './color-legend.component.html',
  styleUrls: ['./color-legend.component.scss']
})
export class ColorLegendComponent implements OnInit {
  @Input() 
  projectType: ProjectTemplateTypes = ProjectTemplateTypes.ShareHolderMeeting;
  @Input()
  dataSource: Lookup[] = [];
  projectTemplateTypes = ProjectTemplateTypes;
  ngOnInit() {
  }
}
