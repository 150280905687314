import {Component, OnInit} from '@angular/core';
import {NavigationTriggerService} from '@app/services/admin/navigation-trigger.service';

@Component({
  selector: 'app-clearing-house-lending',
  templateUrl: './clearing-house-lending.component.html',
  styleUrls: ['./clearing-house-lending.component.scss']
})
export class ClearingHouseLendingComponent implements OnInit {
  isChild = true;
  constructor(private navigationTriggerService: NavigationTriggerService) {

  }

  ngOnInit() {
    this.navigationTriggerService.setSubMenu(false);
  }
}
