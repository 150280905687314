import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { InvestorInitiativeGridComponent } from '../investor-intel-common-grid/investor-intel-common-grid.component';
import { InvestorIntelItem } from '@app/dto/InvestorIntelItem';
import { EndpointConstants } from '@app/services/constants/endpoint.constants';
import { FilterDto } from '@app/service/search-grid/SearchDto';
import { AppConstants } from '@app/app.constants';
import { InvestorEngagementExampleDialogComponent } from './engagement-example-dialog/engagement-example-dialog.component';

@Component({
  selector: 'app-investor-intel-engagement-example',
  templateUrl: './investor-intel-engagement-example.component.html',
  styleUrls: ['./investor-intel-engagement-example.component.scss']
})
export class InvestorIntelEngagementExampleComponent implements OnInit {
  @Input()
  investorId: number;
  @ViewChild(InvestorInitiativeGridComponent) gridChild: InvestorInitiativeGridComponent;

  displayedGridColumns: string[] = ['name', 'impact', 'sectorFocus', 'regionalFocus', 'summary', 'actions'];
  additionalGridFilter: FilterDto[];
  gridRequestUrl = EndpointConstants.ALL_ENGAGEMENT_EXAMPLES;
  gridIdKeyName: string = 'investorIntelItemId';
  gridDeleteUrl = EndpointConstants.ENGAGEMENT_EXAMPLE;
  deleteConfirmationTitle: string = 'Example/Case'
  deleteMessageMappingKey: string = 'name';
  noItemsGridText = 'There are no Example/Cases';

  constructor(
    private httpClient: HttpClient,
    public dialog: MatDialog,
  ) {
  }
  ngOnInit() {
    this.additionalGridFilter = [
      new FilterDto({ filterPorp: "investorId", value: [this.investorId + ''] }),
      new FilterDto({ filterPorp: "itemType", value: [AppConstants.INVESTOR_INTEL_TYPES.ENGAGEMENT_EXAMPLE] })
    ];
  }

  refreshGridData() {
    this.gridChild.fetchGridData();
  }

  onEditClicked(item: InvestorIntelItem) {
    const dialogRef = this.dialog.open(InvestorEngagementExampleDialogComponent, {
      width: '1000px',
      maxHeight: 'fit-content',
      data: {
        investorId: this.investorId,
        isCreate: false,
        id: item.investorIntelItemId,
        title: 'Edit Example/Case'
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      this.refreshGridData();
    });
  }

  onAddBtnClick() {
    const dialogRef = this.dialog.open(InvestorEngagementExampleDialogComponent, {
      width: '1000px',
      maxHeight: 'fit-content',
      data: {
        id: this.investorId,
        isCreate: true,
        title: 'Add New Example/Case:'
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      this.refreshGridData();
    });
  }
}