import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { BeneficiaryLogsInsertSegmentComponent } from '../audit-logs-segment/beneficiary-logs/beneficiary-logs-insert-segment/beneficiary-logs-insert-segment.component';
import { BeneficiaryLogsUpdateSegmentComponent } from '../audit-logs-segment/beneficiary-logs/beneficiary-logs-update-segment/beneficiary-logs-update-segment.component';
import { BeneficiaryLogsDeleteSegmentComponent } from '../audit-logs-segment/beneficiary-logs/beneficiary-logs-delete-segment/beneficiary-logs-delete-segment.component';
import { AuditLogDTO } from '@app/dto/AuditLogDTO';
import { AlertService } from '@app/services/alert.service';
import { beneficiaryString } from '@app/utility/Strings';
import { GenericAuditLogsInsertSegmentComponent } from '../audit-logs-segment/generic-logs/generic-logs-insert-segment/generic-logs-insert-segment.component';
import { GenericAuditLogsUpdateSegmentComponent } from '../audit-logs-segment/generic-logs/generic-logs-update-segment/generic-logs-update-segment.component';
import { GenericAuditLogsDeleteSegmentComponent } from '../audit-logs-segment/generic-logs/generic-logs-delete-segment/generic-logs-delete-segment.component';

@Component({
  selector: 'app-beneficiary-logs-segment',
  templateUrl: './beneficiary-logs-segment.component.html',
  styleUrls: ['./beneficiary-logs-segment.component.scss']
})
export class BeneficiaryLogsSegmentComponent implements OnInit {
  private static readonly COMPONENTS = {
    'GenericAuditLogs': {
      'Added': GenericAuditLogsInsertSegmentComponent,
      'Update': GenericAuditLogsUpdateSegmentComponent,
      'Delete': GenericAuditLogsDeleteSegmentComponent,
    },
    'InvestorAssetManagerLookup':{
      'Added': BeneficiaryLogsInsertSegmentComponent,
      'Update': BeneficiaryLogsUpdateSegmentComponent,
      'Delete': BeneficiaryLogsDeleteSegmentComponent
    },
    'InvestorFundLookup':{
      'Added': BeneficiaryLogsInsertSegmentComponent,
      'Update': BeneficiaryLogsUpdateSegmentComponent,
      'Delete': BeneficiaryLogsDeleteSegmentComponent
    },
    'InvestorBeneficiary':{
      'Added': BeneficiaryLogsInsertSegmentComponent,
      'Update': BeneficiaryLogsUpdateSegmentComponent,
      'Delete': BeneficiaryLogsDeleteSegmentComponent
    }
  }
  action: string;
  @Input() isOpen;
  @Input() public log: AuditLogDTO = new AuditLogDTO();
  @ViewChild('container', {read: ViewContainerRef}) entry: ViewContainerRef;
  
  constructor(private resolver: ComponentFactoryResolver,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.entry.clear();

    if ( this.log.auditData ) {
      try{
        this.action = this.log.auditAction;
        const componentName = BeneficiaryLogsSegmentComponent.COMPONENTS[this.log.entityType] ? this.log.entityType : 'GenericAuditLogs';
        const component = BeneficiaryLogsSegmentComponent.COMPONENTS[componentName][this.log.auditAction];
        const factory = this.resolver.resolveComponentFactory(component);
        const componentRef = this.entry.createComponent(factory);
        (<ComponentDto>componentRef.instance).data = JSON.parse(this.log.auditData);    
    } catch (error) {
      this.alertService.sendError(JSON.stringify(error));
    }
  }
}
  
_beneficiaryString = (string) => {
    return beneficiaryString(string);
  };
}

class ComponentDto {
  data: any;
}
