import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuditEntityParentType } from '@app/enums/AuditEntityParentTypeEnum';

@Component({
  selector: 'app-investor-audit-logs',
  templateUrl: './investor-audit-logs.component.html',
  styleUrls: ['./investor-audit-logs.component.scss']
})
export class InvestorAuditLogsComponent {

  @Input() data: any;
  params = {
    parentId: 0,
    auditEntityParentType: AuditEntityParentType.INVESTOR
  };

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.params = {
        parentId: +params['id'],
        auditEntityParentType: AuditEntityParentType.INVESTOR
      };
    });
  }
}
