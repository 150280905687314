import {AfterContentChecked, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { RoleGuardService } from '@app/auth/role-guard.service';
import { BeneficiaryHistoryNavigationButtonsComponent } from '../beneficiary-history-navigation-buttons/beneficiary-history-navigation-buttons.component';

@Component({
  selector: 'app-benenficiarymaintenancedialog',
  templateUrl: './benenficiarymaintenancedialog.component.html',
  styleUrls: ['./benenficiarymaintenancedialog.component.scss']
})
export class BenenficiarymaintenancedialogComponent implements OnInit, AfterContentChecked {
  public isFormValid = false;
  isCreateView = false;
  hasAccess :boolean =false;
  dataValues: any;
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              public dialog: MatDialog,
              private roleGuardService: RoleGuardService,
              private cdr: ChangeDetectorRef,
              private dialogRef: MatDialogRef<BenenficiarymaintenancedialogComponent>) {}

  ngOnInit(): void {
    this.hasAccess = this.roleGuardService.hasAccess('ADMIN', 'EDIT');
    this.dataValues = this.data;
    this.isCreateView = this.dataValues.isCreateView;
  }

  onCloseBtnClick($event): void {
    this.dialogRef.close({data: $event});
  }

  setFormValidation(valid) {
    this.isFormValid = valid;
  }

  // this is necessary to fix `ExpressionChangedAfterItHasBeenCheckedError`
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  showHistory(){
    let value = this.dataValues;
  const dialog = this.dialog.open(BeneficiaryHistoryNavigationButtonsComponent,
      {
        data: {value },
        width:'550px',
        maxHeight: '800px'
      });
    dialog.afterClosed().subscribe(x => {

    });
  }
}
