/* tslint:disable:max-line-length */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectMailingDocRecipientSentDTO } from '@app/dto/ProjectMailingDocRecipientSentDTO';
import { environment } from '../../../environments/environment';
import { ProjectMailingDocumentDTO } from '@app/dto/ProjectMailingDocumentDTO';
import { ProjectMailingDocumentOrderReceiveDTO } from '@app/dto/ProjectMailingDocumentOrderReceiveDTO';
import { ProjectMailingDocumentCopyDetailedDTO } from '@app/dto/ProjectMailingDocumentCopyDetailedDTO';
import { ProjectMailingDocRecipientDetailedDTO } from '@app/dto/ProjectMailingDocRecipientDetailedDTO';
import {
  IProjectMailingDeliveryLabelInput,
  IProjectMailingIndividualDeliveryTicketInput,
  ProjectMailingIndividualDeliveryTicket
} from '@app/dto/ProjectMailingDeliveryLabelDTO';
import { ClientReportDto } from '@app/dto/ClientReportDto';

@Injectable({
  providedIn: 'root'
})
export class DashboardMailingService {

  constructor(private http: HttpClient) { }

  public getMailingDocumentsSet(projectId: number): Observable<ProjectMailingDocumentDTO[]> {
    return this.http.get<ProjectMailingDocumentDTO[]>(`${environment.serverUrl}dashboardsmailing/mailing-document-set/all/${projectId}`);
  }

  public addMailingDocuments(document: ProjectMailingDocumentDTO): Observable<any> {
    return this.http.post<ProjectMailingDocumentDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-set`, document);
  }

  public editMailingDocuments(document: ProjectMailingDocumentDTO): Observable<any> {
    return this.http.put<ProjectMailingDocumentDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-set/edit`, document);
  }
  public addCopiesToMailingDocument(document: ProjectMailingDocumentOrderReceiveDTO): Observable<any> {
    return this.http.post<ProjectMailingDocumentOrderReceiveDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-copy/add`, document);
  }

  public deleteMailingDocuments(document: ProjectMailingDocumentDTO): Observable<any> {
    return this.http.delete(`${environment.serverUrl}dashboardsmailing/mailing-document-set/${document.mailingDocumentId}`);
  }

  public getMailingEventsHistory(documentId: number): Observable<ProjectMailingDocumentDTO[]> {
    return this.http.get<ProjectMailingDocumentDTO[]>(`${environment.serverUrl}dashboardsmailing/mailing-document-copy/all/${documentId}`);
  }

  public getMailingEvent(mailingDocumentCopyId: number): Observable<ProjectMailingDocumentCopyDetailedDTO> {
    return this.http.get<ProjectMailingDocumentCopyDetailedDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-copy/${mailingDocumentCopyId}`);
  }

  public updateMailingEvent(event: ProjectMailingDocumentCopyDetailedDTO): Observable<ProjectMailingDocumentCopyDetailedDTO> {
    return this.http.put<ProjectMailingDocumentCopyDetailedDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-copy/edit`, event);
  }
  public addMailingDocumentRecipient(recipient: ProjectMailingDocRecipientDetailedDTO): Observable<any> {
    return this.http.post<ProjectMailingDocRecipientDetailedDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient`, recipient);
  }

  public editMailingDocumentRecipient(recipient: ProjectMailingDocRecipientDetailedDTO): Observable<any> {
    return this.http.put<ProjectMailingDocRecipientDetailedDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient/edit`, recipient);
  }

  public addSentToMailingDocumentRecipient(recipient: ProjectMailingDocRecipientSentDTO): Observable<any> {
    return this.http.post<ProjectMailingDocRecipientSentDTO>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient/shipment`, recipient);
  }

  public getDeliveryLabelData(projectId: number): Observable<IProjectMailingDeliveryLabelInput> {
    return this.http.get<IProjectMailingDeliveryLabelInput>(`${environment.serverUrl}dashboardsmailing/mailing-document-label-print/${projectId}`);
  }

  public getCusips(mailingDocRecId: number, projectId: number, isEditMode: boolean) {
    return this.http.get<any[]>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient-set-cusip/${mailingDocRecId}/${projectId}/${isEditMode}`);
  }

  public deleteMailingRecipient(mailingDocumentRecipientId: number): Observable<any> {
    return this.http.delete<any>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient/delete/${mailingDocumentRecipientId}`);
  }

  public getMailingRecepientEventsHistory(recipientId: number): Observable<ProjectMailingDocRecipientSentDTO[]> {
    return this.http.get<ProjectMailingDocRecipientSentDTO[]>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient/all/${recipientId}`);
  }

  public getMailingRecepientEventDetails(eventId: number, projectId: number): Observable<IProjectMailingIndividualDeliveryTicketInput> {
    return this.http.get<IProjectMailingIndividualDeliveryTicketInput>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient-details/${eventId}/${projectId}`);
  }

  public updateMailingRecepientEventDetails(historyEvent: ProjectMailingIndividualDeliveryTicket): Observable<ProjectMailingIndividualDeliveryTicket> {
    return this.http.put<ProjectMailingIndividualDeliveryTicket>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient-details/edit`, historyEvent);
  }

  public deliveryIndividualTicket(data: ProjectMailingIndividualDeliveryTicket): Observable<ProjectMailingIndividualDeliveryTicket> {
    return this.http.post<ProjectMailingIndividualDeliveryTicket>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient/shipment`, data);
  }

  public deliveryBulkTicket(model: ClientReportDto): Observable<ProjectMailingIndividualDeliveryTicket[]> {
    return this.http.post<ProjectMailingIndividualDeliveryTicket[]>(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient/bulk-shipment`, model);
  }

  downloadDeliveryIndividualTicketPdfFile(mailingDocumentRecipientShipmentId: number): Promise<Blob> {
    let getfileheaders = new HttpHeaders().set('Accept', 'application/pdf');
    return this.http.get(`${environment.serverUrl}dashboardsmailing/mailing-document-recipient/file/${mailingDocumentRecipientShipmentId}`, { responseType: 'blob', headers: getfileheaders }).toPromise();
  }
}
