import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {InvestorValueSelectGroupModel, ValuesLookup} from '@app/dto/InvestorsVotingProcess';

@Component({
  selector: 'app-investor-value-select-group',
  templateUrl: 'investor-value-select-group.component.html',
  styleUrls: ['investor-value-select-group.component.scss']
})
export class InvestorValueSelectGroupComponent implements OnInit {
  _configuration;
  @Input() public disabled = false;
  @Output() disabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() set configuration(obj: InvestorValueSelectGroupModel) {
    if (obj) {
      this._configuration = obj;
      this.calculateNotSelectedRecords();
    }
  }

  @Output() configurationChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  editIt = false;
  model = {
    allNotSelectedRecords: 0,
    allSelectedRecords: 0,
    allNotSelectedRecordsCheck: false,
    allSelectedRecordsCheck: false,
    indeterminateAllNotSelectedRecords: false,
    indeterminateAllSelectedRecords: false,
  };

  allNotSelectedRecords: ValuesLookup[] = [];
  allSelectedRecords: ValuesLookup[] = [];

  constructor() {
    this._configuration = new InvestorValueSelectGroupModel();
  }

  ngOnInit() {
    this.calculateNotSelectedRecords();
  }

  changeDisabled(value) {
    this.disabled = value;
    this.disabledChange.emit(value);
  }

  calculateNotSelectedRecords() {
    this.allSelectedRecords = (this._configuration || {selectedRecords: []}).selectedRecords || [];
    this.editIt = this.allSelectedRecords.length > 0;
    const allSelectedIds = this.allSelectedRecords.map(record => record.id);
    this.allNotSelectedRecords = ((this._configuration || {allRecords: []}).allRecords || []).filter(record => allSelectedIds.indexOf(record.id) === -1);
  }

  bulkUpdateCheckbox($event, records) {
    records = records.map(r => {
      r.checked = $event.checked;
    });
  }

  updateBulkCheckbox() {
    this.model.allNotSelectedRecords = (this.allNotSelectedRecords || []).filter(rec => rec.checked).length;
    this.model.allSelectedRecords = (this.allSelectedRecords || []).filter(rec => rec.checked).length;
    this.model.indeterminateAllNotSelectedRecords = this.model.allNotSelectedRecords > 0 && this.model.allNotSelectedRecords !== (this.allNotSelectedRecords || []).length;
    this.model.indeterminateAllSelectedRecords = this.model.allSelectedRecords > 0 && this.model.allSelectedRecords !== (this.allSelectedRecords || []).length;
    this.model.allNotSelectedRecordsCheck = this.model.allNotSelectedRecords && this.model.allNotSelectedRecords !== (this.allNotSelectedRecords || []).length;
    this.model.allSelectedRecordsCheck = this.model.allSelectedRecords && this.model.allSelectedRecords !== (this.allSelectedRecords || []).length;
  }

  moveToSelected() {
    const allChecked = (this.allNotSelectedRecords || []).reduce((accomulator, rec) => {
      if (rec.checked) {
        accomulator.selected = [...accomulator.selected, rec];
      }
      else {
        accomulator.notSelected = [...accomulator.notSelected, rec];
      }

      return accomulator;
    }, {selected: [], notSelected: []});
    this.allSelectedRecords = [...this.allSelectedRecords, ...allChecked.selected.map(row => {
      row.checked = false;
      return row;
    })];
    this.allNotSelectedRecords = allChecked.notSelected;
    this.updateConfig();
    this.updateBulkCheckbox();
  }

  moveToNotSelected() {
    const allChecked = (this.allSelectedRecords || []).reduce((accomulator, rec) => {
      if (rec.checked) {
        accomulator.selected = [...accomulator.selected, rec];
      }
      else {
        accomulator.notSelected = [...accomulator.notSelected, rec];
      }

      return accomulator;
    }, {selected: [], notSelected: []});

    this.allNotSelectedRecords = [...this.allNotSelectedRecords, ...allChecked.selected.map(row => {
      row.checked = false;
      return row;
    })];

    this.allSelectedRecords = allChecked.notSelected;
    this.updateConfig();
    this.updateBulkCheckbox();
  }

  updateConfig() {
    this._configuration.selectedRecords = this.allSelectedRecords;
    this.configurationChange.emit(this._configuration);
  }
}

