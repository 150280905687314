import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CustodiansLink } from '@app/dto/CustodiansLink';
import { NameValueModel } from '@app/models/basic-structures';
import { LocalCustodiansService } from '@app/services/admin/local-custodians.service';
import { AlertService } from '@app/services/alert.service';

@Component({
  selector: 'app-add-global-custodian-link-dialog',
  templateUrl: './add-global-custodian-link-dialog.component.html',
  styleUrls: ['./add-global-custodian-link-dialog.component.scss']
})
export class AddGlobalCustodianLinkDialogComponent {
  model: CustodiansLink = new CustodiansLink();
  localCustodianId: number;
  isEdit = false;
  constructor(@Inject(MAT_DIALOG_DATA)
  public data: any,
    private dialogRef: MatDialogRef<AddGlobalCustodianLinkDialogComponent>,
    private localCustodiansService: LocalCustodiansService,
    private alertService: AlertService) {
      if(data.localCustodianId){
        this.localCustodianId = data.localCustodianId;
        this.loadLocalCustodianInitialData();
      } else {
        this.isEdit = true;
        this.model = new CustodiansLink(data.model);
      }
  }

  loadLocalCustodianInitialData(){
    this.localCustodiansService.get(this.localCustodianId, true).subscribe(data => {
      this.model = new CustodiansLink({ localCustodianId: data.localCustodianId, country: data.country });
    });
  }

  countrySelectionChanged(newCountry: NameValueModel) {
    this.model.country = newCountry.name;
  }

  getCustodianName = (item) => {
    return item.dtcCode && item.dtcCode.length ? `${item.globalCustodianName} (DTC: ${item.dtcCode})`: `${item.globalCustodianName}`;
  }

  onCustodianSelected(item) {
    if (item.custodianId) {
      this.model.globalCustodianId = item.custodianId;
      this.model.dtcCodeId = item.codeId;
    } else {
      this.model.globalCustodianId = null;
      this.model.dtcCodeId = null;
    }
  }

  onSave() {
    this.localCustodiansService.linkToGlobalCustodian(this.model).subscribe(
      data => {
        const message = this.isEdit? `updated`:`linked`;
        this.alertService.sendSuccess(`Global custodian has been ${message} successfully!`);
        this.dialogRef.close();
      },
      err => {
        var message = this.alertService.transformError(err.error || { Message: 'Unable to save link.' });
        this.alertService.sendError(message);
        this.dialogRef.close();
      }
    );
  }
}
