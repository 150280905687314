import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatIconRegistry, MatPaginator, MatSort, MatSortable, MatTableDataSource} from '@angular/material';
import {GridFilterItem, GridFilterModel} from '@app/components/grid-filter/grid-filter.models';
import {FilterDto} from '@app/service/search-grid/SearchDto';
import {VotingItemDto} from '@app/dto/VotingItemDto';
import {SearchGridService} from '@app/service/search-grid/search-grid.service';
import {Utils} from '@app/helper/utils';
import {Lookup} from '@app/dto/Lookup';
import {StartupdataproviderService} from '@app/service/startupdataprovider.service';
import {DomSanitizer} from '@angular/platform-browser';
import {VotingItemsService} from '@app/services/voting-items.service';
import {ProjectsService} from '@app/services/projects.service';
import {ProjectTemplateService} from '@app/service/projectTemplateService';
import {AlertService} from '@app/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {combineLatest} from 'rxjs';
import {DatePipe} from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-position-tracking',
  templateUrl: './dashboard-position-tracking.component.html',
  styleUrls: ['./dashboard-position-tracking.component.scss']
})
export class DashboardPositionTrackingComponent implements OnInit {
  _dataSource = new MatTableDataSource();
  dataSource;
  loaded = true;
  orgDisplayedColumns: string[] = [
    'custodian',
    'id',
    'type',
  ];
  displayedColumns: string[] = [];
  displayDynamicColumns: DynamicColumnDefinition[] = [];
  shareTypeColumns = [];
  shareTypeDateColumns = [];
  searchService: SearchGridService;
  filter: GridFilterModel = new GridFilterModel({
    gridFilterItems: [
      new GridFilterItem({ filterPorp: 'ProjectId', value: [''] }),
      new GridFilterItem({ filterPorp: 'VotingItemId', value: [''] }),
    ]
  });
  defaultShareType: any = { cusip: null, name: 'Total'};
  shareTypes: any[] = [];
  orgShareTypes: any[] = [];
  voteTitle: string;
  showGrid = false;
  private customPageSize = 25;
  private additionalFilter: FilterDto = null;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() projectId: number;

  sharesMemory = [];

  util = new Utils(this.startupdataproviderService);

  constructor(private projectTemplateService: ProjectTemplateService,
              private alertService: AlertService,
              private httpClient: HttpClient,
              private iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer,
              private projectsService: ProjectsService,
              private votingItemsService: VotingItemsService,
              private projectService: ProjectsService,
              private startupdataproviderService: StartupdataproviderService,
              private datePipe: DatePipe,
              private spinnerService: NgxSpinnerService) {
    iconRegistry.addSvgIcon(
      'search',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/outline-search-24px.svg'));

  }

  ngOnInit() {
    this.filter.gridFilterItems[0] = new GridFilterItem({ filterPorp: 'ProjectId', value: [`${this.projectId}`] });
    this.getData();
    this.projectsService.getProjectShareClassificationsPair(this.projectId).subscribe(data => {

      this.orgShareTypes = data.map(item => ({cusip: item.cusip, name: item.name}));
      this.shareTypes = Array.from(new Set([...this.orgShareTypes, { cusip: null, name: 'Total'}]));
      this.filter.gridFilterItems[1] = new GridFilterItem({filterPorp: 'ShareType', value: [`${this.defaultShareType.name}`]});

      this.loaded = true;
      this.searchService.onFilterChanged(this.filter);
    }, err => {
      this.alertService.sendError(' error ' + JSON.stringify(err));
    });
  }

  getData() {
    this.spinnerService.show('position-tracking');

    this.searchService =
      new SearchGridService(
        this.paginator,
        this._dataSource,
        this.httpClient,
        'dashboards/project-custodians-share-capitals-changes',
        this.filter,
        null,
        this.customPageSize,
        false,
        false
      );

    this.searchService.allData.subscribe(data => {
      this.spinnerService.show('position-tracking');

      if (typeof data.items !== 'undefined' && data.items.length > 0) {
        this.showGrid = true;
        this.setColumns(data);
        this.setTableRows(data);
      } else {
        this.showGrid = false;
        this.dataSource = new MatTableDataSource();
        this.dataSource.data = [];
      }

      if (data.items) {
        this.spinnerService.hide('position-tracking');
      }
    });
  }

  setColumns(data) {
    this.shareTypeColumns = this.extractUniqueShareTypes(data.items);

    this.displayedColumns = Object.assign([], this.orgDisplayedColumns);
    this.displayDynamicColumns = [];


    this.shareTypeColumns.forEach(shareType => {
      const shareClassificationDates = this.getUniqueDatesPerShareType(data.items, shareType);
      this.sharesMemory[shareType] = shareClassificationDates;
      shareClassificationDates.forEach(date => {
        const coldef = new DynamicColumnDefinition();
        coldef.colName = `${date}_${shareType}`;
        coldef.colHeaderName = date;
        this.displayedColumns.push(`${date}_${shareType}`);
        this.displayDynamicColumns.push(coldef);
      });
    });
  }

  getShareTypeColumnColspan = (shareType) => this.sharesMemory[shareType].length;

  setTableRows(data){
    const tableRows = data.items.reduce((accumulator, item, currentIndex) => {
      const record = {
        custodian: item.custodianName,
        id: item.custodianIdentifierId,
        type: item.isGlobalCustodian ? 'GLOBAL' : 'LOCAL',
      };
      let previousValue = 0;
      let oldShareType = '';
      this.displayDynamicColumns.forEach((_item, index) => {
        const [date, shareType] = _item.colName.split('_');
        const value = (item.shareCapitalDateTimeChanges.filter(share => this.datePipe.transform(share.timeOfChange, 'yyyy-MM-dd') === date ? !this.isTotalShareSelected() ? share.shareClassification === shareType : true : false) || []).reduce((sum, shareItem) => sum + shareItem.shareCapital, 0);
        const hideDiff = !index || shareType !== oldShareType;
        record[_item.colName] = {
          value: value,
          previousValue: previousValue,
          plus: value > previousValue,
          minus: value < previousValue,
          diff: hideDiff ? 0 : Math.abs(value - previousValue)
        };
        const sign = record[_item.colName].plus ? '+' : record[_item.colName].minus ? '-' : '';
        record[_item.colName].diffValue = `${sign}${record[_item.colName].diff}`
        previousValue = record[_item.colName].value;
        oldShareType = shareType;
      })

      accumulator.push(record);

      return accumulator;
    }, []);

    this.dataSource = new MatTableDataSource();
    this.dataSource.data = tableRows;
  }

  filterGrid(value, filterId) {
    if (filterId === 1) {
      this.filter.gridFilterItems[1] = new GridFilterItem({filterPorp: 'ShareType', value: [`${value}`]});
    }

    this.searchService.onFilterChanged(this.filter);
  }

  isTotalShareSelected = () => this.filter.gridFilterItems.find(filter => filter.filterPorp === 'ShareType').value[0] === 'Total';

  extractUniqueShareTypes = array => {
    if (this.isTotalShareSelected()) {
      return ['Total'];
    }
    return array.reduce((h, obj) => [...h, ...obj.shareCapitalDateTimeChanges.map(o => o.shareClassification)], [])
      .filter((value, index, self) => self.indexOf(value) === index);
  };

  getUniqueDatesPerShareType = (array, shareType) => array.reduce((h, obj) => [...h, ...this.filterPerShareType(obj.shareCapitalDateTimeChanges, shareType).map(o => this.datePipe.transform(o.timeOfChange, 'yyyy-MM-dd'))], [])
    .filter((value, index, self) => self.indexOf(value) === index).sort(function(a, b) {
      const dateA: any = new Date(a), dateB: any = new Date(b);
      return dateA - dateB;
    })

  filterPerShareType = ( array, shareType ) => {
    if(this.isTotalShareSelected()) {
      return array;
    }
    return array.filter( value => value.shareClassification === shareType);
  }

  getClassificationCusip(classification: string): string {
    let cusip = this.shareTypes.find(c => c.name === classification).cusip;
    if(cusip){
      return `(${cusip})`
    }

    return;
  }
}

export class DynamicColumnDefinition {
  colName: string;
  colHeaderName: string;
}
