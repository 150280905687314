import { Component, Input } from '@angular/core';
import { AuditEntityParentType } from '@app/enums/AuditEntityParentTypeEnum';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-audit-logs',
  templateUrl: './project-audit-logs.component.html',
  styleUrls: ['./project-audit-logs.component.scss']
})
export class ProjectAuditLogsComponent {

  @Input() data: any;
  params = {
    parentId: 0,
    auditEntityParentType: AuditEntityParentType.PROJECT
  };

  constructor(private route: ActivatedRoute) {
    this.route.parent.params.subscribe((params) => {
      this.params = {
        parentId: +params['id'],
        auditEntityParentType: AuditEntityParentType.PROJECT
      };
    });
  }
}
