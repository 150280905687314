import { Component } from '@angular/core';
import { NativeDateAdapter,  MatDateFormats } from '@angular/material';

@Component({

  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
})

export class DatePickerComponent {
}

export class AppDateAdapter extends NativeDateAdapter {

  parse(value: any): Date | null {

      if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
        const str = value.split('/');
        const year = Number(str[2]);
        const month = Number(str[1]) - 1;
        const date = Number(str[0]);
        return new Date(date, month, year);
      }
      const timestamp = typeof value === 'number' ? value : Date.parse(value);
      return isNaN(timestamp) ? null : new Date(timestamp);
    }

  format(date: Date, displayFormat: any): string {
  if (displayFormat == "input" || displayFormat.month == 'numeric') {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      return this.to2digit(day) + ' ' + this.getMonthName(month) + ' ' + year;
  }
  else {
      return date.toDateString();
  }
 }

 private getMonthName(month: number){
    let months= this.getMonthNames("short");
    return months[month-1];
 }
 private to2digit(n: number) {
     return ('00' + n).slice(-2);
 }

}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { day: 'numeric', month: 'short', year: 'numeric'},
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric'},
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { day: 'numeric', month: 'long' },
  }
};



