export class ProjectCustodians {
  projectId: number;
  projectTypeId: number;
  custodians: ProjectCustodian[] = [];
  voteItems: ProjectCustodianVoteItem[] = [];
}

export class ProjectCustodian {
  custodianId: number;
  custodianName: string;
  votes: ProjectCustodianVote[] = [];
}

export class ProjectCustodianVote {
  id: number;
  itemId: number;
  voteType: string;
  vote: number;
  isc?: number;
}

export class ProjectCustodianVoteItem {
  itemId: number;
  displayField1: string;
  displayField2: string;
  parentDisplayField1: string;
  parentDisplayField2: string;
  voteTotals: ProjectCustodianVoteTotal[] = [];
}


export class ProjectCustodianVoteTotal {
  voteType: string;
  vote: number;
  isc: number;
}


export class CustodiansList {
  custodianId: number;
  name: string;
  isGlobalCustodian: boolean;
  codes: any;
  codeId?: number;
  codeName?: string;
}


export class CustodiansPerProjectPost {
  projectId: number;
  userId: number;
  custodian: CustodiansList;
}
