import { UIPermissionDTO } from "./UIPermissionDTOs";

export class User
{
    userId:number;
    aDUserName:string;
    label:string;
    role:string;
    coverage:string;
    permissions: UIPermissionDTO[];
}