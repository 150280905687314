import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AlertService} from '@app/services/alert.service';
import {CustodiansService} from '@app/services/custodians.service';
import {CustodiansList} from '@app/dto/ProjectCustodians';

@Component({
  selector: 'app-project-custodian-reconciliation-dialog',
  templateUrl: 'project-custodian-reconciliation-dialog.component.html',
  styleUrls: ['project-custodian-reconciliation-dialog.component.scss'],
})

export class ProjectCustodianReconciliationDialogComponent implements OnInit {

  custodians: CustodiansListInternal[] = [];
  projectId: number;
  userId: number;
  rolename: string;
  coverage: string;
  selectedCustodians = [];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProjectCustodianReconciliationDialogComponent>,
    private alertService: AlertService,
    private custodiansService: CustodiansService) {
    this.projectId = +data.projectId;
    this.userId = +data.userId;
    this.rolename = data.rolename;
    this.coverage = data.coverage;
    this.selectedCustodians = data.selectedCustodians;
  }

  ngOnInit() {
    this.getCustodians();
  }

  getCustodians() {
    this.custodiansService.getAllCustodians().subscribe(
      data => {
        this.custodians = data.map(item => {
          return {
            ...item,
            ...{
              checked: this.selectedCustodians.findIndex(c => c.custodianId === item.custodianId &&
                c.isGlobalCustodian === item.isGlobalCustodian) > -1,
              disabled: this.selectedCustodians.findIndex(c => c.custodianId === item.custodianId &&
                c.isGlobalCustodian === item.isGlobalCustodian) > -1
            }
          };
        });
      },
      err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      }
    );
  }

  onCancelBtnClick() {
    this.dialogRef.close();
  }

  onSaveBtnClick() {
    const selectedCustodians = this.custodians.find(c => c.checked);
    const custodiansPerProjectPost = {
      projectId: this.projectId,
      userId: this.userId,
      custodian: selectedCustodians
    };
    this.custodiansService.saveCustodiansPerProject(custodiansPerProjectPost).subscribe(
      data => {
        if (data) this.alertService.sendSuccess('Update Successful !');
        this.dialogRef.close();
      },
      err => {
        this.alertService.sendError(' error ' + JSON.stringify(err));
      }
    );
  }

}


class CustodiansListInternal extends CustodiansList {
  checked?= false;
  disabled?= false;
}
