import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { map, catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EMPTY, of } from 'rxjs';
import { AlertService } from '@app/services/alert.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    constructor(private adalService: MsAdalAngular6Service, private router: Router, private inj: Injector) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.adalService.acquireToken(environment.activeDirectoryClientId)
            .pipe(
                catchError((error) => {
                    this.adalService.login();
                    return next.handle(request);
                })).switchMap(token => {
                    if (!request.url.includes(environment.graphApiBaseUrl)) {
                        request = request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                    }
                    return next.handle(request).pipe(catchError(x => this.HandleAuthError(x)));
                });

    }

    private HandleAuthError(err: HttpErrorResponse): Observable<any> {
      const alertService = this.inj.get(AlertService);
        if (err.status === 401) {
            this.adalService.login();
        } else if (err.status === 500) {
          alertService.sendError('Ops, something happen! Our team is informed about the problem and will check it.');
        }
        throw err;
    }
}
