import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input() disabled: boolean;

  @Output()
  public onClick = new EventEmitter<MouseEvent>();

  public handleClick(event: MouseEvent) {
    this.onClick.emit(event);
  }
}
