import { ActiveDirectoryUser } from "./ActiveDirectoryUser";

export class Investor {
    investorId: number;
    company: string;
    parentCompany: string;
    factsetID: string;
    legacyInvLabel:string;
    country: string;
    city: string;
    postalZipCode: string;
    stateId: number;
    stateName: string;
    street: string;
    address: string;
    other: string;
    phoneNo: string;
    email: string;
    website: string;
    sector: string;
    category: string;
    currency: string;
    aum: number;
    aumCurrency: string;
    marketCapitalization: number;
    marketCapitalizationCurrency: string;
    comment: string;
    coverage: string [];
    responsibilityUser: ActiveDirectoryUser;
}