import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@app/../environments/environment';
import {Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {Esg} from '@app/dto/Esg';
import {ExternalAdvisorDto} from '@app/dto/ExternalAdvisorDto';

@Injectable({
  providedIn: 'root'
})
export class AdminExternalAdvisorService {
  private cache$: Observable<ExternalAdvisorDto>[] = [];

  constructor(private http: HttpClient) {
  }

  public get(id: number, useCache: boolean = false): Observable<ExternalAdvisorDto> {
    if (!this.cache$[id] || !useCache) {
      this.cache$[id] = this.http
        .get<ExternalAdvisorDto>(`${environment.serverUrl}externaladvisors/get-by-id/${id}`).pipe(
          map(data => data),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        );
    }

    return this.cache$[id];
  }

  public create(model: ExternalAdvisorDto): Observable<ExternalAdvisorDto> {
    return this.http.post<ExternalAdvisorDto>(`${environment.serverUrl}externaladvisors/add`, model);
  }

  public update(model: ExternalAdvisorDto): Observable<ExternalAdvisorDto> {
    return this.create(model);
  }


  clearCache(id: number | null = null) {
    if (this.cache$ && id) {
      this.cache$[id] = null;
    } else {
      this.cache$ = null;
    }
  }


}
