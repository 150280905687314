import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ProjectsService } from '@app/services/projects.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-broker-type-report',
  templateUrl: './broker-type-report.component.html',
  styleUrls: ['./broker-type-report.component.scss']
})
export class BrokerTypeReportComponent implements OnInit {
  dataSource = new MatTableDataSource();
  displayedColumns: string [] = ['cusip', 'shareType', 'banks', 'total', 'discretionary', 'proportional', 'noDiscretionary'];
  topHeaderColumns: string [] = ['topCusip', 'topShareType', 'topBanks', 'brokers'];
  bottomHeadersColumns: string [] = ['cusip', 'shareType', 'banks', 'total', 'discretionary', 'proportional', 'noDiscretionary'];
  @ViewChild(MatSort) sort: MatSort;
  showGrid = false;
  @Input() projectId;

  constructor(private spinnerService: NgxSpinnerService,
    private projectService: ProjectsService) { }

  ngOnInit() {
    this.getData(this.projectId);
  }

  getData(projectId: number) {
    this.projectService.getBrokerTypeReport(projectId).subscribe(report => {
      this.spinnerService.show('broker-type');
      this.showGrid = report.length > 0;
      if (this.showGrid) {
        this.setTable(report);
      } else {
        this.dataSource = new MatTableDataSource();
        this.dataSource.sort = this.sort;
        this.dataSource.data = [];
      }
    });
    this.spinnerService.hide('broker-report');
  }

  setTable(data) {
    const tableRows = data.map(item => {
      let discretionary;
      let noDiscretionary;
      let proportional;
      if(item.brokers){
        discretionary = item.brokers.find(x => x.brokerVoteType == "Discretionary Vote");
        noDiscretionary = item.brokers.find(x => x.brokerVoteType == "No Discretionary Vote");
        proportional = item.brokers.find(x => x.brokerVoteType == "Proportional Vote");
      }
      const record = {
        cusip: item.cusip,
        shareClassification: item.shareClassification,
        numberOfShares: item.numberOfShares,
        shares: item.bankShares,
        brokerDiscretionary : discretionary? discretionary.brokerShares : 0, 
        brokerNoDiscretionary : noDiscretionary? noDiscretionary.brokerShares :0,
        brokerProportional : proportional ? proportional.brokerShares :0,              
        totalBroker: 0,
        banksPercentage: 0,
        brokerPercentage: 0,
        discretionaryPercentage:0,
        proportionalPercentage:0,
        noDiscretionaryPercentage:0,
      };
      record.totalBroker = record.brokerDiscretionary + record.brokerNoDiscretionary + record.brokerProportional;
      record.banksPercentage = (record.shares * 100)/record.numberOfShares;
      record.brokerPercentage = (record.totalBroker *100)/record.numberOfShares;
      record.discretionaryPercentage = record.brokerDiscretionary >0 ? (record.brokerDiscretionary * 100)/(record.totalBroker) :0;
      record.proportionalPercentage = record.brokerProportional > 0 ? (record.brokerProportional * 100)/(record.totalBroker) : 0;
      record.noDiscretionaryPercentage = record.brokerNoDiscretionary >0 ? (record.brokerNoDiscretionary * 100)/(record.totalBroker) : 0;
      return record;
    });
    this.dataSource = new MatTableDataSource();
    this.dataSource.data = tableRows;
  }
}
