export enum FilterOperators {
  NOT = 'Not',
  LESS_THEN = 'LessThan',
  LESS_THEN_OR_EQUAL = 'LessThanOrEqual',
  MORE_THEN = 'MoreThan',
  MORE_THEN_OR_EQUAL = 'MoreThanOrEqual',
  EQUAL = 'Equal',
  LIKE = 'Like',
  SEARCH = 'Search',
  IS_NULL = 'IsNull',
  IS_NOT_NULL = 'IsNotNull',
  RANGE = 'Range',
  IN = 'In',
  CUSTOM = 'Custom',
}
