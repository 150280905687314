import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { MultiselectChipsOptionModel } from '@app/components/multiselect-chips/multiselect-chips.component';
import { Filters } from '@app/investor/investordetail/investor-history-summary/investor-voting-history.component';
import { MatSelectChange } from '@angular/material';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-investor-voting-summary-filters',
  templateUrl: './investor-voting-summary-filters.component.html',
  styleUrls: ['./investor-voting-summary-filters.component.scss'],
})
export class InvestorVotingSummaryFiltersComponent implements OnChanges {
  @Input() investorId: number;
  @Input() allFilters: Filters;
  @Input() defaultFilters: Filters;
  @Input() shareClassifications: string[] = [];
  @Output() filtersApplyClick = new EventEmitter<Filters>();
  @Output() columnsChange = new EventEmitter<Filters>();
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public selectedFilters: Filters;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'icon_calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/navigation-icons/calendar.svg')
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultFilters && changes.defaultFilters.currentValue) {
      this.selectedFilters = new Filters(this.defaultFilters);
    }
  }

  public onSectorSelected(selections: MultiselectChipsOptionModel[]): void {
    this.selectedFilters.sectorOptions = selections;
  }

  public onMarketSelected(selections: MultiselectChipsOptionModel[]): void {
    this.selectedFilters.marketOptions = selections;
  }

  public onResolutionClassificationSelected(selections: MultiselectChipsOptionModel[]): void {
    this.selectedFilters.resolutionClassifications = selections;
  }

  public onProxyAdvisorNamesChange(selections: MultiselectChipsOptionModel[]): void {
    this.selectedFilters.proxyAdvisorsOptions = selections;

    this.columnsChange.emit(this.selectedFilters);
  }

  public onShareClassificationChange(selectChange: MatSelectChange): void {
    this.selectedFilters.shareClassification = selectChange.value;

    this.columnsChange.emit(this.selectedFilters);
  }

  public applyFilters() {
    this.filtersApplyClick.emit(this.selectedFilters);
  }

  public resetFilters() {
    this.selectedFilters = new Filters(this.allFilters);

    this.columnsChange.emit(this.selectedFilters);
  }
}
