import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import { Observable } from 'rxjs';
import {CountrySearchRequest, CitySearchRequest} from '../models/requests';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(private http: HttpClient, private appConfigService: AppConfigService) { }

  public searchForCity(searchTerm: string, countryValue?:string): Observable<any>{
    let csr: CitySearchRequest = new CitySearchRequest();
    csr.searchTerm = searchTerm;
    csr.country = countryValue;

    return this.http.post(this.appConfigService.getActiveWebApiHost() + 'lookups/GetFilteredCities', csr);
  }

  public searchForCountry(searchTerm: string): Observable<any>{
    let csr: CountrySearchRequest = new CountrySearchRequest(); csr.searchTerm = searchTerm;
    return this.http.post(this.appConfigService.getActiveWebApiHost() + 'lookups/GetFilteredCountries', csr);
  }

  public getCurrencies(): Observable<any> {
    return this.http.get(this.appConfigService.getActiveWebApiHost() + 'lookups/currencies');
  }

  public getCompaniesBySector(): Observable<any> {
    return this.http.get(this.appConfigService.getActiveWebApiHost() + 'lookups/companies-by-sector');
  }
}
