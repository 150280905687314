import { ProxyAdvisorRecommendationItem } from "./ProxyAdvisorRecommendationItem";

export class ProxyAdvisorRecommendations {
    constructor(){
        this.proxyAdvisorRecommendationItems = [];
    }
    projectId: number;
    proxyAdvisorId: number;
    proxyAdvisorName: string;
    projectTemplateId: number;
    iscPercent: number;
    proxyAdvisorRecommendationItems: ProxyAdvisorRecommendationItem[];
}